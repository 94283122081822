import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Fx, Icon, Card, Row, Col } from 'Components';
import { useStores } from 'Hooks/useStore';
import {
	DashboardProgress,
	InfoTooltip,
	OpenInNewTab,
	Statistic
} from 'Pages/Dashboard/components';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import { getIsPending } from 'Stores/util';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';
import { ElearningWidgets } from './Elearning.types';

const TotalElearningScore = () => {
	const { t } = useTranslation();
	const { filters } = useDashboardContext();
	const { dashboardStore } = useStores();
	const {
		status,
		elearning: { score }
	} = dashboardStore;

	useEffect(() => {
		dashboardStore.getElearningScore(filters);
		// eslint-disable-next-line
	}, [filters]);

	return (
		<Card
			loading={getIsPending(status.getElearningScore)}
			title={t('dashboard.tabs.elearning.totalElearningScore.title')}
			footer={
				<OpenInNewTab
					tab={DashboardTabKey.Elearning}
					widget={ElearningWidgets.TotalElearningScore}
				/>
			}
			extra={
				<Fx align="middle">
					<InfoTooltip
						title={t(
							'dashboard.tabs.elearning.totalElearningScore.tooltip'
						)}
					/>
				</Fx>
			}
		>
			<Row fullHeight>
				<Col span={8}>
					<Fx align="middle" justify="center" fullHeight>
						<DashboardProgress percent={score?.riskScore}>
							{t('dashboard.riskScore')}
						</DashboardProgress>
					</Fx>
				</Col>
				<Col span={8}>
					<Fx align="middle" justify="center" fullHeight>
						<Statistic
							vertical
							inheritColor
							icon={<Icon type="play" />}
							value={score?.enrollments}
						>
							{t('dashboard.enrollments')}
						</Statistic>
					</Fx>
				</Col>
				<Col span={8}>
					<Fx align="middle" justify="center" fullHeight>
						<Statistic
							vertical
							inheritColor
							icon={<Icon type="users" />}
							value={score?.users}
						>
							{t('dashboard.users')}
						</Statistic>
					</Fx>
				</Col>
			</Row>
		</Card>
	);
};

export default observer(TotalElearningScore);
