import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { Fx, Layout, Spin } from 'Components';

import OwnershipGroup from './OwnershipGroup/OwnershipGroup';

import {
	GetOwnershipGroupsRequest,
	GetOwnershipGroupsResponse,
	OwnershipGroup as OwnershipGroupType
} from 'Services/Api/Groups/Types';
import { GET_OWNERSHIP_GROUPS } from 'Services/Api/Groups/Queries';
import { getAppRoutes } from 'Pages/App/App.constants';
import styles from './OwnershipGroups.module.scss';
import { useErrorBoundary } from 'Hooks';
import { DataTreeContextProvider } from 'Contexts/DataTree/DataTreeContext';
import { DataTreeContextProps } from 'Contexts/DataTree/DataTree.types';
import { ownershipGroupToTreeNode } from 'Pages/User/components/OwnershipGroupField/OwnershipGroupField.helpers';
import DataTree from 'Contexts/DataTree/DataTree';
import { useState } from 'react';
import OwnershipGroupsSearch from './OwnershipGroupsSearch';
import GroupTreeLegend from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupTreeLegend';
interface OwnershipGroupsRouteParams {
	groupId?: string;
}

function OwnershipGroups() {
	const { t } = useTranslation();
	const history = useHistory();
	const { groupId } = useParams<OwnershipGroupsRouteParams>();
	const [activeGroup, setActiveGroup] = useState<
		OwnershipGroupType | undefined
	>();
	const errorBoundary = useErrorBoundary();
	const result = useQuery<
		GetOwnershipGroupsResponse,
		GetOwnershipGroupsRequest
	>(GET_OWNERSHIP_GROUPS, {
		variables: {
			filters: {}
		},
		onError: errorBoundary.onError,
		notifyOnNetworkStatusChange: true
	});

	const onChange: DataTreeContextProps<OwnershipGroupType>['onChange'] = (
		value
	) => {
		const item = value?.[0];
		if (item && item.data) {
			setActiveGroup(item.data);
		} else {
			errorBoundary.setStatus('notFound');
		}

		history.replace(
			generatePath(getAppRoutes().OWNERSHIP_GROUPS, {
				groupId: item?.key
			})
		);
	};

	if (!result.called || result.loading) {
		return <Spin />;
	}

	const dataSource = result.data?.listOwnershipGroups;

	const activeGroupId = Number(groupId);
	const initialValue = activeGroupId ? [{ key: activeGroupId }] : [];
	return (
		<Layout column={false} className={styles.root}>
			<DataTreeContextProvider<OwnershipGroupType>
				initialValue={initialValue}
				onChange={onChange}
				dataSource={dataSource}
				toNode={ownershipGroupToTreeNode}
				defaultActiveFirstOption
			>
				<DataTree
					layout="sidebar"
					className={styles.sidebar}
					header={(props) => (
						<Layout.Header aligned="left">
							<Fx column fullWidth>
								<h1>{t('ownershipGroups.title')}</h1>
								<OwnershipGroupsSearch {...props} />
							</Fx>
						</Layout.Header>
					)}
					footer={
						<GroupTreeLegend exclude={['deleted', 'archived']} />
					}
				>
					<DataTree.List />
				</DataTree>
			</DataTreeContextProvider>

			<OwnershipGroup
				group={activeGroup}
				// re-mount component if selected item is changed to reset filters/sorters
				key={activeGroup?.id}
				resultRefetch={result.refetch}
			/>
		</Layout>
	);
}

export default OwnershipGroups;
