import { useTranslation } from 'react-i18next';
import { Modal, Spin } from 'Components';
import { useModal } from 'Hooks/useModal';
import {
	DataTreeContextValue,
	DataTreeNode
} from 'Contexts/DataTree/DataTree.types';
import { DataTreeContextProvider } from 'Contexts/DataTree/DataTreeContext';
import {
	GetOwnershipGroupsRequest,
	GetOwnershipGroupsResponse,
	OwnershipGroup
} from 'Services/Api/Groups/Types';
import { useQuery } from '@apollo/client';
import { GET_OWNERSHIP_GROUPS } from 'Services/Api/Groups/Queries';
import DataTree from 'Contexts/DataTree/DataTree';
import GroupTreeLegend from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupTreeLegend';
import { AddUserValues } from 'Pages/User/User.types';
import { ownershipGroupToTreeNode } from './OwnershipGroupField.helpers';
import OwnershipGroupsSearch from 'Pages/OwnershipGroups/OwnershipGroupsSearch';
import { labelValueToDataTreeValue } from 'Contexts/DataTree/DataTree.helpers';

export type FieldValue =
	| (DataTreeNode & { data?: OwnershipGroup })[]
	| undefined;
export interface OwnershipGroupModalProps {
	initialValue: AddUserValues['ownershipGroup'];
	onSubmit: (value: FieldValue) => void;
}
function OwnershipGroupModal(props: OwnershipGroupModalProps) {
	const initialValue = props.initialValue
		? labelValueToDataTreeValue([props.initialValue])
		: [];

	const result = useQuery<
		GetOwnershipGroupsResponse,
		GetOwnershipGroupsRequest
	>(GET_OWNERSHIP_GROUPS, {
		variables: {
			filters: {}
		},
		notifyOnNetworkStatusChange: true
	});

	const dataSource = result.data?.listOwnershipGroups;
	return (
		<DataTreeContextProvider<OwnershipGroup>
			initialValue={initialValue}
			dataSource={dataSource}
			toNode={ownershipGroupToTreeNode}
		>
			{({ value, dirty }) => (
				<OwnershipGroupModalContent
					{...props}
					value={value}
					dirty={dirty}
					loading={!result.called || result.loading}
				/>
			)}
		</DataTreeContextProvider>
	);
}
type OwnershipGroupModalContentProps = OwnershipGroupModalProps &
	Pick<DataTreeContextValue, 'value' | 'dirty'> & {
		loading: boolean;
	};
function OwnershipGroupModalContent(props: OwnershipGroupModalContentProps) {
	const { t } = useTranslation();
	const { hideModal } = useModal();
	const onSubmit = async () => {
		await props.onSubmit(props.value as FieldValue);
		hideModal();
	};
	return (
		<Modal
			visible
			size="sm"
			title={t('user.ownershipGroupModal.title')}
			okText={t('user.ownershipGroupModal.addToGroup')}
			onOk={onSubmit}
			onCancel={hideModal}
			okButtonProps={{
				disabled: !props.dirty
			}}
		>
			{props.loading ? (
				<Spin />
			) : (
				<>
					{t('user.ownershipGroupModal.description')}

					<DataTree
						layout="modal"
						header={OwnershipGroupsSearch}
						footer={
							<GroupTreeLegend
								exclude={['deleted', 'archived']}
							/>
						}
					>
						<DataTree.List highlightValue />
					</DataTree>
				</>
			)}
		</Modal>
	);
}

export default OwnershipGroupModal;
