import { useTranslation } from 'react-i18next';
import { Button, Icon, Tooltip, Notification, Space } from 'Components';
import { getAppRoutes } from 'Pages/App/App.constants';
import { useHistory } from 'react-router-dom';
import ModalContextProvider, { useModal } from 'Hooks/useModal';
import RolesActionsModals, {
	RolesActionsModalsProps
} from './RolesActionsModals';
import { RolesActionsModalName } from './modals';
import { PermissionCode } from 'Services/Api/Users/Types';
import { usePermission } from 'Components/Permission/Permission.hooks';
import RolesExportButton, {
	RolesExportButtonProps
} from './components/RolesExportButton';

type RolesActionsContentProps = RolesActionsModalsProps &
	RolesExportButtonProps & { hasData: boolean };
function RolesActionsContent(props: RolesActionsContentProps) {
	const { ids } = props;
	const permission = usePermission();
	const history = useHistory();
	const { t } = useTranslation();
	const { showModal } = useModal();

	const onAdd = () => {
		history.push(getAppRoutes().ADD_ROLE);
	};
	const onDeleteClick = () => {
		showModal({ name: RolesActionsModalName.Delete, value: null });
	};

	const onCloneClick = () => {
		if (ids.length > 1) {
			Notification.error({
				description: t(
					'roles.rolesActionsModals.cloneModal.notificationCloneOnlyOneRoleAtTime'
				)
			});
			return;
		}
		showModal({ name: RolesActionsModalName.Clone, value: null });
	};
	const {
		permittedByCode: {
			[PermissionCode.CloneSystemRole]: isClonePermitted,
			[PermissionCode.DeleteSystemRole]: isDeletePermitted,
			[PermissionCode.AddSystemRole]: isAddPermitted,
			[PermissionCode.ExportSystemRoles]: isExportPermitted
		}
	} = permission.getHasPermission({
		code: [
			PermissionCode.CloneSystemRole,
			PermissionCode.DeleteSystemRole,
			PermissionCode.AddSystemRole,
			PermissionCode.ExportSystemRoles
		]
	});
	const isActionDisabled = !ids.length;

	return (
		<>
			<RolesActionsModals {...props} />
			<Space>
				{isClonePermitted && (
					<Tooltip
						title={t(
							'roles.rolesActionsModals.cloneModal.disabledTooltip'
						)}
						enabled={isActionDisabled}
					>
						<Button
							type="primary"
							shape="round"
							ghost
							disabled={isActionDisabled}
							onClick={onCloneClick}
						>
							{t('roles.clone')}
						</Button>
					</Tooltip>
				)}
				{isDeletePermitted && (
					<Tooltip
						title={t(
							'roles.rolesActionsModals.deleteModal.disabledTooltip'
						)}
						enabled={isActionDisabled}
					>
						<Button
							type="primary"
							shape="round"
							ghost
							disabled={isActionDisabled}
							onClick={onDeleteClick}
						>
							{t('delete')}
						</Button>
					</Tooltip>
				)}

				{isExportPermitted && (
					<RolesExportButton
						params={props.params}
						disabled={!props.hasData}
					/>
				)}

				{isAddPermitted && (
					<Button
						type="primary"
						shape="round"
						icon={<Icon type="plus" />}
						onClick={onAdd}
					>
						{t('roles.add')}
					</Button>
				)}
			</Space>
		</>
	);
}

const RolesActions = (props: RolesActionsContentProps) => (
	<ModalContextProvider>
		<RolesActionsContent {...props} />
	</ModalContextProvider>
);

export default RolesActions;
