import { Alert, Button, Col, Layout, Row, Space } from 'Components';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableLayoutContext } from './EditableLayout';

export interface EditableFieldsProps {
	onChange?: (dirty: boolean, isSubmitting: boolean) => void;
	footer?: boolean;
}

const EditableFields: React.FC<EditableFieldsProps> = ({
	footer,
	onChange,
	children
}) => {
	const { t } = useTranslation();
	const {
		status,
		dirty,
		isSubmitting,
		isValid,
		submitForm
	} = useFormikContext();

	useEffect(() => {
		onChange?.(dirty, isSubmitting);
		// eslint-disable-next-line
	}, [dirty, isSubmitting]);

	return (
		<Layout>
			<Layout.Content fullHeight>
				<Row fullHeight>
					<Col fullHeight xs={15}>
						{status && (
							<Alert
								showIcon
								message=""
								description={status}
								type="error"
							/>
						)}
						{children}
					</Col>
				</Row>
			</Layout.Content>
			{footer && (
				<EditableLayoutContext.Consumer>
					{({ goBack }) => (
						<Layout.Footer>
							<Space>
								<Button
									ghost
									shape="round"
									type="default"
									onClick={goBack}
								>
									{t('cancel')}
								</Button>
								<Button
									shape="round"
									type="primary"
									disabled={!(isValid && dirty)}
									loading={isSubmitting}
									onClick={() => submitForm()}
								>
									{t('saveChanges')}
								</Button>
							</Space>
						</Layout.Footer>
					)}
				</EditableLayoutContext.Consumer>
			)}
		</Layout>
	);
};

export default EditableFields;
