import { isArray, isEmpty, isNil, omitBy } from 'lodash';

export const getIsRequestParamsFiltered = (
	// eslint-disable-next-line
	params: Record<string, any> | undefined
) =>
	!isEmpty(
		omitBy(
			params,
			(value) => isNil(value) || (isArray(value) && !value.length)
		)
	);
