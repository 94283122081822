import { Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'Components';
import styles from './ValidateEmail.module.scss';

interface ValidateEmailFormProps {
	isValid: boolean;
	loading: boolean;
	isExpiredJWTError: boolean;
}

function ValidateEmailForm({
	isValid,
	loading,
	isExpiredJWTError
}: ValidateEmailFormProps) {
	const { t } = useTranslation();
	return (
		<Form className={styles.form}>
			<div className={styles.inputEmailWrap}>
				<Input.FormikField
					name="userEmail"
					label={t('user.email')}
					required
				/>
			</div>
			<div className={styles.buttonEmailWrap}>
				<Button
					shape="round"
					type="primary"
					htmlType="submit"
					disabled={!isValid || loading}
					loading={loading}
				>
					{t(
						isExpiredJWTError
							? 'emailValidation.resendValidationEmail'
							: 'emailValidation.validateEmail'
					)}
				</Button>
			</div>
		</Form>
	);
}

export default ValidateEmailForm;
