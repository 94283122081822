import { observer } from 'mobx-react-lite';
import { ColumnProps } from 'antd/lib/table';
import { PreviewUser } from 'Services/Api/Users/Types';
import { Table, Fx, Pagination } from 'Components';
import {
	ReportType,
	PreviewReportTypeData,
	ReportDataByAllUsersReportType
} from 'Services/Api/Reports/Types';
import { useAllUsers } from './AllUsersReportTable.hooks';
import styles from './AllUsersReportTable.module.scss';

interface AllUsersReportTableProps {
	data: PreviewReportTypeData<ReportType.Users>['data'];
	columns: ColumnProps<PreviewUser>[];
	reportName: PreviewReportTypeData<ReportType.Users>['name'];
}

function AllUsersReportTable(props: AllUsersReportTableProps) {
	const propsData = (props.data as unknown) as ReportDataByAllUsersReportType;
	const {
		dataSource,
		totalCount,
		pageNumber,
		pageSize,
		dataLoading,
		onPaginationChange
	} = useAllUsers(propsData, props.reportName);
	return (
		<>
			<Table
				dataSource={dataSource}
				rowKey="uuid"
				columns={props.columns}
				pagination={false}
				loading={dataLoading}
				className={styles.table}
			/>
			<Fx shrink={0}>
				<Pagination
					total={totalCount}
					pageSize={pageSize}
					current={pageNumber}
					onChange={onPaginationChange}
				/>
			</Fx>
		</>
	);
}

export default observer(AllUsersReportTable);
