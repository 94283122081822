import { Tooltip } from 'Components';
import styles from './VisualizationTooltip.module.scss';
import { TooltipProps } from 'antd';
import { VisualizationTooltipValue } from './VisualizationTooltip.types';

type VisualizationTooltipProps = Partial<TooltipProps> & {
	value: VisualizationTooltipValue;
	placement?: 'top';
};
function VisualizationTooltip({
	value,
	placement,
	...tooltipProps
}: VisualizationTooltipProps) {
	const xPos = `${value.x}px`;
	const yPos = `${value.y}px`;
	const translateX = `${xPos}`;
	const translateY = placement === 'top' ? `calc(${yPos} - 100%)` : `${yPos}`; // move tooltip 100% height up
	return (
		<div
			className={styles.root}
			style={{ transform: `translate(${translateX}, ${translateY})` }}
		>
			<Tooltip
				{...tooltipProps}
				visible
				title={
					<div className={styles.tooltipValue}>
						{value.values.map((d, i) => {
							return (
								<div
									key={i}
									className={styles.tooltipValueItem}
								>
									{d.title}
									<div
										className={styles.count}
										style={{ color: d.countColor }}
									>
										{d.count}
									</div>
								</div>
							);
						})}
					</div>
				}
				overlayClassName={styles.tooltipOverlay}
				getPopupContainer={(node) =>
					node.parentElement || document.body
				}
			/>
		</div>
	);
}

export default VisualizationTooltip;
