import { ExportButton } from 'Components';
import { ExportButtonProps } from 'Components/ExportButton/ExportButton';
import { EXPORT_OWNERSHIP_GROUPS } from 'Services/Api/Groups/Queries';
import {
	ExportOwnershipGroupsRequest,
	ExportOwnershipGroupsResponse
} from 'Services/Api/Groups/Types';

export type OwnershipGroupExportButtonProps = Omit<
	ExportButtonProps<
		ExportOwnershipGroupsResponse,
		ExportOwnershipGroupsRequest
	>,
	'query' | 'getQueryUrl'
>;
function OwnershipGroupExportButton(props: OwnershipGroupExportButtonProps) {
	const getQueryUrl = (data: ExportOwnershipGroupsResponse) =>
		data.exportOwnershipGroups.downloadUrl;
	return (
		<ExportButton<
			ExportOwnershipGroupsResponse,
			ExportOwnershipGroupsRequest
		>
			query={EXPORT_OWNERSHIP_GROUPS}
			getQueryUrl={getQueryUrl}
			{...props}
		/>
	);
}

export default OwnershipGroupExportButton;
