import {
	SnippetsOutlined,
	QuestionCircleOutlined,
	GlobalOutlined,
	LoadingOutlined
} from '@ant-design/icons';
import { ReactComponent as Eye } from 'Icons/eye.svg';
import { ReactComponent as FileText } from 'Icons/file-text.svg';
import { ReactComponent as ModelFund } from 'Icons/model-fund.svg';
import { ReactComponent as Settings } from 'Icons/settings.svg';
import { ReactComponent as Stack } from 'Icons/stack.svg';
import { ReactComponent as ExclamationCircle } from 'Icons/exclamation-circle.svg';
import { ReactComponent as Close } from 'Icons/close.svg';
import { ReactComponent as ChevronDown } from 'Icons/chevron-down.svg';
import { ReactComponent as Help } from 'Icons/help.svg';
import { ReactComponent as Bell } from 'Icons/bell.svg';
import { ReactComponent as ArrowLeft } from 'Icons/arrow-left.svg';
import { ReactComponent as FlagUs } from 'Icons/flag-usa.svg';
import { ReactComponent as FlagFr } from 'Icons/flag-fr.svg';
import { ReactComponent as FlagEs } from 'Icons/flag-es.svg';
import { ReactComponent as Plus } from 'Icons/plus.svg';
import { ReactComponent as PlusEllipsis } from 'Icons/plus-ellipsis.svg';
import { ReactComponent as MinusCircle } from 'Icons/minus-circle.svg';
import { ReactComponent as More } from 'Icons/more.svg';
import { ReactComponent as Search } from 'Icons/search.svg';
import { ReactComponent as Filter } from 'Icons/filter.svg';
import { ReactComponent as Download } from 'Icons/download.svg';
import { ReactComponent as Move } from 'Icons/move.svg';
import { ReactComponent as Check } from 'Icons/check.svg';
import { ReactComponent as CheckCircle } from 'Icons/check-circle.svg';
import { ReactComponent as Delete } from 'Icons/delete.svg';
import { ReactComponent as Edit } from 'Icons/edit.svg';
import { ReactComponent as Password } from 'Icons/password.svg';
import { ReactComponent as Users } from 'Icons/users.svg';
import { ReactComponent as Restore } from 'Icons/restore.svg';
import { ReactComponent as Import } from 'Icons/import.svg';
import { ReactComponent as Copy } from 'Icons/copy.svg';
import { ReactComponent as Unlock } from 'Icons/unlock.svg';
import { ReactComponent as Lock } from 'Icons/lock.svg';
import { ReactComponent as InfoOutlined } from 'Icons/info-outlined.svg';
import { ReactComponent as Hierarchy } from 'Icons/hierarchy.svg';
import { ReactComponent as Information } from 'Icons/information.svg';
import { ReactComponent as Property } from 'Icons/property.svg';
import { ReactComponent as Like } from 'Icons/like.svg';
import { ReactComponent as Dislike } from 'Icons/dislike.svg';
import { ReactComponent as Neutral } from 'Icons/neutral.svg';
import { ReactComponent as TrendUp } from 'Icons/trend-up.svg';
import { ReactComponent as TrendDown } from 'Icons/trend-down.svg';
import { ReactComponent as Envelope } from 'Icons/envelope.svg';
import { ReactComponent as Click } from 'Icons/click.svg';
import { ReactComponent as NewWindow } from 'Icons/new-window.svg';
import { ReactComponent as Reward } from 'Icons/reward.svg';
import { ReactComponent as Sandglass } from 'Icons/sandglass.svg';
import { ReactComponent as Play } from 'Icons/play.svg';
import { ReactComponent as DownloadCircle } from 'Icons/download-circle.svg';
import { ReactComponent as Report } from 'Icons/report.svg';
import { ReactComponent as Calendar } from 'Icons/calendar.svg';
import { ReactComponent as Profile } from 'Icons/profile.svg';
import { ReactComponent as Success } from 'Icons/success.svg';
import { ReactComponent as Discount } from 'Icons/discount.svg';
import { ReactComponent as Time } from 'Icons/time.svg';
import { ReactComponent as EyeCrossed } from 'Icons/eye-crossed.svg';
import { ReactComponent as EyeOpened } from 'Icons/eye-opened.svg';

const Icons = {
	snippets: SnippetsOutlined,
	eye: Eye,
	filter: Filter,
	fileText: FileText,
	modelFund: ModelFund,
	settings: Settings,
	stack: Stack,
	search: Search,
	close: Close,
	question: QuestionCircleOutlined,
	global: GlobalOutlined,
	exclamationCircle: ExclamationCircle,
	chevronDown: ChevronDown,
	bell: Bell,
	help: Help,
	arrowLeft: ArrowLeft,
	flagUs: FlagUs,
	flagFr: FlagFr,
	flagEs: FlagEs,
	plus: Plus,
	plusEllipsis: PlusEllipsis,
	minusCircle: MinusCircle,
	more: More,
	download: Download,
	move: Move,
	check: Check,
	checkCircle: CheckCircle,
	delete: Delete,
	edit: Edit,
	password: Password,
	users: Users,
	restore: Restore,
	import: Import,
	copy: Copy,
	unlock: Unlock,
	lock: Lock,
	infoOutlined: InfoOutlined,
	hierarchy: Hierarchy,
	information: Information,
	property: Property,
	like: Like,
	dislike: Dislike,
	neutral: Neutral,
	loading: LoadingOutlined,
	trendUp: TrendUp,
	trendDown: TrendDown,
	envelope: Envelope,
	click: Click,
	newWindow: NewWindow,
	reward: Reward,
	sandglass: Sandglass,
	play: Play,
	downloadCircle: DownloadCircle,
	report: Report,
	calendar: Calendar,
	profile: Profile,
	success: Success,
	discount: Discount,
	time: Time,
	eyeCrossed: EyeCrossed,
	eyeOpened: EyeOpened
};

export default Icons;
