import { Tabs as AntTabs, TabsProps } from 'antd';
import cn from 'classnames';
import styles from './Tabs.module.scss';

type TabsInterface = React.FC<TabsProps> & {
	TabPane: typeof AntTabs.TabPane;
};
const Tabs: TabsInterface = (props) => {
	return <AntTabs {...props} className={cn(props.className, styles.root)} />;
};

Tabs.TabPane = AntTabs.TabPane;

export default Tabs;
