import { ColumnProps } from 'antd/lib/table';
import {
	ARCHIVED_LABEL,
	GROUP_ACTIVE_STATUS_LABEL_MAP,
	GROUP_ACTIVE_STATUS_TAG_MAP,
	GROUP_ACTIVE_STATUS_LIST,
	PROPERTY_LABEL
} from 'app-config';
import {
	Tag,
	Input,
	Icon,
	Button,
	Select,
	Radio,
	Text,
	OptionalValue
} from 'Components';
import {
	useCreateColumns,
	ColumnFilters
} from 'Components/Table/createColumns';
import {
	GetGroupsListRequest,
	Group,
	GroupActiveStatus
} from 'Services/Api/Groups/Types';
import GroupMoreButton from './GroupMoreButton/GroupMoreButton';
import { DataSourceItem } from './Group.types';
import { GroupMode } from '../Groups.types';
import { useTranslation } from 'react-i18next';

export const GROUP_TABLE_COLUMN_PROPS = (): Record<
	keyof Pick<
		Group,
		'name' | 'isProperty' | 'active' | 'isArchived' | 'syncCode'
	>,
	ColumnProps<DataSourceItem> &
		Required<Pick<ColumnProps<DataSourceItem>, 'render'>>
> => {
	const { t } = useTranslation();
	return {
		name: {
			width: 280,
			title: t('group.groupName'),
			key: 'name',
			dataIndex: 'name',
			defaultSortOrder: 'ascend',
			render: (value) => value
		},
		isProperty: {
			width: 150,
			title: t('property'),
			key: 'isProperty',
			dataIndex: 'isProperty',
			filters: [
				{
					text: PROPERTY_LABEL.YES(),
					value: true
				},
				{
					text: PROPERTY_LABEL.NO(),
					value: false
				}
			],
			render: (value) =>
				value ? PROPERTY_LABEL.YES() : PROPERTY_LABEL.NO()
		},
		syncCode: {
			width: 150,
			title: t('group.syncCode'),
			key: 'syncCode',
			dataIndex: 'syncCode',
			render: (_, record) => (
				<OptionalValue>{record.syncCode}</OptionalValue>
			)
		},
		active: {
			width: 140,
			title: t('group.status'),
			key: 'active',
			dataIndex: 'active',
			filters: GROUP_ACTIVE_STATUS_LIST.map((value) => ({
				text: GROUP_ACTIVE_STATUS_LABEL_MAP[value](),
				value
			})),
			render: (_, record) => (
				<Tag type={GROUP_ACTIVE_STATUS_TAG_MAP[record.active]}>
					{GROUP_ACTIVE_STATUS_LABEL_MAP[record.active]()}
				</Tag>
			)
		},
		isArchived: {
			width: 140,
			title: t('group.archived'),
			key: 'isArchived',
			dataIndex: 'isArchived',
			filters: [
				{
					text: ARCHIVED_LABEL.YES(),
					value: true
				},
				{
					text: ARCHIVED_LABEL.NO(),
					value: false
				}
			],
			render: (value) => (
				<Tag type="error" ghost>
					{value ? ARCHIVED_LABEL.YES() : ARCHIVED_LABEL.NO()}
				</Tag>
			)
		}
	};
};

type GroupFilters = GetGroupsListRequest['filters'];
export type GroupTableFilters = ColumnFilters<GroupFilters>;

interface GetColumnsParams {
	filterValues: GroupFilters | undefined;
	isManageMode: boolean;
	isTenant: boolean;
	deleteGroupAction: (fieldId: string) => void;
	isDeleteGroupButtonDisable: boolean;
	handleAddGroupClick: () => void;
	validateUniqGroupName: () => void;
	validateGroupSyncCode: () => void;
	mode: GroupMode;
}

const useColumns = ({
	filterValues,
	isManageMode,
	isTenant,
	deleteGroupAction,
	isDeleteGroupButtonDisable,
	handleAddGroupClick,
	validateUniqGroupName,
	validateGroupSyncCode,
	mode
}: GetColumnsParams): Array<ColumnProps<DataSourceItem>> => {
	const { t } = useTranslation();
	const { search, filter, filters, sort } = useCreateColumns<
		Group,
		GroupFilters
	>({
		filterValues
	});

	const colProps = GROUP_TABLE_COLUMN_PROPS();

	const columns: Array<ColumnProps<DataSourceItem> | false> = [
		{
			...search,
			...sort,
			...colProps.name,
			title: <>{t(isTenant ? 'group.tenantName' : 'group.groupName')}</>,
			filteredValue: filters.name,
			render: (value, record) =>
				record.isManageRow ? (
					{
						children: (
							<Button
								type="primary"
								shape="round"
								icon={<Icon type="plus" />}
								onClick={handleAddGroupClick}
							>
								{t(
									'groups.groupDetails.groupDetailsActions.addGroup'
								)}
							</Button>
						),
						props: {
							colSpan: 5
						}
					}
				) : record.key ? (
					<Input.HookFormInput
						name={`groups.${record.key}.name`}
						onBlur={() => validateUniqGroupName()}
						required
					/>
				) : (
					<>
						{value}
						<br />
						<Text color="default">
							{t('groups.columns.level', {
								level: record.level
							})}
						</Text>
					</>
				)
		},
		{
			...filter,
			...sort,
			...colProps.isProperty,
			render: (value, record, index) => ({
				children: record.isManageRow ? null : record.key ? (
					<Radio.Group.HookFormField
						name={`groups.${record.key}.isProperty`}
						defaultValue={false}
						disabled={!record.canBePropertyToAncestors}
					>
						<Radio value={true}>{PROPERTY_LABEL.YES()}</Radio>
						<Radio value={false}>{PROPERTY_LABEL.NO()}</Radio>
					</Radio.Group.HookFormField>
				) : (
					colProps.isProperty.render(value, record, index)
				),
				props: {
					colSpan: record.isManageRow ? 0 : undefined
				}
			}),
			filteredValue: filters.isProperty
		},
		{
			...search,
			...sort,
			...colProps.syncCode,
			filteredValue: filters.syncCode,
			render: (value, record, index) => ({
				children: record.isManageRow ? null : record.key ? (
					<Input.HookFormInput
						name={`groups.${record.key}.syncCode`}
						onBlur={validateGroupSyncCode}
						required
					/>
				) : (
					colProps.syncCode.render(value, record, index)
				),
				props: {
					colSpan: record.isManageRow ? 0 : undefined
				}
			})
		},
		{
			...filter,
			...sort,
			...colProps.active,
			filteredValue: filters.active,
			render: (value, record, index) => {
				return {
					children: record.isManageRow ? null : record.key ? (
						<Select.HookFormSelect
							name={`groups.${record.key}.active`}
							required
							defaultValue={GROUP_ACTIVE_STATUS_LABEL_MAP[
								GroupActiveStatus.Active
							]()}
						>
							{GROUP_ACTIVE_STATUS_LIST.map((status) => (
								<Select.Option key={status} value={status}>
									{GROUP_ACTIVE_STATUS_LABEL_MAP[status]()}
								</Select.Option>
							))}
						</Select.HookFormSelect>
					) : (
						colProps.active.render(value, record, index)
					),
					props: {
						colSpan: record.isManageRow ? 0 : undefined
					}
				};
			}
		},
		isManageMode && {
			...filter,
			...sort,
			...colProps.isArchived,
			filteredValue: filters.isArchived
		},
		{
			title: t('actions'),
			width: 80,
			render: (_, record) => {
				return {
					children: record.isManageRow ? null : record.fieldId ? (
						<Button
							shape="round"
							type="primary"
							size="small"
							ghost
							icon={<Icon type="close" />}
							disabled={isDeleteGroupButtonDisable}
							onClick={() =>
								deleteGroupAction(record.fieldId as string)
							}
						/>
					) : (
						<GroupMoreButton
							group={record}
							disabled={mode === 'add-multiple'}
						/>
					),
					props: {
						colSpan: record.isManageRow ? 0 : undefined
					}
				};
			}
		}
	];

	return columns.filter(Boolean) as Array<ColumnProps<DataSourceItem>>;
};

export default useColumns;
