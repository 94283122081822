import { Upload } from 'antd';
import { Button } from 'Components';
import { useTranslation } from 'react-i18next';
import {
	useUploadGroupsButton,
	UploadGroupsButtonProps
} from './UploadGroupsButton.hooks';

function UploadGroupsButton(props: UploadGroupsButtonProps) {
	const { t } = useTranslation();
	const { loading, beforeUpload } = useUploadGroupsButton(props);

	return (
		<Upload
			accept=".xlsx"
			showUploadList={false}
			beforeUpload={beforeUpload}
			disabled={loading}
		>
			<Button loading={loading} thin type="text" link>
				{t('groupsHierarchy.formHierarchy.importGroups')}
			</Button>
		</Upload>
	);
}

export default UploadGroupsButton;
