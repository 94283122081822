import { useState, useCallback, useMemo, useRef } from 'react';

import { APP_CONFIG } from 'app-config';
import { useFormikContext } from 'formik';
import { ReportFormValues } from 'Pages/Report/EditReport/schema';

import { useStores } from 'Hooks/useStore';
import getTabs from './getUserActivityTabs';
import {
	ReportDataByReportTypeUserActivity,
	UserActivityTab,
	UserActivityParams
} from 'Services/Api/Reports/Types';
import { getIsPending } from 'Stores/util';
import { PreviewUser } from 'Services/Api/Users/Types';
import { adaptPropsDataToDataSource } from '../../PreviewReport.helpers';
import { UserActivityDataSource } from './UserActivityReportTable.types';
import { DEFAULT_PAGINATION_PARAMS } from './UserActivityReportTable.constants';
import { IPagination } from '../PreviewReportTable.types';
import { getBaseUserActivityRequest } from '../PreviewReportTable.helpers';

export function useTabsWithPagination(
	propsData: ReportDataByReportTypeUserActivity,
	// for the saved reports preview case
	reportName: string
) {
	const { reportStore, reportsStore } = useStores();
	const [tabState, setTabState] = useState<UserActivityDataSource>({
		activeTab: UserActivityTab.Total,
		dataSource: adaptPropsDataToDataSource<PreviewUser>(
			propsData[UserActivityTab.Total] as PreviewUser[]
		),
		totalCount: propsData.totalCount,
		pageNumber: APP_CONFIG.TABLE_DEFAULT_PAGINATION_PAGE,
		pageSize: APP_CONFIG.TABLE_PAGE_SIZE
	});
	const paginationRef = useRef<Record<string, IPagination>>({
		[UserActivityTab.Total]: DEFAULT_PAGINATION_PARAMS
	});

	const formikContext = useFormikContext<ReportFormValues>();

	const formikValues = formikContext?.values ?? null;

	const getUserActivityParams = useMemo(
		() => {
			if (formikValues) {
				return getBaseUserActivityRequest(formikValues);
			}

			const report = reportsStore.getReportByName(reportName);

			if (report) {
				return (tabPaginationParams: UserActivityParams) =>
					Object.assign(
						{},
						{ reportId: report.id },
						tabPaginationParams
					);
			}
		},
		// eslint-disable-next-line
		[formikValues]
	);

	const tabs = getTabs();

	const onTabChange = useCallback(
		async (value: typeof tabState.activeTab) => {
			const tab = tabs.find((tab) => tab.displayName === value);
			if (tab) {
				const isPaginationCacheForTabInitiated = Boolean(
					paginationRef.current[tab.displayName]
				);

				const {
					pageNumber,
					pageSize
				} = isPaginationCacheForTabInitiated
					? paginationRef.current[tab.displayName]
					: DEFAULT_PAGINATION_PARAMS;

				await updateTabState(tab.displayName, pageSize, pageNumber);
			}
		},
		// eslint-disable-next-line
		[]
	);

	const onPaginationChange = useCallback(
		async (pageNumber?: number, pageSize?: number) => {
			const definedPageNumber =
				pageNumber ?? APP_CONFIG.TABLE_DEFAULT_PAGINATION_PAGE;
			const definedPageSize = pageSize ?? APP_CONFIG.TABLE_PAGE_SIZE;
			paginationRef.current[tabState.activeTab] = {
				pageNumber: definedPageNumber,
				pageSize: definedPageSize
			};
			await updateTabState(
				(tabState.activeTab as unknown) as UserActivityTab,
				definedPageSize,
				definedPageNumber
			);
		},
		// eslint-disable-next-line
		[tabState.activeTab]
	);

	async function updateTabState(
		userActivityTab: UserActivityTab,
		pageSize: number,
		pageNumber: number
	) {
		const params = getUserActivityParams({
			pageSize,
			pageNumber,
			userActivityTab
		});
		const response = await reportStore.previewReport(params);
		const responseData = (response.data as unknown) as ReportDataByReportTypeUserActivity;
		setTabState(() => ({
			activeTab: userActivityTab,
			pageSize,
			pageNumber,
			dataSource: adaptPropsDataToDataSource<PreviewUser>(
				responseData[userActivityTab] as PreviewUser[]
			),
			totalCount: responseData.totalCount
		}));
	}

	return {
		onTabChange,
		onPaginationChange,
		tabs,
		dataLoading: getIsPending(reportStore.status.previewReport),
		tabState
	};
}
