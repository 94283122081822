import { DropdownMenu } from 'Components';
import { UsersTableDeletedStatus } from './Users.constants';
import styles from './Users.module.scss';

interface UsersTableTypeDropdownProps {
	initialValue: boolean;
	onChange: (value: UsersTableDeletedStatus) => void;
}
function UsersTableTypeDropdown(props: UsersTableTypeDropdownProps) {
	const onChange = (value: UsersTableDeletedStatus) => {
		props.onChange(value);
	};

	const value = props.initialValue
		? UsersTableDeletedStatus.Deleted
		: UsersTableDeletedStatus.All;

	return (
		<DropdownMenu
			value={value}
			options={[
				{
					key: UsersTableDeletedStatus.All,
					label: UsersTableDeletedStatus.All
				},
				{
					key: UsersTableDeletedStatus.Deleted,
					label: UsersTableDeletedStatus.Deleted
				}
			]}
			onChange={onChange}
			className={styles.usersDropdown}
		/>
	);
}

export default UsersTableTypeDropdown;
