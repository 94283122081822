export function asyncHandler() {
	let onResolve: (<T>(value: T | PromiseLike<T>) => void) | null = null;
	// eslint-disable-next-line
	let onReject: ((reason?: any) => void) | null = null;
	return {
		executePromise: () =>
			new Promise((res, rej) => {
				onResolve = res;
				onReject = rej;
			}),
		getResolve: () => onResolve,
		getReject: () => onReject
	};
}
