import { useHistory, Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AuthState } from 'Pages/Auth/Auth.types';
import { useStores } from 'Hooks/useStore';
import { getAppRoutes } from 'Pages/App/App.constants';
import { TwoFactorAuthorizationStatus } from 'Services/Api/Users/Types';
import { Notification } from 'Components';

function RedirectToLogin() {
	localStorage.setItem("greeting","0");
	localStorage.setItem("initalpull","0");
	const { location } = useHistory();
	const { authStore } = useStores();
	const { t } = useTranslation();

	if (
		authStore?.currentUser?.twoFactorAuthorizationStatus ===
		TwoFactorAuthorizationStatus.Blocked
	) {
		Notification.error({
			description: t('twoFactorAuthorization.errors.2FABlocked')
		});
	}
	const appRoutes = getAppRoutes();

	const { search, pathname } = location;

	return (
		<Redirect
			to={{
				pathname: appRoutes.LOGIN,
				state: {
					returnUrl: search ? pathname + search : pathname
				} as AuthState
			}}
		/>
	);
}

export default RedirectToLogin;
