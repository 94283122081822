import {
	Row as AntRow,
	RowProps as AntRowProps,
	Col as AntCol,
	ColProps
} from 'antd';
import cn from 'classnames';
import styles from './Grid.module.scss';

type GridProps = {
	fullHeight?: boolean;
};
type RowProps = AntRowProps &
	GridProps & {
		fullWidth?: boolean;
	};
const Row = ({ fullHeight, fullWidth, ...props }: RowProps) => (
	<AntRow
		{...props}
		className={cn(props.className, {
			[styles.fullHeight]: fullHeight,
			[styles.fullWidth]: fullWidth
		})}
	/>
);

const Col = ({ fullHeight, ...props }: ColProps & GridProps) => (
	<AntCol
		{...props}
		className={cn(props.className, { [styles.fullHeight]: fullHeight })}
	/>
);

export { Row, Col };
