import Icons from 'Components/Icon/Icons';
import { TFunction } from 'i18next';
import { REPORT_TIME_PERIOD_HIDDEN } from 'Pages/Report/EditReport/EditReport.constants';
import { StandardReportListItem, ReportType } from 'Services/Api/Reports/Types';

export const PATH = 'reports.tabs.standard';

export const STANDARD_REPORT_SECTION_LABEL: Record<
	StandardReportListItem['category'],
	string
> = {
	executive_reports: `${PATH}.sections.executiveReports`,
	summary: `${PATH}.sections.summary`,
	data_export: `${PATH}.sections.dataExport`
};

const standardReportDesc = (type: ReportType) => (t: TFunction) => {
	return REPORT_TIME_PERIOD_HIDDEN.includes(type)
		? ''
		: t(`${PATH}.currentOrHistorical`);
};

export const STANDARD_REPORT_TYPE_MAP: Record<
	ReportType,
	{
		label: (t: TFunction) => string;
		desc: (t: TFunction) => string;
		icon: keyof typeof Icons;
		value: ReportType;
	}
> = {
	[ReportType.ActiveCampaigns]: {
		label: (t) => t('dashboard.tabs.phishing.activeCampaigns.title'),
		desc: standardReportDesc(ReportType.ActiveCampaigns),
		icon: 'success',
		value: ReportType.ActiveCampaigns
	},
	[ReportType.ElearningGroupSummary]: {
		label: (t) =>
			`${t('dashboard.eLearning')} ${t('dashboard.groupSummary.title')}`,
		desc: standardReportDesc(ReportType.ElearningGroupSummary),
		icon: 'report',
		value: ReportType.ElearningGroupSummary
	},
	[ReportType.OverviewGroupSummary]: {
		label: (t) =>
			`${t('dashboard.overview')} ${t('dashboard.groupSummary.title')}`,
		desc: standardReportDesc(ReportType.OverviewGroupSummary),
		icon: 'report',
		value: ReportType.OverviewGroupSummary
	},
	[ReportType.PhishingGroupSummary]: {
		label: (t) =>
			`${t('dashboard.phishing')} ${t('dashboard.groupSummary.title')}`,
		desc: standardReportDesc(ReportType.PhishingGroupSummary),
		icon: 'report',
		value: ReportType.PhishingGroupSummary
	},
	[ReportType.SpotcheckGroupSummary]: {
		label: (t) =>
			`${t('dashboard.spotCheck')} ${t('dashboard.groupSummary.title')}`,
		desc: standardReportDesc(ReportType.SpotcheckGroupSummary),
		icon: 'report',
		value: ReportType.SpotcheckGroupSummary
	},
	[ReportType.UserActivity]: {
		label: (t) => t('dashboard.userActivity'),
		desc: standardReportDesc(ReportType.UserActivity),
		icon: 'checkCircle',
		value: ReportType.UserActivity
	},
	[ReportType.Users]: {
		label: (t) => t(`${PATH}.list.users`),
		desc: standardReportDesc(ReportType.Users),
		icon: 'users',
		value: ReportType.Users
	},
	[ReportType.Groups]: {
		label: (t) => t(`${PATH}.list.groups`),
		desc: standardReportDesc(ReportType.Groups),
		icon: 'property',
		value: ReportType.Groups
	},
	[ReportType.Roles]: {
		label: (t) => t(`${PATH}.list.roles`),
		desc: standardReportDesc(ReportType.Roles),
		icon: 'profile',
		value: ReportType.Roles
	},
	[ReportType.RiskScore]: {
		label: (t) => t(`${PATH}.list.riskScore`),
		desc: standardReportDesc(ReportType.RiskScore),
		icon: 'like',
		value: ReportType.RiskScore
	},
	[ReportType.ExecutiveSummary]: {
		label: (t) => t(`${PATH}.list.executiveSummary.title`),
		desc: (t) => t(`${PATH}.list.executiveSummary.description`),
		icon: 'report',
		value: ReportType.ExecutiveSummary
	}
};

export const STANDARD_REPORT_TYPES = Object.values(STANDARD_REPORT_TYPE_MAP);
