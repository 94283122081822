import { AxiosErrorResponse } from 'app-types';
import { Button } from 'Components';
import { ButtonProps } from 'Components/Button/Button';
import { useStores } from 'Hooks/useStore';
import { useHandleDownloadError } from 'Hooks';
import { useTranslation } from 'react-i18next';
import { ReportFormValues } from './schema';
import { preparePreviewRequest } from '../PreviewReport/PreviewReport.helpers';
import { observer } from 'mobx-react-lite';
import { useDownloadReportProgress } from 'Hooks/useDownloadReportProgress/useDownloadReportProgress';

type EditReportDownloadButtonProps = ButtonProps & {
	params: ReportFormValues;
};

function EditReportDownloadButton(props: EditReportDownloadButtonProps) {
	const { t } = useTranslation();
	const handleDownloadError = useHandleDownloadError();
	const { downloadReportsStore } = useStores();
	useDownloadReportProgress();

	const handleDownload: ButtonProps['onClick'] = async (e) => {
		try {
			const params = {
				...preparePreviewRequest(props.params),
				fileType: props.params.fileType
			};
			props.onClick?.(e);

			await downloadReportsStore.generateAndDownloadReport(params);
		} catch (e) {
			const axiosError = e as AxiosErrorResponse;
			handleDownloadError(axiosError);
		}
	};

	return (
		<Button
			type="primary"
			shape="round"
			onClick={handleDownload}
			disabled={props.disabled}
		>
			{t('download')}
		</Button>
	);
}

export default observer(EditReportDownloadButton);
