import { Menu } from 'antd';
import { Button, Dropdown, Icon } from 'Components';
import { useModal } from 'Hooks/useModal';
import { OwnershipGroupActionsModalName } from './modals';
import {
	DynamicPermissionCode,
	GetUsersRequest,
	PermissionCode
} from 'Services/Api/Users/Types';
import { generatePath, useHistory } from 'react-router-dom';
import { getAppRoutes } from 'Pages/App/App.constants';
import { usePermission } from 'Components/Permission/Permission.hooks';
import { useMemo } from 'react';
import { qsStringify, toPath } from 'Helpers/navigation';
import { useTranslation } from 'react-i18next';
import { MenuItem } from 'app-types';

enum MoreDropdownButtonMenuKey {
	Edit = 'Edit',
	Users = 'Users',
	Delete = 'Delete'
}

const MENU_ITEMS: MenuItem<MoreDropdownButtonMenuKey>[] = [
	{
		key: MoreDropdownButtonMenuKey.Edit,
		title: 'edit',
		icon: <Icon type="edit" />,
		permission: {
			code: PermissionCode.EditOwnershipGroup
		}
	},
	{
		key: MoreDropdownButtonMenuKey.Users,
		title: 'ownershipGroups.ownershipGroup.moreDropdownButton.showUsers',
		icon: <Icon type="users" />,
		permission: {
			dynamicCode: DynamicPermissionCode.ViewUsers
		}
	},
	{
		key: MoreDropdownButtonMenuKey.Delete,
		title: 'delete',
		icon: <Icon type="delete" />,
		permission: {
			code: PermissionCode.DeleteOwnershipGroup
		}
	}
];
interface MoreDropdownButtonProps {
	ownershipGroupId: number;
}

const MoreDropdownButton = (props: MoreDropdownButtonProps) => {
	const { t } = useTranslation();
	const { getHasPermission } = usePermission();
	const history = useHistory();
	const { showModal } = useModal();
	const onMenuClick = (info: { key: React.Key }) => {
		const menuKey = info.key as MoreDropdownButtonMenuKey;
		switch (menuKey) {
			case MoreDropdownButtonMenuKey.Delete:
				showModal({
					value: props.ownershipGroupId,
					name: OwnershipGroupActionsModalName.Delete
				});
				break;
			case MoreDropdownButtonMenuKey.Edit:
				history.push(
					generatePath(getAppRoutes().EDIT_OWNERSHIP_GROUP, {
						groupId: props.ownershipGroupId
					})
				);
				break;
			case MoreDropdownButtonMenuKey.Users:
				const usersFilters: GetUsersRequest['filters'] = {
					ownershipGroupIds: [String(props.ownershipGroupId)]
				};
				const path = toPath({
					path: getAppRoutes().USERS,
					query: qsStringify(usersFilters)
				});
				history.push(path);
				break;
		}
	};

	const menuItems = useMemo(() => {
		return MENU_ITEMS.filter(({ permission }) => {
			const hasPermission =
				!permission || getHasPermission(permission).permitted;
			return hasPermission;
		});
	}, [getHasPermission]);

	if (!menuItems.length) {
		return null;
	}
	return (
		<Dropdown
			overlay={
				<Menu onClick={onMenuClick}>
					{menuItems.map((menuItem) => (
						<Menu.Item key={menuItem.key} icon={menuItem.icon}>
							{t(menuItem.title)}
						</Menu.Item>
					))}
				</Menu>
			}
			arrow={false}
		>
			<Button
				shape="round"
				ghost
				type="primary"
				size="small"
				thinVertical
			>
				<Icon type="more" />
			</Button>
		</Dropdown>
	);
};

export default MoreDropdownButton;
