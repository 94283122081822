import { useFormikContext } from 'formik';
import { Input, Layout, Permission, Select } from 'Components';
import { useTranslation } from 'react-i18next';
import {
	DynamicPermissionCode,
	PermissionCode
} from 'Services/Api/Users/Types';
import { LanguageI18nKey } from 'Services/Api/Common/Types';
import { TITLE_BY_MENU_KEY } from 'i18n/i18n.constants';
import {
	RoleField,
	GroupField,
	JobClassField,
	UserRoleField
} from '../components';
import { useModuleSettings } from '../EditUser/EditUser.hooks';
import ModuleSettingsContainer, {
	useJobClass,
	useResetLmsFieldsOnGroupsChange
} from '../EditUser/tabs/ModuleSettings/ModuleSettingsContainer';
import UserContextProvider, { UserContext } from '../UserContext';
import { AddUserValues } from '../User.types';
import { getLMSModuleSettingErrorText } from '../EditUser/tabs/ModuleSettings/ModuleSettings.helpers';

function CreateUserFormFields() {
	const {
		onUserRoleChange,
		onJobClassChange,
		onDisabled
	} = useModuleSettings();
	const { t } = useTranslation();
	const { values, errors } = useFormikContext<AddUserValues>();
	const { loading, data } = useJobClass({
		values
	});
	useResetLmsFieldsOnGroupsChange({ values, resetLmsFields: onDisabled });
	return (
		<UserContextProvider permissionCode={DynamicPermissionCode.AddUser}>
			<Layout.List>
				<Layout.List.ListItem title={t('user.editUser.tabs.profile')}>
					<Layout.List.Section title={t('general')}>
						<Input.FormikField
							name="firstName"
							label={t('user.firstName')}
							required
						/>
						<Input.FormikField
							name="lastName"
							label={t('user.lastName')}
							required
						/>
						<Input.FormikField
							name="email"
							label={t('user.email')}
							required
						/>
						<Input.FormikField
							name="cognitoLogin"
							label={t('user.cognitoLogin')}
							required
						/>
						<Input.FormikField
							name="phoneNumber"
							label={t('user.phoneNumber')+` (optional)`}
						/>
					</Layout.List.Section>
					<Layout.List.Section title={t('user.permission')}>
						<RoleField />
						<GroupField />
					</Layout.List.Section>
				</Layout.List.ListItem>
				<Layout.List.ListItem title={t('user.accountsSettings')}>
					<Layout.List.Section>
						<UserContext.Consumer>
							{({
								languages,
								languagesList,
								timezones,
								timezonesList
							}) => (
								<>
									<Select.FormikField
										name="timezoneId"
										label={t('user.timezoneId')}
										loading={timezones.loading}
									>
										{timezonesList?.map((option) => (
											<Select.Option
												key={option.id}
												value={option.id}
											>
												{option.zone}
											</Select.Option>
										))}
									</Select.FormikField>
									<Select.FormikField
										name="languageId"
										label={t('user.languageId')}
										loading={languages.loading}
									>
										{languagesList?.map((option) => (
											// @NOTE: 'disabled' implemented according to https://support.dataart.com/browse/VNZ-1931
											<Select.Option
												key={option.id}
												value={option.id}
												disabled={
													option.name !==
													TITLE_BY_MENU_KEY[
														LanguageI18nKey.English
													]
												}
											>
												{option.name}
											</Select.Option>
										))}
									</Select.FormikField>
								</>
							)}
						</UserContext.Consumer>
					</Layout.List.Section>
				</Layout.List.ListItem>
				<Permission code={PermissionCode.Lms}>
					{({ permitted }) =>
						permitted && (
							<Layout.List.ListItem
								fullHeight
								title={t('user.editUser.tabs.moduleSettings')}
							>
								<Layout.List.Section>
									<ModuleSettingsContainer
										values={values}
										onDisabled={onDisabled}
									>
										{(disabled) => (
											<>
												<JobClassField
													value={
														values.moduleSettings
															?.jobClassId
													}
													disabled={disabled}
													onChange={onJobClassChange}
													error={getLMSModuleSettingErrorText(
														errors.moduleSettings,
														'jobClassId',
														t
													)}
													required={!disabled}
													options={data}
													loading={loading}
												/>
												<UserRoleField
													value={
														values.moduleSettings
															?.userRoleId
													}
													disabled={disabled}
													onChange={onUserRoleChange}
													error={getLMSModuleSettingErrorText(
														errors.moduleSettings,
														'userRoleId',
														t
													)}
													required={!disabled}
												/>

												{/* 
                        // TODO: need for https://support.dataart.com/browse/VNZ-1684
                        <Checkbox.FormikField
													label={t(
														'user.doNotUpdateOnAutoFeed'
													)}
													name="doNotUpdateOnAutoFeeds"
												/> */}
											</>
										)}
									</ModuleSettingsContainer>
								</Layout.List.Section>
							</Layout.List.ListItem>
						)
					}
				</Permission>
			</Layout.List>
		</UserContextProvider>
	);
}

export default CreateUserFormFields;
