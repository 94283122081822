import { observer } from 'mobx-react-lite';
import {
	PermissionCode,
	DynamicPermissionCode
} from 'Services/Api/Users/Types';
import { usePermission } from './Permission.hooks';

interface Code {
	code: PermissionCode | PermissionCode[];
}
interface DynamicCode {
	dynamicCode: DynamicPermissionCode | DynamicPermissionCode[];
	dynamicCodeRole?: string;
}
interface PermissionPropsBase extends Partial<Code>, Partial<DynamicCode> {
	every?: boolean;
	children: (helpers: {
		permitted: boolean;
		permittedByCode: Record<PermissionCode | string, boolean>;
	}) => React.ReactNode;
}

export type PermissionProps = PermissionPropsBase & (Code | DynamicCode);

const Permission: React.FC<PermissionProps> = (props) => {
	const helpers = usePermission();
	return <>{props.children(helpers.getHasPermission(props))}</>;
};

export default observer(Permission);
