import {
	useRef,
	ReactElement,
	useMemo,
	ReactNode,
	useState,
	useEffect
} from 'react';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import cn from 'classnames';
import { TableLocale } from 'antd/lib/table/interface';
import TotalCount from 'Components/Table/TotalCount';
import EmptyText from 'Components/Table/EmptyText';
import { Checkbox } from 'Components';
import { useSize } from 'Hooks';
import styles from './VirtualTable.module.scss';
import { CHECKBOX_BOX, ROW_HEIGHT } from './VirtualTable.constants';

export interface Column<D> {
	title: string | ReactElement;
	width?: number;
	renderComponent(data: D, index?: number): ReactNode;
	className?: string;
}
export interface VirtualTableProps<D> {
	columns: (Column<D> | typeof CHECKBOX_BOX)[];
	dataSource: D[];
	itemSize?: number;
	locale?: TableLocale;
	renderTotal?: boolean;
	handleOnChange?: (indexes: string[]) => void;
	footer?: ReactElement;
	listClass?: string;
	getRowKey?: (data: D) => string;
	cellClassName?: string;
}

function VirtualTable<DS>({
	columns,
	dataSource,
	itemSize,
	locale,
	renderTotal,
	handleOnChange,
	footer,
	listClass,
	getRowKey,
	cellClassName
}: VirtualTableProps<DS>) {
	const ref = useRef(null);
	const size = useSize(ref);
	const [checkedRowKeys, setCheckedRowKeys] = useState<
		Record<string, boolean>
	>({});
	const [indeterminate, setIndeterminate] = useState(false);
	const [checkAll, setCheckAll] = useState<boolean>(false);
	const shouldRenderCheckbox = typeof handleOnChange === 'function';

	useEffect(() => {
		const checkedRowKeysLength = Object.keys(checkedRowKeys).filter(
			(rowKey) => checkedRowKeys[rowKey]
		).length;
		const isLengthEqual = checkedRowKeysLength === dataSource.length;
		setIndeterminate(Boolean(checkedRowKeysLength) && !isLengthEqual);
		setCheckAll(isLengthEqual);
	}, [checkedRowKeys, dataSource, handleOnChange]);

	useEffect(() => {
		if (typeof getRowKey === 'function') {
			const rowKeys = dataSource.map(getRowKey);
			setCheckedRowKeys((currentRowKeys) => {
				const currentRowKeysToArray = Object.keys(currentRowKeys);

				return currentRowKeysToArray.reduce<Record<string, boolean>>(
					(acc, rowKey) => {
						if (rowKeys.includes(rowKey)) {
							acc[rowKey] = checkedRowKeys[rowKey];
						}

						return acc;
					},
					{}
				);
			});
		}

		// eslint-disable-next-line
	}, [dataSource]);

	const dataSourceWithFooter = footer ? [...dataSource, footer] : dataSource;
	const definedColumns = useMemo(() => {
		return shouldRenderCheckbox ? [CHECKBOX_BOX, ...columns] : columns;
	}, [shouldRenderCheckbox, columns]);
	const columnsWidth = useMemo(() => {
		const staticWidthProperties = definedColumns.reduce<{
			amount: number;
			commonWidth: number;
		}>(
			(acm, column) => {
				if (column.width) {
					acm.amount += 1;
					acm.commonWidth += column.width;
				}
				return acm;
			},
			{ amount: 0, commonWidth: 0 }
		);

		return definedColumns.map((column) => {
			if (column.width) {
				return column.width;
			}

			return (
				((size?.width ?? 0) - staticWidthProperties.commonWidth) /
				(definedColumns.length - staticWidthProperties.amount)
			);
		});
	}, [size, definedColumns]);

	const handleRowCheckboxChange = (isChecked: boolean, rowData: DS) => {
		const rowKey =
			typeof getRowKey === 'function' ? getRowKey(rowData) : '';

		setCheckedRowKeys((currentCheckedRowKeys) => {
			const newRowKeys = {
				...currentCheckedRowKeys,
				[rowKey]: isChecked
			};

			handleOnChange?.(
				Object.keys(newRowKeys).filter((rowKey) => newRowKeys[rowKey])
			);

			return newRowKeys;
		});
	};

	const handleHeaderCheckboxChange = (isChecked: boolean) => {
		setCheckAll(() => {
			const newRowKeys =
				isChecked && typeof getRowKey === 'function'
					? dataSource.reduce<Record<string, boolean>>(
							(acc, item) => {
								const rowKey = getRowKey(item);
								return {
									...acc,
									[rowKey]: true
								};
							},
							{}
					  )
					: {};

			handleOnChange?.(Object.keys(newRowKeys));

			setCheckedRowKeys(newRowKeys);
			return isChecked;
		});
	};

	const renderHeader = () => {
		return (
			<div className={styles.tableHeader}>
				{definedColumns.map((column, index) => {
					const style = {
						width: columnsWidth[index]
					};
					const isCheckbox = shouldRenderCheckbox && !index;
					return (
						<div
							key={index}
							className={cn(styles.tableHeaderTh, {
								[styles.headerCheckbox]: isCheckbox,
								[cellClassName as string]:
									cellClassName && !isCheckbox
							})}
							style={style}
						>
							<div className={styles.tableHeaderContent}>
								{isCheckbox ? (
									<Checkbox
										indeterminate={indeterminate}
										checked={checkAll}
										onChange={(ev) =>
											handleHeaderCheckboxChange(
												ev.target.checked
											)
										}
									/>
								) : (
									// @ts-ignore
									column.title
								)}
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	const renderRow = ({ style, data, index }: ListChildComponentProps) => {
		const isFooter = footer && index === dataSourceWithFooter.length - 1;
		const hasFooter = Boolean(footer);
		const checked =
			typeof getRowKey === 'function'
				? checkedRowKeys[getRowKey(data[index])]
				: false;
		return (
			<div
				style={style}
				className={cn(styles.virtualRow, {
					[styles.hasFooter]: hasFooter,
					[styles.noFooter]: !hasFooter,
					[styles.checkedRow]: checked
				})}
			>
				{isFooter
					? footer
					: definedColumns.map(
							/*@ts-ignore */
							({ renderComponent, className }, i) => {
								const style = {
									width: columnsWidth[i]
								};
								const isCheckbox = shouldRenderCheckbox && !i;
								return (
									<div
										style={style}
										className={cn(styles.virtualTd, {
											[className]: className,
											[styles.checkboxRow]: isCheckbox,
											[cellClassName as string]:
												cellClassName && !isCheckbox
										})}
										key={`${data[index]?.id}_${i}` ?? index}
									>
										{isCheckbox ? (
											<Checkbox
												checked={checked}
												onChange={(ev) => {
													handleRowCheckboxChange(
														ev.target.checked,
														data[index]
													);
												}}
											/>
										) : data[index] ? (
											renderComponent(data[index], index)
										) : null}
									</div>
								);
							}
					  )}
			</div>
		);
	};

	return (
		<div className={styles.virtualTableWrap}>
			{renderHeader()}
			<div
				ref={ref}
				className={cn(styles.listWrap, listClass, {
					[styles.listWrapEmpty]: !dataSource.length
				})}
			>
				{dataSource.length ? (
					<List
						itemCount={dataSourceWithFooter.length}
						height={size?.height ?? 0}
						width={size?.width ?? 0}
						itemSize={itemSize ?? ROW_HEIGHT}
						// @ts-ignore
						itemData={dataSourceWithFooter}
						// @ts-ignore
						itemKey={(index: number, data: DS) =>
							// @ts-ignore
							data[index]?.id ?? index
						}
					>
						{renderRow}
					</List>
				) : (
					<EmptyText locale={locale} />
				)}
			</div>
			{renderTotal && (
				<TotalCount
					count={
						!dataSource.length || dataSource.length === 1
							? 0
							: dataSource.length - 1
					}
				/>
			)}
		</div>
	);
}

export default VirtualTable;
