import * as yup from 'yup';
import {
	getIsValidPassword,
	ValidationErrorPasswordIncludesCharacters,
	ValidationTestName,
	WHITESPACE_PATTERN
} from 'Helpers/validations';
import { nospace, password } from 'Helpers/validations/validations.helpers';

const passwordSchema = yup
	.string()
	.min(8)
	.max(20)
	.test(
		'is-no-space',
		nospace,
		(value = '') => !value.match(WHITESPACE_PATTERN)
	);

export const VALIDATION = {
	login: yup
		.string()
		.min(2)
		.max(50)
		.test(
			'is-no-space',
			nospace,
			(value = '') => !value.match(WHITESPACE_PATTERN)
		)
		.label('user.cognitoLogin')
		.required(),
	temporaryPassword: passwordSchema.required(),
	password: passwordSchema
		.test(
			ValidationTestName.PasswordIncludesCharacters,
			password,
			(value, context) => {
				const { info, isValid } = getIsValidPassword(value);
				if (!isValid) {
					const errorOptions: Pick<
						ValidationErrorPasswordIncludesCharacters,
						'params'
					> = {
						params: {
							info
						}
					};
					return context.createError(errorOptions);
				}
				return true;
			}
		)
		.required(),
	confirmPassword: passwordSchema
		.oneOf([yup.ref('password'), null], (params) => ({
			key: 'util.validation.validationMessage.confirmPassword.oneOf',
			params
		}))
		.test({
			name: 'is-valid',
			exclusive: false,
			message: password,
			test: (value) => getIsValidPassword(value).isValid
		})
		.required()
};
