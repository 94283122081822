import { ApolloError } from '@apollo/client';
import { APIErrorCodes } from 'app-types';
import { useTranslation, Trans } from 'react-i18next';
import { checkApolloError } from 'Helpers/graphql';
import ErrorScreenLayout, { ErrorScreenLayoutProps } from './ErrorScreenLayout';

type ErrorScreenBaseProps = Omit<ErrorScreenLayoutProps, 'translationPath'>;
type ErrorScreenProps = ErrorScreenBaseProps & {
	error: ApolloError | undefined;
};

type ErrorScreenInterface = React.FC<ErrorScreenProps> & {
	NotFound: typeof NotFound;
	InternalError: typeof InternalError;
	ErrorPermission: typeof ErrorPermission;
  ErrorTokenExpired: typeof ErrorTokenExpired
};

const ErrorScreen: ErrorScreenInterface = ({ error, ...props }) => {
	const apolloError = checkApolloError(error);
	if (apolloError.is(APIErrorCodes.Forbidden)) {
		return <ErrorPermission {...props} />;
	}
	if (apolloError.is(APIErrorCodes.NotFound)) {
		return <NotFound {...props} />;
	}
	if (apolloError.is(APIErrorCodes.Internal)) {
		return <InternalError {...props} />;
	}
	return null;
};

const NotFound = (props: ErrorScreenBaseProps) => {
	return <ErrorScreenLayout {...props} translationPath="notFound" />;
};

const InternalError = (props: ErrorScreenBaseProps) => {
	return <ErrorScreenLayout {...props} translationPath="internalError" />;
};

const ErrorPermission = (props: ErrorScreenBaseProps) => {
	return <ErrorScreenLayout {...props} translationPath="permissions" />;
};

const ErrorTokenExpired = (props: ErrorScreenBaseProps) => {
  const {t} = useTranslation()
	return <ErrorScreenLayout {...props} translationPath="downloadReportTokenExpired">
         <p>
            {t('downloadReportTokenExpired.descriptionFirstParagraph')}
         </p>
         <Trans 
          i18nKey='downloadReportTokenExpired.descriptionSecondParagraph' 
          components={
            {
              // eslint-disable-next-line
              email: <a target='_blank' href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}`} />,
              // eslint-disable-next-line
              request: <a target='_blank' href={process.env.REACT_APP_VENZA_TICKET_SUPPORT}/>,
              emailAddress: process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS
            }
          }
         
         />

  </ErrorScreenLayout>;
};

ErrorScreen.NotFound = NotFound;
ErrorScreen.InternalError = InternalError;
ErrorScreen.ErrorPermission = ErrorPermission;
ErrorScreen.ErrorTokenExpired = ErrorTokenExpired

export default ErrorScreen;
