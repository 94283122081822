import { InputValue } from './PasswordInput.types';

const DOT = '•';
export function manageInputValue(
	origin: string,
	showPassword: boolean
): InputValue {
	return {
		origin,
		dots: showPassword
			? origin
			: origin
					.split('')
					.map(() => DOT)
					.join('')
	};
}

interface GetChangeAndStartOutput {
	diff: string;
	start: number;
}

export function getChangeAndStart(val: string): GetChangeAndStartOutput {
	let start = 0;
	let diff = '';

	for (let i = 0; i < val.length; i += 1) {
		if (val[i] !== DOT) {
			diff += val[i];
			if (start !== null) {
				start = i;
			}
		}
	}

	return {
		start,
		diff
	};
}
