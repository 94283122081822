import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { isEqual } from 'lodash';
import {
	AddUsersFormFieldBaseProps,
	AddUsersFormValues,
	AddUser
} from '../AddUsers.types';
import { EditUserValues } from 'Pages/User/User.types';
import { SelectProps } from 'Components/Form/Select/Select';
import { useGetUserValues } from '../AddUsers.hooks';

type FieldRole = 'userRoleId' | 'jobClassId';

export interface ModuleSettingsFieldProps {
	onDisabled: () => void;
	currentValue: number | string | undefined;
	currentError: string | undefined;
	onChange: SelectProps['onChange'];
	userValues: Pick<
		EditUserValues,
		'primarySystemRoleId' | 'groups' | 'moduleSettings'
	>;
	moduleSettings: AddUser['moduleSettings'];
}

interface BusinessProps extends AddUsersFormFieldBaseProps {
	children: (fieldProps: ModuleSettingsFieldProps) => JSX.Element;
	fieldRole: FieldRole;
}
export const ModuleSettingsBusiness = ({
	children,
	fieldKey,
	fieldRole
}: BusinessProps) => {
	const {
		getValues,
		setValue,
		formState: { errors }
	} = useFormContext<AddUsersFormValues>();
	const values = getValues();
	const key = Number(fieldKey);
	const user = values.users[key];
	const currentValue = user.moduleSettings?.[fieldRole];

	const currentError =
		// @ts-ignore
		errors?.users?.[key]?.moduleSettings?.[fieldRole]?.type;
	const onChange: SelectProps['onChange'] = useCallback(
		(value) => {
			setValue(
				`users.${key}.moduleSettings`,
				{ ...user.moduleSettings, [fieldRole]: value },
				{
					shouldValidate: true
				}
			);
		},
		// eslint-disable-next-line
		[key, fieldRole, user]
	);

	const onDisabled = useCallback(() => {
		setValue(
			`users.${key}.moduleSettings`,
			{ ...user.moduleSettings, [fieldRole]: undefined },
			{
				shouldValidate: true
			}
		);
		// eslint-disable-next-line
	}, [key]);

	const userValues = useGetUserValues(user);

	return children({
		onDisabled,
		currentValue,
		currentError,
		onChange,
		userValues,
		moduleSettings: user.moduleSettings
	});
};
export const areEqual = (
	prev: ModuleSettingsFieldProps,
	curr: ModuleSettingsFieldProps
) => {
	return (
		prev.currentError === curr.currentError &&
		prev.currentValue === curr.currentValue &&
		isEqual(prev.userValues, curr.userValues) &&
		isEqual(prev.moduleSettings, curr.moduleSettings)
	);
};
