import cn from 'classnames';
import { Fx, Layout } from 'Components';
import InternationalizationDropdown from 'Pages/App/AppLayout/AppHeader/InternationalizationDropdown/InternationalizationDropdown';
import AppLayout from 'Pages/App/AppLayout/AppLayout';
import styles from './AuthLayout.module.scss';
import { ReactComponent as Logo } from './images/logo.svg';

interface AuthLayoutProps {
	wide?: boolean;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, wide }) => {
	return (
		<AppLayout
			className={styles.root}
			header={
				<Fx align="middle" justify="end" fullHeight>
					<InternationalizationDropdown />
				</Fx>
			}
			headerTheme="light"
			contentAddon={<Layout.MountainView />}
		>
			<div className={cn(styles.auth, wide && styles.wideBox)}>
				<div className={styles.logo}>
					<Logo />
				</div>
				<div className={cn(styles.box, wide && styles.wideBox)}>
					{children}
				</div>
			</div>
		</AppLayout>
	);
};

export default AuthLayout;
