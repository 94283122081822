import { System, GetSystemsResponse } from 'Services/Api/System/Types';
import SYSTEM_API from 'Services/Api/System/Api';
import { axiosClient } from 'Services/Api/client';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Status } from 'app-types';

class SystemsStore {
	systems: System[] = [];
	systemsStatusRequest: Status = Status.Idle;
	constructor() {
		makeObservable(this, {
			systems: observable,
			systemsStatusRequest: observable
		});
	}

	@action
	async loadSystems() {
		try {
			const { data } = await axiosClient.get<GetSystemsResponse>(
				SYSTEM_API.GET_SYSTEMS
			);
			runInAction(() => {
				this.systems = data;
				this.systemsStatusRequest = Status.Success;
			});
		} catch (error) {
			runInAction(() => {
				this.systemsStatusRequest = Status.Failure;
			});
		}
	}

	getSystemByName(systemName: string): System | undefined {
		return this.systems.find((system) => system.systemName === systemName);
	}
}

export const systemsStore = new SystemsStore();

export default SystemsStore;
