import { useRef, useEffect } from 'react';

export function usePrevious<T = unknown>(value: T) {
	const ref = useRef<T>();

	useEffect(() => {
		ref.current = value;
	}, [value]);

	// Return previous value (happens before update in useEffect)
	return ref.current;
}
