import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Notification } from 'Components';
import { useModal } from 'Hooks/useModal';
import { isNumber } from 'lodash';
import { MOVE_GROUP } from 'Services/Api/Groups/Mutations';
import { Group, MoveGroupRequest } from 'Services/Api/Groups/Types';
import { MoveGroupModalProps } from './MoveGroupModal';
import { checkApolloError } from 'Helpers/graphql';
import { APIErrorCodes } from 'app-types';
import { useConfigs } from 'Hooks';
import { GroupTreeInitialValue } from './GroupModal/GroupTree';
import { DataTreeNodeItem } from 'Contexts/DataTree/DataTree.types';

export const useMoveGroupModal = (props: MoveGroupModalProps) => {
	const { t } = useTranslation();
	const { modal } = useModal();
	const { hideModal } = useModal();
	const [moveGroup] = useMutation<MoveGroupRequest>(MOVE_GROUP, {
		ignoreResults: false
	});
	const modalValue = modal?.value as GroupTreeInitialValue;
	const configs = useConfigs();

	const onSubmit = async (value: DataTreeNodeItem<Group>[] | undefined) => {
		try {
			const selectedItem = value?.[0];
			if (
				!selectedItem ||
				!modalValue.disabledValue ||
				!isNumber(selectedItem.key) ||
				!isNumber(modalValue.disabledValue.key)
			) {
				throw Error('Validation error');
			}
			const variables: MoveGroupRequest = {
				input: {
					groupId: modalValue.disabledValue.key,
					newParentGroupId: selectedItem.key
				}
			};
			await moveGroup({
				variables
			});

			hideModal();
			props.onSubmit();
			Notification.success({
				description: t(
					'groups.groupDetails.components.moveGroupModal.notification'
				)
			});
		} catch (error) {
			const apolloError = checkApolloError(error);
			if (
				apolloError.is(APIErrorCodes.GroupHierarchyDepthLImitExceeded)
			) {
				Notification.error({
					description: configs.message
				});
			} else if (apolloError.is(APIErrorCodes.GroupCannotBeProperty)) {
				Notification.error({
					description: t(
						`groups.groupDetails.components.moveGroupModal.errorCodes.${[
							APIErrorCodes.GroupCannotBeProperty
						]}`
					)
				});
			} else if (
				apolloError.is(APIErrorCodes.MovingBetweenTenantsForbidden)
			) {
				Notification.error({
					description: t(
						`errorCodes.${APIErrorCodes.MovingBetweenTenantsForbidden}`
					)
				});
			} else {
				const errors = apolloError.getNonFieldErrors(
					t,
					'groups.groupDetails.components.moveGroupModal.errorCodes',
					{
						[APIErrorCodes.GroupsHierarchySizeLimitExceeded]: {
							groupsHierarchySizeLimit: String(
								configs.groupsHierarchySizeLimit
							)
						}
					}
				);

				if (errors.length) {
					Notification.error({
						description: errors
					});
					return;
				}
				Notification.error({
					description: t('errorCodes.genericErrorMessage')
				});
			}
		}
	};

	return {
		modalValue,
		onSubmit
	};
};
