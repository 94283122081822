import { Button, Fx, Icon, Input, Layout, Select, Tooltip } from 'Components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportFileType } from 'Services/Api/Reports/Types';
import {
	REPORT_FIELD_LABEL,
	REPORT_FILE_TYPES,
	REPORT_FILE_TYPES_ALL
} from './EditReport.constants';
import { ReportFormValues } from './schema';
import styles from './EditReport.module.scss';

function General() {
	const { t } = useTranslation();
	const { initialValues } = useFormikContext<ReportFormValues>();
	const isFileTypeDisabled = initialValues.fileType === ReportFileType.Pdf;

	return (
		<Layout.List.ListItem title={t('general')}>
			<Layout.List.Section>
				<Fx
					justify="space-between"
					align="middle"
					className={styles.inputSuffixAside}
				>
					<Input.FormikField
						name="name"
						label={t(REPORT_FIELD_LABEL.name)}
						required
						fullWidth
						suffix={
							<Tooltip
								title={t('report.general.reportName.tooltip')}
								placement="top"
							>
								<Button type="default" text>
									<Icon type="infoOutlined" size="xl" />
								</Button>
							</Tooltip>
						}
					/>
				</Fx>
				<Select.FormikField
					name="fileType"
					label={t(REPORT_FIELD_LABEL.fileType)}
					required
					disabled={isFileTypeDisabled}
				>
					{(isFileTypeDisabled
						? REPORT_FILE_TYPES_ALL
						: REPORT_FILE_TYPES
					).map((opt) => (
						<Select.Option key={opt.value} value={opt.value}>
							{t(opt.label)}
						</Select.Option>
					))}
				</Select.FormikField>
			</Layout.List.Section>
		</Layout.List.ListItem>
	);
}

export default General;
