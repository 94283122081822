import {
	default as GroupsFieldComponent,
	GroupsFieldProps as GroupsFieldComponentProps
} from 'Pages/User/components/GroupsField/GroupsField';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import {
	AddUsersFormFieldBaseProps,
	AddUsersFormValues
} from '../AddUsers.types';
import AllGroupsField from 'Pages/User/components/AllGroupsField';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import styles from '../AddUsers.module.scss';

function GroupsField({ fieldKey, className }: AddUsersFormFieldBaseProps) {
	const {
		setValue,
		getValues,
		formState
	} = useFormContext<AddUsersFormValues>();
	const values = getValues();

	const key = Number(fieldKey);
	const value = values.users[key];
	const name = `users.${key}.groups`;

	const onSubmit: GroupsFieldComponentProps['onSubmit'] = useCallback(
		(value) => {
			// @ts-ignore
			setValue(name, value, { shouldValidate: true });
		},
		// eslint-disable-next-line
		[name]
	);

	if (
		value.primarySystemRoleId?.value === SystemRoleType.SuperAdmin ||
		value.primarySystemRoleId?.data.role === SystemRoleType.SuperAdmin
	) {
		// @ts-ignore
		setValue(name, []);
		return (
			<AllGroupsField label={null} className={styles.addUsersSelect} />
		);
	}

	return (
		<GroupsFieldComponent
			error={Boolean(formState.errors.users?.[key]?.groups)}
			value={value?.groups}
			onSubmit={onSubmit}
			className={className}
		/>
	);
}

export default GroupsField;
