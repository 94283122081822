import { Pagination as AntPagination, PaginationProps } from 'antd';
import { APP_CONFIG } from 'app-config';
import cn from 'classnames';
import TotalCount from 'Components/Table/TotalCount';
import styles from './Pagination.module.scss';

function Pagination({
	showSizeChanger = true,
	hideOnSinglePage = false,
	pageSizeOptions = APP_CONFIG.TABLE_PAGINATION_SIZE,
	...props
}: PaginationProps) {
	return (
		<AntPagination
			{...props}
			className={cn(props.className, styles.root)}
			showTotal={(total) => (
				<TotalCount count={total} className={styles.totalCount} />
			)}
			pageSizeOptions={pageSizeOptions}
			hideOnSinglePage={hideOnSinglePage}
			showSizeChanger={showSizeChanger}
		/>
	);
}

export default Pagination;
