import { Menu } from 'antd';
import { Button, Dropdown, Icon, Notification } from 'Components';
import { usePermission } from 'Components/Permission/Permission.hooks';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, AxiosErrorResponse } from 'app-types';
import {
	ReportType,
	SavedReport,
	DownloadSavedReportRequestWithFileType
} from 'Services/Api/Reports/Types';
import { useModal } from 'Hooks/useModal';
import { useStores } from 'Hooks/useStore';
import { useHandleDownloadError } from 'Hooks';
import { handleAxiosError, getAxiosErrorType } from 'Helpers/api';
import { REPORT_TYPES_PDF_ONLY } from 'Pages/Report/EditReport/EditReport.constants';
import {
	ReportModalAction,
	ReportModalValue
} from '../ReportModals/ReportModals.types';
import { generatePath, useHistory } from 'react-router-dom';
import { getAppRoutes } from 'Pages/App/App.constants';

enum MenuKey {
	Preview = 'Preview',
	Edit = 'Edit',
	Download = 'Download',
	Clone = 'clone',
	Delete = 'Delete'
}

type DropdownMenuItem = MenuItem<MenuKey> & {
	hideForReportTypes?: ReportType[];
};

const MENU_ITEMS: DropdownMenuItem[] = [
	{
		key: MenuKey.Preview,
		title: 'report.preview',
		icon: <Icon type="eye" />,
		hideForReportTypes: REPORT_TYPES_PDF_ONLY
	},
	{
		key: MenuKey.Edit,
		title: 'edit',
		icon: <Icon type="edit" />
	},
	{
		key: MenuKey.Download,
		title: 'download',
		icon: <Icon type="envelope" />
	},
	{
		key: MenuKey.Clone,
		title: 'clone',
		icon: <Icon type="discount" />
	},
	{
		key: MenuKey.Delete,
		title: 'delete',
		icon: <Icon type="delete" />
	}
];

type ActionsButtonProps = {
	report: SavedReport;
};
const ActionsButton = ({ report }: ActionsButtonProps) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { reportStore, downloadReportsStore, authStore } = useStores();
	const { getHasPermission } = usePermission();
	const { showModal } = useModal();
	const handleDownloadError = useHandleDownloadError();
	const [loading, setLoading] = useState(false);

	const handlePreview = async () => {
		try {
			const data = await reportStore.previewReport({
				reportId: report.id
			});

			if (!data) return;

			const modalValue: ReportModalValue = {
				name: ReportModalAction.Preview,
				value: {
					data
				}
			};

			showModal(modalValue);
		} catch (e) {
			const axiosError = e as AxiosErrorResponse;
			const { isNotFoundError } = getAxiosErrorType(axiosError);
			if (authStore.isCurrentUserOwner && isNotFoundError) {
				Notification.error({
					description: t('errorCodes.NO_AVAILABLE_PROPERTIES')
				});
			} else {
				handleAxiosError(axiosError, t);
			}
		}
	};

	const onMenuClick = async (info: { key: React.Key }) => {
		const menuKey = info.key as MenuKey;
		setLoading(true);
		switch (menuKey) {
			case MenuKey.Download:
				try {
					await downloadReportsStore.generateAndDownloadReport({
						reportId: report.id,
						fileType: report.fileFormat
					} as DownloadSavedReportRequestWithFileType);
				} catch (error) {
					const axiosError = error as AxiosErrorResponse;
					handleDownloadError(axiosError);
				}
				break;
			case MenuKey.Preview:
				await handlePreview();
				break;
			case MenuKey.Delete:
				const deleteValue: ReportModalValue = {
					name: ReportModalAction.Delete,
					value: report
				};

				showModal(deleteValue);
				break;
			case MenuKey.Clone:
				const cloneValue: ReportModalValue = {
					name: ReportModalAction.Clone,
					value: report
				};

				showModal(cloneValue);
				break;
			case MenuKey.Edit:
				history.push(
					generatePath(getAppRoutes().EDIT_REPORT, {
						reportId: report.id
					})
				);
				break;
		}
		setLoading(false);
	};

	const menuItems = useMemo(() => {
		return MENU_ITEMS.filter(({ hideForReportTypes, permission }) => {
			const hasPermission =
				!permission || getHasPermission(permission).permitted;

			return (
				hasPermission &&
				!hideForReportTypes?.includes(report.reportType)
			);
		});
	}, [getHasPermission, report.reportType]);

	if (!menuItems.length) {
		return null;
	}

	return (
		<Dropdown
			overlay={
				<Menu onClick={onMenuClick}>
					{menuItems.map((menuItem) => (
						<Menu.Item key={menuItem.key} icon={menuItem.icon}>
							{t(menuItem.title)}
						</Menu.Item>
					))}
				</Menu>
			}
			arrow={false}
		>
			<Button
				shape="round"
				ghost
				type="primary"
				size="small"
				thinVertical
				loading={loading}
			>
				<Icon type="more" />
			</Button>
		</Dropdown>
	);
};

export default ActionsButton;
