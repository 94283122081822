import { TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { Fx, Layout, Table } from 'Components';
import { createSortDescription } from 'Components/Table/createColumns';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { LayoutHolder, DownloadReportButton } from 'Pages/Dashboard/components';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PhishingActiveCampaign } from 'Services/Api/Dashboard/Types';
import { ReportType } from 'Services/Api/Reports/Types';
import { getIsPending } from 'Stores/util';
import { useColumns } from './columns';

function ActiveCampaigns() {
	const { t } = useTranslation();
	const { dashboardStore } = useStores();
	const { filters } = useDashboardContext();
	const {
		phishing: { activeCampaigns },
		status
	} = dashboardStore;

	const handleTableChange: TableProps<PhishingActiveCampaign>['onChange'] = (
		_paginationConfig,
		_filters,
		sorter
	) => {
		const sort = createSortDescription<PhishingActiveCampaign>(
			sorter as SorterResult<PhishingActiveCampaign>
		);

		dashboardStore.getPhishingActiveCampaigns({
			...activeCampaigns.params,
			sort
		});
	};

	useEffect(() => {
		dashboardStore.getPhishingActiveCampaigns(filters);
		// eslint-disable-next-line
	}, [filters]);

	return (
		<LayoutHolder>
			<Layout>
				<Layout.Heading>
					<Fx align="middle" justify="space-between">
						<h3>
							{t('dashboard.tabs.phishing.activeCampaigns.title')}
						</h3>
						<DownloadReportButton
							reportType={ReportType.ActiveCampaigns}
						/>
					</Fx>
				</Layout.Heading>
				<Table<PhishingActiveCampaign>
					dataSource={activeCampaigns.data}
					rowKey="name"
					columns={useColumns({
						params: activeCampaigns.params
					})}
					pagination={false}
					onChange={handleTableChange}
					loading={getIsPending(status.getPhishingActiveCampaigns)}
				/>
			</Layout>
		</LayoutHolder>
	);
}

export default observer(ActiveCampaigns);
