import { useMemo } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { PreviewReportProps } from '../PreviewReport.types';
import { Table } from 'Components';
import { adaptPropsDataToDataSource } from '../PreviewReport.helpers';

interface PreviewReportTableComponentProps<RT> {
	data: PreviewReportProps['data'];
	columns: ColumnProps<RT>[];
}

function PreviewReportTableComponent<RT extends { uuid: string }>(
	props: PreviewReportTableComponentProps<RT>
) {
	const dataSource = useMemo(
		// `index` parameter of `rowKey` function in <Table /> is deprecated.
		// There is no guarantee that it will work as expected.
		() => adaptPropsDataToDataSource((props.data.data as unknown) as RT[]),
		[props.data.data]
	);

	if (!props.columns) return null;

	return (
		<Table
			dataSource={(dataSource as unknown) as RT[]}
			rowKey="uuid"
			columns={props.columns}
			pagination={false}
		/>
	);
}

export default PreviewReportTableComponent;
