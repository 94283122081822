import { generatePath, useHistory } from 'react-router-dom';

import { getAppRoutes } from 'Pages/App/App.constants';
import { StandardReportListItem, ReportType } from 'Services/Api/Reports/Types';
import { STANDARD_REPORT_SECTION_LABEL } from './Standard.constants';
import styles from './Standard.module.scss';
import StandardReport from './StandardReport';
import { useEffect, useMemo } from 'react';
import { useStores } from 'Hooks/useStore';
import { getIsPending } from 'Stores/util';
import { Spin } from 'Components';
import { observer } from 'mobx-react-lite';
import { groupBy, map } from 'lodash';
import { usePermission } from 'Components/Permission/Permission.hooks';
import { PermissionCode } from 'Services/Api/Users/Types';
import { useTranslation, withTranslation } from 'react-i18next';

function Standard() {
	const { t } = useTranslation();
	const history = useHistory();
	const { getHasPermission } = usePermission();
	const { reportsStore } = useStores();
	const { standardReports, status } = reportsStore;
	const isAddAvailable = getHasPermission({ code: PermissionCode.AddReport })
		.permitted;

	const reports = useMemo(() => {
		return groupBy(standardReports, (report) => report.category);
	}, [standardReports]);

	useEffect(() => {
		reportsStore.getStandardReports();
		// eslint-disable-next-line
	}, []);

	const handleStartdardReportClick = (type: ReportType) => {
		history.push(generatePath(getAppRoutes().ADD_REPORT, { type }));
	};

	if (getIsPending(status.getStandardReports)) {
		return <Spin />;
	}

	return (
		<div className={styles.root}>
			{map(
				reports,
				(reports, key: StandardReportListItem['category']) => (
					<div key={key}>
						<h2>{t(STANDARD_REPORT_SECTION_LABEL[key])}</h2>
						<div className={styles.reports}>
							{reports.map(({ type }) => (
								<StandardReport
									key={type}
									reportType={type}
									onClick={
										isAddAvailable
											? handleStartdardReportClick
											: undefined
									}
								/>
							))}
						</div>
					</div>
				)
			)}
		</div>
	);
}

export default withTranslation()(observer(Standard));
