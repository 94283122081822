import { memo } from 'react';
import { UserRoleField as UserRoleFieldComponent } from 'Pages/User/components';
import { AddUsersFormFieldBaseProps } from '../AddUsers.types';
import ModuleSettingsContainer from 'Pages/User/EditUser/tabs/ModuleSettings/ModuleSettingsContainer';
import {
	ModuleSettingsBusiness,
	ModuleSettingsFieldProps,
	areEqual
} from './ModuleSettingsBusiness';
import styles from '../AddUsers.module.scss';

const RoleField = memo<ModuleSettingsFieldProps>(
	({ userValues, onDisabled, currentValue, currentError, onChange }) => {
		return (
			<ModuleSettingsContainer
				values={userValues}
				onDisabled={onDisabled}
			>
				{(disabled) => (
					<UserRoleFieldComponent
						label={false}
						value={currentValue}
						onChange={onChange}
						disabled={disabled}
						error={currentError}
						className={styles.addUsersSelect}
						blockHelp
					/>
				)}
			</ModuleSettingsContainer>
		);
	},
	areEqual
);

function UserRoleField({ className, ...props }: AddUsersFormFieldBaseProps) {
	return (
		<ModuleSettingsBusiness {...props} fieldRole="userRoleId">
			{(props) => <RoleField {...props} />}
		</ModuleSettingsBusiness>
	);
}

export default UserRoleField;
