import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Checkbox, Layout } from 'Components';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionGroup } from 'Services/Api/Roles/Types';
import { PERMISSION_GROUP_HEADING_BY_NAME } from '../../EditRole.constants';
import { EditRoleFormValues } from '../../EditRole.types';
import styles from './Permissions.module.scss';

interface PermissionsGroupProps {
	group: PermissionGroup;
}
function PermissionsGroup({ group }: PermissionsGroupProps) {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext<EditRoleFormValues>();

	const defaultCheckAll = group.permissions.every(
		(perm) => values.permissionIds[perm.id]
	);
	const [checkAll, setCheckAll] = useState(defaultCheckAll);

	const onCheckAll = (event: CheckboxChangeEvent) => {
		const { checked } = event.target;
		const newPermissions = group.permissions.reduce<
			EditRoleFormValues['permissionIds']
		>((acc, value) => {
			acc[value.id] = checked;
			return acc;
		}, {});

		setFieldValue('permissionIds', {
			...values.permissionIds,
			...newPermissions
		});
		setCheckAll(checked);
	};

	const onCheck = (event: CheckboxChangeEvent) => {
		const { checked, name } = event.target;
		const id = Number(name);
		const newPermissions: EditRoleFormValues['permissionIds'] = {
			...values.permissionIds,
			[id]: checked
		};

		setFieldValue('permissionIds', newPermissions);
		setCheckAll(group.permissions.every((perm) => newPermissions[perm.id]));
	};

	return (
		<Layout.List.Section key={group.id}>
			<Checkbox onChange={onCheckAll} checked={checkAll}>
				<h3>{t(PERMISSION_GROUP_HEADING_BY_NAME[group.name])}</h3>
			</Checkbox>
			<div className={styles.checkboxGroup}>
				{group.permissions.map((perm) => (
					<div key={perm.id}>
						<Checkbox
							name={String(perm.id)}
							className={styles.checkbox}
							onChange={onCheck}
							checked={values.permissionIds[perm.id]}
						>
							{perm.code}
						</Checkbox>
					</div>
				))}
			</div>
		</Layout.List.Section>
	);
}

export default PermissionsGroup;
