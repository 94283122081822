import { USER_ROLE_NAME_MAP } from 'app-config';
import { AxiosError } from 'axios';

// eslint-disable-next-line
export const isResponseError = <T = AxiosError>(error: any): error is T => {
	return error instanceof Error;
};

export const getUserRoleLabel = (role: string) => {
	return USER_ROLE_NAME_MAP[role] || role;
};
