import { Fx } from 'Components';
import { useTranslation } from 'react-i18next';

const STATUS_MESSAGE = {
	404: 'Components.errorPage.404'
};
interface ErrorPageProps {
	status?: keyof typeof STATUS_MESSAGE;
}

const ErrorPage: React.FC<ErrorPageProps> = (props) => {
	const { t } = useTranslation();
	const tStatus = props.status ? STATUS_MESSAGE[props.status] : undefined;
	return (
		<Fx justify="center" align="middle" fullHeight>
			{tStatus ? t(tStatus) : props.children}
		</Fx>
	);
};

export default ErrorPage;
