import { GroupActiveStatus } from 'Services/Api/Groups/Types';
import { UserLoginStatus } from 'Services/Api/Users/Types';
import i18n from 'i18n';
import { TagProps } from 'Components/Tag/Tag';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import { APIErrorCodes } from 'app-types';

export const APP_CONFIG = {
	TIME_FORMAT: 'HH:mm',
	MONTH_FORMAT: 'MMM',
	QUARTER_FORMAT: 'Qo',
	DATE_FORMAT: 'YYYY-MM-DD',
	DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm',
	DEFAULT_TIME: '08:00',
	SEARCH_MIN_LENGTH: 3,
	IDLE_TIMEOUT: 900000,
	TABLE_PAGE_SIZE: 25,
	TABLE_DEFAULT_PAGINATION_PAGE: 1,
	TABLE_PAGINATION_SIZE: ['10', '25', '50', '100'],
	// @NOTE: that appSync has 30 sec execution timeout limit: https://repost.aws/questions/QUw61RAiFGSHiWKxSnjppUfA/execution-timeout-aws-appsync-api
	DOWNLOAD_MAX_MS: Number(process.env.REACT_APP_DOWNLOAD_MAX_MS),
	I18N_ERROR_KEY: 'errorCodes'
};

export const GROUP_ACTIVE_STATUS_TAG_MAP: {
	[key: string]: TagProps['type'];
} = {
	[GroupActiveStatus.Active]: 'success',
	[GroupActiveStatus.Inactive]: 'default'
};

export const USER_DELETED_LABEL = {
	YES: () => i18n.t('appConfig.deletedLabel.yes'),
	NO: () => i18n.t('appConfig.deletedLabel.no')
};

export const USER_ACTIVE_LABEL = {
	YES: () => i18n.t('appConfig.active'),
	NO: () => i18n.t('appConfig.inactive')
};

export const GROUP_ACTIVE_STATUS_LABEL_MAP = {
	[GroupActiveStatus.Active]: USER_ACTIVE_LABEL.YES,
	[GroupActiveStatus.Inactive]: USER_ACTIVE_LABEL.NO
};

export const USER_LOGIN_STATUS_LABEL = {
	[UserLoginStatus.LockedOut]: () => i18n.t('options.loginStatus.lockedOut'),
	[UserLoginStatus.ActivationPending]: () =>
		i18n.t('options.loginStatus.activationPending')
};

export const USER_LOGIN_STATUS_TAG_MAP: {
	[key: string]: TagProps['type'];
} = {
	[UserLoginStatus.LockedOut]: 'warning',
	[UserLoginStatus.ActivationPending]: 'primary'
};

export const PROPERTY_LABEL = {
	YES: () => i18n.t('appConfig.propertyLabel.yes'),
	NO: () => i18n.t('appConfig.propertyLabel.no')
};

export const DELETED_LABEL = {
	YES: () => i18n.t('appConfig.deletedLabel.yes'),
	NO: () => i18n.t('appConfig.deletedLabel.no')
};

export const ARCHIVED_LABEL = {
	YES: () => i18n.t('appConfig.archivedLabel.yes'),
	NO: () => i18n.t('appConfig.archivedLabel.no')
};

export const USER_ROLE_NAME_MAP: Record<string, string> = {
	[SystemRoleType.Admin]: 'Admin',
	[SystemRoleType.Manager]: 'Manager',
	[SystemRoleType.User]: 'User',
	[SystemRoleType.Owner]: 'Owner',
	[SystemRoleType.SuperAdmin]: 'Super Admin'
};

export const USER_ROLE_LIST = Object.values(SystemRoleType);
export const GROUP_ACTIVE_STATUS_LIST = Object.values(GroupActiveStatus);

export const COGNITO_ERROR_MSG = {
	DEACTIVATED: `PreAuthentication failed with error ${APIErrorCodes.AccountDeactivated}.`,
	DISABLED: 'User is disabled.',
	FORBIDDEN: `PreTokenGeneration failed with error ${APIErrorCodes.Forbidden}.`,
	TEMPORARY_PASSWORD_EXPIRED:
		'Temporary password has expired and must be reset by an administrator.',
	PASSWORD_RESET_REQUIRED_FOR_THE_USER:
		'Password reset required for the user',
	INVALID_SESSION_FOR_THE_USER: 'Invalid session for the user.',
	INVALID_SESSION_SESSION_IS_EXPIRED:
		'Invalid session for the user, session is expired.'
};

export const COGNITO_ERROR_MSG_TO_DESCRIPTION_MAP = {
	[COGNITO_ERROR_MSG.DEACTIVATED]: 'auth.login.errors.deactivated',
	[COGNITO_ERROR_MSG.DISABLED]: 'auth.login.errors.disabled',
	[COGNITO_ERROR_MSG.FORBIDDEN]: 'auth.login.errors.forbidden',
	[COGNITO_ERROR_MSG.TEMPORARY_PASSWORD_EXPIRED]:
		'auth.login.errors.temporaryPasswordExpired',
	[COGNITO_ERROR_MSG.PASSWORD_RESET_REQUIRED_FOR_THE_USER]:
		'auth.login.errors.passwordResetRequired',
	[COGNITO_ERROR_MSG.INVALID_SESSION_FOR_THE_USER]:
		'auth.login.errors.invalidSessionForTheUser',
	[COGNITO_ERROR_MSG.INVALID_SESSION_SESSION_IS_EXPIRED]:
		'auth.login.errors.invalidSessionSessionIsExpired'
};

export const TERMS_OF_USE_LINK = 'https://www.venzagroup.com/terms-of-use/';

export const PRIVACY_POLICY_LINK = 'https://www.venzagroup.com/privacy-policy/';
// @NOTE: such error allows to describe errors related to uniq naming
export const UNIQUE_ERROR_TYPE = 'UNIQUE';
export const RECREATED_ERROR_TYPE = 'RECREATED_ERROR_TYPE';
export const UNIQUE_SYNC_CODE = 'UNIQUE_SYNC_CODE';
export const API_DEFINED_ERROR_TYPE = 'API_DEFINED_ERROR_TYPE';
