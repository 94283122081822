import MockAdapter from 'axios-mock-adapter/types';
import moment from 'moment';
import { GroupActiveStatus } from '../Groups/Types';
import {
	PreviewReportResponse,
	ReportDisplayResultsBy,
	ReportType
} from './Types';

const mockReports = (_mock: MockAdapter) => {
	/*mock.onGet(
		new RegExp(
			`^${REPORTS_API.PREVIEW_REPORT}(.*?)reportType=((?!${ReportType.ElearningGroupSummary}|${ReportType.UserActivity}|${ReportType.Roles}).)*$`
		)
	).reply<PreviewReportResponse>((config) => {
		const params = config.params as PreviewReportRequest;
		const mockedValue = {
			...MOCKED_PREVIEWS[params.reportType],
			name: params.name
		};
		return [200, config.data ?? mockedValue];
	});*/
};

// eslint-disable-next-line
const MOCKED_PREVIEWS: Partial<Record<ReportType, PreviewReportResponse>> = {
	[ReportType.SpotcheckGroupSummary]: {
		reportType: ReportType.SpotcheckGroupSummary,
		period: {
			from: moment().toISOString(),
			to: moment().subtract(3, 'M').toISOString()
		},
		name: '',
		displayResultsBy: ReportDisplayResultsBy.Month,
		data: [
			{
				groupperiod: 'Jan',
				current: 44,
				groupId: 43455,
				groupName: 'Reporting Tenant',
				last: 87,
				variance: -43
			},
			{
				groupperiod: 'Feb',
				current: 44,
				groupId: 43456,
				groupName: 'Reporting Tenant',
				last: 87,
				variance: -43
			},
			{
				groupperiod: 'Mar',
				current: 44,
				groupId: 43457,
				groupName: 'Reporting Tenant',
				last: 87,
				variance: -43
			}
		]
	},
	[ReportType.PhishingGroupSummary]: {
		reportType: ReportType.PhishingGroupSummary,
		period: {
			from: moment().toISOString(),
			to: moment().subtract(3, 'M').toISOString()
		},
		name: '',
		displayResultsBy: ReportDisplayResultsBy.Year,
		data: [
			{
				groupperiod: '2021',
				attachmentsOpened: 23,
				dataSubmit: 16,
				emailsClicked: 39,
				emailsOpen: 51,
				emailsSent: 56,
				groupName: 'My Tenant',
				score: 30,
				success: 55,
				variance: -13
			},
			{
				groupperiod: '2022',
				attachmentsOpened: 23,
				dataSubmit: 16,
				emailsClicked: 39,
				emailsOpen: 51,
				emailsSent: 56,
				groupName: 'My Tenant',
				score: 30,
				success: 55,
				variance: -13
			}
		]
	},
	[ReportType.ActiveCampaigns]: {
		reportType: ReportType.ActiveCampaigns,
		period: {
			from: moment().toISOString(),
			to: moment().subtract(3, 'M').toISOString()
		},
		name: '',
		data: [
			{
				groupperiod: '2021',
				date: '2022-03-27T13:19:07.896Z',
				email: 'Samsung',
				landing: 'https://www.samsung.com',
				name: 'Samsung',
				score: 30,
				status: 'Completed'
			},
			{
				groupperiod: '2022',
				date: '2022-03-25T13:19:07.896Z',
				email: 'Apple',
				landing: 'https://www.apple.com',
				name: 'Apple',
				score: 30,
				status: 'Completed'
			}
		]
	},
	[ReportType.OverviewGroupSummary]: {
		reportType: ReportType.OverviewGroupSummary,
		period: {
			from: moment().toISOString(),
			to: moment().subtract(3, 'M').toISOString()
		},
		name: '',
		displayResultsBy: ReportDisplayResultsBy.Year,
		data: [
			{
				groupperiod: '2021',
				elearningScore: 50,
				groupName: 'Reporting Tenant',
				indexComposite: 50,
				phishingScore: 75,
				spotCheckScore: 25
			}
		]
	},
	[ReportType.ElearningGroupSummary]: {
		reportType: ReportType.ElearningGroupSummary,
		period: {
			from: moment().toISOString(),
			to: moment().subtract(3, 'M').toISOString()
		},
		name: '',
		displayResultsBy: ReportDisplayResultsBy.Month,
		data: [
			{
				groupperiod: 'Jan',
				complete: 87,
				enrollments: 105,
				groupName: 'Reporting Tenant',
				id: 43455,
				inProgress: 9,
				notStarted: 9,
				score: 50,
				users: 21
			}
		]
	},
	[ReportType.Roles]: {
		reportType: ReportType.Roles,
		period: {
			from: moment().toISOString()
		},
		name: '',
		data: [
			{
				createdDate: '2021-12-14T14:40:15.783Z',
				id: 50,
				permissionsCount: 4,
				role: '2nd',
				usersCount: 3
			},
			{
				createdDate: '2021-12-14T14:40:15.783Z',
				id: 51,
				permissionsCount: 4,
				role: '2nd',
				usersCount: 0
			}
		]
	},
	[ReportType.Groups]: {
		reportType: ReportType.Groups,
		period: {
			from: moment().toISOString()
		},
		name: '',
		data: [
			{
				id: 57,
				name: 'B&BssssSS',
				parentId: null,
				syncCode: '7654',
				active: GroupActiveStatus.Active,
				isProperty: false,
				isArchived: true,
				level: 1,
				moduleSettings: null,
				deletedDate: null,
				canBeProperty: false,
				parents: []
			},
			{
				id: 58,
				name: 'B&BssssSS',
				parentId: null,
				syncCode: '7654',
				active: GroupActiveStatus.Active,
				isProperty: false,
				isArchived: true,
				level: 1,
				moduleSettings: null,
				deletedDate: null,
				canBeProperty: false,
				parents: []
			}
		]
	},

	[ReportType.UserActivity]: {
		reportType: ReportType.UserActivity,
		period: {
			from: moment().toISOString()
		},
		name: '',
		data: []
	}
};

export default mockReports;
