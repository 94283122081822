import { DocumentNode } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ButtonProps, Menu } from 'antd';
import { Button, Dropdown, Icon } from 'Components';
import { useExportButton } from './ExportButton.hooks';
import { MenuItem } from 'app-types';

enum ExportMenuKey {
	CSV
}
const DEFAULT_MENU_ITEMS: MenuItem<ExportMenuKey>[] = [
	{ key: ExportMenuKey.CSV, title: 'appConfig.exportFormats.csv' }
];

export type ExportButtonProps<TData, TVariables> = Pick<
	ButtonProps,
	'disabled' | 'loading' | 'children'
> & {
	onCompleted?: (data: TData) => void;
	// @NOTE: it needs in order to receive download url from query data
	getQueryUrl?: (data: TData) => string;
	menu?: false; // If dropdown menu should be available

	query?: DocumentNode;
	params?: TVariables | undefined;
	onExport?: () => Promise<TData>;
};
function ExportButton<TData, TVariables>(
	props: ExportButtonProps<TData, TVariables>
) {
	const { t } = useTranslation();
	const { handleDownload, loading, disabled } = useExportButton(props);
	const { menu } = props;

	const isMenuHidden = menu === false;

	const buttonProps: ButtonProps = {
		type: 'primary',
		shape: 'round',
		loading,
		disabled,
		onClick: isMenuHidden ? handleDownload : undefined
	};

	// Separated conditions because button wraps string in <span /> and css covers this case
	// but not in case of wrapping with fragment
	const button = props.children ? (
		<Button {...buttonProps}>{props.children}</Button>
	) : (
		<Button {...buttonProps}>
			<Icon type="download" />
			{t('export')}
		</Button>
	);

	if (isMenuHidden) return button;

	return (
		<Dropdown
			trigger={['click']}
			arrow={false}
			overlay={
				<Menu onClick={handleDownload}>
					{DEFAULT_MENU_ITEMS.map((menuItem) => (
						<Menu.Item key={menuItem.key}>
							{t(menuItem.title)}
						</Menu.Item>
					))}
				</Menu>
			}
		>
			{button}
		</Dropdown>
	);
}

export default ExportButton;
