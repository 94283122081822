import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { isFunction } from 'lodash';
import {
	Spin,
	RedirectToLogin,
	RedirectToTwoFactorAuthorization
} from 'Components';
import { useActionTracker } from 'Hooks';
import { TenantUserPoolType } from 'Services/Api/Common/Types';
import { TwoFactorAuthorizationStatus } from 'Services/Api/Users/Types';
import { useHistory, Redirect, generatePath } from 'react-router';
import { useStores } from 'Hooks/useStore';
import { getIsDone, getIsSuccess } from 'Stores/util';
import { APP_ROUTES } from './App.constants';
import { AppWithErrorBoundaryAndLogoutTracker } from './AppWithErrorBoundaryAndLogoutTracker';
import { getAppRoutes } from 'Pages/App/App.constants';
import { AuthState } from '../Auth/Auth.types';

export interface PrivateAppPropsBase {
	includeLayout?: boolean;
}
type PrivateAppProps = PrivateAppPropsBase & {
	children: ((isAuthenticated: boolean) => React.ReactNode) | React.ReactNode;
};

function PrivateApp({ includeLayout = true, children }: PrivateAppProps) {
	const rootStore = useStores();
	const {
		authStore,
		languageStore: { isDefaultResourceLoaded }
	} = rootStore;
	const { location, replace: historyReplace } = useHistory<AuthState>();
	const { sendUserLoggedInAction } = useActionTracker();
	const isMountedRef = useRef(false);

	const isCurrentUserLoaded = getIsSuccess(authStore.status.getCurrentUser);

	useEffect(
		() => {
			if (isCurrentUserLoaded && location.state?.userLoggedIn) {
				location.state = { userLoggedIn: false };
				sendUserLoggedInAction();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isCurrentUserLoaded, location.state?.userLoggedIn]
	);

	useEffect(() => {
		isMountedRef.current = true;
		// We need to check here if user is authenticated, and if not, redirect him to login page
		// No need to cover catch block for now
		authStore.checkAuth();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (authStore.needReloadPostfix) {
			const {
				postfix
			} = authStore.currentUserPoolData as TenantUserPoolType;

			let newPath;

			if (postfix === APP_ROUTES.HOME) {
				newPath =
					APP_ROUTES.HOME +
					location.pathname.split('/').slice(1).join('/');
			} else if (authStore.prevPostfix === APP_ROUTES.HOME) {
				newPath = `/${postfix}${location.pathname}`;
			} else {
				newPath = location.pathname.replace(
					authStore.prevPostfix,
					postfix
				);
			}

			historyReplace(newPath);
			authStore.resetNeedReloadPostfix();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authStore.needReloadPostfix]);

	const isDoneLoading = getIsDone(authStore.status.checkAuth);

	if (!isMountedRef.current || !isDoneLoading || !isDefaultResourceLoaded) {
		return <Spin />;
	}

	const appRoutes = getAppRoutes();
	const twoFactorAuthorizationStatus =
		authStore.currentUser?.twoFactorAuthorizationStatus;
	return (
		<>
			{isFunction(children) ? (
				children(authStore.isAuthenticated)
			) : authStore.isAuthenticated ? (
				authStore.currentUser?.isEmailValidated ? (
					<AppWithErrorBoundaryAndLogoutTracker
						includeLayout={includeLayout}
						blockNavigation={false}
					>
						{children}
					</AppWithErrorBoundaryAndLogoutTracker>
				) : (
					<Redirect
						to={{
							pathname: generatePath(appRoutes.VALIDATE_EMAIL)
						}}
					/>
				)
			) : authStore.isTwoFactorAuthorizationEnabled &&
			  twoFactorAuthorizationStatus !==
					TwoFactorAuthorizationStatus.Blocked ? (
				<RedirectToTwoFactorAuthorization />
			) : (
				<RedirectToLogin />
			)}
		</>
	);
}

export default observer(PrivateApp);
