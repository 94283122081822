import { TableLocale } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { Layout } from 'Components';
import styles from './Table.module.scss';

interface EmptyTextProps {
	loading?: boolean;
	locale?: TableLocale;
}

const EmptyText = ({ loading, locale }: EmptyTextProps) => {
	const { t } = useTranslation();
	return (
		<>
			<div className={styles.emptyText}>
				{loading ? (
					<div />
				) : (
					<>
						{locale?.emptyText || (
							<h3>{t('appConfig.noResultsFound')}</h3>
						)}
						<Layout.MountainView maxHeight={150} />
					</>
				)}
			</div>
		</>
	);
};

export default EmptyText;
