import { ExportButton } from 'Components';
import { ExportButtonProps } from 'Components/ExportButton/ExportButton';
import { EXPORT_USERS } from 'Services/Api/Users/Queries';
import {
	ExportUsersRequest,
	ExportUsersResponse
} from 'Services/Api/Users/Types';

export type UsersExportButtonProps = Omit<
	ExportButtonProps<ExportUsersResponse, ExportUsersRequest>,
	'query' | 'getQueryUrl'
>;
function UsersExportButton(props: UsersExportButtonProps) {
	const getQueryUrl = (data: ExportUsersResponse) => {
		return data.exportUsers.downloadUrl;
	};
	return (
		<ExportButton<ExportUsersResponse, ExportUsersRequest>
			query={EXPORT_USERS}
			getQueryUrl={getQueryUrl}
			{...props}
		/>
	);
}

export default UsersExportButton;
