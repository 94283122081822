import { AxiosErrorResponse } from 'app-types';
import { Notification } from 'Components';
import { downloadLink } from 'Helpers/downloadLink';
import { useStores } from 'Hooks/useStore';
import { useTranslation } from 'react-i18next';
import {
	ReportByTokenDetails,
	ReportByTokenType
} from 'Services/Api/Reports/Types';
import { useHandleDownloadError } from 'Hooks';

export const useDownloadReportButton = (
	report: ReportByTokenDetails | undefined
) => {
	const { t } = useTranslation();
	const { reportStore } = useStores();
	const handleDownloadError = useHandleDownloadError();
	const downloadPdfReport = async () => {
		if (
			!(
				report?.type === ReportByTokenType.ExecutiveSummary ||
				report?.type === ReportByTokenType.RiskScore ||
				report?.type === ReportByTokenType.StandardReports ||
				report?.type === ReportByTokenType.UsersListParams ||
				report?.type === ReportByTokenType.UsersActivityDashboard ||
				report?.type === ReportByTokenType.SystemRoles ||
				report?.type === ReportByTokenType.OwnershipGroups ||
				report?.type === ReportByTokenType.DeletedGroups ||
				report?.type === ReportByTokenType.Groups ||
				report?.type === ReportByTokenType.AuditLog
			)
		) {
			return;
		}

		try {
			const data = await reportStore.downloadReportByPdfId({
				pdfId: report.meta.pdfId
			});
			downloadLink(data.url);
			Notification.success({
				description: t('notification.downloadFile.success')
			});
		} catch (e) {
			const error = e as AxiosErrorResponse;
			handleDownloadError(error);
		}
	};
	return {
		downloadPdfReport
	};
};
