import { useCallback, useEffect, useRef } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { APP_CONFIG } from 'app-config';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { getAppRoutes } from 'Pages/App/App.constants';
import { debounce } from 'lodash';

const IDLE_LS_KEY = 'lastActiveDate';

const IdleLogoutTracker: React.FC = () => {
	const timer = useRef<NodeJS.Timeout | void>();
	const { authStore } = useStores();
	const history = useHistory();

	const setNowActive = useCallback(() => {
		localStorage.setItem(IDLE_LS_KEY, String(+moment()));
	}, []);

	const checkActivity = useCallback(() => {
		const date = localStorage.getItem(IDLE_LS_KEY);
		const ms = moment().diff(moment(Number(date)), 'milliseconds');

		const isValid = Boolean(date && ms < APP_CONFIG.IDLE_TIMEOUT);
		if (!isValid) {
			window.unblock?.();
			history.push(getAppRoutes().IDLE_LOGOUT);
		} else {
			start();
		}
		// eslint-disable-next-line
	}, []);

	const stopTimer = useCallback(() => {
		if (timer.current) {
			timer.current = clearTimeout(timer.current);
		}
		// eslint-disable-next-line
	}, []);

	const startTimer = useCallback(() => {
		stopTimer();
		timer.current = setTimeout(checkActivity, APP_CONFIG.IDLE_TIMEOUT);
		// eslint-disable-next-line
	}, []);

	const onActivityEvent = useCallback(() => {
		start();
		// eslint-disable-next-line
	}, []);

	// Debounce to reduce redundant handler calls
	// eslint-disable-next-line
	const onVisibilityChange = useCallback(
		debounce(() => {
			if (document.hidden) {
				stopTimer();
			} else {
				checkActivity();
			}
		}),
		[]
	);

	const EVENT_LISTENERS = [
		{
			type: 'keydown',
			listener: onActivityEvent
		},
		{
			type: 'wheel',
			listener: onActivityEvent
		},
		{
			type: 'mousedown',
			listener: onActivityEvent
		},
		{
			type: 'visibilitychange',
			listener: onVisibilityChange
		}
	];

	const toggleEventListeners = useCallback((add: boolean) => {
		EVENT_LISTENERS.forEach((value) => {
			if (add) {
				document.addEventListener(value.type, value.listener);
			} else {
				document.removeEventListener(value.type, value.listener);
			}
		});
		// eslint-disable-next-line
	}, []);

	const start = useCallback((initialize = false) => {
		setNowActive();
		startTimer();
		if (initialize) {
			toggleEventListeners(true);
		}
		// eslint-disable-next-line
	}, []);

	const stop = useCallback(() => {
		stopTimer();
		toggleEventListeners(false);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (authStore.isAuthenticated) {
			start(true);
		} else {
			stop();
		}
		return () => {
			stop();
		};
		// eslint-disable-next-line
	}, [authStore.isAuthenticated]);

	return null;
};

export default observer(IdleLogoutTracker);
