import { Trans, useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Fx, Notification, Spin, Card } from 'Components';
import {
	ControlSpace,
	InfoTooltip,
	Statistic,
	ExportMenu
} from 'Pages/Dashboard/components';
import {
	ExportUsersActivityFileType,
	ExportUsersActivityRequest,
	ExportUsersActivityResponse,
	GetUsersActivityRequest,
	GetUsersActivityResponse
} from 'Services/Api/Dashboard/Types';
import {
	EXPORT_USERS_ACTIVITY,
	GET_USER_ACTIVITY
} from 'Services/Api/Dashboard/Queries';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import { STATISTICS } from './UserActivity.constants';
import { checkApolloError } from 'Helpers/graphql';
import { TInfo, APIErrorCodes } from 'app-types';
import { useDownloadReportQuery } from 'Hooks/useDownloadReportQuery';

export const UserActivityTitle = ({ title }: { title: TInfo }) => (
	<Trans i18nKey={title.key} values={title.params} />
);

function UserActivity() {
	const { t } = useTranslation();
	const { filters } = useDashboardContext();
	const [getUserActivity, userActivityResult] = useLazyQuery<
		GetUsersActivityResponse,
		GetUsersActivityRequest
	>(GET_USER_ACTIVITY, {
		onError: (error) => {
			const apolloError = checkApolloError(error);
			let description = error.message;
			if (apolloError.is(APIErrorCodes.Forbidden)) {
				description = t(
					'dashboard.tabs.userActivity.errorCodes.FORBIDDEN'
				);
			} else if (apolloError.is(APIErrorCodes.NoAvailableProperties)) {
				description = t('errorCodes.NO_AVAILABLE_PROPERTIES');
			}

			Notification.error({
				description
			});
		}
	});
	const getQueryUrl = (data: ExportUsersActivityResponse) => {
		return data.exportUsersActivity.downloadUrl;
	};
	const [
		exportUserActivity,
		exportUserActivityResult
	] = useDownloadReportQuery<
		ExportUsersActivityResponse,
		ExportUsersActivityRequest
	>(
		EXPORT_USERS_ACTIVITY,
		{
			onError: (error) => {
				Notification.error({
					description: error.message
				});
			}
		},
		getQueryUrl
	);

	useEffect(() => {
		getUserActivity({
			variables: filters
		});
	}, [filters, getUserActivity]);

	const onExportMenuClick = (info: { key: React.Key }) => {
		const menuKey = info.key as ExportUsersActivityFileType;
		exportUserActivity({
			variables: {
				fileType: menuKey,
				...filters
			}
		});
	};

	const data = userActivityResult.data?.getUsersActivity;
	if (!userActivityResult.called || userActivityResult.loading) {
		return <Spin />;
	}

	const errors = checkApolloError(userActivityResult.error).getNonFieldErrors(
		t,
		'dashboard.tabs.userActivity.errorCodes'
	);

	if (errors.length) return null;

	return (
		<Card
			title={t('dashboard.tabs.userActivity.title')}
			extra={
				<ControlSpace>
					<InfoTooltip
						title={t('dashboard.tabs.userActivity.tooltip')}
					/>
					<ExportMenu
						onExportMenuClick={onExportMenuClick}
						loading={exportUserActivityResult.loading}
					/>
				</ControlSpace>
			}
		>
			<Fx justify="space-between">
				{STATISTICS.map((stat, index) => (
					<Statistic
						key={index}
						icon={stat.icon}
						value={stat.value(data)}
						inheritColor
					>
						<UserActivityTitle title={stat.title} />
					</Statistic>
				))}
			</Fx>
		</Card>
	);
}

export default UserActivity;
