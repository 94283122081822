import qs from 'query-string';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';

export const qsParse: typeof qs.parse = (query, options) =>
	qs.parse(query, {
		arrayFormat: 'bracket',
		parseBooleans: true,
		...options
	});

export const qsStringify: typeof qs.stringify = (query, options) =>
	qs.stringify(query, {
		arrayFormat: 'bracket',
		...options
	});

export const toPath = ({
	path,
	params,
	query
}: {
	path: string;
	// eslint-disable-next-line
	params?: Record<string, any>;
	query?: string;
}) => {
	const pathname = generatePath(path, params);
	return query?.length ? `${pathname}?${query}` : pathname;
};

export const navigateBack = (
	history: ReturnType<typeof useHistory>,
	defaultPath: string
) => {
	if (history.action !== 'POP') {
		history.goBack();
	} else {
		history.push(defaultPath);
	}
};
