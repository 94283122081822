import * as yup from 'yup';
import {
	ArrayLocale,
	BooleanLocale,
	DateLocale,
	MixedLocale,
	NumberLocale,
	ObjectLocale,
	StringLocale
} from 'yup/lib/locale';
import {
	required,
	min,
	max,
	minLength
} from 'Helpers/validations/validations.helpers';

interface LocaleObject {
	mixed?: MixedLocale;
	string?: StringLocale;
	number?: NumberLocale;
	date?: DateLocale;
	boolean?: BooleanLocale;
	object?: ObjectLocale;
	array?: ArrayLocale;
}

const locale: LocaleObject = {
	mixed: {
		required
	},
	string: {
		min,
		max,
		email: required
	},
	array: {
		min: minLength
	}
};
yup.setLocale(locale);
