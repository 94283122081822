import { useState } from 'react';
import { APIErrorCodes, AxiosErrorResponse } from 'app-types';
import { checkApolloError } from 'Helpers/graphql';
import { isAxiosErrorCodeEqual } from 'Helpers/api';

export default function useTokenErrorValidation() {
	const [isTokenExpired, setIsTokenExpired] = useState(false);
	const [isTokenInvalid, setIsTokenInvalid] = useState(false);

	const determineTokenErrorForApolloProvider = (
		apolloError: ReturnType<typeof checkApolloError>
	): boolean => {
		if (apolloError.is(APIErrorCodes.TokenIsNotValid)) {
			setIsTokenInvalid(true);
			return true;
		}
		if (apolloError.is(APIErrorCodes.TokenExpired)) {
			setIsTokenExpired(true);
			return true;
		}
		return false;
	};

	const determineTokenErrorForAxiosService = (e: AxiosErrorResponse) => {
		if (isAxiosErrorCodeEqual(APIErrorCodes.TokenExpired, e)) {
			setIsTokenExpired(true);
		} else if (isAxiosErrorCodeEqual(APIErrorCodes.TokenIsNotValid, e)) {
			setIsTokenInvalid(true);
		}
	};

	return {
		isTokenInvalid,
		isTokenExpired,
		determineTokenErrorForApolloProvider,
		determineTokenErrorForAxiosService
	};
}
