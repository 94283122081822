import { useMemo } from 'react';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import { PermissionCode } from 'Services/Api/Users/Types';
import { useStores } from './useStore';

const useIsLMSModulesHidden = () => {
	const { authStore } = useStores();
	const isModulesNavHidden = useMemo(() => {
		const { currentUser } = authStore;

		if (currentUser?.primarySystemRole.role === SystemRoleType.SuperAdmin) {
			return false;
		}

		if (currentUser?.primarySystemRole.role === SystemRoleType.Owner) {
			return true;
		}

		const hasLmsPermissionInPrimary = currentUser?.primarySystemRole.permissions.some(
			(permission) => permission.code === PermissionCode.Lms
		);

		const hasGetSystemsPermission = currentUser?.primarySystemRole.permissions.some(
			(permission) => permission.code === PermissionCode.GetSystems
		);

		const hasLmsPermissionInSecondary = currentUser?.secondarySystemRoles.some(
			(item) =>
				item.permissions.some(
					(permission) => permission.code === PermissionCode.Lms
				)
		);
		const hasModuleEnabled = currentUser?.groups.some(
			(group) => group.moduleSettings?.moduleEnabled
		);

		if (
			(hasLmsPermissionInPrimary || hasLmsPermissionInSecondary) &&
			hasModuleEnabled &&
			hasGetSystemsPermission
		) {
			return false;
		}

		return true;
	}, [authStore]);

	return isModulesNavHidden;
};

export default useIsLMSModulesHidden;
