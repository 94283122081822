import * as yup from 'yup';
import { VALIDATION } from 'Helpers/validations';
import { Values } from './AddOwnershipGroup.types';

const PATH = 'ownershipGroup.addOwnershipGroup.fieldLabels';

const schema = yup.object().shape<Record<keyof Values, yup.AnySchema>>({
	name: VALIDATION.baseName.label(`${PATH}.name`).required(),
	status: yup.string().label(`${PATH}.status`).required(),
	groups: yup.array().label(`${PATH}.groups`)
});

export default schema;
