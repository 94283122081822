import { Prompt } from 'Components';
import { Form, Formik } from 'formik';
import { OwnershipGroup } from 'Services/Api/Groups/Types';
import AddOwnershipGroupForm from './AddOwnershipGroupForm';
import schema from './schema';
import { useAddOwnershipGroup } from './AddOwnershipGroup.hooks';

export interface AddOwnershipGroupProps {
	group: OwnershipGroup | null;
}
function AddOwnershipGroup(props: AddOwnershipGroupProps) {
	const { initialValues, onSubmit } = useAddOwnershipGroup(props);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={schema}
		>
			{({ dirty, isSubmitting }) => (
				<>
					<Prompt blockTransition={dirty && !isSubmitting} />
					<Form>
						<AddOwnershipGroupForm groupId={props.group?.id} />
					</Form>
				</>
			)}
		</Formik>
	);
}

export default AddOwnershipGroup;
