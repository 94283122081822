import { useQuery } from '@apollo/client';
import {
	adaptConfigsData,
	createGroupMaxHierarchyLevel,
	getGroupHierarchyErrorMessage,
	getGroupHierarchyLevel
} from './Configs.helpers';
import { GET_CONFIGS } from 'Services/Api/Configs/Queries';
import { GetConfigsResponse } from 'Services/Api/Configs/Types';
import { useTranslation } from 'react-i18next';

export const useConfigs = () => {
	const { t } = useTranslation();
	const result = useQuery<GetConfigsResponse>(GET_CONFIGS, {
		fetchPolicy: 'cache-first'
	});
	const configs = adaptConfigsData(result.data);
	const errorMessage = getGroupHierarchyErrorMessage(result.data);

	return {
		result,
		groupHierarchyLevel: getGroupHierarchyLevel(result.data),
		groupMaxHierarchyLevel: createGroupMaxHierarchyLevel(configs),
		groupsHierarchySizeLimit: configs?.GROUP_HIERARCHY_SIZE_LIMIT ?? 0,
		usersUploadSizeLimit: configs?.USERS_UPLOAD_XLSX_SIZE_LIMIT ?? 0,
		maxGroupsAmountForUser: configs?.MAX_GROUPS_AMOUNT_FOR_USER ?? 0,
		maxGroupNameCharacters: configs?.MAX_GROUP_NAME_CHARACTERS ?? 0,
		message: t(errorMessage.key, errorMessage.params),
		isConfigsLoading: result.loading
	};
};
