import cn from 'classnames';
import { v4 } from 'uuid';
import Icon from 'Components/Icon/Icon';
import usePasswordInput from './PasswordInput.hooks';
import { PasswordInputProps } from './PasswordInput.types';
import styles from './PasswordInput.module.scss';

function InputPassword(props: PasswordInputProps) {
	const {
		showPassword,
		handleShowPassword,
		handleValue,
		difRef,
		inputValue
	} = usePasswordInput(props);
	const { name, hasError } = props;
	const iconType = showPassword ? 'eyeOpened' : 'eyeCrossed';
	const id = v4();
	const inputClassName = cn(styles.input, hasError && styles.hasError);
	return (
		<>
			<input
				name={name}
				className={inputClassName}
				onChange={handleValue}
				onBlur={props.onBlur}
				value={inputValue}
				id={id}
				autoComplete="off"
				ref={difRef}
			/>
			<div className={styles.iconWrap} onClick={handleShowPassword}>
				<Icon type={iconType} className={styles.icon} />
			</div>
		</>
	);
}

export default InputPassword;
