import { Col, Layout, Row, Button } from 'Components';
import { useIsLMSModulesHidden } from 'Hooks';
import CardLink from '../CardLink/CardLink';
import { useTranslation } from 'react-i18next';
import { useStores } from 'Hooks/useStore';
import { OpenInNewTab } from 'Pages/Dashboard/components';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';
import { MyResultsWidgets } from '../MyResults.types';

const QuickLinks = () => {
	const { t } = useTranslation();

	const {
		dashboardStore: { myResults }
	} = useStores();
	const isLMSModulesHidden = useIsLMSModulesHidden();
	return (
		<Layout>
			<Layout.Heading>
				<h1>{t('dashboard.tabs.myResults.quickLinks.title')}</h1>
			</Layout.Heading>
			<Row gutter={24} fullHeight>
				{!isLMSModulesHidden && (
					<Col span={12}>
						<CardLink
							title={t(
								'dashboard.tabs.myResults.quickLinks.myCourses.title'
							)}
							subtitle={t(
								'dashboard.tabs.myResults.quickLinks.myCourses.subtitle',
								{ count: myResults?.coursesRemaining || 0 }
							)}
						>
							<OpenInNewTab
								tab={DashboardTabKey.MyResults}
								widget={MyResultsWidgets.MyCourses}
								tooltipPlacement="top"
								button={(handleButtonClick) => (
									<Button
										onClick={handleButtonClick}
										shape="round"
										type="primary"
									>
										{t(
											'dashboard.tabs.myResults.quickLinks.myCourses.buttonText'
										)}
									</Button>
								)}
							/>
						</CardLink>
					</Col>
				)}

				<Col span={12}>
					<CardLink
						title={t(
							'dashboard.tabs.myResults.quickLinks.userGuide.title'
						)}
						subtitle={t(
							'dashboard.tabs.myResults.quickLinks.userGuide.subtitle'
						)}
						buttonText={t(
							'dashboard.tabs.myResults.quickLinks.userGuide.buttonText'
						)}
						href="http://help.venzagroup.com/"
						buttonProps={{ ghost: true }}
					/>
				</Col>
			</Row>
		</Layout>
	);
};

export default QuickLinks;
