import { Button, Icon, Tooltip, Fx } from 'Components';
import styles from './CustomColumn.module.scss';

interface CustomColumnProps {
	title: string;
	subTitle?: string;
	tooltipText?: string;
	required?: boolean;
}

function CustomColumn(props: CustomColumnProps) {
	return (
		<Fx align="middle" justify="space-between">
			<div>
				<div>
					{props.title}
					{props.required && '*'}
				</div>
				{props.subTitle && (
					<div className={styles.subTitle}>{props.subTitle}</div>
				)}
			</div>
			{props.tooltipText && (
				<Tooltip title={props.tooltipText} placement="top">
					<Button type="default" text className={styles.button}>
						<Icon type="infoOutlined" size="md" />
					</Button>
				</Tooltip>
			)}
		</Fx>
	);
}

export default CustomColumn;
