import { useTranslation } from 'react-i18next';
interface EmptyTextContentProps {
	isGroup: boolean;
}

const EmptyTextContent = ({ isGroup }: EmptyTextContentProps) => {
	const { t } = useTranslation();
	return isGroup ? (
		<>
			<h1>{t('groups.groupDetails.emptyText.title.group')}</h1>
			<h3>{t('groups.groupDetails.emptyText.description.group')}</h3>
		</>
	) : (
		<>
			<h1>{t('groups.groupDetails.emptyText.title.tenant')}</h1>
			<h3>{t('groups.groupDetails.emptyText.description.tenant')}</h3>
		</>
	);
};

export default EmptyTextContent;
