import React from 'react';
import { getUserRoleLabel } from 'app-util';
import { SystemRole } from 'Services/Api/Roles/Types';

export const getNamesBySelectedRowKeys = (
	dataSource: SystemRole[],
	selectedRowKeys: React.Key[]
) => {
	return dataSource.reduce<string[]>((acc, value) => {
		if (selectedRowKeys.includes(value.id)) {
			acc.push(getUserRoleLabel(value.role));
		}
		return acc;
	}, []);
};
