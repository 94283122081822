import { Menu } from 'antd';
import { Dropdown, Notification, LanguageMenuItem } from 'Components';
import Icon from 'Components/Icon/Icon';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useMutation } from '@apollo/client';
import { UPDATE_CURRENT_USER } from 'Services/Api/Auth/Mutations';
import {
	I18N_LANGUAGE_LIST,
	ID_BY_LANGUAGE,
	ICON_BY_MENU_KEY
} from 'i18n/i18n.constants';
import { useStores } from 'Hooks/useStore';
import { LanguageI18nKey } from 'Services/Api/Common/Types';
// @NOTE@1: temporary solution, satisfy requirements of ticket: https://support.dataart.com/secure/RapidBoard.jspa?projectKey=VNZ&rapidView=12502
// import { getIsPending } from 'Stores/util';
import { I18N_DEFAULT_LANGUAGE } from 'Stores/LanguageStore';

interface HandleClickInput {
	key: React.Key;
}

function InternationalizationDropdown() {
	const [updateCurrentUser] = useMutation(UPDATE_CURRENT_USER);
	const { languageStore, authStore } = useStores();
	const [iconType, setIconType] = useState(
		ICON_BY_MENU_KEY[languageStore.language]
	);
	// @NOTE@1
	// const [loading, setLoading] = useState(false);

	useEffect(() => {
		setIconType(ICON_BY_MENU_KEY[languageStore.language]);
	}, [languageStore.language, setIconType]);

	const handleClick = async ({ key }: HandleClickInput) => {
		// @NOTE@1
		//	setLoading(true);
		try {
			const language = key as LanguageI18nKey;
			if (authStore.isAuthenticated) {
				const languageId = ID_BY_LANGUAGE[language];
				if (
					authStore.currentUser?.language?.id !== Number(languageId)
				) {
					await updateCurrentUser({
						variables: {
							input: {
								languageId
							}
						}
					});
					await authStore.getCurrentUser();
				}
			} else {
				await languageStore.changeLanguage(language);
			}
		} catch (error) {
			Notification.error({
				description: (error as Error).message
			});
		} finally {
			// @NOTE@1
			//	setLoading(false);
		}
	};
	// @NOTE@1: temporary solution, satisfy requirements of ticket: https://support.dataart.com/secure/RapidBoard.jspa?projectKey=VNZ&rapidView=12502
	// const isLoading =
	// 	loading || getIsPending(languageStore.status.changeLanguage);

	return (
		<Dropdown
			overlay={
				<Menu
					onClick={handleClick}
					selectedKeys={[languageStore.language]}
				>
					{I18N_LANGUAGE_LIST.map((language) => (
						// @NOTE: 'disabled' implemented according to https://support.dataart.com/browse/VNZ-1931
						<Menu.Item
							key={language}
							disabled={language !== I18N_DEFAULT_LANGUAGE}
						>
							<LanguageMenuItem menuKey={language} withTitle />
						</Menu.Item>
					))}
				</Menu>
			}
			placement="topRight"
			disabled
			// @NOTE@1
			//	disabled={isLoading}
		>
			<Icon type={iconType} />
		</Dropdown>
	);
}

export default observer(InternationalizationDropdown);
