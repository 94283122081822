import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AuditLogAction, AuditLogPage } from 'Services/Api/AuditLog/Types';

export const useAuditLogColumnFilters = () => {
	const { t } = useTranslation();

	const pages = orderBy(
		Object.values(AuditLogPage).map((code) => ({
			text: t(`auditLog.pages.${code}`),
			value: code
		})),
		(d) => d.text,
		['asc']
	);

	const actions = orderBy(
		Object.values(AuditLogAction).map((code) => ({
			text: t(`auditLog.actions.${code}`),
			value: code
		})),
		(d) => d.text,
		['asc']
	);

	return {
		pages,
		actions
	};
};
