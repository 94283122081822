interface GetSortedEntitiesCbParams<E> {
	entity: E;
	index: number;
}

export function getSortedEntities<E>(
	entities: E[],
	cb: (params: GetSortedEntitiesCbParams<E>) => boolean
): E[] {
	const validEntities: E[] = [];

	return entities
		.reduce<E[]>((invalidEntities, entity, index, arr) => {
			const acm = cb({ entity, index }) ? invalidEntities : validEntities;

			acm.push(entity);

			return arr.length === index + 1
				? invalidEntities.concat(validEntities)
				: invalidEntities;
		}, [])
		.map((entity: E, index: number) => ({
			...entity,
			key: String(index)
		}));
}
