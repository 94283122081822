import { APP_CONFIG } from 'app-config';
import { SearchProps } from 'Contexts/DataTree/components/Search/Search.types';
import DataTree from 'Contexts/DataTree/DataTree';
import { apolloClient } from 'Services/Api/client';
import { GET_PROPERTIES } from 'Services/Api/Groups/Queries';
import {
	GetPropertiesRequest,
	GetPropertiesResponse,
	Property
} from 'Services/Api/Groups/Types';

type PropertiesSearchProps = SearchProps<Property>;

const toOption: PropertiesSearchProps['toOption'] = (value, data) => ({
	key: String(value.id),
	value: value.id,
	label: value.name,
	description: data[value.id]?.parents?.map((p) => p.title).join(' > ')
});

function PropertiesSearch(props: Partial<PropertiesSearchProps>) {
	const onSearch = async (searchTerm: string) => {
		const response = await apolloClient.query<
			GetPropertiesResponse,
			GetPropertiesRequest
		>({
			query: GET_PROPERTIES,
			fetchPolicy: 'no-cache',
			variables: {
				filters: {
					name:
						searchTerm.length >= APP_CONFIG.SEARCH_MIN_LENGTH
							? searchTerm
							: ''
				}
			}
		});
		return response.data.listProperties;
	};

	return (
		<DataTree.Search<Property>
			{...props}
			search={onSearch}
			toOption={toOption}
		/>
	);
}

export default PropertiesSearch;
