import DataTree from 'Contexts/DataTree/DataTree';
import { DataTreeContextProvider } from 'Contexts/DataTree/DataTreeContext';
import { Group, GroupActiveStatus } from 'Services/Api/Groups/Types';
import { Text } from 'Components';
import GroupTreeLegend, { GROUP_LEGEND_TYPE_MAP } from './GroupTreeLegend';
import { useGroupTree, UseGroupTreeProps } from 'Hooks';
import GroupsSearch from 'Pages/Groups/GroupsSearch';
import {
	DataTreeContextProps,
	DataTreeNode
} from 'Contexts/DataTree/DataTree.types';
import { useTranslation } from 'react-i18next';

type DataTreeProps = DataTreeContextProps<Group>;
export type GroupTreeInitialValue = Pick<
	DataTreeProps,
	'initialValue' | 'disabledValue'
>;
export type GroupTreeProps = Pick<
	DataTreeProps,
	| 'initialValue'
	| 'clearValue'
	| 'onChange'
	| 'disabledValue'
	| 'dataSyncRef'
	| 'isMultiSelection'
> &
	UseGroupTreeProps & {
		toNode?: (item: Group, node: DataTreeNode) => DataTreeNode;
	};

const GroupTree: React.FC<GroupTreeProps> = ({ withDeleted, ...props }) => {
	const { t } = useTranslation();
	const { initialLoad, loadMore, loadValue } = useGroupTree({ withDeleted });

	return (
		<DataTreeContextProvider<Group>
			{...props}
			load={initialLoad}
			loadMore={loadMore}
			toNode={(item) => {
				const color =
					(item.isArchived && GROUP_LEGEND_TYPE_MAP['archived']) ||
					(item.deletedDate !== null &&
						GROUP_LEGEND_TYPE_MAP['deleted']) ||
					(item.active === GroupActiveStatus.Inactive &&
						GROUP_LEGEND_TYPE_MAP['inactive']) ||
					undefined;

				const node = {
					key: item.id,
					parentKey: item.parentId,
					title: item.name,
					label: (
						<Text color={color}>
							{item.name}
							{item.parentId === null
								? ` (${t('group.tenant')})`
								: ''}
						</Text>
					)
				};
				return props.toNode ? props.toNode(item, node) : node;
			}}
			loadInitialValue={loadValue}
		>
			<DataTree
				layout="modal"
				header={(searchProps) => (
					<GroupsSearch {...searchProps} withDeleted={withDeleted} />
				)}
				footer={
					<GroupTreeLegend exclude={withDeleted ? [] : ['deleted']} />
				}
			>
				{props.children}
			</DataTree>
		</DataTreeContextProvider>
	);
};

export default GroupTree;
