import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'Services/Api/client';
import { ConfigProvider } from 'antd';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import fr from 'antd/lib/locale/fr_FR';
import es from 'antd/lib/locale/es_ES';
import en from 'antd/lib/locale/en_US';
import { LanguageI18nKey } from 'Services/Api/Common/Types';

const ANTD_LOCALE = {
	[LanguageI18nKey.English]: en,
	[LanguageI18nKey.Spanish]: es,
	[LanguageI18nKey.French]: fr
};

const Provider: React.FC = ({ children }) => {
	const { languageStore } = useStores();
	return (
		<ApolloProvider client={apolloClient}>
			<ConfigProvider locale={ANTD_LOCALE[languageStore.language]}>
				<>{children}</>
			</ConfigProvider>
		</ApolloProvider>
	);
};

export default observer(Provider);
