import { v4 as uuid } from 'uuid';
import { Status } from 'app-types';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { axiosClient } from 'Services/Api/client';
import REPORTS_API from 'Services/Api/Reports/Api';
import COMMON_API from 'Services/Api/Common/Api';
import {
	CloneReportRequest,
	DownloadReportResponse,
	PreviewReportRequest,
	PreviewReportResponse,
	ReportDetails,
	ReportRequestBase,
	AddReportRequest,
	GetReportByTokenRequest,
	EditReportRequest,
	ReportByTokenDetails,
	DownloadSavedReportRequest,
	DownloadPdfReportRequest
} from 'Services/Api/Reports/Types';

import DASHBOARD_API from 'Services/Api/Dashboard/Api';

class ReportStore {
	status = {
		getReportByToken: Status.Idle,
		previewReport: Status.Idle,
		getReport: Status.Idle,
		downloadReportByPdfId: Status.Idle
	};
	reportByToken: ReportByTokenDetails | undefined;
	activeReport: ReportDetails | undefined;

	constructor() {
		makeObservable(this, {
			status: observable,
			activeReport: observable,
			reportByToken: observable
		});
	}

	@action
	async getReportByToken(params: GetReportByTokenRequest) {
		runInAction(() => {
			this.status.getReportByToken = Status.Pending;
		});
		try {
			const response = await axiosClient.get<ReportByTokenDetails>(
				COMMON_API.REPORT_JWT_VALIDATION,
				{
					params
				}
			);

			runInAction(() => {
				this.reportByToken = response.data;
				this.status.getReportByToken = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getReportByToken = Status.Failure;
			});
			throw e;
		}
	}

	@action
	async addReport(params: AddReportRequest) {
		await axiosClient.post<AddReportRequest>(
			REPORTS_API.SAVE_REPORT,
			params
		);
	}

	@action
	async editReport({ id, ...params }: EditReportRequest) {
		await axiosClient.post(`${REPORTS_API.SAVE_REPORT}/${id}`, params);
	}

	@action
	async previewReport(params: PreviewReportRequest) {
		runInAction(() => {
			this.status.previewReport = Status.Pending;
		});
		try {
			const response = await axiosClient.get<PreviewReportResponse>(
				REPORTS_API.PREVIEW_REPORT,
				{
					params
				}
			);

			runInAction(() => {
				this.status.previewReport = Status.Success;
			});

			return response.data;
		} catch (e) {
			runInAction(() => {
				this.status.previewReport = Status.Failure;
			});
			throw e;
		}
	}

	@action
	async downloadReport(params: DownloadSavedReportRequest) {
		return await axiosClient.get<DownloadReportResponse>(
			COMMON_API.DOWNLOAD_REPORT,
			{
				params: {
					...params,
					// BE requires it
					downloadId: uuid()
				}
			}
		);
	}

	@action
	async downloadReportByPdfId(params: DownloadPdfReportRequest) {
		runInAction(() => {
			this.status.downloadReportByPdfId = Status.Pending;
		});
		try {
			const response = await axiosClient.get<DownloadReportResponse>(
				`${DASHBOARD_API.GENERATED_REPORT}/${params.pdfId}`
			);

			runInAction(() => {
				this.status.downloadReportByPdfId = Status.Success;
			});

			return response.data;
		} catch (e) {
			runInAction(() => {
				this.status.downloadReportByPdfId = Status.Failure;
			});
			throw e;
		}
	}

	@action
	async cloneReport(params: CloneReportRequest) {
		await axiosClient.post(REPORTS_API.CLONE_REPORT, params);
	}

	@action
	async getReport(params: ReportRequestBase) {
		runInAction(() => {
			this.status.getReport = Status.Pending;
		});
		try {
			const response = await axiosClient.get<ReportDetails>(
				`${REPORTS_API.GET_REPORT}/${params.reportId}`
			);

			runInAction(() => {
				this.activeReport = response.data;
				this.status.getReport = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getReport = Status.Failure;
			});
			throw e;
		}
	}
}

export const reportStore = new ReportStore();

export default ReportStore;
