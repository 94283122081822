import { useTranslation } from 'react-i18next';

import { Fx, Layout, Table, Space } from 'Components';
import { OwnershipGroupProperty } from 'Services/Api/Groups/Types';
import OwnershipGroupActions from './OwnershipGroupActions';
import MoreDropdownButton from './MoreDropdownButton';
import styles from '../OwnershipGroups.module.scss';
import {
	OwnershipGroupProps,
	useOwnershipGroupTable
} from './OwnershipGroup.hooks';
import ModalContextProvider from 'Hooks/useModal';

function OwnershipGroup(props: OwnershipGroupProps) {
	const { t } = useTranslation();
	const {
		result,
		selected,
		filtered,
		dataSource,
		columns,
		loading,
		selectedRowKeys,
		namesBySelectedRowKeys,
		setSelectedRowKeys,
		onTableChange,
		onControlSubmit
	} = useOwnershipGroupTable(props);
	const emptyText = !selected ? (
		<>
			<h1>{t('ownershipGroups.emptyText.title')}</h1>
			<h3>{t('ownershipGroups.emptyText.description')}</h3>
		</>
	) : (
		<>
			<h1>{t('ownershipGroups.ownershipGroup.emptyText.title')}</h1>
			<h3>{t('ownershipGroups.ownershipGroup.emptyText.description')}</h3>
		</>
	);
	return (
		<ModalContextProvider>
			<Layout>
				<Layout.Header aligned="right">
					<Fx grow={1}>
						<Space align="center" size="middle">
							{props.group && (
								<>
									<h1>{props.group.name}</h1>
									<MoreDropdownButton
										ownershipGroupId={props.group.id}
									/>
								</>
							)}
						</Space>
					</Fx>

					<Fx grow={1} shrink={0} justify="end">
						<OwnershipGroupActions
							id={props.group?.id || null}
							ids={selectedRowKeys}
							idsNames={namesBySelectedRowKeys}
							hasData={Boolean(dataSource?.length)}
							onSubmit={onControlSubmit}
							ownerGroupName={props.group?.name ?? ''}
							resultRefetch={props.resultRefetch}
							params={result.variables}
						/>
					</Fx>
				</Layout.Header>

				<Table<OwnershipGroupProperty>
					dataSource={dataSource}
					rowKey="id"
					columns={columns}
					loading={loading}
					pagination={false}
					onChange={onTableChange}
					rowSelection={{
						selectedRowKeys,
						onChange: setSelectedRowKeys
					}}
					showHeader={selected}
					locale={{
						emptyText: filtered ? undefined : emptyText
					}}
					totalVisible
					totalClassName={styles.tableFooter}
				/>
			</Layout>
		</ModalContextProvider>
	);
}

export default OwnershipGroup;
