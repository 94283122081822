import { APP_CONFIG } from 'app-config';
import cn from 'classnames';
import { AutoComplete, Button, Icon, Input } from 'Components';
import { useDataTreeContext } from 'Contexts/DataTree/DataTreeContext';
import { AutoCompleteProps } from 'Components/Form/AutoComplete/AutoComplete';
import { debounce } from 'lodash';
import { useCallback, useMemo } from 'react';
import styles from './Search.module.scss';
import { useSearch } from './Search.hooks';
import { DataSourceItem, SearchProps } from './Search.types';
import { useTranslation } from 'react-i18next';

function Search<T = DataSourceItem>({
	toOption,
	search,
	defaultPaginationEnabled,
	...autocompleteProps
}: SearchProps<T>) {
	const { t } = useTranslation();
	const {
		currentLoadedStateRef,
		loadedState,
		updateLoadedState
	} = useSearch<T>();
	const { data, treeData, selectInTree } = useDataTreeContext();

	// eslint-disable-next-line
	const onSearch = useCallback(
		debounce(async (value: string, isMore?: boolean) => {
			updateLoadedState({ type: 'start', payload: { isMore } });

			try {
				if (value.length < APP_CONFIG.SEARCH_MIN_LENGTH) {
					throw new Error();
				}

				const result = await search(
					value,
					currentLoadedStateRef.current.pagination
				);

				if (!result) {
					throw new Error();
				}

				updateLoadedState({
					type: 'success',
					payload: {
						isMore,
						searchTerm: value,
						result
					}
				});
			} catch (e) {
				updateLoadedState({
					type: 'error',
					payload: {
						isMore
					}
				});
			}
		}, 400),
		[]
	);

	const loadMore = () => {
		onSearch(loadedState.searchTerm, true);
	};

	const options = useMemo(() => {
		const loadMoreRow =
			defaultPaginationEnabled &&
			loadedState.hasMore &&
			!loadedState.searching
				? {
						key: 'loadmore',
						value: 'loadmore',
						label: (
							<div
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								<Button
									type="text"
									link
									thin
									loading={loadedState.loading}
									onClick={loadMore}
								>
									{t('Components.dataTree.loadMore')}
								</Button>
							</div>
						)
				  }
				: null;

		const opts = (loadedState.dataSource || []).map((item) => {
			const option = toOption(item, data);
			return {
				...option,
				value: String(option.value),
				label: (
					<>
						<h4>{option.label}</h4>
						{option.description}
					</>
				)
			};
		});
		return loadMoreRow ? [...opts, loadMoreRow] : opts;
		// eslint-disable-next-line
	}, [loadedState]);

	const onSelect: AutoCompleteProps['onSelect'] = async (_, option) => {
		if (!option.key) {
			return;
		}

		updateLoadedState({ type: 'start' });
		await selectInTree(option.key);
		updateLoadedState({ type: 'error' });
	};

	return (
		<AutoComplete
			{...autocompleteProps}
			disabled={!treeData?.length}
			options={options}
			onSelect={onSelect}
			onSearch={onSearch}
			searched={loadedState.searched}
			autoClearValueOnSelect
			className={cn(autocompleteProps.className)}
			dropdownClassName={cn(
				styles.dropdown,
				autocompleteProps.dropdownClassName
			)}
			virtual={false}
			filterOption={false}
		>
			<Input.Search
				prefix={
					<Icon type={loadedState.searching ? 'loading' : 'search'} />
				}
				allowClear
			/>
		</AutoComplete>
	);
}

export default Search;
