import { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PROPERTY_LABEL, UNIQUE_ERROR_TYPE } from 'app-config';
import {
	Radio,
	Icon,
	Input,
	Button,
	Tooltip,
	Fx,
	Space,
	Notification
} from 'Components';
import { useConfigs } from 'Hooks';
import Level, { LevelProps } from '../../../components/Level/Level';
import styles from './LevelItem.module.scss';
import UploadGroupsButton from '../UploadGroupsButton/UploadGroupsButton';

import { useGroupsHierarchyContext } from '../../../GroupsHierarchyContext';
import { RadioChangeEvent } from 'antd';
import {
	isGroupsHierarchyLimitReached,
	validateGroupName
} from '../../../GroupsHierarchy.helpers';
import getIndexes from 'Helpers/getIndexes';

interface LevelItemHeaderProps {
	onChevronClick: () => void;
	isFolded: boolean;
	levelNumber: number;
	disabled: boolean;
	onChange: (value: boolean, levelNumber: number) => void;
}

const LevelItemHeader = ({
	onChevronClick,
	isFolded,
	levelNumber,
	disabled,
	onChange
}: LevelItemHeaderProps) => {
	const { t } = useTranslation();
	const { getValues } = useFormContext();

	useEffect(() => {
		return () => {
			const levelValues = getValues()[`level${levelNumber}`];

			if (levelValues.isProperty) {
				onChange(false, levelNumber);
			}
		};
		//eslint-disable-next-line
	}, []);
	const handleOnChange = (ev: RadioChangeEvent) => {
		onChange(ev.target.value, levelNumber);
	};
	return (
		<Fx justify="space-between" grow={1}>
			<Fx align="middle">
				<span className={styles.property}>{t('property')}?</span>
				<Radio.Group.HookFormField
					name={`level${levelNumber}.isProperty`}
					defaultValue={false}
					className={styles.radioGroup}
					disabled={disabled}
					onChange={handleOnChange}
				>
					<Radio value={true}>{PROPERTY_LABEL.YES()}</Radio>
					<Radio value={false}>{PROPERTY_LABEL.NO()}</Radio>
				</Radio.Group.HookFormField>
			</Fx>
			<div className={styles.headerRootRight}>
				<Space align="center">
					<Tooltip
						overlay={t('groupsHierarchy.formHierarchy.tooltip')}
					>
						<Icon type="information" />
					</Tooltip>
					<UploadGroupsButton level={levelNumber} />
					<Icon
						type="chevronDown"
						onClick={onChevronClick}
						className={isFolded ? undefined : styles.revertIcon}
					/>
				</Space>
			</div>
		</Fx>
	);
};
interface LevelItemGroupsProps {
	levelNumber: number;
}

const LevelItemGroups = ({ levelNumber }: LevelItemGroupsProps) => {
	const { t } = useTranslation();
	const {
		groupsHierarchySizeLimit,
		amountOfGroupsInTenant
	} = useGroupsHierarchyContext();
	const prevIndexes = useRef<number[]>([]);
	const {
		control,
		formState: { errors },
		setValue,
		getValues,
		setError,
		clearErrors
	} = useFormContext();
	const { maxGroupNameCharacters } = useConfigs();
	const { fields, append } = useFieldArray({
		control,
		name: `level${levelNumber}.groupsNames`
	});

	useEffect(() => {
		if (!fields.length) {
			append({
				name: ''
			});
		}

		//eslint-disable-next-line
	}, [fields.length]);

	const addGroupClick = () => {
		const values = getValues();

		if (
			isGroupsHierarchyLimitReached(
				values,
				groupsHierarchySizeLimit,
				amountOfGroupsInTenant,
				`level${levelNumber}`,
				1
			)
		) {
			Notification.error({
				description: t('groupsHierarchy.groupsLimit', {
					groupsHierarchySizeLimit
				})
			});

			return;
		}
		append({
			name: ''
		});
	};

	const onDeleteGroupClick = (fieldId: string, index: number) => {
		setValue(
			`level${levelNumber}.groupsNames`,
			fields.filter(({ id }) => id !== fieldId),
			{ shouldValidate: true }
		);

		if (
			errors?.[`level${levelNumber}`]?.groupsNames[index]?.name?.type ===
			UNIQUE_ERROR_TYPE
		) {
			validateGroupUniqName();
		} else {
			clearErrors(`level${levelNumber}.groupsNames[${index}]`);
		}
	};

	const validateGroupUniqName = useCallback(() => {
		const groups = getValues()[`level${levelNumber}`].groupsNames;
		if (groups.length > 1) {
			const indexes = getIndexes(groups, 'name');

			if (indexes.length) {
				if (!isEqual(indexes, prevIndexes.current)) {
					Notification.error({
						description: t(
							'groups.addMultipleGroups.errorMessageUniqueName'
						)
					});
				}

				prevIndexes.current = indexes;
				indexes.forEach((index: number) => {
					setError(`level${levelNumber}.groupsNames.${index}.name`, {
						type: UNIQUE_ERROR_TYPE
					});
				});

				return;
			}
		}
		prevIndexes.current = [];
		clearErrors(`level${levelNumber}.groupsNames`);

		// eslint-disable-next-line
	}, []);

	const handleValidateGroupName = (value: string): boolean => {
		return value.length
			? validateGroupName(maxGroupNameCharacters)(value)
			: true;
	};

	return (
		<div className={styles.itemRootContainer}>
			{fields.map(({ id }, index) => {
				return (
					<div className={styles.group} key={id}>
						<Input.HookFormInput
							label={t('group.groupName')}
							className={styles.formItem}
							name={`level${levelNumber}.groupsNames.${index}.name`}
							onBlur={validateGroupUniqName}
							validate={handleValidateGroupName}
						/>
						<Button
							shape="round"
							type="primary"
							size="small"
							ghost
							icon={<Icon type="close" />}
							disabled={fields.length === 1}
							onClick={() => onDeleteGroupClick(id, index)}
							className={styles.button}
						/>
					</div>
				);
			})}

			<div className={styles.controlContainer}>
				<Button
					type="primary"
					shape="round"
					icon={<Icon type="plus" />}
					onClick={addGroupClick}
				>
					{t('groups.groupDetails.groupDetailsActions.addGroup')}
				</Button>
				<span>
					{t('groupsHierarchy.formHierarchy.totalGroups', {
						groupsAmount: fields.length
					})}
				</span>
			</div>
		</div>
	);
};

interface LevelItemProps extends LevelProps {
	disabled: boolean;
	onChange: (value: boolean, levelNumber: number) => void;
}

const LevelItem = ({ levelNumber, disabled, onChange }: LevelItemProps) => {
	const [isFolded, setIsFolded] = useState<boolean>(true);

	const toggleFolded = useCallback(() => {
		setIsFolded((isFolded) => !isFolded);
	}, []);

	return (
		<Level
			levelNumber={levelNumber}
			Component={
				<LevelItemHeader
					onChevronClick={toggleFolded}
					isFolded={isFolded}
					levelNumber={levelNumber}
					disabled={disabled}
					onChange={onChange}
				/>
			}
		>
			{isFolded ? <LevelItemGroups levelNumber={levelNumber} /> : null}
		</Level>
	);
};

export default LevelItem;
