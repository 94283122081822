import { useTranslation } from 'react-i18next';
import { Fx, Button, Icon } from 'Components';
import styles from './AddMultipleGroupsMenu.module.scss';

interface AddMultipleGroupsMenuProps {
	onCancel(): void;
	onSubmit: React.MouseEventHandler<HTMLElement>;
	loading: boolean;
	headerTranslationPath: string;
	disabledSubmit?: boolean;
}

function AddMultipleGroupsMenu({
	onCancel,
	onSubmit,
	loading,
	headerTranslationPath,
	disabledSubmit
}: AddMultipleGroupsMenuProps) {
	const { t } = useTranslation();
	return (
		<>
			<h1 className={styles.addMultipleGroupsMenuTitle}>
				{t(headerTranslationPath)}
			</h1>
			<Fx>
				<Button
					shape="round"
					ghost
					type="default"
					className={styles.addMultipleGroupsMenuCancelButton}
					onClick={onCancel}
				>
					{t('cancel')}
				</Button>
				<Button
					shape="round"
					type="primary"
					icon={<Icon type="check" />}
					onClick={onSubmit}
					loading={loading}
					disabled={disabledSubmit}
				>
					{t('save')}
				</Button>
			</Fx>
		</>
	);
}

export default AddMultipleGroupsMenu;
