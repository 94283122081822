import { InfoTooltip, LineProgress } from 'Pages/Dashboard/components';
import { Fx, Text } from 'Components';
import { inPercent } from 'Helpers/numeric';
import styles from './SpotCheck.module.scss';

interface SpotCheckLineProgressProps {
	percent: number;
	title: string;
	tooltip: string;
}

function SpotCheckLineProgress({
	percent,
	title,
	tooltip
}: SpotCheckLineProgressProps) {
	return (
		<LineProgress
			className={styles.line}
			large
			before={<div className={styles.title}>{title}</div>}
			percent={percent}
			after={
				<Fx className={styles.graphPercentHolder} align="middle">
					<div className={styles.graphPercentValue}>
						<Text color="primary">{inPercent(percent)}</Text>
					</div>
					<InfoTooltip title={tooltip} />
				</Fx>
			}
		/>
	);
}

export default SpotCheckLineProgress;
