import { useLazyQuery } from '@apollo/client';
import { GetGroupRequest, GetGroupResponse } from 'Services/Api/Groups/Types';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { isNumber } from 'lodash';
import { GET_GROUP } from 'Services/Api/Groups/Queries';
import AddGroupForm from './AddGroupForm';
import { Spin } from 'Components';
import { useErrorBoundary } from 'Hooks';

const AddGroup = () => {
	const { groupId } = useParams<{ groupId?: string }>();
	const errorBoundary = useErrorBoundary();
	const [getGroup, result] = useLazyQuery<GetGroupResponse, GetGroupRequest>(
		GET_GROUP,
		{
			fetchPolicy: 'no-cache',
			notifyOnNetworkStatusChange: true,
			onError: errorBoundary.onError
		}
	);
	const data = result.data?.getGroup ?? result.previousData?.getGroup;
	useEffect(() => {
		const id = Number(groupId);
		if (!isNumber(id) || isNaN(id)) {
			return;
		}
		if (result.refetch) {
			result.refetch({
				id
			});
		} else {
			getGroup({
				variables: {
					id
				}
			});
		}
		// eslint-disable-next-line
	}, [groupId]);

	if (groupId && !data && (!result.called || result.loading)) {
		return <Spin />;
	}

	return (
		<>
			{result.loading && <Spin overlay />}
			<AddGroupForm group={data} />
		</>
	);
};

export default AddGroup;
