import XLSX from 'xlsx';
import { isEqual } from 'lodash';
import { SheetData } from 'app-types';

export const readFirstXLSXTab = (
	ev: ProgressEvent<FileReader>
): Record<string, SheetData> => {
	const fileContent = XLSX.read(ev.target?.result, {
		type: 'array'
	});
	const { SheetNames, Sheets } = fileContent;
	const [sheetName] = SheetNames;

	return Sheets[sheetName];
};

export function validateColumnNames(
	data: Record<string, SheetData>,
	columnTitlesCells: string[],
	columnNames: string[]
): boolean {
	const fileColumnNames = columnTitlesCells.reduce<string[]>((acc, title) => {
		return data[title]?.v ? acc.concat(data[title].v) : acc;
	}, []);

	if (fileColumnNames.length !== columnTitlesCells.length) return false;
	return isEqual(fileColumnNames, columnNames);
}
interface ValidateColumnNamesXLSXOutput {
	isValid: boolean;
	columnTitles: string[];
}
export function validateColumnNamesXLSX(
	data: Record<string, SheetData>,
	columnNames: string[]
): ValidateColumnNamesXLSXOutput {
	const fileColumnNames = Object.keys(data).reduce<string[]>((acc, cell) => {
		const cellNumber = cell.slice(1);
		if (cellNumber.length > 1 || Number(cellNumber) !== 1) return acc;

		return acc.concat(data[cell].v);
	}, []);

	// case, when user added additional column
	if (fileColumnNames.length > columnNames.length) {
		return {
			isValid: false,
			columnTitles: []
		};
	}
	const adaptedColumnNames = columnNames.slice(0, fileColumnNames.length);
	return {
		isValid: isEqual(adaptedColumnNames, fileColumnNames),
		columnTitles: adaptedColumnNames
	};
}

export function getCleanedKeys(
	data: Record<string, SheetData>,
	sheetNames: string[]
) {
	const dataCells = Object.keys(data);
	return dataCells.filter((cell) => {
		const [cellLetter, ...cellNumbers] = cell;
		return (
			sheetNames.includes(cellLetter) &&
			(cellNumbers.length > 1 || Number(cellNumbers[0]) !== 1)
		);
	});
}

export function getCleanedKeysXLSX(
	data: Record<string, SheetData>,
	sheetNames: string[]
) {
	const dataCells = Object.keys(data);
	return dataCells.filter((cell) => {
		const [cellLetter, ...cellNumbers] = cell;
		return (
			sheetNames.includes(cellLetter) &&
			(cellNumbers.length > 1 || Number(cellNumbers[0]) !== 1) &&
			data[cell].v.length
		);
	});
}
