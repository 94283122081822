import { gql } from '@apollo/client';

export const CREATE_TENANT = gql`
	mutation createTenant($input: TenantCreate!) {
		createTenant(input: $input) {
			id
		}
	}
`;

export const CREATE_GROUP = gql`
	mutation createGroup($input: GroupCreate!) {
		createGroup(input: $input) {
			id
		}
	}
`;

export const MOVE_GROUP = gql`
	mutation moveGroup($input: MoveGroup!) {
		moveGroup(input: $input)
	}
`;

export const DELETE_GROUP = gql`
	mutation deleteGroup($id: Int!) {
		deleteGroup(id: $id)
	}
`;

export const EDIT_TENANT = gql`
	mutation editTenant($input: EditTenant!) {
		editTenant(input: $input) {
			id
		}
	}
`;

export const EDIT_GROUP = gql`
	mutation editGroup($input: EditGroup!) {
		editGroup(input: $input) {
			id
		}
	}
`;

export const RESTORE_GROUPS = gql`
	mutation restoreGroups($input: RestoreGroups!) {
		restoreGroups(input: $input)
	}
`;

export const CREATE_OWNERSHIP_GROUP = gql`
	mutation createOwnershipGroup($input: OwnershipGroupCreate!) {
		createOwnershipGroup(input: $input) {
			id
		}
	}
`;

export const EDIT_OWNERSHIP_GROUP = gql`
	mutation editOwnershipGroup($input: OwnershipGroupUpdate!) {
		editOwnershipGroup(input: $input) {
			id
		}
	}
`;

export const REMOVE_OWNERSHIP_GROUP_PROPERTIES = gql`
	mutation removeOwnershipGroupProperties($id: Int!, $groupIds: [Int]!) {
		removeOwnershipGroupProperties(id: $id, groupIds: $groupIds)
	}
`;

export const DELETE_OWNERSHIP_GROUP = gql`
	mutation deleteOwnershipGroup($id: Int!) {
		deleteOwnershipGroup(id: $id)
	}
`;

export const RESET_GROUP_USERS_PASSWORDS = gql`
	mutation resetGroupUsersPasswords($id: Int!) {
		resetGroupUsersPasswords(id: $id) {
			totalCount
		}
	}
`;

export const CREATE_MULTIPLE_GROUPS = gql`
	mutation createMultipleGroups($input: [GroupCreate]!) {
		createMultipleGroups(input: $input)
	}
`;

export const CREATE_HIERARCHY = gql`
	mutation createHierarchy($input: CreateHierarchyInput!) {
		createHierarchy(input: $input)
	}
`;
