import cn from 'classnames';
import { Fx, OptionalValue } from 'Components';
import styles from './VisualizationLegend.module.scss';

interface VisualizationLegendProps {
	list: { label: string; value?: number | null; color?: string }[];
	vertical?: boolean;
}
function VisualizationLegend(props: VisualizationLegendProps) {
	return (
		<Fx className={cn(styles.root, { [styles.vertical]: props.vertical })}>
			{props.list.map((item, index) => (
				<Fx key={index} align="middle" className={styles.item}>
					{item.color && (
						<span
							className={styles.dot}
							style={{
								backgroundColor: item.color
							}}
						/>
					)}
					{item.hasOwnProperty('value') && (
						<span className={styles.value}>
							<OptionalValue>{item.value}</OptionalValue>
						</span>
					)}
					{item.label}
				</Fx>
			))}
		</Fx>
	);
}

export default VisualizationLegend;
