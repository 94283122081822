import { InputProps } from 'antd';
import { Select, Input } from 'Components';
import Icon from 'Components/Icon/Icon';
import { createFormikField } from 'Helpers/components';
import moment from 'moment';
import { mapInputFormikFieldProps } from '../Input/Input';
import { mapSelectFormikFieldProps, SelectProps } from '../Select/Select';
import styles from './DatePickerSelect.module.scss';

const getWeekdays = () =>
	moment.weekdays(true).map((weekday, index) => ({
		label: weekday,
		value: index + 1
	}));

type WeekdayProps = SelectProps & {
	picker: 'weekday';
};
type DayProps = InputProps & {
	picker: 'day';
};
type DatePickerSelectProps = WeekdayProps | DayProps;
type DatePickerSelectInterface = React.FC<DatePickerSelectProps> & {
	FormikField: typeof FormikField;
};
const DatePickerSelect: DatePickerSelectInterface = (props) => {
	if (props.picker === 'weekday') {
		const { picker, ...fieldProps } = props;
		return (
			<Select
				{...fieldProps}
				suffixIcon={<Icon type="calendar" />}
				className={styles.select}
			>
				{getWeekdays().map((opt) => (
					<Select.Option key={opt.value} value={opt.value}>
						{opt.label}
					</Select.Option>
				))}
			</Select>
		);
	}
	if (props.picker === 'day') {
		const { picker, ...fieldProps } = props;
		return (
			<Input
				{...fieldProps}
				className={styles.input}
				prefix={<Icon type="calendar" />}
			/>
		);
	}
	return null;
};

const FormikField = createFormikField<DatePickerSelectProps>(
	DatePickerSelect,
	(props) => {
		if (props.props.picker === 'weekday') {
			const mapProps = props as Parameters<
				typeof mapSelectFormikFieldProps
			>[0];
			return mapSelectFormikFieldProps(mapProps);
		}
		if (props.props.picker === 'day') {
			const mapProps = props as Parameters<
				typeof mapInputFormikFieldProps
			>[0];
			return mapInputFormikFieldProps(mapProps);
		}
		return {};
	}
);

DatePickerSelect.FormikField = FormikField;

export default DatePickerSelect;
