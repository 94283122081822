import { TInfo } from 'app-types';
import { Trans } from 'react-i18next';
import styles from './TInfoText.module.scss';

type DescriptionProps = Omit<Partial<TInfo>, 'key'> & {
	path: TInfo['key'];
};
function TInfoText({ path, params }: DescriptionProps) {
	return (
		<Trans
			i18nKey={path}
			values={params}
			components={[<strong className={styles.value} />]}
		/>
	);
}

export default TInfoText;
