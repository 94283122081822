import { Group } from 'Services/Api/Groups/Types';

export function getIsAddHierarchyGroupsEnabled(group?: Group): boolean {
	if (!group) return false;

	return group.level === 1
		? !group.isProperty
		: !group.isProperty &&
				group.parents?.every(({ isProperty }) => !isProperty);
}
