import { useState } from 'react';
import {
	DataTreeContextProps,
	DataTreeNodeItem
} from 'Contexts/DataTree/DataTree.types';
import { apolloClient } from 'Services/Api/client';
import {
	GET_GROUPS_LIST,
	GET_GROUPS_ANCESTOR_IDS
} from 'Services/Api/Groups/Queries';
import {
	GetGroupsAncestorIdsRequest,
	GetGroupsAncestorIdsResponse,
	GetGroupsListRequest,
	GetGroupsListResponse,
	Group
} from 'Services/Api/Groups/Types';

export interface UseGroupTreeProps {
	withDeleted?: boolean;
}

export const useGroupTree = (props: UseGroupTreeProps) => {
	const [loading, setLoading] = useState(false);
	const load = async (variables: GetGroupsListRequest) => {
		try {
			setLoading(true);
			const response = await apolloClient.query<
				GetGroupsListResponse,
				GetGroupsListRequest
			>({
				query: GET_GROUPS_LIST,
				fetchPolicy: 'no-cache',
				variables
			});
			return response.data.listGroups;
		} catch (error) {
			throw error;
		} finally {
			setLoading(false);
		}
	};

	const loadValue = async (value: DataTreeNodeItem<Group>[]) => {
		const response = await apolloClient.query<
			GetGroupsAncestorIdsResponse,
			GetGroupsAncestorIdsRequest
		>({
			query: GET_GROUPS_ANCESTOR_IDS,
			fetchPolicy: 'no-cache',
			variables: {
				input: {
					ids: (value || []).map((item) => item.key as number)
				}
			}
		});

		return response.data.getRootAncestorIds.ids;
	};

	const initialLoad = async () => {
		return await load({
			filters: {
				depth: 1,
				withDeleted: Boolean(props.withDeleted)
			}
		});
	};

	const loadMore: DataTreeContextProps<Group>['loadMore'] = async (id) => {
		return await load({
			filters: {
				id,
				withDeleted: Boolean(props.withDeleted)
			}
		});
	};

	return {
		load,
		loadMore,
		loadValue,
		initialLoad,
		loading
	};
};
