import { useFormikContext } from 'formik';
import { mapValues } from 'lodash';
import { useMemo, useState, useEffect } from 'react';
import * as yup from 'yup';

import { CustomValidationError } from 'Helpers/validations';
import { schema } from './schema';
import { INITIAL_TESTS, TestsType } from './NewPasswordFields.constants';
import { getIsValidByRuleType, getTests } from './NewPasswordFields.helpers';
import { NewPasswordFieldsValues } from './NewPasswordFields.types';

export function useNewPasswordFields() {
	const { values } = useFormikContext<NewPasswordFieldsValues>();
	const [tests, setTests] = useState<TestsType>(INITIAL_TESTS);

	useEffect(() => {
		schema
			?.validate(values, { strict: true, abortEarly: false })
			.then(() => {
				// mark all as valid
				setTests((currentValue) =>
					mapValues(currentValue, (value) => ({
						...value,
						valid: true
					}))
				);
			})
			.catch((e: yup.ValidationError) => {
				const errors = e.inner as CustomValidationError[];

				setTests((currentValue) => getTests(errors, currentValue));
			});
	}, [values]);

	const validByRule = useMemo(() => getIsValidByRuleType(tests), [tests]);

	return {
		validByRule
	};
}
