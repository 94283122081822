import * as d3 from 'd3';
import { CHART_CONFIG } from '../Visualizations.constants';
import { colorScale, linearScale } from '../Visualizations.helpers';
import { BarValue } from './BarChart.types';

const { selectors, margin } = CHART_CONFIG.bar;

type SvgType = d3.Selection<SVGGElement, unknown, null, undefined>;

function barchart() {
	const dispatch = d3.dispatch('tooltip:show', 'tooltip:hide');
	let svg: SvgType | undefined;
	let scales: ReturnType<typeof chart.scales> | undefined;
	let size = {
		width: 0,
		height: 0,
		innerWidth: 0,
		innerHeight: 0
	};
	let chartData: BarValue[] | undefined;
	let update: (() => void) | undefined;

	function chart(
		selection: d3.Selection<HTMLDivElement, unknown, null, undefined>
	) {
		selection.each(function () {
			svg = selection
				.append('svg')
				.append<SVGGElement>('g')
				.attr('transform', `translate(${margin.left}, ${margin.top})`);
		});
		const axisFn = axis();
		const graphFn = graph();
		axisFn.create();
		graphFn.create();
		update = () => {
			if (size) {
				selection
					.select('svg')
					.attr('width', size.width)
					.attr('height', size.height);
			}
			axisFn.draw();
			graphFn.draw();
		};
	}
	chart.dispatch = dispatch;
	chart.scales = () => {
		if (!chartData) return;
		const { max, categoryNames, groupNames } = chartData.reduce<{
			max: number;
			groupNames: string[];
			categoryNames: string[];
		}>(
			(acc, value) => {
				value.values.forEach((v) => {
					if (!acc.groupNames.includes(v.timePeriod)) {
						acc.groupNames.push(v.timePeriod);
					}
					if (v.value > acc.max) acc.max = v.value;
				});

				if (!acc.categoryNames.includes(value.category)) {
					acc.categoryNames.push(value.category);
				}
				return acc;
			},
			{
				max: 0,
				groupNames: [],
				categoryNames: []
			}
		);

		const color = colorScale(groupNames);

		const x0 = d3
			.scaleBand()
			.domain(categoryNames)
			.range([0, size.innerWidth])
			.paddingInner(0.46)
			.paddingOuter(0.08);

		const x1 = d3
			.scaleBand()
			.domain(groupNames)
			.range([0, x0.bandwidth()])
			.paddingInner(0.3)
			.paddingOuter(1);

		const xAxis = d3.axisBottom(x0).tickPadding(16);

		const { scale: y, scaleAxis: yAxis } = linearScale(
			0,
			max,
			size.innerHeight,
			size.innerWidth
		);
		const ret = {
			x0,
			x1,
			y,
			yAxis,
			xAxis,
			color
		};
		scales = ret;
		if (update) update();
		return ret;
	};

	chart.size = (width: number, height: number) => {
		size = {
			width,
			height,
			innerWidth: width - margin.left - margin.right,
			innerHeight: height - margin.top - margin.bottom
		};
		chart.scales();
		return chart;
	};

	chart.data = (data: BarValue[]) => {
		chartData = data;
		chart.scales();
		return chart;
	};

	function axis() {
		return {
			create: () => {
				if (!svg) return;

				// X-axis
				svg.append('g').attr('class', selectors.axis.x);

				// Y-axis
				svg.append('g').attr('class', selectors.axis.y);
			},
			draw: () => {
				if (!svg || !scales) return;
				const { xAxis, yAxis } = scales;
				// X-axis
				const xSelection = svg.select<SVGGElement>(
					`.${selectors.axis.x}`
				);

				xSelection
					.attr('transform', `translate(0,${size.innerHeight})`)
					.attr('font-family', 'Titillium')
					.call(xAxis);

				// Y-axis
				const ySelection = svg.select<SVGGElement>(
					`.${selectors.axis.y}`
				);

				ySelection.attr('font-family', 'Titillium').call(yAxis);

				[xSelection, ySelection].forEach((s) => {
					s.selectAll('text')
						.attr('color', '#7c8180')
						.attr('font-size', '14px')
						.attr('font-weight', '100');
					s.selectAll('line')
						.attr('stroke-dasharray', '3 3')
						.attr('color', '#e1e2e2');

					s.select('path').remove();
				});
			}
		};
	}

	function graph() {
		return {
			create: () => {
				if (!svg) return;
				// Graph
				svg.append('g').attr('class', selectors.graph);
			},
			draw: () => {
				if (!svg || !scales || !chartData) return;
				const graphSel = svg.select<SVGGElement>(`.${selectors.graph}`);

				const { x0, x1, y, color } = scales;

				graphSel
					.selectAll<SVGGElement, BarValue>(`.${selectors.barGroup}`)
					.data(chartData, (d) => d.category)
					.join(
						(enter) => {
							const g = enter
								.append('g')
								.attr('class', selectors.barGroup)
								.attr(
									'transform',
									(d) => `translate(${x0(d.category)},0)`
								);
							return g;
						},
						(update) => update,
						(e) => e.remove()
					)
					.selectAll<SVGGElement, BarValue>(`.${selectors.bar}`)
					.data((d) => d.values)
					.join(
						(enter) => {
							const g = enter
								.append('g')
								.attr('class', selectors.bar)
								.on('mouseenter', function () {
									if (!this.parentNode) {
										return;
									}
									const selection = d3.select<
										SVGGElement,
										BarValue['values'][0]
									>(this);
									const datum = selection.datum();
									const parentDatum = d3
										.select<SVGGElement, BarValue>(
											this.parentNode as SVGGElement
										)
										.datum();

									const domRect = selection
										?.node()
										?.getBoundingClientRect();
									if (!domRect) return;
									dispatch.call('tooltip:show', undefined, {
										x: domRect.x + x1.bandwidth() / 2,
										y: domRect.top,
										values: [
											{
												title: parentDatum.category,
												count: datum.value,
												countColor: color(
													datum.timePeriod
												)
											}
										]
									});
								})
								.on('mouseleave', () => {
									dispatch.call('tooltip:hide');
								});

							g.append('rect')
								.attr('fill', '#EFF1F1')
								.attr('x', (d) => x1(d.timePeriod) || null)
								.attr('y', 0)
								.attr('width', x1.bandwidth())
								.attr('height', size.innerHeight);

							g.append('rect')
								.attr('class', selectors.barValue)
								.attr('fill', (d) => color(d.timePeriod))
								.attr('x', (d) => x1(d.timePeriod) || null)
								.attr('y', (d) => y(d.value))
								.attr('width', x1.bandwidth())
								.attr(
									'height',
									(d) => size.innerHeight - y(d.value)
								);

							return g;
						},
						(update) => {
							update
								.select(`.${selectors.barValue}`)
								.transition()
								.attr('x', (d) => x1(d.timePeriod) || null)
								.attr('y', (d) => y(d.value))
								.attr('id', (d) => d.value)
								.attr('width', x1.bandwidth())
								.attr(
									'height',
									(d) => size.innerHeight - y(d.value)
								);

							return update;
						},
						(exit) => exit.remove()
					);
			}
		};
	}

	return chart;
}

export default barchart;
