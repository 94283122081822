import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Layout } from 'Components';

const DomainSettings: FC = () => {
	const { t } = useTranslation();
	return (
		<Layout.List fullHeight>
			<Layout.List.ListItem fullHeight>
				<Layout.List.Section
					title={t('group.editGroup.sections.emailSettings')}
				>
					<Input.FormikField
						name="fromName"
						label={t('group.editGroup.inputs.fromName')}
						disabled
					/>
					<Input.FormikField
						name="fromEmail"
						label={t('group.editGroup.inputs.fromEmail')}
						disabled
					/>
					<Input.FormikField
						name="replyTo"
						label={t('group.editGroup.inputs.replyTo')}
						disabled
					/>
				</Layout.List.Section>
				<Layout.List.Section
					title={t('group.editGroup.sections.domainSettings')}
				>
					<Input.FormikField
						name="SMTPServer"
						label={t('group.editGroup.inputs.SMTPServer')}
						disabled
					/>
					<Input.FormikField
						name="SMTPUserName"
						label={t('group.editGroup.inputs.SMTPUserName')}
						disabled
					/>
					<Input.FormikField
						name="SMTPPassword"
						label={t('group.editGroup.inputs.SMTPPassword')}
						disabled
					/>
					<Input.FormikField
						name="domain"
						label={t('group.editGroup.inputs.domain')}
						disabled
					/>
				</Layout.List.Section>
			</Layout.List.ListItem>
		</Layout.List>
	);
};

export default DomainSettings;
