import * as d3 from 'd3';
import { useEffect, useRef, useState } from 'react';
import { VisualizationTooltipValue } from './VisualizationTooltip/VisualizationTooltip.types';
import styles from './Chart.module.scss';
import barchart from './BarChart/chart';
import piechart from './PieChart/chart';
import linechart from './LineChart/chart';
import { Spin } from 'Components';
import VisualizationTooltip from './VisualizationTooltip/VisualizationTooltip';
import { isNumber } from 'lodash';

const CHART_TYPE = {
	bar: barchart,
	pie: piechart,
	line: linechart
};

type ChartType = typeof CHART_TYPE;
type ChartTypeKey = keyof ChartType;
type ChartTypeProps<
	K extends ChartTypeKey,
	P = Parameters<ChartType[K]>[0]
> = (P extends undefined
	? {
			props?: P;
	  }
	: {
			props: P;
	  }) & {
	type: K;
};

export interface ChartHelpers<K extends ChartTypeKey> {
	chart: ReturnType<ChartType[K]> | undefined;
}
type ChartProps<K extends ChartTypeKey> = ChartTypeProps<K> & {
	loading: boolean;
	width?: number;
	inheritWidth?: boolean;
	height?: number;
	children: (helpers: ChartHelpers<K>) => React.ReactNode;
};
function Chart<K extends ChartTypeKey>(props: ChartProps<K>) {
	const chart = CHART_TYPE[props.type];
	const rootRef = useRef<HTMLDivElement | null>(null);
	const svgRef = useRef<HTMLDivElement | null>(null);
	const chartRef = useRef<ChartHelpers<K>['chart']>();
	const isMountedRef = useRef(false);
	const [tooltip, setTooltip] = useState<VisualizationTooltipValue | null>(
		null
	);
	const parentWidth = rootRef.current?.getBoundingClientRect().width;
	const width = props.inheritWidth ? parentWidth : props.width ?? 0;

	useEffect(() => {
		isMountedRef.current = true;
		if (!svgRef.current) {
			return;
		}
		// eslint-disable-next-line
		chartRef.current = chart(props.props as any) as ReturnType<
			typeof chart
		>;

		d3.select(svgRef.current).call(chartRef.current);

		// set size
		if (isNumber(width) && isNumber(props.height)) {
			chartRef.current.size(width, props.height);
		}

		chartRef.current.dispatch.on(
			'tooltip:show',
			(value: VisualizationTooltipValue) => {
				setTooltip(value);
			}
		);
		chartRef.current.dispatch.on('tooltip:hide', () => {
			setTooltip(null);
		});
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (isNumber(width) && isNumber(props.height)) {
			chartRef.current?.size(width, props.height);
		}
		// eslint-disable-next-line
	}, [width]);

	return (
		<div ref={rootRef} className={styles.root}>
			<div
				className={styles.content}
				style={{
					width: width || 'auto'
				}}
			>
				{props.loading && <Spin overlay />}
				<div ref={svgRef} />
				{tooltip && (
					<VisualizationTooltip value={tooltip} placement="top" />
				)}
				{isMountedRef.current &&
					props.children({ chart: chartRef.current })}
			</div>
		</div>
	);
}

export default Chart;
