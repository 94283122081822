import { Upload, UploadProps, InputProps } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Input from '../Form/Input/Input';
import styles from './UploadFile.module.scss';

interface UploadFileProps extends UploadProps {
	disabled: boolean;
	value: InputProps['value'];
}

const UploadFile = ({
	beforeUpload,
	onChange,
	disabled,
	value,
	accept
}: UploadFileProps) => {
	const { t } = useTranslation();
	const handleBeforeUpload: UploadProps['beforeUpload'] = () => {
		return false;
	};

	return (
		<Upload
			showUploadList={false}
			accept={accept}
			beforeUpload={beforeUpload ?? handleBeforeUpload}
			onChange={onChange}
			disabled={disabled}
		>
			<Input
				className={cn(styles.selectFile, {
					[styles.disabledSelectedFile]: disabled
				})}
				suffix={
					<div className={styles.suffix}>
						{t('Components.uploadFile.selectFile')}
					</div>
				}
				readOnly
				value={value}
				placeholder={t('Components.uploadFile.placeholder')}
			/>
		</Upload>
	);
};

export default UploadFile;
