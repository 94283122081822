import { TableProps } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Group as GroupType } from 'Services/Api/Groups/Types';
import { Table } from 'Components';
import styles from './Group.module.scss';
import { DataSourceItem } from './Group.types';
import EmptyTextContent from './EmptyTextContent';

export interface GroupTemplateTableProps<T> {
	group: GroupType | null;
	isManage?: boolean;
	onTableChange?: TableProps<T>['onChange'];
	// eslint-disable-next-line
	dataSource: any;
	tableLoading?: boolean;
	columns: ColumnProps<T>[];
	isDataSourceFiltered?: boolean;
	rowClassName?: (record: T) => string;
	totalVisible: number;
}

function GroupTemplateTable<T extends DataSourceItem>({
	group,
	isManage,
	onTableChange,
	dataSource,
	tableLoading,
	columns,
	isDataSourceFiltered,
	rowClassName,
	totalVisible
}: GroupTemplateTableProps<T> & TableProps<T>) {
	return (
		<Table<T>
			dataSource={dataSource}
			rowKey="id"
			columns={columns}
			loading={tableLoading}
			pagination={false}
			onChange={onTableChange}
			rowClassName={rowClassName}
			className={isManage ? undefined : styles.groupTable}
			locale={{
				emptyText: isDataSourceFiltered ? undefined : (
					<EmptyTextContent isGroup={Boolean(group?.id)} />
				)
			}}
			totalVisible={totalVisible}
		/>
	);
}

export default GroupTemplateTable;
