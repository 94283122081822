import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Select } from 'Components';
import { LMSSettings } from 'Pages/Group/AddGroup/components';
import { useStores } from 'Hooks/useStore';
interface ModuleSettingsProps {
	isTenant: boolean;
}

const ModuleSettings: FC<ModuleSettingsProps> = ({ isTenant }) => {
	const { t } = useTranslation();
	const { systemsStore } = useStores();
	return (
		<Layout.List fullHeight>
			<Layout.List.ListItem fullHeight>
				<Layout.List.Section
					title={t('group.editGroup.sections.moduleSettings')}
				>
					<LMSSettings isTenant={isTenant} />
					{isTenant && (
						<Select.FormikField
							name="systemId"
							disabled
							label={t('group.systemName')}
						>
							{systemsStore.systems.map((system) => (
								<Select.Option
									key={system.id}
									value={system.id}
								>
									{system.systemName}
								</Select.Option>
							))}
						</Select.FormikField>
					)}
				</Layout.List.Section>
			</Layout.List.ListItem>
		</Layout.List>
	);
};

export default ModuleSettings;
