import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Modal, Notification } from 'Components';
import { useModal } from 'Hooks/useModal';
import { DELETE_OWNERSHIP_GROUP } from 'Services/Api/Groups/Mutations';
import { DeleteOwnerShipGroupRequest } from 'Services/Api/Groups/Types';
import { useHistory, generatePath } from 'react-router';
import { getAppRoutes } from 'Pages/App/App.constants';
import { checkApolloError } from 'Helpers/graphql';
import { APIErrorCodes } from 'app-types';
import TInfoText from 'Contexts/TInfoText/TInfoText';

interface MetaUser {
	cognitoLogin: string;
	isDeleted: boolean;
}

const TRANSLATION_PATH =
	'ownershipGroups.ownershipGroup.ownershipGroupActionsModals.deleteModal';

export interface DeleteOwnershipGroupModalProps {
	ownerGroupName: string;
	resultRefetch: () => void;
}

export function DeleteOwnershipGroupModal({
	ownerGroupName,
	resultRefetch
}: DeleteOwnershipGroupModalProps) {
	const { t } = useTranslation();
	const history = useHistory();
	const { modal, hideModal } = useModal();
	const [
		deleteOwnershipGroup,
		{ loading }
	] = useMutation<DeleteOwnerShipGroupRequest>(DELETE_OWNERSHIP_GROUP);

	const onDeleteSubmit = async () => {
		try {
			await deleteOwnershipGroup({
				variables: {
					id: modal?.value
				}
			});

			Notification.success({
				description: t(`${TRANSLATION_PATH}.successNotification`)
			});

			history.replace(generatePath(getAppRoutes().OWNERSHIP_GROUPS));
			resultRefetch();
		} catch (error) {
			const apolloError = checkApolloError(error);
			if (
				apolloError.is(
					APIErrorCodes.OwnershipGroupWithUsersCannotBeDeleted
				)
			) {
				const metaUsers = apolloError.getMeta<MetaUser[]>();

				const translationPath =
					metaUsers.length > 1 ? 'multiple' : 'single';
				const usersByLogin = metaUsers
					.reduce<string[]>((acc, { cognitoLogin, isDeleted }) => {
						const cognitoLoginWithDeletedStatus = `"${cognitoLogin}: ${t(
							`appConfig.deletedLabel.${isDeleted ? 'yes' : 'no'}`
						)}"`;

						acc.push(cognitoLoginWithDeletedStatus);

						return acc;
					}, [])
					.join(', ');

				Notification.error({
					description: t(
						`errorCodes.${APIErrorCodes.OwnershipGroupWithUsersCannotBeDeleted}.${translationPath}`,
						{
							usersByLogin
						}
					)
				});

				return;
			}

			Notification.error({
				description: t('errorCodes.genericErrorMessage')
			});
		} finally {
			hideModal();
		}
	};

	return (
		<Modal
			visible
			title={
				<TInfoText
					path={`${TRANSLATION_PATH}.title`}
					params={{ name: ownerGroupName }}
				/>
			}
			okText={t('delete')}
			onOk={onDeleteSubmit}
			onCancel={hideModal}
			okButtonProps={{
				disabled: loading,
				loading
			}}
		>
			{t(`${TRANSLATION_PATH}.description`)}
		</Modal>
	);
}
