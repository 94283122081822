import { TFunction } from 'react-i18next';
import { EditUserValues } from 'Pages/User/User.types';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import { PermissionCode, LMSModuleSettingsIds } from 'Services/Api/Users/Types';

export const getIsModuleSettingsDisabled = (
	values: Pick<EditUserValues, 'primarySystemRoleId' | 'groups'>,
	authUserPermissionCodes: PermissionCode[] = []
) => {
	if (!authUserPermissionCodes.includes(PermissionCode.Lms)) {
		return true;
	}

	const primaryRole = values.primarySystemRoleId?.find(
		(role) => role.data.isPrimary
	);

	if (
		primaryRole?.data.role === SystemRoleType.SuperAdmin ||
		primaryRole?.data.role === SystemRoleType.Owner
	) {
		return true;
	}

	const hasLmsPermission = values.primarySystemRoleId?.some((systemRole) =>
		systemRole.data.permissions.some(
			(perm) => perm.code === PermissionCode.Lms
		)
	);
	const hasModuleEnabled = values.groups?.some(
		(group) => group.data?.moduleSettings?.moduleEnabled
	);

	return !hasLmsPermission || !hasModuleEnabled;
};

export interface ModuleSettingsValidationErrorDataItem {
	lmsFieldName: string;
	itemNumber: number;
}

export class ModuleSettingsValidationError extends Error {
	constructor(
		public message: string,
		public data?: ModuleSettingsValidationErrorDataItem[]
	) {
		super(message);
	}
}

export const isModuleSettingsValidationError = (error: Error): boolean =>
	error instanceof ModuleSettingsValidationError;

export const getLMSModuleSettingErrorText = (
	moduleSettingsError: string | undefined,
	lmsField: 'jobClassId' | 'userRoleId',
	t: TFunction<'translation'>
): string | undefined => {
	const path = lmsField === 'userRoleId' ? 'lmsRole' : 'lmsJobcass';
	return moduleSettingsError === 'both' || moduleSettingsError === lmsField
		? t('util.validation.validationMessage.noYupRequired', {
				path: t(`user.${path}.label`)
		  })
		: undefined;
};

export function getErrorFieldName(values: LMSModuleSettingsIds): string {
	const isJobClassId = !values.moduleSettings?.jobClassId;
	const isUserRoleId = !values.moduleSettings?.userRoleId;
	const isBoth = isJobClassId && isUserRoleId;
	return isBoth ? 'both' : isJobClassId ? 'jobClassId' : 'userRoleId';
}

export function validateLMSModuleSettings(
	isLMSModuleSettingsEnabled: boolean,
	values: LMSModuleSettingsIds
): void {
	if (
		!isLMSModuleSettingsEnabled &&
		(!values.moduleSettings?.jobClassId ||
			!values.moduleSettings?.userRoleId)
	) {
		throw new ModuleSettingsValidationError(getErrorFieldName(values));
	}
}
