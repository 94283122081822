import flow from 'lodash/flow';
import { GetConfigsResponse } from 'Services/Api/Configs/Types';
import { validationMessage } from 'Helpers/validations/validations.helpers';

export const adaptConfigsData = (data?: GetConfigsResponse) => {
	if (!data) return null;

	return data.getConfigs.reduce<Record<string, number>>(
		(acm, { name, value }) => {
			acm[name] = value;
			return acm;
		},
		{}
	);
};

export function createGroupMaxHierarchyLevel(
	configs: Record<string, number> | null
): number {
	return (configs?.GROUP_HIERARCHY_DEPTH_LIMIT ?? 0) + 1;
}

const createGroupHierarchyErrorMessage = (level: number) =>
	validationMessage('group.addGroup.groupHierarchyErrorMessage')({
		value: level
	});

export const getGroupHierarchyLevel = flow(
	adaptConfigsData,
	createGroupMaxHierarchyLevel
);

export const getGroupHierarchyErrorMessage = flow(
	adaptConfigsData,
	createGroupMaxHierarchyLevel,
	createGroupHierarchyErrorMessage
);
