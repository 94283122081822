import { useFormContext } from 'react-hook-form';
import {
	AddUsersFormFieldBaseProps,
	AddUsersFormValues
} from '../../AddUsers.types';
import PrimaryRoleField, { PrimaryRoleFieldProps } from './PrimaryRoleField';

function PrimaryRole({ fieldKey, className }: AddUsersFormFieldBaseProps) {
	const {
		getValues,
		formState,
		setValue
	} = useFormContext<AddUsersFormValues>();
	const values = getValues();
	const key = Number(fieldKey);

	const onChange: PrimaryRoleFieldProps['onChange'] = (value) => {
		setValue(`users.${key}.primarySystemRoleId`, value, {
			shouldValidate: true
		});
	};

	const error = Boolean(formState.errors.users?.[key]?.primarySystemRoleId);
	return (
		<PrimaryRoleField
			value={values.users[key].primarySystemRoleId}
			error={error}
			onChange={onChange}
			className={className}
		/>
	);
}

export default PrimaryRole;
