import { useCallback, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import {
	GroupDetails,
	GetHierarchySizeResponse,
	GetHierarchySizeVariables
} from 'Services/Api/Groups/Types';
import { getAppRoutes } from 'Pages/App/App.constants';
import { useConfigs } from 'Hooks';
import { getIsAddHierarchyGroupsEnabled } from '../Group.helpers';
import { PermissionCode } from 'Services/Api/Users/Types';
import { usePermission } from 'Components/Permission/Permission.hooks';
import { GET_HIERARCHY_SIZE } from 'Services/Api/Groups/Queries';

export enum AddGroupMenuKey {
	Single = 'Single',
	Multiple = 'Multiple',
	Hierarchy = 'Hierarchy'
}
export interface GroupActionsProps {
	groupId: number | undefined;
	group: GroupDetails | undefined;
	level: number | undefined;
	hasData: boolean;
	onMultipleGroupsClick(): void;
}
export const useGroupActions = ({
	groupId,
	group,
	level,
	onMultipleGroupsClick,
	hasData
}: GroupActionsProps) => {
	const [getHierarchySize, { data, loading, called }] = useLazyQuery<
		GetHierarchySizeResponse,
		GetHierarchySizeVariables
	>(GET_HIERARCHY_SIZE);

	useEffect(() => {
		if (groupId) {
			getHierarchySize({
				variables: {
					input: {
						groupId: String(groupId)
					}
				}
			});
		}

		// eslint-disable-next-line
	}, []);

	const permission = usePermission();
	const {
		permittedByCode: {
			[PermissionCode.AddGroup]: isAddGroupPermitted,
			[PermissionCode.AddTenant]: isAddTenantPermitted,
			[PermissionCode.ExportGroups]: isExportPermitted
		}
	} = permission.getHasPermission({
		code: [
			PermissionCode.AddGroup,
			PermissionCode.AddTenant,
			PermissionCode.ExportGroups
		]
	});

	const isAddHierarchyGroupsEnabled = getIsAddHierarchyGroupsEnabled(group);
	const history = useHistory();

	const configs = useConfigs();

	const addGroupClick = useCallback(() => {
		history.push(
			generatePath(getAppRoutes().ADD_GROUP, {
				groupId
			})
		);
		// eslint-disable-next-line
	}, []);

	const addHierarchyClick = () => {
		history.push(
			generatePath(getAppRoutes().GROUP_HIERARCHY, {
				groupId
			})
		);
	};

	const onAddGroupMenuClick = (info: { key: React.Key }) => {
		const menuKey = info.key as AddGroupMenuKey;
		switch (menuKey) {
			case AddGroupMenuKey.Single:
				addGroupClick();
				break;
			case AddGroupMenuKey.Multiple:
				onMultipleGroupsClick();
				break;
			case AddGroupMenuKey.Hierarchy:
				addHierarchyClick();
				break;
		}
	};
	const isAmountOfGroupsInTenantIsReached =
		(data?.getHierarchySize.totalCount ?? 0) >=
		configs.groupsHierarchySizeLimit;
	const maxHierarchyLevel = configs.groupMaxHierarchyLevel;

	const isHierarchySizeLoading = !groupId || !called || loading;
	const isAddGroupDisabled =
		maxHierarchyLevel <= (level ?? 0) ||
		isHierarchySizeLoading ||
		isAmountOfGroupsInTenantIsReached;

	return {
		maxHierarchyLevel,
		isAddGroupDisabled,
		isAddGroupPermitted,
		isAddTenantPermitted,
		isExportPermitted,
		isAddHierarchyGroupsEnabled,
		onAddGroupMenuClick,
		addGroupClick,
		isAmountOfGroupsInTenantIsReached,
		groupsHierarchySizeLimit: configs.groupsHierarchySizeLimit,
		isExportButtonDisabled: !hasData
	};
};
