import { DownloadUrl, Entity, MutationRequest } from 'app-types';
import { ModuleSettings, OwnershipGroup } from '../Groups/Types';
import { JobClass, UserRole } from '../Masterdata/Types';
import { SystemRolesListItem } from '../Roles/Types';

export enum UserLoginStatus {
	ActivationPending = 'ACTIVATION_PENDING',
	LockedOut = 'LOCKED_OUT'
}

export interface TimezoneEntity {
	id: number;
	zone: string;
}

export interface UserModuleSettings {
	jobClass?: JobClass;
	userRole?: UserRole;
}

export enum TwoFactorAuthorizationStatus {
	Disabled = 'disabled',
	Setup = 'setup',
	SetupPending = 'setupPending',
	Blocked = 'blocked'
}

export interface User {
	cognitoId: string;
	cognitoLogin: string;
	lastLoginDate: string | null;
	peakV3Id: string;
	firstName: string;
	lastName: string;
	email: string;
	isActive: boolean;
	isDeleted: boolean;
	isLockedOut: boolean;
	phoneNumber: string | null;
	primarySystemRole: SystemRolesListItem;
	secondarySystemRoles: SystemRolesListItem[];
	groups: UserGroup[];
	timezone?: TimezoneEntity;
	language?: Entity;
	daysBeforeRemoval: number | null;
	ownershipGroup?: Pick<OwnershipGroup, 'id' | 'name' | 'status'> | null;
	loginStatus: UserLoginStatus | null;
	softDeletedDate: string | null;
	moduleSettings: UserModuleSettings | null;
	doNotUpdateOnAutoFeeds?: boolean;
	isEmailValidated: boolean;
	isConsentGiven: boolean;
	twoFactorAuthorizationStatus: TwoFactorAuthorizationStatus;
}

export type PreviewUser = User & {
	level1: string;
	level2: string;
	level3: string;
	level4: string;
	level5: string;
	level6: string;
	ownershipGroupName: string;
};

export type UserGroup = Entity & {
	deletedDate: string | null;
	moduleSettings: ModuleSettings | null;
};
export interface GetUsersRequest {
	page: number;
	itemsPerPage: number;
	search?: string;
	sort: string;
	filters: Partial<{
		cognitoLogin: string;
		firstName: string;
		lastName: string;
		email: string;
		groupIds: string[];
		ownershipGroupIds: string[];
		isActive: boolean[];
		isDeleted: boolean;
		isLockedOut: boolean[];
		isActivationPending: boolean[];
		softDeletedDate: string;
		systemRoles: string[];
	}>;
}

export interface GetUsersResponse {
	list: {
		totalCount: number;
		data: User[];
	};
}

export type ExportUsersRequest = Pick<
	GetUsersRequest,
	'search' | 'sort' | 'filters'
>;

export interface ExportUsersResponse {
	exportUsers: DownloadUrl;
}
export interface GetLanguagesList {
	getLanguagesList: Entity[];
}

export interface GetTimezonesList {
	getTimezonesList: TimezoneEntity[];
}

export interface ToggleUserStatusRequest {
	input: {
		peakV3Ids: string[];
		isActive: boolean;
	};
}

export type ValidationError = {
	errorCode: string;
	fieldName: string;
	meta: [string];
};

export type ValidationWarning = {
	warningCode: string;
	fieldName: string;
};

export interface LMSModuleSettingsIds {
	moduleSettings?: null | {
		jobClassId?: string;
		userRoleId?: number;
	};
}

export type AddUserRequestInput = Pick<
	User,
	'email' | 'firstName' | 'lastName'
> &
	LMSModuleSettingsIds & {
		languageId?: number;
		phoneNumber?: string | null;
		primarySystemRoleId: number;
		timezoneId?: number;
		secondarySystemRoleIds?: number[];
		groups?: number[];
		ownershipGroup?: number;
		doNotUpdateOnAutoFeeds?: boolean;
	};

export type AddUserRequest = MutationRequest<
	AddUserRequestInput & Pick<User, 'cognitoLogin'>
>;
export type UpdateUserRequest = MutationRequest<
	AddUserRequestInput & Pick<User, 'peakV3Id'>
>;

export interface GetUserResponse {
	findUser: User;
}

export type MoveUsersRequest = MutationRequest<{
	userIds: string[];
	groupId: number;
}>;

export type MultipleUsers = {
	requestId?: string;
	users: AddUserRequestInput[];
};

export type AddUsersRequest = MutationRequest<MultipleUsers>;

export type AddUsersResponse = {
	createMultipleUsers: string;
};

export enum DynamicPermissionCode {
	AddUser = 'add_user',
	EditUser = 'edit_user',
	ActivateUser = 'activate_disable_user',
	MoveUser = 'move_user',
	RestoreUser = 'restore_user',
	DeleteUser = 'delete_user',
	ViewUsers = 'view_user',
	ResetPassword = 'reset_password',
	Reset2Fa = 'reset_2fa'
}

export enum PermissionCode {
	UserManagerScreenView = 'user_manager_screen_view',
	ViewGroups = 'group_manager_view_page',
	ViewOwnershipGroups = 'view_ownership_groups',
	AddOwnershipGroup = 'add_ownership_group',
	EditOwnershipGroup = 'edit_ownership_group',
	DeleteOwnershipGroup = 'delete_ownership_group',
	AddTenant = 'add_tenant',
	AddGroup = 'add_group',
	EditTenant = 'edit_tenant',
	EditGroup = 'edit_group',
	MoveGroup = 'move_group',
	ViewRoles = 'system_role_manager_view_page',
	AddSystemRole = 'add_system_role',
	EditSystemRole = 'edit_system_role',
	DeleteSystemRole = 'delete_system_role',
	CloneSystemRole = 'clone_system_role',
	ChangeGroupPassword = 'change_group_password',
	DeleteTenant = 'delete_tenant',
	DeleteGroup = 'delete_group',
	RestoreTenant = 'restore_tenant',
	RestoreGroup = 'restore_group',
	SystemRoleManagerViewPage = 'system_role_manager_view_page',
	ExportSystemRoles = 'export_system_roles',
	ExportGroups = 'export_groups',
	ExportOwnershipGroups = 'export_ownership_groups',
	ExportUsers = 'export_users',
	CyberSecurity = 'cyber_security',
	Lms = 'lms',
	ViewDashboardUserActivity = 'view_user_activity',
	ViewDashboardOverview = 'view_dashboard_overview',
	ViewDashboardSpotcheck = 'view_dashboard_spotcheck',
	ViewDashboardElearning = 'view_dashboard_elearning',
	ViewDashboardMyResults = 'view_dashboard_my_results',
	ViewDashboardPhishing = 'view_dashboard_phishing',
	AddReport = 'create_report_current_historical',
	ViewStandardReports = 'view_standard_reports',
	ExportAuditLog = 'export_user_audit_log',
	ViewAuditLog = 'view_user_audit_log',
	GenerateDashboardExecutiveSummary = 'generate_dashboard_executive_summary',
	UserNotifications = 'add_user_notification',
	GetSystems = 'get_systems'
}

export interface OnUsersDisableRequest {
	shouldLogout: boolean;
}

export interface OnUsersDisableResponse {
	onUsersDisable: {
		peakV3Ids: string[];
		mfa?: boolean;
	};
}

export type ResetUserPasswordsRequest = MutationRequest<{ ids: string[] }>;
export type ResetUserPasswordsResponse = {
	resetPassword: {
		totalCount: number;
	};
};

export type GetUsersImportTemplateRequest = {
	groupId: number;
};

export type GetUsersImportTemplateResponse = {
	getUsersImportTemplate: DownloadUrl;
};

export type UserForValidation = {
	firstName: string;
	lastName: string;
	email: string;
	login: string;
	phone?: string;
	systemRole: string;
	group: string;
	timezone?: string;
	language?: string;
	jobClass?: string;
	userRole?: string;
};

export type ValidateUsersRequest = {
	groupId: number;
	users: UserForValidation[];
};

export type ValidatedUser = {
	firstName: string;
	lastName: string;
	email: string;
	login: string;
	phone: string;
	systemRole: SystemRolesListItem | null;
	group: UserGroup | null;
	timezone: TimezoneEntity | null;
	language: Entity | null;
	jobClass: JobClass | null;
	userRole: UserRole | null;
	errors: ValidationError[];
	warnings: ValidationWarning[];
};
export type ValidateUsersResponse = {
	validateUsers: ValidatedUser[];
};

export type GetFailedUsersRequest = {
	input: {
		jwt: string;
	};
};

export interface FailedUser {
	errors: ValidationError[];
	userInput: AddUserRequest['input'];
}

export enum FailedUsersStatus {
	Failed = 'failed',
	InProgress = 'inProgress',
	Complete = 'complete'
}
export interface GetFailedUsersResponse {
	getFailedUsers: {
		status: FailedUsersStatus;
		requestId: string;
		failedUsers: FailedUser[];
	};
}

export type ResetMFARequest = MutationRequest<{
	peakV3Id: string;
}>;
