import { ColumnProps, TableProps } from 'antd/lib/table';
import {
	FilterConfirmProps,
	SorterResult,
	SortOrder
} from 'antd/lib/table/interface';
import { APP_CONFIG } from 'app-config';
import { Icon, Input } from 'Components';
import { usePrevious } from 'Hooks/usePrevious';
import { debounce, isArray, isEqual, isNil, isString } from 'lodash';
import { useState, useEffect } from 'react';

const DEBOUNCE_TIME = 400;

// eslint-disable-next-line
type RecordType = Record<any, any>;

const SORT_DIRECTIONS: TableProps<RecordType>['sortDirections'] = [
	'ascend',
	'descend',
	'ascend'
];

export type ColumnFilters<T extends RecordType> = {
	[K in keyof T]:  // eslint-disable-next-line
		| (Required<T>[K] extends Array<any>
				? Required<T>[K]
				: Required<T>[K][])
		| undefined;
};
export interface SearchChangeArgs {
	value: string;
	selectedKeys: React.Key[];
	confirm?: (param?: FilterConfirmProps | undefined) => void;
}

interface SearchChangeArgsBlur extends SearchChangeArgs {
	setSelectedKeys: (selectedKeys: React.Key[]) => void;
}
export function createSortDescription<E>(result: SorterResult<E>): string {
	const { columnKey, order } = result;

	const definedSortOrder = order === 'ascend' ? 'asc' : 'desc';
	return `${columnKey}:${definedSortOrder}`;
}

export function sortDescriptionToSorter<E>(
	value: string
): Required<Pick<SorterResult<E>, 'columnKey' | 'order'>> | undefined {
	const [columnKey, order] = value.split(':');
	return columnKey
		? {
				columnKey,
				order: order === 'asc' ? 'ascend' : 'descend'
		  }
		: undefined;
}

function toFilters<F extends RecordType>(values: F | undefined) {
	if (!values) {
		return;
	}

	return Object.keys(values).reduce<ColumnFilters<F>>(
		(acc, key: keyof ColumnFilters<F>) => {
			const value = values[key];
			if (isNil(value) || (isString(value) && !value)) return acc;
			acc[key] = (isArray(value)
				? value
				: [value]) as ColumnFilters<F>[typeof key];
			return acc;
		},
		{} as ColumnFilters<F>
	);
}

interface CreateColumnsHelpers<T, F extends RecordType> {
	search: ColumnProps<T>;
	filter: ColumnProps<T>;
	sort: ColumnProps<T>;
	filters: ColumnFilters<F>;
	getSortOrder: (columnKey: keyof F) => SortOrder;
	onChange: TableProps<T>['onChange'];
}
interface UseCreateColumnsProps<F extends RecordType> {
	filterValues: F | undefined;
	sortDesc?: string;
	isControllable?: boolean;
}
export interface UseCreateColumnsInterface<T, F extends RecordType> {
	(value: UseCreateColumnsProps<F>): CreateColumnsHelpers<T, F>;
}
export function useCreateColumns<T, F extends RecordType>({
	filterValues,
	sortDesc,
	isControllable = false
}: UseCreateColumnsProps<F>): CreateColumnsHelpers<T, F> {
	const [filteredInfo, setFilteredInfo] = useState<
		CreateColumnsHelpers<T, F>['filters'] | undefined
	>(toFilters(filterValues));
	const previousFilterValues = usePrevious(filterValues);

	useEffect(() => {
		if (!isEqual(previousFilterValues, filterValues)) {
			if (isControllable) {
				setFilteredInfo(toFilters(filterValues));
			} else if (!previousFilterValues && filterValues) {
				setFilteredInfo((currentValue) => {
					if (!currentValue) {
						return toFilters(filterValues);
					}
					return currentValue;
				});
			}
		}
	}, [isControllable, previousFilterValues, filterValues]);

	const onChange: TableProps<T>['onChange'] = (_, filters) => {
		setFilteredInfo(filters as CreateColumnsHelpers<T, F>['filters']);
	};

	// eslint-disable-next-line
	const onColumnSearch = debounce(
		({ selectedKeys, value, confirm }: SearchChangeArgs) => {
			const previousValue = selectedKeys[0] as string | undefined;

			// submit change if value length is valid or when value has been fully cleared
			if (
				value?.length >= APP_CONFIG.SEARCH_MIN_LENGTH ||
				(!value && previousValue?.length)
			) {
				confirm?.({ closeDropdown: false });
			}
		},
		DEBOUNCE_TIME
	);

	// eslint-disable-next-line
	const onSearchInputBlur = ({
		value,
		setSelectedKeys,
		confirm
	}: SearchChangeArgsBlur) => {
		if (value?.length < APP_CONFIG.SEARCH_MIN_LENGTH) {
			setSelectedKeys([]);
			confirm?.({ closeDropdown: true });
		}
	};

	const helpers: CreateColumnsHelpers<T, F> = {
		sort: {
			sorter: true,
			sortDirections: SORT_DIRECTIONS,
			showSorterTooltip: false
		},
		search: {
			filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
				return (
					<Input
						value={selectedKeys[0]}
						allowClear
						onChange={(e) => {
							// do not set empty string so that empty value is not considered as a filter and
							// icon idicating applied filter will remain unselected
							setSelectedKeys(
								e.target.value ? [e.target.value] : []
							);
							onColumnSearch({
								value: e.target.value,
								selectedKeys,
								confirm
							});
						}}
						onBlur={(e) => {
							const value = e.target.value;
							onSearchInputBlur({
								value,
								selectedKeys,
								setSelectedKeys,
								confirm
							});
						}}
					/>
				);
			},
			filterIcon: <Icon type="search" />
		},
		filter: {
			filterIcon: <Icon type="filter" />
		},
		filters: filteredInfo || ({} as CreateColumnsHelpers<T, F>['filters']),
		getSortOrder: (columnKey) => {
			const sorter = sortDescriptionToSorter(sortDesc || '');
			return sorter?.columnKey === columnKey ? sorter.order : null;
		},
		onChange
	};

	return helpers;
}
