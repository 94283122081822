import { Text } from 'Components';
import { GROUP_LEGEND_TYPE_MAP } from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupTreeLegend';
import { GroupActiveStatus, OwnershipGroup } from 'Services/Api/Groups/Types';

export const ownershipGroupToTreeNode = (item: OwnershipGroup) => {
	const color =
		item.status === GroupActiveStatus.Inactive
			? GROUP_LEGEND_TYPE_MAP['inactive']
			: undefined;

	return {
		key: item.id,
		title: item.name,
		parentKey: null,
		label: <Text color={color}>{item.name}</Text>
	};
};
