import * as yup from 'yup';
import { FIELD_TYPE_VALIDATION } from 'Helpers/validations';
import { maxNumber } from 'Helpers/validations/validations.helpers';

const baseSchema = (maxGroupNameAmountCharacters: number) =>
	yup.object().shape({
		levelName: FIELD_TYPE_VALIDATION.text
			.label('groupsHierarchy.formXLSX.levelItem.name')
			.min(2)
			.max(maxGroupNameAmountCharacters),
		numberOfGroups: FIELD_TYPE_VALIDATION.numeric
			.label('groupsHierarchy.formXLSX.levelItem.numberOfGroups')
			.max(3, (params) => maxNumber({ ...params, max: 999 }))
	});

const schema = (maxGroupNameAmountCharacters: number) =>
	yup.object().shape({
		levels: yup.array().of(baseSchema(maxGroupNameAmountCharacters))
	});

export default schema;
