import { TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { Fx, Layout, Table } from 'Components';
import { createSortDescription } from 'Components/Table/createColumns';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { LayoutHolder, DownloadReportButton } from 'Pages/Dashboard/components';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ElearningGroupSummary } from 'Services/Api/Dashboard/Types';
import { ReportType } from 'Services/Api/Reports/Types';
import { getIsPending } from 'Stores/util';
import { useColumns } from './columns';

function GroupSummary() {
	const { t } = useTranslation();
	const { filters } = useDashboardContext();
	const { dashboardStore } = useStores();
	const {
		elearning: { groupSummary },
		status
	} = dashboardStore;

	const handleTableChange: TableProps<ElearningGroupSummary>['onChange'] = (
		_,
		__,
		sorter
	) => {
		const sort = createSortDescription<ElearningGroupSummary>(
			sorter as SorterResult<ElearningGroupSummary>
		);

		dashboardStore.getElearningGroupSummary({
			...groupSummary.params,
			sort
		});
	};

	useEffect(() => {
		dashboardStore.getElearningGroupSummary(filters);
		// eslint-disable-next-line
	}, [filters]);

	return (
		<LayoutHolder>
			<Layout>
				<Layout.Heading>
					<Fx align="middle" justify="space-between">
						<h3>{t('dashboard.groupSummary.title')}</h3>
						<DownloadReportButton
							reportType={ReportType.ElearningGroupSummary}
						/>
					</Fx>
				</Layout.Heading>
				<Table<ElearningGroupSummary>
					dataSource={groupSummary.data}
					rowKey="groupName"
					columns={useColumns({
						params: groupSummary.params
					})}
					pagination={false}
					onChange={handleTableChange}
					loading={getIsPending(status.getElearningGroupSummary)}
				/>
			</Layout>
		</LayoutHolder>
	);
}

export default observer(GroupSummary);
