import { usePermission } from 'Components/Permission/Permission.hooks';
import { useStores } from 'Hooks/useStore';
import { useIsLMSModulesHidden } from 'Hooks';
import { useCallback, useMemo, useEffect, useState } from 'react';
import { AppHeaderMenuItem, NavMenuKey } from './AppHeader.types';
import { MENU_ITEMS } from './AppHeader.constants';

export const useMenuItems = () => {
	const [menuItems, setMenuItems] = useState<AppHeaderMenuItem[]>(MENU_ITEMS);
	const { getHasPermission } = usePermission();
	const { systemsStore } = useStores();
	const isModulesNavHidden = useIsLMSModulesHidden();

	useEffect(() => {
		async function getSystems() {
			await systemsStore.loadSystems();
			if (systemsStore.systems.length && !isModulesNavHidden) {
				setMenuItems((menuItems) => {
					return menuItems.concat({
						title: 'appHeader.modules',
						children: systemsStore.systems.map((system) => ({
							key: NavMenuKey.PeakPortal,
							title: system.systemName,
							suffix: system.id
						}))
					});
				});
			}
		}
		getSystems();
		//eslint-disable-next-line
	}, []);

	const getIsMenuItemVisible = useCallback(
		(menuItem: AppHeaderMenuItem) => {
			return (
				!menuItem.permission ||
				getHasPermission(menuItem.permission).permitted
			);
		},
		[getHasPermission]
	);

	const calculatedMenuItems = useMemo(() => {
		return menuItems.reduce<typeof MENU_ITEMS>((acc, value) => {
			if (value.children) {
				const children = value.children.filter(getIsMenuItemVisible);
				if (children.length) {
					acc.push({
						...value,
						children
					});
				}
			} else {
				if (getIsMenuItemVisible(value)) {
					acc.push(value);
				}
			}
			return acc;
		}, []);
	}, [getIsMenuItemVisible, menuItems]);

	return { menuItems: calculatedMenuItems };
};
