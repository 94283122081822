import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Form, Formik, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';

import { EditableLayout, Notification, Spin } from 'Components';
import { getAppRoutes } from 'Pages/App/App.constants';
import { ADD_SYSTEM_ROLE } from 'Services/Api/Roles/Mutations';
import { AddSystemRoleRequest } from 'Services/Api/Roles/Types';
import { schema } from '../EditRole/schema';
import { checkApolloError } from 'Helpers/graphql';
import EditRoleTabs from '../EditRole/EditRoleTabs';
import { Details } from '../EditRole/tabs';
import { useRoleForm } from '../EditRole/EditRole.hooks';
import {
	AddRoleFormValues,
	EditRoleFormValues
} from '../EditRole/EditRole.types';
import { getSubmitValues } from '../EditRole/EditRole.helpers';

const AddRole = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const [addSystemRole] = useMutation<unknown, AddSystemRoleRequest>(
		ADD_SYSTEM_ROLE,
		{
			ignoreResults: false
		}
	);

	const onSubmit = async (
		values: AddRoleFormValues,
		helpers: FormikHelpers<EditRoleFormValues>
	) => {
		try {
			await addSystemRole({
				variables: {
					input: getSubmitValues(values)
				}
			});
			history.push(getAppRoutes().ROLES);
			Notification.success({
				description: t('role.addRole.notificationSuccess')
			});
		} catch (e) {
			const errors = checkApolloError(e).getFieldErrors(
				t,
				schema.describe().fields,
				'role.addRole.errorCodes'
			);
			if (errors) {
				helpers.setErrors(errors);
			} else {
				Notification.error({
					description: t('errorCodes.genericErrorMessage')
				});
			}
		}
	};

	const goBack = () => {
		history.push(getAppRoutes().ROLES);
	};

	const { initialValues } = useRoleForm();

	if (!initialValues) {
		return <Spin />;
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={schema}
		>
			<Form>
				<EditableLayout
					goBack={goBack}
					heading={t('role.addRole.title')}
					okText={t('role.addRole.submitText')}
				>
					<Details isNew />
					<EditRoleTabs isNew />
				</EditableLayout>
			</Form>
		</Formik>
	);
};

export default AddRole;
