import { Table as AntTable, TableProps as AntTableProps } from 'antd';

import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './Table.module.scss';
import { isBoolean, isUndefined } from 'lodash';
import TotalCount from './TotalCount';
import EmptyText from './EmptyText';

const DEFAULT_SCROLL_VALUES = { y: '100%' };

type TableProps<T> = AntTableProps<T> & {
	totalVisible?: boolean | number;
	totalClassName?: string;
};
// eslint-disable-next-line
function Table<RecordType extends Record<any, any>>({
	totalClassName,
	totalVisible,
	...props
}: TableProps<RecordType>) {
	const { t } = useTranslation();

	const totalCountValue = isUndefined(totalVisible)
		? undefined
		: isBoolean(totalVisible)
		? props.dataSource?.length ?? 0
		: totalVisible;
	return (
		<>
			<AntTable
				{...props}
				locale={{
					...props.locale,
					filterReset: t('Components.table.reset'),
					filterConfirm: t('ok'),
					emptyText: (
						<EmptyText
							loading={props.loading as boolean}
							locale={props.locale}
						/>
					)
				}}
				className={cn(styles.root, props.className)}
				scroll={DEFAULT_SCROLL_VALUES}
			/>
			<TotalCount count={totalCountValue} className={totalClassName} />
		</>
	);
}

export default Table;
