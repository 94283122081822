import { DataNode, EventDataNode } from 'antd/lib/tree';

import { usePrevious } from 'Hooks/usePrevious';
import { isEqual, lowerCase, sortBy } from 'lodash';
import { arrayToTree } from 'performant-array-to-tree';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
	toDataTreeValue,
	getValueAncestorKeys,
	mergeData,
	getTruthyExecutor,
	getFalsyExecutorFromMeta,
	getChildrenIDsFromParents
} from './DataTree.helpers';
import {
	DataTreeData,
	DataTreeContextProps,
	DataTreeContextValue,
	DataTreeNodeItem,
	DataTreeCache
} from './DataTree.types';

export function useDataTree<T>(props: DataTreeContextProps<T>) {
	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState<DataTreeNodeItem[] | undefined>(
		undefined
	);
	const valuePrevious = usePrevious(value);
	const [checkable, setCheckable] = useState(false);
	const [expandedKeys, setExpandedKeys] = useState<
		DataTreeContextValue['expandedKeys']
	>(undefined);
	const [data, setData] = useState<DataTreeData<T>>({});

	const [initialized, setInitialized] = useState(false);
	const initializedPrevious = usePrevious(initialized);

	const isDirty = !isEqual(
		toDataTreeValue(props.initialValue),
		toDataTreeValue(value)
	);

	const cacheRef = useRef<DataTreeCache>({
		loaded: {},
		leafs: {},
		initializeStarted: false
	});

	const {
		initialValue,
		dataSource,
		dataSyncRef,
		isMultiSelection,
		onChange,
		loadMore,
		toNode,
		load,
		loadInitialValue
	} = props;

	const isMountedRef = useRef(false);

	// Prevent running into the React warning 'Can’t perform a React state update on an unmounted component'.
	// when setting state in context of async operation
	const updateAfterAsync = useCallback((cb: () => void) => {
		if (isMountedRef.current) {
			cb();
		}
	}, []);

	// Callback function for when a treeNode is expanded or collapsed
	const onExpand: DataTreeContextValue['onExpand'] = (keys) => {
		setExpandedKeys(keys);
	};

	// Callback function for when the user clicks a treeNode
	const onSelect: DataTreeContextValue['onSelect'] = (keys) => {
		// prevent unselecting current item
		if (!keys.length) {
			return;
		}
		setValue(
			keys.reduce<DataTreeNodeItem[]>((acc, key) => {
				const node =
					(props.root?.key === key && props.root) || data[key];
				if (node) acc.push(node);
				return acc;
			}, [])
		);
	};

	const selectInTree: DataTreeContextValue['selectInTree'] = async (key) => {
		// Load data if it hasn't been loaded before
		const newData = await loadDataNodesData([{ key }], data);
		setData(newData);
		const node = newData[key];
		if (!node) {
			return;
		}

		const isMultipleSelectionValid = !checkable || node.checkable !== false;
		const isSingleSelectionValid = !(
			node.disabled || node.key === props.disabledValue?.key
		);
		const isValueSelected = value?.some(({ key }) => key === node.key);

		if (
			isValueSelected ||
			!isMultipleSelectionValid ||
			!isSingleSelectionValid
		) {
			return;
		}

		setValue((value) => {
			if (!isMultiSelection) {
				return checkable ? [...(value || []), node] : [node];
			}

			const executor = getChildrenIDsFromParents<T>(newData);
			const ids = executor([node]);
			if (isValueSelected)
				return value?.filter(({ key }) => !ids.includes(key as number));

			const newNodes = ids.reduce<DataTreeNodeItem[]>((acc, id) => {
				if (newData[id]) {
					acc.push(newData[id] as DataTreeNodeItem);
				}
				return acc;
			}, []);

			return (value ?? []).concat(newNodes);
		});

		// Expand tree at the selected item from the dropdown
		setExpandedKeys(getValueAncestorKeys([node], newData, props));
	};

	// Callback function for when the user clicks a treeNode checkbox
	const onCheck: DataTreeContextValue['onCheck'] = (checked, meta) => {
		setValue((value) => {
			if (isMultiSelection) {
				if (meta.checked) {
					const truthyExecutor = getTruthyExecutor();
					const nodes = truthyExecutor([meta.node]);
					return value?.concat(nodes as DataTreeNodeItem[]);
				}
				const falsyExecutor = getFalsyExecutorFromMeta();
				const mapper = falsyExecutor([meta.node]);
				return value?.filter((node) => !mapper.has(node.key as number));
			}
			const keys = Array.isArray(checked) ? checked : checked.checked;
			return keys.reduce<DataTreeNodeItem[]>((acc, key) => {
				const node = data[key];
				if (node) acc.push(node);
				return acc;
			}, []);
		});
	};

	const loadDataNode = useCallback(
		async (dataNode: Pick<EventDataNode, 'key'>) => {
			const key = data[dataNode.key]?.parents?.[0]?.key ?? dataNode.key;
			if (!loadMore || cacheRef.current.loaded[key]) {
				return [];
			}

			const result = await loadMore(dataNode.key as number);

			cacheRef.current.loaded[dataNode.key] = true;
			cacheRef.current.leafs[dataNode.key] =
				!result.length ||
				(result.length === 1 && toNode(result[0]).key === dataNode.key);

			return result;
		},
		// do not include `toNode`
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data, loadMore]
	);

	const getUpdatedData = useCallback(
		(list: T[], currentData: typeof data) => {
			return mergeData(
				list,
				currentData,
				{
					disabledValue: props.disabledValue,
					toNode: props.toNode
				},
				cacheRef.current
			);
		},
		// do not consider `toNode`/`disabledValue` as dynamic props
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const loadDataNodesData = useCallback(
		async (
			value: DataTreeContextValue['value'],
			data: DataTreeData<T>
		): Promise<DataTreeData<T>> => {
			let newData = data;
			if (!value?.length || !loadInitialValue) {
				return newData;
			}

			const ids = await loadInitialValue(value);
			await Promise.all(
				ids.map(async (id) => {
					const result = await loadDataNode({ key: id });
					newData = getUpdatedData(result, newData);
				})
			);
			return newData;
		},
		[loadInitialValue, loadDataNode, getUpdatedData]
	);

	const treeData = useMemo(() => {
		let children = arrayToTree(
			sortBy(Object.values(data), (item) =>
				lowerCase(item?.title).replace(/\s/g, '')
			),
			{
				dataField: null,
				id: 'key',
				parentId: 'parentKey'
			}
		) as DataNode[];
		if (props.root) {
			children = [
				{
					...props.root,
					children
				}
			];
		}

		return children;
	}, [data, props.root]);

	const initValue = useCallback(() => {
		const initialVal = props.initialValue || [];

		// By this time, data has been loaded
		// so keys from `initialValue` considered valid if the exist in data source
		let newValue = initialVal.reduce<DataTreeNodeItem[]>((acc, item) => {
			const node = data[item.key];
			if (node) acc.push(node);
			return acc;
		}, []);

		const [firstNodeInTree] = treeData;

		const firstOption =
			data[firstNodeInTree.key] ||
			(props.root?.key === firstNodeInTree.key ? props.root : undefined);

		// Determine fallback value (first option in a tree) in case not hvaing valid initial values (data doesn't exist)
		const defaultOption = newValue.length
			? undefined
			: props.defaultActiveFirstOption
			? firstOption
			: undefined;

		newValue = newValue.length
			? newValue
			: defaultOption
			? [defaultOption]
			: [];

		setValue(newValue);
		// Expand value in a tree
		setExpandedKeys(
			getValueAncestorKeys(
				[
					...newValue,
					...(props.disabledValue ? [props.disabledValue] : [])
				],
				data,
				props
			)
		);
	}, [props, data, treeData]);

	const initData = useCallback(async () => {
		setLoading(true);
		setInitialized(false);

		if (!dataSource && !load) {
			return;
		}

		if (Array.isArray(dataSource) && !dataSource.length) {
			setLoading(false);
			return;
		}

		// Reset previous data by keys since it gets fully overwritten
		cacheRef.current = {
			loaded: {},
			leafs: {},
			initializeStarted: true
		};

		try {
			let newData = dataSource ? getUpdatedData(dataSource, {}) : {};

			if (load) {
				const initialData = await load();
				newData = await loadDataNodesData(
					initialValue,
					getUpdatedData(initialData, newData)
				);
			}
			updateAfterAsync(() => {
				setData(newData);
				setInitialized(true);
			});
		} finally {
			setLoading(false);
		}
	}, [
		initialValue,
		dataSource,
		load,
		loadDataNodesData,
		getUpdatedData,
		updateAfterAsync
	]);

	const loadData = props.loadMore
		? async (dataNode: EventDataNode) => {
				const result = await loadDataNode(dataNode);
				updateAfterAsync(() => {
					const updatedData = getUpdatedData(result, data);
					setData(updatedData);

					if (
						!isMultiSelection &&
						dataNode.checked &&
						!dataNode.children?.length &&
						// @ts-ignore: ts doesn't parse correctly type 'EventDataNode' and shows that 'parentKey' doesn't present in dataNode object
						dataNode?.parentKey === null
					) {
						const executor = getChildrenIDsFromParents(updatedData);
						// @ts-ignore: inconsistency between 'DataTreeNodeItem' and 'EventDataNode'
						const newNodes = executor([dataNode]).reduce<
							DataTreeNodeItem[]
						>((acc, id) => {
							if (
								updatedData[id] &&
								dataNode.key.toString() !== id.toString()
							) {
								acc.push(updatedData[id] as DataTreeNodeItem);
							}
							return acc;
						}, []);

						setValue((value) => (value ?? []).concat(newNodes));
					}
				});
		  }
		: undefined;

	useEffect(() => {
		isMountedRef.current = true;
		return () => {
			isMountedRef.current = false;
		};
	}, []);

	// Load initial data
	useEffect(() => {
		if (!cacheRef.current.initializeStarted) {
			initData();
		}
	}, [initData]);

	const previousDataSource = usePrevious(dataSource);

	// After initialization, begin tracking changes in `dataSource` and overwrite data with the new one
	useEffect(() => {
		if (
			initialized &&
			previousDataSource &&
			dataSource &&
			!isEqual(previousDataSource, dataSource)
		) {
			setData(getUpdatedData(dataSource, {}));
		}
	}, [initialized, previousDataSource, dataSource, setData, getUpdatedData]);

	// Track initialization progress in order to set value after data is loaded
	useEffect(() => {
		if (initialized !== initializedPrevious && initialized) {
			initValue();
		}
	}, [initialized, initializedPrevious, initValue]);

	useEffect(() => {
		if (props.clearValue) {
			setValue(undefined);
		}
	}, [props.clearValue]);

	useEffect(() => {
		if (!isEqual(value, valuePrevious)) {
			onChange?.(value);
		}
	}, [value, valuePrevious, onChange]);

	useEffect(() => {
		if (dataSyncRef) {
			dataSyncRef.current.data = data;
			dataSyncRef.current.expandedKeys = expandedKeys ?? [];
		}
	}, [data, dataSyncRef, expandedKeys]);

	return {
		data,
		initialized,
		dirty: isDirty,
		loading,
		value,
		treeData,
		loadData,
		checkable,
		setCheckable,
		expandedKeys,
		nested: Boolean(
			props.loadMore || treeData.some((item) => item.children?.length)
		),
		root: props.root,
		onExpand,
		onSelect,
		onCheck,
		selectInTree,
		reInitialize: initData
	};
}
