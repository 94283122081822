import { v4 as uuid } from 'uuid';
import { isEmpty, omit, flow } from 'lodash';
import {
	DEFAULT_GROUP_ABBREVIATION,
	IS_PROPERTY_SIGN,
	LEVEL_COLUMN_NAME
} from './GroupsHierarchy.constants';
import {
	Value,
	Values,
	HierarchyGroup,
	HierarchyValues,
	GroupsHierarchyParentNameLevel,
	ValuesXLSX,
	ValueXLSX,
	ParsedValue
} from './GroupsHierarchy.types';
import {
	GroupActiveStatus,
	Group,
	GetGroupResponse,
	CreateHierarchyGroupsRequest
} from 'Services/Api/Groups/Types';
import { PATTERN } from 'Helpers/validations';
import { SheetData } from 'app-types';
interface CreateNewHierarchyGroupParams {
	parentId: string;
	level: number;
	rowNumber?: number;
	name?: string;
	isProperty?: boolean;
	id?: string;
}

export const createNewHierarchyGroup = ({
	level = 2,
	name = '',
	parentId,
	isProperty = false,
	rowNumber,
	id = uuid()
}: CreateNewHierarchyGroupParams): HierarchyGroup => {
	return {
		isProperty,
		syncCode: '',
		active: GroupActiveStatus.Active,
		id,
		level,
		parentId,
		name,
		rowNumber
	};
};

interface CurrentGroup {
	groups: string[];
	parentName: string | null;
	groupName: string;
	isProperty: boolean;
	level: number;
}
interface IDSManagerOutput {
	parentId: string;
	id: string;
}
function createIdsManager() {
	let idsRelation: Record<string, Record<string, string>> = Object.create(
		null
	);

	function idsManager(
		parentName: string | null,
		groupName: string,
		level: number,
		tenantID: string
	): IDSManagerOutput {
		let parentId = tenantID;
		const id = uuid();
		if (parentName && idsRelation?.[level - 1]?.[parentName]) {
			parentId = idsRelation[level - 1][parentName];
		}
		idsRelation[level] = {
			...idsRelation[level],
			[groupName]: id
		};

		return {
			parentId,
			id
		};
	}
	const clearIDsManager = () => {
		idsRelation = Object.create(null);
	};

	return {
		idsManager,
		clearIDsManager
	};
}
export const { idsManager, clearIDsManager } = createIdsManager();
export function createHierarchyFromXLSXFile(
	data: Record<string, SheetData>,
	parent: Group | null,
	sheetNames: string[]
) {
	if (!parent) return null;
	const [columnA, ...restColumns] = sheetNames;
	const [columnB] = restColumns;
	const omittedData = omit(data, ['!ref', '!margins']);
	const dataKeys = Object.keys(omittedData);
	const tenant: HierarchyGroup = {
		...parent,
		syncCode: String(parent.syncCode),
		id: String(parent.id),
		parentId: null
	};

	// rule: amount of items in columnB equal to groups amount
	const columnBCells = [];

	for (let i = 0; i < dataKeys.length; i += 1) {
		const currentKey = dataKeys[i];
		const [cellLetter, ...cellNumbers] = currentKey;

		if (cellLetter === columnB) {
			columnBCells.push(cellNumbers.join(''));
		}
	}

	// delete column title
	columnBCells.shift();

	return [
		tenant,
		...columnBCells.map((cellNumber) => {
			const currentGroup = restColumns.reduce<CurrentGroup>(
				(acc, columnLetter, index, arr) => {
					const cellName = columnLetter + cellNumber;
					const currentCell = omittedData[cellName];
					if (currentCell?.v) {
						acc.groups.push(currentCell.v);
					}
					const nextLetter = arr[index + 1];

					if (
						(nextLetter &&
							!omittedData[nextLetter + cellNumber]?.v.length) ||
						!nextLetter
					) {
						acc.level =
							acc.level ||
							Number(omittedData[columnLetter + 1].v.slice(-1));
					}
					if (arr.length === index + 1) {
						acc.isProperty =
							omittedData[columnA + cellNumber]?.v ===
							IS_PROPERTY_SIGN;

						const groupRelation = acc.groups.slice(-2);
						const isSingleGroup = groupRelation.length === 1;
						acc.parentName = isSingleGroup
							? null
							: groupRelation[0];
						acc.groupName = isSingleGroup
							? groupRelation[0]
							: groupRelation[1];
					}

					return acc;
				},
				{
					groups: [],
					parentName: '',
					groupName: '',
					isProperty: false,
					level: 0
				}
			);

			const { parentName, groupName, level, isProperty } = currentGroup;
			const { id, parentId } = idsManager(
				parentName,
				groupName,
				level,
				String(parent.id)
			);

			return createNewHierarchyGroup({
				id,
				parentId,
				name: groupName,
				isProperty,
				level
			});
		})
	];
}

export function getParentNameLevel(
	data?: GetGroupResponse
): GroupsHierarchyParentNameLevel | null {
	if (!data) return null;

	const parentData = data.getGroup;

	return {
		parentLevel: parentData.level ?? 1,
		parentName: parentData.name
	};
}

// level 1,2,3,4,5 | undefined
export function createDefaultValues(
	level: number | undefined,
	parentLevel: number | undefined,
	values: Record<string, Value>
) {
	if (typeof level === 'undefined' || typeof parentLevel === 'undefined')
		return {};

	return Array(level - parentLevel)
		.fill(null)
		.map((_, index) => `level${parentLevel + 1 + index}`)
		.reduce<Record<string, Value>>((acc, levelKey) => {
			acc[levelKey] = values[levelKey];

			return acc;
		}, {});
}

interface GroupMapperOption {
	parentId: string;
	levelNumber: number;
	isProperty: boolean;
}

function groupMapper(
	groupsNames: Value['groupsNames'],
	temporaryIds: string[],
	{ parentId, levelNumber, isProperty }: GroupMapperOption
): HierarchyGroup[] {
	return groupsNames.map(({ name }, index) => {
		const id = uuid();
		temporaryIds.push(id);
		return createNewHierarchyGroup({
			id,
			parentId,
			isProperty,
			level: levelNumber,
			name: name.trim().length
				? name
				: DEFAULT_GROUP_ABBREVIATION + (index + 1)
		});
	});
}

export function prepareValues(values: Values, parent: Group): HierarchyGroup[] {
	let ids: string[] = [];

	const tenant: HierarchyGroup = {
		...parent,
		syncCode: String(parent.syncCode),
		id: String(parent.id),
		parentId: null
	};

	return Object.keys(values).reduce<HierarchyGroup[]>(
		(acc, key, index) => {
			const levelNumber = Number(key.slice(-1));

			const { isProperty, groupsNames } = values[key];
			let groups: HierarchyGroup[] = [];

			if (!index) {
				groups = groupMapper(groupsNames, ids, {
					parentId: String(parent.id),
					isProperty,
					levelNumber
				});
			} else {
				const temporaryIds: string[] = [];
				const temporaryGroups: HierarchyGroup[][] = ids.map(
					(parentId) =>
						groupMapper(groupsNames, temporaryIds, {
							parentId,
							isProperty,
							levelNumber
						})
				);
				groups = temporaryGroups.flat();
				ids = [...temporaryIds];
			}

			return acc.concat(groups);
		},
		[tenant]
	);
}

export function prepareFormValues(
	hierarchyGroups: HierarchyGroup[]
): HierarchyValues {
	return hierarchyGroups.reduce<HierarchyValues>((acc, { id }, ind, arr) => {
		if (ind + 1 === arr.length) return acc;

		const testArr = arr.slice(ind + 1);
		const arrValues = testArr.filter(({ parentId }) => parentId === id);
		if (arrValues.length) {
			acc[id] = arrValues.map((hierarchyGroup, rowNumber) => ({
				...hierarchyGroup,
				rowNumber
			}));
		}

		return acc;
	}, {});
}

function getHierarchyThree(hierarchyGroups: HierarchyGroup[]) {
	// eslint-disable-next-line
	return hierarchyGroups.reduce<Record<string, any>>((acc, group) => {
		const { id, parentId, isProperty, level } = group;
		if (!acc[level]) {
			acc[level] = {};
		}

		acc[level] = {
			...acc[level],
			[id]: {
				value: isProperty,
				id,
				parentId
			}
		};
		return acc;
	}, {});
}

type Direction = 'up' | 'down';
interface GetChainOptions {
	level: number;
	ids: string[];
	needToSkip: boolean;
}
// @ts-ignore
function getChain(
	direction: Direction,
	// eslint-disable-next-line
	hierarchyThree: Record<string, any>,
	result: Record<string, string[]>,
	{ level, ids, needToSkip }: GetChainOptions
) {
	if (level === 0 || level === 7) {
		return result;
	}

	if (!needToSkip) {
		result[level] = Array.isArray(result[level])
			? ids.every((id) => result[level].includes(id))
				? result[level]
				: result[level].concat(ids)
			: ids;
	}

	if (
		direction === 'up' &&
		ids.every((id) => hierarchyThree?.[level]?.[id])
	) {
		const { parentId } = hierarchyThree[level][ids[0]];
		return getChain(direction, hierarchyThree, result, {
			level: level - 1,
			ids: [parentId],
			needToSkip: false
		});
	} else if (direction === 'down') {
		const nextLevel = level + 1;
		if (!hierarchyThree[nextLevel]) return result;
		const nextIds = Object.keys(
			hierarchyThree[nextLevel]
		).filter((nextId) =>
			ids.some((id) => hierarchyThree[nextLevel][nextId].parentId === id)
		);

		if (nextIds.length) {
			return getChain(direction, hierarchyThree, result, {
				level: nextLevel,
				ids: nextIds,
				needToSkip: false
			});
		}

		return result;
	}
}

const getChainUp = getChain.bind(null, 'up');

const getChainDown = getChain.bind(null, 'down');

export function getDisabledIds(
	hierarchyGroups: HierarchyGroup[],
	activesIsPropertyLevel: Record<string, string[]>
): Record<string, string[]> {
	const result: Record<string, string[]> = {};

	if (isEmpty(activesIsPropertyLevel)) return {};
	const hierarchyThree = getHierarchyThree(hierarchyGroups);
	const executors = [getChainUp, getChainDown];

	Object.keys(activesIsPropertyLevel).forEach((level) => {
		const ids = activesIsPropertyLevel[level];
		ids.forEach((id) => {
			executors.forEach((fn) =>
				fn(hierarchyThree, result, {
					level: Number(level),
					ids: [id],
					needToSkip: true
				})
			);
		});
	});

	return result;
}

export function createHierarchyInput(
	values: HierarchyValues,
	groupId: string
): CreateHierarchyGroupsRequest['input'] {
	return {
		id: Number(groupId),
		hierarchy: Object.values(values)
			.flat()
			.map((group) => ({
				id: group.id,
				name: group.name,
				active: group.active,
				isProperty: group.isProperty,
				level: group.level,
				parentId: group.parentId ? String(group.parentId) : '',
				rowNumber: group.rowNumber ?? 0,
				syncCode: group?.syncCode?.length
					? group.syncCode.trim()
					: undefined
			}))
	};
}

function createGetDefaultUniqName() {
	let counter = 100;
	return () => {
		counter++;
		return `${DEFAULT_GROUP_ABBREVIATION}_${counter}`;
	};
}

export const getDefaultUniqName = createGetDefaultUniqName();

export function calcGroupsAmount(arr: number[]): number {
	return arr.reduce<number>((acc, item, i, arr) => {
		let groupsAmountPerLevel = item;
		if (i) {
			groupsAmountPerLevel = arr
				.slice(0, i + 1)
				.reduce((acc, it, ind) => (ind ? acc * it : acc + it));
		}

		acc += groupsAmountPerLevel;
		return acc;
	}, 0);
}

export function isGroupsHierarchyLimitReachedXLSX(
	values: ValuesXLSX,
	groupsHierarchySizeLimit: number,
	amountOfGroupsInTenant: number
): boolean {
	const mappedValues = values.levels.map(({ numberOfGroups }) =>
		groupsAmountToNumber(numberOfGroups)
	);

	const groupsAmount = calcGroupsAmount(mappedValues);

	return isGroupsHierarchyLimitReachedResult(
		groupsAmount,
		groupsHierarchySizeLimit,
		amountOfGroupsInTenant
	);
}
export function isGroupsHierarchyLimitReached(
	values: Record<string, Value>,
	groupsHierarchySizeLimit: number,
	amountOfGroupsInTenant: number,
	levelNumber: string | string[],
	possibleAddedGroupsAmount: number | number[]
): boolean {
	const calculatedValues = { ...values };
	if (Array.isArray(levelNumber)) {
		levelNumber.forEach((level, index) => {
			if (calculatedValues.hasOwnProperty(level)) return;
			calculatedValues[level] = {
				isProperty: false,
				groupsNames: Array.isArray(possibleAddedGroupsAmount)
					? Array(possibleAddedGroupsAmount[index])
					: []
			};
		});
	}
	const groupsAmount = calcGroupsAmount(
		Object.keys(calculatedValues).map((key) => {
			return (
				calculatedValues[key].groupsNames.length +
				(typeof levelNumber === 'string' && key === levelNumber
					? (possibleAddedGroupsAmount as number)
					: 0)
			);
		})
	);

	return isGroupsHierarchyLimitReachedResult(
		groupsAmount,
		groupsHierarchySizeLimit,
		amountOfGroupsInTenant
	);
}

export function isGroupsHierarchyLimitReachedResult(
	groupsAmount: number,
	groupsHierarchySizeLimit: number,
	amountOfGroupsInTenant: number
): boolean {
	return groupsAmount + amountOfGroupsInTenant > groupsHierarchySizeLimit;
}

export const validateGroupName = (maxGroupNameCharacters: number) => (
	value: string
) => {
	if (value.length < 2 || value.length > maxGroupNameCharacters) {
		return false;
	}

	return !value.match(PATTERN.leadTrailSpace);
};

export const groupsAmountToNumber = (numberAsString: string): number => {
	const value = parseInt(numberAsString, 10);
	return value || 1;
};

export function createThree(arr: ValueXLSX[]) {
	if (!arr.length) return;
	const [{ levelName, numberOfGroups, isProperty }] = arr.slice(0, 1);

	const groupName = levelName || DEFAULT_GROUP_ABBREVIATION;
	const groupsAmount = groupsAmountToNumber(numberOfGroups);

	return Array(groupsAmount)
		.fill(null)
		.reduce((acc, _, index) => {
			const groupNumber = index + 1;
			const definedGroupName = groupName + groupNumber;

			acc[definedGroupName] = {
				groupName: definedGroupName,
				isProperty,
				children: createThree(arr.slice(1))
			};
			return acc;
		}, {});
}

export const createRow = (isProperty: boolean, row?: string[]): string[] => {
	const propertySign = isProperty ? IS_PROPERTY_SIGN : '';

	return !row ? [propertySign] : [propertySign, ...row.slice(1)];
};

export function adaptRowsToXLSXColumns(
	rows: string[][],
	headersLength: number
): string[][] {
	return rows.map((row) => {
		if (row.length < headersLength) {
			const difference = headersLength - row.length;
			return row.concat(Array(difference).fill(''));
		}
		return row;
	});
}

export function getRowsDependencyCreator(rows: string[][] = []) {
	function rowsDependencyCreator(
		three: Record<string, ParsedValue>,
		row?: string[]
	) {
		const values: ParsedValue[] = Object.values(three);
		values.forEach((value) => {
			const { children, groupName, isProperty } = value;
			const currentRow = createRow(isProperty, row);

			currentRow.push(groupName);
			rows.push(currentRow.slice());
			if (children) {
				return rowsDependencyCreator(children, currentRow);
			}
		});

		return rows;
	}
	rowsDependencyCreator.cleanUp = () => {
		setTimeout(() => {
			rows = [];
		}, 0);
		return rows;
	};

	return rowsDependencyCreator;
}

export const rowsDependencyCreator = getRowsDependencyCreator();

export const createRows = flow(
	createThree,
	rowsDependencyCreator,
	rowsDependencyCreator.cleanUp
);

export function generateColumnNames(
	parentLevel: number,
	groupMaxHierarchyLevel: number
): string[] {
	return Array(groupMaxHierarchyLevel - parentLevel)
		.fill(parentLevel)
		.map((level, i) => `${LEVEL_COLUMN_NAME}${level + i + 1}`);
}

export function createManageValues<E>() {
	let bank: E[] = [];
	function addValues(entity: E) {
		bank.push(entity);
	}
	function reset() {
		bank = [];
	}

	function getLast() {
		const last = bank[bank.length - 1];
		return last ?? null;
	}
	return {
		addValues,
		reset,
		getLast
	};
}
