import { DataNode } from 'antd/lib/tree';
import { LabelValueItem } from 'app-types';
import { isNil, uniq } from 'lodash';
import {
	DataTreeData,
	DataTreeContextProps,
	DataTreeContextValue,
	DataTreeNodeItem,
	DataTreeCache,
	DataTreeNode
} from './DataTree.types';

export const mergeData = <T>(
	newData: T[],
	data: DataTreeData<T>,
	props: Pick<DataTreeContextProps<T>, 'disabledValue' | 'toNode'>,
	cache?: DataTreeCache
): DataTreeData<T> => {
	const nodes: DataTreeData<T> = { ...data };

	newData.forEach((item) => {
		const node = props.toNode(item);
		const n: DataTreeNodeItem<T> = {
			...node,
			data: item,
			parents: [],
			isLeaf: cache?.leafs[node.key]
		};

		const nodeParent = !isNil(n.parentKey) ? nodes[n.parentKey] : undefined;

		if (!isNil(n.parentKey)) {
			if (nodeParent) {
				n.parents = [...(nodeParent.parents || []), nodeParent];
				nodeParent.isLeaf = false;
			} else {
				// data arrives in order from top level groups to bottom
				// if by the time we get to child group and don't find group by `parentKey` field, it means that it is missing
				// and `parentKey` has to be manually reset to `null` to not break further calculations when creating nested structure
				n.parentKey = null;
			}
		}
		const highestAncestor = n.parents?.[0];
		if (highestAncestor && cache?.loaded[highestAncestor.key]) {
			n.isLeaf = true;
		}

		const isNDisabled =
			props.disabledValue?.key === node.key || node.disabled;
		const isNAncestorDisabled = Boolean(
			n.parents?.some(
				(p) => props.disabledValue?.key === p.key || p.disabled
			)
		);
		n.disabled = isNDisabled || isNAncestorDisabled;

		nodes[n.key] = n;
	});

	return nodes;
};

export const getValueAncestorKeys = (
	value: DataTreeContextValue['value'],
	data: DataTreeData,
	props: Pick<DataTreeContextProps, 'root' | 'defaultActiveExpand'>
) => {
	if (!value) {
		return;
	}
	const newExpandedKeys = value.reduce<React.Key[]>((acc, value) => {
		const node = data[value.key];
		if (!node) return acc;
		const parentKeys = node.parents?.map((p) => p.key) || [];
		acc.push(...parentKeys);
		acc.push(...(props.defaultActiveExpand ? [node.key] : []));
		return acc;
	}, []);

	if (props.root) {
		newExpandedKeys.push(props.root.key);
	}

	return uniq(newExpandedKeys);
};

export const toDataTreeValue = (value: DataTreeNodeItem[] | undefined) =>
	value?.map((item) => ({
		key: item.key,
		title: item.title
	}));

export const labelValueToDataTreeValue = <K extends DataTreeNode['key']>(
	value: LabelValueItem<K>[]
): DataTreeNode[] =>
	value.map((d) => ({
		key: d.key,
		title: d.label
	}));

export const dataTreeValueToLabelValue = <K extends DataTreeNode['key']>(
	value: DataTreeNode[]
): LabelValueItem<K>[] =>
	value.map((d) => ({
		key: d.key as K,
		value: d.key,
		label: d.title
	}));

export function getFalsyExecutorFromMeta() {
	const mp = new Map<number, DataNode>();
	function executor(nodes: DataNode[]): Map<number, DataNode> {
		return nodes.reduce<Map<number, DataNode>>((acc, node) => {
			acc.set(node.key as number, node);
			if (node.children?.length) {
				return executor(node.children);
			}
			return acc;
		}, mp);
	}
	return executor;
}

export function getTruthyExecutor() {
	const acm: DataNode[] = [];
	function executor(nodes: DataNode[]): DataNode[] {
		return nodes.reduce((acc, node) => {
			acc.push(node);
			if (node.children?.length) {
				return executor(node.children);
			}
			return acc;
		}, acm);
	}
	return executor;
}

export function getChildrenIDsFromParents<T>(data?: DataTreeData<T>) {
	const dataToArray = data ? Object.values(data) : [];
	const ids: number[] = [];
	function executor(nodes: DataTreeNodeItem<T>[]): number[] {
		if (!data) return [];
		return nodes.reduce((acc, node) => {
			acc.push(node.key as number);
			const nodeChildren = dataToArray.filter(
				(value) =>
					((value?.parentKey as unknown) as number) === node.key
			);
			return nodeChildren.length
				? executor((nodeChildren as unknown) as DataTreeNodeItem<T>[])
				: acc;
		}, ids);
	}
	return executor;
}
