import { EditableLayout, Prompt } from 'Components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import AddGroupFormFields, {
	AddGroupFormFieldsPropsBase
} from './AddGroupFormFields';
import { usePrevious } from 'Hooks/usePrevious';
import { useEffect } from 'react';
import { getInitialValues } from './AddGroup.helpers';
import { generatePath } from 'react-router-dom';
import { getAppRoutes } from 'Pages/App/App.constants';
import { AddGroupValues } from '../Group.types';

function AddGroupFormContent({ isTenant, group }: AddGroupFormFieldsPropsBase) {
	const { t } = useTranslation();
	const history = useHistory();
	const { setFieldValue } = useFormikContext<AddGroupValues>();
	const previousGroup = usePrevious(group);
	const goBack = () => {
		history.push(
			generatePath(getAppRoutes().GROUPS, { groupId: group?.id })
		);
	};

	useEffect(() => {
		if (previousGroup && group && previousGroup.id !== group.id) {
			const initialValues = getInitialValues(group);
			setFieldValue('parent', initialValues.parent);
			setFieldValue('tenant', initialValues.tenant);
			setFieldValue('moduleEnabled', initialValues.moduleEnabled);
			setFieldValue(
				'canBePropertyToAncestors',
				initialValues.canBePropertyToAncestors
			);
		}
	}, [previousGroup, group, setFieldValue]);

	return (
		<EditableLayout
			goBack={goBack}
			heading={t(
				isTenant
					? 'group.addGroup.tenant.title'
					: 'group.addGroup.group.title'
			)}
			okText={t(
				isTenant
					? 'group.addGroup.tenant.submitText'
					: 'group.addGroup.group.submitText'
			)}
			prompt={false}
		>
			<Prompt>
				{(helpers) => (
					<AddGroupFormFields
						{...helpers}
						isTenant={isTenant}
						group={group}
					/>
				)}
			</Prompt>
		</EditableLayout>
	);
}

export default AddGroupFormContent;
