import { TableProps } from 'antd';
import { useEffect, useMemo } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { omit, keys } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { FieldArrayWithId } from 'react-hook-form';
import {
	GetGroupRequest,
	GetGroupResponse,
	GetGroupsListRequest,
	GetGroupsListResponse,
	Group as GroupType
} from 'Services/Api/Groups/Types';
import GroupActions from './GroupActions/GroupActions';
import { Fx } from 'Components';
import { GET_GROUP, GET_GROUPS_LIST } from 'Services/Api/Groups/Queries';
import { createSortDescription } from 'Components/Table/createColumns';
import useColumns, { GroupTableFilters } from './columns';

import { GroupModalsProps } from './modals';

import { getIsRequestParamsFiltered } from 'Helpers/table';
import { GroupMode, Values } from '../Groups.types';
import useScrollIntoView from 'Hooks/useScrollIntoView';
import { MANAGE_ROW_KEY } from '../Groups.constants';
import GroupTemplate from './GroupTemplate';
import { DataSourceItem } from './Group.types';
import { getCanBePropertyToAncestors } from 'Pages/Group/EditGroup/EditGroup.helpers';

export const DEFAULT_TABLE_REQUEST_VALUES = {
	sort: 'name:asc',
	filters: {
		depth: 1,
		parentId: null
	}
};

interface GroupProps {
	group: GroupType | null;
	defaultGroupName: string;
	mode: GroupMode;
	rootLevel: number;
	onMultipleGroupsClick(): void;
	fields: FieldArrayWithId<Values, 'groups', 'id'>[];
	handleDeleteGroupClick: (fieldId: string) => void;
	handleAddGroupClick: () => void;
	validateUniqGroupName: () => void;
	validateGroupSyncCode: () => void;
}

function Group({
	group,
	defaultGroupName,
	onSubmit,
	mode,
	rootLevel,
	onMultipleGroupsClick,
	fields,
	handleDeleteGroupClick,
	handleAddGroupClick,
	validateUniqGroupName,
	validateGroupSyncCode
}: GroupProps & GroupModalsProps) {
	const groupId = group?.id ?? undefined;

	const [getGroupDetails, groupResult] = useLazyQuery<
		GetGroupResponse,
		GetGroupRequest
	>(GET_GROUP);

	const currentGroup = groupResult.data?.getGroup;
	const [getGroup, result] = useLazyQuery<
		GetGroupsListResponse,
		GetGroupsListRequest
	>(GET_GROUPS_LIST, {
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true
	});

	const isManage = mode === 'manage';

	const fetchGroups = (
		values: GetGroupsListRequest = DEFAULT_TABLE_REQUEST_VALUES
	) => {
		const variables: GetGroupsListRequest = {
			sort: values.sort,
			filters: {
				...values.filters,
				depth: 1,
				parentId: groupId
			}
		};
		if (result.refetch) {
			result.refetch(variables);
		} else {
			getGroup({
				variables
			});
		}
	};

	useScrollIntoView(fields.length);

	useEffect(() => {
		fetchGroups();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (groupId) {
			getGroupDetails({
				variables: {
					id: groupId
				}
			});
		}
	}, [groupId, getGroupDetails]);

	const onTableChange: TableProps<DataSourceItem>['onChange'] = (
		_,
		filters,
		sorter
	) => {
		const sort = createSortDescription<DataSourceItem>(
			sorter as SorterResult<DataSourceItem>
		);
		const values = filters as GroupTableFilters;
		const variables: GetGroupsListRequest = {
			sort,
			filters: {
				depth: 1,
				parentId: groupId,
				name: values.name?.[0] || undefined,
				isProperty: values.isProperty ?? undefined,
				syncCode: values.syncCode?.[0] || undefined,
				active: values.active ?? undefined,
				isArchived: values.isArchived
			}
		};
		fetchGroups(variables);
	};

	const groupsList =
		result.data?.listGroups ?? result.previousData?.listGroups;

	const isDataSourceFiltered = getIsRequestParamsFiltered(
		omit(
			result.variables?.filters || {},
			keys(DEFAULT_TABLE_REQUEST_VALUES.filters)
		)
	);

	const dataSource: DataSourceItem[] = useMemo(() => {
		return (groupsList || []).concat(
			// @ts-ignore
			isManage
				? []
				: [
						...fields.map((field) => ({
							...field,
							canBePropertyToAncestors:
								!currentGroup ||
								getCanBePropertyToAncestors(currentGroup)
						})),
						{ isManageRow: true, id: MANAGE_ROW_KEY }
				  ]
		);
	}, [groupsList, fields, currentGroup, isManage]);
	const isGroupLoading =
		Boolean(groupId) && (!groupResult.called || groupResult.loading);
	const isTableLoading = !result.called || result.loading || isGroupLoading;

	const columns = useColumns({
		filterValues: result.variables?.filters,
		isManageMode: isManage,
		isTenant: !group && rootLevel === 1,
		deleteGroupAction: handleDeleteGroupClick,
		handleAddGroupClick,
		isDeleteGroupButtonDisable: fields.length === 1,
		validateUniqGroupName,
		validateGroupSyncCode,
		mode
	});

	return (
		<GroupTemplate
			dataSource={dataSource}
			defaultGroupName={defaultGroupName}
			group={group}
			isDataSourceFiltered={isDataSourceFiltered}
			columns={columns}
			tableLoading={isTableLoading}
			onSubmit={onSubmit}
			onTableChange={onTableChange}
			totalVisible={isManage ? dataSource.length : dataSource.length - 1}
			isManage={isManage}
			rowClassName={(record) =>
				record?.isManageRow || record?.key
					? 'form-control'
					: 'table-data'
			}
		>
			{!isGroupLoading && mode === 'manage' && (
				<Fx grow={1} shrink={0} justify="end">
					<GroupActions
						groupId={groupId}
						group={currentGroup}
						level={group?.level}
						params={result.variables}
						onMultipleGroupsClick={onMultipleGroupsClick}
						hasData={Boolean(dataSource.length)}
					/>
				</Fx>
			)}
		</GroupTemplate>
	);
}

export default Group;
