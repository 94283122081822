import { notification } from 'antd';
import cn from 'classnames';
import { ArgsProps } from 'antd/lib/notification';
import { Icon } from 'Components';
import styles from './Notification.module.scss';
import { Replace, TInfo } from 'app-types';
import React from 'react';
import { isArray, isPlainObject, isString } from 'lodash';
import TInfoText from 'Contexts/TInfoText/TInfoText';

notification.config({
	duration: 15
});

type NotificationArgs = Replace<
	ArgsProps,
	{
		description: TInfo[] | React.ReactNode;
		message?: ArgsProps;
	}
>;

export const isTInfo = (value: unknown): value is TInfo => {
	if (!isPlainObject(value)) return false;
	const info = value as TInfo;
	return isString(info.key) && isPlainObject(info.params);
};

const description = (value: NotificationArgs['description']) => {
	if (isArray(value)) {
		return value.map((item) => {
			return isTInfo(item) ? (
				<TInfoText path={item.key} params={item.params} />
			) : (
				item
			);
		});
	}
	return value;
};

const DEFAULT_ARGS = (args: NotificationArgs): Partial<ArgsProps> => ({
	className: cn(styles.root, { [styles.emptyMessage]: !args.message }),
	icon: <Icon type="exclamationCircle" />,
	closeIcon: <Icon type="close" />
});

const Notification = {
	error: (args: NotificationArgs) => {
		notification.error({
			message: null,
			...DEFAULT_ARGS(args),
			...args,
			description: description(args.description)
		});
	},
	success: (args: NotificationArgs) => {
		notification.success({
			message: null,
			...DEFAULT_ARGS(args),
			...args,
			description: description(args.description)
		});
	},
	open: (args: NotificationArgs) => {
		notification.open({
			message: null,
			...DEFAULT_ARGS(args),
			icon: null,
			...args,
			description: description(args.description)
		});
	},
	close: notification.close
};

export default Notification;
