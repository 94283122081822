import { CloneRoleRequest } from 'Services/Api/Roles/Types';

export enum RolesActionsModalName {
	Delete = 'Delete',
	Clone = 'Clone'
}

export interface DeleteRoleModalProps {
	ids: string[];
	idsNames: string[];
	onSubmit: () => void;
}

export interface CloneRoleModalProps {
	roleId: string;
	roleName: string;
	onSubmit: () => void;
}

export type Values = Pick<CloneRoleRequest['input'], 'role'>;
