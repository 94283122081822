import { Fx, Card, Text } from 'Components';
import { useTranslation } from 'react-i18next';
import {
	InfoTooltip,
	LineProgress,
	OpenInNewTab
} from 'Pages/Dashboard/components';
import styles from './Elearning.module.scss';
import { inPercent } from 'Helpers/numeric';
import { useStores } from 'Hooks/useStore';
import { useEffect } from 'react';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import { observer } from 'mobx-react-lite';
import { getIsPending } from 'Stores/util';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';
import { ElearningWidgets } from './Elearning.types';

const LearningPathSummary = () => {
	const { t } = useTranslation();
	const { filters } = useDashboardContext();
	const { dashboardStore } = useStores();

	const {
		elearning: { pathSummary = [] },
		status
	} = dashboardStore;

	useEffect(() => {
		dashboardStore.getElearningPathSummary(filters);
		// eslint-disable-next-line
	}, [filters]);

	return (
		<Card
			loading={getIsPending(status.getElearningPathSummary)}
			title={t('dashboard.tabs.elearning.learningPathSummary.title')}
			extra={
				<Fx align="middle">
					<InfoTooltip
						title={t(
							'dashboard.tabs.elearning.learningPathSummary.tooltip'
						)}
					/>
				</Fx>
			}
			footer={
				<OpenInNewTab
					tab={DashboardTabKey.Elearning}
					widget={ElearningWidgets.LearningPathSummary}
				/>
			}
		>
			<div className={styles.pathSummary}>
				{pathSummary.map(
					({
						learningPathId,
						learningPathName,
						percentageComplete
					}) => (
						<LineProgress
							large
							key={learningPathId}
							before={
								<div className={styles.name}>
									<Text color="default">
										{learningPathName}
									</Text>
								</div>
							}
							percent={percentageComplete}
							after={
								<Text
									className={styles.value}
									color="primary"
									size="lg"
								>
									{inPercent(percentageComplete)}
								</Text>
							}
							className={styles.progress}
						/>
					)
				)}
			</div>
		</Card>
	);
};

export default observer(LearningPathSummary);
