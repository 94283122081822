import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// @NOTE@1: temporary solution, satisfy requirements of ticket: https://support.dataart.com/secure/RapidBoard.jspa?projectKey=VNZ&rapidView=12502
// import LanguageDetector from 'i18next-browser-languagedetector';

i18next
	// @NOTE@1
	// .use(LanguageDetector)
	.use(initReactI18next)
	.init({
		partialBundledLanguages: true,
		fallbackLng: false,
		interpolation: {
			escapeValue: false,
			format: (value, format) => {
				switch (format) {
					case 'lowercase':
						return value?.toLowerCase();
				}

				return value;
			}
		},
		resources: {},
		react: {
			useSuspense: true,
			wait: true
		}
	});

export default i18next;
