import { useDashboardTabs } from 'Pages/Dashboard/Dashboard.hooks';
import { useTranslation } from 'react-i18next';
import { getInitialValues } from './EditReport.helpers';
import { ReportFormValues } from './schema';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';

interface CheckboxTab{
  label: string;
  value: DashboardTabKey;
  disabled: boolean;
}

export function useEditReport(
	report: Parameters<typeof getInitialValues>[0] | undefined
): {
	initialValues: ReportFormValues | null;
} {
	const { t } = useTranslation();
	const { exportedTabs } = useDashboardTabs();

	if (!report?.reportType) {
		return {
			initialValues: null
		};
	}

  const tabs = exportedTabs.reduce<CheckboxTab[]>((acc, tab)=>{
  if(tab.hasPermission){
    acc.push(
      {
        label: t(tab.tab),
        value: tab.key,
        disabled: false
      }
    )
  }
    return acc
  }, [])

	const data = {
		tabs
	};
	const initialValues = getInitialValues(report, t, data);

	return {
		initialValues: initialValues ?? null
	};
}
