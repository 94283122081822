import * as d3 from 'd3';
import { isNumber, orderBy } from 'lodash';
import { CHART_CONFIG } from '../Visualizations.constants';
import { colorScale } from '../Visualizations.helpers';
import { PieValue } from './PieChart';

const { selectors } = CHART_CONFIG.pie;

type SvgType = d3.Selection<SVGGElement, unknown, null, undefined>;

interface PiechartProps {
	formatFn?: (value: PieValue['y']) => string | null;
}
function piechart(props: PiechartProps) {
	const dispatch = d3.dispatch('tooltip:show', 'tooltip:hide');
	let svg: SvgType | undefined;
	let scales: ReturnType<typeof chart.scales> | undefined;
	let size = {
		width: 170,
		height: 170
	};
	let chartData: PieValue[] | undefined;
	let update: (() => void) | undefined;

	function chart(
		selection: d3.Selection<HTMLDivElement, unknown, null, undefined>
	) {
		selection.each(function () {
			svg = selection.append('svg').append<SVGGElement>('g');
		});

		const graphFn = graph();
		graphFn.create();
		update = () => {
			if (svg && size) {
				selection
					.select('svg')
					.attr('width', size.width)
					.attr('height', size.height);
				svg.attr(
					'transform',
					`translate(${size.width / 2}, ${size.height / 2})`
				);
			}

			graphFn.draw();
		};
	}
	chart.dispatch = dispatch;
	chart.scales = () => {
		if (!chartData || !size) return;

		const radius = Math.min(size.width, size.height) / 2;
		const arc = d3
			.arc<d3.PieArcDatum<PieValue>>()
			.padAngle(0.02)
			.innerRadius(radius - radius * 0.3)
			.outerRadius(radius);

		const pie = d3.pie<PieValue>().value((d) => d.y);

		const color = colorScale(
			chartData.map((d) => d.timePeriod).filter((d) => d) as string[]
		);

		const ret = {
			arc,
			pie,
			color
		};
		scales = ret;
		if (update) update();
		return ret;
	};

	chart.size = (width: number, height: number) => {
		size = {
			width,
			height
		};
		chart.scales();
		return chart;
	};

	chart.data = (data: PieValue<number | null>[]) => {
		chartData = orderBy(
			data.filter((d) => isNumber(d.y) && d.y !== 0) as PieValue[],
			(d) => d.y,
			['desc']
		);
		chart.scales();
		return chart;
	};

	function graph() {
		return {
			create: () => {
				if (!svg) return;
				// Graph
				svg.append('g').attr('class', selectors.graph);
			},
			draw: () => {
				if (!svg || !scales || !chartData) return;
				const graphSel = svg.select<SVGGElement>(`.${selectors.graph}`);

				const { arc, pie, color } = scales;

				const arcs = pie(chartData);

				graphSel
					.selectAll<SVGGElement, d3.PieArcDatum<PieValue>>('path')
					.data(arcs, (d) => `${d.data.x}${d.data.y}`)
					.join(
						(enter) => {
							const path = enter
								.append('path')
								.attr('fill', (d) =>
									color(d.data.timePeriod ?? '')
								)
								.attr('d', arc)
								.on('mouseenter', function () {
									const selection = d3.select<
										SVGGElement,
										d3.PieArcDatum<PieValue>
									>(this);
									const datum = selection.datum();

									const domRect = selection
										?.node()
										?.getBoundingClientRect();
									if (!domRect) return;
									dispatch.call('tooltip:show', undefined, {
										x: domRect.x,
										y: domRect.y,
										values: [
											{
												title: datum.data.x,
												count:
													props.formatFn?.(
														datum.data.y
													) ?? datum.data.y,
												countColor: color(
													datum.data.timePeriod ?? ''
												)
											}
										]
									});
								})
								.on('mouseleave', () => {
									dispatch.call('tooltip:hide');
								});

							return path;
						},
						(update) => {
							return update
								.attr('d', arc)
								.attr('fill', (d) =>
									color(d.data.timePeriod ?? '')
								);
						},
						(exit) => exit.remove()
					);
			}
		};
	}

	return chart;
}

export default piechart;
