// eslint-disable-next-line
function getIndexes<E extends Record<string, any>, K extends keyof E>(
	entity: E[],
	field: K
): number[] {
	const cash = entity.reduce<Partial<Record<K, number[]>>>((acc, item, i) => {
		const entityDefinedName = item[field];
		if (entityDefinedName) {
			const name: E[K] = entityDefinedName.trim().toLowerCase();
			const indexes = acc[name];
			acc[name] = Array.isArray(indexes) ? [...indexes, i] : [i];
		}

		return acc;
	}, {});

	return Object.values(cash as Record<string, number[]>)
		.filter((field) => field.length > 1)
		.flat();
}

export default getIndexes;
