import { FC, useState } from 'react';
import { TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { axiosClient } from 'Services/Api/client';
import { downloadLink } from 'Helpers/downloadLink';
import DASHBOARD_API from 'Services/Api/Dashboard/Api';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import { Fx, Layout, Table, Notification } from 'Components';
import { createSortDescription } from 'Components/Table/createColumns';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SpotCheckJobClassOverviewItem } from 'Services/Api/Dashboard/Types';

import { getIsPending } from 'Stores/util';
import { useColumns } from './columns';
import { ExportMenu } from 'Pages/Dashboard/components';
import { DownloadUrl } from 'app-types';

const ICI: FC = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const { t } = useTranslation();
	const { filters } = useDashboardContext();
	const { dashboardStore } = useStores();
	const { spotCheck, status } = dashboardStore;

	const handleExportReportClick = async (info: { key: React.Key }) => {
		try {
			setLoading(true);
			const { data } = await axiosClient.get<DownloadUrl>(
				DASHBOARD_API.SPOT_CHECK_EXPORT_ICI_JOB_ROLE,
				{
					params: {
						...filters,
						fileType: info.key
					}
				}
			);

			downloadLink(data.downloadUrl);
			Notification.success({
				description: t('notification.downloadFile.success')
			});
		} catch (error) {
			Notification.error({
				description: t('errorCodes.genericErrorMessage')
			});
		} finally {
			setLoading(false);
		}
	};

	const handleTableChange: TableProps<SpotCheckJobClassOverviewItem>['onChange'] = (
		_,
		__,
		sorter
	) => {
		const sort = createSortDescription<SpotCheckJobClassOverviewItem>(
			sorter as SorterResult<SpotCheckJobClassOverviewItem>
		);

		dashboardStore.getSpotCheckJobRoleSummary({
			...filters,
			sort
		});
	};
	const isExportMenuDisabled = !spotCheck.jobRoles.data.length;
	return (
		<Layout>
			<Layout.Heading>
				<Fx align="middle" justify="space-between">
					<h3>
						{t('dashboard.tabs.spotCheck.climateMeasurement.title')}
					</h3>
					<ExportMenu
						onExportMenuClick={handleExportReportClick}
						loading={loading}
						disabled={isExportMenuDisabled}
					/>
				</Fx>
			</Layout.Heading>
			<Table<SpotCheckJobClassOverviewItem>
				dataSource={spotCheck.jobRoles.data}
				rowKey="industryJobClassId"
				columns={useColumns({
					params: spotCheck.jobRoles.params
				})}
				pagination={false}
				onChange={handleTableChange}
				loading={getIsPending(status.getSpotCheckJobRole)}
			/>
		</Layout>
	);
};

export default observer(ICI);
