import React, { useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { DropDownProps, Menu } from 'antd';
import { Icon, Dropdown } from 'Components';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import styles from './DropdownMenu.module.scss';

interface Option {
	key: string;
	label: string;
}

type Value = string | undefined;

export type DropdownMenuProps = Pick<DropDownProps, 'className'> & {
	onChange?(value: Value): void;
	value?: Value;
	options: Option[];
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({
	onChange,
	options,
	className,
	value
}) => {
	const { t } = useTranslation();

	const translatedValue = useMemo(() => t(value ?? ''), [value, t]);
	const handleClick: MenuClickEventHandler = (e) => {
		const newValue = (e.key as unknown) as string;
		onChange?.(newValue);
	};

	return (
		<Dropdown
			trigger={['click']}
			placement={'bottomLeft'}
			className={className}
			overlayClassName={styles.overlay}
			overlay={
				<Menu onClick={handleClick}>
					{options.map((item) => {
						const isActive = value === item.key;
						return (
							<Menu.Item key={item.key}>
								<div
									className={cn(styles.menuItem, {
										[styles.menuItemActive]: isActive
									})}
								>
									{t(item.label)}
									{isActive ? (
										<Icon
											type="check"
											className={styles.menuItemIcon}
										/>
									) : null}
								</div>
							</Menu.Item>
						);
					})}
				</Menu>
			}
		>
			<div className={styles.dropDownContent}>{translatedValue}</div>
		</Dropdown>
	);
};

export default DropdownMenu;
