import {useMemo} from 'react'
import { getUserRoleLabel } from 'app-util';
import { FormItem, Select } from 'Components';
import { FormItemProps } from 'Components/Form/FormItem/FormItem';
import { SelectProps } from 'Components/Form/Select/Select';
import { useFormContext } from 'react-hook-form';
import { ADD_USERS_SYSTEM_ROLE_LIST } from '../../AddUsers.constants';
import { AddUsersFormValues } from '../../AddUsers.types';

export type PrimaryRoleFieldProps = Pick<FormItemProps, 'className' | 'label'> &
	Pick<SelectProps, 'value'> & {
		error?: boolean;
		onChange: (
			value: AddUsersFormValues['users'][0]['primarySystemRoleId']
		) => void;
	};
function PrimaryRoleField({
	error,
	onChange,
	className,
	label,
	...selectProps
}: PrimaryRoleFieldProps) {
	const { getValues } = useFormContext<AddUsersFormValues>();
	const values = getValues();

	const onFieldChange: SelectProps['onChange'] = (value, option) => {
		const opt = option as AddUsersFormValues['users'][0]['primarySystemRoleId'];
		onChange({
			...value,
			data: opt?.data
		});
	};

  const systemRolesOptions = useMemo(()=>{
      return values.data.systemRoles
      ?.filter((role) =>
        ADD_USERS_SYSTEM_ROLE_LIST.includes(role.role)
      ).sort((roleA, roleB)=> roleA.id - roleB.id).map((role) => (
        <Select.Option
          key={role.id}
          value={role.role}
          data={role}
        >
          {getUserRoleLabel(role.role)}
        </Select.Option>
      ))
  }, [values.data.systemRoles])

	return (
		<FormItem
			className={className}
			label={label}
			validateStatus={(error && 'error') || undefined}
		>
			<Select.ScrollHandled
				{...selectProps}
				labelInValue
				// @ts-ignore
				onChange={onFieldChange}
			>
				{systemRolesOptions}
			</Select.ScrollHandled>
		</FormItem>
	);
}

export default PrimaryRoleField;
