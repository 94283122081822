import { useHistory } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { Notification } from 'Components';
import { AddReportRequest } from 'Services/Api/Reports/Types';
import { schema, ReportFormValues } from './schema';
import { prepareRequest } from './EditReport.helpers';
import { getFieldErrors } from 'Helpers/graphql/graphql.helpers';
import { AxiosError } from 'axios';
import EditReportFormLayout from './EditReportFormLayout';

interface EditReportProps {
	initialValues: ReportFormValues;
	submit: (params: AddReportRequest) => Promise<void>;
	returnUrl: string;
}
function EditReport({ initialValues, returnUrl, submit }: EditReportProps) {
	const history = useHistory();
	const { t } = useTranslation();

	const goBack = () => {
		history.push(returnUrl);
	};

	const onSubmit = async (
		values: ReportFormValues,
		helpers: FormikHelpers<ReportFormValues>
	) => {
		try {
			const request = prepareRequest(values);
			await submit(request);
			goBack();
			Notification.success({
				description: t('notification.edit.success')
			});
		} catch (e) {
			const errorInfo = (e as AxiosError).response?.data.error;
			const fieldErrors = getFieldErrors(
				errorInfo,
				schema.describe().fields,
				t
			);
			helpers.setErrors(fieldErrors);
		} finally {
			helpers.setSubmitting(false);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={schema}
			validateOnMount
		>
			<EditReportFormLayout goBack={goBack} />
		</Formik>
	);
}

export default EditReport;
