import { VisualizationTimePeriod } from 'Pages/Dashboard/visualizations/Visualizations.types';
import { CampainSummaryData } from 'Services/Api/Dashboard/Types';
import {
	CAMPAIGN_SUMMARY_CATEGORIES,
	CAMPAIGN_SUMMARY_CATEGORY_LABEL_MAP
} from './CampaignsSummary.constants';

export const prepareChartData = (data: CampainSummaryData) =>
	CAMPAIGN_SUMMARY_CATEGORIES.map((category) => ({
		category: CAMPAIGN_SUMMARY_CATEGORY_LABEL_MAP[category],
		values: [
			{
				timePeriod: VisualizationTimePeriod.Previous,
				value: data.previous?.[category] ?? 0
			},
			{
				timePeriod: VisualizationTimePeriod.Current,
				value: data.current?.[category] ?? 0
			}
		]
	}));
