import { ColumnProps } from 'antd/lib/table';
import { Select, LanguageMenuItem, Input } from 'Components';
import {
	CustomColumn,
	GroupsField,
	UserRoleField,
	PrimaryRoleField,
	JobClassField
} from './components';
import styles from './AddUsers.module.scss';
import cn from 'classnames';
import { AddUser, AddUsersFormValues } from './AddUsers.types';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_BY_ID, ID_BY_LANGUAGE } from 'i18n/i18n.constants';
import { LanguageI18nKey } from 'Services/Api/Common/Types';

type GetColumnsConfig = AddUsersFormValues['data'] & {
	validateCognitoLogin: () => void;
	resetPhoneNumberField: (key: string) => void;
};
const useColumns = ({
	timezones,
	languages,
	validateCognitoLogin,
	resetPhoneNumberField
}: GetColumnsConfig) => {
	const { t } = useTranslation();
	const columns: Array<ColumnProps<AddUser>> = [
		{
			title: (
				<CustomColumn
					title={t('user.firstName')}
					tooltipText={t('user.validationRules.firstName')}
					required
				/>
			),
			render: (_, data) => (
				<Input.HookFormInput
					name={`users.${data.key}.firstName`}
					required
				/>
			)
		},
		{
			title: (
				<CustomColumn
					title={t('user.lastName')}
					tooltipText={t('user.validationRules.lastName')}
					required
				/>
			),
			render: (_, data) => (
				<Input.HookFormInput
					name={`users.${data.key}.lastName`}
					required
				/>
			)
		},
		{
			title: (
				<CustomColumn
					title={t('user.cognitoLogin')}
					tooltipText={t('user.validationRules.cognitoLogin')}
					required
				/>
			),
			render: (_, data) => (
				<Input.HookFormInput
					name={`users.${data.key}.cognitoLogin`}
					onBlur={validateCognitoLogin}
					required
				/>
			)
		},
		{
			title: (
				<CustomColumn
					title={t('user.email')}
					tooltipText={t('user.validationRules.email')}
					required
				/>
			),
			render: (_, data) => (
				<Input.HookFormInput
					name={`users.${data.key}.email`}
					required
				/>
			)
		},
		{
			title: (
				<CustomColumn
					title={t('user.phoneNumber')}
					tooltipText={t('user.validationRules.phone')}
				/>
			),
			render: (_, data) => (
				<Input.HookFormInput
					name={`users.${data.key}.phoneNumber`}
					onBlur={() => resetPhoneNumberField(data.key)}
				/>
			)
		},
		{
			title: (
				<CustomColumn
					title={t('user.timezoneId')}
					tooltipText={t('user.validationRules.timezone')}
				/>
			),
			width: 150,
			render: (_, data) => (
				<Select.HookFormSelect name={`users.${data.key}.timezoneId`}>
					{timezones?.map((timezone) => (
						<Select.Option key={timezone.id} value={timezone.id}>
							{timezone.zone}
						</Select.Option>
					))}
				</Select.HookFormSelect>
			)
		},
		{
			title: (
				<CustomColumn
					title={t('user.languageId')}
					tooltipText={t('user.validationRules.language')}
				/>
			),
			width: 100,
			render: (_, data) => {
				return (
					<Select.HookFormSelect
						name={`users.${data.key}.languageId`}
						className={cn(
							styles.languageSelect,
							styles.marginBottomNull
						)}
					>
						{languages?.map((language) => (
							<Select.Option
								key={language.id}
								value={language.id}
								// @NOTE: 'disabled' implemented according to https://support.dataart.com/browse/VNZ-1931
								disabled={
									Number(
										ID_BY_LANGUAGE[LanguageI18nKey.English]
									) !== language.id
								}
							>
								<LanguageMenuItem
									menuKey={LANGUAGE_BY_ID[language.id]}
								/>
							</Select.Option>
						))}
					</Select.HookFormSelect>
				);
			}
		},
		{
			title: (
				<CustomColumn
					title={t('user.primarySystemRoleId')}
					tooltipText={t('user.validationRules.primarySystemRoleId')}
					required
				/>
			),
			render: (_, data) => {
				return (
					<PrimaryRoleField
						fieldKey={data.key}
						className={styles.addUsersSelect}
					/>
				);
			}
		},
		{
			title: (
				<CustomColumn
					title={t('user.group')}
					tooltipText={t('user.validationRules.group')}
					required
				/>
			),
			width: 310,
			render: (_, data) => (
				<GroupsField
					fieldKey={data.key}
					className={styles.addUsersSelect}
				/>
			)
		},
		{
			title: (
				<CustomColumn
					title={t('user.lmsJobcass.title')}
					subTitle={t('user.lmsJobcass.subTitle')}
					tooltipText={t('user.validationRules.lmsJobcass')}
				/>
			),
			key: 'JobClassField',
			render: (_, data) => {
				return <JobClassField fieldKey={data.key} />;
			}
		},
		{
			title: (
				<CustomColumn
					title={t('user.lmsRole.title')}
					subTitle={t('user.lmsRole.subTitle')}
					tooltipText={t('user.validationRules.lmsRole')}
				/>
			),
			key: 'UserRoleField',
			render: (_, data) => <UserRoleField fieldKey={data.key} />
		}
	];
	return columns;
};

export default useColumns;
