import { useLazyQuery, useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { GET_PERMISSIONS, GET_SYSTEM_ROLE } from 'Services/Api/Roles/Queries';
import {
	GetPermissionsResponse,
	GetSystemRoleDetailsRequest,
	GetSystemRoleDetailsResponse
} from 'Services/Api/Roles/Types';
import { getInitialValues } from './EditRole.helpers';
import { useErrorBoundary } from 'Hooks';

interface EditRoleRouteParams {
	roleId: string;
}
export const useRoleForm = () => {
	const params = useParams<EditRoleRouteParams>();
	const errorBoundary = useErrorBoundary();
	const result = useQuery<GetPermissionsResponse>(GET_PERMISSIONS, {
		variables: {
			systemRoleId: Number(params.roleId)
		}
	});
	const isPermissionsLoading = !result.called || result.loading;

	const [getSystemRole, getSystemRoleResult] = useLazyQuery<
		GetSystemRoleDetailsResponse,
		GetSystemRoleDetailsRequest
	>(GET_SYSTEM_ROLE, {
		onError: errorBoundary.onError
	});

	const isNew = !params.roleId;
	const roleId = Number(params.roleId);

	useEffect(() => {
		if (roleId) {
			getSystemRole({
				variables: {
					id: roleId
				}
			});
		}

		// eslint-disable-next-line
	}, []);

	const isLoading =
		!getSystemRoleResult.called || getSystemRoleResult.loading;
	if ((roleId && isLoading) || isPermissionsLoading) {
		return {
			initialValues: null
		};
	}

	const role = getSystemRoleResult.data?.getSystemRole || null;

	return {
		initialValues: getInitialValues(
			// if passed implicitly, ts throws error
			// works if passed explicitly
			// @ts-ignore
			role,
			result.data?.getPermissions
		),
		isNew,
		roleId
	};
};
