import { ValidationMessageParams } from './validations.types';

export const validationMessage = (key: string) => (
	params: ValidationMessageParams = {}
) => ({
	key,
	params
});

export const required = validationMessage(
	'util.validation.validationMessage.required'
);
export const min = validationMessage('util.validation.validationMessage.min');
export const max = validationMessage('util.validation.validationMessage.max');

export const minLength = validationMessage(
	'util.validation.validationMessage.minLength'
);

export const minDigits = validationMessage(
	'util.validation.validationMessage.minDigits'
);
export const maxDigits = validationMessage(
	'util.validation.validationMessage.maxDigits'
);
export const maxNumber = validationMessage(
	'util.validation.validationMessage.maxNumber'
);
export const numeric = validationMessage(
	'util.validation.validationMessage.numeric'
);
export const nospace = validationMessage(
	'util.validation.validationMessage.noSpace'
);
export const noLeadTrailSpace = validationMessage(
	'util.validation.validationMessage.noLeadTrailSpace'
);
export const password = validationMessage(
	'util.validation.passwordValidMessage'
);

export const tenantIdFieldValidationRules = validationMessage(
	'util.validation.validationMessage.tenantIdValidationRules'
);

export const email = required;
