import { gql } from '@apollo/client';

export const EXPORT_USERS_AND_GROUPS_FOR_DELETION = gql`
	query exportUsersAndGroupsForDeletion($input: WeeklyReport) {
		exportUsersAndGroupsForDeletion(input: $input) {
			downloadUrl
		}
	}
`;

export const EXPORT_WEEKLY_REPORT = gql`
	query exportWeeklyReport($input: WeeklyReport) {
		exportWeeklyReport(input: $input) {
			downloadUrl
		}
	}
`;
