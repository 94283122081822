import { Layout } from 'Components';
import { FC } from 'react';
import { PermissionGroup } from 'Services/Api/Roles/Types';
import PermissionsGroup from './PermissionsGroup';

interface PermissionsProps {
	values: PermissionGroup[];
}

const Permissions: FC<PermissionsProps> = ({ values }) => (
	<Layout.List>
		<Layout.List.ListItem stacked>
			{values.map((group) => (
				<PermissionsGroup key={group.id} group={group} />
			))}
		</Layout.List.ListItem>
	</Layout.List>
);

export default Permissions;
