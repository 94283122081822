import { useMutation } from '@apollo/client';
import { APIErrorCodes } from 'app-types';
import { useTranslation } from 'react-i18next';
import { Modal, Notification } from 'Components';
import { useModal } from 'Hooks/useModal';
import { Group } from 'Services/Api/Groups/Types';
import { DELETE_GROUP } from 'Services/Api/Groups/Mutations';
import { checkApolloError } from 'Helpers/graphql';
import { GroupModalsProps } from './types';
import TInfoText from 'Contexts/TInfoText/TInfoText';

export function DeleteGroupModal(props: GroupModalsProps) {
	const { t } = useTranslation();
	const { modal, hideModal } = useModal();

	const [deleteGroup, result] = useMutation(DELETE_GROUP, {
		onCompleted: () => {
			props.onSubmit();
			hideModal();
			Notification.success({
				description: t(
					'groups.groupDetails.components.deleteGroupModal.successNotification'
				)
			});
		},
		onError: (e) => {
			hideModal();

			Notification.error({
				description: checkApolloError(e).is(
					APIErrorCodes.DeleteGroupWithUsers
				)
					? t(
							'groups.groupDetails.components.deleteGroupModal.errorNotification'
					  )
					: e.message
			});
		}
	});

	if (!modal) {
		return null;
	}
	const group = modal.value as Group;

	const onSubmit = () => {
		deleteGroup({ variables: { id: group.id } });
	};

	return (
		<Modal
			visible
			title={
				<TInfoText
					path="groups.groupDetails.components.deleteGroupModal.title"
					params={{ name: group.name }}
				/>
			}
			okText={t('delete')}
			okButtonProps={{ loading: result.loading }}
			onOk={onSubmit}
			onCancel={hideModal}
		>
			{t('groups.groupDetails.components.deleteGroupModal.body')}
		</Modal>
	);
}
