import { ExportButton } from 'Components';
import { ExportButtonProps } from 'Components/ExportButton/ExportButton';
import { EXPORT_GROUPS } from 'Services/Api/Groups/Queries';
import {
	ExportGroupsRequest,
	ExportGroupsResponse
} from 'Services/Api/Groups/Types';

export type GroupExportButtonProps = Omit<
	ExportButtonProps<ExportGroupsResponse, ExportGroupsRequest>,
	'query' | 'get'
>;
function GroupExportButton(props: GroupExportButtonProps) {
	const getQueryUrl = (data: ExportGroupsResponse) =>
		data.exportGroups.downloadUrl;

	return (
		<ExportButton<ExportGroupsResponse, ExportGroupsRequest>
			query={EXPORT_GROUPS}
			getQueryUrl={getQueryUrl}
			{...props}
		/>
	);
}

export default GroupExportButton;
