import { useEffect } from 'react';

function useScrollIntoView<D>(
	dependency: D,
	nodeClassName = '.ant-table-tbody'
) {
	useEffect(() => {
		const tableBody = document.querySelector(nodeClassName);

		if (tableBody) {
			tableBody.scrollIntoView(false);
		}
		// eslint-disable-next-line
	}, [dependency]);
}

export default useScrollIntoView;
