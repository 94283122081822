import { Fx, InputSearchToggle, Layout, Pagination, Table } from 'Components';
import { User } from 'Services/Api/Users/Types';
import { useColumns } from './columns/columns';
import { UsersTableControls } from './components';
import { toTableParams } from './Users.helpers';
import { useUsers, useUsersFilters } from './Users.hooks';
import UsersTableTypeDropdown from './UsersTableTypeDropdown';
import { useRef } from 'react';

function Users() {
	const props = useUsers();
	const {
		result,
		dataSource,
		selectedRowKeys,
		setSelectedRowKeys,
		usersBySelectedRowKeys,
		filteredInfo
	} = props;
	const {
		filters,
		filterValues,
		onTableChange,
		onPaginationChange,
		onSearchChange,
		onActionSubmit,
		onUsersDeletedStatusChange,
		setFilterValue
	} = useUsersFilters(props);

	const totalCount = result.data?.list.totalCount ?? 0;
	const paginationTotalCountRef = useRef(totalCount);
	if (result.data) {
		paginationTotalCountRef.current = totalCount;
	}

	return (
		<Layout>
			<Layout.Heading
				prefixNode={
					<UsersTableTypeDropdown
						initialValue={filteredInfo?.filters.isDeleted === true}
						onChange={onUsersDeletedStatusChange}
					/>
				}
			>
				{({ searchFieldClassName }) => (
					<Fx justify="space-between" align="middle">
						<div className={searchFieldClassName}>
							<InputSearchToggle
								value={filterValues?.search}
								onChange={onSearchChange}
							/>
						</div>
						<Fx>
							<UsersTableControls
								selectedRowKeys={selectedRowKeys as string[]}
								selectedUsers={usersBySelectedRowKeys}
								onChange={onActionSubmit}
								params={result.variables}
								hasData={Boolean(dataSource.length)}
							/>
						</Fx>
					</Fx>
				)}
			</Layout.Heading>
			<Table<User>
				dataSource={dataSource}
				rowKey="peakV3Id"
				loading={result.loading}
				pagination={false}
				rowSelection={{
					selectedRowKeys,
					onChange: setSelectedRowKeys
				}}
				onChange={onTableChange}
				columns={useColumns({
					params: toTableParams(filterValues),
					filters,
					setFilterValue,
					withLinks: true
				})}
			/>
			<Fx shrink={0}>
				<Pagination
					total={paginationTotalCountRef.current}
					pageSize={result.variables?.itemsPerPage ?? 0}
					current={result.variables?.page ?? 0}
					onChange={onPaginationChange}
				/>
			</Fx>
		</Layout>
	);
}

export default Users;
