import { Formik, Form, FormikHelpers } from 'formik';
import { useEffect, useState, FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Input, Button, Fx, Notification, RedirectToLogin } from 'Components';
import { axiosClient } from 'Services/Api/client';
import AUTH_API from 'Services/Api/Auth/Api';
import {
	TwoStepVerificationAuthenticateResponse,
	Values
} from '../TwoStepAuthentication.types';
import { AuditLogPage } from 'Services/Api/AuditLog/Types';
import { TwoFactorAuthorizationStatus } from 'Services/Api/Users/Types';
import loginSchema from './schema';
import { getAppRoutes } from 'Pages/App/App.constants';
import { AuthState } from 'Pages/Auth/Auth.types';
import { useStores } from 'Hooks/useStore';
import styles from '../TwoStepAuthentication.module.scss';

interface TokenFormProps {
	isMFARegistrationPage?: boolean;
}

function useTwoStepAuthentication(
	isMFARegistrationPage: TokenFormProps['isMFARegistrationPage']
) {
	const [isBlocked, setIsBlocked] = useState(false);
	const history = useHistory<AuthState>();
	const { authStore } = useStores();
	const { t } = useTranslation();

	useEffect(() => {
		if (authStore.currentUser === null) {
			setIsBlocked(true);
		}
	}, [authStore.currentUser]);

	const initialValues = {
		token: ''
	};

	const appRoutes = getAppRoutes();

	const onSubmit = async (values: Values, helpers: FormikHelpers<Values>) => {
		const returnUrl = history.location.state?.returnUrl;
		const {
			data
		} = await axiosClient.post<TwoStepVerificationAuthenticateResponse>(
			AUTH_API.TWO_STEP_AUTHENTICATION_VALIDATE,
			{
				token: values.token,
				page:
					history.location.pathname ===
					appRoutes.TWO_STEP_REGISTRATION
						? AuditLogPage.MFARegistration
						: undefined
			}
		);
		if (data.validate) {
			authStore.setTwoFactorAuthorizationPassed();

			const pathName = returnUrl ?? appRoutes.HOME;

			if (isMFARegistrationPage) {
				Notification.success({
					description: t('auth.changePassword.notificationSuccess')
				});
			}

			history.push(pathName);

			return;
		}

		if (
			data.twoFAAuthorizationStatus ===
			TwoFactorAuthorizationStatus.Blocked
		) {
			history.push(appRoutes.LOGOUT);
			Notification.error({
				description: t('twoFactorAuthorization.errors.2FABlocked')
			});

			return;
		}
		helpers.setErrors({
			token: t('twoFactorAuthorization.errors.tokenIsInvalid')
		});
	};

	return {
		onSubmit,
		t,
		initialValues,
		isBlocked
	};
}

export const TokenForm: FC<TokenFormProps> = ({ isMFARegistrationPage }) => {
	const { onSubmit, t, initialValues, isBlocked } = useTwoStepAuthentication(
		isMFARegistrationPage
	);

	if (isBlocked) {
		return <RedirectToLogin />;
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={loginSchema}
		>
			{({ isSubmitting }) => (
				<Form className={styles.form}>
					<Input.FormikField
						name="token"
						required
						placeholder={t(
							'twoFactorAuthorization.input.placeholder'
						)}
						className={styles.input}
					/>
					<Fx justify="end">
						<Button
							htmlType="submit"
							type="primary"
							shape="round"
							loading={isSubmitting}
							disabled={isSubmitting}
						>
							{t('twoFactorAuthorization.button')}
						</Button>
					</Fx>
				</Form>
			)}
		</Formik>
	);
};

export default observer(TokenForm);
