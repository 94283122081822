import { DropdownMenu } from 'Components';
import { DropdownMenuProps } from 'Components/DropdownMenu/DropdownMenu';
import { getAppRoutes } from 'Pages/App/App.constants';
import { generatePath, useHistory } from 'react-router-dom';

export enum GroupDropdownMenuKey {
	All = 'groups.title',
	Deleted = 'deletedGroups.title'
}

function GroupsDropdownMenu(props: Partial<DropdownMenuProps>) {
	const history = useHistory();
	const onChange = (key: GroupDropdownMenuKey) => {
		switch (key) {
			case GroupDropdownMenuKey.All:
				history.push(generatePath(getAppRoutes().GROUPS));
				break;
			case GroupDropdownMenuKey.Deleted:
				history.push(getAppRoutes().GROUPS_DELETED);
				break;
		}
	};
	return (
		<DropdownMenu
			options={[
				{
					key: GroupDropdownMenuKey.All,
					label: GroupDropdownMenuKey.All
				},
				{
					key: GroupDropdownMenuKey.Deleted,
					label: GroupDropdownMenuKey.Deleted
				}
			]}
			onChange={onChange}
			{...props}
		/>
	);
}

export default GroupsDropdownMenu;
