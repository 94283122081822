/* eslint-disable */

export const WHITESPACE_PATTERN = /\s+/g;
export const SPECIAL_CHAR_PATTERN = /[!@$%^&*#()_\+\-={\}\[\]|\\\/"':;>.<,?±§]/g;
export const INTEGER_PATTERN = /\d/g;

export const PATTERN = {
	leadTrailSpace: /^\s+|\s+$/
};

export const FIELD_PATTERN = {
	name: /^[a-zàâçéèêëîïôûùüÿñæœáéíóú,.'\-\s]*?$/i,
	reportName: /^[a-zàâçéèêëîïôûùüÿñæœáéíóú,.'\-\s\d]*?$/i,
	phone: /^\+{1}\d+$/,
	email: /^[a-zA-Z\.@\_\-\d]*?$/,
	numeric: /^[0-9]+$/
};
