import { gql } from '@apollo/client';

export const ADD_SYSTEM_ROLE = gql`
	mutation createSystemRole($input: SystemRoleCreate!) {
		createSystemRole(input: $input) {
			id
		}
	}
`;

export const EDIT_SYSTEM_ROLE = gql`
	mutation updateSystemRole($input: SystemRoleUpdate!) {
		updateSystemRole(input: $input) {
			id
		}
	}
`;

export const DELETE_ROLES = gql`
	mutation deleteSystemRoles($input: deleteSystemRoles!) {
		deleteSystemRoles(input: $input)
	}
`;

export const CLONE_ROLE = gql`
	mutation cloneSystemRole($input: SystemRoleClone!) {
		cloneSystemRole(input: $input)
	}
`;

export const ENABLE_MFA_FOR_USER_ROLE = gql`
	mutation enableMFAForUserRole($input: RoleWithEnabledMFA) {
		enableMFAForUserRole(input: $input) {
			peakV3Ids
			shouldLogout
			mfa
		}
	}
`;
