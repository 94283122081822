import { useModal } from 'Hooks/useModal';
import PreviewReportModal from 'Pages/Report/PreviewReport/PreviewReportModal';
import CloneReportModal from './CloneReportModal';
import DeleteReportModal from './DeleteReportModal';
import { ReportModalAction, ReportModalsPropsBase } from './ReportModals.types';

function ReportModals(props: ReportModalsPropsBase) {
	const { modal } = useModal();
	if (!modal) return null;
	return (
		<>
			{modal.name === ReportModalAction.Preview && <PreviewReportModal />}
			{modal.name === ReportModalAction.Delete && <DeleteReportModal />}
			{modal.name === ReportModalAction.Clone && (
				<CloneReportModal refresh={props.refresh} />
			)}
		</>
	);
}

export default ReportModals;
