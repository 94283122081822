import { dataTreeValueToLabelValue } from 'Contexts/DataTree/DataTree.helpers';
import {
	DataTreeContextProps,
	DataTreeNode
} from 'Contexts/DataTree/DataTree.types';
import { useFormikContext } from 'formik';
import { useModal } from 'Hooks/useModal';
import { useStores } from 'Hooks/useStore';
import { isEqual } from 'lodash';
import { GroupTreeProps } from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupTree';
import { useState } from 'react';
import { GroupActiveStatus } from 'Services/Api/Groups/Types';
import { ReportType } from 'Services/Api/Reports/Types';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import { ReportFormValues } from '../../schema';
import { GroupsFieldModalProps } from './GroupsFieldModal';

export enum TabKey {
	OwnershipGroup = 'OwnershipGroup',
	Group = 'Group'
}

export type Value = Pick<ReportFormValues, 'groupId' | 'ownershipGroupId'>;

export const useGroupsFieldModal = (props: GroupsFieldModalProps) => {
	const { authStore } = useStores();
	const { hideModal } = useModal();
	const { values } = useFormikContext<ReportFormValues>();
	const initialValue = {
		groupId: !values.ownershipGroupId ? values.groupId : undefined,
		ownershipGroupId: !values.groupId ? values.ownershipGroupId : undefined
	};

	const [value, setValue] = useState<Value>(initialValue);
	const [activeTab, setActiveTab] = useState<TabKey>(
		value.ownershipGroupId ? TabKey.OwnershipGroup : TabKey.Group
	);

	const handleGroups: DataTreeContextProps['onChange'] = (val) => {
		if (activeTab !== TabKey.Group) return;
		const groupId = dataTreeValueToLabelValue<number>(val ?? [])?.[0];
		setValue({
			groupId,
			ownershipGroupId: groupId ? undefined : value.ownershipGroupId
		});
	};

	const handleOwnGroups: DataTreeContextProps['onChange'] = (val) => {
		if (activeTab !== TabKey.OwnershipGroup) return;
		const ownershipGroupId = dataTreeValueToLabelValue<number>(
			val ?? []
		)?.[0];
		setValue({
			groupId: ownershipGroupId ? undefined : value.groupId,
			ownershipGroupId
		});
	};

	const handleSubmit = (newValue?: DataTreeNode[] | undefined) => {
		props.onConfirm(
			newValue
				? { groupId: dataTreeValueToLabelValue<number>(newValue)?.[0] }
				: value
		);
		hideModal();
	};

	const toGroupsNodeEnabled = [ReportType.Users, ReportType.Groups].includes(
		values.reportType
	);
	const toGroupsNode: GroupTreeProps['toNode'] = (item, node) => ({
		...node,
		disabled: toGroupsNodeEnabled
			? false
			: item.isArchived || item.active === GroupActiveStatus.Inactive
	});

	return {
		handleGroups,
		handleOwnGroups,
		handleSubmit,
		activeTab,
		setActiveTab,
		value,
		isDirty: !isEqual(initialValue, value),
		isOwnershipGroupsTabVisible:
			values.reportType === ReportType.Users &&
			authStore.currentUser?.primarySystemRole.role ===
				SystemRoleType.SuperAdmin,
		toGroupsNode
	};
};
