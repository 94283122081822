import { useFormikContext } from 'formik';
import { JobClassField, UserRoleField } from 'Pages/User/components';
import { AddUsersCopyValues } from '../../AddUsers.types';
import { SelectProps } from 'Components/Form/Select/Select';
import styles from './Copier.module.scss';
import { CustomLabel } from '..';
import ModuleSettingsContainer, {
	useJobClass,
	useResetLmsFieldsOnGroupsChange
} from 'Pages/User/EditUser/tabs/ModuleSettings/ModuleSettingsContainer';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

function CopierModuleSettingsFields() {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext<AddUsersCopyValues>();

	const onUserRoleChange: SelectProps['onChange'] = (value) => {
		setFieldValue('moduleSettings.userRoleId', value);
	};

	const onJobClassChange: SelectProps['onChange'] = (value) => {
		setFieldValue('moduleSettings.jobClassId', value);
	};

	const resetLmsFields = useCallback(() => {
		setFieldValue('moduleSettings', undefined);
	}, [setFieldValue]);

	const { loading, data } = useJobClass({
		values
	});
	useResetLmsFieldsOnGroupsChange({ values, resetLmsFields });

	return (
		<ModuleSettingsContainer
			values={{
				groups: values.groups || [],
				primarySystemRoleId: values.primarySystemRoleId
					? [values.primarySystemRoleId]
					: []
			}}
			onDisabled={resetLmsFields}
		>
			{(disabled) => (
				<>
					<JobClassField
						label={
							<CustomLabel
								title={t('user.lmsJobcass.label')}
								subTitle={t('user.lmsJobcass.subTitle')}
							/>
						}
						value={values.moduleSettings?.jobClassId}
						onChange={onJobClassChange}
						disabled={disabled}
						className={styles.select}
						options={data}
						loading={loading}
					/>
					<UserRoleField
						label={
							<CustomLabel
								title={t('user.lmsRole.label')}
								subTitle={t('user.lmsRole.subTitle')}
							/>
						}
						value={values.moduleSettings?.userRoleId}
						onChange={onUserRoleChange}
						disabled={disabled}
						className={styles.select}
					/>
				</>
			)}
		</ModuleSettingsContainer>
	);
}

export default CopierModuleSettingsFields;
