import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Icon, Tooltip, Space } from 'Components';
import { Menu } from 'antd';
import {
	useGroupActions,
	GroupActionsProps,
	AddGroupMenuKey
} from './GroupActions.hooks';
import GroupExportButton, { GroupExportButtonProps } from './GroupExportButton';

function GroupActions(props: GroupActionsProps & GroupExportButtonProps) {
	const { groupId } = props;

	const {
		isAddGroupDisabled,
		isAddGroupPermitted,
		isAddTenantPermitted,
		isExportPermitted,
		isAddHierarchyGroupsEnabled,
		maxHierarchyLevel,
		onAddGroupMenuClick,
		addGroupClick,
		isAmountOfGroupsInTenantIsReached,
		groupsHierarchySizeLimit,
		isExportButtonDisabled
	} = useGroupActions(props);
	const { t } = useTranslation();
	const tooltipTitle = isAmountOfGroupsInTenantIsReached
		? t('groups.groupDetails.groupDetailsActions.tooltipHierarchyLimit', {
				groupsHierarchySizeLimit
		  })
		: t(
				'groups.groupDetails.groupDetailsActions.tooltipMaxHierarchyLevel',
				{
					maxHierarchyLevel
				}
		  );

	const addGroupButton = (
		<Tooltip
			title={tooltipTitle}
			trigger="hover"
			enabled={isAddGroupDisabled}
		>
			<Button
				type="primary"
				shape="round"
				icon={<Icon type="plus" />}
				disabled={isAddGroupDisabled}
			>
				{t('groups.groupDetails.groupDetailsActions.addGroup')}
			</Button>
		</Tooltip>
	);

	return (
		<Space>
			{isExportPermitted && (
				<GroupExportButton
					params={props.params}
					disabled={isExportButtonDisabled}
				/>
			)}

			{groupId
				? isAddGroupPermitted && (
						<Dropdown
							overlay={
								<Menu onClick={onAddGroupMenuClick}>
									<Menu.Item
										key={AddGroupMenuKey.Single}
										icon={<Icon type="plus" />}
									>
										{t(
											'groups.groupDetails.groupDetailsActions.addSingleGroup'
										)}
									</Menu.Item>
									<Menu.Item
										key={AddGroupMenuKey.Multiple}
										icon={<Icon type="plusEllipsis" />}
									>
										{t(
											'groups.groupDetails.groupDetailsActions.addMultipleGroups'
										)}
									</Menu.Item>
									{isAddHierarchyGroupsEnabled && (
										<Menu.Item
											key={AddGroupMenuKey.Hierarchy}
											icon={<Icon type="hierarchy" />}
										>
											{t(
												'groups.groupDetails.groupDetailsActions.addHierarchy'
											)}
										</Menu.Item>
									)}
								</Menu>
							}
							trigger={['click']}
							arrow={false}
							disabled={isAddGroupDisabled}
						>
							{addGroupButton}
						</Dropdown>
				  )
				: isAddTenantPermitted && (
						<Button
							type="primary"
							shape="round"
							icon={<Icon type="plus" />}
							onClick={addGroupClick}
						>
							{t(
								'groups.groupDetails.groupDetailsActions.addTenant'
							)}
						</Button>
				  )}
		</Space>
	);
}

export default GroupActions;
