import { Stack } from 'Components';
import GroupSummary from './GroupSummary/GroupSummary';
import Widgets from './Widgets/Widgets';

function Overview() {
	return (
		<Stack>
			<Widgets />
			<GroupSummary />
		</Stack>
	);
}

export default Overview;
