import { useTranslation } from 'react-i18next';
import GroupModal from './GroupModal/GroupModal';
import { useMoveGroupModal } from './MoveGroupModal.hooks';

export interface MoveGroupModalProps {
	onSubmit: () => void;
}
function MoveGroupModal(props: MoveGroupModalProps) {
	const { t } = useTranslation();
	const { modalValue, onSubmit } = useMoveGroupModal(props);

	if (!modalValue) return null;

	const title = t('groups.groupDetails.components.moveGroupModal.title', {
		groupName: modalValue.disabledValue?.title ?? ''
	});
	const tooltip = t('groups.groupDetails.components.moveGroupModal.tooltip');
	const okText = t(
		'groups.groupDetails.components.moveGroupModal.moveToGroup'
	);
	return (
		<GroupModal
			title={title}
			tooltip={tooltip}
			okText={okText}
			initialValue={modalValue}
			onSubmit={onSubmit}
		/>
	);
}

export default MoveGroupModal;
