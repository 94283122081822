const REPORTS_API = {
	STANDARD_REPORTS: '/reports/standardReports',
	SAVED_REPORTS: '/reports/savedReports',
	SAVE_REPORT: '/reports/savedReports',
	CLONE_REPORT: '/reports/savedReports/clone',
	GET_REPORT: '/reports/savedReports',
	PREVIEW_REPORT: '/reports/preview',
	DOWNLOAD_REPORT: '/reports/download/url'
};

export default REPORTS_API;
