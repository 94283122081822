import { useTranslation } from 'react-i18next';
import { SelectProps as AntSelectProps } from 'antd/lib/select';
import { FormItem, Select, InactiveHierarchyModalContent } from 'Components';
import ModalContextProvider, { useModal } from 'Hooks/useModal';
import { AddUserValues } from 'Pages/User/User.types';
import { SelectProps } from 'Components/Form/Select/Select';
import GroupModal, {
	GroupModalValue,
	GroupModalProps
} from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupModal';
import { ShowInactiveModal } from 'Components/InactiveHierarchyModalContent/InactiveHierarchyModalContent.types';
import { isGroupOrHierarchyIsInactiveOrArchived } from './GroupsField.helpers';

export type GroupsFieldValue = Required<
	Pick<AddUserValues, 'groups'>
>['groups'];
export type GroupsFieldProps = Pick<SelectProps, 'disabled' | 'placeholder'> &
	Pick<GroupModalProps, 'title' | 'okText' | 'checkable' | 'description'> & {
		onSubmit: (value: GroupsFieldValue) => void;
		error: string | boolean | undefined;
		value: GroupsFieldValue | undefined;
		label?: string;
		required?: boolean;
		className?: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onDeselect?: AntSelectProps<any>['onDeselect'];
	};
type GroupsFieldContentProps = ShowInactiveModal & GroupsFieldProps;

function GroupsFieldContent({
	value,
	error,
	label,
	disabled,
	placeholder,
	onSubmit,
	required = true,
	className,
	checkable,
	description,
	title,
	okText,
	onDeselect,
	showInactiveModal
}: GroupsFieldContentProps) {
	const { modal, showModal } = useModal();
	const { t } = useTranslation();

	const onClick: React.MouseEventHandler<HTMLDivElement> = () => {
		if (disabled) return;
		showModal({ value: null });
	};

	const onModalSubmit = async (value: GroupModalValue) => {
		const values = value ?? [];

		const groupHierarchyOrArchived = isGroupOrHierarchyIsInactiveOrArchived(
			values
		);

		if (groupHierarchyOrArchived) {
			await showInactiveModal();
		}

		onSubmit(
			values.reduce<GroupsFieldValue>((acc, value) => {
				if (!value.data) {
					return acc;
				}
				acc.push({
					key: value.key as number,
					value: value.key,
					label: value.title,
					data: value.data
				});
				return acc;
			}, [])
		);
	};

	const onChange: SelectProps['onChange'] = (_, option) => {
		const list = option as AddUserValues['groups'];
		const options: AddUserValues['groups'] =
			list?.map((opt) => ({
				key: opt.key,
				label: opt.label,
				value: opt.value,
				data: opt.data
			})) || [];
		onSubmit(options);
	};

	return (
		<>
			{modal && (
				<GroupModal
					initialValue={{
						initialValue: value
					}}
					checkable={checkable ?? true}
					title={title ?? t('user.groupModal.title')}
					okText={okText ?? t('user.groupModal.addToGroup')}
					description={
						description ?? t('user.groupModal.description')
					}
					onSubmit={onModalSubmit}
					hideOnSubmit
				/>
			)}
			<FormItem
				label={label}
				required={required}
				validateStatus={(error && 'error') || undefined}
				help={error}
				className={className}
			>
				<Select
					mode="multiple"
					labelInValue
					onClick={onClick}
					onChange={onChange}
					open={false}
					disabled={disabled}
					value={value}
					onDeselect={onDeselect}
					placeholder={placeholder}
				>
					{value?.map((option) => (
						<Select.Option {...option} children={null} />
					))}
				</Select>
			</FormItem>
		</>
	);
}

function GroupsField(props: GroupsFieldContentProps) {
	return (
		<ModalContextProvider>
			<GroupsFieldContent {...props} />
		</ModalContextProvider>
	);
}

function InactiveHierarchyModal(props: GroupsFieldProps) {
	return (
		<InactiveHierarchyModalContent>
			{/* @NOTE: showInactiveModal={undefined as never} need for correct ts functionality */}
			<GroupsField {...props} showInactiveModal={undefined as never} />
		</InactiveHierarchyModalContent>
	);
}

export default InactiveHierarchyModal;
