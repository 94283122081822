import { Tree, TreeProps as AntTreeProps } from 'antd';
import { useDataTreeContext } from '../../DataTreeContext';
import styles from './List.module.scss';
import { Fx, Icon } from 'Components';
import { DataTreeNode } from '../../DataTree.types';
import { isBoolean } from 'lodash';
import { useEffect } from 'react';
import cn from 'classnames';

export type ListProps = AntTreeProps & {
	highlightValue?: boolean;
};
function List(props: ListProps) {
	const {
		initialized,
		root,
		value,
		treeData,
		expandedKeys,
		nested,
		loadData,
		setCheckable,
		onExpand,
		onSelect,
		onCheck
	} = useDataTreeContext();

	useEffect(() => {
		// update context with tree way of selection (one/multiple) to maintain
		// the same logic when selecting option from the search dropdown
		if (isBoolean(props.checkable)) {
			setCheckable(props.checkable);
		}
		// eslint-disable-next-line
	}, []);
	const titleRenderer: ListProps['titleRender'] = (node) => {
		const itemNode = node as DataTreeNode;
		const isSelected =
			props.highlightValue &&
			Boolean(value?.find((item) => item.key === itemNode.key));
		return (
			<Fx align="middle" justify="space-between">
				{itemNode.label ?? itemNode.title}
				{isSelected && <Icon type="check" />}
			</Fx>
		);
	};
	const selectedKeys = (value || []).map((item) => item.key);
	return (
		<>
			<Tree
				className={cn(props.className, styles.root, {
					[styles.flat]: !nested,
					[styles.hasRoot]: Boolean(root)
				})}
				switcherIcon={<Icon type="chevronDown" />}
				// constant change in treeData leads to warning `Can't perform a React state update on an unmounted component` in `Tree`
				// Checking `initialized` flag prevents intermidiate changes to take place in `treeData`
				treeData={initialized ? treeData : undefined}
				loadData={loadData}
				titleRender={titleRenderer}
				selectedKeys={selectedKeys}
				checkedKeys={selectedKeys}
				expandedKeys={expandedKeys}
				onExpand={onExpand}
				onSelect={props.checkable ? undefined : onSelect}
				onCheck={onCheck}
				checkable={props.checkable}
				checkStrictly={props.checkStrictly}
				disabled={!treeData?.length || props.disabled}
			/>
		</>
	);
}

export default List;
