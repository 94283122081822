import { FormItem, Input } from 'Components';
import { FormItemProps } from 'Components/Form/FormItem/FormItem';
import { GROUPS_LABEL } from 'Pages/Groups/Groups.constants';
import { useTranslation } from 'react-i18next';

function AllGroupsField(props: FormItemProps) {
	const { t } = useTranslation();
	return (
		<FormItem
			{...props}
			label={props.label === null ? null : t('user.groups')}
		>
			<Input value={t(GROUPS_LABEL.ALL_TENANTS) as string} disabled />
		</FormItem>
	);
}

export default AllGroupsField;
