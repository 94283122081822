import { Group, GroupActiveStatus } from 'Services/Api/Groups/Types';
import { Text } from 'Components';
import { HierarchyGroup } from 'Pages/GroupsHierarchy/GroupsHierarchy.types';

export const groupToTreeNode = (item: Group | HierarchyGroup) => {
	const color = item.isArchived
		? 'warning'
		: item.active === GroupActiveStatus.Inactive
		? 'default'
		: undefined;

	return {
		key: item.id,
		title: item.name,
		parentKey: item.parentId,
		label: <Text color={color}>{item.name}</Text>
	};
};
