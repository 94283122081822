import { minBy } from 'lodash';
import { generatePath, useHistory } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';

import { Prompt } from 'Components';
import { PromptHelpers } from 'Components/Prompt/Prompt';
import { getAppRoutes } from 'Pages/App/App.constants';
import { Group as GroupType } from 'Services/Api/Groups/Types';
import Group from './Group/Group';
import { useAddMultipleGroups } from './Groups.hooks';
import GroupsDropdownMenu, { GroupDropdownMenuKey } from './GroupsDropdownMenu';
import GroupsLayoutTemplate from './GroupsLayoutTemplate';
import { AddMultipleGroupsMenu } from './components';
import useScrollIntoView from 'Hooks/useScrollIntoView';
import {
	DataTreeContextProps,
	DataTreeContextValue,
	DataTreeNode
} from 'Contexts/DataTree/DataTree.types';
import { useEffect, useState } from 'react';
import GroupsSearch from './GroupsSearch';
import { useGroupTree } from 'Hooks';
import { DataTreeLayoutProps } from 'Contexts/DataTree/components/DataTreeLayout/DataTreeLayout';
import { usePrevious } from 'Hooks/usePrevious';
import { GROUPS_LABEL } from './Groups.constants';
import styles from './GroupsLayout.module.scss';
import { useTranslation } from 'react-i18next';

function Groups(promptHelpers: PromptHelpers) {
	const { t } = useTranslation();
	const history = useHistory();
	const {
		mode,
		form,
		groupId,
		response,
		onCancel,
		handleAddGroupClick,
		handleOnMultipleGroupSubmit,
		onMultipleGroupsClick,
		handleDeleteGroupClick,
		fields,
		blockIsUniqError,
		validateGroupUniqName,
		validateGroupSyncCode
	} = useAddMultipleGroups({ promptHelpers });
	useScrollIntoView(mode);
	const { initialLoad, loadMore, loadValue } = useGroupTree({});

	const [root, setRoot] = useState<{
		node: DataTreeNode;
		highestLevel: number;
	}>({
		node: {
			key: -1,
			title: ''
		},
		highestLevel: 0
	});
	const [activeGroup, setActiveGroup] = useState<GroupType | undefined>();

	const load = async () => {
		const result = await initialLoad();
		const highestAncestor = minBy(result, (item) => item.level);
		if (highestAncestor) {
			setRoot({
				node: {
					...root.node,
					// Note: label will be wrong when language is changed
					title: t(
						highestAncestor.level === 1
							? GROUPS_LABEL.ALL_TENANTS
							: GROUPS_LABEL.ALL_GROUPS
					)
				},
				highestLevel: highestAncestor.level
			});
		}

		return result;
	};

	const onChange: DataTreeContextProps<GroupType>['onChange'] = (value) => {
		const item = value?.[0];

		if (item) {
			setActiveGroup(item.data);
		}

		const params =
			item?.key === root.node.key ? undefined : { groupId: item?.key };
		history.replace(generatePath(getAppRoutes().GROUPS, params));
	};

	const activeGroupId = Number(groupId);
	const initialValue = activeGroupId ? [{ key: activeGroupId }] : [];

	return (
		<FormProvider {...form}>
			<GroupsLayoutTemplate<GroupType>
				root={root.node}
				onChange={onChange}
				load={load}
				loadMore={loadMore}
				loadInitialValue={loadValue}
				disabled={mode === 'add-multiple'}
				initialValue={initialValue}
				header={(props, context) => (
					<GroupsHeader
						mode={mode}
						onCancel={onCancel}
						handleOnMultipleGroupSubmit={
							handleOnMultipleGroupSubmit
						}
						response={response}
						blockIsUniqError={blockIsUniqError}
						className={props.className}
						reInitialize={context.reInitialize}
					/>
				)}
			>
				{({ initialized, loading, reInitialize }) => (
					<>
						{initialized && !loading && (
							<Group
								group={activeGroup || null}
								key={activeGroup?.id}
								defaultGroupName={root.node.title ?? ''}
								onSubmit={reInitialize}
								mode={mode}
								rootLevel={root.highestLevel}
								onMultipleGroupsClick={onMultipleGroupsClick}
								handleAddGroupClick={handleAddGroupClick}
								handleDeleteGroupClick={handleDeleteGroupClick}
								validateGroupSyncCode={validateGroupSyncCode}
								// @ts-ignore
								fields={fields}
								validateUniqGroupName={validateGroupUniqName}
							/>
						)}
					</>
				)}
			</GroupsLayoutTemplate>
		</FormProvider>
	);
}

type GroupsHeaderProps = Pick<
	ReturnType<typeof useAddMultipleGroups>,
	| 'mode'
	| 'response'
	| 'onCancel'
	| 'handleOnMultipleGroupSubmit'
	| 'blockIsUniqError'
> &
	Parameters<DataTreeLayoutProps['header']>[0] &
	Pick<DataTreeContextValue, 'reInitialize'>;
const GroupsHeader = ({
	mode,
	response,
	onCancel,
	handleOnMultipleGroupSubmit,
	blockIsUniqError,
	className,
	reInitialize
}: GroupsHeaderProps) => {
	const previousMode = usePrevious(mode);

	useEffect(() => {
		if (
			previousMode &&
			mode &&
			previousMode !== mode &&
			response.called &&
			mode === 'manage'
		) {
			reInitialize();
		}
	}, [mode, previousMode, response, reInitialize]);

	return mode === 'manage' ? (
		<>
			<GroupsDropdownMenu value={GroupDropdownMenuKey.All} />
			<GroupsSearch
				className={className}
				dropdownClassName={styles.searchDropdown}
			/>
		</>
	) : (
		<AddMultipleGroupsMenu
			onCancel={onCancel}
			onSubmit={handleOnMultipleGroupSubmit}
			loading={response.loading}
			headerTranslationPath="groups.addMultipleGroups.title"
			disabledSubmit={blockIsUniqError}
		/>
	);
};

function GroupsWithPrompt() {
	return <Prompt>{(helpers) => <Groups {...helpers} />}</Prompt>;
}

export default GroupsWithPrompt;
