import { Fx, Icon, Card, Row, Col } from 'Components';
import { useStores } from 'Hooks/useStore';
import {
	InfoTooltip,
	OpenInNewTab,
	Statistic
} from 'Pages/Dashboard/components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import PieChart from 'Pages/Dashboard/visualizations/PieChart/PieChart';
import { useEffect, useMemo } from 'react';
import { VisualizationTimePeriod } from 'Pages/Dashboard/visualizations/Visualizations.types';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import { getIsPending } from 'Stores/util';
import { inPercent } from 'Helpers/numeric';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';
import { ElearningWidgets } from './Elearning.types';

const PATH = 'dashboard.tabs.elearning';

const TotalElearningOverview = () => {
	const { t } = useTranslation();
	const { dashboardStore } = useStores();
	const { filters } = useDashboardContext();

	const { elearning, status } = dashboardStore;

	const chartData = useMemo(() => {
		if (!elearning.overview) {
			return [];
		}
		return [
			{
				timePeriod: VisualizationTimePeriod.Previous,
				x: t('dashboard.inProgress'),
				y: elearning.overview.inProgress
			},
			{
				timePeriod: VisualizationTimePeriod.Current,
				x: t('dashboard.completed'),
				y: elearning.overview.complete
			},
			{
				x: t('dashboard.notStarted'),
				y: elearning.overview.notStarted
			}
		];
	}, [t, elearning.overview]);

	useEffect(() => {
		dashboardStore.getElearningOverview(filters);
		// eslint-disable-next-line
	}, [filters]);

	return (
		<Card
			loading={getIsPending(status.getElearningOverview)}
			title={t(`${PATH}.totalElearningOverview.title`)}
			extra={
				<Fx align="middle">
					<InfoTooltip
						title={t(`${PATH}.totalElearningOverview.tooltip`)}
					/>
				</Fx>
			}
			footer={
				<OpenInNewTab
					tab={DashboardTabKey.Elearning}
					widget={ElearningWidgets.TotalCourseOverview}
				/>
			}
		>
			<Row fullHeight wrap>
				<Col fullHeight xs={10} push={1}>
					<Fx align="middle" justify="center" fullHeight fullWidth>
						<PieChart
							data={chartData}
							loading={false}
							formatFn={inPercent}
						/>
					</Fx>
				</Col>
				<Col fullHeight xs={10} push={1} pull={4}>
					<Fx
						align="middle"
						justify="space-between"
						fullHeight
						fullWidth
					>
						<Statistic
							vertical
							inheritColor
							icon={<Icon type="reward" />}
							value={inPercent(elearning.overview?.complete)}
							type="happy"
						>
							{t('dashboard.completed')}
						</Statistic>
						<Statistic
							vertical
							inheritColor
							icon={<Icon type="sandglass" />}
							value={inPercent(elearning.overview?.inProgress)}
						>
							{t('dashboard.inProgress')}
						</Statistic>
						<Statistic
							vertical
							inheritColor
							icon={<Icon type="minusCircle" />}
							value={inPercent(elearning.overview?.notStarted)}
							type="default"
						>
							{t('dashboard.notStarted')}
						</Statistic>
					</Fx>
				</Col>
			</Row>
		</Card>
	);
};

export default observer(TotalElearningOverview);
