import { isApolloError } from '@apollo/client';
import {
	APIErrorCodes,
	ApolloErrorType,
	GraphQlErrorInfoTypeByIndex,
	GraphQlErrorInfoTypeMultiple,
	GraphQlErrorInfoTypeSingle,
	TInfo
} from 'app-types';
import { TFunction } from 'i18next';
import { isArray } from 'lodash';
import { SchemaFieldDescription } from 'yup/lib/schema';
import { getFieldErrors, getNormalizedErrorInfo } from './graphql.helpers';

const CODE_401 = 'code 401';

const isApolloErrorType = (e: unknown): e is ApolloErrorType =>
	!!e && isApolloError(e as Error);

const isApolloErrorByIndex = (
	e: unknown
): e is ApolloErrorType<GraphQlErrorInfoTypeByIndex> => {
	return (
		isApolloErrorType(e) &&
		!isApolloErrorMultiple(e) &&
		!isApolloErrorSingle(e)
	);
};

const isApolloErrorSingle = (
	e: unknown
): e is ApolloErrorType<GraphQlErrorInfoTypeSingle> => {
	return (
		isApolloErrorType(e) &&
		Boolean(
			(e.graphQLErrors[0].errorInfo as GraphQlErrorInfoTypeSingle)
				?.errorCode
		)
	);
};

const isApolloErrorMultiple = (
	e: unknown
): e is ApolloErrorType<GraphQlErrorInfoTypeMultiple> => {
	return isApolloErrorType(e) && isArray(e.graphQLErrors[0].errorInfo);
};

export function checkApolloError(error: unknown) {
	return {
		is: (errorCode: APIErrorCodes | APIErrorCodes[]) => {
			if (!isApolloErrorType(error)) {
				return false;
			}

			if (isApolloErrorByIndex(error)) {
				const errorInfoKeys = Object.keys(
					error.graphQLErrors[0]?.errorInfo ?? {}
				);

				if (
					errorInfoKeys.every((key) => {
						const toNumber = Number(key);
						return !isNaN(toNumber);
					})
				) {
					return errorInfoKeys.some((key) => {
						// @ts-ignore
						const info = error.graphQLErrors[0].errorInfo[key];
						return info[0].errorCode === errorCode;
					});
				}
			}
			if (isApolloErrorSingle(error)) {
				return (
					error.graphQLErrors[0].errorInfo?.errorCode === errorCode
				);
			}
			if (isApolloErrorMultiple(error)) {
				return (
					error.graphQLErrors[0].errorInfo?.[0].errorCode ===
					errorCode
				);
			}
		},
		isUnauthorizedError: () => {
			return isApolloErrorType(error) && error.message.includes(CODE_401);
		},
		getErrorIndexes: (errorCode: APIErrorCodes): number[] => {
			if (!isApolloErrorByIndex(error)) {
				return [];
			}
			const info = error.graphQLErrors[0].errorInfo ?? {};
			const indexes = Object.keys(info).map(Number);

			return indexes.reduce<number[]>((acc, key) => {
				if (
					info[key].some(
						({ errorCode: errorInfoCode }: { errorCode: string }) =>
							errorInfoCode === errorCode
					)
				) {
					acc.push(Number(key));
				}
				return acc;
			}, []);
		},
		getMeta: <M>() => {
			const apolloError = error as ApolloErrorType<GraphQlErrorInfoTypeSingle>;
			return apolloError.graphQLErrors[0].errorInfo?.meta as M;
		},

		isApolloError: () => isApolloErrorType(error),
		getNonFieldErrors: (
			t: TFunction,
			path?: string,
			meta?: Record<string, Record<string, string>>
		) => {
			if (!(isApolloErrorSingle(error) || isApolloErrorMultiple(error)))
				return [];

			const errorInfo = error.graphQLErrors[0].errorInfo || {};
			const info = isArray(errorInfo) ? errorInfo : [errorInfo];
			return info.reduce<TInfo[]>((acc, value) => {
				if (value.fieldName) {
					return acc;
				}
				const infoValue = getNormalizedErrorInfo(
					value,
					meta ? meta[value.errorCode] : {},
					t,
					path
				);
				if (infoValue.message) {
					acc.push(infoValue.message);
				}

				return acc;
			}, []);
		},
		getFieldErrors: (
			t: TFunction,
			meta: Record<string, SchemaFieldDescription>,
			translationPath?: string
		) => {
			if (!(isApolloErrorSingle(error) || isApolloErrorMultiple(error)))
				return;
			const errorInfo = error.graphQLErrors[0].errorInfo ?? {};
			const info = isArray(errorInfo) ? errorInfo : [errorInfo];
			return getFieldErrors(info, meta, t, translationPath);
		},

		getErrorInfo: <I>() =>
			isApolloErrorByIndex(error)
				? ((error.graphQLErrors[0].errorInfo as unknown) as I) ?? {}
				: null
	};
}
