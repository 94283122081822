import { createContext, useState, useCallback, FC, useContext } from 'react';
import { noop } from 'lodash';
import { ErrorScreen } from 'Components';

export type ErrorBoundaryStatus = 'notFound' | 'internal' | 'forbidden' | null;
interface ErrorContextProvider {
	setStatus: (status: ErrorBoundaryStatus) => void;
}

export const ErrorContext = createContext<ErrorContextProvider>({
	setStatus: noop
});

const ErrorBoundary: FC = ({ children }) => {
	const [status, setStatus] = useState<ErrorBoundaryStatus>(null);

	const handleSetStatus = useCallback((status: ErrorBoundaryStatus) => {
		setStatus(status);
	}, []);

	return (
		<ErrorContext.Provider
			value={{
				setStatus: handleSetStatus
			}}
		>
			{status === 'notFound' ? (
				<ErrorScreen.NotFound />
			) : status === 'internal' ? (
				<ErrorScreen.InternalError />
			) : status === 'forbidden' ? (
				<ErrorScreen.ErrorPermission />
			) : (
				children
			)}
		</ErrorContext.Provider>
	);
};

export const useErrorBoundaryContext = () => useContext(ErrorContext);

export default ErrorBoundary;
