import { ColumnProps } from 'antd/lib/table';
import { OptionalValue } from 'Components';
import {
	useCreateColumns,
	UseCreateColumnsInterface
} from 'Components/Table/createColumns';
import { useTranslation } from 'react-i18next';
import {
	ElearningGroupSummary,
	ElearningGroupSummaryRequest
} from 'Services/Api/Dashboard/Types';
import { renderMoodIndicatorColumn } from '../../columns.helpers';

export function useColumnProps<T extends ElearningGroupSummary>(
	helpers?: ReturnType<UseCreateColumnsInterface<T, T>>
) {
	const { t } = useTranslation();
	const columns: Array<ColumnProps<T>> = [
		{
			...helpers?.sort,
			title: t('dashboard.groupSummary.name'),
			key: 'groupName',
			dataIndex: 'groupName',
			width: '19.82%',
			sortOrder: helpers?.getSortOrder('groupName')
		},
		{
			...helpers?.sort,
			title: t('dashboard.groupSummary.score'),
			key: 'score',
			dataIndex: 'score',
			render: renderMoodIndicatorColumn,
			sortOrder: helpers?.getSortOrder('score'),
		},
		{
			...helpers?.sort,
			title: t('dashboard.users'),
			key: 'users',
			dataIndex: 'users',
			sortOrder: helpers?.getSortOrder('users'),
			render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			...helpers?.sort,
			title: t('dashboard.enrollments'),
			key: 'enrollments',
			dataIndex: 'enrollments',
			sortOrder: helpers?.getSortOrder('enrollments'),
      render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			...helpers?.sort,
			title: t('dashboard.completed'),
			key: 'complete',
			dataIndex: 'complete',
			sortOrder: helpers?.getSortOrder('complete'),
      render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			...helpers?.sort,
			title: t('dashboard.inProgress'),
			key: 'inProgress',
			dataIndex: 'inProgress',
			sortOrder: helpers?.getSortOrder('inProgress'),
      render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			...helpers?.sort,
			title: t('dashboard.notStarted'),
			key: 'notStarted',
			dataIndex: 'notStarted',
			sortOrder: helpers?.getSortOrder('notStarted'),
      render: (value) => <OptionalValue>{value}</OptionalValue>
		}
	];
	return columns;
}

export const useColumns = ({
	params
}: {
	params: ElearningGroupSummaryRequest;
}) => {
	const helpers = useCreateColumns<
		ElearningGroupSummary,
		ElearningGroupSummary
	>({
		filterValues: undefined,
		sortDesc: params.sort
	});

	return useColumnProps(helpers);
};
