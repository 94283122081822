import { Checkbox, Col, Row, Select } from 'Components';
import { useFormikContext } from 'formik';
import {
	REPORT_FIELD_LABEL,
	REPORT_USER_STATUS
} from '../EditReport.constants';
import { ReportFormValues } from '../schema';
import { ReportType } from 'Services/Api/Reports/Types';
import { USER_STATUSES } from 'Pages/Users/Users.constants';
import { useTranslation } from 'react-i18next';

function ReportTypeFilters() {
	const { t } = useTranslation();
	const { initialValues } = useFormikContext<ReportFormValues>();

	return (
		<>
			{initialValues.reportType === ReportType.Users && (
				<>
					<Row>
						<Col xs={10}>
							<Select.FormikField
								name="userStatus"
								mode="multiple"
								label={t(REPORT_FIELD_LABEL.userStatus)}
								fullWidth
								help={null}
							>
								{REPORT_USER_STATUS.map((opt) => (
									<Select.Option
										key={opt.value}
										value={opt.value}
									>
										{opt.label()}
									</Select.Option>
								))}
							</Select.FormikField>
						</Col>
					</Row>
					<Row>
						<Col xs={10}>
							<Select.FormikField
								name="userLoginStatus"
								mode="multiple"
								filterOption={false}
								label={t(REPORT_FIELD_LABEL.userLoginStatus)}
								fullWidth
								help={null}
							>
								{USER_STATUSES.map((opt) => (
									<Select.Option
										key={opt.value}
										value={opt.value}
									>
										{opt.text()}
									</Select.Option>
								))}
							</Select.FormikField>
						</Col>
					</Row>
				</>
			)}
			{initialValues.reportType === ReportType.ExecutiveSummary && (
				<Checkbox.Group.FormikField
					name="executeSummaryTypes"
					label={t(REPORT_FIELD_LABEL.executeSummaryTypes)}
					marginBottom={false}
					options={initialValues.data.tabs}
					required
				/>
			)}
		</>
	);
}

export default ReportTypeFilters;
