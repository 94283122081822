import { VisualizationTimePeriod } from '../Visualizations.types';

export type LineValue = LineValueCategories & {
	x: string;
};

export interface LineValueCategories {
	y: number;
	y0?: number;
}

export type LineValueItem = LineValue & {
	axisYear: number;
};

export interface LineValuesByPeriod {
	values: LineValueItem[];
	year: number;
	timePeriod: VisualizationTimePeriod;
}

export enum LineTimeInterval {
	Month = 'month',
	Quarter = 'quarter'
}
export interface LineChartProps {
	categories?: (keyof LineValueCategories)[];
}
