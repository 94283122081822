import React, { useRef, useCallback } from 'react';
import { Input as AntInput } from 'antd';
import { InputProps as AntInputProps } from 'antd/lib/input';
import { Button, Icon } from 'Components';
import cn from 'classnames';
import styles from './Input.module.scss';

export const useInputAllowClearProps = (props: AntInputProps) => {
	const ref = useRef<AntInput | null>(null);

	const onClearClick: React.MouseEventHandler<HTMLElement> = useCallback(
		(e) => {
			ref.current?.handleReset(e);
		},
		[]
	);

	const needClear = props.value && !props.disabled && !props.readOnly;

	return {
		ref,
		suffix: !props.allowClear ? (
			props.suffix
		) : (
			<Button
				type="default"
				text
				thin
				onClick={onClearClick}
				className={cn({
					[styles.hidden]: !needClear
				})}
			>
				<Icon type="close" />
			</Button>
		),
		allowClear: false
	};
};
