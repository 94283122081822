import { Modal, Tabs } from 'Components';
import DataTree from 'Contexts/DataTree/DataTree';
import { useModal } from 'Hooks/useModal';
import { useStores } from 'Hooks/useStore';
import GroupTree from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupTree';
import PropertiesFieldModal from 'Pages/OwnershipGroup/AddOwnershipGroup/PropertiesField/PropertiesFieldModal';
import OwnershipGroups from 'Pages/Users/columns/GroupsColumnFilter/tabs/OwnershipGroups';
import { useTranslation } from 'react-i18next';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import { ReportFormValues } from '../../schema';
import { useGroupsFieldModal } from './GroupsFieldModal.hooks';

enum TabKey {
	OwnershipGroup = 'OwnershipGroup',
	Group = 'Group'
}

type Value = Pick<ReportFormValues, 'groupId' | 'ownershipGroupId'>;

export interface GroupsFieldModalProps {
	onConfirm: (value: Value) => void;
}
function GroupsFieldModal(props: GroupsFieldModalProps) {
	const { t } = useTranslation();
	const { authStore } = useStores();
	const { modal, hideModal } = useModal();
	const {
		value,
		isDirty,
		handleGroups,
		handleOwnGroups,
		handleSubmit,
		activeTab,
		setActiveTab,
		isOwnershipGroupsTabVisible,
		toGroupsNode
	} = useGroupsFieldModal(props);

	if (!modal) return null;

	const groupIdInitialValue = value.groupId ? [value.groupId] : undefined;

	if (
		authStore.currentUser?.primarySystemRole.role === SystemRoleType.Owner
	) {
		return (
			<PropertiesFieldModal
				initialValue={groupIdInitialValue}
				onSubmit={handleSubmit}
				title={t('dashboard.filters.propertiesModal.title')}
				okText={t('apply')}
				legendProps={{
					exclude: ['inactive', 'deleted', 'archived']
				}}
				listProps={{
					checkable: false
				}}
			/>
		);
	}

	return (
		<Modal
			visible
			size="sm"
			okText={t('apply')}
			onOk={() => handleSubmit()}
			onCancel={hideModal}
			okButtonProps={{
				disabled: !isDirty
			}}
		>
			<Tabs
				defaultActiveKey={activeTab}
				onChange={(activeKey) => setActiveTab(activeKey as TabKey)}
			>
				<Tabs.TabPane key={TabKey.Group} tab={t('groups.allGroups')}>
					<GroupTree
						clearValue={!value.groupId}
						initialValue={
							value.groupId ? [value.groupId] : undefined
						}
						onChange={handleGroups}
						toNode={toGroupsNode}
					>
						<DataTree.List highlightValue />
					</GroupTree>
				</Tabs.TabPane>
				{isOwnershipGroupsTabVisible && (
					<Tabs.TabPane
						key={TabKey.OwnershipGroup}
						tab={t('ownershipGroups.title')}
					>
						<OwnershipGroups
							clearValue={!value.ownershipGroupId}
							initialValue={
								value.ownershipGroupId
									? [value.ownershipGroupId]
									: undefined
							}
							listProps={{ checkable: false }}
							onChange={handleOwnGroups}
						/>
					</Tabs.TabPane>
				)}
			</Tabs>
		</Modal>
	);
}

export default GroupsFieldModal;
