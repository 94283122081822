import { useMutation } from '@apollo/client';
import { Notification, Spin } from 'Components';
import { FormikHelpers } from 'formik';
import EditUserForm from 'Pages/User/EditUser/EditUserForm';
import { useStores } from 'Hooks/useStore';
import { UPDATE_CURRENT_USER } from 'Services/Api/Auth/Mutations';
import { observer } from 'mobx-react-lite';
import { EditProfileValues } from '../User.types';
import { getInitialValues } from '../EditUser/EditUser.helpers';
import { useHistory } from 'react-router';
import { navigateBack } from 'Helpers/navigation';
import { getAppRoutes } from 'Pages/App/App.constants';
import { UpdateCurrentUserRequest } from 'Services/Api/Auth/Types';
import { useEffect, useRef } from 'react';
import { getIsPending } from 'Stores/util';
import { useTranslation } from 'react-i18next';
import { getProfileSubmitValues } from './Profile.helpers';
import { checkApolloError } from 'Helpers/graphql';
import { APIErrorCodes } from 'app-types';

function Profile() {
	const { t } = useTranslation();
	const history = useHistory();
	const { authStore } = useStores();
	const isMountedRef = useRef(false);
	const [updateCurrentUser] = useMutation<void, UpdateCurrentUserRequest>(
		UPDATE_CURRENT_USER
	);

	const goBack = () => {
		navigateBack(history, getAppRoutes().HOME);
	};

	const onSubmit = async (
		values: EditProfileValues,
		helpers: FormikHelpers<EditProfileValues>
	) => {
		try {
			const input = getProfileSubmitValues(values);
			const response = await updateCurrentUser({
				variables: {
					input
				}
			});
			// actualize auth
			await authStore.getCurrentUser();
			// reset to initial for prompt not blocking transition
			helpers.resetForm({ values });
			goBack();

			Notification.success({
				description: t('notification.edit.success')
			});

			return response;
		} catch (e) {
			helpers.setSubmitting(false);
			const apolloError = checkApolloError(e);

			if (apolloError.is(APIErrorCodes.Forbidden)) {
				Notification.error({
					description: t(`errorCodes.${APIErrorCodes.Forbidden}`)
				});
			} else {
				Notification.error({
					description: t('errorCodes.genericErrorMessage')
				});
			}
		}
	};

	useEffect(() => {
		authStore.getCurrentUser();
		isMountedRef.current = true;
		// eslint-disable-next-line
	}, []);

	if (
		getIsPending(authStore.status.getCurrentUser) ||
		!isMountedRef.current
	) {
		return <Spin />;
	}

	const user = authStore.currentUser;
	const initialValues = user ? getInitialValues(user, true) : null;
	if (!user || !initialValues) {
		return null;
	}

	return (
		<EditUserForm
			userName={`${user.firstName} ${user.lastName}`}
			userRole={user.primarySystemRole.role}
			isProfile
			initialValues={initialValues}
			onSubmit={onSubmit}
			goBack={goBack}
			twoFactorAuthenticationStatus={user.twoFactorAuthorizationStatus}
		/>
	);
}

export default observer(Profile);
