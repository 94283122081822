import { useTranslation } from 'react-i18next';
import { Checkbox, Col, FormItem, Fx, Layout, Row, Select } from 'Components';
import RecurrenceOn from './RecurrenceOn';
import {
	REPORT_FIELD_LABEL,
	REPORT_FREQUENCIES
} from '../EditReport.constants';
import styles from '../EditReport.module.scss';
import { useStores } from 'Hooks/useStore';
import { useRecurrence } from './Recurrence.hooks';
import NotificationsDisabledError from 'Pages/User/Profile/tabs/NotificationsDisabledError';

function Recurrence() {
	const { t } = useTranslation();
	const { authStore } = useStores();

	const {
		values,
		handleIsRecurringChange,
		handleScheduleFrequencyTypeChange
	} = useRecurrence();

	const disabled = !authStore.currentUser?.timezone?.id;

	return (
		<Layout.List.ListItem
			wrapInBody={false}
			title={t('report.sections.recurrence')}
		>
			{disabled && <NotificationsDisabledError />}

			<Row>
				<Col xs={10}>
					<Checkbox.FormikField
						name="isRecurring"
						onChange={handleIsRecurringChange}
						disabled={disabled}
					>
						{t(REPORT_FIELD_LABEL.isRecurring)}
					</Checkbox.FormikField>
				</Col>
			</Row>

			{!disabled && values.isRecurring && (
				<>
					<Row>
						<Col xs={10}>
							<FormItem
								label={t(REPORT_FIELD_LABEL.scheduleFrequency)}
								fullWidth
								required
							>
								<Select
									value={values.scheduleFrequency?.type}
									onChange={handleScheduleFrequencyTypeChange}
								>
									{REPORT_FREQUENCIES.map((opt) => (
										<Select.Option
											key={opt.value}
											value={opt.value}
										>
											{t(opt.label)}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						</Col>
						<Col xs={11}>
							{values.scheduleFrequency && <RecurrenceOn />}
						</Col>
					</Row>

					<FormItem
						label={t(REPORT_FIELD_LABEL.emailsList)}
						required
						marginBottom={false}
					/>
					<Row>
						<Col xs={10}>
							<Select.FormikField
								name="emailsList"
								mode="tags"
								notFoundContent={null}
							/>
						</Col>
						<Col xs={14}>
							<Fx fullHeight>
								<Fx shrink={0} className={styles.separator}>
									{t('report.separators.or')}/
									{t('report.separators.and')}
								</Fx>
								<Checkbox.FormikField name="emailMeReport">
									{t(REPORT_FIELD_LABEL.emailMeReport)}
								</Checkbox.FormikField>
							</Fx>
						</Col>
					</Row>
				</>
			)}
		</Layout.List.ListItem>
	);
}

export default Recurrence;
