import { ColumnProps } from 'antd/lib/table';
import {
	useCreateColumns,
	ColumnFilters
} from 'Components/Table/createColumns';
import {
	DeletedGroup,
	GetDeletedGroupsRequest
} from 'Services/Api/Groups/Types';
import { GROUP_TABLE_COLUMN_PROPS } from 'Pages/Groups/Group/columns';
import { DateFormatted, OptionalValue, DaysBeforeRemoval } from 'Components';
import { useTranslation } from 'react-i18next';

type FilterValues = GetDeletedGroupsRequest['filters'];
export type TableFilterValues = ColumnFilters<FilterValues>;

const useColumns = <T extends DeletedGroup>({
	filterValues
}: {
	filterValues: FilterValues;
}) => {
	const { t } = useTranslation();
	const { search, filter, filters, sort } = useCreateColumns<T, FilterValues>(
		{
			filterValues
		}
	);

	const colProps = GROUP_TABLE_COLUMN_PROPS() as Record<
		keyof ReturnType<typeof GROUP_TABLE_COLUMN_PROPS>,
		any // eslint-disable-line
	>;

	const columns: Array<ColumnProps<T>> = [
		{
			...search,
			...sort,
			...colProps.name,
			render: (value, record) => (
				<>
					{value}
					<DaysBeforeRemoval days={record.daysBeforeRemoval} />
				</>
			)
		},
		{
			...filter,
			...sort,
			...colProps.isProperty,
			filteredValue: filters.isProperty
		},
		{
			...search,
			...sort,
			width: 180,
			title: t('group.tenant'),
			key: 'tenantName',
			dataIndex: 'tenantName',
			filteredValue: filters.tenantName,
			render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			...search,
			...sort,
			...colProps.syncCode,
			filteredValue: filters.syncCode
		},
		{
			...search,
			width: 250,
			title: t('deletedGroups.columns.hierarchy'),
			key: 'hierarchy',
			dataIndex: 'hierarchy',
			filteredValue: filters.hierarchy,
			render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			...filter,
			...sort,
			...colProps.active,
			filteredValue: filters.active
		},
		{
			...filter,
			...sort,
			...colProps.isArchived,
			filteredValue: filters.isArchived
		},
		{
			...sort,
			width: 160,
			title: t('deletedGroups.columns.deletedDate'),
			key: 'deletedDate',
			dataIndex: 'deletedDate',
			render: (value) => <DateFormatted date={value} />
		}
	];

	return columns;
};

export default useColumns;
