import { useTranslation } from 'react-i18next';
import { useModal } from 'Hooks/useModal';
import { useMoveUsersModal } from './MoveUsersModal.hooks';
import GroupModal from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupModal';
import { DataTreeContextProps } from 'Contexts/DataTree/DataTree.types';
import TInfoText from 'Contexts/TInfoText/TInfoText';

export type MoveUsersModalValues = Pick<
	DataTreeContextProps,
	'disabledValue' | 'initialValue'
> & {
	userIds: string[];
	userIdsName?: string;
	usersCognitoLogins: string;
};

export type MoveUsersModalProps = {
	onSubmit: () => void;
};
const MoveUsersModal = (props: MoveUsersModalProps) => {
	const { t } = useTranslation();
	const { modal } = useModal();
	const { modalValue, onSubmit } = useMoveUsersModal(props);
	if (!modal) return null;

	return (
		<GroupModal
			initialValue={{
				initialValue: modalValue.initialValue,
				disabledValue: modalValue.disabledValue
			}}
			title={
				<TInfoText
					path={`users.components.moveUserModal.modalTitle.${
						modalValue.userIdsName ? 'dynamic' : 'static'
					}`}
					params={{ name: modalValue.userIdsName }}
				/>
			}
			tooltip={t('users.components.moveUserModal.tooltip')}
			okText={t('users.components.moveUserModal.moveToGroup')}
			onSubmit={onSubmit}
		/>
	);
};

export default MoveUsersModal;
