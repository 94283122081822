import { SelectProps } from 'Components/Form/Select/Select';
import { useFormikContext } from 'formik';
import { EditUserValues } from '../User.types';

export const useModuleSettings = () => {
	const { values, setFieldValue } = useFormikContext<EditUserValues>();

	const onUserRoleChange: SelectProps['onChange'] = (value) => {
		setFieldValue('moduleSettings.userRoleId', value);
	};

	const onJobClassChange: SelectProps['onChange'] = (value) => {
		setFieldValue('moduleSettings.jobClassId', value);
	};

	const onDisabled = () => {
		if (values.moduleSettings) {
			setFieldValue('moduleSettings', undefined);
		}
	};

	return {
		onUserRoleChange,
		onJobClassChange,
		onDisabled
	};
};
