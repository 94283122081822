import { useMemo } from 'react';
import { Menu } from 'antd';
import { isNumber } from 'lodash';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';

import { useModal } from 'Hooks/useModal';
import { Button, Dropdown, Icon } from 'Components';
import { Group } from 'Services/Api/Groups/Types';
import { getAppRoutes } from 'Pages/App/App.constants';
import { GroupModalsName } from '../modals';
import { usePermission } from 'Components/Permission/Permission.hooks';
import {
	GroupMoreButtonMenuItemKey,
	GroupMoreButtonMenuItem
} from './GroupMoreButton.types';
import {
	MENU_ITEMS,
	MENU_ITEMS_GROUP,
	MENU_ITEMS_TENANT
} from './GroupMoreButton.constants';
import { GetUsersRequest } from 'Services/Api/Users/Types';
import { qsStringify, toPath } from 'Helpers/navigation';
import { GroupTreeInitialValue } from '../modals/MoveGroupModal/GroupModal/GroupTree';
import { useTranslation } from 'react-i18next';

interface GroupMoreButtonProps {
	group: Group;
	disabled?: boolean;
}

const GroupMoreButton = (props: GroupMoreButtonProps) => {
	const { t } = useTranslation();
	const { getHasPermission } = usePermission();
	const { showModal } = useModal();
	const history = useHistory();

	const onMenuClick = (info: { key: React.Key }) => {
		const menuKey = info.key as GroupMoreButtonMenuItemKey;
		switch (menuKey) {
			case GroupMoreButtonMenuItemKey.Move:
				const value: GroupTreeInitialValue = {
					disabledValue: {
						key: props.group.id,
						title: props.group.name
					},
					initialValue: isNumber(props.group.parentId)
						? [{ key: props.group.parentId }]
						: undefined
				};
				showModal({
					name: GroupModalsName.Move,
					value
				});
				break;
			case GroupMoreButtonMenuItemKey.Delete:
				showModal({
					name: GroupModalsName.Delete,
					value: props.group
				});
				break;
			case GroupMoreButtonMenuItemKey.Edit:
				history.push(
					generatePath(
						!props.group.parentId
							? getAppRoutes().EDIT_TENANT
							: getAppRoutes().EDIT_GROUP,
						{
							groupId: props.group.id
						}
					)
				);
				break;
			case GroupMoreButtonMenuItemKey.ResetPassword:
				showModal({
					name: GroupModalsName.ResetPassword,
					value: props.group
				});

				break;
			case GroupMoreButtonMenuItemKey.Users:
				const usersFilters: GetUsersRequest['filters'] = {
					groupIds: [String(props.group.id)]
				};
				const path = toPath({
					path: getAppRoutes().USERS,
					query: qsStringify(usersFilters)
				});
				history.push(path);
				break;
		}
	};

	const isTenant = props.group.level === 1;

	const menuItems = useMemo(() => {
		return MENU_ITEMS.reduce<GroupMoreButtonMenuItem[]>((acc, value) => {
			let menuItem = value;
			if (value.key) {
				menuItem = {
					...menuItem,
					...(isTenant
						? MENU_ITEMS_TENANT[value.key]
						: MENU_ITEMS_GROUP[value.key])
				};
			}

			const hasPermission =
				!menuItem.permission ||
				getHasPermission(menuItem.permission).permitted;
			if (hasPermission && menuItem.visible !== false) {
				acc.push(menuItem);
			}
			return acc;
		}, []);
	}, [getHasPermission, isTenant]);

	const disabled = !menuItems.length || props.disabled;

	return (
		<Dropdown
			overlay={
				<Menu onClick={onMenuClick}>
					{menuItems.map((menuItem) => (
						<Menu.Item key={menuItem.key} icon={menuItem.icon}>
							{t(menuItem.title)}
						</Menu.Item>
					))}
				</Menu>
			}
			disabled={disabled}
			arrow={false}
		>
			<Button
				shape="round"
				ghost
				type="primary"
				size="small"
				thinVertical
				disabled={disabled}
			>
				<Icon type="more" />
			</Button>
		</Dropdown>
	);
};

export default GroupMoreButton;
