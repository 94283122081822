import { APP_CONFIG } from 'app-config';
import { ErrorInfo, TInfo } from 'app-types';
import { isI18nPathPresent } from 'i18n/i18n.helpers';
import { TFunction } from 'i18next';
import { isArray, isFunction, mapValues, isEmpty } from 'lodash';
import { SchemaDescription, SchemaFieldDescription } from 'yup/lib/schema';

export const getNormalizedErrorInfo = (
	info: ErrorInfo,
	meta: Partial<SchemaDescription> = {},
	t: TFunction,
	translationPath?: string
) => {
	const metadata = mapValues(meta, (value) =>
		isFunction(value) ? value() : value
	);

	const metaParams = isEmpty(metadata.meta) ? {} : metadata.meta;
	const options = mapValues(
		{
			...metadata,
			...info.meta,
			...metaParams
		},
		(value) => (isArray(value) ? value.join(', ') : value)
	);

	const pathList = (translationPath ? [translationPath] : []).concat(
		APP_CONFIG.I18N_ERROR_KEY
	);
	const message = pathList.reduce<TInfo | undefined>((acc, path) => {
		const key = `${path}.` + info.errorCode;
		if (isI18nPathPresent(key, t) && !acc) {
			acc = {
				key,
				params: {
					...options,
					path: options.label
				}
			};
		}
		return acc;
	}, undefined);

	return {
		...info,
		message
	};
};

export const getFieldErrors = (
	errorInfo: ErrorInfo[],
	meta: Record<string, SchemaFieldDescription>,
	t: TFunction,
	translationPath?: string
) => {
	if (!errorInfo) {
		return {};
	}
	return errorInfo.reduce<Record<string, TInfo[]>>((acc, value) => {
		if (!value.fieldName) {
			return acc;
		}

		const infoValue = getNormalizedErrorInfo(
			value,
			meta[value.fieldName],
			t,
			translationPath
		);
		acc[value.fieldName] = acc[value.fieldName] || [];
		if (infoValue.message) {
			acc[value.fieldName].push(infoValue.message);
		}

		return acc;
	}, {});
};
