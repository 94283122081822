import { PermissionGroupName } from 'Services/Api/Roles/Types';

export const PERMISSION_GROUP_HEADING_BY_NAME = {
	[PermissionGroupName.GroupsManagement]:
		'role.editRole.editRolePermissionGroups.groupsManagement',
	[PermissionGroupName.OwnershipGroupsManagement]:
		'role.editRole.editRolePermissionGroups.ownershipGroupsManagement',
	[PermissionGroupName.SystemRolesManagement]:
		'role.editRole.editRolePermissionGroups.systemRolesManagement',
	[PermissionGroupName.UsersManagement]:
		'role.editRole.editRolePermissionGroups.usersManagement',
	[PermissionGroupName.DashboardManagement]:
		'role.editRole.editRolePermissionGroups.dashboardManagement',
	[PermissionGroupName.ReportsManagement]:
		'role.editRole.editRolePermissionGroups.reportsManagement',
	[PermissionGroupName.ForceSyncManagement]:
		'role.editRole.editRolePermissionGroups.forceSyncManagement',
	[PermissionGroupName.SystemsManagement]:
		'role.editRole.editRolePermissionGroups.systemsManagement'
};
