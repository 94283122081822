import { SystemRoleType } from 'Services/Api/Roles/Types';
import { useStores } from 'Hooks/useStore';

export default function useSendAuthenticationLog() {
	const { authStore } = useStores();
	const DEFAULT_GROUP_ID = 'grc_root';
	const DEFAULT_GROUP_NAME = 'GRC Root';
	function sendUserLoggedInAction() {
		if (
			process.env.NODE_ENV === 'development' ||
			!process.env.REACT_APP_GAINSIGHT_TAG
		)
			return;
		const isSuperAdmin =
			authStore.currentUser?.primarySystemRole?.role ===
			SystemRoleType.SuperAdmin;
		const group = isSuperAdmin
			? { name: DEFAULT_GROUP_NAME, id: DEFAULT_GROUP_ID }
			: authStore.currentUser?.groups[0];
		// @ts-ignore
		window.aptrinsic(
			'identify',
			{
				//User Fields
				id: authStore.currentUser?.peakV3Id, // Required for logged in app users
				//unix time in ms
				plan: 'gold'
			},
			{
				id: group?.id,
				name: group?.name,
				systemId: authStore.currentUser?.userPool.systemId ?? null,
				tenantId: authStore.currentUser?.userPool?.zeeNumber ?? null
			}
		);
	}
	return {
		sendUserLoggedInAction
	};
}
