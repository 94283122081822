import { useTranslation } from 'react-i18next';
import { PropsWithChildren } from 'react';

import { Fx, Layout, Space } from 'Components';
import GroupMoreButton from './GroupMoreButton/GroupMoreButton';
import ModalContextProvider from 'Hooks/useModal';
import GroupModals from './GroupModals';
import styles from './Group.module.scss';
import { DataSourceItem } from './Group.types';
import GroupTemplateTable, {
	GroupTemplateTableProps
} from './GroupTemplateTable';

type GroupTemplateProps<T> = GroupTemplateTableProps<T> & {
	onSubmit?: () => void;
	defaultGroupName: string;
};

function GroupTemplate<D extends DataSourceItem>(
	props: PropsWithChildren<GroupTemplateProps<D>>
) {
	const { children, onSubmit, group, defaultGroupName, isManage } = props;
	const { t } = useTranslation();

	return (
		<ModalContextProvider>
			<Layout>
				{typeof onSubmit === 'function' && (
					<GroupModals onSubmit={onSubmit} />
				)}
				<Layout.Header aligned="right">
					<Fx grow={1}>
						<Space align="center" size="middle">
							<h1>
								{group ? (
									<>
										{group.name}
										<span className={styles.levelTitle}>
											{t(
												`groups.groupDetails.title.${
													group.level === 1
														? 'tenant'
														: 'group'
												}`,
												{ groupLevel: group.level }
											)}
										</span>
									</>
								) : (
									defaultGroupName
								)}
							</h1>
							{group && isManage && (
								<GroupMoreButton group={group} />
							)}
						</Space>
					</Fx>
					{children}
				</Layout.Header>

				<GroupTemplateTable {...props} />
			</Layout>
		</ModalContextProvider>
	);
}
export default GroupTemplate;
