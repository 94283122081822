import * as XLSX from 'xlsx';
import { UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Notification } from 'Components';
import { useFormContext } from 'react-hook-form';
import { Value } from 'Pages/GroupsHierarchy/GroupsHierarchy.types';
import { isGroupsHierarchyLimitReached } from '../../../GroupsHierarchy.helpers';
import { useGroupsHierarchyContext } from '../../../GroupsHierarchyContext';
import { useState } from 'react';
import { RcFile } from 'antd/lib/upload';

const TEMPLATE_CONFIG = {
	COLUMN: 'Group name',
	SHEET: 'Group names'
};

export interface UploadGroupsButtonProps {
	level: number;
}

export const useUploadGroupsButton = (props: UploadGroupsButtonProps) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { setValue, getValues } = useFormContext();
	const {
		groupsHierarchySizeLimit,
		amountOfGroupsInTenant
	} = useGroupsHierarchyContext();
	const values = getValues();

	const fieldKey = `level${props.level}`;

	const onloadError = (message?: string) => {
		throw new Error(message ?? t('notification.uploadFile.error'));
	};

	const readFile = (file: RcFile) => {
		const reader = new FileReader();

		reader.onloadend = () => {
			setLoading(false);
		};
		reader.onload = (e) => {
			try {
				const wb = XLSX.read(e.target?.result, { type: 'array' });

				const sheetName = wb.SheetNames?.[0];

				// 1. validate on number of sheets
				// 2. sheet has to have name according to the original template
				if (
					wb.SheetNames.length !== 1 ||
					sheetName !== TEMPLATE_CONFIG.SHEET
				) {
					onloadError();
				}

				const sheet = wb.Sheets[sheetName];
				const [columns, ...rows] = XLSX.utils.sheet_to_json(sheet, {
					header: 1
				});

				// template should contain 1 column which is named according to the original template
				if (
					columns.length !== 1 ||
					columns[0] !== TEMPLATE_CONFIG.COLUMN
				) {
					onloadError();
				}

				if (rows.length === 0) {
					onloadError();
				}

				const isLimitReached = isGroupsHierarchyLimitReached(
					{
						...values,
						[fieldKey]: {
							...values[fieldKey],
							// data will be overwritten in case it is valid
							groupsNames: []
						}
					},
					groupsHierarchySizeLimit,
					amountOfGroupsInTenant,
					fieldKey,
					rows.length
				);

				if (isLimitReached) {
					onloadError(t('groupsHierarchy.uploadGroupsLimit'));
				}

				const value: Value['groupsNames'] = rows.map((row) => ({
					name: row[0] ?? ''
				}));
				setValue(`level${props.level}.groupsNames`, value, {
					shouldValidate: true
				});

				Notification.success({
					description: t('notification.uploadFile.success')
				});
			} catch (e) {
				Notification.error({
					description: t('errorCodes.genericErrorMessage')
				});
			}
		};
		reader.readAsArrayBuffer(file);
	};

	const beforeUpload: UploadProps['beforeUpload'] = (file) => {
		setLoading(true);

		// wait till state is updated with loading flag
		setTimeout(() => {
			readFile(file);
		}, 400);

		// prevent upload
		return false;
	};

	return {
		loading,
		beforeUpload
	};
};
