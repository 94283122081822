import { Dropdown as AntDropdown, DropDownProps } from 'antd';
import cn from 'classnames';
import { Icon } from 'Components';
import styles from './Dropdown.module.scss';

const Dropdown: React.FC<DropDownProps> = (props) => {
	return (
		<AntDropdown
			trigger={['click']}
			placement="bottomRight"
			{...props}
			className={cn(props.className, styles.root)}
			overlayClassName={cn(props.overlayClassName, styles.menu)}
		>
			<div>
				<div>{props.children}</div>
				{props.arrow !== false && <Icon type="chevronDown" />}
			</div>
		</AntDropdown>
	);
};

export default Dropdown;
