import { useTranslation } from 'react-i18next';
import { useModal } from 'Hooks/useModal';
import GroupModal from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupModal';
import { DataTreeNodeItem } from 'Contexts/DataTree/DataTree.types';
import { Group } from 'Services/Api/Groups/Types';
import { GroupTreeInitialValue } from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupTree';

export type GroupFieldModalValues = GroupTreeInitialValue;
export type MoveGroupModalProps = GroupFieldModalValues & {
	onSubmit: (value: DataTreeNodeItem<Group>[] | undefined) => void;
};
function GroupFieldModal(props: MoveGroupModalProps) {
	const { t } = useTranslation();

	const { modal } = useModal();

	if (!modal) return null;

	return (
		<GroupModal
			initialValue={{
				initialValue: props.initialValue,
				disabledValue: props.disabledValue
			}}
			title={t(
				'group.addGroup.components.groupFieldModal.editParentGroup'
			)}
			tooltip={t(
				'group.addGroup.components.groupFieldModal.tooltipTitle'
			)}
			onSubmit={props.onSubmit}
			hideOnSubmit
		/>
	);
}

export default GroupFieldModal;
