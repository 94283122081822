import { Modal as AntModal, ModalProps as AntModalProps } from 'antd';
import { Icon, Space } from 'Components';
import Button from 'Components/Button/Button';
import styles from './Modal.module.scss';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const WIDTH_BY_SIZE = {
	xs: 425,
	sm: 642
};

export type ModalProps = AntModalProps & {
	size?: 'xs' | 'sm';
	cancelButtonVisible?: boolean;
	okButton?: React.ReactNode;
};
const Modal: React.FC<ModalProps> = ({
	size = 'xs',
	cancelButtonVisible = true,
	...props
}) => {
	const { t } = useTranslation();
	const footer =
		props.footer === null
			? null
			: props.footer || (
					<Space>
						{cancelButtonVisible && (
							<Button
								ghost
								shape="round"
								type="default"
								onClick={props.onCancel}
								{...props.cancelButtonProps}
							>
								{props.cancelText ?? t('cancel')}
							</Button>
						)}
						{props.okButton ?? (
							<Button
								shape="round"
								type="primary"
								onClick={props.onOk}
								{...props.okButtonProps}
							>
								{props.okText ?? t('submit')}
							</Button>
						)}
					</Space>
			  );
	return (
		<AntModal
			{...props}
			className={cn(styles.root, props.className)}
			closeIcon={<Icon type="close" />}
			width={(size && WIDTH_BY_SIZE[size]) ?? props.width}
			footer={footer}
		/>
	);
};

export default Modal;
