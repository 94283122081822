import { useTranslation } from 'react-i18next';
import { Checkbox, Fx } from 'Components';
import styles from '../Dashboard.module.scss';
import {
	useDashboardFilters,
	DashboardFiltersProps
} from './DashboardFilters.hooks';
import DashboardGroupFilter from './filters/DashboardGroupFilter';

function DashboardFilters(props: DashboardFiltersProps) {
	const { t } = useTranslation();

	const {
		submitting,
		isPropertyCheckboxVisible,
		isPropertiesOnly,
		onGroupsChange,
		onPropertyChange
	} = useDashboardFilters(props);

	return (
		<Fx className={styles.filters} align="middle">
			<DashboardGroupFilter
				label={t('dashboard.filters.groups.label')}
				horizontal
				onChange={onGroupsChange}
				submitting={submitting}
				propertiesOnly={isPropertiesOnly}
			/>

			<Fx className={styles.suffixField}>
				{isPropertyCheckboxVisible && (
					<Checkbox disabled={submitting} onChange={onPropertyChange}>
						{t('dashboard.filters.properties.label')}
					</Checkbox>
				)}
			</Fx>
		</Fx>
	);
}

export default DashboardFilters;
