import { APP_CONFIG } from 'app-config';
import { UpdateCurrentUserRequest } from 'Services/Api/Auth/Types';
import { EditProfileValues } from '../User.types';

export const getProfileSubmitValues = (values: EditProfileValues) => {
	const params: UpdateCurrentUserRequest['input'] = {
		email: values.email,
		firstName: values.firstName,
		lastName: values.lastName,
		phoneNumber: !values.phoneNumber ? null : values.phoneNumber,
		languageId: values.languageId,
		timezoneId: values.timezoneId,
		doNotUpdateOnAutoFeeds: values.doNotUpdateOnAutoFeeds
	};

	const { notifications } = values;
	// Notifications are being checked if they has to be included in submit or not by referring to the required fields
	// Considering that the the form doesnt have a "trigger" to identify when notifications has to be required or not
	if (
		values.timezoneId &&
		notifications &&
		notifications.day &&
		notifications.time
	) {
		params.notifications = {
			day: notifications.day,
			time: notifications.time.format(APP_CONFIG.TIME_FORMAT),
			getWeeklyReport: notifications.getWeeklyReport ?? false,
			getDeletionReport: notifications.getDeletionReport ?? false
		};
	}

	return params;
};
