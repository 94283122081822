import { PropsWithChildren } from 'react';
import { Fx, Layout } from 'Components';
import { DataTreeContextProvider } from 'Contexts/DataTree/DataTreeContext';
import { DataTreeContextProps } from 'Contexts/DataTree/DataTree.types';
import DataTree from 'Contexts/DataTree/DataTree';
import { ListProps } from 'Contexts/DataTree/components/List/List';
import { groupToTreeNode } from './Groups.helpers';
import styles from './GroupsLayout.module.scss';
import { DataTreeLayoutProps } from 'Contexts/DataTree/components/DataTreeLayout/DataTreeLayout';
import { isFunction } from 'lodash';
import GroupTreeLegend from './Group/modals/MoveGroupModal/GroupModal/GroupTreeLegend';

type GroupsLayoutTemplateProps<T> = Omit<DataTreeContextProps<T>, 'toNode'> &
	Pick<DataTreeLayoutProps, 'header'> &
	Pick<ListProps, 'disabled'>;

function GroupsLayoutTemplate<T>({
	disabled,
	children,
	header,
	...props
}: PropsWithChildren<GroupsLayoutTemplateProps<T>>) {
	return (
		<DataTreeContextProvider<T>
			defaultActiveFirstOption
			// eslint-disable-next-line
			toNode={groupToTreeNode as any}
			{...props}
		>
			{(context) => (
				<Layout column={false} className={styles.root}>
					<DataTree
						layout="sidebar"
						className={styles.sidebar}
						header={(props, helpers) => (
							<Layout.Header aligned="left">
								<Fx column fullWidth>
									{header(props, helpers)}
								</Fx>
							</Layout.Header>
						)}
						footer={<GroupTreeLegend exclude={['deleted']} />}
					>
						<DataTree.List disabled={disabled} />
					</DataTree>

					{isFunction(children) ? children(context) : children}
				</Layout>
			)}
		</DataTreeContextProvider>
	);
}

export default GroupsLayoutTemplate;
