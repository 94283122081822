import { v4 as uuid } from 'uuid';
import { TFunction } from 'i18next';
import { Entity } from 'app-types';
import { roleToOption } from 'Pages/User/EditUser/EditUser.helpers';
import { SystemRoleType, SystemRolesListItem } from 'Services/Api/Roles/Types';
import { AddUser, AddUsersFormValues } from './AddUsers.types';
import { AddUserValues, EditUserValues } from 'Pages/User/User.types';
import { ValidatedUser, AddUserRequestInput } from 'Services/Api/Users/Types';
import {
	ModuleSettingsValidationError,
	ModuleSettingsValidationErrorDataItem,
	getErrorFieldName
} from 'Pages/User/EditUser/tabs/ModuleSettings/ModuleSettings.helpers';
import { LanguageI18nKey } from 'Services/Api/Common/Types';
import { ID_BY_LANGUAGE } from 'i18n/i18n.constants';

export const createNewUser = (
	values: Pick<AddUsersFormValues, 'data'>
): AddUser => {
	const defaultPrimaryRole = values.data.systemRoles?.find(
		(systemRole) => systemRole.role === SystemRoleType.User
	);
	return {
		firstName: '',
		lastName: '',
		cognitoLogin: '',
		primarySystemRoleId: defaultPrimaryRole
			? roleToOption(defaultPrimaryRole)
			: undefined,
		email: '',
		key: '0',
		fieldId: uuid(),
		groups: [],
		moduleSettings: undefined,
		languageId: undefined,
		phoneNumber: undefined,
		timezoneId: undefined
	};
};

export const createUserGroups = (
	groupEntity: Entity
): AddUserValues['groups'] => {
	return [
		{
			// @ts-ignore
			data: groupEntity,
			key: groupEntity.id,
			value: groupEntity.id,
			label: groupEntity.name
		}
	];
};

export function mapValidUserToAddUser(
	user: ValidatedUser,
	systemRolesList?: SystemRolesListItem[]
): AddUser {
	const {
		login,
		phone,
		language,
		timezone,
		systemRole,
		group,
		jobClass,
		userRole,
		...rest
	} = user;

	const definedSystemRole =
		systemRole ??
		systemRolesList?.find(
			(systemRole) => systemRole.role === SystemRoleType.User
		);

	const primarySystemRoleId = definedSystemRole
		? roleToOption(definedSystemRole, 'role')
		: undefined;

	return {
		...rest,
		key: '0',
		cognitoLogin: login,
		phoneNumber: phone || undefined,
		languageId: language?.id
			? Number(ID_BY_LANGUAGE[LanguageI18nKey.English])
			: undefined,
		timezoneId: timezone?.id,
		primarySystemRoleId,
		groups: group ? createUserGroups(group) : undefined,
		fieldId: uuid(),
		moduleSettings: {
			jobClassId: jobClass?.peakV3Id ?? undefined,
			userRoleId: userRole?.id ?? undefined
		}
	};
}
export function getUserValues(
	groups: AddUser['groups'],
	primarySystemRoleId: AddUser['primarySystemRoleId'],
	moduleSettings: AddUser['moduleSettings']
) {
	return {
		groups: groups || [],
		primarySystemRoleId: primarySystemRoleId ? [primarySystemRoleId] : [],
		moduleSettings
	};
}

export function createInput(
	users: AddUser[],
	getIsLMSModuleSettingsDisabled: (
		values: Pick<EditUserValues, 'primarySystemRoleId' | 'groups'>
	) => boolean,
	requestId: string | null
) {
	const fieldsWithLMSError: ModuleSettingsValidationErrorDataItem[] = [];

	const inputUsers = users.reduce<AddUserRequestInput[]>(
		(
			acc,
			{
				firstName,
				lastName,
				languageId,
				timezoneId,
				phoneNumber,
				primarySystemRoleId,
				groups,
				moduleSettings,
				cognitoLogin,
				email
			},
			i
		) => {
			const userValues = getUserValues(
				groups,
				primarySystemRoleId,
				moduleSettings
			);
			const isLMSModuleSettingsDisabled = getIsLMSModuleSettingsDisabled(
				userValues
			);
			if (
				!isLMSModuleSettingsDisabled &&
				(!moduleSettings?.jobClassId || !moduleSettings?.userRoleId)
			) {
				const lmsFieldName = getErrorFieldName({
					moduleSettings
				});
				fieldsWithLMSError.push({
					lmsFieldName,
					itemNumber: i
				});
				return acc;
			}
			const inputUser = {
				firstName,
				lastName,
				languageId,
				timezoneId,
				phoneNumber: phoneNumber || undefined,
				cognitoLogin,
				email,
				// validation rules shouldn't lead to role being not defined
				primarySystemRoleId: primarySystemRoleId?.data.id as number,
				groups: groups?.map((group) => group.key),
				moduleSettings:
					moduleSettings?.jobClassId ?? moduleSettings?.userRoleId
						? moduleSettings
						: undefined
			};
			acc.push(inputUser);
			return acc;
		},
		[]
	);

	if (fieldsWithLMSError.length) {
		throw new ModuleSettingsValidationError('error', fieldsWithLMSError);
	}

	return {
		users: inputUsers,
		requestId: requestId ?? undefined
	};
}

export function getCreateMultipleUsersErrorDescription(t: TFunction) {
	const errorCodeCollection = new Set<string>();
	return {
		combineErrorCode: (errorCode: string) => {
			errorCodeCollection.add(errorCode);
		},
		getErrorDescription: (params: Record<string, number>): string => {
			return Array.from(errorCodeCollection)
				.map((errorCode) => t(`user.errorCodes.${errorCode}`, params))
				.join('.\n');
		}
	};
}
