import { Tag as AntTag, TagProps as AntTagProps } from 'antd';
import cn from 'classnames';
import styles from './Tag.module.scss';

export type TagTheme = 'success' | 'default' | 'error' | 'warning' | 'primary';
export type TagProps = AntTagProps & {
	type?: TagTheme;
	ghost?: boolean;
};
const Tag: React.FC<TagProps> = ({ ghost, ...props }) => {
	return (
		<AntTag
			{...props}
			className={cn(props.className, styles.root, {
				[styles.success]: props.type === 'success',
				[styles.default]: props.type === 'default',
				[styles.error]: props.type === 'error',
				[styles.warning]: props.type === 'warning',
				[styles.primary]: props.type === 'primary',
				[styles.ghost]: ghost
			})}
		/>
	);
};
export default Tag;
