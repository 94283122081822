import React from 'react';
import EditReportHeading from '../EditReport/EditReportHeading';
import { PreviewReportProps } from './PreviewReport.types';
import PreviewReportTable from './PreviewReportTable/PreviewReportTable';

type PreviewReportInterface = React.ComponentType<PreviewReportProps> & {
	Title: typeof PreviewReportTitle;
};

const PreviewReport: PreviewReportInterface = ({ data }) => {
	return <PreviewReportTable data={data} />;
};

const PreviewReportTitle = ({ data }: PreviewReportProps) => {
	const period = [
		data.period.from,
		...(data.period.to ? [data.period.to] : [])
	];
	return (
		<>
			<EditReportHeading name={data.name} reportType={data.reportType} />
			<br />
			{period.join(' - ')}
		</>
	);
};

PreviewReport.Title = PreviewReportTitle;

export default PreviewReport;
