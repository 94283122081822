import { useMutation } from '@apollo/client';
import { Modal, Notification } from 'Components';
import { useTranslation } from 'react-i18next';
import { useModal } from 'Hooks/useModal';
import { RESTORE_USERS } from 'Services/Api/Users/Mutations';
import { checkApolloError } from 'Helpers/graphql';
import { APIErrorCodes } from 'app-types';
import TInfoText from 'Contexts/TInfoText/TInfoText';

interface RestoreUsersModalProps {
	isMultiple: boolean;
	userFullName: string;
	onSubmit: () => void;
}

const TRANSLATION_PATH = 'users.components.restoreUsersModal';

const RestoreUsersModal = (props: RestoreUsersModalProps) => {
	const { t } = useTranslation();
	const [restoreUsers, { loading }] = useMutation(RESTORE_USERS);
	const { hideModal, modal } = useModal();
	const messagePath = props.isMultiple ? 'multiple' : 'single';
	const onSubmit = async () => {
		try {
			const ids = modal?.value.userIds ?? [];
			await restoreUsers({
				variables: {
					input: {
						ids
					}
				}
			});

			Notification.success({
				description: t(
					`${TRANSLATION_PATH}.notification.success.${messagePath}`
				)
			});

			props.onSubmit();
		} catch (error) {
			const apolloError = checkApolloError(error as Error);

			let description = t('errorCodes.genericErrorMessage');
			if (apolloError.is(APIErrorCodes.RestoreUsersWithInvalidGroup)) {
				const { groups, users } = apolloError.getMeta<{
					groups: string[];
					users: string[];
				}>();
				const baseDescriptionPath = `errorCodes.${APIErrorCodes.RestoreUsersWithInvalidGroup}`;
				if (groups.length) {
					description = t(`${baseDescriptionPath}.groups`, {
						groups: groups.map((group) => `"${group}"`).join(', ')
					});
				} else if (users.length) {
					description = t(`${baseDescriptionPath}.users`, { users });
				}
			} else if (apolloError.is(APIErrorCodes.Forbidden)) {
				const meta = apolloError.getMeta<string[]>();
				description = t(`${TRANSLATION_PATH}.notification.error`, {
					users: Array.isArray(meta)
						? meta.map((user) => `"${user}"`)
						: ''
				});
			}
			Notification.error({
				description
			});
		} finally {
			hideModal();
		}
	};
	return (
		<Modal
			visible
			title={
				<TInfoText
					path={`${TRANSLATION_PATH}.title.${messagePath}`}
					params={{
						name: props.userFullName
					}}
				/>
			}
			onCancel={hideModal}
			onOk={onSubmit}
			okText={t(`${TRANSLATION_PATH}.okText`)}
			okButtonProps={{
				disabled: loading,
				loading
			}}
		>
			{t(`${TRANSLATION_PATH}.description.${messagePath}`)}
		</Modal>
	);
};

export default RestoreUsersModal;
