import {
	action,
	makeObservable,
	observable,
	runInAction,
	reaction
} from 'mobx';
import moment from 'moment';
import i18n from '../i18n/index';
import { authStore } from './AuthStore';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import { axiosClient } from 'Services/Api/client';
import {
	GetTranslationRequest,
	GetTranslationResponse,
	LanguageI18nKey
} from 'Services/Api/Common/Types';
import COMMON_API from 'Services/Api/Common/Api';
import { isArray } from 'lodash';
import { LANGUAGE_BY_ID } from 'i18n/i18n.constants';
import { Status } from 'app-types';

export const I18N_DEFAULT_LANGUAGE =
	Object.values(LanguageI18nKey).find((language) =>
		Boolean(i18n.language && i18n.language.startsWith(language))
	) ?? LanguageI18nKey.English;

const MOMENT_LOCALE = {
	[LanguageI18nKey.English]: 'en-gb',
	[LanguageI18nKey.French]: 'fr',
	[LanguageI18nKey.Spanish]: 'es'
};

class LanguageStore {
	public language: LanguageI18nKey = I18N_DEFAULT_LANGUAGE;
	status = {
		changeLanguage: Status.Idle
	};
	isDefaultResourceLoaded = false;

	constructor() {
		makeObservable(this, {
			language: observable,
			status: observable,
			isDefaultResourceLoaded: observable
		});
		this.changeLanguage(this.language);

		reaction(
			() => this.status.changeLanguage,
			() => {
				if (!this.isDefaultResourceLoaded) {
					this.isDefaultResourceLoaded = Boolean(
						i18n.options.resources?.[I18N_DEFAULT_LANGUAGE]
					);
				}
			}
		);

		reaction(
			() => authStore.currentUser?.language?.id,
			(id) => {
				const language = id
					? LANGUAGE_BY_ID[id]
					: I18N_DEFAULT_LANGUAGE;
				languageStore.changeLanguage(language);
				moment.locale(MOMENT_LOCALE[language]);
			}
		);
	}

	@action
	async changeLanguage(language: LanguageI18nKey) {
		runInAction(() => {
			this.status.changeLanguage = Status.Pending;
		});
		try {
			await this.loadLanguage(language);
			await i18n.changeLanguage(language);
			runInAction(() => {
				this.language = language;
				this.status.changeLanguage = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.changeLanguage = Status.Failure;
			});
		}
	}

	@action
	async loadLanguage(language: LanguageI18nKey) {
		if (i18n.options.resources?.[language]) return;

		try {
			const params: GetTranslationRequest = {
				code: language
			};
			const response = await axiosClient.get<GetTranslationResponse>(
				COMMON_API.TRANSLATION,
				{
					params
				}
			);

			const ns = isArray(i18n.options.defaultNS)
				? i18n.options.defaultNS[0]
				: i18n.options.defaultNS;
			i18n.addResourceBundle(language, ns, response.data, true, true);
		} catch (e) {
			throw e;
		}
	}
}

export const languageStore = new LanguageStore();

export default LanguageStore;
