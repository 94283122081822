import {
	Card,
	Col,
	Divider,
	FormItem,
	Fx,
	Row,
	Select,
	OptionalValue
} from 'Components';
import { inPercent } from 'Helpers/numeric';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import {
	ControlSpace,
	DashboardProgress,
	InfoTooltip,
	OpenInNewTab,
	TrendIndicator
} from 'Pages/Dashboard/components';
import { useTranslation } from 'react-i18next';
import styles from './SpotCheck.module.scss';
import SpotCheckLineProgress from './SpotCheckLineProgress';
import {
	ShowFilter,
	ShowFilterValue,
	SpotCheckWidgets
} from './SpotCheck.types';
import { getIsPending } from 'Stores/util';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';

const PATH = 'dashboard.tabs.spotCheck.spotCheckOverview';

const SPOT_CHECK_SELECT: ShowFilter[] = [
	{
		value: 'spotCheck:current',
		label: 'dashboard.timePeriod.current'
	},
	{
		value: 'spotCheck:last',
		label: 'dashboard.timePeriod.previous'
	}
];

function SpotCheckOverview() {
	const { t } = useTranslation();
	const { dashboardStore } = useStores();
	const { spotCheck } = dashboardStore;

	const handleChangeSelect = (value: ShowFilterValue) => {
		dashboardStore.getSpotCheckOverview({ sort: value });
	};

	if (!spotCheck.overview.data) {
		return null;
	}
	const selectValue = spotCheck.overview.params.sort;
	return (
		<Card
			title={t(`${PATH}.title`)}
			loading={getIsPending(dashboardStore.status.getSpotCheckOverview)}
			extra={
				<ControlSpace>
					<FormItem
						label={t(`${PATH}.show`)}
						className={styles.filter}
						horizontal
					>
						<Select
							getPopupContainer={() => document.body}
							onChange={handleChangeSelect}
							value={selectValue}
						>
							{SPOT_CHECK_SELECT.map(({ value, label }) => (
								<Select.Option key={value} value={value}>
									{t(label)}
								</Select.Option>
							))}
						</Select>
					</FormItem>
					<InfoTooltip title={t(`${PATH}.tooltip`)} />
				</ControlSpace>
			}
			footer={
				<OpenInNewTab<ShowFilterValue>
					tab={DashboardTabKey.SpotCheck}
					widget={SpotCheckWidgets.SpotCHeckOverview}
					widgetParams={selectValue}
				/>
			}
		>
			<Row fullHeight>
				<Col span={10}>
					<Fx align="middle" justify="center" fullHeight>
						<DashboardProgress
							percent={spotCheck.overview.data.ICIScore}
						/>
						<div className={styles.infosecCompositeIndex}>
							<h3>{t(`${PATH}.infosecCompositeIndex`)}</h3>

							<TrendIndicator
								direction={
									spotCheck.overview.data.trendDirection
								}
							>
								<OptionalValue>
									{inPercent(
										spotCheck.overview.data.changePercent
									)}
								</OptionalValue>
								&nbsp;
								{t(
									`${PATH}.${
										selectValue === 'spotCheck:current'
											? 'sinceLastSpotCheck'
											: 'varianceFromCurrent'
									}`
								)}
							</TrendIndicator>
						</div>
					</Fx>
				</Col>
				<Col span={1}>
					<Fx align="stretch" justify="center" fullHeight>
						<Divider type="vertical" />
					</Fx>
				</Col>
				<Col span={13}>
					<Fx align="middle" justify="center" fullHeight>
						<div className={styles.graph}>
							{t(`${PATH}.scores.title`)}
							<SpotCheckLineProgress
								percent={spotCheck.overview.data.tar}
								title={t(`${PATH}.scores.tar.title`)}
								tooltip={t(`${PATH}.scores.tar.tooltip`)}
							/>
							<SpotCheckLineProgress
								percent={spotCheck.overview.data.pas}
								title={t(`${PATH}.scores.pas.title`)}
								tooltip={t(`${PATH}.scores.pas.tooltip`)}
							/>

							<SpotCheckLineProgress
								percent={spotCheck.overview.data.pss}
								title={t(`${PATH}.scores.pss.title`)}
								tooltip={t(`${PATH}.scores.pss.tooltip`)}
							/>
						</div>
					</Fx>
				</Col>
			</Row>
		</Card>
	);
}

export default observer(SpotCheckOverview);
