import { ColumnProps } from 'antd/lib/table';
import { useCreateColumns } from 'Components/Table/createColumns';
import { useTranslation } from 'react-i18next';
import {
	SpotCheckJobClassOverviewItem,
	SpotCheckJobClassParams
} from 'Services/Api/Dashboard/Types';
import {
	renderMoodIndicatorColumn,
	renderTrendIndicator
} from '../../columns.helpers';

export const useColumns = ({ params }: { params: SpotCheckJobClassParams }) => {
	const { t } = useTranslation();

	const { sort, getSortOrder } = useCreateColumns<
		SpotCheckJobClassOverviewItem,
		Record<string, string>
	>({
		filterValues: undefined,
		sortDesc: params.sort
	});

	const columns: Array<ColumnProps<SpotCheckJobClassOverviewItem>> = [
		{
			...sort,
			title: t('dashboard.tabs.spotCheck.climateMeasurement.jobRole'),
			key: 'industryJobClassName',
			dataIndex: 'industryJobClassName',
			width: '63%',
			sortOrder: getSortOrder('industryJobClassName')
		},
		{
			...sort,
			title: t('dashboard.timePeriod.previous'),
			key: 'last',
			dataIndex: 'last',
			render: renderMoodIndicatorColumn,
			sortOrder: getSortOrder('last')
		},
		{
			...sort,
			title: t('dashboard.timePeriod.current'),
			key: 'current',
			dataIndex: 'current',
			render: renderMoodIndicatorColumn,
			sortOrder: getSortOrder('current')
		},
		{
			...sort,
			title: t('dashboard.groupSummary.variance'),
			key: 'variance',
			dataIndex: 'variance',
			render: (_, { variance }) => renderTrendIndicator(variance),
			sortOrder: getSortOrder('variance')
		}
	];

	return columns;
};
