import { Button, Modal, Space, Tooltip } from 'Components';
import DataTree from 'Contexts/DataTree/DataTree';
import { DataTreeNodeItem } from 'Contexts/DataTree/DataTree.types';
import { ModalProps } from 'Components/Modal/Modal';
import { useModal } from 'Hooks/useModal';
import { isEqual } from 'lodash';
import { useState } from 'react';
import { Group } from 'Services/Api/Groups/Types';
import GroupTree, { GroupTreeProps, GroupTreeInitialValue } from './GroupTree';
import { useTranslation } from 'react-i18next';

export type DataTreeNodeItemGroup = DataTreeNodeItem<Group>[];

export type GroupModalValue = DataTreeNodeItemGroup | undefined;

export type GroupModalProps = Pick<ModalProps, 'okText' | 'title'> & {
	description?: React.ReactNode;
	initialValue: GroupTreeInitialValue;
	onSubmit: (values: GroupModalValue) => Promise<void> | void;
	hideOnSubmit?: boolean;
	tooltip?: string;
	checkable?: boolean;
};
function GroupModal(props: GroupModalProps) {
	const { t } = useTranslation();
	const { hideModal } = useModal();
	const { modal } = useModal();

	const [loading, setLoading] = useState(false);
	const [value, setValue] = useState<GroupModalValue>();

	const initialKeys = props.initialValue.initialValue?.map(
		(item) => item.key
	);
	const currentKeys = value?.map((item) => item.key);
	const dirty = !isEqual(initialKeys, currentKeys ?? initialKeys);

	const onChange: GroupTreeProps['onChange'] = (value) => {
		setValue(value);
	};

	const onSubmit = async () => {
		try {
			setLoading(true);
			await props.onSubmit(value);

			if (props.hideOnSubmit) {
				hideModal();
			}
		} finally {
			setLoading(false);
		}
	};

	if (!modal) return null;
	const submitButton = (
		<Button
			shape="round"
			type="primary"
			onClick={onSubmit}
			loading={loading}
			disabled={!dirty}
		>
			{props.okText ?? t('saveChanges')}
		</Button>
	);
	return (
		<Modal
			visible
			size="sm"
			title={props.title}
			footer={
				<Space>
					<Button
						ghost
						shape="round"
						type="default"
						onClick={hideModal}
					>
						{t('cancel')}
					</Button>
					{props.tooltip ? (
						<Tooltip
							title={props.tooltip}
							trigger="hover"
							enabled={!dirty}
						>
							{submitButton}
						</Tooltip>
					) : (
						submitButton
					)}
				</Space>
			}
			onCancel={hideModal}
		>
			{props.description}
			<GroupTree
				initialValue={props.initialValue.initialValue}
				disabledValue={props.initialValue.disabledValue}
				onChange={onChange}
			>
				<DataTree.List
					highlightValue
					checkable={props.checkable}
					checkStrictly
				/>
			</GroupTree>
		</Modal>
	);
}

export default GroupModal;
