import { authStore } from './AuthStore';
import { languageStore } from './LanguageStore';
import DashboardStore, {
	dashboardStore
} from './DashboardStore/DashboardStore';
import { reportsStore } from './ReportsStore';
import ReportStore, { reportStore } from './ReportStore';
import { downloadReportsStore } from './DownloadReportStore';
import { systemsStore } from './SystemsStore';
class RootStore {
	authStore = authStore;
	languageStore = languageStore;
	dashboardStore = dashboardStore;
	downloadReportsStore = downloadReportsStore;
	reportsStore = reportsStore;
	reportStore = reportStore;
	systemsStore = systemsStore;
	public clearDashboardStore() {
		this.dashboardStore = new DashboardStore();
	}
	public clearReportStore() {
		this.reportStore = new ReportStore();
	}
}

export default new RootStore();
