import { IconProps } from 'Components/Icon/Icon';
import invert from 'lodash/invert';
import { LanguageI18nKey } from 'Services/Api/Common/Types';

export const I18N_LANGUAGE_LIST = Object.values(LanguageI18nKey);

export const LANGUAGE_BY_ID: Record<number, LanguageI18nKey> = {
	1: LanguageI18nKey.English,
	2: LanguageI18nKey.Spanish,
	3: LanguageI18nKey.French
};

export const ID_BY_LANGUAGE = (invert(LANGUAGE_BY_ID) as unknown) as Record<
	LanguageI18nKey,
	number
>;

export const ICON_BY_MENU_KEY: Record<LanguageI18nKey, IconProps['type']> = {
	[LanguageI18nKey.English]: 'flagUs',
	[LanguageI18nKey.French]: 'flagFr',
	[LanguageI18nKey.Spanish]: 'flagEs'
};

export const TITLE_BY_MENU_KEY: Record<LanguageI18nKey, string> = {
	[LanguageI18nKey.English]: 'English',
	[LanguageI18nKey.French]: 'French',
	[LanguageI18nKey.Spanish]: 'Spanish'
};
