import { Trans, useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { SelectProps as AntSelectProps } from 'antd/lib/select';
import { UploadProps } from 'antd';
import { isEmpty } from 'lodash';
import { Button, Tooltip, Fx, Notification, UploadFile } from 'Components';
import GroupsField, {
	GroupsFieldValue
} from 'Pages/User/components/GroupsField/GroupsField';
import { TRANSLATION_PATH } from './ImportXLSXFileModal.constants';
import { GET_USERS_IMPORT_TEMPLATE } from 'Services/Api/Users/Queries';
import {
	GetUsersImportTemplateRequest,
	GetUsersImportTemplateResponse
} from 'Services/Api/Users/Types';
import { downloadLink } from 'Helpers/downloadLink';
import styles from './ImportXLSXFileModal.module.scss';
import TInfoText from 'Contexts/TInfoText/TInfoText';

const TRANSLATION_PATH_TO_RULES = `${TRANSLATION_PATH}rules.`;
const RULES = [
	`${TRANSLATION_PATH_TO_RULES}fileFormat`,
	`${TRANSLATION_PATH_TO_RULES}mandatoryFields`,
	`${TRANSLATION_PATH_TO_RULES}bulkUpload`
];

interface ImportXLSXFileModalContentProps {
	handleFileUpload: UploadProps['onChange'];
	fileName: string;
	groupFieldValue: GroupsFieldValue | undefined;
	setGroupFieldValue: (groupFieldValue: GroupsFieldValue) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onDeselect: AntSelectProps<any>['onDeselect'];
}
const ImportXLSXFileModalContent = ({
	handleFileUpload,
	fileName,
	groupFieldValue,
	setGroupFieldValue,
	onDeselect
}: ImportXLSXFileModalContentProps) => {
	const [getUsersImportTemplate, { data }] = useLazyQuery<
		GetUsersImportTemplateResponse,
		GetUsersImportTemplateRequest
	>(GET_USERS_IMPORT_TEMPLATE);

	const { t } = useTranslation();
	const onSubmit = (group: GroupsFieldValue) => {
		if (group && !isEmpty(group)) {
			const { id } = group[0].data;

			getUsersImportTemplate({
				variables: {
					groupId: id
				}
			});
		}
		setGroupFieldValue(group);
	};
	const handleDownloadLinkClick = (
		e: React.MouseEvent<HTMLAnchorElement>
	) => {
		if (!data) {
			e.preventDefault();
			return;
		}
		downloadLink(data.getUsersImportTemplate.downloadUrl);
		Notification.success({
			description: t('notification.downloadFile.success')
		});
	};
	const downloadUrl = data?.getUsersImportTemplate.downloadUrl ?? '';
	const isDisabled = isEmpty(groupFieldValue);

	return (
		<>
			<p>{t(`${TRANSLATION_PATH}rulesTitle`)}</p>
			<ul className={styles.rulesList}>
				{RULES.map((rule) => {
					return (
						<li key={rule}>
							<TInfoText path={rule} />
						</li>
					);
				})}
			</ul>

			<h3 className={styles.title}>
				{t(`${TRANSLATION_PATH}downloadTheTemplateTitle`)}
			</h3>
			<p>{t(`${TRANSLATION_PATH}downloadTheTemplateDescription`)}</p>
			<Fx>
				<GroupsField
					required
					onSubmit={onSubmit}
					label={t(`${TRANSLATION_PATH}selectGroup`)}
					placeholder={t(`${TRANSLATION_PATH}selectGroup`)}
					error={false}
					value={groupFieldValue}
					className={styles.groupField}
					checkable={false}
					title={t(`${TRANSLATION_PATH}selectGroup`)}
					okText={t(`${TRANSLATION_PATH}selectGroup`)}
					description=""
					onDeselect={onDeselect}
				/>

				<Tooltip
					title={t(`${TRANSLATION_PATH}tooltip`)}
					trigger="hover"
					enabled={isDisabled}
					className={styles.downloadXLSXLinkWithTooltip}
				>
					<Button
						download={downloadUrl}
						className={styles.downloadXLSXLink}
						onClick={handleDownloadLinkClick}
						type="text"
						disabled={isDisabled}
						link
					>
						{t(`downloadXLSXTemplate`)}
					</Button>
				</Tooltip>
			</Fx>

			<h3 className={styles.title}>{t('uploadFile')}</h3>
			<UploadFile
				onChange={handleFileUpload}
				disabled={isDisabled}
				accept=".xlsx"
				value={
					isDisabled
						? (t(`${TRANSLATION_PATH}tooltip`) as string)
						: fileName || ''
				}
			/>

			<div className={styles.userGuide}>
				<p>
					<Trans i18nKey={`${TRANSLATION_PATH}questionsDescription`}>
						<a
							href={process.env.REACT_APP_VENZA_USER_GUIDE_LINK}
							target="_blank"
							rel="noreferrer"
						>
							&nbsp;
						</a>
						<a
							href={process.env.REACT_APP_VENZA_TICKET_SUPPORT}
							target="_blank"
							rel="noreferrer"
						>
							&nbsp;
						</a>
					</Trans>
				</p>
			</div>
		</>
	);
};

export default ImportXLSXFileModalContent;
