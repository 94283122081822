import { useHistory, Redirect } from 'react-router';
import { TwoFactorAuthorizationStatus } from 'Services/Api/Users/Types';
import { useStores } from 'Hooks/useStore';
import { getAppRoutes } from 'Pages/App/App.constants';
import { AuthState } from 'Pages/Auth/Auth.types';

function RedirectToTwoFactorAuthorization() {
	const { authStore } = useStores();
	const { location } = useHistory<AuthState>();
	const appRoutes = getAppRoutes();
	const twoFactorAuthorizationStatus =
		authStore.currentUser?.twoFactorAuthorizationStatus;
	return (
		<Redirect
			to={{
				state: {
					returnUrl: location.pathname + location.search
				},
				pathname:
					location.state?.firstTimeSignIn ||
					twoFactorAuthorizationStatus ===
						TwoFactorAuthorizationStatus.SetupPending
						? appRoutes.TWO_STEP_REGISTRATION
						: appRoutes.TWO_STEP_AUTHENTICATION
			}}
		/>
	);
}

export default RedirectToTwoFactorAuthorization;
