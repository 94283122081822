import { Status } from 'app-types';
import {
	SpotCheckOverviewData,
	SpotCheckOverviewResponse
} from 'Services/Api/Dashboard/Types';
import { TrendIndicatorDirection } from 'Pages/Dashboard/Dashboard.types';

export function defineDirection(
	variance: number
): TrendIndicatorDirection | null {
	if (variance > 0) return 'up';
	if (variance < 0) return 'down';
	return null;
}

export function getSpotCheckSummaryOverviewData({
	pas,
	pss,
	tar,
	ici,
	variance
}: SpotCheckOverviewResponse): SpotCheckOverviewData {
	return {
		pas,
		tar,
		pss,
		changePercent: variance,
		trendDirection: defineDirection(variance),
		ICIScore: ici
	};
}

export const getIsPending = (status: Status) => status === Status.Pending;

export const getIsSuccess = (status: Status) => status === Status.Success;
export const getIsFailure = (status: Status) => status === Status.Failure;

export const getIsDone = (status: Status) => {
	return getIsSuccess(status) || getIsFailure(status);
};
