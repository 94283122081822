import React from 'react';
import cn from 'classnames';
import { Form as AntForm } from 'antd';
import { FormItemProps as AntFormItemProps } from 'antd/lib/form';
import styles from './FormItem.module.scss';

export type FormItemProps = AntFormItemProps & {
	horizontal?: boolean;
	marginBottom?: boolean;
	fullWidth?: boolean;
	text?: boolean;
};
const FormItem: React.FC<FormItemProps> = ({
	horizontal,
	marginBottom,
	fullWidth,
	text,
	labelAlign,
	...props
}) => {
	return (
		<AntForm.Item
			{...props}
			colon={false}
			className={cn(styles.root, props.className, {
				[styles.horizontal]: horizontal,
				[styles.noMarginBottom]: marginBottom === false,
				[styles.fullWidth]: fullWidth,
				[styles.text]: text,
				[styles.labelAlignRight]: labelAlign === 'right'
			})}
		/>
	);
};

export default FormItem;
