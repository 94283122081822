import { FC } from 'react';
import styles from './CardLink.module.scss';
import { Button, Card, Text } from 'Components';
import { ButtonProps } from 'Components/Button/Button';

interface CardLinkProps {
	title: string;
	subtitle: string;
	buttonText?: string;
	buttonProps?: ButtonProps;
	href?: string;
}

const CardLink: FC<CardLinkProps> = ({
	title,
	subtitle,
	buttonProps,
	buttonText,
	href,
	children
}) => {
	const renderTitle = () => {
		return (
			<div className={styles.title}>
				{title}
				<Text className={styles.subTitle} color="default">
					{subtitle}
				</Text>
			</div>
		);
	};

	const handleButtonClick = () => {
		window.open(href, '_blank');
	};

	return (
		<Card className={styles.root} title={renderTitle()}>
			{children ?? (
				<Button
					shape="round"
					type="primary"
					{...buttonProps}
					onClick={handleButtonClick}
				>
					{buttonText}
				</Button>
			)}
		</Card>
	);
};

export default CardLink;
