import { FormItem, Select } from 'Components';
import { SelectProps } from 'Components/Form/Select/Select';
import { useFormikContext } from 'formik';
import ModalContextProvider, { ModalContext } from 'Hooks/useModal';
import { useTranslation } from 'react-i18next';
import { REPORT_FIELD_LABEL } from '../../EditReport.constants';
import { ReportFormValues } from '../../schema';
import GroupsFieldModal, { GroupsFieldModalProps } from './GroupsFieldModal';

export type GroupsFieldProps = Pick<SelectProps, 'disabled' | 'onClear'> &
	GroupsFieldModalProps;
function GroupsField({ disabled, onClear, onConfirm }: GroupsFieldProps) {
	const { t } = useTranslation();
	const { values } = useFormikContext<ReportFormValues>();
	const value = values.groupId ?? values.ownershipGroupId;
	return (
		<ModalContextProvider>
			<ModalContext.Consumer>
				{({ modal, showModal }) => (
					<>
						{modal && <GroupsFieldModal onConfirm={onConfirm} />}
						<FormItem label={t(REPORT_FIELD_LABEL.groupId)}>
							<Select
								disabled={disabled}
								loading={disabled}
								onClear={onClear}
								labelInValue
								value={value}
								onClick={() => showModal({ value: null })}
								open={false}
								allowClear
								getPopupContainer={() => document.body}
							>
								{value && (
									<Select.Option
										key={value.key}
										value={value.value}
										children={null}
									/>
								)}
							</Select>
						</FormItem>
					</>
				)}
			</ModalContext.Consumer>
		</ModalContextProvider>
	);
}

export default GroupsField;
