import { useTranslation } from 'react-i18next';
import { EditableLayout } from 'Components';
import { getAppRoutes } from 'Pages/App/App.constants';
import { generatePath, useHistory } from 'react-router-dom';
import AddOwnershipGroupFormFields from './AddOwnershipGroupFormFields';
import { isUndefined } from 'lodash';

type AddOwnershipGroupFormProps = {
	groupId: number | undefined;
};
function AddOwnershipGroupForm(props: AddOwnershipGroupFormProps) {
	const { t } = useTranslation();
	const history = useHistory();
	const { groupId } = props;

	const goBack = () => {
		history.push(
			generatePath(getAppRoutes().OWNERSHIP_GROUPS, { groupId })
		);
	};

	const isNew = isUndefined(groupId);

	return (
		<EditableLayout
			goBack={goBack}
			heading={
				isNew
					? t('ownershipGroup.addOwnershipGroup.title')
					: t('ownershipGroup.editOwnershipGroup.title')
			}
			okText={
				isNew
					? t('ownershipGroup.addOwnershipGroup.okText')
					: t('saveChanges')
			}
		>
			<AddOwnershipGroupFormFields />
		</EditableLayout>
	);
}

export default AddOwnershipGroupForm;
