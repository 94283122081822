import { Button, Layout, Prompt, Space } from 'Components';
import { useFormikContext } from 'formik';
import { isBoolean, noop } from 'lodash';
import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EditableLayout.module.scss';

export type EditableLayoutContextValue = Pick<EditableLayoutProps, 'goBack'>;
export const EditableLayoutContext = createContext<EditableLayoutContextValue>({
	goBack: noop
});

export interface LayoutProps {
	heading: React.ReactNode;
	goBack: () => void;
	backButtonVisible?: boolean;
	prompt?: boolean;
	footer?: boolean | ((props: EditableLayoutContextValue) => JSX.Element);
}

interface EditableLayoutInterface {
	Tab: typeof TabLayout;
}
type EditableLayoutProps = LayoutProps & {
	okText?: string;
};
const EditableLayout: React.FC<EditableLayoutProps> &
	EditableLayoutInterface = (props) => {
	const { t } = useTranslation();
	const { isValid, dirty, isSubmitting, submitForm } = useFormikContext();
	const {
		prompt = true,
		backButtonVisible,
		okText,
		goBack,
		heading,
		footer = true,
		children
	} = props;

	const FooterComponent = !isBoolean(footer) ? footer : () => null;
	return (
		<Layout>
			{prompt && <Prompt blockTransition={dirty && !isSubmitting} />}

			<Layout.Heading
				goBack={backButtonVisible !== false ? goBack : undefined}
			>
				<h1>{heading}</h1>
			</Layout.Heading>
			<Layout.Body>
				<Layout.Content>{children}</Layout.Content>

				{footer === true ? (
					<Layout.Footer>
						<Space>
							<Button
								ghost
								shape="round"
								type="default"
								onClick={goBack}
							>
								{t('cancel')}
							</Button>
							<Button
								shape="round"
								type="primary"
								disabled={!(isValid && dirty)}
								loading={isSubmitting}
								onClick={() => submitForm()}
							>
								{okText ?? t('submit')}
							</Button>
						</Space>
					</Layout.Footer>
				) : (
					<FooterComponent goBack={goBack} />
				)}
			</Layout.Body>
		</Layout>
	);
};

const TabLayout: React.FC<LayoutProps> = (props) => {
	const { t } = useTranslation();
	const { isValid, dirty, isSubmitting, submitForm } = useFormikContext();
	const {
		heading,
		backButtonVisible,
		prompt = true,
		goBack,
		footer = true,
		children
	} = props;
	return (
		<EditableLayoutContext.Provider value={props}>
			<Layout>
				{prompt && <Prompt blockTransition={dirty && !isSubmitting} />}
				<Layout.Heading
					goBack={backButtonVisible !== false ? goBack : undefined}
				>
					<h1>{heading}</h1>
				</Layout.Heading>

				{children}

				{footer && (
					<Layout.Footer className={styles.footer}>
						<Space>
							<Button
								ghost
								shape="round"
								type="default"
								onClick={goBack}
							>
								{t('cancel')}
							</Button>
							<Button
								shape="round"
								type="primary"
								htmlType="submit"
								disabled={!(isValid && dirty)}
								loading={isSubmitting}
								onClick={() => submitForm()}
							>
								{t('saveChanges')}
							</Button>
						</Space>
					</Layout.Footer>
				)}
			</Layout>
		</EditableLayoutContext.Provider>
	);
};

EditableLayout.Tab = TabLayout;

export default EditableLayout;
