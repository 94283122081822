import { memo } from 'react';
import { isEqual } from 'lodash';
import EmptyTextContent from 'Pages/Groups/Group/EmptyTextContent';
import { VirtualTable } from 'Components';
import { Column } from 'Components/VirtualTable/VirtualTable';
import { HierarchyGroup } from '../../GroupsHierarchy.types';

interface MemoizedVirtualTableProps {
	dataSource: HierarchyGroup[];
	columns: Array<Column<HierarchyGroup>>;
	disabledIds: Record<string, string[]>;
}

const MemoizedVirtualTable = memo<MemoizedVirtualTableProps>(
	({ columns, dataSource }) => {
		return (
			<VirtualTable<HierarchyGroup>
				dataSource={dataSource}
				columns={columns}
				renderTotal
				locale={{
					emptyText: <EmptyTextContent isGroup />
				}}
			/>
		);
	},
	(prev, current) =>
		isEqual(prev.dataSource, current.dataSource) &&
		isEqual(prev.disabledIds, current.disabledIds)
);

export default MemoizedVirtualTable;
