import cn from 'classnames';
import { Button, Fx, Icon } from 'Components';
import { isFunction } from 'lodash';
import React from 'react';
import MountainLeftImg from './images/mountain-left.svg';
import MountainRightImg from './images/mountain-right.svg';
import styles from './Layout.module.scss';

type LayoutInterface = React.FC<LayoutProps> & {
	Header: typeof LayoutHeader;
	Heading: typeof LayoutHeading;
	Body: typeof Body;
	Content: typeof Content;
	Footer: typeof Footer;
	List: typeof List;
	MountainView: typeof MountainView;
};

interface LayoutBaseProps {
	fullHeight?: boolean;
}

interface LayoutProps {
	className?: string;
	column?: boolean;
	fullHeight?: boolean;
}

const Layout: LayoutInterface = ({
	className,
	column = true,
	fullHeight,
	children
}) => {
	return (
		<Fx
			column={column}
			fullHeight={fullHeight}
			className={cn(styles.root, className)}
		>
			{children}
		</Fx>
	);
};

interface LayoutHeaderProps {
	aligned?: 'left' | 'right';
	className?: string;
}
const LayoutHeader: React.FC<LayoutHeaderProps> = ({
	aligned,
	className,
	children
}) => {
	return (
		<Fx
			align="middle"
			justify="space-between"
			className={cn(className, styles.header, {
				[styles.alignedLeft]: aligned === 'left',
				[styles.alignedRight]: aligned === 'right'
			})}
			shrink={0}
		>
			{children}
		</Fx>
	);
};

interface LayoutHeadingProps {
	goBack?: () => void;
	prefixNode?: React.ReactNode;
	children:
		| React.ReactNode
		| ((helpers: { searchFieldClassName: string }) => React.ReactNode);
}
const LayoutHeading: React.FC<LayoutHeadingProps> = ({
	goBack,
	prefixNode,
	children
}) => {
	const onBackClick: React.MouseEventHandler<HTMLElement> = () => {
		if (goBack) {
			goBack();
			return;
		}
	};
	const control =
		prefixNode ??
		(goBack && (
			<Button type="text" thin onClick={onBackClick}>
				<Icon type="arrowLeft" />
			</Button>
		));
	return (
		<Fx align="middle" className={styles.heading} shrink={0}>
			{control && <div className={styles.headingPrefix}>{control}</div>}

			<div className={styles.headingContent}>
				{isFunction(children)
					? children({
							searchFieldClassName: styles.headingSearchField
					  })
					: children}
			</div>
		</Fx>
	);
};
interface BodyProps {
	fullWidth?: boolean;
	className?: string;
}
const Body: React.FC<BodyProps> = ({ children, fullWidth, className }) => {
	return (
		<div
			className={cn(styles.body, className, {
				[styles.bodyFullWidth]: fullWidth
			})}
		>
			{children}
		</div>
	);
};

const Content: React.FC<LayoutBaseProps> = ({ fullHeight, children }) => {
	return (
		<div
			className={cn(styles.content, { [styles.fullHeight]: fullHeight })}
		>
			{children}
		</div>
	);
};

interface FooterProps {
	className?: string;
}
const Footer: React.FC<FooterProps> = ({ className, children }) => {
	return <div className={cn(styles.footer, className)}>{children}</div>;
};

const MountainView = ({ maxHeight }: { maxHeight?: number }) => {
	return (
		<div className={styles.mountainView}>
			<img src={MountainLeftImg} alt="mountain" style={{ maxHeight }} />
			<img src={MountainRightImg} alt="mountain" style={{ maxHeight }} />
		</div>
	);
};

interface ListInterface {
	ListItem: typeof ListItem;
	ListItemBody: typeof ListItemBody;
	Section: typeof Section;
}
const List: React.FC<LayoutBaseProps> & ListInterface = ({
	fullHeight,
	children
}) => {
	return (
		<div className={cn(styles.list, { [styles.fullHeight]: fullHeight })}>
			{children}
		</div>
	);
};

type ListItemProps = LayoutBaseProps & {
	title?: React.ReactNode;
	stacked?: boolean;
	wrapInBody?: boolean;
	use80?: boolean;
};
const ListItem: React.FC<ListItemProps> = ({
	title,
	stacked,
	wrapInBody = true,
	fullHeight,
	use80,
	children
}) => {
	return (
		<div
			className={cn(styles.listItem, { [styles.fullHeight]: fullHeight })}
		>
			{title && <h1>{title}</h1>}
			{wrapInBody ? (
				<Layout.List.ListItemBody stacked={stacked} use80={use80}>
					{children}
				</Layout.List.ListItemBody>
			) : (
				children
			)}
		</div>
	);
};

type ListItemBodyProps = LayoutBaseProps & {
	stacked?: boolean;
	use80?: boolean;
};
const ListItemBody: React.FC<ListItemBodyProps> = ({
	stacked,
	children,
	use80
}) => {
	return (
		<div
			className={cn(styles.listItemBody, {
				[styles.stacked]: stacked,
				[styles.use80]: use80
			})}
		>
			{children}
		</div>
	);
};

interface SectionProps {
	title?: React.ReactNode;
}
const Section: React.FC<SectionProps> = ({ title, children }) => {
	return (
		<div className={cn(styles.section)}>
			{title && <h2>{title}</h2>}
			{children}
		</div>
	);
};

List.ListItem = ListItem;
List.ListItemBody = ListItemBody;
List.Section = Section;

Layout.List = List;
Layout.Header = LayoutHeader;
Layout.Heading = LayoutHeading;
Layout.Body = Body;
Layout.Content = Content;
Layout.Footer = Footer;
Layout.MountainView = MountainView;

export default Layout;
