import { Link } from 'react-router-dom';
import { getAppRoutes } from '../../App.constants';
import styles from './AppHeader.module.scss';
import { ReactComponent as Logo } from './logo.svg';
import { BlockNavigation } from './AppHeader.types';

type LogoComponentProps = BlockNavigation;

const LogoComponent = ({ blockNavigation }: LogoComponentProps) => {
	return blockNavigation ? (
		<div className={styles.logo}>
			<Logo />
		</div>
	) : (
		<Link className={styles.logo} to={getAppRoutes().HOME}>
			<Logo />
		</Link>
	);
};

export default LogoComponent;
