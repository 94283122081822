import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Layout, InactiveHierarchyModalContent } from 'Components';
import { AddUsersFormValues } from './AddUsers.types';
import AddUsersLayout from './AddUsersLayout';
import { schema } from './schema';
import { createNewUser } from './AddUsers.helpers';
import ModalContextProvider from 'Hooks/useModal';
import styles from './AddUsers.module.scss';
import { useUserContext } from 'Pages/User/UserContext';
import { ShowInactiveModal as WrapLayoutProps } from 'Components/InactiveHierarchyModalContent/InactiveHierarchyModalContent.types';

const AddUsersWrap: FC = ({ children }) => {
	const { languagesList, timezonesList, systemRolesList } = useUserContext();
	const data = {
		languages: languagesList,
		timezones: timezonesList,
		systemRoles: systemRolesList
	};
	const INITIAL_VALUES: AddUsersFormValues = {
		users: [
			createNewUser({
				data
			})
		],
		data
	};

	const form = useForm<AddUsersFormValues>({
		defaultValues: INITIAL_VALUES,
		// @ts-ignore
		resolver: yupResolver(schema),
		mode: 'onChange'
	});

	return (
		<Layout fullHeight className={styles.layoutOverflowHidden}>
			<FormProvider {...form}>{children}</FormProvider>
		</Layout>
	);
};

const WrapLayout = (props: WrapLayoutProps) => {
	return (
		<ModalContextProvider>
			<AddUsersLayout {...props} />
		</ModalContextProvider>
	);
};

const AddUsers = () => {
	return (
		<AddUsersWrap>
			<InactiveHierarchyModalContent withConfirmationLoading>
				{/* @NOTE: showInactiveModal={undefined as never} need for correct ts functionality */}
				<WrapLayout showInactiveModal={undefined as never} />
			</InactiveHierarchyModalContent>
		</AddUsersWrap>
	);
};

export default AddUsers;
