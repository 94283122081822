import { Button } from 'Components';
import ModalContextProvider, { useModal } from 'Hooks/useModal';
import { useTranslation } from 'react-i18next';
import DownloadReportModal, {
	DownloadReportModalProps
} from './DownloadReportModal';

type DownloadReportProps = DownloadReportModalProps;
function DownloadReport(props: DownloadReportProps) {
	const { t } = useTranslation();
	const { showModal } = useModal();
	const handleDownload = () => {
		showModal({ value: props.reportType });
	};
	return (
		<>
			<Button shape="round" ghost type="primary" onClick={handleDownload}>
				{t('dashboard.downloadReport')}
			</Button>
			<DownloadReportModal {...props} />
		</>
	);
}

function DownloadReportButton(props: DownloadReportProps) {
	return (
		<ModalContextProvider>
			<DownloadReport {...props} />
		</ModalContextProvider>
	);
}

export default DownloadReportButton;
