import { Progress as AntProgress } from 'antd';
import cn from 'classnames';
import { isUndefined } from 'lodash';
import styles from './LineProgress.module.scss';
import { ProgressStatus } from 'Pages/Dashboard/Dashboard.types';
import { Fx } from 'Components';
import { ReactNode } from 'react';

interface LineProgressProps {
	before?: ReactNode;
	after?: ReactNode;
	status?: ProgressStatus | null;
	percent?: number | null;
	large?: boolean;
	className?: string;
}

const LineProgress: React.FC<LineProgressProps> = ({
	percent,
	status,
	before,
	after,
	large,
	className
}) => {
	if (isUndefined(percent)) {
		return null;
	}

	return (
		<Fx align="middle" fullWidth className={className}>
			{before && <div className={styles.before}>{before}</div>}
			<AntProgress
				strokeWidth={large ? 18 : 8}
				className={cn(styles.progress, status && styles[status])}
				showInfo={false}
				percent={percent || undefined}
			/>
			{after && <div className={styles.after}>{after}</div>}
		</Fx>
	);
};

export default LineProgress;
