import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import OwnershipGroupField from 'Pages/User/components/OwnershipGroupField/OwnershipGroupField';
import { AddUserValues } from 'Pages/User/User.types';
import GroupsField from './GroupsField/GroupsField';
import AllGroupsField from './AllGroupsField';
import { useEffect } from 'react';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import { translateTInfo } from 'i18n/i18n.helpers';

interface GroupFieldProps {
	isProfile?: boolean;
}
function GroupField(props: GroupFieldProps) {
	const { t } = useTranslation();
	const {
		values,
		setFieldValue,
		setFieldTouched,
		getFieldMeta
	} = useFormikContext<AddUserValues>();
	const groupMeta = getFieldMeta('groups');
	const { primarySystemRoleId } = values;

	const primarySystemRole = primarySystemRoleId?.find(
		(item) => item.data.isPrimary
	);

	const onGroupsFieldSubmit = (value: AddUserValues['groups']) => {
		setFieldValue('groups', value);
		setTimeout(() => {
			// trigger validate
			// use timeout to avoid error message blinking when set for the first time
			setFieldTouched('groups');
		});
	};

	useEffect(() => {
		if (!primarySystemRole) {
			setFieldValue('groups', undefined);
			setFieldValue('ownershipGroup', undefined);
		}
	}, [setFieldValue, primarySystemRole]);

	if (!primarySystemRole) {
		return null;
	}

	if (primarySystemRole.data.role === SystemRoleType.SuperAdmin) {
		return <AllGroupsField required />;
	}

	if (primarySystemRole.data.role === SystemRoleType.Owner) {
		return <OwnershipGroupField disabled={props.isProfile} />;
	}

	const error = groupMeta.touched ? groupMeta.error : undefined;
	return (
		<GroupsField
			label={t('user.groups')}
			error={translateTInfo(error, t)}
			value={values.groups}
			disabled={props.isProfile}
			onSubmit={onGroupsFieldSubmit}
		/>
	);
}

export default GroupField;
