import { ExportButton } from 'Components';
import { ExportButtonProps } from 'Components/ExportButton/ExportButton';
import { EXPORT_SYSTEM_ROLES } from 'Services/Api/Roles/Queries';
import {
	ExportSystemRolesRequest,
	ExportSystemRolesResponse
} from 'Services/Api/Roles/Types';

export type RolesExportButtonProps = Omit<
	ExportButtonProps<ExportSystemRolesResponse, ExportSystemRolesRequest>,
	'query' | 'getQueryUrl'
>;
function RolesExportButton(props: RolesExportButtonProps) {
	const getQueryUrl = (data: ExportSystemRolesResponse) =>
		data.exportSystemRoles.downloadUrl;
	return (
		<ExportButton<ExportSystemRolesResponse, ExportSystemRolesRequest>
			query={EXPORT_SYSTEM_ROLES}
			getQueryUrl={getQueryUrl}
			{...props}
		/>
	);
}

export default RolesExportButton;
