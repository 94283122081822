import { ExportButton } from 'Components';
import { ExportButtonProps } from 'Components/ExportButton/ExportButton';
import {
	EXPORT_USERS_AND_GROUPS_FOR_DELETION,
	EXPORT_WEEKLY_REPORT
} from 'Services/Api/Reports/Queries';
import {
	ReportByTokenType,
	DownloadRegularReportRequest,
	DownloadRegularReportResponse,
	RegularReportByTokenDetails
} from 'Services/Api/Reports/Types';

interface ReportExportProps<K = RegularReportByTokenDetails['type']> {
	(key: K): ReportTypeExportProps<K>;
}

type ReportTypeExportProps<T = RegularReportByTokenDetails['type']> = Pick<
	ExportButtonProps<
		DownloadRegularReportResponse<T>,
		DownloadRegularReportRequest
	>,
	'query' | 'getQueryUrl'
>;

const getExportButtonProps: ReportExportProps = (reportType) => {
	switch (reportType) {
		case ReportByTokenType.ReminderAboutUsersAndGroupsDeletion:
			const deletionProps: ReportTypeExportProps<typeof reportType> = {
				query: EXPORT_USERS_AND_GROUPS_FOR_DELETION,
				getQueryUrl: (
					data: DownloadRegularReportResponse<typeof reportType>
				) => data.exportUsersAndGroupsForDeletion.downloadUrl
			};
			return deletionProps;

		case ReportByTokenType.WeeklySummary:
			const weeklyProps: ReportTypeExportProps<typeof reportType> = {
				query: EXPORT_WEEKLY_REPORT,
				getQueryUrl: (
					data: DownloadRegularReportResponse<typeof reportType>
				) => data.exportWeeklyReport.downloadUrl
			};
			return weeklyProps;
	}
};

interface RegularReportProps {
	report: RegularReportByTokenDetails;
}

const RegularReport: React.FC<RegularReportProps> = ({ report, children }) => {
	const exportButtonProps = getExportButtonProps(report.type);
	return (
		<ExportButton<
			DownloadRegularReportResponse<typeof report.type>,
			DownloadRegularReportRequest
		>
			{...exportButtonProps}
			params={{
				input: report.meta
			}}
			menu={false}
		>
			{children}
		</ExportButton>
	);
};

export default RegularReport;
