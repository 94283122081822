import { useState, useCallback, useMemo, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Notification, Prompt, Spin, Layout } from 'Components';
import GroupsLayoutTemplate from 'Pages/Groups/GroupsLayoutTemplate';
import { useGroupsHierarchyContext } from './GroupsHierarchyContext';
import { AddMultipleGroupsMenu } from 'Pages/Groups/components';
import {
	HierarchyGroup,
	HierarchyValues,
	RouteParams
} from './GroupsHierarchy.types';
import HierarchyGroupComponent from './HierarchyGroup';
import {
	prepareFormValues,
	createHierarchyInput
} from './GroupsHierarchy.helpers';
import { CREATE_HIERARCHY } from 'Services/Api/Groups/Mutations';
import {
	CreateHierarchyGroupsRequest,
	CreateHierarchyGroupsResponse
} from 'Services/Api/Groups/Types';
import { getAppRoutes } from 'Pages/App/App.constants';
import { checkApolloError } from 'Helpers/graphql';
import { APIErrorCodes, ErrorInfo } from 'app-types';
import {
	DataTreeContextProps,
	DataTreeNode
} from 'Contexts/DataTree/DataTree.types';

const GroupsHierarchyManageLayout = () => {
	const { t } = useTranslation();
	const [createHierarchy, { loading, data }] = useMutation<
		CreateHierarchyGroupsResponse,
		CreateHierarchyGroupsRequest
	>(CREATE_HIERARCHY);
	const { hierarchyGroups, blockIsUniqError } = useGroupsHierarchyContext();
	const { groupId } = useParams<RouteParams>();
	const history = useHistory();
	const [selectedItem, setSelectedItem] = useState<DataTreeNode[]>([
		{
			key: (hierarchyGroups[0]?.id as unknown) as number,
			title: hierarchyGroups[0]?.name
		}
	]);

	const [reloadingFieldArray, setReloadingFieldArray] = useState(false);
	const form = useForm({
		defaultValues: prepareFormValues(hierarchyGroups),
		mode: 'all'
	});

	const {
		formState: { isSubmitSuccessful }
	} = form;

	const [{ key, title }] = selectedItem;
	useEffect(() => {
		if (data?.createHierarchy && isSubmitSuccessful) {
			Notification.success({
				description: t('groupsHierarchy.successNotification')
			});
			history.push(generatePath(getAppRoutes().GROUPS, { groupId }));
		}
		// eslint-disable-next-line
	}, [data, isSubmitSuccessful]);
	const group = useMemo(
		() =>
			hierarchyGroups.find((group) => group.id === key) as HierarchyGroup,
		[key, hierarchyGroups]
	);

	const handleOnHierarchyGroupSubmit = useCallback(
		async (values: HierarchyValues) => {
			try {
				const input = createHierarchyInput(values, groupId);

				await createHierarchy({
					variables: {
						input
					}
				});
			} catch (error) {
				const apolloError = checkApolloError(error);

				if (apolloError.is(APIErrorCodes.UnprocessableEntity)) {
					const metaKeys = apolloError.getMeta<
						Record<string, unknown>
					>();

					if (metaKeys) {
						const keys = Object.keys(metaKeys);

						const valuesLevelsIds = Object.keys(values);

						const pathToTranslation =
							keys.length > 1 ? 'multiple' : 'single';
						Notification.error({
							description: t(
								`groups.errorCodes.${APIErrorCodes.GroupNameShouldBeUniqueAtLevel}.${pathToTranslation}`
							)
						});
						valuesLevelsIds.forEach((levelId) => {
							values[levelId].forEach((value, index) => {
								if (keys.includes(value.id)) {
									form.setError(
										`${levelId}.${index}.name`,
										{}
									);
								}
							});
						});
					}
          return
				}
				if (apolloError.is(APIErrorCodes.GroupCannotBeProperty)) {
					Notification.error({
						description: t(
							`groupsHierarchy.notifications.error.${APIErrorCodes.GroupCannotBeProperty}`
						)
					});
          return
				}

        if(apolloError.is(APIErrorCodes.GroupSyncCodeShouldBeUniqPerTenant)){
					const metaKeys = apolloError.getMeta<
						{rowNumber: number; levelId: string;}[]
					>();

					if (metaKeys?.length) {

						metaKeys.forEach(({levelId, rowNumber}) => {
              form.setError(
                `${levelId}.${rowNumber}.syncCode`,
                {}
              );
						});
					}
          Notification.error({
            description: t('group.fieldErrorCodes.GROUP_SYNC_CODE_SHOULD_BE_UNIQUE_PER_TENANT', {path: 'Group sync code'})
          });

          return 
        }
				if (apolloError.isApolloError()) {
					const errorInfo = apolloError.getErrorInfo<
						Record<string, ErrorInfo[]>
					>();

					if (errorInfo) {
						Notification.error({
							description: t(
								'groupsHierarchy.notifications.error.submitHierarchy'
							)
						});
						return;
					}
					Notification.error({
						description: t('errorCodes.genericErrorMessage')
					});
				}
			}
		},

		// eslint-disable-next-line
		[groupId, history, form]
	);

	const onHierarchyGroupSubmit = useCallback(
		(ev) => {
			form.handleSubmit(handleOnHierarchyGroupSubmit)(ev);
		},
		[form, handleOnHierarchyGroupSubmit]
	);

	const onCancel = useCallback(() => {
		history.goBack();
	}, [history]);

	const onChange: DataTreeContextProps['onChange'] = (value) => {
		if (value) {
			setSelectedItem(value);
			form.reset(form.getValues());
			setReloadingFieldArray(true);
			setTimeout(() => {
				setReloadingFieldArray(false);
			}, 50);
		}
	};

	return (
		<>
			<Prompt blockTransition={!isSubmitSuccessful} />
			<FormProvider {...form}>
				<GroupsLayoutTemplate<HierarchyGroup>
					onChange={onChange}
					dataSource={hierarchyGroups}
					initialValue={selectedItem}
					defaultActiveExpand
					header={() => (
						<AddMultipleGroupsMenu
							onCancel={onCancel}
							onSubmit={onHierarchyGroupSubmit}
							loading={loading}
							headerTranslationPath="groupsHierarchy.title"
							disabledSubmit={blockIsUniqError}
						/>
					)}
				>
					{reloadingFieldArray ? (
						<Layout>
							<Spin />
						</Layout>
					) : (
						<HierarchyGroupComponent
							fieldArrayName={String(key)}
							defaultGroupName={title as string}
							group={group}
						/>
					)}
				</GroupsLayoutTemplate>
			</FormProvider>
		</>
	);
};

export default GroupsHierarchyManageLayout;
