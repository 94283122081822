import { Icon } from 'Components';
import { ProgressStatus } from 'Pages/Dashboard/Dashboard.types';
import { FC } from 'react';
import styles from './MoodIndicator.module.scss';
import cn from 'classnames';
import Icons from 'Components/Icon/Icons';

const ICON_TYPE_BY_STATUS: Record<ProgressStatus, keyof typeof Icons> = {
	sad: 'dislike',
	happy: 'like',
	normal: 'neutral'
};
interface MoodProps {
	status: ProgressStatus | null;
	className?: string;
}

const Mood: FC<MoodProps> = (props) => {
	if (!props.status) {
		return null;
	}

	const iconType = ICON_TYPE_BY_STATUS[props.status];

	return (
		<Icon
			type={iconType}
			className={cn(
				styles.root,
				props.className,
				styles[props.status || '']
			)}
		/>
	);
};

export default Mood;
