import { Icon } from 'Components';
import { TrendIndicatorDirection } from 'Pages/Dashboard/Dashboard.types';
import { FC } from 'react';
import cn from 'classnames';
import styles from './TrendIndicator.module.scss';

interface TrendIndicatorProps {
	direction?: TrendIndicatorDirection | null;
	className?: string;
}

const TrendIndicator: FC<TrendIndicatorProps> = ({
	direction,
	children,
	className
}) => {
	if (!direction && !children) {
		return null;
	}

	let indicator;

	if (direction === 'up') {
		indicator = <Icon type="trendUp" className={styles.arrow} />;
	}

	if (direction === 'down') {
		indicator = <Icon type="trendDown" className={styles.arrow} />;
	}

	return (
		<div className={cn(styles.root, className)}>
			{indicator}
			{children}
		</div>
	);
};

export default TrendIndicator;
