import React from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import {
	CheckboxGroupProps as AntCheckboxGroupProps,
	CheckboxProps
} from 'antd/lib/checkbox';
import { createFormikField } from 'Helpers/components';
import styles from './Checkbox.module.scss';
import cn from 'classnames';

type CheckboxInterface = React.FC<CheckboxProps> & {
	FormikField: typeof FormikField;
	Group: typeof Group;
};

const Checkbox: CheckboxInterface = (props) => (
	<AntCheckbox {...props} className={cn(styles.root, props.className)} />
);

export type CheckboxGroupProps = AntCheckboxGroupProps & {
	vertical?: boolean;
};
type GroupInterface = React.FC<CheckboxGroupProps> & {
	FormikField: typeof GroupFormikField;
};
const Group: GroupInterface = ({ vertical, ...props }) => (
	<AntCheckbox.Group
		{...props}
		className={cn(styles.root, props.className, {
			[styles.vertical]: vertical
		})}
	/>
);
const FormikField = createFormikField<CheckboxProps>(Checkbox, (props) => ({
	checked: props.field.value,
	onChange: (value) => {
		props.props.onChange?.(value);
		return props.helpers.setValue(value.target.checked);
	}
}));
const GroupFormikField = createFormikField<CheckboxGroupProps>(
	Group,
	(props) => ({
		value: props.field.value,
		onChange: (value) => {
			props.props.onChange?.(value);
			return props.helpers.setValue(value);
		}
	})
);

Group.FormikField = GroupFormikField;
Checkbox.Group = Group;
Checkbox.FormikField = FormikField;

export default Checkbox;
