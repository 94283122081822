import { isEmpty } from 'lodash';
import { useCallback, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { Modal, Notification } from 'Components';
import { useModal } from 'Hooks/useModal';
import { useErrorBoundary, useConfigs, useFileUpload } from 'Hooks';
import { GroupsFieldValue } from 'Pages/User/components/GroupsField/GroupsField';
import { VALIDATE_USERS } from 'Services/Api/Users/Queries';
import { GET_BASE_SYSTEM_ROLES } from 'Services/Api/Roles/Queries';
import {
	ValidateUsersRequest,
	ValidateUsersResponse,
	UserForValidation,
	ValidatedUser
} from 'Services/Api/Users/Types';
import {
	SystemRolesListItem,
	GetBaseSystemRolesResponse
} from 'Services/Api/Roles/Types';
import ImportXLSXFileModalContent from './ImportXLSXFileModalContent';
import {
	TRANSLATION_PATH,
	SHEETS_NAMES,
	SHEETS_TO_NAMES,
	COLUMNS_NAMES,
	COLUMNS_TITLES_CELLS
} from './ImportXLSXFileModal.constants';
import { SheetData } from 'app-types';
import {
	readFirstXLSXTab,
	validateColumnNames,
	getCleanedKeys
} from 'Helpers/xlsxFile';

interface ImportXLSXFileModalProps {
	handleUsersFromXLSXFile: (validatedUsers: ValidatedUser[]) => void;
}

type SystemRolesListItemByRole = Record<string, SystemRolesListItem>;

const ImportXLSXFileModal = (props: ImportXLSXFileModalProps) => {
	const errorBoundary = useErrorBoundary();
	const { hideModal } = useModal();
	const systemRoleWithPermission = useRef<SystemRolesListItemByRole | null>(
		null
	);
	const [getBaseSystemRole] = useLazyQuery<GetBaseSystemRolesResponse>(
		GET_BASE_SYSTEM_ROLES,
		{
			onCompleted: (data) => {
				systemRoleWithPermission.current = data.getBaseSystemRoles.reduce<SystemRolesListItemByRole>(
					(acc, baseSystemRole) => {
						acc[baseSystemRole.role] = baseSystemRole;
						return acc;
					},
					{}
				);
			}
		}
	);
	const [validateUsers, { loading }] = useLazyQuery<
		ValidateUsersResponse,
		ValidateUsersRequest
	>(VALIDATE_USERS, {
		onCompleted: async (data) => {
			if (data.validateUsers) {
				const validatedUsers = data.validateUsers.map(
					(validatedUser) => ({
						...validatedUser,
						systemRole: validatedUser?.systemRole?.role
							? (systemRoleWithPermission.current as SystemRolesListItemByRole)[
									validatedUser.systemRole.role
							  ]
							: null
					})
				);
				await props.handleUsersFromXLSXFile(validatedUsers);

				hideModal();
			}
		},
		onError: errorBoundary.onError
	});
	const { usersUploadSizeLimit } = useConfigs();

	const [groupFieldValue, setGroupFieldValue] = useState<GroupsFieldValue>();
	const { file, setFile, handleFileUpload, fileName } = useFileUpload();
	const { t } = useTranslation();

	const handleOnDeselectGroup = useCallback(() => {
		setFile(null);
		// eslint-disable-next-line
	}, []);

	const handleParseFile = () => {
		getBaseSystemRole();
		const reader = new FileReader();

		reader.onload = (e) => {
			try {
				if (groupFieldValue) {
					const data: Record<string, SheetData> = readFirstXLSXTab(e);

					const isColumnsValid = validateColumnNames(
						data,
						COLUMNS_TITLES_CELLS,
						COLUMNS_NAMES()
					);

					if (!isColumnsValid) {
						// case columns were rewritten by User
						Notification.error({
							description: t('invalidFile')
						});
						return;
					}
					const cleanedKeys = getCleanedKeys(data, SHEETS_NAMES);
					if (!cleanedKeys.length) {
						// case empty file
						Notification.error({
							description: t('invalidFile')
						});
						return;
					}
					const validData = cleanedKeys.reduce<
						Record<string, Record<string, string>>
					>((acc, sheet) => {
						const [cellLetter] = sheet;
						const cellNumber = sheet.slice(1);

						if (!acc[cellNumber]) {
							acc[cellNumber] = {};
						}
						const key = SHEETS_TO_NAMES[cellLetter];
						if (key && data[sheet].v) {
							acc[cellNumber] = {
								...acc[cellNumber],
								[key]: data[sheet].v
							};
						}
						return acc;
					}, {});

					const users = Object.values(validData).filter(
						(user) => !isEmpty(user)
					) as UserForValidation[];
					if (users.length > usersUploadSizeLimit) {
						Notification.error({
							description: t(`${TRANSLATION_PATH}fileLimit`, {
								fileLimit: usersUploadSizeLimit
							})
						});
						return;
					}

					const { id } = groupFieldValue[0].data;

					validateUsers({
						variables: {
							groupId: id,
							users
						}
					});
				}
			} catch (error) {
				Notification.error({
					description: t('errorCodes.genericErrorMessage')
				});
			}
		};
		if (file) {
			reader.readAsArrayBuffer(file);
		}
	};

	return (
		<Modal
			visible
			title={t(`${TRANSLATION_PATH}title`)}
			size="sm"
			onCancel={hideModal}
			onOk={handleParseFile}
			okButtonProps={{
				loading,
				disabled: isEmpty(groupFieldValue) || !file
			}}
			okText={t(`${TRANSLATION_PATH}submitText`)}
		>
			<ImportXLSXFileModalContent
				handleFileUpload={handleFileUpload}
				fileName={fileName}
				groupFieldValue={groupFieldValue}
				setGroupFieldValue={setGroupFieldValue}
				onDeselect={handleOnDeselectGroup}
			/>
		</Modal>
	);
};

export default ImportXLSXFileModal;
