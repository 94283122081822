import { useTranslation } from 'react-i18next';
import { EditableLayout, Notification, Prompt } from 'Components';
import { Form, Formik, FormikHelpers } from 'formik';
import { getAppRoutes } from 'Pages/App/App.constants';
import { schema } from '../schema';
import { useMutation } from '@apollo/client';
import { CREATE_USER } from 'Services/Api/Users/Mutations';
import { useHistory } from 'react-router';
import CreateUserFormFields from './CreateUserFormFields';
import { checkApolloError } from 'Helpers/graphql';
import { navigateBack } from 'Helpers/navigation';
import { APIErrorCodes } from 'app-types';
import { AddUserValues } from '../User.types';
import { getAddUserSubmitValues } from '../EditUser/EditUser.helpers';
import { AddUserRequest } from 'Services/Api/Users/Types';
import { useIsModuleSettingDisabled } from '../EditUser/tabs/ModuleSettings/ModuleSettingsContainer';
import {
	isModuleSettingsValidationError,
	validateLMSModuleSettings
} from '../EditUser/tabs/ModuleSettings/ModuleSettings.helpers';
import { useUser } from '../User.hooks';

const CreateUser = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { injectMeta } = useUser();
	const [createUser] = useMutation<void, AddUserRequest>(CREATE_USER, {
		ignoreResults: false
	});
	const { getIsLMSModuleSettingsDisabled } = useIsModuleSettingDisabled();
	const initialValues = {} as AddUserValues;

	const goBack = () => {
		navigateBack(history, getAppRoutes().USERS);
	};

	const onSubmit = async (
		values: AddUserValues,
		helpers: FormikHelpers<AddUserValues>
	) => {
		try {
			const isModuleSettingsDisabled = getIsLMSModuleSettingsDisabled(
				values
			);
			validateLMSModuleSettings(isModuleSettingsDisabled, values);
			await createUser({
				variables: {
					input: getAddUserSubmitValues(values)
				}
			});
			helpers.resetForm({ values });
			goBack();
			Notification.success({
				description: t('user.createUser.notification')
			});
		} catch (e) {
			const error = e as Error;
			if (isModuleSettingsValidationError(error)) {
				helpers.setErrors({
					moduleSettings: error.message
				});
				return;
			}
			helpers.setSubmitting(false);

			const errors = checkApolloError(error).getFieldErrors(
				t,
				injectMeta(schema.describe().fields),
				'user.errorCodes'
			);

			helpers.setErrors(errors ?? {});

			if (errors) return;

			const apolloError = checkApolloError(error);

			if (apolloError.is(APIErrorCodes.Forbidden)) {
				Notification.error({
					description: t(`errorCodes.${APIErrorCodes.Forbidden}`)
				});
			} else if (
				apolloError.is(APIErrorCodes.ValidationIsCognitoPhoneNumber)
			) {
				helpers.setErrors({
					phoneNumber: t(
						`user.errorCodes.${APIErrorCodes.ValidationIsCognitoPhoneNumber}`
					)
				});
			} else {
				Notification.error({
					description: t('errorCodes.genericErrorMessage')
				});
			}
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={schema}
		>
			{({ dirty, isSubmitting }) => (
				<>
					<Prompt blockTransition={dirty && !isSubmitting} />
					<Form>
						<EditableLayout
							goBack={goBack}
							heading={t('user.createUser.title')}
							okText={t('user.createUser.saveUser')}
						>
							<CreateUserFormFields />
						</EditableLayout>
					</Form>
				</>
			)}
		</Formik>
	);
};

export default CreateUser;
