import { PermissionCode } from 'Services/Api/Users/Types';
import {
	Overview,
	UserActivity,
	Phishing,
	Elearning,
	SpotCheck,
	MyResults
} from './tabs';

import { DashboardTab } from './Dashboard.types';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';

export const SAD_BOUNDARY = 50;
export const HAPPY_BOUNDARY = 80;

export const DASHBOARD_TAB = {
	[DashboardTabKey.Overview]: {
		key: DashboardTabKey.Overview,
		tab: 'dashboard.overview',
		component: Overview,
		permission: {
			code: PermissionCode.ViewDashboardOverview
		}
	},
	[DashboardTabKey.Phishing]: {
		key: DashboardTabKey.Phishing,
		tab: 'dashboard.phishing',
		component: Phishing,
		permission: {
			code: PermissionCode.ViewDashboardPhishing
		}
	},
	[DashboardTabKey.Elearning]: {
		key: DashboardTabKey.Elearning,
		tab: 'dashboard.eLearning',
		component: Elearning,
		permission: {
			code: PermissionCode.ViewDashboardElearning
		}
	},
	[DashboardTabKey.SpotCheck]: {
		key: DashboardTabKey.SpotCheck,
		tab: 'dashboard.spotCheck',
		component: SpotCheck,
		permission: {
			code: PermissionCode.ViewDashboardSpotcheck
		}
	},
	[DashboardTabKey.UserActivity]: {
		key: DashboardTabKey.UserActivity,
		tab: 'dashboard.userActivity',
		component: UserActivity,
		permission: {
			code: PermissionCode.ViewDashboardUserActivity
		}
	},
	[DashboardTabKey.MyResults]: {
		key: DashboardTabKey.MyResults,
		tab: 'dashboard.myResults',
		component: MyResults,
		permission: {
			code: PermissionCode.ViewDashboardMyResults
		},
		isExported: false
	}
};

export const DASHBOARD_TABS: DashboardTab[] = [
	DASHBOARD_TAB[DashboardTabKey.Overview],
	DASHBOARD_TAB[DashboardTabKey.Phishing],
	DASHBOARD_TAB[DashboardTabKey.Elearning],
	DASHBOARD_TAB[DashboardTabKey.SpotCheck],
	DASHBOARD_TAB[DashboardTabKey.UserActivity],
	DASHBOARD_TAB[DashboardTabKey.MyResults]
];
