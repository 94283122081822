import { Select, InactiveHierarchyModalContent } from 'Components';
import { SelectProps } from 'Components/Form/Select/Select';
import { useFormikContext } from 'formik';
import ModalContextProvider, { useModal } from 'Hooks/useModal';
import { AddUserValues } from 'Pages/User/User.types';
import { useTranslation } from 'react-i18next';
import { GroupActiveStatus } from 'Services/Api/Groups/Types';
import OwnershipGroupModal, { FieldValue } from './OwnershipGroupModal';
import { ShowInactiveModal } from 'Components/InactiveHierarchyModalContent/InactiveHierarchyModalContent.types';

type OwnershipGroupFieldProps = Pick<SelectProps, 'disabled'>;

type OwnershipGroupFieldPropsWithInactive = ShowInactiveModal &
	OwnershipGroupFieldProps;

function OwnershipGroupFieldContent(
	props: OwnershipGroupFieldPropsWithInactive
) {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext<AddUserValues>();
	const { modal, showModal, hideModal } = useModal();

	const onClick: React.MouseEventHandler<HTMLDivElement> = () => {
		if (props.disabled) return;
		showModal({ value: null });
	};

	const onSubmit = async (value: FieldValue) => {
		const { showInactiveModal } = props;
		const firstItem = value?.[0];

		if (firstItem?.data?.status === GroupActiveStatus.Inactive) {
			await showInactiveModal();
		}

		const newValue: AddUserValues['ownershipGroup'] = firstItem
			? {
					key: firstItem.key as number,
					value: firstItem.key,
					label: firstItem.title
			  }
			: undefined;
		setFieldValue('ownershipGroup', newValue);
		setTimeout(() => {
			hideModal();
		});
	};

	const onChange = (value: AddUserValues['ownershipGroup']) => {
		onSubmit(value ? [value] : undefined);
	};

	return (
		<>
			<Select.FormikField
				name="ownershipGroup"
				label={t('user.ownershipGroup')}
				required
				labelInValue
				onClick={onClick}
				// @ts-ignore
				onChange={onChange}
				open={false}
				help={modal ? null : undefined}
				disabled={props.disabled}
			/>
			{modal && (
				<OwnershipGroupModal
					initialValue={values.ownershipGroup}
					onSubmit={onSubmit}
				/>
			)}
		</>
	);
}

function OwnershipGroupField(props: OwnershipGroupFieldPropsWithInactive) {
	return (
		<ModalContextProvider>
			<OwnershipGroupFieldContent {...props} />
		</ModalContextProvider>
	);
}

function OwnershipGroupFieldWithInactive(props: OwnershipGroupFieldProps) {
	return (
		<InactiveHierarchyModalContent>
			{/* @NOTE: showInactiveModal={undefined as never} need for correct ts functionality */}
			<OwnershipGroupField
				{...props}
				showInactiveModal={undefined as never}
			/>
		</InactiveHierarchyModalContent>
	);
}

export default OwnershipGroupFieldWithInactive;
