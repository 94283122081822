import StatusLogoutTracker from 'Pages/Auth/StatusLogout/StatusLogoutTracker';
import IdleLogoutTracker from 'Pages/Auth/IdleLogout/IdleLogoutTracker';
import ErrorBoundary from 'Contexts/ErrorBoundaryContext/ErrorBoundaryContext';
import AppLayout from './AppLayout/AppLayout';

interface AppWithErrorBoundaryAndLogoutTrackerProps {
	includeLayout?: boolean;
	blockNavigation?: boolean;
}

export const AppWithErrorBoundaryAndLogoutTracker: React.FC<AppWithErrorBoundaryAndLogoutTrackerProps> = ({
	children,
	includeLayout,
	blockNavigation
}) => {
	return (
		<>
			{process.env.NODE_ENV === 'development' ? null : (
				<IdleLogoutTracker />
			)}
			<StatusLogoutTracker />
			<ErrorBoundary>
				{includeLayout ? (
					<AppLayout blockNavigation={blockNavigation}>
						{children}
					</AppLayout>
				) : (
					children
				)}
			</ErrorBoundary>
		</>
	);
};
