import { Fx, Card, Row, Col, Text, Divider } from 'Components';
import { useStores } from 'Hooks/useStore';
import { DashboardProgress, InfoTooltip } from 'Pages/Dashboard/components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { getIsPending } from 'Stores/util';
import { lowerCase } from 'lodash';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';

const TotalCampaignScore = () => {
	const { t } = useTranslation();
	const { filters } = useDashboardContext();
	const { dashboardStore } = useStores();

	const {
		phishing: { score },
		status
	} = dashboardStore;

	useEffect(() => {
		dashboardStore.getPhishingTotalCampaignScore(filters);
		// eslint-disable-next-line
	}, [filters]);

	return (
		<Card
			loading={getIsPending(status.getPhishingTotalCampaignScore)}
			title={t('dashboard.tabs.phishing.totalCampaignScore.title')}
			extra={
				<Fx align="middle">
					<InfoTooltip
						title={t(
							'dashboard.tabs.phishing.totalCampaignScore.tooltip'
						)}
					/>
				</Fx>
			}
			footer={<>&nbsp;</>}
		>
			<Row fullHeight>
				<Col span={7}>
					<Fx align="middle" justify="center" fullHeight>
						<DashboardProgress percent={score?.previous}>
							{t('dashboard.timePeriod.previous')}
						</DashboardProgress>
					</Fx>
				</Col>
				<Col span={7}>
					<Fx align="middle" justify="center" fullHeight>
						<DashboardProgress percent={score?.current}>
							{t('dashboard.timePeriod.current')}
						</DashboardProgress>
					</Fx>
				</Col>
				<Col span={3}>
					<Fx align="stretch" justify="center" fullHeight>
						<Divider type="vertical" />
					</Fx>
				</Col>
				<Col span={7}>
					<Fx align="middle" justify="center" fullHeight>
						<DashboardProgress percent={score?.average}>
							<Text>
								{t(
									'dashboard.tabs.phishing.totalCampaignScore.average'
								)}
							</Text>
							&nbsp;(
							{lowerCase(t('dashboard.timePeriod.current'))})
						</DashboardProgress>
					</Fx>
				</Col>
			</Row>
		</Card>
	);
};

export default observer(TotalCampaignScore);
