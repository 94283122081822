import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { APP_CONFIG } from 'app-config';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { ReportRecurringFrequency } from 'Services/Api/Reports/Types';
import { ReportFormValues } from '../schema';

export const useRecurrence = () => {
	const {
		initialValues,
		values,
		setFieldValue,
		setFieldTouched
	} = useFormikContext<ReportFormValues>();

	const handleIsRecurringChange = (ev: unknown) => {
		if (!(ev as CheckboxChangeEvent).target.checked) {
			const fields: (keyof ReportFormValues)[] = [
				'scheduleFrequency',
				'emailMeReport',
				'emailsList'
			];

			// reset fields to initial state if unchecked
			fields.forEach((field) => {
				setFieldValue(field, initialValues[field]);
				setFieldTouched(field, false);
			});
		}
	};

	const handleScheduleFrequencyTypeChange = (value: unknown) => {
		// define initial values
		setFieldValue(
			'scheduleFrequency.time',
			moment(APP_CONFIG.DEFAULT_TIME, APP_CONFIG.TIME_FORMAT),
			false
		);
		setFieldValue(
			'scheduleFrequency.frequency',
			(value as ReportRecurringFrequency) ===
				ReportRecurringFrequency.Quarterly
				? 1
				: undefined,
			false
		);

		setFieldValue('scheduleFrequency.type', value, true);
	};

	return {
		values,
		handleIsRecurringChange,
		handleScheduleFrequencyTypeChange
	};
};
