import { useTranslation } from 'react-i18next';

import { Button, Icon, Tooltip, Space } from 'Components';
import { getAppRoutes } from 'Pages/App/App.constants';
import { generatePath, useHistory } from 'react-router-dom';
import {
	OwnershipGroupActionsModalsProps,
	OwnershipGroupActionsModalName
} from './modals';
import { useModal } from 'Hooks/useModal';
import OwnershipGroupActionsModals from './OwnershipGroupActionsModals';
import { PermissionCode } from 'Services/Api/Users/Types';
import { usePermission } from 'Components/Permission/Permission.hooks';
import OwnershipGroupExportButton, {
	OwnershipGroupExportButtonProps
} from './components/OwnershipGroupExportButton';

type OwnershipGroupActionsProps = OwnershipGroupActionsModalsProps &
	OwnershipGroupExportButtonProps & {
		hasData: boolean;
	};
function OwnershipGroupActions(props: OwnershipGroupActionsProps) {
	const { ids } = props;
	const { t } = useTranslation();
	const permission = usePermission();
	const history = useHistory();
	const { showModal } = useModal();

	const onAddNewClick = () => {
		history.push(generatePath(getAppRoutes().ADD_OWNERSHIP_GROUP));
	};
	const onRemoveClick = () => {
		showModal({ name: OwnershipGroupActionsModalName.Remove, value: null });
	};
	const isActionDisabled = !ids.length;
	const {
		permittedByCode: {
			[PermissionCode.AddOwnershipGroup]: isAddPermitted,
			[PermissionCode.EditOwnershipGroup]: isEditPermitted,
			[PermissionCode.ExportOwnershipGroups]: isExportPermitted
		}
	} = permission.getHasPermission({
		code: [
			PermissionCode.AddOwnershipGroup,
			PermissionCode.EditOwnershipGroup,
			PermissionCode.ExportOwnershipGroups
		]
	});
	return (
		<>
			<OwnershipGroupActionsModals {...props} />
			<Space wrap align="end">
				{isEditPermitted && (
					<Tooltip
						title={t(
							'ownershipGroups.ownershipGroup.ownershipGroupActions.removeDisabledTooltip'
						)}
						enabled={isActionDisabled}
					>
						<Button
							type="primary"
							shape="round"
							ghost
							disabled={isActionDisabled}
							onClick={onRemoveClick}
						>
							{t('remove')}
						</Button>
					</Tooltip>
				)}

				{isAddPermitted && (
					<Button
						type="primary"
						shape="round"
						icon={<Icon type="plus" />}
						onClick={onAddNewClick}
					>
						{t(
							'ownershipGroups.ownershipGroup.ownershipGroupActions.add'
						)}
					</Button>
				)}
				{isExportPermitted && (
					<OwnershipGroupExportButton
						params={props.params}
						disabled={!props.hasData}
					/>
				)}
			</Space>
		</>
	);
}

export default OwnershipGroupActions;
