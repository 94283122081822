import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Formik, FormikHelpers } from 'formik';

import { EditableLayout, ErrorScreen, Notification, Spin } from 'Components';
import { getAppRoutes } from 'Pages/App/App.constants';
import { EditRoleFormValues } from './EditRole.types';
import EditRoleTabs from './EditRoleTabs';
import { schema } from './schema';
import { useRoleForm } from './EditRole.hooks';
import { useMutation } from '@apollo/client';
import {
	EditSystemRoleRequest,
	EnableMFAForUserRoleRequest
} from 'Services/Api/Roles/Types';
import {
	EDIT_SYSTEM_ROLE,
	ENABLE_MFA_FOR_USER_ROLE
} from 'Services/Api/Roles/Mutations';
import { checkApolloError } from 'Helpers/graphql';
import { navigateBack } from 'Helpers/navigation';
import { getSubmitValues } from './EditRole.helpers';

function EditRole() {
	const { t } = useTranslation();

	const history = useHistory();
	const [editSystemRole] = useMutation<unknown, EditSystemRoleRequest>(
		EDIT_SYSTEM_ROLE,
		{
			ignoreResults: false
		}
	);
	const { initialValues, roleId, isNew } = useRoleForm();
	const [enableMFAForUserRole] = useMutation<
		unknown,
		EnableMFAForUserRoleRequest
	>(ENABLE_MFA_FOR_USER_ROLE);

	const goBack = () => {
		history.push(getAppRoutes().ROLES);
	};

	const onSubmit = async (
		values: EditRoleFormValues,
		helpers: FormikHelpers<EditRoleFormValues>
	) => {
		try {
			await editSystemRole({
				variables: {
					input: getSubmitValues(values)
				}
			});

			if (
				values.isPrimary &&
				!initialValues?.twoFactorAuthorizationEnabled &&
				values.twoFactorAuthorizationEnabled
			) {
				await enableMFAForUserRole({
					variables: {
						input: {
							roleId: values.id
						}
					}
				});
			}

			helpers.resetForm({ values });
			navigateBack(history, getAppRoutes().ROLES);
			Notification.success({
				description: t('notification.edit.success')
			});
		} catch (e) {
			const errors = checkApolloError(e).getFieldErrors(
				t,
				schema.describe().fields,
				'role.addRole.errorCodes'
			);
			if (errors) {
				helpers.setErrors(errors);
			} else {
				Notification.error({
					description: t('errorCodes.genericErrorMessage')
				});
			}
		}
	};

	if (!initialValues) {
		return <Spin />;
	}

	if (!isNew && !roleId) {
		return <ErrorScreen.NotFound />;
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={schema}
		>
			<EditableLayout.Tab
				goBack={goBack}
				heading={t('role.editRole.title', { name: initialValues.role })}
			>
				<EditRoleTabs />
			</EditableLayout.Tab>
		</Formik>
	);
}

export default EditRole;
