import { Modal } from 'Components';
import { useTranslation } from 'react-i18next';
import ModalContextProvider, { useModal } from 'Hooks/useModal';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router';

/* eslint-disable @typescript-eslint/no-explicit-any */
type Value = {
	value?: any;
	onOk: (value: any) => void;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export interface PromptHelpers {
	isBlocked: boolean;
	setBlockTransition: (block: boolean) => void;
	prompt: (value: Value) => void;
}
export interface PromptProps {
	blockTransition?: boolean;
	children?: (helpers: PromptHelpers) => React.ReactNode;
}

const Prompt: React.FC<PromptProps> = ({ blockTransition, children }) => {
	const { t } = useTranslation();
	const history = useHistory();

	const shouldBlockRef = useRef(false);
	const [block, setBlock] = useState(false);
	const { modal, showModal, hideModal } = useModal();

	const prompt = useCallback(
		(value: Value) => {
			showModal({ value });
		},
		[showModal]
	);

	const onOK = () => {
		if (!modal) {
			return;
		}
		modal.value.onOk(modal.value.value);
		hideModal();
	};

	useEffect(() => {
		shouldBlockRef.current = blockTransition || block;
	}, [blockTransition, block]);

	useEffect(() => {
		window.unblock = history.block((location, action) => {
			if (shouldBlockRef.current) {
				showModal({
					value: {
						onOk: () => {
							window.unblock?.();
							shouldBlockRef.current = false;
							const path = `${location.pathname}${location.search}`;
							switch (action) {
								case 'PUSH':
									history.push(path);
									break;
								case 'REPLACE':
									history.replace(path);
									break;
								case 'POP':
									history.goBack();
									break;
							}
						}
					}
				});
				return false;
			}
		});

		return () => {
			window.unblock?.();
		};
	}, [history, showModal]);

	return (
		<>
			{children?.({
				prompt,
				isBlocked: block,
				setBlockTransition: setBlock
			})}
			{modal && (
				<Modal
					visible
					title={t('Components.prompt.title')}
					onOk={onOK}
					onCancel={hideModal}
					okText={t('Components.prompt.okText')}
				>
					{t('Components.prompt.description')}
				</Modal>
			)}
		</>
	);
};

const PromptContainer = (props: PromptProps) => (
	<ModalContextProvider>
		<Prompt {...props} />
	</ModalContextProvider>
);

export default PromptContainer;
