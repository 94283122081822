import { ColumnProps } from 'antd/lib/table';
import {
	useCreateColumns,
	ColumnFilters,
	UseCreateColumnsInterface
} from 'Components/Table/createColumns';
import { AuditLog, GetAuditLogsRequest } from 'Services/Api/AuditLog/Types';
import { DateFormatted, DatePicker, OptionalValue } from 'Components';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import moment from 'moment';
import { APP_CONFIG } from 'app-config';
import { useAuditLogColumnFilters } from './AuditLog.hooks';
import { useTranslation } from 'react-i18next';

type FilterValues = Omit<GetAuditLogsRequest['filters'], 'date'> & {
	date?: [string, string];
};
export type TableFilterValues = ColumnFilters<FilterValues>;

export function useColumnProps<T extends AuditLog>(
	helpers: ReturnType<UseCreateColumnsInterface<T, FilterValues>>
) {
	const { t } = useTranslation();
	const { pages, actions } = useAuditLogColumnFilters();
	const columns: Array<ColumnProps<T>> = [
		{
			...helpers.sort,
			...helpers.filter,
			sortOrder: helpers?.getSortOrder('date'),
			title: t('auditLog.columns.date'),
			key: 'date',
			dataIndex: 'date',
			filteredValue: helpers?.filters.date,
			width: '12%',
			render: (value) => <DateFormatted date={value} />,
			filterDropdown: (props: FilterDropdownProps) => {
				const selectedKeys = props.selectedKeys as [string, string];
				const currentValue = selectedKeys.map((date) =>
					moment(date, APP_CONFIG.DATE_TIME_FORMAT)
				) as [moment.Moment, moment.Moment];
				return (
					<DatePicker.RangePicker
						value={currentValue}
						onChange={(e) => {
							const value = e as
								| [moment.Moment, moment.Moment]
								| null;
							props.setSelectedKeys(
								value === null
									? []
									: [
											value[0]
												.startOf('D')
												.format(
													APP_CONFIG.DATE_TIME_FORMAT
												),
											value[1]
												.endOf('D')
												.format(
													APP_CONFIG.DATE_TIME_FORMAT
												)
									  ]
							);
							props.confirm?.({ closeDropdown: false });
						}}
					/>
				);
			}
		},
		{
			title: t('auditLog.columns.id'),
			key: 'id',
			dataIndex: 'id',
			width: '10%'
		},
		{
			...helpers.sort,
			...helpers.search,
			sortOrder: helpers?.getSortOrder('tenant'),
			title: t('auditLog.columns.tenant'),
			key: 'tenant',
			dataIndex: 'tenant',
			filteredValue: helpers?.filters.tenant,
			width: '10%',
			render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			...helpers.sort,
			...helpers.search,
			sortOrder: helpers?.getSortOrder('login'),
			title: t('auditLog.columns.login'),
			key: 'login',
			dataIndex: 'login',
			filteredValue: helpers?.filters.login,
			width: '10%'
		},
		{
			...helpers.sort,
			...helpers.search,
			sortOrder: helpers?.getSortOrder('ip'),
			title: t('auditLog.columns.ip'),
			key: 'ip',
			dataIndex: 'ip',
			filteredValue: helpers?.filters.ip,
			width: '10%',
			render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			...helpers.sort,
			...helpers?.filter,
			sortOrder: helpers?.getSortOrder('page'),
			title: t('auditLog.columns.page'),
			key: 'page',
			dataIndex: 'page',
			filteredValue: helpers?.filters.page,
			width: '13%',
			filters: pages,
			render: (_, record) => t(`auditLog.pages.${record.page}`)
		},
		{
			...helpers.sort,
			...helpers?.filter,
			sortOrder: helpers?.getSortOrder('action'),
			title: t('auditLog.columns.action'),
			key: 'action',
			dataIndex: 'action',
			filteredValue: helpers?.filters.action,
			width: '12%',
			filters: actions,
			render: (_, record) => t(`auditLog.actions.${record.action}`)
		},
		{
			...helpers.sort,
			...helpers.search,
			sortOrder: helpers?.getSortOrder('browser'),
			title: t('auditLog.columns.browser'),
			key: 'browser',
			dataIndex: 'browser',
			filteredValue: helpers?.filters.browser,
			width: '11%',
			render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			...helpers.sort,
			...helpers.search,
			sortOrder: helpers?.getSortOrder('modifyBy'),
			title: t('auditLog.columns.modifiedBy'),
			key: 'modifyBy',
			dataIndex: 'modifyBy',
			filteredValue: helpers?.filters.modifyBy,
			width: '12%'
		}
	];
	return columns;
}

const useColumns = <T extends AuditLog>({
	params
}: {
	params: GetAuditLogsRequest | undefined;
}) => {
	const filters: FilterValues = {
		...params?.filters,
		date: params?.filters.date
			? [params.filters.date.from, params.filters.date.to]
			: undefined
	};
	const helpers = useCreateColumns<T, FilterValues>({
		filterValues: filters,
		sortDesc: params?.sort
	});
	return useColumnProps(helpers);
};

export default useColumns;
