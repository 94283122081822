import { ExportButton } from 'Components';
import { ExportButtonProps } from 'Components/ExportButton/ExportButton';
import { EXPORT_AUDIT_LOGS } from 'Services/Api/AuditLog/Queries';
import {
	ExportAuditLogsRequest,
	ExportAuditLogsResponse
} from 'Services/Api/AuditLog/Types';

export type ExportAuditLogButtonProps = Omit<
	ExportButtonProps<ExportAuditLogsResponse, ExportAuditLogsRequest>,
	'query' | 'getQueryUrl'
>;
function ExportAuditLogButton(props: ExportAuditLogButtonProps) {
	const getQueryUrl = (data: ExportAuditLogsResponse) => {
		return data.exportAuditLogs.downloadUrl;
	};
	return (
		<ExportButton<ExportAuditLogsResponse, ExportAuditLogsRequest>
			query={EXPORT_AUDIT_LOGS}
			getQueryUrl={getQueryUrl}
			{...props}
		/>
	);
}

export default ExportAuditLogButton;
