import { ElearningWidgetParams } from 'Pages/Dashboard/tabs/Elearning/Elearning.types';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';
import { Widgets } from '../../Dashboard.types';
import { ElearningWidgets } from '../../tabs/Elearning/Elearning.types';
import { SpotCheckWidgets } from '../../tabs/SpotCheck/SpotCheck.types';
import { LineTimeInterval } from 'Pages/Dashboard/visualizations/LineChart/LineChart.types';

interface SpotCheckWidgetParams {
	period: string;
}

interface ElearningTabHistoricalCompletedCoursesWidget {
	timeInterval: LineTimeInterval;
	periods: string[];
}

export type AdaptWidgetsParamsOutput =
	| SpotCheckWidgetParams
	| Record<string, never>
	| ElearningTabHistoricalCompletedCoursesWidget;

export function adaptWidgetsParams<WP = string>(
	tab: DashboardTabKey,
	widget: Widgets,
	widgetParams?: WP
): AdaptWidgetsParamsOutput {
	if (!widgetParams) {
		return {};
	}
	if (
		tab === DashboardTabKey.SpotCheck &&
		widget === SpotCheckWidgets.SpotCHeckOverview
	) {
		return {
			period: ((widgetParams as unknown) as string).replace(
				'spotCheck:',
				''
			)
		};
	}

	if (
		tab === DashboardTabKey.Elearning &&
		widget === ElearningWidgets.HistoricalCompletedCourses
	) {
		const historicalCompleteCourseWidgets = (widgetParams as unknown) as ElearningWidgetParams;
		return {
			timeInterval: historicalCompleteCourseWidgets.timeInterval,
			periods: Array.isArray(historicalCompleteCourseWidgets.checkedKeys)
				? historicalCompleteCourseWidgets.checkedKeys.map(String)
				: []
		};
	}

	return {};
}
