import { USER_ACTIVITY_LABEL } from 'Pages/Dashboard/tabs/UserActivity/UserActivity.constants';
import { UserActivityTitle } from 'Pages/Dashboard/tabs/UserActivity/UserActivity';
import { UserActivityTab } from 'Services/Api/Reports/Types';

const UserActivityTitleTotal = () => (
	<UserActivityTitle title={USER_ACTIVITY_LABEL.total} />
);

UserActivityTitleTotal.displayName = UserActivityTab.Total;

const UserActivityTitleLogged = () => (
	<UserActivityTitle title={USER_ACTIVITY_LABEL.logged} />
);

UserActivityTitleLogged.displayName = UserActivityTab.Logged;

const UserActivityTitleLockedOut = () => (
	<UserActivityTitle title={USER_ACTIVITY_LABEL.lockedOut} />
);

UserActivityTitleLockedOut.displayName = UserActivityTab.LockedOut;

const UserActivityTitleCreated = () => (
	<UserActivityTitle title={USER_ACTIVITY_LABEL.created} />
);

UserActivityTitleCreated.displayName = UserActivityTab.Created;

const UserActivityTitleDeactivated = () => (
	<UserActivityTitle title={USER_ACTIVITY_LABEL.deactivated} />
);

UserActivityTitleDeactivated.displayName = UserActivityTab.Deactivated;

const UserActivityTitleDeleted = () => (
	<UserActivityTitle title={USER_ACTIVITY_LABEL.deleted} />
);
UserActivityTitleDeleted.displayName = UserActivityTab.Deleted;

export default function getTabs() {
	return [
		UserActivityTitleTotal,
		UserActivityTitleLogged,
		UserActivityTitleLockedOut,
		UserActivityTitleCreated,
		UserActivityTitleDeactivated,
		UserActivityTitleDeleted
	];
}
