import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Fx, InputSearchToggle, Layout, Table } from 'Components';
import GroupsDropdownMenu, {
	GroupDropdownMenuKey
} from 'Pages/Groups/GroupsDropdownMenu';
import { DeletedGroup } from 'Services/Api/Groups/Types';
import { getIsRequestParamsFiltered } from 'Helpers/table';
import { useDeletedGroups } from './DeletedGroups.hooks';
import DeletedGroupsActions from './DeletedGroupsActions';

function DeletedGroups() {
	const { t } = useTranslation();
	const {
		result,
		dataSource,
		columns,
		onTableChange,
		selectedRowKeys,
		setSelectedRowKeys,
		refetch,
		onSearchChange
	} = useDeletedGroups();

	const groupNamesBySelectedRowKeys = useMemo(() => {
		return !dataSource
			? []
			: dataSource.reduce<string[]>((acc, value) => {
					if (selectedRowKeys.includes(value.id)) {
						acc.push(value.name);
					}
					return acc;
			  }, []);
	}, [dataSource, selectedRowKeys]);

	const onControlSubmit = () => {
		setSelectedRowKeys([]);
		refetch();
	};
	const isDataSourceFiltered = getIsRequestParamsFiltered({
		...result.variables?.filters,
		search: result.variables?.search
	});
	return (
		<Fx column fullHeight>
			<Layout.Heading
				prefixNode={
					<GroupsDropdownMenu value={GroupDropdownMenuKey.Deleted} />
				}
			>
				{({ searchFieldClassName }) => (
					<Fx justify="space-between" align="middle">
						<div className={searchFieldClassName}>
							<InputSearchToggle
								value={result.variables?.search}
								onChange={onSearchChange}
							/>
						</div>

						<Fx>
							<DeletedGroupsActions
								ids={selectedRowKeys as string[]}
								idsNames={groupNamesBySelectedRowKeys}
								onSubmit={onControlSubmit}
								hasData={Boolean(dataSource?.length)}
								params={result.variables}
							/>
						</Fx>
					</Fx>
				)}
			</Layout.Heading>
			<Table<DeletedGroup>
				dataSource={dataSource}
				rowKey="id"
				columns={columns}
				loading={result.loading}
				pagination={false}
				onChange={onTableChange}
				totalVisible
				locale={{
					emptyText: isDataSourceFiltered ? undefined : (
						<>
							<h1>{t('deletedGroups.emptyText.title')}</h1>
							<h3>{t('deletedGroups.emptyText.description')}</h3>
						</>
					)
				}}
				rowSelection={{
					selectedRowKeys,
					onChange: setSelectedRowKeys
				}}
			/>
		</Fx>
	);
}

export default DeletedGroups;
