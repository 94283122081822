import { useTranslation } from 'react-i18next';
import { Modal, Notification } from 'Components';
import { useModal } from 'Hooks/useModal';
import { useMutation } from '@apollo/client';
import { DeleteRolesRequest } from 'Services/Api/Roles/Types';
import { DELETE_ROLES } from 'Services/Api/Roles/Mutations';
import { APIErrorCodes } from 'app-types';
import { capitalize } from 'lodash';
import { checkApolloError } from 'Helpers/graphql';
import { DeleteRoleModalProps } from './types';
import TInfoText from 'Contexts/TInfoText/TInfoText';

function DeleteRoleModal({ ids, idsNames, onSubmit }: DeleteRoleModalProps) {
	const { t } = useTranslation();
	const { hideModal } = useModal();

	const [deleteRoles, result] = useMutation<unknown, DeleteRolesRequest>(
		DELETE_ROLES
	);

	const isSingleSelected = ids.length === 1;
	const onDeleteSubmit = async () => {
		try {
			await deleteRoles({
				variables: {
					input: {
						ids
					}
				}
			});
			onSubmit();
			hideModal();
			const description = t(
				`roles.rolesActionsModals.deleteModal.notificationSuccess.${
					isSingleSelected ? 'single' : 'multiple'
				}`
			);
			Notification.success({ description });
		} catch (error) {
			let description = t('errorCodes.genericErrorMessage');
			const apolloError = checkApolloError(error);

			if (apolloError.isApolloError()) {
				const roles = apolloError.getMeta<string[]>();
				const isSingle = roles.length === 1;
				if (apolloError.is(APIErrorCodes.RoleCannotBeDeleted)) {
					description = t(
						`roles.rolesActionsModals.deleteModal.notificationError.roleCannotBeDeleted.${
							isSingle ? 'single' : 'multiple'
						}`,
						{
							roles: isSingle
								? capitalize(roles[0])
								: roles.map(capitalize).join(', ')
						}
					);
				} else if (
					apolloError.is(APIErrorCodes.RoleWithUsersCannotBeDeleted)
				) {
					description = t(
						`roles.rolesActionsModals.deleteModal.notificationError.roleWithUsersCannotBeDeleted.${
							isSingle ? 'single' : 'multiple'
						}`,
						{
							roles: isSingle ? roles[0] : roles.join(', ')
						}
					);
				} else if (
					apolloError.is(
						APIErrorCodes.RoleWithDeletedUsersCannotBeDeleted
					)
				) {
					description = t(
						`roles.rolesActionsModals.deleteModal.notificationError.roleWithDeletedUsersCannotBeDeleted.${
							isSingle ? 'single' : 'multiple'
						}`,
						{
							roles: isSingle ? roles[0] : roles.join(', ')
						}
					);
				}
			}

			hideModal();
			Notification.error({ description });
		}
	};

	const title = isSingleSelected
		? {
				key: 'roles.rolesActionsModals.deleteModal.title.single',
				params: { name: idsNames[0] }
		  }
		: {
				key: 'roles.rolesActionsModals.deleteModal.title.multiple',
				params: {}
		  };
	const description = t(
		`roles.rolesActionsModals.deleteModal.description.${
			isSingleSelected ? 'single' : 'multiple'
		}`
	);

	return (
		<Modal
			visible
			title={<TInfoText {...title} path={title.key} />}
			okText={t('delete')}
			okButtonProps={{
				loading: result.loading
			}}
			onOk={onDeleteSubmit}
			onCancel={hideModal}
			maskClosable={false}
		>
			{description}
		</Modal>
	);
}

export default DeleteRoleModal;
