import { Layout, OptionalValue, Radio } from 'Components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportTimePeriod } from 'Services/Api/Reports/Types';
import { REPORT_TIME_PERIOD_HIDDEN } from '../EditReport.constants';
import { ReportFormValues } from '../schema';
import Historical from './Historical';

function TimePeriod() {
	const { t } = useTranslation();
	const { initialValues, values } = useFormikContext<ReportFormValues>();

	const isTimePeriodAvailable = !REPORT_TIME_PERIOD_HIDDEN.includes(
		initialValues.reportType
	);

	return (
		<Layout.List.ListItem
			wrapInBody={false}
			title={t('report.sections.timePeriod')}
		>
			{isTimePeriodAvailable ? (
				<>
					<Radio.Group.FormikField
						name="timePeriodType"
						options={[
							{
								label: t('report.timePeriodTypes.current'),
								value: ReportTimePeriod.Current
							},
							{
								label: t('report.timePeriodTypes.historical'),
								value: ReportTimePeriod.Historical,
								disabled: REPORT_TIME_PERIOD_HIDDEN.includes(
									initialValues.reportType
								)
							}
						]}
						vertical
					/>
					{values.timePeriodType === ReportTimePeriod.Historical && (
						<Historical />
					)}
				</>
			) : (
				<OptionalValue />
			)}
		</Layout.List.ListItem>
	);
}

export default TimePeriod;
