import React, { useState } from 'react';

// eslint-disable-next-line
export interface ModalType<T = any, V = any> {
	name?: T;
	value: V;
}
export const ModalContext = React.createContext<{
	modal: ModalType | null;
	showModal: (value: ModalType) => void;
	hideModal: () => void;
}>({
	modal: null,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	showModal: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	hideModal: () => {}
});

export const useModal = () => React.useContext(ModalContext);

const ModalContextProvider: React.FC = (props) => {
	const [modal, setModal] = useState<ModalType | null>(null);

	const showModal = (value: ModalType) => {
		setModal(value);
	};

	const hideModal = () => {
		setModal(null);
	};

	return (
		<ModalContext.Provider
			value={{
				modal,
				showModal,
				hideModal
			}}
		>
			{props.children}
		</ModalContext.Provider>
	);
};

export default ModalContextProvider;
