import * as yup from 'yup';
import { VALIDATION as AUTH_VALIDATION } from '../AuthValidation';
import { VALIDATION } from 'Helpers/validations';

export const loginSchema = yup.object().shape({
	login: AUTH_VALIDATION.login,
	password: AUTH_VALIDATION.password
});

export const firstLoginScreenSchema = yup.object().shape({
	login: AUTH_VALIDATION.login,
	password: AUTH_VALIDATION.temporaryPassword
});

export const termsAndConditionsSchema = yup.object().shape({
	phoneNumber: VALIDATION.phone.label('user.phoneNumber'),
	termsOfUse: yup.boolean().oneOf([true]).required(),
	privacyPolicy: yup.boolean().oneOf([true]).required()
});
