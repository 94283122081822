import { Group, GroupActiveStatus } from 'Services/Api/Groups/Types';
import { GroupModalValue } from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupModal';

export function isGroupInactiveOrArchived(group: Group): boolean {
	return group.active === GroupActiveStatus.Inactive || group.isArchived;
}

export function isGroupOrHierarchyIsInactiveOrArchived(
	values: GroupModalValue
): boolean {
	if (!values || !values?.length) return false;

	const userInactiveGroups = values.filter((value) =>
		isGroupInactiveOrArchived(value?.data as Group)
	);

	if (userInactiveGroups.length) {
		return true;
	}
	const userInactiveHierarchy = values.filter((value) =>
		value.parents?.some(
			(parent) => parent?.data && isGroupInactiveOrArchived(parent.data)
		)
	);
	if (userInactiveHierarchy.length) {
		return true;
	}

	return false;
}
