import { useModal } from 'Hooks/useModal';
import {
	MoveGroupModal,
	DeleteGroupModal,
	GroupModalsProps,
	GroupModalsName,
	ResetPasswordGroupModal
} from './modals';

function GroupModals(props: GroupModalsProps) {
	const { modal } = useModal();

	switch (modal?.name) {
		case GroupModalsName.Move:
			return <MoveGroupModal {...props} />;
		case GroupModalsName.Delete:
			return <DeleteGroupModal {...props} />;
		case GroupModalsName.ResetPassword:
			return <ResetPasswordGroupModal {...props} />;
		default:
			return null;
	}
}

export default GroupModals;
