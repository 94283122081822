import * as yup from 'yup';
import { MessageParams } from 'yup/lib/types';

export enum ValidationTestName {
	PasswordIncludesCharacters = 'PasswordIncludesCharacters',
	Min = 'min',
	Max = 'max',
	OneOf = 'oneOf',
	IncludesLowerCase = 'IncludesLowerCase',
	IncludesUpperCase = 'IncludesUpperCase',
	IncludesSpecialChar = 'IncludesSpecialChar',
	IncludesInterger = 'IncludesInterger'
}

type CompositionError<T, I> = Omit<yup.ValidationError, 'params'> & {
	type: T;
	params: {
		info: I;
	};
};

export interface ValidationErrorByTestName {
	[ValidationTestName.PasswordIncludesCharacters]: ValidationErrorPasswordIncludesCharacters;
}

export type ValidationErrorPasswordIncludesCharacters = CompositionError<
	ValidationTestName.PasswordIncludesCharacters,
	{
		isValid: boolean;
		info: Record<
			| ValidationTestName.IncludesLowerCase
			| ValidationTestName.IncludesUpperCase
			| ValidationTestName.IncludesSpecialChar
			| ValidationTestName.IncludesInterger,
			boolean
		>;
	}['info']
>;

export type CustomValidationError =
	| ValidationErrorPasswordIncludesCharacters
	| yup.ValidationError;

export type ValidationMessageParams = Partial<
	MessageParams & {
		min: number;
		max: number;
		index: number;
	}
>;
