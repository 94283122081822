import cn from 'classnames';
import { Select as AntSelect } from 'antd';
import { SelectProps as AntSelectProps } from 'antd/lib/select';
import { debounce } from 'lodash';
import { useState, useRef, useEffect } from 'react';
import {
	createFormikField,
	createHookFormField,
	FormikFieldMapProps
} from 'Helpers/components';
import styles from './Select.module.scss';
import Icon from 'Components/Icon/Icon';
import { Translation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectProps = AntSelectProps<any>;

type SelectInterface = React.FC<SelectProps> & {
	Option: typeof AntSelect.Option;
	FormikField: typeof FormikField;
	ScrollHandled: typeof ScrollHandled;
	HookFormSelect: typeof HookFormSelect;
};
const Select: SelectInterface = (props) => {
	const suffixIcon =
		props.suffixIcon ??
		(props.loading ? undefined : <Icon type="chevronDown" />);
	return (
		<AntSelect
			{...props}
			placeholder={
				props.placeholder ?? (
					<Translation>{(t) => t('select')}</Translation>
				)
			}
			className={cn(props.className, styles.root)}
			dropdownClassName={styles.dropdown}
			getPopupContainer={
				props.getPopupContainer || ((trigger) => trigger.parentNode)
			}
			clearIcon={<Icon type="close" />}
			removeIcon={<Icon type="close" />}
			suffixIcon={suffixIcon}
		/>
	);
};

type ScrollHandledProps = SelectProps;

function getPopupContainer() {
	return document.body;
}

const ScrollHandled: React.FC<ScrollHandledProps> = (props) => {
	const dropDownRef = useRef<HTMLDivElement | null>(null);
	const [open, setOpen] = useState<boolean | undefined>(undefined);

	useEffect(() => {
		const onScroll = debounce((event) => {
			if (!dropDownRef.current || !event.target) return;
			if (!dropDownRef.current.contains(event.target as Node)) {
				setOpen(false);
			}
		}, 0);
		window.addEventListener('scroll', onScroll, true);
		return () => {
			window.removeEventListener('scroll', onScroll, true);
		};
	}, []);

	return (
		<Select
			{...props}
			dropdownRender={(menu) => <div ref={dropDownRef}>{menu}</div>}
			open={open}
			onDropdownVisibleChange={setOpen}
			getPopupContainer={getPopupContainer}
		>
			{props.children}
		</Select>
	);
};

export const mapSelectFormikFieldProps: FormikFieldMapProps<SelectProps> = (
	props
) => ({
	onChange: (value, option) => {
		props.props.onChange?.(value, option);
		return props.helpers.setValue(value);
	},
	onBlur: (event) => {
		props.helpers.setTouched(true);
		props.field.onBlur(event);
	}
});

const FormikField = createFormikField<SelectProps>(
	Select,
	mapSelectFormikFieldProps
);

const HookFormSelect = createHookFormField<ScrollHandledProps>(ScrollHandled);
Select.Option = AntSelect.Option;
Select.FormikField = FormikField;
Select.HookFormSelect = HookFormSelect;
Select.ScrollHandled = ScrollHandled;

export default Select;
