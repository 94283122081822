import * as yup from 'yup';
import { groupSchema } from '../Group/schema';

const addMultipleGroupSchema = (maxGroupNameAmountCharacters: number) =>
	groupSchema(maxGroupNameAmountCharacters)
		.omit(['syncCode'])
		.shape({
			// it needs because of the react-hook-form library doesn't support scheme not required fields see https://github.com/react-hook-form/react-hook-form/issues/1781
			syncCode: yup.string().test({
				test: (value) => {
					if (!value) return true;
					return (
						(value?.length >= 1 && value?.length <= 60) || !value
					);
				},
				message: 'invalid sync code'
			})
		});

const getAddMultipleGroupsSchema = (maxGroupNameAmountCharacters: number) =>
	yup.object().shape({
		groups: yup
			.array()
			.of(addMultipleGroupSchema(maxGroupNameAmountCharacters))
	});

export default getAddMultipleGroupsSchema;
