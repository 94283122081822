import cn from 'classnames';
import { EditableFields, Tabs } from 'Components';
import Profile from './tabs/Profile';
import Settings from './tabs/Settings';
import ChangePassword from './tabs/ChangePassword';
import { PromptHelpers } from 'Components/Prompt/Prompt';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import ModuleSettings from './tabs/ModuleSettings/ModuleSettings';
import {
	DynamicPermissionCode,
	PermissionCode,
	TwoFactorAuthorizationStatus
} from 'Services/Api/Users/Types';
import { usePermission } from 'Components/Permission/Permission.hooks';
import UserContextProvider from '../UserContext';
import styles from './EditUser.module.scss';
import Notifications from '../Profile/tabs/Notifications';
import { useTranslation } from 'react-i18next';
import { TabItem } from 'app-types';

export enum TabKey {
	Profile = 'Profile',
	Settings = 'Settings',
	ModuleSettings = 'ModuleSettings',
	ChangePassword = 'Change Password',
	Notifications = 'Notifications'
}

type UserTab = TabItem<TabKey>;

const TABS: UserTab[] = [
	{
		key: TabKey.Profile,
		tab: 'user.editUser.tabs.profile'
	},
	{
		key: TabKey.Notifications,
		tab: 'profile.notifications.tab',
		permission: { code: PermissionCode.UserNotifications }
	},
	{
		key: TabKey.Settings,
		tab: 'user.editUser.tabs.settings'
	},
	{
		key: TabKey.ModuleSettings,
		tab: 'user.editUser.tabs.moduleSettings',
		permission: { code: PermissionCode.Lms }
	},
	{
		key: TabKey.ChangePassword,
		tab: 'user.editUser.tabs.changePassword'
	}
];

type EditUserTabsProps = PromptHelpers & {
	isProfile: boolean;
	isLmsHasError?: boolean;
	twoFactorAuthenticationStatus: TwoFactorAuthorizationStatus;
};

function EditUserTabs({
	setBlockTransition,
	isProfile,
	isLmsHasError,
	twoFactorAuthenticationStatus
}: EditUserTabsProps) {
	const { t } = useTranslation();
	const {
		dirty: currentDirty,
		isSubmitting: currentIsSubmitting
	} = useFormikContext();
	const permission = usePermission();
	const [changePasswordDirty, setChangePasswordDirty] = useState(false);

	useEffect(() => {
		const globalBlockTransitoion = currentDirty && !currentIsSubmitting;
		setBlockTransition(globalBlockTransitoion || changePasswordDirty);
	}, [
		setBlockTransition,
		currentDirty,
		currentIsSubmitting,
		changePasswordDirty
	]);

	const onChangePasswordFormChange = (
		dirty: boolean,
		isSubmitting: boolean
	) => {
		setChangePasswordDirty(dirty && !isSubmitting);
	};

	const getTabContent = (tab: UserTab) => {
		let content: React.ReactNode = null;
		switch (tab.key) {
			case TabKey.Profile:
				content = (
					<EditableFields footer>
						<Profile isProfile={isProfile} />
					</EditableFields>
				);
				break;
			case TabKey.Notifications:
				if (isProfile) {
					content = (
						<EditableFields footer>
							<Notifications />
						</EditableFields>
					);
				}
				break;
			case TabKey.Settings:
				content = (
					<EditableFields footer>
						<Settings
							isProfile={isProfile}
							twoFactorAuthenticationStatus={
								twoFactorAuthenticationStatus
							}
						/>
					</EditableFields>
				);
				break;
			case TabKey.ModuleSettings:
				content = (
					<EditableFields footer>
						<ModuleSettings isProfile={isProfile} />
					</EditableFields>
				);
				break;
			case TabKey.ChangePassword:
				if (isProfile) {
					content = (
						<ChangePassword onChange={onChangePasswordFormChange} />
					);
				}
				break;
		}
		return content;
	};

	return (
		<UserContextProvider permissionCode={DynamicPermissionCode.EditUser}>
			<Tabs
				defaultActiveKey={TabKey.Profile}
				className={cn({
					[styles.moduleSettingsErrorTab]: isLmsHasError
				})}
			>
				{TABS.map((tab) => {
					const hasPermission =
						!tab.permission ||
						permission.getHasPermission(tab.permission).permitted;
					if (!hasPermission) {
						return null;
					}
					const content = getTabContent(tab);
					if (!content) {
						return null;
					}
					return (
						<Tabs.TabPane tab={t(tab.tab)} key={tab.key}>
							{content}
						</Tabs.TabPane>
					);
				})}
			</Tabs>
		</UserContextProvider>
	);
}

export default EditUserTabs;
