import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { RadioChangeEvent } from 'antd';
import { Formik } from 'formik';
import XLSX from 'xlsx';
import { SheetData } from 'app-types';
import { Button, Layout, Space, Prompt, Radio, Notification } from 'Components';
import { useFileUpload, useConfigs } from 'Hooks';
import {
	readFirstXLSXTab,
	getCleanedKeysXLSX,
	validateColumnNamesXLSX
} from 'Helpers/xlsxFile';
import {
	HierarchyFormSubmitButton,
	HierarchySubmitButton,
	HierarchyXLSXSubmitButton
} from './components';
import { getAppRoutes } from 'Pages/App/App.constants';
import { useGroupsHierarchyContext } from './GroupsHierarchyContext';
import {
	Values,
	RouteParams,
	ValueXLSX,
	ValuesXLSX
} from './GroupsHierarchy.types';
import styles from './GroupsHierarchy.module.scss';
import schema from './schema';
import GroupsHierarchyForm from './GroupsHierarchyForm/GroupsHierarchyForm';
import GroupsHierarchyXLSX from './GroupsHierarchyXLSX/GroupsHierarchyXLSX';
import {
	WS_NAME,
	LEVEL_COLUMN_NAME,
	IS_PROPERTY_COLUMN_NAME,
	XLSX_TEMPLATE_NAME,
	SHEETS_NAMES
} from './GroupsHierarchy.constants';
import {
	adaptRowsToXLSXColumns,
	createRows,
	createHierarchyFromXLSXFile,
	isGroupsHierarchyLimitReachedResult,
	generateColumnNames
} from './GroupsHierarchy.helpers';

const GroupsHierarchyLayout = () => {
	const [levels, setLevels] = useState<ValueXLSX[]>([]);
	const { t } = useTranslation();
	const history = useHistory();
	const { groupId } = useParams<RouteParams>();
	const [hierarchyType, setHierarchyType] = useState<string | undefined>();
	const { file, handleFileUpload, fileName } = useFileUpload();

	const onHierarchyTypeChange = (e: RadioChangeEvent) => {
		setHierarchyType(e.target.value);
	};
	const {
		levelNumber,
		levelNumberXLSX,
		parentLevel,
		parent,
		changeScreenType,
		groupsHierarchySizeLimit,
		amountOfGroupsInTenant,
		groupMaxHierarchyLevel
	} = useGroupsHierarchyContext();
	const { maxGroupNameCharacters } = useConfigs();
	const onImportXLSXClick = useCallback(() => {
		const reader = new FileReader();

		reader.onload = (ev) => {
			const data: Record<string, SheetData> = readFirstXLSXTab(ev);

			const columnsNames = [
				IS_PROPERTY_COLUMN_NAME,
				...generateColumnNames(parentLevel, groupMaxHierarchyLevel)
			];

			const {
				isValid: isColumnsValid,
				columnTitles
			} = validateColumnNamesXLSX(data, columnsNames);

			if (!isColumnsValid) {
				// case columns were rewritten by User
				Notification.error({
					description: t('invalidFile')
				});
				return;
			}

			const sheetNames = SHEETS_NAMES.slice(0, columnTitles.length);
			const cleanedKeys = getCleanedKeysXLSX(data, sheetNames);

			if (!cleanedKeys.length) {
				// case empty file
				Notification.error({
					description: t('invalidFile')
				});
				return;
			}

			const hierarchyGroups = createHierarchyFromXLSXFile(
				data,
				parent,
				sheetNames
			);

			if (hierarchyGroups) {
				if (
					isGroupsHierarchyLimitReachedResult(
						// don't count root group it is already included to the amountOfGroupsInTenant
						hierarchyGroups.length - 1,
						groupsHierarchySizeLimit,
						amountOfGroupsInTenant
					)
				) {
					Notification.error({
						description: t('groupsHierarchy.groupsLimitReached')
					});
					return;
				}
				Notification.success({
					description: t('notification.uploadFile.success')
				});
				changeScreenType('manage-hierarchy', hierarchyGroups);
			}
		};
		if (file) {
			reader.readAsArrayBuffer(file);
		}

		// eslint-disable-next-line
	}, [file]);

	const handleDownloadXLSX = (values: ValuesXLSX) => {
		const workbook = XLSX.utils.book_new();
		const headers = [IS_PROPERTY_COLUMN_NAME];

		const levelHeaders = values.levels.map(
			(_, i) => `${LEVEL_COLUMN_NAME}${i + parentLevel + 1}`
		);

		headers.push(...levelHeaders);
		const wsData = [headers];

		const rows = createRows(values.levels);

		const adaptedRows = adaptRowsToXLSXColumns(rows, headers.length);

		wsData.push(...adaptedRows);

		const ws = XLSX.utils.aoa_to_sheet(wsData);
		/* Add the worksheet to the workbook */
		XLSX.utils.book_append_sheet(workbook, ws, WS_NAME);
		XLSX.writeFile(workbook, `${XLSX_TEMPLATE_NAME}.xlsx`);
		Notification.success({
			description: t('notification.downloadFile.success')
		});
	};

	const form = useForm<Values>({
		mode: 'all'
	});

	const goBack = () => {
		history.push(generatePath(getAppRoutes().GROUPS, { groupId }));
	};

	const onCancel = () => {
		goBack();
	};

	const {
		handleSubmit,
		formState: { errors }
	} = form;
	const hasFile = file !== null;
	return (
		<div className={styles.hierarchyLayout}>
			<Prompt
				blockTransition={
					levelNumber !== parentLevel ||
					levelNumberXLSX !== parentLevel ||
					hasFile
				}
			/>

			<Layout className={styles.layoutBody}>
				<Layout.Heading goBack={onCancel}>
					<h1>{t('groupsHierarchy.title')}</h1>
				</Layout.Heading>
				<Layout.Body>
					<Layout.Content>
						<div className={styles.baseContent}>
							<h1 className={styles.contentTitle}>
								{t('groupsHierarchy.contentTitle')}
							</h1>
							<p>{t('groupsHierarchy.hierarchyQuestion')}</p>

							<Radio.Group
								onChange={onHierarchyTypeChange}
								value={hierarchyType}
								className={styles.radioGroup}
							>
								<Radio value="form">
									{t('groupsHierarchy.hierarchyInForm')}
								</Radio>
								<Radio value="xlsx">
									{t('groupsHierarchy.hierarchyInXLSX')}
								</Radio>
							</Radio.Group>
						</div>
						{hierarchyType === 'form' && (
							<FormProvider {...form}>
								<GroupsHierarchyForm />
							</FormProvider>
						)}
						{hierarchyType === 'xlsx' && (
							<Formik
								initialValues={{
									levels
								}}
								onSubmit={handleDownloadXLSX}
								enableReinitialize
								validationSchema={schema(
									maxGroupNameCharacters
								)}
							>
								{({ values, isValid }) => (
									<GroupsHierarchyXLSX
										values={values}
										isValid={isValid}
										setLevels={setLevels}
										handleFileUpload={handleFileUpload}
										fileName={fileName}
									/>
								)}
							</Formik>
						)}
					</Layout.Content>
					<Layout.Footer>
						<Space>
							<Button
								ghost
								shape="round"
								type="default"
								onClick={onCancel}
							>
								{t('cancel')}
							</Button>

							{hierarchyType === 'form' ? (
								<HierarchyFormSubmitButton
									handleSubmit={handleSubmit}
									errors={errors}
								/>
							) : hierarchyType === 'xlsx' ? (
								<HierarchyXLSXSubmitButton
									onClick={onImportXLSXClick}
									disabled={!hasFile}
								/>
							) : (
								<HierarchySubmitButton disabled />
							)}
						</Space>
					</Layout.Footer>
				</Layout.Body>
			</Layout>
		</div>
	);
};

export default GroupsHierarchyLayout;
