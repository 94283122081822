import { TInfo } from 'app-types';
import { Icon } from 'Components';
import React from 'react';
import { UserActivity } from 'Services/Api/Dashboard/Types';

export const params = {
	duration: 90
};
interface StatisticItem {
	icon: React.ReactNode;
	value: (d: UserActivity | undefined) => number | undefined;
	title: TInfo;
}

export const USER_ACTIVITY_LABEL: Record<
	keyof Omit<UserActivity, 'duration'>,
	TInfo
> = {
	total: { key: 'dashboard.tabs.userActivity.total', params },
	logged: { key: 'dashboard.tabs.userActivity.loggedIn', params },
	lockedOut: { key: 'dashboard.tabs.userActivity.lockedOut', params },
	created: { key: 'dashboard.tabs.userActivity.created', params },
	deactivated: { key: 'dashboard.tabs.userActivity.deactivated', params },
	deleted: { key: 'dashboard.tabs.userActivity.deleted', params }
};

export const STATISTICS: StatisticItem[] = [
	{
		icon: <Icon type="users" />,
		value: (d) => d?.total,
		title: USER_ACTIVITY_LABEL.total
	},
	{
		icon: <Icon type="checkCircle" />,
		value: (d) => d?.logged,
		title: USER_ACTIVITY_LABEL.logged
	},
	{
		icon: <Icon type="lock" />,
		value: (d) => d?.lockedOut,
		title: USER_ACTIVITY_LABEL.lockedOut
	},
	{
		icon: <Icon type="plus" />,
		value: (d) => d?.created,
		title: USER_ACTIVITY_LABEL.created
	},
	{
		icon: <Icon type="minusCircle" />,
		value: (d) => d?.deactivated,
		title: USER_ACTIVITY_LABEL.deactivated
	},
	{
		icon: <Icon type="delete" />,
		value: (d) => d?.deleted,
		title: USER_ACTIVITY_LABEL.deleted
	}
];
