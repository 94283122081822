import { useTranslation } from 'react-i18next';
import { FormItem, Select } from 'Components';
import { useFormikContext } from 'formik';
import { AddUserValues } from 'Pages/User/User.types';
import { SelectProps } from 'Components/Form/Select/Select';
import { roleToOption } from 'Pages/User/EditUser/EditUser.helpers';
import { sortBy } from 'lodash';
import { useUserContext } from '../UserContext';
import { translateTInfo } from 'i18n/i18n.helpers';

const FIELD_NAME = 'primarySystemRoleId';

interface RoleFieldProps {
	isProfile?: boolean;
}
function RoleField(props: RoleFieldProps) {
	const { t } = useTranslation();
	const {
		values,
		setFieldValue,
		getFieldMeta,
		setFieldTouched
	} = useFormikContext<AddUserValues>();
	const meta = getFieldMeta(FIELD_NAME);
	const { systemRoles, systemRolesList } = useUserContext();

	const onBlur = () => {
		setFieldTouched(FIELD_NAME, true);
	};

	const onChange: SelectProps['onChange'] = (_, option) => {
		const options = option as AddUserValues['primarySystemRoleId'];

		setFieldValue(
			FIELD_NAME,
			sortBy(
				// enumarate object keys to avoid form field redundantly being changed
				// due to additional fields not stated in initial values
				options.map((opt) => ({
					key: opt.key,
					label: opt.label,
					value: opt.value,
					data: opt.data
				})),
				(option) => !option.data.isPrimary
			)
		);
	};

	const currentValue = values.primarySystemRoleId;
	const currentValueRole = currentValue?.find((item) => item.data.isPrimary);

	const error = meta.touched ? meta.error : undefined;
	const isLoading = systemRoles.loading;
	return (
		<FormItem
			label={t('user.primarySystemRoleId')}
			required
			validateStatus={(error && 'error') || undefined}
			help={translateTInfo(error, t)}
		>
			<Select
				loading={isLoading}
				mode="multiple"
				labelInValue
				onChange={onChange}
				disabled={props.isProfile || isLoading}
				value={currentValue}
				optionFilterProp="label"
				onBlur={onBlur}
			>
				{systemRolesList?.map((role) => {
					const option = roleToOption(role);
					return (
						<Select.Option
							{...option}
							disabled={
								option.data.isPrimary &&
								currentValueRole &&
								option.key !== currentValueRole?.key
							}
						>
							{option.label}
						</Select.Option>
					);
				})}
			</Select>
		</FormItem>
	);
}

export default RoleField;
