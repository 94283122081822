import {
	DatePicker as AntDatePicker,
	DatePickerProps as AntDatePickerProps,
	TimePickerProps
} from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { APP_CONFIG } from 'app-config';
import cn from 'classnames';
import Icon from 'Components/Icon/Icon';
import Icons from 'Components/Icon/Icons';
import { createFormikField } from 'Helpers/components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styles from './DatePicker.module.scss';

type DatePickerProps = AntDatePickerProps;
const FORMAT_BY_PICKER = {
	time: APP_CONFIG.TIME_FORMAT
} as Record<string, string>;

const fromDate = (
	value: moment.Moment | null,
	picker: AntDatePickerProps['picker']
) => {
	return (
		value?.format(
			FORMAT_BY_PICKER[picker ?? ''] ?? APP_CONFIG.DATE_FORMAT
		) ?? ''
	);
};

type DatePickerInterface = React.FC<DatePickerProps> & {
	RangePicker: typeof RangePicker;
	FormikField: typeof FormikField;
};
const DatePicker: DatePickerInterface = (props) => {
	const timeProps: TimePickerProps = {
		showSecond: false
	};
	const suffixIconType: keyof typeof Icons =
		props.picker === 'time' ? 'time' : 'calendar';
	return (
		<AntDatePicker
			{...(props as AntDatePickerProps)}
			{...timeProps}
			format={FORMAT_BY_PICKER[props.picker ?? '']}
			className={cn(props.className, styles.root)}
			clearIcon={<Icon type="close" />}
			suffixIcon={<Icon type={suffixIconType} />}
		/>
	);
};

type RangePickerInterface = React.FC<RangePickerProps> & {
	FormikField: typeof RangePickerFormikField;
};
const RangePicker: RangePickerInterface = (props) => {
	const { t } = useTranslation();
	return (
		<AntDatePicker.RangePicker
			{...props}
			className={cn(props.className, styles.range)}
			placeholder={
				props.placeholder ?? [
					t('appConfig.rangeDatePicker.start'),
					t('appConfig.rangeDatePicker.end')
				]
			}
			separator="-"
			suffixIcon={<Icon type="calendar" />}
			clearIcon={<Icon type="close" />}
		/>
	);
};

const FormikField = createFormikField<DatePickerProps>(DatePicker, (props) => ({
	onChange: (value) => {
		props.props.onChange?.(value, fromDate(value, props.props.picker));
		return props.helpers.setValue(value);
	}
}));
const RangePickerFormikField = createFormikField<RangePickerProps>(
	RangePicker,
	(props) => ({
		onChange: (value) => {
			props.props.onChange?.(value, [
				fromDate(value?.[0] ?? null, props.props.picker),
				fromDate(value?.[1] ?? null, props.props.picker)
			]);
			return props.helpers.setValue(value);
		}
	})
);

RangePicker.FormikField = RangePickerFormikField;
DatePicker.RangePicker = RangePicker;
DatePicker.FormikField = FormikField;

export default DatePicker;
