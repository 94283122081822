import {
	AutoComplete as AntAutoComplete,
	AutoCompleteProps as AntAutoCompleteProps
} from 'antd';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import styles from '../Select/Select.module.scss';

type Value = string | null;
export type AutoCompleteProps = AntAutoCompleteProps & {
	autoClearValueOnSelect?: boolean;
	loading?: boolean;
	searched?: boolean;
};
type AutoCompleteInterface = React.FC<AutoCompleteProps> & {
	Option: typeof AntAutoComplete.Option;
};
const AutoComplete: AutoCompleteInterface = ({
	autoClearValueOnSelect,
	loading,
	searched,
	...props
}) => {
	const [value, setValue] = useState<Value>();
	const { t } = useTranslation();
	const onChange = useCallback<
		Required<Pick<AntAutoCompleteProps, 'onChange'>>['onChange']
	>(
		(value, option) => {
			setValue(value);
			props.onChange?.(value, option);
		},
		// eslint-disable-next-line
		[props.onChange]
	);

	const onSelect = useCallback<
		Required<Pick<AntAutoCompleteProps, 'onSelect'>>['onSelect']
	>(
		(value, option) => {
			if (autoClearValueOnSelect) {
				setValue(null);
			}
			props.onSelect?.(value, option);
		},
		// eslint-disable-next-line
		[autoClearValueOnSelect, props.onSelect]
	);

	const filterOption = useCallback(
		(inputValue: string, option) =>
			option.value.toLowerCase().includes(inputValue.toLowerCase()),
		[]
	);
	return (
		<AntAutoComplete
			notFoundContent={t(
				`appConfig.${searched ? 'noResultsFound' : 'noData'}`
			)}
			{...props}
			// resetting value with undefined as the types suggest doesn't work
			// eslint-disable-next-line
			value={value as any}
			onSelect={onSelect}
			onChange={onChange}
			filterOption={props.filterOption ?? filterOption}
			className={cn(props.className, styles.root)}
			dropdownClassName={cn(props.dropdownClassName, styles.dropdown)}
			getPopupContainer={
				props.getPopupContainer || ((trigger) => trigger.parentNode)
			}
		/>
	);
};

AutoComplete.Option = AntAutoComplete.Option;

export default AutoComplete;
