import { useTranslation } from 'react-i18next';
import { Button, Icon, Tooltip, Space } from 'Components';
import ModalContextProvider, { useModal } from 'Hooks/useModal';
import DeletedGroupsActionsModals, {
	DeletedGroupsActionsModalName,
	DeletedGroupsActionsModalsProps
} from './DeletedGroupsActionsModals';
import { usePermission } from 'Components/Permission/Permission.hooks';
import { PermissionCode } from 'Services/Api/Users/Types';
import DeletedGroupsExportButton, {
	DeletedGroupsExportButtonProps
} from './components/DeletedGroupsExportButton';

type DeletedGroupsActionsContentProps = DeletedGroupsActionsModalsProps &
	DeletedGroupsExportButtonProps & {
		hasData: boolean;
	};
function DeletedGroupsActionsContent(props: DeletedGroupsActionsContentProps) {
	const { ids } = props;
	const permission = usePermission();
	const { t } = useTranslation();
	const { showModal } = useModal();

	const onRestoreClick = () => {
		showModal({ name: DeletedGroupsActionsModalName.Restore, value: null });
	};
	const {
		permittedByCode: {
			[PermissionCode.RestoreGroup]: isRestoreGroupPermitted,
			[PermissionCode.RestoreTenant]: isRestoreTenantPermitted,
			[PermissionCode.ExportGroups]: isExportPermitted
		}
	} = permission.getHasPermission({
		code: [
			PermissionCode.RestoreGroup,
			PermissionCode.RestoreTenant,
			PermissionCode.ExportGroups
		]
	});
	const isActionDisabled = !ids.length;
	return (
		<>
			<DeletedGroupsActionsModals {...props} />
			<Space>
				<Tooltip
					title={t(
						'deletedGroups.deletedGroupsActionsModals.restoreModal.disabledTooltip'
					)}
					enabled={isActionDisabled}
				>
					{(isRestoreGroupPermitted || isRestoreTenantPermitted) && (
						<Button
							type="primary"
							shape="round"
							icon={<Icon type="restore" />}
							disabled={isActionDisabled}
							onClick={onRestoreClick}
						>
							{t('restore')}
						</Button>
					)}
				</Tooltip>
				{isExportPermitted && (
					<DeletedGroupsExportButton
						params={props.params}
						disabled={!props.hasData}
					/>
				)}
			</Space>
		</>
	);
}

const DeletedGroupsActions = (props: DeletedGroupsActionsContentProps) => (
	<ModalContextProvider>
		<DeletedGroupsActionsContent {...props} />
	</ModalContextProvider>
);

export default DeletedGroupsActions;
