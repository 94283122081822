import { Redirect } from 'react-router-dom';
import { getAppRoutes } from 'Pages/App/App.constants';
import { useUserContext } from 'Pages/User/UserContext';
import { FailedUsersStatus } from 'Services/Api/Users/Types';
import {
	Layout,
	Button,
	Icon,
	Fx,
	Prompt,
	Space,
	Tooltip,
	Notification,
	CustomerSupportNotification
} from 'Components';
import AddUsersFormFields from './AddUsersFormFields';
import styles from './AddUsers.module.scss';
import { Copier, ImportXLSXFileModal, UsersControl } from './components';
import { useAddUsers } from './AddUsers.hooks';
import { ShowInactiveModal as AddUsersLayoutProps } from 'Components/InactiveHierarchyModalContent/InactiveHierarchyModalContent.types';

const AddUsersLayout = ({ showInactiveModal }: AddUsersLayoutProps) => {
	const {
		goBack,
		onRowSelectionChange,
		handleUsersFromXLSXFile,
		t,
		onPaginationChange,
		onSubmitClick,
		openXLSXModal,
		append,
		closeCopier,
		validateCognitoLoginOnAction,
		toggleCopierVisible,
		setCheckedFields,
		clearErrors,
		resetPhoneNumberField,
		checkedFields,
		modal,
		isDirty,
		isCopierVisible,
		isSubmitting,
		blockImportXLSXButton,
		pageSize,
		currentPage,
		loading,
		isTokenExpired,
		isForbidden,
		failedUsersStatus,
		isTokenInvalid
	} = useAddUsers(showInactiveModal);

	const { languagesList, timezonesList, systemRolesList } = useUserContext();
	const { TOKEN_EXPIRED, ERROR_PERMISSION, HOME } = getAppRoutes();

	if (
		failedUsersStatus === FailedUsersStatus.Complete ||
		failedUsersStatus === FailedUsersStatus.InProgress ||
		isTokenInvalid
	) {
		if (isTokenInvalid) {
			Notification.error({
				description: <CustomerSupportNotification />
			});
		} else {
			const isInProgress =
				failedUsersStatus === FailedUsersStatus.InProgress;
			const postfix = isInProgress
				? 'processInProgress'
				: 'processCompleted';
			Notification.success({
				description: t(`users.addUsers.notification.${postfix}`)
			});
		}

		return <Redirect to={HOME} />;
	}

	if (isTokenExpired) return <Redirect to={TOKEN_EXPIRED} />;

	if (isForbidden) return <Redirect to={ERROR_PERMISSION} />;

	if (!languagesList || !timezonesList || !systemRolesList) return null;

	return (
		<>
			<Prompt blockTransition={isDirty && !isSubmitting} />
			{modal && (
				<ImportXLSXFileModal
					handleUsersFromXLSXFile={handleUsersFromXLSXFile}
				/>
			)}
			<Layout.Heading goBack={goBack}>
				<Fx>
					<h1>{t('users.addUsers.title')}</h1>
					<Space className={styles.headerActionButtonsWrapper}>
						<UsersControl
							append={append}
							checkedFields={checkedFields}
							clearErrors={clearErrors}
							setCheckedFields={setCheckedFields}
							onPaginationChange={onPaginationChange}
							pageSize={pageSize}
							currentPage={currentPage}
							validateCognitoLogin={validateCognitoLoginOnAction}
						/>
						<Tooltip
							title={t('users.addUsers.blockXLSXFileTooltip')}
							trigger="hover"
							enabled={blockImportXLSXButton}
						>
							<Button
								shape="round"
								ghost
								type="primary"
								className={styles.headerImportButton}
								onClick={openXLSXModal}
								disabled={blockImportXLSXButton}
							>
								<Icon type="import" />
								{t('users.addUsers.importFromCsv')}
							</Button>
						</Tooltip>
						<Button
							shape="round"
							ghost
							type="primary"
							onClick={toggleCopierVisible}
						>
							<Icon type="copy" />
							{t('users.addUsers.copyValueToAllUsers')}
						</Button>
					</Space>
				</Fx>
			</Layout.Heading>

			<Copier closeCopier={closeCopier} isVisible={isCopierVisible} />

			<AddUsersFormFields
				validateCognitoLogin={validateCognitoLoginOnAction}
				onRowSelectionChange={onRowSelectionChange}
				onPaginationChange={onPaginationChange}
				resetPhoneNumberField={resetPhoneNumberField}
				currentPage={currentPage}
				pageSize={pageSize}
				selectedRowKeys={checkedFields}
				loading={loading}
			/>

			<Layout.Footer className={styles.footer}>
				<Space>
					<Button ghost shape="round" type="default" onClick={goBack}>
						{t('cancel')}
					</Button>
					<Button
						shape="round"
						type="primary"
						htmlType="submit"
						loading={isSubmitting}
						onClick={onSubmitClick}
					>
						{t('users.addUsers.saveUsers')}
					</Button>
				</Space>
			</Layout.Footer>
		</>
	);
};

export default AddUsersLayout;
