import * as yup from 'yup';

import {
	FIELD_PATTERN,
	INTEGER_PATTERN,
	PATTERN,
	SPECIAL_CHAR_PATTERN
} from './validations.constants';
import { ValidationTestName } from './validations.types';
import { email, noLeadTrailSpace, numeric } from './validations.helpers';

export const FIELD_TYPE_VALIDATION = {
	text: yup
		.string()
		.test(
			'is-no-lead-trail-space',
			noLeadTrailSpace,
			(value) => {
        const defVal = value ?? ''
        return !defVal.match(PATTERN.leadTrailSpace)
      } 
		),
	numeric: yup.string().matches(FIELD_PATTERN.numeric, numeric)
};

const VALIDATION_BASE = {
	baseName: FIELD_TYPE_VALIDATION.text.min(2).max(50)
};

export const VALIDATION = {
	baseName: VALIDATION_BASE.baseName,
	name: VALIDATION_BASE.baseName.matches(FIELD_PATTERN.name, (params) => ({
		key: 'util.validation.validationMessage.name.matches',
		params
	})),
	reportName: VALIDATION_BASE.baseName.matches(
		FIELD_PATTERN.reportName,
		(params) => ({
			key: 'util.validation.validationMessage.name.matches',
			params
		})
	),
	phone: yup
		.string()
		.min(9)
		.max(16)
		.matches(FIELD_PATTERN.phone, (params) => ({
			key: 'util.validation.validationMessage.phone.matches',
			params
		}))
		.nullable(),
	email: FIELD_TYPE_VALIDATION.text
		.min(5)
		.max(50)
		.email()
		.matches(FIELD_PATTERN.email, email)
};

export function getIsValidPassword(value: string | undefined = '') {
	const rules = {
		[ValidationTestName.IncludesLowerCase]: value
			.split('')
			.some(
				(char) =>
					isNaN(+char) &&
					char.toLowerCase() !== char.toUpperCase() &&
					char === char.toLowerCase()
			),
		[ValidationTestName.IncludesUpperCase]: value
			.split('')
			.some(
				(char) =>
					isNaN(+char) &&
					char.toLowerCase() !== char.toUpperCase() &&
					char === char.toUpperCase()
			),
		[ValidationTestName.IncludesSpecialChar]: !!value.match(
			SPECIAL_CHAR_PATTERN
		),
		[ValidationTestName.IncludesInterger]: !!value.match(INTEGER_PATTERN)
	};

	return {
		info: rules,
		isValid: !Object.values(rules).includes(false)
	};
}
