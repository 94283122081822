import { Col, DatePicker, FormItem, Fx, Row } from 'Components';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { REPORT_FIELD_LABEL } from '../EditReport.constants';
import { getFromToPeriod } from '../EditReport.helpers';
import { ReportFormValues } from '../schema';
import { APP_CONFIG } from 'app-config';
import { useTranslation } from 'react-i18next';

function HistoricalRiskScore() {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext<ReportFormValues>();

	useEffect(() => {
		setFieldValue(
			'fromToPeriod',
			values.fromToPeriod ? values.fromToPeriod : getFromToPeriod()
		);
		// eslint-disable-next-line
	}, []);

	const handleToPeriodChange = (value: moment.Moment | null) => {
		const start = getFromToPeriod(value ?? undefined)[0];
		setFieldValue('fromToPeriod.[0]', start);
	};

	const startDateFormtted = values.fromToPeriod?.[0]?.format(
		APP_CONFIG.DATE_FORMAT
	);

	return (
		<Row>
			<Col xs={5}>
				<FormItem
					label={t(REPORT_FIELD_LABEL.definedPeriod)}
					marginBottom={false}
					text
					required
				>
					{startDateFormtted}
				</FormItem>
			</Col>
			<Col xs={5}>
				<Fx fullHeight self="bottom" align="bottom">
					<DatePicker.FormikField
						name="fromToPeriod.[1]"
						marginBottom={false}
						allowClear={false}
						onChange={handleToPeriodChange}
					/>
				</Fx>
			</Col>
		</Row>
	);
}

export default HistoricalRiskScore;
