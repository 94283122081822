import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from 'app-config';

export const components = {
	linkTerms: (
		<a target="_blank" rel="noreferrer" href={TERMS_OF_USE_LINK}>
			&nbsp;
		</a>
	),
	linkPrivacy: (
		<a target="_blank" rel="noreferrer" href={PRIVACY_POLICY_LINK}>
			&nbsp;
		</a>
	)
};
