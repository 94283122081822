import { ExportButton } from 'Components';
import { ButtonProps } from 'Components/Button/Button';
import { ExportButtonProps } from 'Components/ExportButton/ExportButton';
import { downloadLink } from 'Helpers/downloadLink';
import { useStores } from 'Hooks/useStore';
import { useTranslation } from 'react-i18next';
import {
	DownloadReportRequest,
	DownloadReportResponse,
	DownloadSavedReportRequest
} from 'Services/Api/Reports/Types';

type SavedReportButtonProps = ButtonProps &
	Pick<
		ExportButtonProps<DownloadReportResponse, DownloadReportRequest>,
		'onCompleted'
	> & {
		params: DownloadSavedReportRequest;
	};

function SavedReportButton(props: SavedReportButtonProps) {
	const { t } = useTranslation();
	const { reportStore } = useStores();

	const handleDownload = async () => {
		try {
			const params = {
				reportId: props.params.reportId
			};
			if (!params) {
				throw Error;
			}
			const result = await reportStore.downloadReport(params);
			return result.data;
		} catch (e) {
			throw e;
		}
	};

	const handleDownloadCompleted = (data: DownloadReportResponse) => {
		downloadLink(data.url);
		props.onCompleted?.(data);
	};

	return (
		<ExportButton<DownloadReportResponse, DownloadReportRequest>
			onExport={handleDownload}
			onCompleted={handleDownloadCompleted}
			menu={false}
			disabled={props.disabled}
		>
			{t('download')}
		</ExportButton>
	);
}

export default SavedReportButton;
