import React from 'react';
import cn from 'classnames';
import Icons from './Icons';
import styles from './Icon.module.scss';

export type IconProps = React.HTMLAttributes<HTMLSpanElement> &
	React.RefAttributes<HTMLSpanElement> & {
		type: keyof typeof Icons;
		color?: 'default';
		size?: 'md' | 'xl';
	};

function Icon({ type, color, size, ...props }: IconProps) {
	const IconComponent = Icons[type];
	if (!IconComponent) {
		return null;
	}

	return (
		<span
			{...props}
			className={cn(props.className, styles.root, {
				[styles.default]: color === 'default',
				[styles.md]: size === 'md',
				[styles.xl]: size === 'xl'
			})}
			role="img"
		>
			<IconComponent />
		</span>
	);
}

export default Icon;
