import {
	FC,
	useCallback,
	useState,
	cloneElement,
	ReactElement,
	isValidElement
} from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'Components';
import ModalContextProvider, { useModal } from 'Hooks/useModal';
import { asyncHandler } from 'Helpers/asyncHandler';
import { ShowInactiveModal } from './InactiveHierarchyModalContent.types';
import { TIMEOUT_DELAY } from './InactiveHierarchyModalContent.constants';

interface InactiveHierarchyModalContentProps {
	withConfirmationLoading?: boolean;
}

const InactiveHierarchyModalContent: FC<InactiveHierarchyModalContentProps> = ({
	children,
	withConfirmationLoading
}) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { modal, showModal, hideModal } = useModal();

	const { executePromise, getReject, getResolve } = asyncHandler();

	const handleShowModal = useCallback(
		async () => {
			showModal({ value: null });
			await executePromise();
		},
		//eslint-disable-next-line
		[]
	);

	const handleOnOk = useCallback(
		() => {
			if (withConfirmationLoading) {
				setLoading(true);
				setTimeout(() => {
					confirmCase();
					setLoading(false);
				}, TIMEOUT_DELAY);
				return;
			}
			confirmCase();
		},
		//eslint-disable-next-line
		[]
	);

	const handleOnCancel = useCallback(
		() => {
			getReject()?.('canceled');
			hideModal();
		},
		//eslint-disable-next-line
		[]
	);
	const confirmCase = () => {
		getResolve()?.('ok');
		hideModal();
	};
	return (
		<>
			{cloneElement(children as ReactElement<ShowInactiveModal>, {
				showInactiveModal: handleShowModal
			})}
			{modal && (
				<Modal
					visible
					size="sm"
					title={t('user.warnings.groupOrHierarchyIsInactive.header')}
					onOk={handleOnOk}
					onCancel={handleOnCancel}
					okButtonProps={{
						loading
					}}
					okText={t('confirm')}
				>
					{t('user.warnings.groupOrHierarchyIsInactive.content')}
				</Modal>
			)}
		</>
	);
};

export const InactiveHierarchyModal: FC<InactiveHierarchyModalContentProps> = ({
	children,
	withConfirmationLoading
}) => {
	if (!isValidElement(children)) {
		throw Error('provided valid element');
	}
	return (
		<ModalContextProvider>
			<InactiveHierarchyModalContent
				withConfirmationLoading={withConfirmationLoading}
			>
				{children}
			</InactiveHierarchyModalContent>
		</ModalContextProvider>
	);
};

export default InactiveHierarchyModal;
