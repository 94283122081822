import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Notification, Spin } from 'Components';
import { FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { useHistory, useParams } from 'react-router';
import { UPDATE_USER, RESET_MFA } from 'Services/Api/Users/Mutations';
import { GET_USER } from 'Services/Api/Users/Queries';
import {
	GetUserResponse,
	UpdateUserRequest,
	ResetMFARequest
} from 'Services/Api/Users/Types';
import EditUserForm from './EditUserForm';
import { getUserRoleLabel } from 'app-util';
import { APIErrorCodes } from 'app-types';
import { checkApolloError } from 'Helpers/graphql';
import { navigateBack } from 'Helpers/navigation';
import { EditUserValues } from '../User.types';
import { getInitialValues, getEditUserSubmitValues } from './EditUser.helpers';
import { useErrorBoundary } from 'Hooks';
import { getAppRoutes } from 'Pages/App/App.constants';
import { useIsModuleSettingDisabled } from './tabs/ModuleSettings/ModuleSettingsContainer';
import {
	isModuleSettingsValidationError,
	validateLMSModuleSettings
} from './tabs/ModuleSettings/ModuleSettings.helpers';
import { useUser } from '../User.hooks';
import { schema } from '../schema';

function EditUser() {
	const { t } = useTranslation();
	const history = useHistory();
	const params = useParams<{ peakV3Id: string }>();
	const errorBoundary = useErrorBoundary();
	const { injectMeta } = useUser();
	const [isLmsHasError, setLmsHasError] = useState<boolean>(false);
	const { getIsLMSModuleSettingsDisabled } = useIsModuleSettingDisabled();
	const [updateUser] = useMutation<unknown, UpdateUserRequest>(UPDATE_USER);
	const [resetMFAMutation] = useMutation<unknown, ResetMFARequest>(RESET_MFA);
	const [getUser, getUserResponse] = useLazyQuery<GetUserResponse>(GET_USER, {
		variables: {
			peakV3Id: params.peakV3Id
		},
		fetchPolicy: 'no-cache',
		onError: (error: ApolloError) => {
			const apolloError = checkApolloError(error);
			if (apolloError.is(APIErrorCodes.PeakV3IDMustBeaUUID)) {
				errorBoundary.setStatus('notFound');
			} else if (apolloError.is(APIErrorCodes.GroupsNotInHierarchy)) {
				errorBoundary.setStatus('forbidden');
			} else {
				errorBoundary.onError(error);
			}
		}
	});
	const user = getUserResponse.data?.findUser;
	const initialValues = user ? getInitialValues(user) : null;

	const goBack = () => {
		navigateBack(history, getAppRoutes().USERS);
	};

	const onSubmit = async (
		editUserValues: EditUserValues,
		helpers: FormikHelpers<EditUserValues>
	) => {
		const { resetMFA, ...values } = editUserValues;
		try {
			if (resetMFA) {
				await resetMFAMutation({
					variables: {
						input: {
							peakV3Id: values.peakV3Id
						}
					}
				});
			}

			if (!isEqual(initialValues, values)) {
				const isModuleSettingsDisabled = getIsLMSModuleSettingsDisabled(
					values
				);
				setLmsHasError(false);
				validateLMSModuleSettings(isModuleSettingsDisabled, values);

				await updateUser({
					variables: {
						input: getEditUserSubmitValues(values)
					}
				});
			}

			helpers.resetForm({ values });
			goBack();
			Notification.success({
				description: t('notification.edit.success')
			});
		} catch (e) {
			const error = e as Error;
			helpers.setSubmitting(false);
			if (isModuleSettingsValidationError(error)) {
				setLmsHasError(true);
				helpers.setErrors({
					moduleSettings: error.message
				});
				return;
			}
			const apolloError = checkApolloError(error);
			const errors =
				apolloError.getFieldErrors(
					t,
					injectMeta(schema.describe().fields),
					'user.errorCodes'
				) ?? {};

			if (!isEmpty(errors)) {
				helpers.setErrors(errors);
			} else if (
				apolloError.is(APIErrorCodes.MovingBetweenTenantsForbidden)
			) {
				Notification.error({
					description: t(
						`errorCodes.${APIErrorCodes.MovingBetweenTenantsForbidden}`
					)
				});
			} else if (apolloError.is(APIErrorCodes.Forbidden)) {
				Notification.error({
					description: t(`errorCodes.${APIErrorCodes.Forbidden}`)
				});
			} else if (
				apolloError.is(APIErrorCodes.ValidationIsCognitoPhoneNumber)
			) {
				helpers.setErrors({
					phoneNumber: t(
						`user.errorCodes.${APIErrorCodes.ValidationIsCognitoPhoneNumber}`
					)
				});
			} else {
				Notification.error({
					description: t('errorCodes.genericErrorMessage')
				});
			}
		}
	};

	useEffect(() => {
		getUser();
		// eslint-disable-next-line
	}, []);

	if (!user || !getUserResponse.called || getUserResponse.loading) {
		return <Spin />;
	}

	const userName = `${user.firstName} ${user.lastName}`;

	return (
		<EditUserForm
			userName={userName}
			userRole={getUserRoleLabel(user.primarySystemRole.role)}
			isProfile={false}
			initialValues={initialValues as EditUserValues}
			onSubmit={onSubmit}
			goBack={goBack}
			isLmsHasError={isLmsHasError}
			twoFactorAuthenticationStatus={user.twoFactorAuthorizationStatus}
		/>
	);
}

export default EditUser;
