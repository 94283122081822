import { useCallback, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEmpty, isEqual } from 'lodash';
import { UNIQUE_ERROR_TYPE } from 'app-config';
import { Notification, Layout, Space, Fx } from 'Components';
import { useConfigs } from 'Hooks';
import { PATTERN } from 'Helpers/validations';
import getIndexes from 'Helpers/getIndexes';
import useColumns from './columns';
import {
	MANAGE_ROW_KEY,
	FIELD_ARRAY_KEY_NAME
} from './GroupsHierarchy.constants';
import {
	createNewHierarchyGroup,
	getDefaultUniqName,
	createManageValues
} from './GroupsHierarchy.helpers';
import { useGroupsHierarchyContext } from './GroupsHierarchyContext';
import { HierarchyGroup as HierarchyGroupType } from './GroupsHierarchy.types';
import { MemoizedVirtualTable } from './components';
import styles from './GroupsHierarchy.module.scss';

interface HierarchyGroupProps {
	defaultGroupName: string;
	group: HierarchyGroupType;
	fieldArrayName: string;
}
// this function allows to control errors with uniq group name, don't use  formState (from useFormContext) and errors object from it will create bugs on UI (related to onChange event)
const manageValues = createManageValues<number[]>();

const HierarchyGroup = ({
	defaultGroupName,
	group,
	fieldArrayName
}: HierarchyGroupProps) => {
	const { t } = useTranslation();
	const { maxGroupNameCharacters } = useConfigs();
	const {
		control,
		setError,
		clearErrors,
		getValues,
		reset
	} = useFormContext();
	const {
		setHierarchyGroups,
		hierarchyGroups,
		setActiveIsPropertyLevel,
		disabledIds,
		activesIsPropertyLevel,
		setIsUniqError,
		groupsHierarchySizeLimit,
		amountOfGroupsInTenant,
		groupMaxHierarchyLevel
	} = useGroupsHierarchyContext();

	const { fields, append } = useFieldArray({
		name: fieldArrayName,
		control,
		shouldUnregister: true,
		keyName: FIELD_ARRAY_KEY_NAME
	});

	useEffect(() => {
		if (
			isEmpty(activesIsPropertyLevel) &&
			hierarchyGroups.some((group) => group.isProperty)
		) {
			// init default activeIsPropertyLevel state with values related to new creates hierarchyGroups right after screen change
			const defaultIsPropertyLevel = hierarchyGroups.reduce<
				Record<string, string[]>
			>((acc, group) => {
				if (group.isProperty) {
					acc[group.level] = Array.isArray(acc[group.level])
						? acc[group.level].concat(group.id)
						: [group.id];
				}
				return acc;
			}, {});
			setActiveIsPropertyLevel(defaultIsPropertyLevel);
		}
	}, [activesIsPropertyLevel, hierarchyGroups, setActiveIsPropertyLevel]);

	const onAddGroupClick = useCallback(() => {
		if (
			hierarchyGroups.length + amountOfGroupsInTenant >
			groupsHierarchySizeLimit
		) {
			Notification.error({
				description: t('groupsHierarchy.groupsLimitReached')
			});
			return;
		}

		const values = getValues()[fieldArrayName];
		const newGroup = createNewHierarchyGroup({
			name: getDefaultUniqName(),
			level: group.level + 1,
			parentId: String(group.id),
			rowNumber: values?.length
		});

		setHierarchyGroups((hierarchyGroups) => {
			append(newGroup);
			return hierarchyGroups.concat(newGroup);
		});
		// eslint-disable-next-line
	}, [fields, group, hierarchyGroups, groupsHierarchySizeLimit]);

	const onDeleteGroupClick = useCallback(
		(fieldId: string) => {
			setHierarchyGroups((hierarchyGroups) => {
				const formValues = getValues();
				const arrayNames = Object.keys(formValues);
				const branchIDs = [fieldId];

				const values = arrayNames.reduce<
					Record<string, HierarchyGroupType[]>
				>((acc, name) => {
					const levelValues: HierarchyGroupType[] = formValues[name];
					acc[name] = levelValues.filter(({ id, parentId }) => {
						if (parentId && branchIDs.includes(parentId)) {
							branchIDs.push(id);
						}
						return !branchIDs.includes(id);
					});

					return acc;
				}, {});

				reset(values, { keepErrors: true, keepDirty: true });
				validateGroupUniqName();
				validateGroupSyncCode();
				return hierarchyGroups.filter(
					({ id }) => !branchIDs.includes(id)
				);
			});
		},
		// eslint-disable-next-line
		[fieldArrayName, fields]
	);

	const validateGroupUniqName = () => {
		const values = getValues()[fieldArrayName];

		const prevIndexes = manageValues.getLast();
		const indexes = getIndexes(values, 'name');

		if (indexes.length) {
			if (prevIndexes?.length && !isEqual(prevIndexes, indexes)) {
				clearErrors(
					prevIndexes.map(
						(index) => `${fieldArrayName}.${index}.name`
					)
				);
			}
			manageValues.addValues(indexes);
			indexes.forEach((index: number) => {
				setError(`${fieldArrayName}.${index}.name`, {
					type: UNIQUE_ERROR_TYPE
				});
			});
			Notification.error({
				description: t(
					'groups.addMultipleGroups.errorMessageUniqueName'
				)
			});
			setIsUniqError(true);
			return false;
		}

		if (prevIndexes !== null) {
			clearErrors(
				prevIndexes.map((index) => `${fieldArrayName}.${index}.name`)
			);
			manageValues.reset();
		}

		setIsUniqError(false);

		return true;
	};

	const validateGroupSyncCode = () => {
		const values = getValues();
		const groupsData = Object.values(values).flat();

		const indexes = getIndexes(groupsData, 'syncCode');

		if (indexes.length) {
			indexes.forEach((index: number) => {
				const group = groupsData[index];
				const fieldArrayName = group.parentId;
				setError(`${fieldArrayName}.${group.rowNumber}.syncCode`, {
					type: UNIQUE_ERROR_TYPE
				});
			});
			Notification.error({
				description: t(
					'group.fieldErrorCodes.GROUP_SYNC_CODE_SHOULD_BE_UNIQUE_PER_TENANT',
					{ path: 'Group sync code' }
				)
			});
			setIsUniqError(true);
			return false;
		}

		clearErrors(
			Object.keys(values).reduce<string[]>((acc, fieldArrayName) => {
				values[fieldArrayName].forEach(
					(_: HierarchyGroupType, index: number) => {
						acc.push(`${fieldArrayName}.${index}.syncCode`);
					}
				);
				return acc;
			}, [])
		);

		setIsUniqError(false);
		return true;
	};

	const onGroupNameChange = (value: string, fieldId: string) => {
		const isGroupNamesUniq = validateGroupUniqName();

		if (!isGroupNamesUniq) return;
		if (
			value.length &&
			Array.isArray(hierarchyGroups) &&
			value.length > 2 &&
			value.length < maxGroupNameCharacters &&
			!value.match(PATTERN.leadTrailSpace)
		) {
			setHierarchyGroups((hierarchyGroups) =>
				hierarchyGroups.map((group) => {
					return group.id === fieldId
						? { ...group, name: value }
						: group;
				})
			);
		}
	};

	const onSyncCodeChange = (value: string, fieldId: string) => {
		const isSyncCodeUniq = validateGroupSyncCode();

		if (!isSyncCodeUniq) return;

		if (
			value?.length &&
			value.length > 1 &&
			value.length < 60 &&
			Array.isArray(hierarchyGroups)
		) {
			setHierarchyGroups((hierarchyGroups) =>
				hierarchyGroups.map((group) => {
					return group.id === fieldId
						? { ...group, syncCode: value }
						: group;
				})
			);
		}
	};

	const onIsPropertyRadioChange = (
		value: boolean,
		id: string,
		level: string
	) => {
		if (value) {
			setActiveIsPropertyLevel((current) => ({
				...current,
				[level]: Array.isArray(current[level])
					? [...current[level], id]
					: [id]
			}));
		} else {
			setActiveIsPropertyLevel((current) => {
				const levelIds = current[level];

				return {
					...current,
					[level]: Array.isArray(levelIds)
						? levelIds.filter((lId) => lId !== id)
						: []
				};
			});
		}
	};
	const nextLevel = group.level + 1;

	const preparedFields = ((fields as unknown) as HierarchyGroupType[]).map(
		(field, index) => ({
			...field,
			key: index
		})
	);

	const dataSourceWithManageRow: HierarchyGroupType[] =
		group.level !== groupMaxHierarchyLevel
			? [
					...preparedFields,
					{
						// needs to proper work with ts
						...createNewHierarchyGroup({
							parentId: '',
							level: 0
						}),
						isManageRow: true,
						id: MANAGE_ROW_KEY
					}
			  ]
			: preparedFields;
	const columns = useColumns({
		level: nextLevel,
		onAddGroupClick,
		disabledIds,
		disableDeleteGroupButton: fields.length === 1,
		onIsPropertyRadioChange,
		onDeleteGroupClick,
		onGroupNameChange,
		onSyncCodeChange
	});

	return (
		<Layout className={styles.layout}>
			<Layout.Header aligned="right">
				<Fx grow={1}>
					<Space align="center" size="middle">
						<h1>
							{group ? (
								<>
									{group.name}
									<span className={styles.levelTitle}>
										{t(
											`groups.groupDetails.title.${
												group.level === 1
													? 'tenant'
													: 'group'
											}`,
											{ groupLevel: group.level }
										)}
									</span>
								</>
							) : (
								defaultGroupName
							)}
						</h1>
					</Space>
				</Fx>
			</Layout.Header>
			<Layout.Body fullWidth className={styles.layoutBody}>
				<MemoizedVirtualTable
					dataSource={dataSourceWithManageRow}
					columns={columns}
					disabledIds={disabledIds}
				/>
			</Layout.Body>
		</Layout>
	);
};

export default HierarchyGroup;
