import { Menu } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { generatePath, useLocation } from 'react-router-dom';
import { Button, Fx, Icon, Modal, Dropdown } from 'Components';
import { useStores } from 'Hooks/useStore';
import ModalContextProvider, { useModal } from 'Hooks/useModal';
import { useCreatePeakV2URL } from 'Hooks';
import { PagesWithDynamicUrl } from 'Hooks/useCreatePeakV2URL';
import { useHistory } from 'react-router';
import { getAppRoutes } from '../../App.constants';
import styles from './AppHeader.module.scss';
import InternationalizationDropdown from './InternationalizationDropdown/InternationalizationDropdown';
import { MENU_KEY_BY_LEAD_PATH, PATH_BY_MENU_KEY } from './AppHeader.constants';
import {
	AppHeaderMenuItem,
	NavMenuKey,
	UserProfileMenuKey,
	BlockNavigation
} from './AppHeader.types';
import { useMenuItems } from './AppHeader.hooks';
import { getLeadPath } from './AppHeader.helpers';
import LogoComponent from './LogoComponent';

const title = (menuItem: AppHeaderMenuItem) => (
	<Trans i18nKey={menuItem.title} />
);

type AppHeaderProps = BlockNavigation;

function AppHeader({ blockNavigation }: AppHeaderProps) {
	const { t } = useTranslation();

	const { authStore, systemsStore } = useStores();
	const history = useHistory();
	const { modal, showModal, hideModal } = useModal();
	const { pathname } = useLocation();
	const { menuItems } = useMenuItems();
	const { linkUserToPeakV2URL } = useCreatePeakV2URL({
		page: PagesWithDynamicUrl.Modules
	});
	const [selectedKeys, setSelectedKeys] = useState<NavMenuKey>();

	useEffect(() => {
		const leadPath = getLeadPath(pathname);

		const selectedKey = leadPath
			? MENU_KEY_BY_LEAD_PATH()[leadPath]
			: undefined;

		if (selectedKey) {
			setSelectedKeys(selectedKey);
		}
	}, [pathname]);

	const onLogout = () => {
		history.push(getAppRoutes().LOGOUT);
	};

	const onCancel = () => {
		hideModal();
	};

	const onNavMenuClick = async (info: { key: React.Key }) => {
		const menuKey = info.key as NavMenuKey;
		const path = PATH_BY_MENU_KEY()[menuKey];

		if (path) {
			history.push(generatePath(path));
			return;
		}

		const system = systemsStore.getSystemByName(menuKey);

		if (system) {
			await linkUserToPeakV2URL(system);
		}
	};

	const onHelpClick = () => {
		window.open(process.env.REACT_APP_VENZA_HELP_LINK, '_blank');
	};

	const menu = (
		<Menu
			onClick={(info) => {
				const menuKey = info.key as UserProfileMenuKey;
				switch (menuKey) {
					case UserProfileMenuKey.Profile:
						history.push(getAppRoutes().PROFILE);
						break;
					case UserProfileMenuKey.Logout:
						showModal({ value: null });
						break;
				}
			}}
		>
			<Menu.Item
				key={UserProfileMenuKey.Profile}
				disabled={blockNavigation}
			>
				{t('appHeader.myProfile')}
			</Menu.Item>
			<Menu.Item key={UserProfileMenuKey.Logout}>
				{t('appHeader.logout.title')}
			</Menu.Item>
		</Menu>
	);

	return (
		<Fx
			shrink={0}
			fullHeight
			justify="space-between"
			className={styles.root}
		>
			<Fx grow={1} fullHeight>
				<Fx shrink={0} fullHeight align="middle">
					<LogoComponent blockNavigation={blockNavigation} />
				</Fx>
				<div className={styles.headerMenuContainer}>
					<Menu
						mode="horizontal"
						className={styles.headerMenu}
						onClick={onNavMenuClick}
						selectedKeys={selectedKeys ? [selectedKeys] : undefined}
					>
						{menuItems.map((menuItem, index) => {
							if (menuItem.children) {
								return (
									<Menu.SubMenu
										key={index}
										className={styles.headerSubMenuItem}
										icon={<Icon type="chevronDown" />}
										popupClassName={styles.headerSubMenu}
										title={title(menuItem)}
									>
										{menuItem.children.map(
											(menuSubItem) => (
												<Menu.Item
													disabled={blockNavigation}
													key={
														menuSubItem.key ===
														NavMenuKey.PeakPortal
															? menuSubItem.title
															: menuSubItem.key
													}
												>
													{menuSubItem.key ===
													NavMenuKey.PeakPortal
														? menuSubItem.title
														: title(menuSubItem)}
												</Menu.Item>
											)
										)}
									</Menu.SubMenu>
								);
							}
							return (
								<Menu.Item
									key={menuItem.key}
									disabled={blockNavigation}
								>
									{title(menuItem)}
								</Menu.Item>
							);
						})}
					</Menu>
				</div>
			</Fx>
			<Fx align="middle" shrink={0} className={styles.profile}>
				{/* regarding to requirements of VNZ-1910: https://support.dataart.com/browse/VNZ-1910 */}
				{/* <Button type="text" thin>
					<Icon type="bell" />
				</Button> */}
				<Button type="text" thin onClick={onHelpClick}>
					<Icon type="help" />
				</Button>

				{authStore.currentUser && (
					<Dropdown
						overlay={menu}
						trigger={['click']}
						className={styles.profileName}
					>
						<div>
							{authStore.currentUser.firstName}{' '}
							{authStore.currentUser.lastName}
						</div>
					</Dropdown>
				)}
				<InternationalizationDropdown />
			</Fx>

			{modal && (
				<Modal
					visible
					title={t('appHeader.logout.title')}
					onOk={onLogout}
					okText={t('appHeader.logout.title')}
					cancelText={t('cancel')}
					onCancel={onCancel}
				>
					{t('appHeader.logout.description')}
				</Modal>
			)}
		</Fx>
	);
}

const AppHeaderContainer = ({ blockNavigation }: AppHeaderProps) => (
	<ModalContextProvider>
		<AppHeader blockNavigation={blockNavigation} />
	</ModalContextProvider>
);

export default AppHeaderContainer;
