import { FormItem, Text } from 'Components';
import { useTranslation } from 'react-i18next';

function NotificationsDisabledError() {
	const { t } = useTranslation();
	return (
		<FormItem
			help={
				<Text color="error">
					{t('profile.notifications.timeZoneRequired')}
				</Text>
			}
			validateStatus="error"
		/>
	);
}

export default NotificationsDisabledError;
