import React from 'react';
import { Spin as AntSpin } from 'antd';
import { SpinProps as AntSpinProps } from 'antd/lib/spin';
import Fx from 'Components/Fx/Fx';
import classNames from 'classnames';
import styles from './Spin.module.scss';

type SpinProps = AntSpinProps & {
	overlay?: boolean;
	gutter?: 'xs';
};
function Spin({ overlay, gutter, ...props }: SpinProps) {
	return (
		<Fx
			fullHeight
			justify="center"
			align="middle"
			className={classNames(
				props.className,
				styles.root,
				styles[gutter ?? ''],
				{
					[styles.overlay]: overlay
				}
			)}
		>
			<AntSpin {...props} />
		</Fx>
	);
}

export default Spin;
