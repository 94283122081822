import { Icon, Button, Layout as L } from 'Components';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import AppFooter from 'Pages/App/AppLayout/AppFooter/AppFooter';

import styles from './ErrorScreen.module.scss';
import { getAppRoutes } from 'Pages/App/App.constants';
import { useErrorBoundaryContext } from 'Contexts/ErrorBoundaryContext/ErrorBoundaryContext';

export interface ErrorScreenLayoutProps {
	translationPath: string;
	goHomeButton?: boolean;
	footer?: React.ReactNode;
}

const ErrorScreenLayout: React.FC<ErrorScreenLayoutProps> = ({
	translationPath,
	goHomeButton = true,
	footer,
	children
}) => {
	const history = useHistory();
	const { t } = useTranslation();

	const { setStatus } = useErrorBoundaryContext();

	const handleClick = () => {
		setStatus(null);

		history.push(getAppRoutes().HOME);
	};
	return (
		<Layout className={styles.layout}>
			<L.MountainView />
			<Layout.Content className={styles.content}>
				<Icon type="unlock" className={styles.icon} />
				<h1 className={styles.title}>
					{t(`${translationPath}.title`)}
				</h1>
				<div className={styles.description}>
					{children ?? t(`${translationPath}.description`)}
				</div>
				{goHomeButton && (
					<Button type="primary" shape="round" onClick={handleClick}>
						{t(`${translationPath}.button`)}
					</Button>
				)}
				{footer}
			</Layout.Content>
			<AppFooter />
		</Layout>
	);
};

export default ErrorScreenLayout;
