import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Fx, Layout, Tabs, Modal } from 'Components';
import { useStores } from 'Hooks/useStore';

import DashboardFilters from './DashboardFilters/DashboardFilters';
import DashboardExport from './DashboardExport/DashboardExport';
import { DashboardContext } from './DashboardContext';

import { DashboardFiltersValues } from './Dashboard.types';
import styles from './Dashboard.module.scss';
import { useDashboardTabs } from './Dashboard.hooks';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';

import { useModal } from 'Hooks/useModal';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { getAppRoutes } from '../App/App.constants';
import { getLeadPath } from '../App/AppLayout/AppHeader/AppHeader.helpers';

function Dashboard() {
	const { t } = useTranslation();
	const stores = useStores();
	const { tabs } = useDashboardTabs();

	const [filterValues, setFilterValues] = useState<DashboardFiltersValues>(
		{}
	);

	const [activeTab, setActiveTab] = useState(
		tabs.find((tab) => tab.hasPermission)?.key
	);

	const [hasProperties, setHasProperties] = useState(true);
	const onDashboardFiltersChange = (value: DashboardFiltersValues) => {
		setFilterValues({
			...filterValues,
			...value
		});
		window.console.log('Property toggle switch 1');
	};

	const {authStore, systemsStore } = useStores();
	const history = useHistory();
	const gousers = () => {
		history.push(getAppRoutes().USERS);
	};
	const { pathname } = useLocation();
	const buttonspushes = localStorage.getItem("initalpull");	

	const listallstores = JSON.stringify(systemsStore);
	const listallstoresarray = JSON.parse(listallstores);
	const awstokenbracket = stores.authStore;
	const listallawstokenbracket = JSON.stringify(awstokenbracket);
	const newtokenlist = JSON.parse(listallawstokenbracket);
	let isuperadm = '';
	const checkrole = JSON.stringify(newtokenlist.currentUser.primarySystemRole.id);
	if(checkrole == '1'){
		isuperadm = '&awsUserIsSuperAdmin=true';
	}
	let leadPath = getLeadPath(pathname);
	localStorage.setItem("APIadmin",isuperadm);
	if(typeof leadPath === 'string'){
		localStorage.setItem("APIpath",leadPath);
	}else{
		localStorage.setItem("APIpath",'');
	}
	const checkurl = (urlgo:string) => {
		if(newtokenlist.authSession.idToken.jwtToken){
			let useurlgo = urlgo;
			if(leadPath == '/'){
				leadPath = '';
			}else{
				useurlgo = useurlgo+'/';
			}
			const usetokennow = JSON.stringify(newtokenlist.authSession.idToken.jwtToken);
			const fullurl = leadPath+'/rlink.php?awsToken='+usetokennow.replace(/['"]+/g,'')+isuperadm+'&page=module'.replace('//','/');
			//console.log('***'+fullurl+'***');
			window.open(urlgo+fullurl);
		}
	}
	
	useEffect(() => {
		return () => {
			stores.clearDashboardStore();
		}
		// eslint-disable-next-line
	}, []);
	
	if(buttonspushes == "0"){
		localStorage.setItem("initalpull","1");
		setTimeout(() => {history.push(getAppRoutes().HOME)},500);
		//console.log('rerendered HOME...')
	}

	if(checkrole != '4'){
		return (
			<DashboardContext.Provider
				value={{
					filters: filterValues,
					setActiveTab,
					hasProperties
				}}
			>
				<Layout>
					<Layout.Heading>
						<Fx align="middle" justify="space-between">
							<h1>{t('dashboard.title')}</h1>
							<DashboardExport />
						</Fx>
					</Layout.Heading>
						<div id="temporarygreeting">
							
						</div>
					<Tabs
						destroyInactiveTabPane
						className={styles.tabs}
						tabBarExtraContent={
							<DashboardFilters
								onChange={onDashboardFiltersChange}
								onSetHasProperties={setHasProperties}
							/>
						}
						activeKey={activeTab}
						onChange={(activeTab) =>
							setActiveTab(activeTab as DashboardTabKey)
						}
					>
						{tabs.map(
							({ key, tab, component: Component, hasPermission }) => {
								if (!hasPermission) {
									return null;
								}
								return (
									<Tabs.TabPane key={key} tab={t(tab)}>
										<Component />
									</Tabs.TabPane>
								);
							}
						)}
					</Tabs>
				</Layout>
			</DashboardContext.Provider>
		);
	}else{
		return (
			<DashboardContext.Provider
				value={{
					filters: filterValues,
					setActiveTab,
					hasProperties
				}}
			>
				<Layout>
					<Layout.Heading>
						<Fx align="middle" justify="space-between">
							<h1>{t('dashboard.title')}</h1>
							<DashboardExport />
						</Fx>
					</Layout.Heading>
						<div id="temporarygreeting">
							
						</div>
					<Tabs
						destroyInactiveTabPane
						className={styles.tabs}
						tabBarExtraContent={
							<DashboardFilters
								onChange={onDashboardFiltersChange}
								onSetHasProperties={setHasProperties}
							/>
						}
						activeKey={activeTab}
						onChange={(activeTab) =>
							setActiveTab(activeTab as DashboardTabKey)
						}
					>
						{tabs.map(
							({ key, tab, component: Component, hasPermission }) => {
								if (!hasPermission) {
									return null;
								}
								return (
									<Tabs.TabPane key={key} tab={t(tab)}>
										<Component />
									</Tabs.TabPane>
								);
							}
						)}
					</Tabs>
				</Layout>
			</DashboardContext.Provider>
		);
	}

}

export default Dashboard;
