import { useEffect } from 'react';
import moment from 'moment';
import { LineTimeInterval, LineValue } from './LineChart.types';
import Chart, { ChartHelpers } from '../Chart';
export interface LineChartProps {
	loading: boolean;
	data: LineValue[];
	timeInterval?: LineTimeInterval;
	rangesYears: number[] | undefined;
	onInit(value: { rangesYears: moment.Moment[] }): void;
}

function LineChartContent(props: LineChartProps & ChartHelpers<'line'>) {
	useEffect(() => {
		if (!props.chart) {
			return;
		}
		props.onInit({
			rangesYears: props.chart.ranges.map((range) => range.end)
		});
		// eslint-disable-next-line
	}, [props.chart]);

	useEffect(() => {
		props.chart?.timeRangeEndYears(props.rangesYears);
	}, [props.chart, props.rangesYears]);

	useEffect(() => {
		props.chart?.data(props.data);
	}, [props.chart, props.data]);

	useEffect(() => {
		if (!props.timeInterval) return;
		props.chart?.timeInterval(props.timeInterval);
	}, [props.chart, props.timeInterval]);

	return null;
}

function LineChart(props: LineChartProps) {
	return (
		<Chart
			type="line"
			props={{}}
			loading={props.loading}
			inheritWidth
			height={305}
		>
			{(helpers) => <LineChartContent {...props} {...helpers} />}
		</Chart>
	);
}

export default LineChart;
