import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import {
	DateFormatted,
	OptionalValue,
	Tag,
	Text,
	DaysBeforeRemoval
} from 'Components';
import { User } from 'Services/Api/Users/Types';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { Link, generatePath } from 'react-router-dom';
import {
	useCreateColumns,
	UseCreateColumnsInterface
} from 'Components/Table/createColumns';
import {
	USER_ACTIVE_LABEL,
	USER_LOGIN_STATUS_LABEL,
	USER_LOGIN_STATUS_TAG_MAP
} from 'app-config';
import { getUserRoleLabel } from 'app-util';
import { GROUPS_LABEL } from 'Pages/Groups/Groups.constants';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import {
	UsersTableParams,
	UsersTableFilters,
	UsersTableFiltersValue
} from '../Users.types';
import GroupsColumnFilter from './GroupsColumnFilter/GroupsColumnFilter';
import { USER_STATUSES } from '../Users.constants';
import { getAppRoutes } from 'Pages/App/App.constants';

export interface ColumnSearchData {
	value: string;
	selectedKeys: React.Key[];
	setSelectedKeys: (selectedKeys: React.Key[]) => void;
	confirm?: (param?: FilterConfirmProps | undefined) => void;
}

export interface ColumnFilterData {
	value: string[];
	selectedKeys: React.Key[];
	setSelectedKeys: (selectedKeys: React.Key[]) => void;
	confirm?: (param?: FilterConfirmProps | undefined) => void;
}

interface UseColumnsProps {
	params: UsersTableParams | undefined;
	filters: UsersTableFilters;
	setFilterValue: (filters: Partial<UsersTableFiltersValue>) => void;
	withLinks?: boolean;
}

function getEditUserRoute(peakV3Id: string): string {
	const appRouteUser = getAppRoutes().USER;

	return generatePath(appRouteUser, { peakV3Id });
}

export function useColumnProps<T extends User>(
	helpers?: ReturnType<
		UseCreateColumnsInterface<T, UsersTableParams['filters']>
	>,
	props?: UseColumnsProps
) {
	const { t } = useTranslation();
	const groupIdsFilteredValue = [
		...(helpers?.filters.groupIds || []),
		...(helpers?.filters.ownershipGroupIds || [])
	];
	const withDeleted = helpers?.filters.isDeleted?.includes(true);
	const columns: Array<ColumnProps<T>> = [
		{
			...helpers?.search,
			...helpers?.sort,
			defaultSortOrder: 'ascend',
			title: t('user.cognitoLogin'),
			key: 'cognitoLogin',
			dataIndex: 'cognitoLogin',
			render: (_value, record) => {
				const description = !record.isDeleted ? (
					<>
						{props?.withLinks ? (
							<Link to={getEditUserRoute(record.peakV3Id)}>
								{record.cognitoLogin}
							</Link>
						) : (
							record.cognitoLogin
						)}
						<br />
						<Text color="default">
							{t('users.columns.lastLogin')}:{' '}
							{record.lastLoginDate ? (
								<DateFormatted date={record.lastLoginDate} />
							) : (
								<OptionalValue />
							)}
						</Text>
					</>
				) : (
					record.cognitoLogin
				);

				return (
					<>
						{description}
						<DaysBeforeRemoval days={record.daysBeforeRemoval} />
					</>
				);
			},
			sortOrder: helpers?.getSortOrder('cognitoLogin'),
			filteredValue: helpers?.filters.cognitoLogin ?? null,
			width: 180
		},
		{
			...helpers?.search,
			...helpers?.sort,
			title: t('user.firstName'),
			key: 'firstName',
			dataIndex: 'firstName',
			sortOrder: helpers?.getSortOrder('firstName'),
			filteredValue: helpers?.filters.firstName ?? null,
			width: 160
		},
		{
			...helpers?.search,
			...helpers?.sort,
			title: t('user.lastName'),
			key: 'lastName',
			dataIndex: 'lastName',
			sortOrder: helpers?.getSortOrder('lastName'),
			filteredValue: helpers?.filters.lastName ?? null,
			width: 160
		},
		{
			...helpers?.search,
			...helpers?.sort,
			title: t('user.email'),
			key: 'email',
			dataIndex: 'email',
			sortOrder: helpers?.getSortOrder('email'),
			filteredValue: helpers?.filters.email ?? null,
			width: 160
		},

		{
			...helpers?.filter,
			...helpers?.sort,
			sortOrder: helpers?.getSortOrder('systemRoles'),
			title: t('user.primarySystemRoleId'),
			key: 'systemRoles',
			dataIndex: 'systemRoles',
			filters: props?.filters.systemRoles,
			filteredValue: helpers?.filters.systemRoles ?? null,
			render: (_value, record) => {
				const roles = [getUserRoleLabel(record.primarySystemRole.role)];
				if (record.secondarySystemRoles) {
					roles.push(
						...record.secondarySystemRoles.map(
							(secondarySystemRole) => secondarySystemRole.role
						)
					);
				}
				return roles.join(', ');
			},
			width: 150
		},
		{
			...helpers?.filter,
			title: t('user.groups'),
			key: 'groupIds',
			dataIndex: 'groupIds',
			filterIcon: props && (
				<GroupsColumnFilter
					initialValue={{
						groupIds: helpers?.filters.groupIds ?? [],
						ownershipGroupIds:
							helpers?.filters.ownershipGroupIds ?? []
					}}
					setFilterValue={props.setFilterValue}
					withDeleted={withDeleted}
				/>
			),
			filters: [],
			filterDropdownVisible: false,
			filtered: Boolean(groupIdsFilteredValue.length),
			render: (_, record) => {
				switch (record.primarySystemRole.role) {
					case SystemRoleType.SuperAdmin:
						return t(GROUPS_LABEL.ALL_TENANTS);
					case SystemRoleType.Owner:
						return record.ownershipGroup?.name;
					default:
						return record.groups.map((group, index, arr) => (
							<span key={group.id}>
								{group.name}
								{group.deletedDate && (
									<Text color="default">
										&nbsp;(
										{t('appConfig.deletedLabel.yes')})
									</Text>
								)}
								{index !== arr.length - 1 && ', '}
							</span>
						));
				}
			},
			width: 150
		},
		{
			...helpers?.filter,
			...helpers?.sort,
			sortOrder: helpers?.getSortOrder('isActive'),
			title: withDeleted ? t('user.previousStatus') : t('user.status'),
			key: 'isActive',
			dataIndex: 'isActive',
			filters: [
				{
					text: USER_ACTIVE_LABEL.YES(),
					value: true
				},
				{
					text: USER_ACTIVE_LABEL.NO(),
					value: false
				}
			],
			filteredValue: helpers?.filters.isActive || null,
			render: (_, record) => (
				<Tag type={record.isActive ? 'success' : 'default'}>
					{record.isActive
						? USER_ACTIVE_LABEL.YES()
						: USER_ACTIVE_LABEL.NO()}
				</Tag>
			),
			width: 130
		},
		{
			...helpers?.filter,
			title: t('user.loginStatus'),
			key: 'loginStatus',
			dataIndex: 'loginStatus',
			filters: USER_STATUSES.map((value) => ({
				...value,
				text: value.text()
			})),
			filteredValue: helpers?.filters.loginStatus || null,
			render: (_, record) => {
				if (!record.loginStatus) {
					return null;
				}
				return (
					<Tag type={USER_LOGIN_STATUS_TAG_MAP[record.loginStatus]}>
						{USER_LOGIN_STATUS_LABEL[record.loginStatus]()}
					</Tag>
				);
			},
			width: 160
		}
	];
	return columns;
}

export const useColumns = (props: UseColumnsProps) => {
	const { t } = useTranslation();
	const helpers = useCreateColumns<User, UsersTableParams['filters']>({
		filterValues: props.params?.filters,
		sortDesc: props.params?.sort,
		isControllable: true
	});

	const withDeleted = helpers.filters.isDeleted?.includes(true);

	const columns: Array<ColumnProps<User>> = useColumnProps(helpers, props);
	if (withDeleted) {
		columns.push({
			...helpers.sort,
			sortOrder: helpers?.getSortOrder('softDeletedDate'),
			title: t('user.deletionDate'),
			key: 'softDeletedDate',
			dataIndex: 'softDeletedDate',
			filteredValue: helpers?.filters.softDeletedDate || null,
			render: (_, record) =>
				record.softDeletedDate && (
					<DateFormatted date={record.softDeletedDate} />
				),
			width: 150
		});
	}
	return columns;
};
