import { PaginationProps, TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import {
	Fx,
	InputSearchToggle,
	Layout,
	Pagination,
	Permission,
	Table
} from 'Components';
import { createSortDescription } from 'Components/Table/createColumns';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
	AuditLog as AuditLogType,
	GetAuditLogsRequest,
	GetAuditLogsResponse
} from 'Services/Api/AuditLog/Types';
import ExportAuditLogButton from './ExportAuditLogButton';
import useColumns, { TableFilterValues } from './columns';
import { useQuery } from '@apollo/client';
import { GET_AUDIT_LOGS } from 'Services/Api/AuditLog/Queries';
import { APP_CONFIG } from 'app-config';
import { PermissionCode } from 'Services/Api/Users/Types';

const INITIAL_PARAMS: GetAuditLogsRequest = {
	itemsPerPage: APP_CONFIG.TABLE_PAGE_SIZE,
	page: 1,
	sort: createSortDescription({
		columnKey: 'date',
		order: 'descend'
	}),
	filters: {}
};

function AuditLog() {
	const { t } = useTranslation();

	const result = useQuery<GetAuditLogsResponse, GetAuditLogsRequest>(
		GET_AUDIT_LOGS,
		{
			variables: INITIAL_PARAMS,
			notifyOnNetworkStatusChange: true
		}
	);

	const handleSearch = (searchTerm: string) => {
		result.refetch({
			...result.variables,
			page: 1,
			search: searchTerm
		});
	};

	const handleTable: TableProps<AuditLogType>['onChange'] = (
		_,
		filters,
		sorter
	) => {
		const sort = createSortDescription<AuditLogType>(
			sorter as SorterResult<AuditLogType>
		);
		const values = filters as TableFilterValues;

		result.refetch({
			...result.variables,
			page: 1,
			sort,
			filters: {
				tenant: values.tenant?.[0] ?? undefined,
				login: values.login?.[0] ?? undefined,
				ip: values.ip?.[0] ?? undefined,
				browser: values.browser?.[0] ?? undefined,
				modifyBy: values.modifyBy?.[0] ?? undefined,
				date: values.date?.length
					? {
							from: values.date[0],
							to: values.date[1]
					  }
					: undefined,
				page: values.page ?? undefined,
				action: values.action ?? undefined
			}
		});
	};

	const handlePaginationChange: PaginationProps['onChange'] = (
		page,
		pageSize
	) => {
		result.refetch({
			...result.variables,
			page,
			itemsPerPage: pageSize
		});
	};

	const columns = useColumns({
		params: result.variables
	});

	const { variables, loading } = result;

	const data = result.data?.getAuditLogs;
	const dataSource = data?.data;

	return (
		<Layout>
			<Layout.Heading prefixNode={<h1>{t('auditLog.title')}</h1>}>
				{({ searchFieldClassName }) => (
					<Fx justify="space-between" align="middle">
						<div className={searchFieldClassName}>
							<InputSearchToggle
								value={variables?.search}
								onChange={handleSearch}
							/>
						</div>
						<Permission code={PermissionCode.ExportAuditLog}>
							{({ permitted }) =>
								permitted && (
									<Fx>
										<ExportAuditLogButton
											params={variables}
											disabled={!dataSource?.length}
										/>
									</Fx>
								)
							}
						</Permission>
					</Fx>
				)}
			</Layout.Heading>
			<Table<AuditLogType>
				dataSource={dataSource}
				rowKey="id"
				columns={columns}
				loading={loading}
				pagination={false}
				onChange={handleTable}
			/>
			<Fx shrink={0}>
				<Pagination
					total={data?.totalCount ?? 0}
					pageSize={variables?.itemsPerPage ?? 0}
					current={variables?.page ?? 0}
					onChange={handlePaginationChange}
				/>
			</Fx>
		</Layout>
	);
}

export default observer(AuditLog);
