import { APP_CONFIG } from 'app-config';
import {
	ResponseErrorType,
	AxiosErrorResponse,
	ErrorInfo,
	APIErrorCodes,
	ErrorStatusCode
} from 'app-types';
import { Notification } from 'Components';
import { isI18nPathPresent } from 'i18n/i18n.helpers';
import { TFunction } from 'i18next';
import { isArray, isPlainObject, isString } from 'lodash';
import { SchemaFieldDescription } from 'yup/lib/schema';
import { getFieldErrors } from './graphql/graphql.helpers';

export const handleAxiosError = (e: unknown, t: TFunction) => {
	const error = e as AxiosErrorResponse;
	const info = error.response?.data.error;
	if (!info || !isString(info)) return;
	const path = `${APP_CONFIG.I18N_ERROR_KEY}.${info}`;
	const message = info && isI18nPathPresent(path, t) ? t(path) : info;
	Notification.error({
		description: message
	});
};

export const getFormErrors = (
	value: ResponseErrorType | undefined,
	t: TFunction,
	meta: Record<string, SchemaFieldDescription>,
	translationPath?: string
) => {
	const error = isArray(value)
		? value
		: isPlainObject(value) && (value as ErrorInfo).errorCode
		? ([value] as ErrorInfo[])
		: [];
	return getFieldErrors(error, meta, t, translationPath);
};

export const isAxiosErrorCodeEqual = (
	errorCode: APIErrorCodes,
	error: AxiosErrorResponse
) => {
	return error.response?.data.error === errorCode;
};

export const getAxiosErrorType = (error: AxiosErrorResponse) => {
	return {
		isNotFoundError: error.response?.status === ErrorStatusCode.NotFound,
		isUnauthorizedError:
			error.response?.status === ErrorStatusCode.Unauthorized,
		isUnprocessableEntity:
			error.response?.status === ErrorStatusCode.UnprocessableEntity
	};
};
