import { STANDARD_REPORT_TYPE_MAP } from 'Pages/Reports/Standard/Standard.constants';
import { Translation } from 'react-i18next';
import { Report } from 'Services/Api/Reports/Types';

type EditReportHeadingProps = Pick<Report, 'reportType' | 'name'>;
const EditReportHeading = ({ name, reportType }: EditReportHeadingProps) => (
	<Translation>
		{(t) => (
			<>
				{name} ({STANDARD_REPORT_TYPE_MAP[reportType].label(t)})
			</>
		)}
	</Translation>
);

export default EditReportHeading;
