import { usePermission } from 'Components/Permission/Permission.hooks';
import { useMemo } from 'react';
import { DASHBOARD_TABS } from './Dashboard.constants';

export const useDashboardTabs = () => {
	const { getHasPermission } = usePermission();
	const tabs = useMemo(() => {
		return DASHBOARD_TABS.map(({ permission, ...tab }) => ({
			...tab,
			hasPermission: !permission || getHasPermission(permission).permitted
		}));
	}, [getHasPermission]);

	return {
		tabs,
		exportedTabs: tabs.filter((tab) => tab.isExported !== false)
	};
};
