import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import { Alert, Button, Input } from 'Components';
import { VALIDATION } from '../AuthValidation';

const TRANSLATION_PATH = 'auth.resetPassword';

const schema = yup.object().shape({
	login: VALIDATION.login
});

export type ResetPasswordFormValues = {
	login: string;
};

interface ResetPasswordFormProps {
	errorMessage?: string;
	initialValues?: ResetPasswordFormValues;
	onSubmit: (
		values: ResetPasswordFormValues,
		helpers: FormikHelpers<ResetPasswordFormValues>
	) => void;
}
function ResetPasswordForm(props: ResetPasswordFormProps) {
	const { t } = useTranslation();

	const initialValues: ResetPasswordFormValues = props.initialValues ?? {
		login: ''
	};
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={props.onSubmit}
			validationSchema={schema}
			validateOnMount
		>
			{({ isValid, isSubmitting, status }) => (
				<Form>
					{props.errorMessage && (
						<Alert
							showIcon
							message=""
							description={props.errorMessage}
							type="error"
						/>
					)}
					{status && (
						<Alert
							showIcon
							message=""
							description={status}
							type="error"
						/>
					)}
					<h1>{t(`${TRANSLATION_PATH}.title`)}</h1>
					<p>{t(`${TRANSLATION_PATH}.description`)}</p>

					<Input.FormikField
						name="login"
						label={t(`${TRANSLATION_PATH}.inputs.login`)}
					/>

					<Button
						htmlType="submit"
						type="primary"
						shape="round"
						disabled={!isValid}
						loading={isSubmitting}
						fullWidth
					>
						{t(`${TRANSLATION_PATH}.submitText`)}
					</Button>
				</Form>
			)}
		</Formik>
	);
}

export default ResetPasswordForm;
