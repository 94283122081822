import { gql } from '@apollo/client';

export const GET_JOB_CLASSES = gql`
	query getJobClassesList($groupIds: [Int]!) {
		getJobClassesList(groupIds: $groupIds) {
			id
			name
			peakV3Id
			defaultUserRoleId
		}
	}
`;

export const GET_USER_ROLES = gql`
	query getUserRolesList {
		getUserRolesList {
			id
			role
		}
	}
`;
