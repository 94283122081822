import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Form, Formik, FormikHelpers } from 'formik';
import { Modal, Input, Notification } from 'Components';
import { useModal } from 'Hooks/useModal';
import { CloneRoleModalProps, Values } from './types';
import { CLONE_ROLE } from 'Services/Api/Roles/Mutations';
import { CloneRoleRequest } from 'Services/Api/Roles/Types';
import { APIErrorCodes } from 'app-types';
import { checkApolloError } from 'Helpers/graphql';
import { VALIDATION } from 'Helpers/validations';
import TInfoText from 'Contexts/TInfoText/TInfoText';

const schema = yup
	.object()
	.shape<Partial<Record<keyof Values, yup.AnySchema>>>({
		role: VALIDATION.baseName.label('role.editRole.fields.name').required()
	});

const INITIAL_VALUES: Values = {
	role: ''
};

const TRANSLATION_PATH = 'roles.rolesActionsModals.cloneModal';

function CloneRoleModal({ roleId, roleName, onSubmit }: CloneRoleModalProps) {
	const { t } = useTranslation();
	const { hideModal } = useModal();
	const [cloneRole, result] = useMutation<unknown, CloneRoleRequest>(
		CLONE_ROLE,
		{ fetchPolicy: 'no-cache' }
	);
	const onCloneSubmit = async (
		values: Values,
		helpers: FormikHelpers<Values>
	) => {
		try {
			await cloneRole({
				variables: {
					input: {
						id: Number(roleId),
						role: values.role
					}
				}
			});
			Notification.success({
				description: t(`${TRANSLATION_PATH}.notificationSuccessMessage`)
			});
			onSubmit();
			hideModal();
		} catch (e) {
			const error = e as Error;
			if (checkApolloError(error).is(APIErrorCodes.Unique)) {
				helpers.setSubmitting(false);
				helpers.setErrors({
					role: t(`${TRANSLATION_PATH}.errorNotificationUniq`)
				});

				return;
			}

			Notification.error({
				description: t('errorCodes.genericErrorMessage')
			});
		}
	};

	return (
		<Formik
			onSubmit={onCloneSubmit}
			initialValues={INITIAL_VALUES}
			validationSchema={schema}
		>
			{({ submitForm, isValid, dirty }) => {
				return (
					<Modal
						visible
						title={
							<TInfoText
								path={`${TRANSLATION_PATH}.title`}
								params={{ name: roleName }}
							/>
						}
						okText={t(`${TRANSLATION_PATH}.okText`)}
						onOk={submitForm}
						onCancel={hideModal}
						okButtonProps={{
							disabled: !(isValid && dirty),
							loading: result.loading
						}}
						maskClosable={false}
					>
						<p>{t(`${TRANSLATION_PATH}.description`)}</p>

						<Form>
							<Input.FormikField
								name="role"
								label={t(`role.editRole.fields.name`)}
								required
								marginBottom={false}
							/>
						</Form>
					</Modal>
				);
			}}
		</Formik>
	);
}

export default CloneRoleModal;
