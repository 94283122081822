import * as yup from 'yup';
import { FIELD_PATTERN } from 'Helpers/validations';
import { schema as userSchema } from '../../User/schema';
import { AddUser, AddUsersFormValues } from './AddUsers.types';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import { required } from 'Helpers/validations/validations.helpers';

const addMultipleUsersSchema = userSchema
	.omit(['primarySystemRoleId', 'phoneNumber', 'groups'])
	.shape({
		primarySystemRoleId: yup.object().required(),
		// it needs because of the react-hook-form library doesn't support scheme not required fields see https://github.com/react-hook-form/react-hook-form/issues/1781
		phoneNumber: yup.string().test({
			test: (value) => {
				if (!value) return true;
				return (
					(value?.length <= 16 &&
						value?.length >= 9 &&
						FIELD_PATTERN.phone.test(value)) ||
					!value
				);
			},
			message: 'invalid phone'
		}),
		groups: yup.array().when('primarySystemRoleId', {
			is: (primarySystemRoleId: AddUser['primarySystemRoleId']) =>
				primarySystemRoleId?.value !== SystemRoleType.SuperAdmin,
			then: yup.array().min(1, required).required()
		})
	});

export const schema = yup.object<
	Record<keyof Omit<AddUsersFormValues, 'data'>, yup.AnySchema>
>({
	users: yup.array().of(addMultipleUsersSchema)
});
