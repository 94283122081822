import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { unstable_batchedUpdates } from 'react-dom';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { LANGUAGE_BY_ID, ID_BY_LANGUAGE } from 'i18n/i18n.constants';
import {
	Button,
	Icon,
	Select,
	LanguageMenuItem,
	Notification
} from 'Components';
import {
	AddUsersCopyValues,
	AddUsersFormValues,
	AddUser
} from '../../AddUsers.types';
import { UserModuleSettings } from 'Services/Api/Users/Types';
import styles from './Copier.module.scss';
import GroupField from './GroupField';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import CopierModuleSettingsFields from './CopierModuleSettingsFields';
import PrimaryRoleField from './PrimaryRoleField';
import { TIMEOUT_DELAY } from '../../AddUsers.constants';
import { LanguageI18nKey } from 'Services/Api/Common/Types';

interface CopierProps {
	isVisible: boolean;
	closeCopier: () => void;
}

interface MapCurrentUsersToCopierSubmitValues {
	usersWithCopiedValues: AddUser[];
	usersErrorsPaths: string[];
}

const INITIAL_VALUES: AddUsersCopyValues = {};

function Copier(props: CopierProps) {
	const { t } = useTranslation();
	const {
		getValues,
		reset,
		clearErrors,
		formState: { errors }
	} = useFormContext<AddUsersFormValues>();
	const currentValues = getValues();

	const onSubmit = (values: AddUsersCopyValues) => {
		// @NOTE: setTimeout needs for handle situation when form handle huge amount of users and ui looks blocked for user,
		// so using 'setTimeout' system is able to render a spinner
		setTimeout(() => {
			// @TODO: unstable_batchedUpdates is outdated functionality and has to be deleted after moving to react > v.18
			unstable_batchedUpdates(() => {
				const isValuesModuleSettingsEmpty = isEmpty(
					values.moduleSettings
				);
				const {
					usersWithCopiedValues,
					usersErrorsPaths
				} = currentValues.users.reduce<MapCurrentUsersToCopierSubmitValues>(
					(acc, user, i) => {
						if (
							!isValuesModuleSettingsEmpty &&
							errors?.users?.[i]?.moduleSettings
						) {
							Object.keys(
								values?.moduleSettings as UserModuleSettings
							).forEach((fieldName: string) => {
								acc.usersErrorsPaths.push(
									`users.${i}.moduleSettings.${fieldName}`
								);
							});
						}

						if (
							Array.isArray(values.groups) &&
							errors?.users?.[i]?.groups
						) {
							acc.usersErrorsPaths.push(`users.${i}.groups`);
						}

						acc.usersWithCopiedValues.push({
							...user,
							languageId: values.languageId || user.languageId,
							timezoneId: values.timezoneId || user.timezoneId,
							primarySystemRoleId:
								values.primarySystemRoleId ||
								user.primarySystemRoleId,
							groups:
								values.primarySystemRoleId?.value ===
								SystemRoleType.SuperAdmin
									? undefined
									: values.groups || user.groups,
							moduleSettings: isValuesModuleSettingsEmpty
								? // assign new reference for the case if properties are modified
								  { ...user.moduleSettings }
								: {
										...user.moduleSettings,
										...values.moduleSettings
								  }
						});

						return acc;
					},
					{ usersWithCopiedValues: [], usersErrorsPaths: [] }
				);

				if (usersErrorsPaths.length) {
					// @ts-ignore
					clearErrors(usersErrorsPaths);
				}

				reset(
					{ ...currentValues, users: usersWithCopiedValues },
					{
						keepDirty: true,
						keepErrors: true,
						keepTouched: true,
						keepIsSubmitted: true
					}
				);

				props.closeCopier();
				Notification.success({
					description: t(
						'users.addUsers.notification.copiedSuccessfully'
					)
				});
			});
		}, TIMEOUT_DELAY);
	};

	if (!props.isVisible) return null;

	return (
		<Formik initialValues={INITIAL_VALUES} onSubmit={onSubmit}>
			{({ isSubmitting }) => (
				<Form className={styles.form}>
					<Select.FormikField
						name="timezoneId"
						label={t('user.timezoneId')}
						className={styles.select}
					>
						{currentValues.data.timezones?.map((timeZone) => (
							<Select.Option
								key={timeZone.id}
								value={timeZone.id}
							>
								{timeZone.zone}
							</Select.Option>
						))}
					</Select.FormikField>
					<Select.FormikField
						name="languageId"
						label={t('user.languageId')}
						className={styles.selectShort}
					>
						{currentValues.data.languages?.map((language) => (
							<Select.Option
								key={language.id}
								value={language.id}
								// @NOTE: 'disabled' implemented according to https://support.dataart.com/browse/VNZ-1931
								disabled={
									Number(
										ID_BY_LANGUAGE[LanguageI18nKey.English]
									) !== language.id
								}
							>
								<LanguageMenuItem
									menuKey={LANGUAGE_BY_ID[language.id]}
								/>
							</Select.Option>
						))}
					</Select.FormikField>
					<PrimaryRoleField />
					<GroupField />
					<CopierModuleSettingsFields />
					<Button
						type="primary"
						shape="round"
						htmlType="submit"
						loading={isSubmitting}
					>
						<Icon type="check" />
						{t('apply')}
					</Button>
				</Form>
			)}
		</Formik>
	);
}

export default Copier;
