import * as yup from 'yup';
import { VALIDATION } from 'Helpers/validations';
import { AddUserValues, EditProfileValues } from './User.types';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import { isBoolean, without } from 'lodash';
import moment from 'moment';
import { required } from 'Helpers/validations/validations.helpers';
import { VALIDATION as AUTH_VALIDATION } from 'Pages/Auth/AuthValidation';
type ProfileNotifications = Required<EditProfileValues>['notifications'];
const NOTIFICATION_FIELDS: (keyof ProfileNotifications)[] = [
	'time',
	'day',
	'getWeeklyReport',
	'getDeletionReport'
];

export const schema = yup.object().shape({
	firstName: VALIDATION.name.label('user.firstName').required(),
	lastName: VALIDATION.name.label('user.lastName').required(),
	phoneNumber: VALIDATION.phone.label('user.phoneNumber'),
	email: VALIDATION.email.label('user.email').required(),
	cognitoLogin: AUTH_VALIDATION.login.required(),
	primarySystemRoleId: yup
		.array()
		.label('user.primarySystemRoleId')
		.test('is-array-includes-role', required, (value) => {
			const list = value as AddUserValues['primarySystemRoleId'];
			return Boolean(list?.some((item) => item.data.isPrimary));
		})
		.min(1, required)
		.required(),
	groups: yup
		.array()
		.label('user.group')
		.when('primarySystemRoleId', {
			is: (value: unknown) => {
				const list = value as AddUserValues['primarySystemRoleId'];
				const withRole = list?.[0];
				if (!withRole?.data.isPrimary) {
					return false;
				}
				return (
					withRole.data.role !== SystemRoleType.Owner &&
					withRole.data.role !== SystemRoleType.SuperAdmin
				);
			},
			then: yup.array().min(1, required).required()
		}),
	ownershipGroup: yup
		.object()
		.label('user.ownershipGroup')
		.when('primarySystemRoleId', {
			is: (value: unknown) => {
				const list = value as AddUserValues['primarySystemRoleId'];
				const withRole = list?.[0];
				if (!withRole?.data.isPrimary) {
					return false;
				}
				return withRole.data.role === SystemRoleType.Owner;
			},
			then: yup.object().required()
		})
});

const isNotificationsRequired = (
	...deps: ProfileNotifications[keyof ProfileNotifications][]
) => {
	return deps.find((dep) => {
		const isDateAndValid = !moment.isDate(dep) || moment(dep).isValid();
		const isBoolAndValid = !isBoolean(dep) || dep === true;
		return Boolean(dep) && isDateAndValid && isBoolAndValid;
	});
};

export const schemaProfile = schema.concat(
	yup
		.object()
		.shape({
			notifications: yup.object().shape(
				{
					time: yup
						.date()
						.nullable()
						.label('profile.notifications.fields.timeHelp')
						.when(without(NOTIFICATION_FIELDS, 'time'), {
							is: isNotificationsRequired,
							then: (s) => s.required()
						}),
					day: yup
						.number()
						.nullable()
						.label('profile.notifications.fields.weekdayHelp')
						.when(without(NOTIFICATION_FIELDS, 'day'), {
							is: isNotificationsRequired,
							then: (s) => s.required()
						}),
					getDeletionReport: yup.boolean(),
					getWeeklyReport: yup.boolean()
				},
				[['time', 'day']]
			)
		})
		.nullable()
		.default({})
);
