export type ShowFilterValue = 'spotCheck:current' | 'spotCheck:last';

export interface ShowFilter {
	value: ShowFilterValue;
	label: string;
}

export enum SpotCheckWidgets {
	SpotCHeckOverview = 'spot_check_overview'
}
