import { APP_CONFIG } from 'app-config';
import { DataTreeLayoutProps } from 'Contexts/DataTree/components/DataTreeLayout/DataTreeLayout';
import DataTree from 'Contexts/DataTree/DataTree';
import { apolloClient } from 'Services/Api/client';
import { GET_OWNERSHIP_GROUPS } from 'Services/Api/Groups/Queries';
import {
	GetOwnershipGroupsRequest,
	GetOwnershipGroupsResponse,
	OwnershipGroup
} from 'Services/Api/Groups/Types';
import styles from 'Pages/Groups/GroupsLayout.module.scss';

export function OwnershipGroupsSearch(
	props: Parameters<DataTreeLayoutProps['header']>[0]
) {
	const onSearch = async (searchTerm: string) => {
		const response = await apolloClient.query<
			GetOwnershipGroupsResponse,
			GetOwnershipGroupsRequest
		>({
			query: GET_OWNERSHIP_GROUPS,
			fetchPolicy: 'no-cache',
			variables: {
				filters: {
					name:
						searchTerm.length >= APP_CONFIG.SEARCH_MIN_LENGTH
							? searchTerm
							: ''
				}
			}
		});
		return response.data.listOwnershipGroups;
	};

	return (
		<DataTree.Search<OwnershipGroup>
			{...props}
			search={onSearch}
			toOption={(value) => ({
				key: String(value.id),
				value: value.id,
				label: value.name
			})}
			getPopupContainer={() => document.body}
			dropdownClassName={styles.searchDropdown}
		/>
	);
}

export default OwnershipGroupsSearch;
