import { useLazyQuery } from '@apollo/client';
import { ErrorPage, Spin } from 'Components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GET_OWNERSHIP_GROUP } from 'Services/Api/Groups/Queries';
import {
	GetOwnershipGroupRequest,
	GetOwnershipGroupResponse
} from 'Services/Api/Groups/Types';
import AddOwnershipGroup from '../AddOwnershipGroup/AddOwnershipGroup';

import { useErrorBoundary } from 'Hooks';
export interface EditOwnershipGroupParams {
	groupId: string;
}
function EditOwnershipGroup() {
	const params = useParams<EditOwnershipGroupParams>();
	const errorBoundary = useErrorBoundary();
	const [getOwnershipGroup, result] = useLazyQuery<
		GetOwnershipGroupResponse,
		GetOwnershipGroupRequest
	>(GET_OWNERSHIP_GROUP, {
		onError: errorBoundary.onError
	});
	const groupId = params.groupId ? Number(params.groupId) : undefined;

	useEffect(() => {
		if (groupId) {
			getOwnershipGroup({
				variables: {
					id: groupId
				}
			});
		}
		// eslint-disable-next-line
	}, []);
	const group = result.data?.getOwnershipGroupById ?? null;

	if (!groupId || !result.called || result.loading) {
		return <Spin />;
	}

	if (result.error) {
		return <ErrorPage status={404} />;
	}

	return <AddOwnershipGroup group={group} />;
}

export default EditOwnershipGroup;
