import { Button, Notification } from 'Components';
import { useTranslation } from 'react-i18next';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { Progress } from 'antd';
import cn from 'classnames';
import {
	getIsDone,
	getIsFailure,
	getIsPending,
	getIsSuccess
} from 'Stores/util';
import styles from './ProgressInfo.module.scss';
import {
	DownloadReportRequestBase,
	FileType
} from 'Services/Api/Reports/Types';

type ProgressInfoProps = DownloadReportRequestBase & FileType;

const ProgressInfo = observer(({ downloadId, fileType }: ProgressInfoProps) => {
	const { downloadReportsStore } = useStores();
	const info = downloadReportsStore.byDocumentId[downloadId];
	if (!info) return null;
	const { status } = info;
	return (
		<>
			<Progress
				percent={100}
				showInfo={false}
				className={cn({
					[styles.progress]: !getIsDone(status)
				})}
			/>
			<br />
			<br />
			{getIsSuccess(status) && (
				<ProgressInfoSuccess
					downloadId={downloadId}
					fileType={fileType}
				/>
			)}

			{getIsFailure(status) && (
				<ProgressInfoFailure
					downloadId={downloadId}
					fileType={fileType}
				/>
			)}
		</>
	);
});

const ProgressInfoSuccess = ({ downloadId, fileType }: ProgressInfoProps) => {
	const { t } = useTranslation();
	const { downloadReportsStore } = useStores();

	const handleClick = async () => {
		await downloadReportsStore.downloadReportByUUID({ downloadId });
		Notification.close(downloadId);
	};

	return (
		<>
			{t('dashboard.pdfExport.progress.success.description')}
			<br />
			<Button link thin type="text" onClick={handleClick}>
				{t(`dashboard.pdfExport.progress.success.okText.${fileType}`)}
			</Button>
		</>
	);
};

const ProgressInfoFailure = observer(({ downloadId }: ProgressInfoProps) => {
	const { t } = useTranslation();
	const { downloadReportsStore } = useStores();

	const handleClick = async () => {
		try {
			await downloadReportsStore.emailMeReport({ downloadId });
			Notification.close(downloadId);
			Notification.success({
				description: t('dashboard.pdfExport.emailMeSuccess')
			});
		} catch (e) {
			Notification.error({
				description: t('errorCodes.genericErrorMessage')
			});
		}
	};

	const info = downloadReportsStore.byDocumentId[downloadId];
	if (!info) return null;
	return (
		<>
			{t('dashboard.pdfExport.progress.failure.description')}
			<br />
			<Button
				link
				thin
				type="text"
				onClick={handleClick}
				loading={getIsPending(info.statusEmailMeReport)}
			>
				{t('dashboard.pdfExport.progress.failure.okText')}
			</Button>
		</>
	);
});

export default ProgressInfo;
