import { useConfigs, useInjectMeta } from 'Hooks';

export function useUser() {
	const configs = useConfigs();

	const { getInjector } = useInjectMeta();

	return {
		injectMeta: getInjector({
			groups: {
				amount: configs.maxGroupsAmountForUser
			}
		})
	};
}
