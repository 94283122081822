import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { AddUsersCopyValues } from '../../AddUsers.types';
import {
	default as PrimaryRoleFieldComponent,
	PrimaryRoleFieldProps
} from '../PrimaryRoleField/PrimaryRoleField';
import styles from './Copier.module.scss';

function PrimaryRoleField() {
	const { t } = useTranslation();
	const { setFieldValue } = useFormikContext<AddUsersCopyValues>();

	const onChange: PrimaryRoleFieldProps['onChange'] = (value) => {
		setFieldValue('primarySystemRoleId', value);
	};

	return (
		<PrimaryRoleFieldComponent
			onChange={onChange}
			className={styles.select}
			label={t('user.primarySystemRoleId')}
		/>
	);
}

export default PrimaryRoleField;
