import { Trans, useTranslation } from 'react-i18next';
import { useState, useCallback, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { Notification } from 'Components';
import styles from './GroupsHierarchyForm.module.scss';
import { LevelItem, DownloadGroupsTemplateButton } from './components';
import { useGroupsHierarchyContext } from '../GroupsHierarchyContext';
import {
	isGroupsHierarchyLimitReached,
	createDefaultValues
} from '../GroupsHierarchy.helpers';
import { Level, SelectLevel } from '../components';

function prepareLevelItemArray(
	amount: number | undefined,
	parentLevel: number
): number[] {
	return amount
		? Array(amount)
				.fill(parentLevel)
				.map((parent, index) => parent + index + 1)
		: [];
}

const GroupsHierarchyForm = () => {
	const { t } = useTranslation();

	const [levelWithProperty, setLevelIsProperty] = useState<number>(0);
	const {
		levelNumber,
		setLevelNumber,
		parent,
		groupsHierarchySizeLimit,
		amountOfGroupsInTenant,
		parentLevel
	} = useGroupsHierarchyContext();

	const { getValues, reset } = useFormContext();
	useEffect(() => {
		const values = getValues();
		reset(createDefaultValues(levelNumber, parent?.level, values));
		// eslint-disable-next-line
	}, [getValues, levelNumber, parent]);
	const handleOnLevelsSelectChange = useCallback(
		(value: number) => {
			if (levelNumber && value > parentLevel) {
				const levelsNames = prepareLevelItemArray(
					value - parentLevel,
					parentLevel
				).map((levelNumber) => `level${levelNumber}`);
				const groupsAmountsPerLevel = Array(levelsNames.length).fill(1);
				const values = getValues();

				if (
					isGroupsHierarchyLimitReached(
						values,
						groupsHierarchySizeLimit,
						amountOfGroupsInTenant,
						levelsNames,
						groupsAmountsPerLevel
					)
				) {
					const isSingle = value - levelNumber === 1;

					const translationPath = isSingle ? 'single' : 'multiple';
					Notification.error({
						description: t(
							`groupsHierarchy.groupsHierarchyLevelLimit.${translationPath}`,
							{
								groupsHierarchyLimit: groupsHierarchySizeLimit
							}
						)
					});

					return;
				}
			}

			setLevelNumber(value);
		},
		// eslint-disable-next-line
		[getValues, levelNumber, parentLevel]
	);
	const onChange = useCallback((value: boolean, levelNumber: number) => {
		setLevelIsProperty(value ? levelNumber : 0);
	}, []);
	const isLevelsEqual = levelNumber === parentLevel;
	return (
		<>
			<div>
				<SelectLevel
					handleOnLevelsSelectChange={handleOnLevelsSelectChange}
					levelNumber={levelNumber}
					help={t(
						'groupsHierarchy.formHierarchy.numberOFHierarchyLevelHelp'
					)}
					required
				/>
				<p className={styles.description}>
					<Trans i18nKey="groupsHierarchy.formHierarchy.description">
						<DownloadGroupsTemplateButton />
					</Trans>
				</p>
			</div>
			<Level levelNumber={parentLevel} parentName={parent?.name} />
			{levelNumber && !isLevelsEqual
				? prepareLevelItemArray(
						levelNumber - parentLevel,
						parentLevel
				  ).map((levelNumber) => {
						return (
							<LevelItem
								key={levelNumber}
								levelNumber={levelNumber}
								onChange={onChange}
								disabled={
									levelWithProperty
										? levelWithProperty !== levelNumber
										: false
								}
							/>
						);
				  })
				: null}
		</>
	);
};

export default GroupsHierarchyForm;
