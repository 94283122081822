import * as d3 from 'd3';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { CHART_CONFIG } from '../Visualizations.constants';
import { colorScale, linearScale } from '../Visualizations.helpers';
import {
	getChartData,
	getTimeRanges,
	prepareData,
	getYMaxValue
} from './LineChart.helpers';
import {
	LineChartProps,
	LineTimeInterval,
	LineValue,
	LineValueItem,
	LineValuesByPeriod
} from './LineChart.types';

const { selectors, margin, dateFormat } = CHART_CONFIG.line;

type SvgType = d3.Selection<SVGGElement, unknown, null, undefined>;

function linechart(props: LineChartProps = {}) {
	const dispatch = d3.dispatch('tooltip:show', 'tooltip:hide');
	let size = {
		width: 0,
		height: 0,
		innerWidth: 0,
		innerHeight: 0
	};
	let timeInterval: LineTimeInterval = LineTimeInterval.Month;
	let timeRangeEndYears: number[] | undefined;
	let svg: SvgType | undefined;
	let data: LineValuesByPeriod[];
	let scales: ReturnType<typeof chart.scales> | undefined;
	let update: (() => void) | undefined;

	function chart(
		selection: d3.Selection<HTMLDivElement, unknown, null, undefined>
	) {
		selection.each(function () {
			svg = selection
				.append('svg')
				.append<SVGGElement>('g')
				.attr('transform', `translate(${margin.left}, ${margin.top})`);

			const axisFn = axis();
			const graphFn = graph();
			const tooltipFn = tooltip();

			axisFn.create();
			graphFn.create();
			tooltipFn.create();

			update = () => {
				if (size) {
					selection
						.select('svg')
						.attr('width', size.width)
						.attr('height', size.height);
				}

				axisFn.draw();
				graphFn.draw();
				tooltipFn.draw();
			};
		});
	}

	chart.dispatch = dispatch;
	chart.ranges = getTimeRanges(props);
	chart.xRange = chart.ranges[chart.ranges.length - 1];

	chart.data = (d: LineValue[]) => {
		data = prepareData(d, chart);
		chart.scales();
		return chart;
	};

	chart.timeRangeEndYears = (d: typeof timeRangeEndYears) => {
		timeRangeEndYears = d;
		chart.scales();
		return chart;
	};

	chart.size = (width: number, height: number) => {
		size = {
			width,
			height,
			innerWidth: width - margin.left - margin.right,
			innerHeight: height - margin.top - margin.bottom
		};
		chart.scales();
		return chart;
	};

	chart.timeInterval = (value: typeof timeInterval) => {
		if (timeInterval !== value) {
			timeInterval = value;
			chart.scales();
		}
		return chart;
	};

	chart.scales = () => {
		if (!data) return;
		const timeIntervalFn = d3.timeMonth.every(
			timeInterval === LineTimeInterval.Month ? 1 : 3
		) as d3.CountableTimeInterval;

		const chartData = getChartData(
			data,
			timeInterval,
			timeRangeEndYears,
			chart
		);
		const chartDataYMax = getYMaxValue(chartData);

		const x = d3
			.scaleTime()
			.domain([chart.xRange.start.toDate(), chart.xRange.end.toDate()])
			.range([0, size.innerWidth])
			.nice(timeIntervalFn);

		const xAxis = d3
			.axisBottom<Date>(x)
			.tickFormat(d3.timeFormat('%b'))
			.tickPadding(16)
			.tickValues(timeIntervalFn.range(x.domain()[0], x.domain()[1]));

		const color = colorScale(chartData.map((d) => d.timePeriod));

		const { scale: y, scaleAxis: yAxis } = linearScale(
			0,
			chartDataYMax,
			size.innerHeight,
			size.innerWidth
		);

		const line = d3
			.line<LineValueItem>()
			.x((d) => x(chart.getTimeScaleValue(d)))
			.y((d) => y(d.y));

		const height = y.range()[0];
		const width = x.range()[1];

		const ret = {
			y,
			yAxis,
			x,
			xAxis,
			line,
			color,
			height,
			width,
			data: chartData
		};

		scales = ret;
		if (update) update();
		return ret;
	};

	chart.toDate = (date: string | moment.Moment) => moment(date, dateFormat);

	chart.toDateFormatted = (date: string | moment.Moment) =>
		(moment.isMoment(date) ? date : chart.toDate(date)).format(dateFormat);

	chart.getTimeScaleValue = (d: LineValueItem) =>
		chart.toDate(d.x).year(d.axisYear).toDate();

	function axis() {
		return {
			create: () => {
				if (!svg) return;
				// X-axis
				svg.append('g').attr('class', selectors.axis.x);

				// Y-axis
				svg.append('g').attr('class', selectors.axis.y);
			},
			draw: () => {
				if (!svg || !scales) return;

				// X-axis
				const xSelection = svg.select<SVGGElement>(
					`.${selectors.axis.x}`
				);

				// Y-axis
				const ySelection = svg.select<SVGGElement>(
					`.${selectors.axis.y}`
				);

				// X-axis
				xSelection
					.attr('transform', `translate(0,${scales.height})`)
					.call(scales.xAxis)
					.attr('font-family', 'Titillium');

				// Y-axis
				ySelection.call(scales.yAxis).attr('font-family', 'Titillium');

				[xSelection, ySelection].forEach((s) => {
					s.selectAll('text')
						.attr('color', '#7c8180')
						.attr('font-size', '14px')
						.attr('font-weight', '100');
					s.selectAll('line')
						.attr('stroke-dasharray', '3 3')
						.attr('color', '#e1e2e2');

					s.select('path').remove();
				});
			}
		};
	}

	function graph() {
		return {
			create: () => {
				if (!svg) return;
				// Graph
				svg.append('g').attr('class', selectors.graph);
			},
			draw: () => {
				if (!svg || !scales) return;

				const { color, line, data } = scales;

				const graphSel = svg.select<SVGGElement>(`.${selectors.graph}`);
				graphSel
					.selectAll<SVGGElement, typeof data[0]>('path')
					.data(data, (d) => d.year)
					.join(
						(enter) => {
							return enter
								.append('path')
								.attr('stroke', (d) => color(d.timePeriod))
								.attr('stroke-width', 2)
								.attr('fill', 'none')
								.attr('d', (d) => line(d.values));
						},
						(update) => {
							return update
								.attr('stroke', (d) => color(d.timePeriod))
								.attr('d', (d) => line(d.values));
						},
						(exit) => exit.remove()
					);
			}
		};
	}

	function tooltip() {
		return {
			create: () => {
				if (!svg) return;
				// Tooltip
				const tooltipSelection = svg
					.append('g')
					.attr('class', selectors.tooltip);

				tooltipSelection
					.append('line')
					.attr('stroke', '#e1e2e2')
					.attr('display', 'none');

				// eslint-disable-next-line
				const bisect = d3.bisector<LineValueItem, Date>(
					chart.getTimeScaleValue
				).center;
				svg.append('rect')
					.attr('fill', 'none')
					.attr('pointer-events', 'all')
					.on('mouseout', () => {
						dispatch.call('tooltip:hide');
						tooltipSelection.selectAll('*').attr('display', 'none');
					})
					.on('mousemove', function (ev) {
						if (!scales) return;
						const { x, y, color, data } = scales;
						if (!data.length) {
							return;
						}

						const event = ev as MouseEvent;
						const xValue = x.invert(event.offsetX - margin.left);

						const closestPointByPeriod: Record<
							string,
							LineValueItem
						> = {};
						const circlesSel = tooltipSelection.selectAll<
							SVGCircleElement,
							typeof data[0]
						>('circle');
						circlesSel.each(function (d) {
							const i = bisect(d.values, xValue);
							const point = d.values[i];
							if (!point) return;
							closestPointByPeriod[d.timePeriod] = point;

							const xPos = x(chart.getTimeScaleValue(point));
							const yPos = y(point.y);

							d3.select(this)
								.attr('cx', xPos)
								.attr('cy', yPos)
								.attr('display', null);
							tooltipSelection
								.select('line')
								.attr('x1', xPos)
								.attr('x2', xPos)
								.attr('y1', 0)
								.attr('display', null);
						});

						if (!isEmpty(closestPointByPeriod)) {
							const domRect = tooltipSelection
								.node()
								?.getBoundingClientRect();
							if (!domRect) return;
							const periodKeys = Object.keys(
								closestPointByPeriod
							);
							dispatch.call('tooltip:show', undefined, {
								x: domRect.x,
								y: domRect.y,
								values: periodKeys.map((period) => {
									const point = closestPointByPeriod[period];
									return {
										title: chart
											.toDate(point.x)
											.format('MMM YYYY'),
										count: point.y,
										countColor: color(period)
									};
								})
							});
							const d = Object.values(closestPointByPeriod)[0];
							const xPos = x(chart.getTimeScaleValue(d));
							tooltipSelection
								.select('line')
								.attr('x1', xPos)
								.attr('x2', xPos)
								.attr('y1', 0)
								.attr('display', null);
						}
					});
			},
			draw: () => {
				if (!svg || !scales) return;

				const { color, width, height, data } = scales;

				svg.select('rect').attr('width', width).attr('height', height);

				// Tooltip
				const tooltipSelection = svg.select(`.${selectors.tooltip}`);

				tooltipSelection
					.selectAll<SVGCircleElement, typeof data>('circle')
					.data(data)
					.join(
						(enter) => {
							return enter
								.append('circle')
								.attr('r', 8)
								.attr('fill', (d) => color(d.timePeriod))
								.attr('stroke', '#fff')
								.attr('stroke-width', 2)
								.attr('display', 'none');
						},
						(update) =>
							update.attr('fill', (d) => color(d.timePeriod)),
						(exit) => exit.remove()
					);

				tooltipSelection.select('line').attr('y2', height);
			}
		};
	}

	return chart;
}

export default linechart;
