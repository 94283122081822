import { TableProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { SorterResult } from 'antd/lib/table/interface';
import { useEffect } from 'react';

import { Layout, Table } from 'Components';
import { createSortDescription } from 'Components/Table/createColumns';
import ModalContextProvider from 'Hooks/useModal';
import { useStores } from 'Hooks/useStore';
import { SavedReport } from 'Services/Api/Reports/Types';
import { getIsPending } from 'Stores/util';
import useColumns, { TableFilterValues } from './columns/columns';
import ReportModals from './ReportModals/ReportModals';
import { useDownloadReportProgress } from 'Hooks/useDownloadReportProgress/useDownloadReportProgress';

function Saved() {
	const { authStore, reportsStore } = useStores();
	const { savedReports, status, savedReportsList } = reportsStore;

	useDownloadReportProgress();

	useEffect(() => {
		reportsStore.getSavedReports();
		return () => {
			reportsStore.clearSavedReports();
		};
		// eslint-disable-next-line
	}, []);

	const handleTable: TableProps<SavedReport>['onChange'] = (
		_,
		filters,
		sorter
	) => {
		const sort = createSortDescription<SavedReport>(
			sorter as SorterResult<SavedReport>
		);
		const values = filters as TableFilterValues;

		reportsStore.getSavedReports({
			...savedReports.params,
			sort,
			reportName: values.reportName?.[0] ?? undefined,
			reportTypes: values.reportTypes,
			recurrenceTypes: values.recurrenceTypes,
			emailsList: values.emailsList?.[0] ?? undefined
		});
	};

	const refresh = () => {
		reportsStore.getSavedReports();
	};

	const columns = useColumns({
		params: savedReports.params,
		currentUserEmail: authStore.currentUser?.email ?? ''
	});

	return (
		<ModalContextProvider>
			<Layout>
				<Table<SavedReport>
					dataSource={savedReportsList}
					rowKey="id"
					columns={columns}
					loading={getIsPending(status.getSavedReports)}
					onChange={handleTable}
					totalVisible
					pagination={false}
				/>
				<ReportModals refresh={refresh} />
			</Layout>
		</ModalContextProvider>
	);
}

export default observer(Saved);
