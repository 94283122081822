import { useTranslation } from 'react-i18next';
import { Fx, Layout, Table } from 'Components';
import { SystemRole } from 'Services/Api/Roles/Types';
import { useRoles } from './Roles.hooks';
import RolesActions from './RolesActions';
import { useMemo } from 'react';
import { getNamesBySelectedRowKeys } from './Roles.helpers';

function Roles() {
	const { t } = useTranslation();
	const {
		result,
		refetch,
		dataSource,
		columns,
		onTableChange,
		selectedRowKeys,
		setSelectedRowKeys
	} = useRoles();

	const namesBySelectedRowKeys = useMemo(
		() => getNamesBySelectedRowKeys(dataSource, selectedRowKeys),
		[dataSource, selectedRowKeys]
	);

	const onControlSubmit = () => {
		setSelectedRowKeys([]);
		refetch();
	};

	return (
		<Layout>
			<Layout.Heading>
				<Fx align="middle" justify="space-between">
					<h1>{t('roles.title')}</h1>
					<RolesActions
						ids={selectedRowKeys as string[]}
						idsNames={namesBySelectedRowKeys}
						onSubmit={onControlSubmit}
						hasData={Boolean(dataSource.length)}
						params={result.variables}
					/>
				</Fx>
			</Layout.Heading>
			<Table<SystemRole>
				dataSource={dataSource}
				rowKey="id"
				columns={columns}
				loading={result.loading}
				pagination={false}
				onChange={onTableChange}
				totalVisible
				rowSelection={{
					selectedRowKeys,
					onChange: setSelectedRowKeys
				}}
			/>
		</Layout>
	);
}

export default Roles;
