import {
	RemovePropertiesModal,
	DeleteOwnershipGroupModal,
	OwnershipGroupActionsModalsProps,
	OwnershipGroupActionsModalName
} from './modals';
import { useModal } from 'Hooks/useModal';

function OwnershipGroupActionsModals({
	id,
	ids,
	idsNames,
	onSubmit,
	ownerGroupName,
	resultRefetch
}: OwnershipGroupActionsModalsProps) {
	const { modal } = useModal();
	const modalName: OwnershipGroupActionsModalName = modal?.name;

	return (
		<>
			{modalName === OwnershipGroupActionsModalName.Remove && (
				<RemovePropertiesModal
					id={id}
					ids={ids}
					idsNames={idsNames}
					onSubmit={onSubmit}
				/>
			)}
			{modalName === OwnershipGroupActionsModalName.Delete && (
				<DeleteOwnershipGroupModal
					ownerGroupName={ownerGroupName}
					resultRefetch={resultRefetch}
				/>
			)}
		</>
	);
}

export default OwnershipGroupActionsModals;
