import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { Button, Dropdown, Icon } from 'Components';
import { ExportUsersActivityFileType } from 'Services/Api/Dashboard/Types';

interface ExportMenuProps {
	onExportMenuClick: MenuClickEventHandler;
	loading: boolean;
	disabled?: boolean;
}

const ExportMenu = ({
	onExportMenuClick,
	loading,
	disabled
}: ExportMenuProps) => {
	const { t } = useTranslation();
	return (
		<Dropdown
			overlay={
				<Menu onClick={onExportMenuClick}>
					<Menu.Item key={ExportUsersActivityFileType.Csv}>
						<Icon type="download" />
						{t('dashboard.tabs.userActivity.export.csv')}
					</Menu.Item>
					<Menu.Item key={ExportUsersActivityFileType.Xlsx}>
						<Icon type="download" />
						{t('dashboard.tabs.userActivity.export.xlsx')}
					</Menu.Item>
				</Menu>
			}
			trigger={['click']}
			arrow={false}
			disabled={disabled}
		>
			<Button
				type="primary"
				shape="round"
				ghost
				loading={loading}
				disabled={disabled}
			>
				<Icon type="download" />
				{t('export')}
			</Button>
		</Dropdown>
	);
};

export default ExportMenu;
