import { useQuery } from '@apollo/client';
import { APIErrorCodes } from 'app-types';
import { Notification } from 'Components';
import { usePrevious } from 'Hooks/usePrevious';
import { useStores } from 'Hooks/useStore';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react-lite';
import { EditUserValues, PrimarySystemRole } from 'Pages/User/User.types';
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_JOB_CLASSES } from 'Services/Api/Masterdata/Queries';
import { GetJobClassesResponse } from 'Services/Api/Masterdata/Types';
import { getIsModuleSettingsDisabled } from './ModuleSettings.helpers';
import { SystemRoleType } from 'Services/Api/Roles/Types';

interface ModuleSettingsContainerProps {
	values: Pick<EditUserValues, 'primarySystemRoleId' | 'groups'>;
	onDisabled: () => void;
	children: (disabled: boolean) => React.ReactNode;
}

export function useIsModuleSettingDisabled(
	values?: Pick<EditUserValues, 'primarySystemRoleId' | 'groups'>
) {
	const { authStore } = useStores();
	const disabled = useMemo(() => {
		return values
			? getIsModuleSettingsDisabled(
					values,
					authStore.currentUserPermissionCodes
			  )
			: true;
	}, [values, authStore.currentUserPermissionCodes]);

	const getIsLMSModuleSettingsDisabled = (
		values: Pick<EditUserValues, 'primarySystemRoleId' | 'groups'>
	) => {
		return getIsModuleSettingsDisabled(
			values,
			authStore.currentUserPermissionCodes
		);
	};
	return { disabled, getIsLMSModuleSettingsDisabled };
}

interface UseJobClassProps {
	values: Pick<EditUserValues, 'groups' | 'moduleSettings'> & {
		primarySystemRoleId?:
			| EditUserValues['primarySystemRoleId']
			| PrimarySystemRole;
	};
}
export function useJobClass({ values }: UseJobClassProps) {
  
	const shouldSkipRequest = useMemo(() => {
		if (Array.isArray(values?.primarySystemRoleId)) {
			const systemRole = values.primarySystemRoleId.find(
				(role) => role.data.isPrimary
			);
			const role = systemRole?.data?.role;
      if(role === SystemRoleType.SuperAdmin ||
				role === SystemRoleType.Owner) return true
		}
    if( !Array.isArray(values?.groups) || !values.groups.length) return true
		return false;
	}, [values?.primarySystemRoleId, values?.groups]);
	const { t } = useTranslation();
	const groupIds = useMemo(() => (values.groups ?? []).map((d) => d.key), [
		values.groups
	]);
	const { called, loading, data, refetch } = useQuery<GetJobClassesResponse>(
		GET_JOB_CLASSES,
		{
			fetchPolicy: 'cache-first',
			skip: shouldSkipRequest,
			variables: {
				groupIds
			}
		}
	);

	const fetch = async () => {
		try {
			if (shouldSkipRequest) return;
			await refetch({
				variables: {
					groupIds
				}
			});
		} catch (e) {
			if (groupIds.length) {
				Notification.error({
					description: t(
						`user.errorCodes.${APIErrorCodes.UserGroupInTheSameHierarchy}`
					)
				});
			} else {
				Notification.error({
					description: t('errorCodes.genericErrorMessage')
				});
			}
		}
	};

	useEffect(() => {
		fetch();
		// eslint-disable-next-line
	}, [groupIds]);

	return {
		loading: !called || loading,
		data: data?.getJobClassesList
	};
}

interface UseResetLmsFieldsOnGroupsChangeProps {
	values: Pick<EditUserValues, 'groups' | 'moduleSettings'>;
	resetLmsFields: () => void;
}
export const useResetLmsFieldsOnGroupsChange = ({
	values,
	resetLmsFields
}: UseResetLmsFieldsOnGroupsChangeProps) => {
	const previousValues = usePrevious(values);
	useEffect(() => {
		const isModuleSettingsChanged = !isEqual(
			previousValues?.moduleSettings,
			values.moduleSettings
		);
		if (
			previousValues?.groups &&
			!isEqual(values.groups, previousValues.groups) &&
			!isModuleSettingsChanged
		) {
			resetLmsFields();
		}
		// eslint-disable-next-line
	}, [
		values.groups,
		values.moduleSettings,
		previousValues?.groups,
		previousValues?.moduleSettings
	]);
};

function ModuleSettingsContainer({
	values,
	onDisabled,
	children
}: ModuleSettingsContainerProps) {
	const { disabled } = useIsModuleSettingDisabled(values);

	const previousDisabled = usePrevious(disabled);

	useEffect(() => {
		if (previousDisabled === false && disabled) {
			onDisabled();
		}
	}, [previousDisabled, disabled, onDisabled]);

	return <>{children(disabled)}</>;
}

export default observer(ModuleSettingsContainer);
