import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Hooks/useStore';
import { Spin, RedirectToLogin, Fx } from 'Components';
import { getAppRoutes } from 'Pages/App/App.constants';
import { TokenForm } from './components/TokenForm';
import { axiosClient } from 'Services/Api/client';
import AUTH_API from 'Services/Api/Auth/Api';
import AuthLayout from '../AuthLayout';
import { TwoStepVerificationRegisterResponse } from './TwoStepAuthentication.types';
import styles from './TwoStepAuthentication.module.scss';

function useTwoStepRegistration() {
	const [
		twoStepRegisterData,
		setTwoStepRegisterData
	] = useState<null | TwoStepVerificationRegisterResponse>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isNoUser, setIsNoUser] = useState<boolean>(false);
	useEffect(() => {
		async function getTwoStepRegisterData() {
			setIsLoading(true);
			try {
				const {
					data
				} = await axiosClient.post<TwoStepVerificationRegisterResponse>(
					AUTH_API.TWO_STEP_AUTHENTICATION_REGISTER
				);

				setTwoStepRegisterData(data);
			} catch (e) {
				setIsNoUser(true);
			} finally {
				setIsLoading(false);
			}
		}
		getTwoStepRegisterData();
	}, []);
	return {
		isLoading,
		twoStepRegisterData,
		isNoUser
	};
}

const TwoStepRegistration = () => {
	const {
		twoStepRegisterData,
		isLoading,
		isNoUser
	} = useTwoStepRegistration();
	const { authStore } = useStores();
	const { t } = useTranslation();

	if (isNoUser) {
		return <RedirectToLogin />;
	}
	if (authStore.twoFactorAuthorizationPassed) {
		return <Redirect to={getAppRoutes().HOME} />;
	}
	return (
		<AuthLayout wide>
			<h3 className={styles.h3}>
				{t('twoFactorAuthorization.authentication.title')}
			</h3>
			<p className={styles.p}>
				{t('twoFactorAuthorization.authentication.rules.first')}
			</p>
			<p className={styles.p}>
				{t('twoFactorAuthorization.authentication.rules.second')}
			</p>

			<div className={styles.qrCodeWrap}>
				{isLoading ? (
					<Spin />
				) : (
					twoStepRegisterData?.qrImage && (
						<>
							<Fx align="middle" justify="center">
								<img
									src={twoStepRegisterData?.qrImage}
									alt="qrCode"
								/>
							</Fx>
							<p className={styles.p}>
								{t(
									'twoFactorAuthorization.authentication.code.title'
								)}
							</p>
							<p className={styles.p}>{`${t(
								'twoFactorAuthorization.authentication.code.code'
							)} ${twoStepRegisterData?.code}`}</p>
						</>
					)
				)}
			</div>
			<p className={styles.p}>
				{t('twoFactorAuthorization.authentication.rules.third')}
			</p>
			<TokenForm isMFARegistrationPage />
			<p className={styles.p}>
				{t('twoFactorAuthorization.authentication.support')}
			</p>
		</AuthLayout>
	);
};

export default observer(TwoStepRegistration);
