import { Tooltip as AntTooltip, TooltipProps as AntTooltipProps } from 'antd';
import React from 'react';
import styles from './Tooltip.module.scss';
import { Button } from 'Components';

const isButton = (children: React.ReactNode): children is typeof Button => {
	return React.isValidElement(children) && children.type === Button;
};

export type TooltipProps = AntTooltipProps & { enabled?: boolean };

const Tooltip = ({ enabled = true, ...props }: TooltipProps) => {
	// https://github.com/react-component/tooltip/issues/18#issuecomment-411476678
	// wrapping button with span and disabling pointer event fixes bug that tootlip
	// remains open after cursor leaves disabled input/button
	const tooltipChildren = isButton(props.children) ? (
		<span className={styles.buttonContainer}>{props.children}</span>
	) : (
		props.children
	);

	return (
		<>
			{enabled ? (
				<AntTooltip
					{...props}
					children={tooltipChildren}
					className={props.className}
				/>
			) : (
				props.children
			)}
		</>
	);
};

export default Tooltip;
