import { useState, useCallback } from 'react';
import { UploadProps } from 'antd';
import { RcFile } from 'antd/lib/upload';

const useFileUpload = () => {
	const [file, setFile] = useState<RcFile | null>(null);

	const handleFileUpload: UploadProps['onChange'] = useCallback(
		({ file }) => {
			setFile(file);
		},
		[]
	);

	return {
		file,
		setFile,
		handleFileUpload,
		fileName: file?.name ?? ''
	};
};

export default useFileUpload;
