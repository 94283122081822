import cn from 'classnames';
import { Space } from 'Components';
import styles from './Miscellaneous.module.scss';

const ControlSpace: React.FC = ({ children }) => (
	<Space size="middle">{children}</Space>
);

const LayoutHolder: React.FC<{ className?: string }> = ({
	className,
	children
}) => <div className={cn(styles.root, className)}>{children}</div>;

export { LayoutHolder, ControlSpace };
