import { ColumnProps } from 'antd/lib/table';
import {
	useCreateColumns,
	UseCreateColumnsInterface
} from 'Components/Table/createColumns';
import { useTranslation } from 'react-i18next';
import {
	OverviewGroupSummary,
	OverviewGroupSummaryRequest
} from 'Services/Api/Dashboard/Types';
import { PermissionCode } from 'Services/Api/Users/Types';
import { usePermission } from 'Components/Permission/Permission.hooks';
import { renderMoodIndicatorColumn } from '../../columns.helpers';

type ColumnPropsWithPermissions<T> = ColumnProps<T> & {
	permission?: {
		code: PermissionCode;
	};
};

export function useColumnProps<T extends OverviewGroupSummary>(
	helpers?: ReturnType<UseCreateColumnsInterface<T, T>>
) {
	const { t } = useTranslation();
	const { getHasPermission } = usePermission();
	const columns: Array<ColumnPropsWithPermissions<T>> = [
		{
			...helpers?.sort,
			title: t('dashboard.groupSummary.name'),
			key: 'groupName',
			dataIndex: 'groupName',
			width: '32.42%',
			sortOrder: helpers?.getSortOrder('groupName')
		},
		{
			...helpers?.sort,
			title: t('dashboard.indexComposite'),
			key: 'indexComposite',
			dataIndex: 'indexComposite',
			render: renderMoodIndicatorColumn,
			width: '21.22%',
			sortOrder: helpers?.getSortOrder('indexComposite')
		},
		{
			...helpers?.sort,
			title: t('dashboard.phishing'),
			key: 'phishingScore',
			dataIndex: 'phishingScore',
			render: renderMoodIndicatorColumn,
			sortOrder: helpers?.getSortOrder('phishingScore'),
			permission: {
				code: PermissionCode.ViewDashboardPhishing
			}
		},
		{
			...helpers?.sort,
			title: t('dashboard.eLearning'),
			key: 'elearningScore',
			dataIndex: 'elearningScore',
			render: renderMoodIndicatorColumn,
			sortOrder: helpers?.getSortOrder('elearningScore'),
			permission: {
				code: PermissionCode.ViewDashboardElearning
			}
		},
		{
			...helpers?.sort,
			title: t('dashboard.spotCheck'),
			key: 'spotCheckScore',
			dataIndex: 'spotCheckScore',
			render: renderMoodIndicatorColumn,
			sortOrder: helpers?.getSortOrder('spotCheckScore'),
			permission: {
				code: PermissionCode.ViewDashboardSpotcheck
			}
		}
	];

	return columns.filter(
		(column) =>
			!column.permission || getHasPermission(column.permission).permitted
	);
}

export const useColumns = ({
	params
}: {
	params: OverviewGroupSummaryRequest | undefined;
}) => {
	const helpers = useCreateColumns<
		OverviewGroupSummary,
		OverviewGroupSummary
	>({
		filterValues: undefined,
		sortDesc: params?.sort
	});

	return useColumnProps(helpers);
};
