import { useFormikContext } from 'formik';
import { Layout } from 'Components';
import { useTranslation } from 'react-i18next';
import { JobClassField, UserRoleField } from 'Pages/User/components';
import { useModuleSettings } from '../../EditUser.hooks';
import ModuleSettingsContainer, {
	useJobClass
} from './ModuleSettingsContainer';
import { getLMSModuleSettingErrorText } from './ModuleSettings.helpers';
import { EditUserValues } from '../../../User.types';
interface ModuleSettingsProps {
	isProfile?: boolean;
}
function ModuleSettings({ isProfile }: ModuleSettingsProps) {
	const {
		onJobClassChange,
		onUserRoleChange,
		onDisabled
	} = useModuleSettings();
	const { t } = useTranslation();
	const { values, errors } = useFormikContext<EditUserValues>();
	const { loading, data } = useJobClass({
		values
	});
	return (
		<Layout.List fullHeight>
			<Layout.List.ListItem fullHeight>
				<Layout.List.Section
					title={t('user.editUser.tabs.moduleSettingsSubtitle')}
				>
					<ModuleSettingsContainer
						values={values}
						onDisabled={onDisabled}
					>
						{(disabled) => (
							<>
								<JobClassField
									value={values.moduleSettings?.jobClassId}
									isProfile={isProfile}
									disabled={disabled}
									onChange={onJobClassChange}
									required={!disabled}
									error={getLMSModuleSettingErrorText(
										errors.moduleSettings,
										'jobClassId',
										t
									)}
									options={data}
									loading={loading}
								/>
								<UserRoleField
									value={values.moduleSettings?.userRoleId}
									isProfile={isProfile}
									disabled={disabled}
									onChange={onUserRoleChange}
									required={!disabled}
									error={getLMSModuleSettingErrorText(
										errors.moduleSettings,
										'userRoleId',
										t
									)}
								/>
								{/* 
             // TODO: need for https://support.dataart.com/browse/VNZ-1684
                 <Checkbox.FormikField name="doNotUpdateOnAutoFeeds">
									{t('user.doNotUpdateOnAutoFeed')}
								</Checkbox.FormikField> */}
							</>
						)}
					</ModuleSettingsContainer>
				</Layout.List.Section>
			</Layout.List.ListItem>
		</Layout.List>
	);
}

export default ModuleSettings;
