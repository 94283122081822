import { useEffect, useState, useRef } from 'react';

import { InputValue, PasswordInputProps } from './PasswordInput.types';
import { manageInputValue, getChangeAndStart } from './PasswordInput.helpers';

function usePasswordInput(props: PasswordInputProps) {
	const difRef = useRef<HTMLInputElement>(null);
	const position = useRef<number>(0);
	const [value, setValue] = useState<InputValue>({
		origin: '',
		dots: ''
	});

	useEffect(() => {
		if (difRef.current?.selectionEnd) {
			(difRef.current as HTMLInputElement).selectionEnd =
				position.current;
		}
	}, [value.origin]);

	const [showPassword, setShowPassword] = useState(false);

	const handleValue = (ev: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = ev.target;

		setValue((currentState) => {
			// @NOTE: amount of value chars decreased
			if (currentState.origin.length > value.length) {
				const start = currentState.origin.slice(
					0,
					difRef.current?.selectionStart as number
				);
				const end = currentState.origin.slice(
					(difRef.current?.selectionEnd as number) +
						(currentState.origin.length - value.length)
				);
				const origin = start + end;
				position.current = difRef.current?.selectionEnd as number;

				props.onChange?.(origin);
				return manageInputValue(origin, showPassword);
			}

			let origin = value;

			if (!showPassword) {
				const { diff, start } = getChangeAndStart(value);
				origin =
					currentState.origin.slice(0, start) +
					diff +
					currentState.origin.slice(start + (diff.length - 1));
			}
			position.current = difRef.current?.selectionEnd as number;

			props.onChange?.(origin);
			return manageInputValue(origin, showPassword);
		});
	};

	const handleShowPassword = () => {
		setShowPassword((showPassword) => {
			const oppositeShowPassword = !showPassword;
			setValue((currentValue) => {
				return manageInputValue(
					currentValue.origin,
					oppositeShowPassword
				);
			});
			return oppositeShowPassword;
		});
	};

	return {
		handleShowPassword,
		handleValue,
		difRef,
		showPassword,
		inputValue: value.dots
	};
}

export default usePasswordInput;
