import { Input, Layout } from 'Components';
import { useFormikContext } from 'formik';
import { EditUserValues } from 'Pages/User/User.types';
import { useTranslation } from 'react-i18next';
import { RoleField, GroupField } from '../../components';
import { useModuleSettings } from '../EditUser.hooks';
import { useResetLmsFieldsOnGroupsChange } from './ModuleSettings/ModuleSettingsContainer';

interface ProfileProps {
	isProfile: boolean;
}

function Profile({ isProfile }: ProfileProps) {
	const { t } = useTranslation();
	const { onDisabled } = useModuleSettings();
	const { values } = useFormikContext<EditUserValues>();
	useResetLmsFieldsOnGroupsChange({ values, resetLmsFields: onDisabled });
	return (
		<Layout.List fullHeight>
			<Layout.List.ListItem fullHeight>
				<Layout.List.Section title={t('general')}>
					<Input.FormikField
						name="firstName"
						label={t('user.firstName')}
						required
					/>
					<Input.FormikField
						name="lastName"
						label={t('user.lastName')}
						required
					/>
					<Input.FormikField
						name="email"
						label={t('user.email')}
						required
					/>
					<Input.FormikField
						name="cognitoLogin"
						label={t('user.cognitoLogin')}
						disabled
						required
					/>
					<Input.FormikField
						name="phoneNumber"
						label={t('user.phoneNumber')+` (optional)`}
					/>
				</Layout.List.Section>
				<Layout.List.Section title={t('user.permission')}>
					<RoleField isProfile={isProfile} />
					<GroupField isProfile={isProfile} />
				</Layout.List.Section>
			</Layout.List.ListItem>
		</Layout.List>
	);
}

export default Profile;
