import { useLazyQuery, useMutation, ApolloError } from '@apollo/client';
import { RouteComponentProps, useHistory } from 'react-router';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';

import {
	Tabs,
	Spin,
	ErrorPage,
	Notification,
	EditableLayout,
	EditableFields
} from 'Components';
import { GET_GROUP } from 'Services/Api/Groups/Queries';
import { EDIT_GROUP, EDIT_TENANT } from 'Services/Api/Groups/Mutations';
import {
	GetGroupResponse,
	EditTenantRequest,
	EditGroupRequest
} from 'Services/Api/Groups/Types';
import { Details, DomainSettings, ModuleSettings } from './tabs';
import {
	createInitialValues,
	getGroupSubmitValues,
	getTenantSubmitValues
} from './EditGroup.helpers';

import { editTenantSchema, groupSchema } from '../schema';
import { getAppRoutes } from 'Pages/App/App.constants';
import { generatePath } from 'react-router-dom';
import { APIErrorCodes } from 'app-types';
import { checkApolloError } from 'Helpers/graphql';
import { useConfigs, useErrorBoundary } from 'Hooks';
import { EditGroupValues } from '../Group.types';

export enum TabKey {
	Details = 'Details',
	DomainSettings = 'Domain settings',
	ModuleSettings = 'Module settings'
}
const TABS_TITLES = {
	[TabKey.Details]: 'group.editGroup.sections.details',
	[TabKey.DomainSettings]: 'group.editGroup.sections.domainSettings',
	[TabKey.ModuleSettings]: 'group.editGroup.sections.moduleSettings'
};
interface EditGroupParams {
	groupId: string;
}

type EditGroupProps = RouteComponentProps<EditGroupParams>;

const EditGroup: FC<EditGroupProps> = (props) => {
	const history = useHistory();
	const { t } = useTranslation();
	const errorBoundary = useErrorBoundary();
	const [getGroup, response] = useLazyQuery<GetGroupResponse>(GET_GROUP, {
		variables: {
			id: props.match.params.groupId
		},
		onError: (error: ApolloError) => {
			const apolloError = checkApolloError(error);
			if (apolloError.is(APIErrorCodes.GroupsNotInHierarchy)) {
				errorBoundary.setStatus('forbidden');
			} else {
				errorBoundary.onError(error);
			}
		}
	});
	const configs = useConfigs();
	const [editTenant] = useMutation<EditTenantRequest>(EDIT_TENANT);
	const [editGroup] = useMutation<EditGroupRequest>(EDIT_GROUP);

	useEffect(() => {
		getGroup();
		// eslint-disable-next-line
	}, []);

	const onSubmit = async (
		values: EditGroupValues,
		helpers: FormikHelpers<EditGroupValues>
	) => {
		try {
			if (!values.parentId) {
				await editTenant({
					variables: {
						input: getTenantSubmitValues(values)
					}
				});
			} else {
				await editGroup({
					variables: {
						input: getGroupSubmitValues(values)
					}
				});
			}

			goBack();

			Notification.success({
				description: t('notification.edit.success')
			});
		} catch (error) {
			helpers.setSubmitting(false);
			const apolloError = checkApolloError(error);

			if (
				apolloError.is(APIErrorCodes.GroupHierarchyDepthLImitExceeded)
			) {
				Notification.error({
					description: configs.message
				});
				return;
			}

			if (
				apolloError.is(APIErrorCodes.GroupSyncCodeShouldBeUniqPerTenant)
			) {
				helpers.setErrors({
					syncCode: t(
						'group.fieldErrorCodes.GROUP_SYNC_CODE_SHOULD_BE_UNIQUE_PER_TENANT',
						{ path: 'Group sync code' }
					)
				});
				return;
			}

			if (apolloError.is(APIErrorCodes.GroupCannotBeProperty)) {
				Notification.error({
					description: t(
						'group.editGroup.errorCodes.GROUP_CAN_NOT_BE_PROPERTY'
					)
				});
				return;
			}

			if (
				apolloError.is(
					APIErrorCodes.MovingGroupsBetweenTenantsForbidden
				)
			) {
				Notification.error({
					description: t(
						`errorCodes.${APIErrorCodes.MovingGroupsBetweenTenantsForbidden}`
					)
				});
				return;
			}

			const fieldErrors = apolloError.getFieldErrors(
				t,
				validationSchema.describe().fields,
				'group.fieldErrorCodes'
			);

			helpers.setErrors(fieldErrors || {});
			if (fieldErrors) return;
			const errors = apolloError.getNonFieldErrors(
				t,
				'groups.groupDetails.components.moveGroupModal.errorCodes',
				{
					[APIErrorCodes.GroupsHierarchySizeLimitExceeded]: {
						groupsHierarchySizeLimit: String(
							configs.groupsHierarchySizeLimit
						)
					}
				}
			);

			if (errors.length) {
				Notification.error({
					description: errors
				});
				return;
			}
			Notification.error({
				description: t('errorCodes.genericErrorMessage')
			});
		}
	};

	const group = response.data?.getGroup ?? null;

	const validationSchema = useMemo(() => {
		const getValidationSchema = group?.isTenant
			? editTenantSchema
			: groupSchema;
		return getValidationSchema(configs.maxGroupNameCharacters);
	}, [group?.isTenant, configs.maxGroupNameCharacters]);

	const goBack = () => {
		history.push(
			generatePath(getAppRoutes().GROUPS, { groupId: group?.id })
		);
	};

	if (!group && response.loading) {
		return <Spin />;
	}

	if (response.error || !group) {
		return <ErrorPage status={404} />;
	}

	const heading = t('group.editGroup.title', {
		entity: t(group.isTenant ? 'group.tenant' : 'group.group'),
		level: group.level,
		name: group.name
	});
	const initialValues = createInitialValues(group);

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initialValues}
			onSubmit={onSubmit}
		>
			<EditableLayout.Tab goBack={goBack} heading={heading}>
				<Tabs defaultActiveKey={TabKey.Details}>
					<Tabs.TabPane
						tab={t(TABS_TITLES[TabKey.Details])}
						key={TabKey.Details}
					>
						<EditableFields>
							<Details
								isTenant={group.isTenant}
								groupId={props.match.params.groupId}
							/>
						</EditableFields>
					</Tabs.TabPane>
					{group.isTenant ? (
						<Tabs.TabPane
							tab={t(TABS_TITLES[TabKey.DomainSettings])}
							key={TabKey.DomainSettings}
						>
							<EditableFields>
								<DomainSettings />
							</EditableFields>
						</Tabs.TabPane>
					) : null}

					<Tabs.TabPane
						tab={t(TABS_TITLES[TabKey.ModuleSettings])}
						key={TabKey.ModuleSettings}
					>
						<EditableFields>
							<ModuleSettings isTenant={group.isTenant} />
						</EditableFields>
					</Tabs.TabPane>
				</Tabs>
			</EditableLayout.Tab>
		</Formik>
	);
};

export default EditGroup;
