import cn from 'classnames';
import { uniq, without } from 'lodash';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RadioChangeEvent } from 'antd';

import { Checkbox, Fx, Radio } from 'Components';

import {
	VISUALIZATION_TIME_PERIOD_ORDER,
	VISUALIZATION_TIME_PERIOD_LABEL
} from '../Visualizations.constants';
import { LineTimeInterval } from './LineChart.types';
import styles from './LineChart.module.scss';
import { VisualizationTimePeriod } from '../Visualizations.types';
import { useTranslation } from 'react-i18next';

const TIME_INTERVAL_LABEL_MAP = {
	[LineTimeInterval.Month]: 'dashboard.chart.timeInterval.month',
	[LineTimeInterval.Quarter]: 'dashboard.chart.timeInterval.quarter'
};

export interface LineChartControlsProps {
	keys: number[];
	checkedKeys: number[] | undefined;
	timeInterval: LineTimeInterval;
	onCheckedKeysChange: (value: number[]) => void;
	onTimeIntervalChange: (value: LineTimeInterval) => void;
}
function LineChartControls(props: LineChartControlsProps) {
	const { t } = useTranslation();
	const handleCheckedKeysChange = (e: CheckboxChangeEvent) => {
		if (!e.target.name) return;
		const key = Number(e.target.name);
		let newCheckedKeys = [...(props.checkedKeys ?? [])];
		if (e.target.checked) {
			newCheckedKeys.push(key);
		} else {
			newCheckedKeys = without([...(newCheckedKeys || [])], key);
		}
		props.onCheckedKeysChange(uniq(newCheckedKeys));
	};

	const handleTimeIntervalChange = (e: RadioChangeEvent) => {
		props.onTimeIntervalChange(e.target.value);
	};

	return (
		<Fx align="middle" className={styles.controls}>
			<Fx>
				{props.keys.map((key, index) => {
					const timePeriod = VISUALIZATION_TIME_PERIOD_ORDER[index];
					const isChecked = (props.checkedKeys || []).includes(key);
					return (
						<Checkbox
							defaultChecked={isChecked}
							key={key}
							name={String(key)}
							onChange={handleCheckedKeysChange}
							className={cn({
								[styles.current]:
									timePeriod ===
									VisualizationTimePeriod.Current
							})}
						>
							{t(VISUALIZATION_TIME_PERIOD_LABEL[timePeriod])}
						</Checkbox>
					);
				})}
			</Fx>

			<Radio.Group
				className={styles.timeIntervalButton}
				onChange={handleTimeIntervalChange}
				optionType="button"
				defaultValue={props.timeInterval}
				options={[
					{
						value: LineTimeInterval.Month,
						label: t(
							TIME_INTERVAL_LABEL_MAP[LineTimeInterval.Month]
						)
					},
					{
						value: LineTimeInterval.Quarter,
						label: t(
							TIME_INTERVAL_LABEL_MAP[LineTimeInterval.Quarter]
						)
					}
				]}
			/>
		</Fx>
	);
}

export default LineChartControls;
