import React from 'react';
import cn from 'classnames';
import styles from './Fx.module.scss';
import { isNil, omitBy } from 'lodash';

export interface FxProps extends React.HTMLAttributes<HTMLDivElement> {
	className?: string;
	fullHeight?: boolean;
	fullWidth?: boolean;

	inline?: boolean;
	align?: 'top' | 'middle' | 'bottom' | 'stretch';
	justify?:
		| 'start'
		| 'center'
		| 'end'
		| 'space-between'
		| 'space-around'
		| 'even';
	self?: 'bottom';
	column?: boolean;

	shrink?: number;
	grow?: number;
	wrap?: boolean;
}

const Fx: React.FC<FxProps> = ({
	align,
	justify,
	self,
	inline,
	column,
	fullHeight,
	fullWidth,
	shrink,
	grow,
	wrap,
	className,
	children,
	style,
	...props
}) => {
	const classNames = cn(className, styles.root, {
		[styles.column]: column,
		[styles.fullHeight]: fullHeight,
		[styles.fullWidth]: fullWidth,
		[styles.wrap]: wrap,
		[styles.inline]: inline,

		[styles.top]: align === 'top',
		[styles.middle]: align === 'middle',
		[styles.bottom]: align === 'bottom',
		[styles.stretch]: align === 'stretch',

		[styles.center]: justify === 'center',
		[styles.end]: justify === 'end',
		[styles.between]: justify === 'space-between',
		[styles.around]: justify === 'space-around',
		[styles.even]: justify === 'even',

		[styles.selfBottom]: self === 'bottom'
	});

	return (
		<div
			{...props}
			className={classNames}
			style={omitBy(
				{ ...style, flexShrink: shrink, flexGrow: grow },
				isNil
			)}
		>
			{children}
		</div>
	);
};

export default Fx;
