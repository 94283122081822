import React from 'react';
import {
	useField,
	FieldHelperProps,
	FieldInputProps,
	FieldConfig,
	FieldMetaProps
} from 'formik';
import { FormItem } from 'Components';
import { FormItemProps } from 'Components/Form/FormItem/FormItem';
import { useTranslation } from 'react-i18next';
import { translateTInfo } from 'i18n/i18n.helpers';

/**
 * @see https://formik.org/docs/api/useField
 * HOC for custom form components that implicitly uses formik props
 * to keep form state in sync
 *
 * HOC that implements <Field /> or `useField` functionality
 */

type Value = any; // eslint-disable-line @typescript-eslint/no-explicit-any
type FormItemFormikProps = Pick<
	FormItemProps,
	'className' | 'label' | 'required' | 'help' | 'fullWidth' | 'marginBottom'
>;
export type WrappedComponentBaseProps<P> = FieldConfig<P> & P;
type FormikFieldProps<P> = P &
	WrappedComponentBaseProps<P> &
	FormItemFormikProps;
export interface FormikFieldMapProps<P> {
	(
		props: Readonly<{
			field: FieldInputProps<Value>;
			helpers: FieldHelperProps<Value>;
			meta: FieldMetaProps<Value>;
			props: WrappedComponentBaseProps<P>;
		}>
	): Partial<P>;
}
/**
 *
 * @param component Wrapped Component
 * @param mapProps Function that expects field-related properties and is used in case
 * when there is a need to override existing properties
 */
export function createFormikField<P>(
	component: React.ComponentType<P>,
	mapComponentProps?: FormikFieldMapProps<P>
) {
	function FormikField(props: FormikFieldProps<P>) {
		// get field state
		const [field, meta, helpers] = useField(props);

		// extract `FormItemFormikProps`
		const {
			label,
			required,
			help,
			fullWidth,
			marginBottom,
			className,
			...initialComponentProps
		} = props;
		const { t } = useTranslation();
		const error = meta.touched ? meta.error : undefined;

		const formItemProps: FormItemProps = {
			label,
			required,
			fullWidth,
			marginBottom,
			className,
			help: help !== null && translateTInfo(error, t),
			validateStatus: (error && 'error') || undefined
		};

		// eslint-disable-next-line
		const mappedProps: any = mapComponentProps?.({
			field,
			helpers,
			props,
			meta
		});
		const componentProps = {
			...(initialComponentProps as WrappedComponentBaseProps<P>),
			...field,
			...mappedProps
		};

		const Component = component;

		return (
			<FormItem {...formItemProps}>
				<Component {...componentProps} />
			</FormItem>
		);
	}
	return FormikField;
}
