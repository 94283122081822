import { useState, MouseEvent } from 'react';
import { UseFormHandleSubmit, FieldErrors } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Values } from '../../GroupsHierarchy.types';
import { useGroupsHierarchyContext } from '../../GroupsHierarchyContext';
import { DELAY_TIME } from '../../GroupsHierarchy.constants';
import { prepareValues } from '../../GroupsHierarchy.helpers';
import HierarchySubmitButton from '../HierarchySubmitButton/HierarchySubmitButton';

interface HierarchyFormSubmitButtonProps {
	handleSubmit: UseFormHandleSubmit<Values>;
	errors: FieldErrors<Values>;
}

const HierarchyFormSubmitButton = ({
	handleSubmit,
	errors
}: HierarchyFormSubmitButtonProps) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const {
		changeScreenType,
		parent,
		levelNumber
	} = useGroupsHierarchyContext();

	const handleSubmitButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
		setIsSubmitting(true);
		// timeout needs for rendering a spinner when form submitting huge amount of groups
		setTimeout(() => {
			handleSubmit(onSubmit, onError)(e);
		}, DELAY_TIME);
	};

	const onSubmit = (values: Values) => {
		if (parent) {
			const hierarchyGroups = prepareValues(values, parent);
			setIsSubmitting(false);
			changeScreenType('manage-hierarchy', hierarchyGroups);
		}
	};

	const onError = () => {
		setIsSubmitting(false);
	};

	const disableSubmitButton =
		levelNumber === parent?.level || !isEmpty(errors);
	return (
		<HierarchySubmitButton
			disabled={disableSubmitButton}
			onClick={handleSubmitButtonClick}
			loading={isSubmitting}
		/>
	);
};

export default HierarchyFormSubmitButton;
