import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Key } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Notification, Button, Tooltip, Icon, Space } from 'Components';
import { omit, isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import {
	RECREATED_ERROR_TYPE,
	UNIQUE_ERROR_TYPE,
	API_DEFINED_ERROR_TYPE
} from 'app-config';
import {
	AddUser,
	AddUsersFormValues,
	PaginationParams,
	ErrorType
} from '../../AddUsers.types';
import { createNewUser } from '../../AddUsers.helpers';
import { OMITTED_USER_FIELDS, TIMEOUT_DELAY } from '../../AddUsers.constants';

interface DeleteInterface {
	indexes: number[];
	newUsers: AddUser[];
}

interface FooterProps extends PaginationParams {
	append(user: AddUser): void;
	checkedFields: Key[];
	setCheckedFields(arrKey: Key[]): void;
	clearErrors(): void;
	onPaginationChange: (page: number, pageSize?: number) => void;
	validateCognitoLogin: () => void;
}

const UsersControl = ({
	append,
	checkedFields,
	setCheckedFields,
	pageSize,
	onPaginationChange,
	currentPage,
	validateCognitoLogin
}: FooterProps) => {
	const { t } = useTranslation();
	const {
		getValues,
		reset,
		clearErrors,
		setError,
		formState
	} = useFormContext<AddUsersFormValues>();

	const users = getValues().users;

	const { length: checkedFieldsLength } = checkedFields;

	const handlePagination = () => {
		// 1 means we are adding only one user
		const usersAmount = users.length + 1;
		if (pageSize < usersAmount) {
			const pageNumber = Math.ceil(usersAmount / pageSize);
			if (pageNumber === currentPage) return;
			onPaginationChange(pageNumber);
		}
	};

	const handleAddUser = () => {
		append({
			...createNewUser({ data: getValues().data }),
			key: String(users.length)
		});

		handlePagination();
	};

	const handleCopyUser = () => {
		if (checkedFieldsLength > 1) {
			Notification.error({
				description: t('users.addUsers.notification.onlyOne')
			});
			return;
		}
		const [key] = checkedFields;

		const item = users.find(
			(user) => `${user.key}_${user.fieldId}` === key
		);

		append({
			...omit(item, OMITTED_USER_FIELDS),
			fieldId: uuid(),
			key: String(users.length)
		} as AddUser);

		handlePagination();
	};

	const handleDeleteUser = () => {
		if (checkedFieldsLength === users.length) {
			Notification.error({
				description: t('users.addUsers.notification.cannotDeleteAll')
			});
			return;
		}
		const currentValues = getValues();
		const usersWithErrorsRecord = users.reduce<
			Record<string, Record<string, ErrorType>>
		>((acc, user, index) => {
			const userErrors = formState.errors?.users?.[index];
			if (userErrors) {
				const omittedError = Object.keys(userErrors).reduce<
					Record<string, ErrorType>
				>((acc, key) => {
					// @ts-ignore
					if (userErrors[key]?.type !== UNIQUE_ERROR_TYPE) {
						acc[key] =
							// @ts-ignore
							userErrors[key]?.type === API_DEFINED_ERROR_TYPE
								? {
										type: API_DEFINED_ERROR_TYPE,
										// @ts-ignore
										errorCode: userErrors[key]?.errorCode
								  }
								: { type: RECREATED_ERROR_TYPE };
					}
					return acc;
				}, {});
				acc[user.fieldId] = omittedError;
			}
			return acc;
		}, {});

		const { newUsers, indexes } = users.reduce<DeleteInterface>(
			(acc, user, index) => {
				if (checkedFields.includes(`${user.key}_${user.fieldId}`)) {
					acc.indexes.push(index);
				} else {
					acc.newUsers.push({
						...user,
						key: String(acc.newUsers.length)
					});
				}

				return acc;
			},
			{
				indexes: [],
				newUsers: []
			}
		);
		const errorIndexes = indexes.map((index) => `users.${index}`);
		if (errorIndexes.length) {
			//@ts-ignore
			clearErrors(errorIndexes);
		}

		// using reset form method is required here, because setValue method saves old link to form data instead of create a new one.
		reset(
			{
				...currentValues,
				users: newUsers
			},
			{
				keepDirty: true,
				keepTouched: true,
				keepIsSubmitted: true
			}
		);

		const howManyUsersOnLastPage = users.length % pageSize;
		if (
			currentPage !== 1 &&
			(howManyUsersOnLastPage === checkedFieldsLength ||
				(!howManyUsersOnLastPage && checkedFieldsLength === pageSize))
		) {
			onPaginationChange(currentPage - 1);
		}

		setCheckedFields([]);
		setTimeout(() => {
			// @TODO: unstable_batchedUpdates is outdated functionality and has to be deleted after moving to react > v.18
			unstable_batchedUpdates(() => {
				validateCognitoLogin();
				if (!isEmpty(usersWithErrorsRecord)) {
					newUsers.forEach((user, index) => {
						const userError = usersWithErrorsRecord[user.fieldId];
						if (userError) {
							Object.keys(userError).forEach((errorField) => {
								// @ts-ignore
								setError(`users.${index}.${errorField}`, {
									...userError[errorField]
								});
							});
						}
					});
				}
			});
		}, TIMEOUT_DELAY);
	};
	return (
		<Space>
			<Button shape="round" type="primary" onClick={handleAddUser}>
				<Icon type="plus" />
				{t('users.addUsers.controls.addUser')}
			</Button>
			<Tooltip
				title={t('users.addUsers.tooltip.copy')}
				enabled={!checkedFieldsLength}
			>
				<Button
					shape="round"
					type="primary"
					disabled={!checkedFieldsLength}
					onClick={handleCopyUser}
				>
					<Icon type="plus" />
					{t('users.addUsers.controls.copyUser')}
				</Button>
			</Tooltip>
			<Tooltip
				title={t('users.addUsers.tooltip.delete')}
				enabled={!checkedFieldsLength}
			>
				<Button
					shape="round"
					ghost
					type="primary"
					disabled={!checkedFieldsLength}
					onClick={handleDeleteUser}
				>
					<Icon type="delete" />
					{t('delete')}
				</Button>
			</Tooltip>
		</Space>
	);
};
export default UsersControl;
