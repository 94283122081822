import { Select } from 'Components';
import { DataTreeNode } from 'Contexts/DataTree/DataTree.types';
import ModalContextProvider, { useModal } from 'Hooks/useModal';
import GroupFieldModal, { GroupFieldModalValues } from './GroupFieldModal';

type GroupFieldContentProps = GroupFieldModalValues & {
	onSubmit: (value: DataTreeNode) => void;
};

function GroupFieldContent(props: GroupFieldContentProps) {
	const { modal, showModal } = useModal();
	const onClick = () => {
		showModal({
			value: null
		});
	};

	const initialValue = props.initialValue?.[0];

	return (
		<>
			<Select
				value={
					initialValue
						? {
								key: initialValue.key,
								value: initialValue.key,
								label: initialValue.title
						  }
						: undefined
				}
				labelInValue
				open={false}
				onClick={onClick}
			/>
			{modal && (
				<GroupFieldModal
					disabledValue={props.disabledValue}
					initialValue={
						initialValue
							? [{ key: initialValue?.key, title: '' }]
							: undefined
					}
					onSubmit={(value) => {
						const item = value?.[0];
						if (!item) {
							return;
						}
						props.onSubmit(item);
					}}
				/>
			)}
		</>
	);
}

function GroupField(props: GroupFieldContentProps) {
	return (
		<ModalContextProvider>
			<GroupFieldContent {...props} />
		</ModalContextProvider>
	);
}

export default GroupField;
