import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItem, Select } from 'Components';
import { useGroupsHierarchyContext } from '../../GroupsHierarchyContext';
import styles from './SelectLevel.module.scss';
import DashedLine from '../DashedLine/DashedLine';
import { FormItemProps } from 'Components/Form/FormItem/FormItem';

type SelectLevelProps = Pick<FormItemProps, 'required' | 'help'> & {
	handleOnLevelsSelectChange(value: number): void;
	levelNumber: number | undefined;
};

const SelectLevel = ({
	handleOnLevelsSelectChange,
	levelNumber,
	required,
	help
}: SelectLevelProps) => {
	const {
		isConfigsLoading,
		parentLevel,
		groupMaxHierarchyLevel
	} = useGroupsHierarchyContext();
	const { t } = useTranslation();

	const levels = useMemo(() => {
		// getLevels(groupMaxHierarchyLevel)
		// excluding tenant level from levels
		const adaptedAmount = groupMaxHierarchyLevel - 1;

		return Array(adaptedAmount)
			.fill(2)
			.map((two, index) => {
				const value = index + two;
				return {
					text: `${value} ${t(
						'groupsHierarchy.formHierarchy.levels'
					)}`,
					value
				};
			});
	}, [t, groupMaxHierarchyLevel]);
	const isLevelsEqual = levelNumber === parentLevel;
	return (
		<>
			<DashedLine />
			<FormItem
				label={t(
					'groupsHierarchy.formHierarchy.numberOFHierarchyLevel'
				)}
				required={required}
				className={styles.select}
				help={help}
			>
				<Select
					onSelect={handleOnLevelsSelectChange}
					loading={isConfigsLoading}
					value={isLevelsEqual ? undefined : levelNumber}
				>
					{levels
						.filter(({ value }) => value > parentLevel)
						.map(({ text, value }) => {
							return (
								<Select.Option value={value} key={value}>
									{text}
								</Select.Option>
							);
						})}
				</Select>
			</FormItem>
		</>
	);
};

export default SelectLevel;
