import { Alert as AntAlert, AlertProps } from 'antd';
import Icon, { IconProps } from '../Icon/Icon';
import styles from './Alert.module.scss';

const ICON_MAP: { [key in Required<AlertProps>['type']]: IconProps['type'] } = {
	success: 'exclamationCircle',
	warning: 'exclamationCircle',
	error: 'exclamationCircle',
	info: 'exclamationCircle'
};

const Alert = (props: AlertProps) => {
	const iconType = props.type ? ICON_MAP[props.type] : undefined;
	return (
		<AntAlert
			{...props}
			icon={iconType ? <Icon type={iconType} /> : null}
			className={styles.root}
		/>
	);
};

export default Alert;
