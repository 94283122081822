import { SystemRoleType } from 'Services/Api/Roles/Types';

export const OMITTED_USER_FIELDS = [
	'firstName',
	'lastName',
	'cognitoLogin',
	'email',
	'phoneNumber'
];

export const ADD_USERS_SYSTEM_ROLE_LIST: string[] = [
	SystemRoleType.Admin,
	SystemRoleType.User,
	SystemRoleType.Manager
];

export const MAP_ERRORS = {
	firstName: 'firstName',
	lastName: 'lastName',
	email: 'email',
	login: 'cognitoLogin',
	phone: 'phoneNumber',
	systemRole: 'primarySystemRoleId',
	group: 'groups',
	timezone: 'timezoneId',
	language: 'languageId',
	jobClass: 'moduleSettings.jobClassId',
	userRole: 'moduleSettings.userRoleId'
};

export const TABLE_ROW_HEIGHT = 54;

export const PAGE_SIZE_OPTIONS = ['15', '25'];

export const FIRST_PAGE = 1;

export const TIMEOUT_DELAY = 200;
