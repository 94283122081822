import cn from 'classnames';
import { Layout, Spin } from 'Components';
import { DataTreeContextValue } from 'Contexts/DataTree/DataTree.types';
import { useDataTreeContext } from 'Contexts/DataTree/DataTreeContext';
import { SearchProps } from '../Search/Search.types';
import styles from './DataTreeLayout.module.scss';

export type DataTreeLayoutProps = {
	layout: 'modal' | 'sidebar';
	className?: string;
	header: (
		props: Partial<SearchProps>,
		context: DataTreeContextValue
	) => React.ReactNode;
	footer?: React.ReactNode;
};
const DataTreeLayout: React.FC<DataTreeLayoutProps> = ({
	layout,
	className,
	header,
	footer,
	children
}) => {
	const context = useDataTreeContext();

	return (
		<Layout
			className={cn(styles.root, className, {
				[styles.modal]: layout === 'modal',
				[styles.sidebar]: layout === 'sidebar'
			})}
		>
			{context.loading ? (
				<Spin gutter="xs" />
			) : (
				<>
					{header({ className: styles.search }, context)}

					<Layout className={styles.body}>{children}</Layout>

					{footer}
				</>
			)}
		</Layout>
	);
};

export default DataTreeLayout;
