export interface NewPasswordFieldsValues {
	password: string;
	confirmPassword: string;
}

export enum RuleType {
	Length = 'Length',
	Upper = 'Upper',
	Lower = 'Lower',
	Integer = 'Integer',
	SpecialChar = 'SpecialChar',
	Same = 'Same'
}
