import { v4 as uuid } from 'uuid';
import { AddGroup, Values } from './Groups.types';

export const GROUPS_LABEL = {
	ALL_TENANTS: 'groups.allTenants',
	ALL_GROUPS: 'groups.allGroups'
};

export const GROUP: AddGroup = {
	name: '',
	isProperty: false,
	syncCode: '',
	active: 'active',
	key: '0',
	fieldId: uuid(),
	canBePropertyToAncestors: false
};

export const INITIAL_VALUES: Values = {
	groups: [GROUP]
};

export const MANAGE_ROW_KEY = uuid();
