import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Fx } from 'Components';
import DashedLine from '../DashedLine/DashedLine';
import styles from './Level.module.scss';

export interface LevelProps {
	levelNumber: number;
	parentName?: string;
	Component?: ReactNode;
	withDashedLine?: boolean;
}

const Level: FC<LevelProps> = ({
	levelNumber,
	parentName,
	children,
	Component,
	withDashedLine
}) => {
	const { t } = useTranslation();
	return (
		<>
			{withDashedLine && <DashedLine />}
			<Fx
				className={styles.contentWrap}
				justify="space-between"
				align="middle"
			>
				<Fx>
					<h3 className={styles.level}>
						{t('groupsHierarchy.formHierarchy.level')} {levelNumber}
					</h3>
					{parentName && (
						<h3>
							{parentName}{' '}
							{levelNumber === 1 &&
								t('groupsHierarchy.formHierarchy.tenant')}
						</h3>
					)}
				</Fx>
				{Component && Component}
			</Fx>
			{children}
		</>
	);
};

Level.defaultProps = {
	withDashedLine: true
};

export default Level;
