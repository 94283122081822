import cn from 'classnames';
import styles from './Text.module.scss';

export interface TextProps {
	color?: 'default' | 'success' | 'warning' | 'error' | 'primary';
	size?: 'lg';
	className?: string;
}
const Text: React.FC<TextProps> = ({ className, color, size, children }) => {
	return (
		<span
			className={cn(className, styles.root, {
				[styles.default]: color === 'default',
				[styles.success]: color === 'success',
				[styles.warning]: color === 'warning',
				[styles.error]: color === 'error',
				[styles.primary]: color === 'primary',
				[styles.lg]: size === 'lg'
			})}
		>
			{children}
		</span>
	);
};

export default Text;
