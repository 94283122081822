import { createContext, useContext } from 'react';
import { noop } from 'lodash';
import { ScreenType, HierarchyGroup } from './GroupsHierarchy.types';
import { GroupDetails } from 'Services/Api/Groups/Types';

interface IGroupsHierarchyContext {
	levelNumber: number | undefined;
	setLevelNumber: React.Dispatch<React.SetStateAction<number | undefined>>;
	changeScreenType: (
		screenType: ScreenType,
		hierarchyGroups: HierarchyGroup[]
	) => void;
	hierarchyGroups: HierarchyGroup[];
	setHierarchyGroups: React.Dispatch<React.SetStateAction<HierarchyGroup[]>>;
	parent: GroupDetails | null;
	setActiveIsPropertyLevel: React.Dispatch<
		React.SetStateAction<Record<string, string[]>>
	>;
	disabledIds: Record<string, string[]>;
	activesIsPropertyLevel: Record<string, string[]>;
	blockIsUniqError: boolean;
	setIsUniqError: React.Dispatch<React.SetStateAction<boolean>>;
	groupsHierarchySizeLimit: number;
	amountOfGroupsInTenant: number;
	groupMaxHierarchyLevel: number;
	isConfigsLoading: boolean;
	parentLevel: number;
	levelNumberXLSX: number | undefined;
	setLevelNumberXLSX: React.Dispatch<
		React.SetStateAction<number | undefined>
	>;
}
export const GroupsHierarchyContext = createContext<IGroupsHierarchyContext>({
	levelNumber: undefined,
	hierarchyGroups: [],
	setLevelNumber: noop,
	changeScreenType: noop,
	setHierarchyGroups: noop,
	parent: null,
	setActiveIsPropertyLevel: noop,
	disabledIds: {},
	activesIsPropertyLevel: {},
	blockIsUniqError: false,
	setIsUniqError: noop,
	groupsHierarchySizeLimit: 0,
	// 1 means tenant
	amountOfGroupsInTenant: 1,
	groupMaxHierarchyLevel: 1,
	isConfigsLoading: false,
	parentLevel: 1,
	levelNumberXLSX: undefined,
	setLevelNumberXLSX: noop
});

export const useGroupsHierarchyContext = () =>
	useContext(GroupsHierarchyContext);
