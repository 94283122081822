import { Fx, Card, Icon } from 'Components';
import {
	DashboardProgress,
	InfoTooltip,
	Statistic
} from 'Pages/Dashboard/components';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlSpace } from '../Miscellaneous/Miscellaneous';

interface TotalIndexCompositeProps {
	tooltip: string;
	riskScore?: number | null;
	numberOfUsers?: number | null;
	properties?: number | null;
	dropdown?: ReactNode;
	statisticsVisible?: boolean;
}

function TotalIndexComposite({
	riskScore,
	tooltip,
	dropdown,
	numberOfUsers,
	properties,
	statisticsVisible
}: TotalIndexCompositeProps) {
	const { t } = useTranslation();
	return (
		<Card
			title={t('dashboard.tabs.overview.totalIndexComposite.title')}
			extra={
				<ControlSpace>
					<InfoTooltip title={tooltip} />
					{dropdown}
				</ControlSpace>
			}
		>
			<Fx align="middle" justify="space-around" fullHeight>
				<DashboardProgress percent={riskScore}>
					{t('dashboard.riskScore')}
				</DashboardProgress>
				{statisticsVisible && (
					<>
						<Statistic
							vertical
							inheritColor
							icon={<Icon type="users" />}
							value={numberOfUsers}
						>
							{t('dashboard.users')}
						</Statistic>
						<Statistic
							vertical
							inheritColor
							icon={<Icon type="property" />}
							value={properties}
						>
							{t('dashboard.properties')}
						</Statistic>
					</>
				)}
			</Fx>
		</Card>
	);
}

export default TotalIndexComposite;
