import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import cn from 'classnames';
import { LanguageI18nKey } from 'Services/Api/Common/Types';
import {
	DynamicPermissionCode,
	TwoFactorAuthorizationStatus
} from 'Services/Api/Users/Types';
import { TITLE_BY_MENU_KEY } from 'i18n/i18n.constants';
import { Layout, Select, Fx, Button, Permission } from 'Components';
import { useUserContext } from 'Pages/User/UserContext';
import { EditUserValues } from '../../User.types';
import styles from '../../User.module.scss';

interface PropsSettings {
	twoFactorAuthenticationStatus: TwoFactorAuthorizationStatus;
	isProfile: boolean;
}

function Settings({ twoFactorAuthenticationStatus, isProfile }: PropsSettings) {
	const {
		timezones,
		timezonesList,
		languages,
		languagesList
	} = useUserContext();
	const { values, setFieldValue } = useFormikContext<EditUserValues>();
	const { t } = useTranslation();
	const onResetMFAClick = () => {
		setFieldValue('resetMFA', true);
	};

	const isResetMFAButtonVisible =
		!isProfile &&
		(twoFactorAuthenticationStatus === TwoFactorAuthorizationStatus.Setup ||
			twoFactorAuthenticationStatus ===
				TwoFactorAuthorizationStatus.Blocked);

	return (
		<Layout.List fullHeight>
			<Layout.List.ListItem fullHeight use80>
				<Layout.List.Section title={t('user.accountsSettings')}>
					<Select.FormikField
						name="timezoneId"
						label={t('user.timezoneId')}
						loading={timezones.loading}
					>
						{timezonesList?.map((option) => (
							<Select.Option key={option.id} value={option.id}>
								{option.zone}
							</Select.Option>
						))}
					</Select.FormikField>
					<Select.FormikField
						name="languageId"
						label={t('user.languageId')}
						loading={languages.loading}
					>
						{languagesList?.map((option) => (
							// @NOTE@1: 'disabled' implemented according to https://support.dataart.com/browse/VNZ-1931
							<Select.Option
								key={option.id}
								value={option.id}
								disabled={
									option.name !==
									TITLE_BY_MENU_KEY[LanguageI18nKey.English]
								}
							>
								{option.name}
							</Select.Option>
						))}
					</Select.FormikField>
					<Fx
						className={cn(
							twoFactorAuthenticationStatus ===
								TwoFactorAuthorizationStatus.Disabled &&
								styles.disabled
						)}
						align="middle"
						justify="space-between"
					>
						<Fx>
							{t('twoFactorAuthorization.profile.title')}
							{':'}
							<Fx
								className={cn(
									styles.status,
									styles[twoFactorAuthenticationStatus]
								)}
							>
								{t(
									`twoFactorAuthorization.profile.stages.${twoFactorAuthenticationStatus}`
								)}
							</Fx>
						</Fx>
						{isResetMFAButtonVisible && (
							<Permission
								dynamicCode={DynamicPermissionCode.Reset2Fa}
								dynamicCodeRole={values.primarySystemRoleId?.[0]?.label?.toLowerCase()}
							>
								{({ permitted }) => {
									return (
										permitted && (
											<>
												<Button
													onClick={onResetMFAClick}
													shape="round"
													type="primary"
													disabled={values.resetMFA}
												>
													{t(
														'twoFactorAuthorization.profile.resetMFA'
													)}
												</Button>
												<input
													type="checkbox"
													name="resetMFA"
													className={
														styles.displayNone
													}
												/>
											</>
										)
									);
								}}
							</Permission>
						)}
					</Fx>
				</Layout.List.Section>
				{/* TODO */}
				{/* {cognitoLogin && (
                    <Layout.List.Section title="Reset pasword">
                        <p>
                            Set a new temporary password for the user.
                            After submitting user will get the email with temporary credentials and will be able to create a new password
                        </p>
                        <Button 
                            ghost 
                            type="primary" 
                            shape="round" 
                            onClick={() => { 
                                authStore.resetPassword({ login: cognitoLogin });
                                Notification.success({
                                    description: 'Verification code sent.'
                                });
                            }}
                        >
                            Reset Password
                        </Button>
                    </Layout.List.Section>
                )} */}
			</Layout.List.ListItem>
		</Layout.List>
	);
}

export default Settings;
