import { OptionalValue } from 'Components';
import { TrendIndicator } from 'Pages/Dashboard/components';
import { inPercent } from 'Helpers/numeric';
import { defineDirection } from 'Stores/util';
import { MoodIndicator } from '../components';
import { getProgressStatus } from '../Dashboard.helpers';

export const renderMoodIndicatorColumn = (value: number | undefined) => {
	const status = getProgressStatus(value);

	return status ? (
		<>
			<MoodIndicator status={status} />
			{inPercent(value)}
		</>
	) : (
		<OptionalValue />
	);
};

export const renderTrendIndicator = (value: number | null) => {
	return typeof value === 'number' ? (
		<TrendIndicator direction={defineDirection(value)}>
			{inPercent(value)}
		</TrendIndicator>
	) : (
		<OptionalValue />
	);
};
