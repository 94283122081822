import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ReportByTokenType } from 'Services/Api/Reports/Types';
import RegularReport from './RegularReport';
import SavedReportButton from './SavedReportButton';
import { Button } from 'Components';
import { getIsPending } from 'Stores/util';
import { useDownloadReportButton } from './DownloadReportButton.hooks';

function DownloadReportButton() {
	const { t } = useTranslation();
	const {
		reportStore: { reportByToken: report, status }
	} = useStores();
	const { downloadPdfReport } = useDownloadReportButton(report);

	if (!report) return null;

	const submitText = t('download');

	return (
		<>
			{(report.type ===
				ReportByTokenType.ReminderAboutUsersAndGroupsDeletion ||
				report.type === ReportByTokenType.WeeklySummary) && (
				<RegularReport report={report}>{submitText}</RegularReport>
			)}

			{(report.type === ReportByTokenType.ExecutiveSummary ||
				report.type === ReportByTokenType.RiskScore ||
				report.type === ReportByTokenType.StandardReports ||
				report.type === ReportByTokenType.UsersListParams ||
				report.type === ReportByTokenType.UsersActivityDashboard ||
				report.type === ReportByTokenType.SystemRoles ||
				report.type === ReportByTokenType.OwnershipGroups ||
				report.type === ReportByTokenType.DeletedGroups ||
				report.type === ReportByTokenType.Groups ||
				report.type === ReportByTokenType.AuditLog) && (
				<Button
					type="primary"
					shape="round"
					onClick={downloadPdfReport}
					loading={getIsPending(status.downloadReportByPdfId)}
				>
					{submitText}
				</Button>
			)}

			{report.type === ReportByTokenType.UserSavedReports && (
				<SavedReportButton params={report.meta} />
			)}
		</>
	);
}

export default observer(DownloadReportButton);
