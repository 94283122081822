import { CheckboxOptionType } from 'antd';
import { AxiosErrorResponse } from 'app-types';
import {
	Button,
	Checkbox,
	Icon,
	Modal,
	Notification,
	Permission
} from 'Components';
import ModalContextProvider, { useModal } from 'Hooks/useModal';
import { useTranslation } from 'react-i18next';
import styles from './DashboardExport.module.scss';
import { useDashboardTabs } from '../Dashboard.hooks';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useState } from 'react';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { PermissionCode } from 'Services/Api/Users/Types';
import { useDashboardContext } from '../DashboardContext';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';
import { useDownloadReportProgress } from 'Hooks/useDownloadReportProgress/useDownloadReportProgress';
import { getAxiosErrorType } from 'Helpers/api';

function DashboardExport() {
	return (
		<Permission code={PermissionCode.GenerateDashboardExecutiveSummary}>
			{(permitted) =>
				permitted.permitted && (
					<ModalContextProvider>
						<DashboardExportContent />
					</ModalContextProvider>
				)
			}
		</Permission>
	);
}

const DashboardExportContent = observer(() => {
	const { t } = useTranslation();
	const { downloadReportsStore, authStore } = useStores();
	const { modal, showModal, hideModal } = useModal();
	const { exportedTabs } = useDashboardTabs();
	const { filters } = useDashboardContext();

	const options = exportedTabs.reduce<CheckboxOptionType[]>((acc, tab) => {
		if (!tab.hasPermission) return acc;
		acc.push({
			label: t(tab.tab),
			value: tab.key
		});
		return acc;
	}, []);
	const initialValue = options.map<DashboardTabKey>(
		(opt) => opt.value as DashboardTabKey
	);
	const [value, setValue] = useState<DashboardTabKey[]>(initialValue);

	useDownloadReportProgress();

	const handleExportClick = () => {
		showModal({ value: null });
	};

	const onOk = async () => {
		hideModal();
		try {
			await downloadReportsStore.generateAndDownloadExecutiveSummaryReport(
				{
					...filters,
					tabs: value
				}
			);
		} catch (e) {
			const axiosError = e as AxiosErrorResponse;
			let description = t('errorCodes.genericErrorMessage');
			const { isNotFoundError } = getAxiosErrorType(axiosError);
			if (isNotFoundError && authStore.isCurrentUserOwner) {
				description = t('errorCodes.NO_AVAILABLE_PROPERTIES');
			}

			Notification.error({
				description
			});
		}
	};

	const onCancel = () => {
		hideModal();
		setValue(initialValue);
	};

	const onChange = (checkedValue: CheckboxValueType[]) => {
		setValue(checkedValue as typeof value);
	};

	return (
		<>
			<Button
				shape="round"
				type="primary"
				onClick={handleExportClick}
				disabled={!options.length}
			>
				<Icon type="download" />
				{t('dashboard.export')}
			</Button>
			{modal && (
				<Modal
					visible
					title={t('dashboard.pdfExport.title')}
					onOk={onOk}
					onCancel={onCancel}
					okText={t('export')}
					okButtonProps={{
						disabled: !value.length
					}}
				>
					{t('dashboard.pdfExport.description')}
					<Checkbox.Group
						onChange={onChange}
						vertical
						className={styles.checkbox}
						value={value}
						options={options}
					/>
				</Modal>
			)}
		</>
	);
});

export default observer(DashboardExport);
