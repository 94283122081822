import styles from './CustomLabel.module.scss';
interface CustomLabelProps {
	title: string;
	subTitle: string;
}

function CustomLabel(props: CustomLabelProps) {
	return (
		<>
			<span>{props.title}</span>
			<span className={styles.subTitle}>{props.subTitle}</span>
		</>
	);
}

export default CustomLabel;
