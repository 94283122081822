import { Form, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Button, EditableLayout, Fx, Layout, Space } from 'Components';
import General from './General';
import TimePeriod from './TimePeriod/TimePeriod';
import { ReportFileType } from 'Services/Api/Reports/Types';
import Filters from './Filters/Filters';
import { ReportFormValues } from './schema';
import Recurrence from './Recurrence/Recurrence';
import {
	EditableLayoutContextValue,
	LayoutProps
} from 'Components/EditableLayout/EditableLayout';
import PreviewReportButton from '../PreviewReport/PreviewReportButton';
import EditReportHeading from './EditReportHeading';
import DownloadReportButton from './EditReportDownloadButton';

type EditReportProps = Pick<LayoutProps, 'goBack'>;

function EditReportFormLayout({ goBack }: EditReportProps) {
	const { initialValues } = useFormikContext<ReportFormValues>();
	return (
		<Form>
			<EditableLayout
				goBack={goBack}
				heading={
					<EditReportHeading
						name={initialValues.name}
						reportType={initialValues.reportType}
					/>
				}
				footer={Footer}
			>
				<Layout.List>
					<General />
					<TimePeriod />
					<Filters reportType={initialValues.reportType} />
					<Recurrence />
				</Layout.List>
			</EditableLayout>
		</Form>
	);
}

const Footer = (props: EditableLayoutContextValue) => {
	const { t } = useTranslation();
	const {
		initialValues,
		values,
		dirty,
		isValid,
		isSubmitting,
		submitCount,
		submitForm
	} = useFormikContext<ReportFormValues>();
	const isAdd = !values.id;
	const isSubmitDisabled = !(
		((isAdd && submitCount === 0) || dirty) &&
		isValid
	);
	return (
		<Layout.Footer>
			<Fx justify="space-between" align="middle">
				<Button
					ghost
					shape="round"
					type="default"
					onClick={props.goBack}
				>
					{t('cancel')}
				</Button>

				<Space>
					{initialValues.fileType !== ReportFileType.Pdf && (
						<PreviewReportButton disabled={!isValid} />
					)}
					<DownloadReportButton disabled={!isValid} params={values} />
				</Space>

				<Button
					shape="round"
					type="primary"
					disabled={isSubmitDisabled}
					loading={isSubmitting}
					onClick={submitForm}
				>
					{t('save')}
				</Button>
			</Fx>
		</Layout.Footer>
	);
};

export default EditReportFormLayout;
