import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { ColumnProps } from 'antd/lib/table';
import { DateFormatted } from 'Components';
import {
	useCreateColumns,
	ColumnFilters,
	UseCreateColumnsInterface
} from 'Components/Table/createColumns';
import { getAppRoutes } from 'Pages/App/App.constants';
import { GetSystemRolesRequest, SystemRole } from 'Services/Api/Roles/Types';
import { GetUsersRequest } from 'Services/Api/Users/Types';
import { qsStringify, toPath } from 'Helpers/navigation';
import { getUserRoleLabel } from 'app-util';

type FilterValues = GetSystemRolesRequest['filters'];
export type TableFilterValues = ColumnFilters<FilterValues>;

export function useColumnProps<T extends SystemRole>(
	helpers?: ReturnType<UseCreateColumnsInterface<T, FilterValues>>
) {
	const { t } = useTranslation();
	const columns: Array<ColumnProps<T>> = [
		{
			...helpers?.search,
			...helpers?.sort,
			sortOrder: helpers?.getSortOrder('role'),
			title: t('roles.columns.role'),
			key: 'role',
			dataIndex: 'role',
			filteredValue: helpers?.filters.role,
			render: (_, record) => (
				<Link
					to={generatePath(getAppRoutes().EDIT_ROLE, {
						roleId: record.id
					})}
				>
					{getUserRoleLabel(record.role)}
				</Link>
			)
		},
		{
			...helpers?.sort,
			sortOrder: helpers?.getSortOrder('usersCount'),
			title: t('roles.columns.usersCount'),
			key: 'usersCount',
			dataIndex: 'usersCount',
			render: (value, record) => {
				if (value === 0) {
					return value;
				}
				const usersFilters: GetUsersRequest['filters'] = {
					systemRoles: [record.role]
				};
				const to = toPath({
					path: getAppRoutes().USERS,
					query: qsStringify(usersFilters)
				});
				return <Link to={to}>{value}</Link>;
			}
		},
		{
			...helpers?.sort,
			sortOrder: helpers?.getSortOrder('permissionsCount'),
			title: t('roles.columns.permissionsCount'),
			key: 'permissionsCount',
			dataIndex: 'permissionsCount'
		},
		{
			...helpers?.sort,
			sortOrder: helpers?.getSortOrder('createdDate'),
			title: t('roles.columns.createdDate'),
			key: 'createdDate',
			dataIndex: 'createdDate',
			render: (value) => <DateFormatted date={value} />
		}
	];
	return columns;
}

const useColumns = <T extends SystemRole>({
	params
}: {
	params: GetSystemRolesRequest | undefined;
}) => {
	const helpers = useCreateColumns<T, FilterValues>({
		filterValues: params?.filters || {},
		sortDesc: params?.sort
	});
	return useColumnProps(helpers);
};

export default useColumns;
