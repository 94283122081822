const DASHBOARD_API = {
	GENERATE_REPORT: '/dashboard/executiveSummary/generateReport',
	GENERATE_REPORT_EMAIL_ME: '/reports/userRequestSendEmail',
	GENERATED_REPORT: '/reports/download',

	OVERVIEW_SCORES: '/dashboard/overview/scores',
	OVERVIEW_SUMMARY: '/dashboard/overview/groupSummary',

	PHISHING_CAMPAIGNS: '/dashboard/phishing/activeCampaigns',
	PHISHING_SCORE: '/dashboard/phishing/totalCampaignsScore',
	PHISHING_OVERVIEW: '/dashboard/phishing/totalCampaignOverview',
	PHISHING_GROUP_SUMMARY: '/dashboard/phishing/groupSummary',
	PHISHING_CAMPAIGN_SUMMARY: '/dashboard/phishing/campaignSummary',

	ELEARNING_PATH_SUMMARY: '/dashboard/elearning/learningPathSummary',
	ELEARNING_GROUP_SUMMARY: '/dashboard/elearning/groupSummary',
	ELEARNING_TOTAL_OVERVIEW: '/dashboard/elearning/totalOverview',
	ELEARNING_TOTAL_SCORE: '/dashboard/elearning/totalScore',
	ELEARNING_COURSE_ACTIVITY: '/dashboard/elearning/courseActivity',
	ELEARNING_COMPLETED_COURSES:
		'/dashboard/elearning/historicalCompletedCourses',

	SPOT_CHECK_OVERVIEW: '/dashboard/spotCheck/overview',
	SPOT_CHECK_SUMMARY: '/dashboard/spotCheck/groupSummary',
	SPOT_CHECK_JOB_ROLE: '/dashboard/spotCheck/jobRole',
	SPOT_CHECK_EXPORT_ICI_JOB_ROLE: '/dashboard/spotCheck/exportICI',
	MY_RESULTS: '/dashboard/myResults',

	RISK_SCORE: '/dashboard/overview/riskScoreCard'
};

export default DASHBOARD_API;
