import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { APIErrorCodes } from 'app-types';
import { Modal, Notification } from 'Components';
import { useModal } from 'Hooks/useModal';
import { RESTORE_GROUPS } from 'Services/Api/Groups/Mutations';
import { RestoreGroupsRequest } from 'Services/Api/Groups/Types';
import { checkApolloError } from 'Helpers/graphql';
import { useConfigs } from 'Hooks';
import TInfoText from 'Contexts/TInfoText/TInfoText';

export enum DeletedGroupsActionsModalName {
	Restore = 'Restore'
}

function adaptMetaEntities(entities: string[]) {
	return entities.map((entity) => `"${entity}"`).join(', ');
}

export interface DeletedGroupsActionsModalsProps {
	ids: string[];
	idsNames: string[];
	onSubmit: () => void;
}
function DeletedGroupsActionsModals({
	ids,
	idsNames,
	onSubmit
}: DeletedGroupsActionsModalsProps) {
	const { t } = useTranslation();
	const { modal, hideModal } = useModal();
	const [restoreGroups, result] = useMutation<unknown, RestoreGroupsRequest>(
		RESTORE_GROUPS,
		{ fetchPolicy: 'no-cache' }
	);
	const configs = useConfigs();
	const isSingleSelected = ids.length === 1;
	const onRestoreSubmit = async () => {
		try {
			await restoreGroups({
				variables: {
					input: {
						ids
					}
				}
			});
			onSubmit();
			hideModal();
			const description = t(
				`deletedGroups.deletedGroupsActionsModals.restoreModal.successNotification.${
					isSingleSelected ? 'single' : 'multiple'
				}`
			);
			Notification.success({
				description
			});
		} catch (error) {
			const apolloError = checkApolloError(error);

			if (apolloError.isApolloError()) {
				const [parentGroupNames, groupName] = apolloError.getMeta<
					string[][]
				>();
				const isSingle =
					parentGroupNames.length === 1 && groupName.length === 1;
				const path = isSingle ? 'single' : 'multiple';
				if (apolloError.is(APIErrorCodes.GroupsParentDeleted)) {
					Notification.error({
						description: t(
							`deletedGroups.deletedGroupsActionsModals.restoreModal.errorNotification.${APIErrorCodes.GroupsParentDeleted}.${path}`,
							{
								parentGroupName: parentGroupNames,
								groupName
							}
						)
					});
				}
				if (
					apolloError.is(
						APIErrorCodes.GroupHierarchyDepthLImitExceeded
					)
				) {
					Notification.error({
						description: t(
							`errorCodes.${APIErrorCodes.GroupHierarchyDepthLImitExceeded}.${path}`,
							{
								parentGroups: parentGroupNames.join(', '),
								groupName,
								limit: configs.groupHierarchyLevel
							}
						)
					});
				}
				if (
					apolloError.is(
						APIErrorCodes.GroupCannotBeRestoredAsProperty
					)
				) {
					const groups = apolloError.getMeta<string[]>();

					Notification.error({
						description: t(
							`deletedGroups.deletedGroupsActionsModals.restoreModal.errorNotification.${APIErrorCodes.GroupCannotBeRestoredAsProperty}`,
							{ groups: `(${groups})` }
						)
					});
				}

				if (
					apolloError.is(
						APIErrorCodes.GroupsHierarchySizeLimitExceeded
					)
				) {
					const groups = adaptMetaEntities(
						apolloError.getMeta<string[]>()
					);

					Notification.error({
						description: t(
							`deletedGroups.deletedGroupsActionsModals.restoreModal.errorNotification.${APIErrorCodes.GroupsHierarchySizeLimitExceeded}`,
							{
								groups: `(${groups})`,
								limit: configs.groupsHierarchySizeLimit
							}
						)
					});
				}

				if (
					apolloError.is(APIErrorCodes.GroupNameShouldBeUniqueAtLevel)
				) {
					const groupNames = adaptMetaEntities(
						apolloError.getMeta<string[]>()
					);

					Notification.error({
						description: t(
							`deletedGroups.deletedGroupsActionsModals.restoreModal.errorNotification.${APIErrorCodes.GroupNameShouldBeUniqueAtLevel}`,
							{
								groupNames
							}
						)
					});
				}
			} else {
				Notification.error({
					description: t('errorCodes.genericErrorMessage')
				});
			}
			hideModal();
		}
	};

	const modalPropsByName: Record<
		DeletedGroupsActionsModalName,
		{ title: React.ReactNode; description: string }
	> = {
		[DeletedGroupsActionsModalName.Restore]: {
			title: (
				<TInfoText
					path={`deletedGroups.deletedGroupsActionsModals.restoreModal.title.${
						isSingleSelected ? 'single' : 'multiple'
					}`}
					params={{ name: idsNames[0] }}
				/>
			),
			description: t(
				`deletedGroups.deletedGroupsActionsModals.restoreModal.description.${
					isSingleSelected ? 'single' : 'multiple'
				}`
			)
		}
	};

	if (!modal?.name) {
		return null;
	}

	const modalName: DeletedGroupsActionsModalName = modal.name;
	const modalProps = modalPropsByName[modalName];

	return (
		<>
			{modalName === DeletedGroupsActionsModalName.Restore && (
				<Modal
					visible
					title={modalProps.title}
					okText={t('restore')}
					okButtonProps={{
						loading: result.loading
					}}
					onOk={onRestoreSubmit}
					onCancel={hideModal}
					maskClosable={false}
				>
					{modalProps.description}
				</Modal>
			)}
		</>
	);
}

export default DeletedGroupsActionsModals;
