import { useTranslation } from 'react-i18next';
import {
	GROUP_ACTIVE_STATUS_LABEL_MAP,
	GROUP_ACTIVE_STATUS_LIST
} from 'app-config';
import { Select } from 'Components';
import { useModal } from 'Hooks/useModal';
import PropertiesFieldModal, {
	PropertiesFieldModalProps
} from './PropertiesFieldModal';
import { useFormikContext } from 'formik';
import { Values } from '../AddOwnershipGroup.types';
import styles from './PropertiesField.module.scss';
import { labelValueToDataTreeValue } from 'Contexts/DataTree/DataTree.helpers';

function PropertiesField() {
	const { t } = useTranslation();
	const {
		values,
		setFieldValue,
		setFieldTouched
	} = useFormikContext<Values>();
	const { modal, showModal } = useModal();

	const onClick: React.MouseEventHandler<HTMLDivElement> = () => {
		showModal({ value: null });
	};

	const onSubmit: PropertiesFieldModalProps['onSubmit'] = (value) => {
		const newValue: Values['groups'] =
			value?.map((item) => ({
				key: item.key,
				value: item.key,
				label: item.title
			})) ?? [];
		setFieldValue('groups', newValue);
		setTimeout(() => {
			// trigger validate
			// use timeout to avoid error message blinking when set for the first time
			setFieldTouched('groups');
		});
	};

	const onChange = (values: Values['groups']) => {
		onSubmit(values);
	};

	return (
		<>
			{modal && (
				<PropertiesFieldModal
					initialValue={labelValueToDataTreeValue(values.groups)}
					onSubmit={onSubmit}
				/>
			)}
			<Select.FormikField
				mode="multiple"
				name="groups"
				label={t('ownershipGroup.addOwnershipGroup.fieldLabels.groups')}
				labelInValue
				className={styles.field}
				dropdownRender={() => <></>}
				onClick={onClick}
				// eslint-disable-next-line
				onChange={onChange as any}
			>
				{GROUP_ACTIVE_STATUS_LIST.map((status) => (
					<Select.Option key={status} value={status}>
						{GROUP_ACTIVE_STATUS_LABEL_MAP[status]()}
					</Select.Option>
				))}
			</Select.FormikField>
		</>
	);
}

export default PropertiesField;
