import { Icon } from '..';
import { ICON_BY_MENU_KEY, TITLE_BY_MENU_KEY } from 'i18n/i18n.constants';
import { LanguageI18nKey } from 'Services/Api/Common/Types';

interface LanguageMenuItemProps {
	menuKey: LanguageI18nKey;
	withTitle?: boolean;
}

const LanguageMenuItem = ({ menuKey, withTitle }: LanguageMenuItemProps) => {
	const iconType = ICON_BY_MENU_KEY[menuKey];
	return (
		<>
			{iconType ? <Icon type={iconType} /> : null}
			{withTitle ? <span>&nbsp;{TITLE_BY_MENU_KEY[menuKey]}</span> : null}
		</>
	);
};

export default LanguageMenuItem;
