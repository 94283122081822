import { FC, useEffect } from 'react';
import { TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { Fx, Layout, Table } from 'Components';
import { createSortDescription } from 'Components/Table/createColumns';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SpotCheckGroupsOverviewItem } from 'Services/Api/Dashboard/Types';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import { getIsPending } from 'Stores/util';
import { useColumns } from './columns';
import { ReportType } from 'Services/Api/Reports/Types';
import { DownloadReportButton } from 'Pages/Dashboard/components';

const GroupSummary: FC = () => {
	const { t } = useTranslation();
	const { filters } = useDashboardContext();
	const { dashboardStore } = useStores();
	const { spotCheck, status } = dashboardStore;

	const handleTableChange: TableProps<SpotCheckGroupsOverviewItem>['onChange'] = (
		_,
		__,
		sorter
	) => {
		const sort = createSortDescription<SpotCheckGroupsOverviewItem>(
			sorter as SorterResult<SpotCheckGroupsOverviewItem>
		);

		dashboardStore.getSpotCheckGroupSummary({
			...filters,
			sort
		});
	};

	useEffect(() => {
		dashboardStore.getSpotCheckGroupSummary(filters);
		// eslint-disable-next-line
	}, [filters]);

	return (
		<Layout>
			<Layout.Heading>
				<Fx align="middle" justify="space-between">
					<h3>{t('dashboard.groupSummary.title')}</h3>
					<DownloadReportButton
						reportType={ReportType.SpotcheckGroupSummary}
					/>
				</Fx>
			</Layout.Heading>
			<Table<SpotCheckGroupsOverviewItem>
				dataSource={spotCheck.groupSummary.data}
				rowKey="groupId"
				columns={useColumns({
					params: spotCheck.groupSummary.params
				})}
				pagination={false}
				onChange={handleTableChange}
				loading={getIsPending(status.getSpotCheckGroupSummary)}
			/>
		</Layout>
	);
};

export default observer(GroupSummary);
