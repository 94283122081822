import { curry } from 'lodash';
import { v4 as uuid } from 'uuid';
import { APP_CONFIG } from 'app-config';
import {
	PreviewReportRequest,
	ReportType,
	AddReportRequest,
	UserActivityParams,
	UserActivityTab
} from 'Services/Api/Reports/Types';
import { prepareRequest } from '../EditReport/EditReport.helpers';
import { ReportFormValues } from '../EditReport/schema';

export const prepareBaseParams = (
	reportRequest: AddReportRequest
): PreviewReportRequest => {
	return {
		name: reportRequest.name,
		reportType: reportRequest.reportType,
		timePeriodType: reportRequest.timePeriodType,
		definedPeriod: reportRequest.definedPeriod,
		fromPeriodFrom: reportRequest.fromToPeriod?.from,
		fromPeriodTo: reportRequest.fromToPeriod?.to,
		displayResultsBy: reportRequest.displayResultsBy,
		showOnlyProperties: reportRequest.showOnlyProperties,
		groupId: reportRequest.groupId,
		ownershipGroupId: reportRequest.ownershipGroupId
	};
};

export const createUserActivityRequest = (
	previewReportRequest: PreviewReportRequest,
	userActivityParams: UserActivityParams
): PreviewReportRequest => {
	return Object.assign({}, previewReportRequest, userActivityParams);
};

export const curryCreateUserActivityRequest = curry(createUserActivityRequest);

export const preparePreviewRequest = (
	values: ReportFormValues
): PreviewReportRequest => {
	const request = prepareRequest(values);
	const params = prepareBaseParams(request);

	switch (request.reportType) {
		case ReportType.Users:
			return {
				...params,
				userStatus: request.userStatus,
				userLoginStatus: request.userLoginStatus,
				pageNumber: APP_CONFIG.TABLE_DEFAULT_PAGINATION_PAGE,
				pageSize: APP_CONFIG.TABLE_PAGE_SIZE
			};
		case ReportType.ExecutiveSummary:
			return {
				...params,
				executeSummaryTypes: request.executeSummaryTypes
			};
		case ReportType.UserActivity:
			return createUserActivityRequest(params, {
				userActivityTab: UserActivityTab.Total,
				pageNumber: APP_CONFIG.TABLE_DEFAULT_PAGINATION_PAGE,
				pageSize: APP_CONFIG.TABLE_PAGE_SIZE
			});
	}

	return params;
};

export function adaptPropsDataToDataSource<E>(data: E[]) {
	return Array.isArray(data)
		? data.map((e) => ({
				...e,
				uuid: uuid()
		  }))
		: [];
}
