import { useTranslation } from 'react-i18next';
import { Alert, Checkbox } from 'Components';
import { useFormikContext } from 'formik';
import { AddGroupValues } from 'Pages/Group/Group.types';

function ArchivedField() {
	const { t } = useTranslation();
	const { values, initialValues } = useFormikContext<AddGroupValues>();
	return (
		<>
			<Checkbox.FormikField name="isArchived">
				{t('group.archived')}
			</Checkbox.FormikField>
			{values.isArchived &&
				values.isArchived !== initialValues.isArchived && (
					<>
						<Alert
							showIcon
							message=""
							description={t('group.archivedWarningMessage')}
							type="error"
						/>
						<br />
					</>
				)}
		</>
	);
}

export default ArchivedField;
