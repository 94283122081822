import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import { Input as InputElement, InputProps } from 'antd';
import { Input, Icon } from 'Components';
import styles from './InputSearchToggle.module.scss';
import { APP_CONFIG } from 'app-config';
import { usePrevious } from 'Hooks/usePrevious';

type InputSearchToggleProps = Pick<InputProps, 'value' | 'className'> & {
	onChange: (value: string) => void;
};

const InputSearchToggle: React.FC<InputSearchToggleProps> = ({
	value,
	className,
	onChange
}) => {
	const [inputValue, setInputValue] = useState<
		InputSearchToggleProps['value']
	>(value);
	const [isInputVisible, setIsInputVisible] = useState<boolean>(false);
	const inputRef = useRef<InputElement | null>(null);

	const previousInputValue = usePrevious(inputValue);

	useEffect(() => {
		if (isInputVisible) {
			inputRef?.current?.input.focus();
		}
	}, [isInputVisible]);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const handleClick = () => {
		setIsInputVisible(true);
	};

	const handleOnBlur = () => {
		const currentValue = inputRef.current?.input.value ?? '';
		if (currentValue.length < APP_CONFIG.SEARCH_MIN_LENGTH) {
			setIsInputVisible(false);

			const newValue = '';
			setInputValue(newValue);
			onChange(newValue);
		}
	};

	const onInputChange: InputProps['onChange'] = (e) => {
		const newValue = e.target.value;

		setInputValue(newValue);

		const newValueLength = newValue.length;

		if (
			newValueLength >= APP_CONFIG.SEARCH_MIN_LENGTH ||
			(!newValueLength && (previousInputValue as string)?.length)
		) {
			onChange?.(newValue);
		}
	};

	return (
		<div className={cn(className, styles.root)}>
			{isInputVisible ? (
				<Input.Search
					onChange={onInputChange}
					prefix={<Icon type="search" />}
					ref={inputRef}
					onBlur={handleOnBlur}
					allowClear
					className={styles.input}
					value={inputValue}
				/>
			) : (
				<Icon
					onClick={handleClick}
					type="search"
					className={styles.inputSearchIcon}
				/>
			)}
		</div>
	);
};

export default InputSearchToggle;
