import { isNil } from 'lodash';
import { SAD_BOUNDARY, HAPPY_BOUNDARY } from './Dashboard.constants';
import { ProgressStatus, TrendIndicatorDirection } from './Dashboard.types';

const MIN_VALID_VALUE = 0;
const MAX_VALID_VALUE = 100;

export const getProgressStatus = (
	value?: number | null
): ProgressStatus | null => {
	if (isNil(value) || value < MIN_VALID_VALUE || value > MAX_VALID_VALUE) {
		return null;
	}

	if (value < SAD_BOUNDARY) {
		return 'sad';
	}

	if (value > HAPPY_BOUNDARY) {
		return 'happy';
	}

	return 'normal';
};

export const getAbsDiffValue = (
	currentValue: number | null,
	hystoricalValue?: number | null
) =>
	isNil(hystoricalValue) || isNil(currentValue)
		? null
		: Math.abs(currentValue - hystoricalValue);

export const getTrendDirection = (
	currentValue: number | null,
	hystoricalValue?: number | null
): TrendIndicatorDirection | null => {
	if (
		isNil(hystoricalValue) ||
		isNil(currentValue) ||
		currentValue === hystoricalValue
	) {
		return null;
	}

	return currentValue - hystoricalValue < 0 ? 'down' : 'up';
};
