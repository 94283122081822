import { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import { useHistory } from 'react-router';

import { ON_USERS_DISABLE } from 'Services/Api/Users/Mutations';
import {
	OnUsersDisableRequest,
	OnUsersDisableResponse
} from 'Services/Api/Users/Types';
import { getAppRoutes } from 'Pages/App/App.constants';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';

function StatusLogoutTracker() {
	const history = useHistory();
	const { authStore } = useStores();

	const result = useSubscription<
		OnUsersDisableResponse,
		OnUsersDisableRequest
	>(ON_USERS_DISABLE, {
		variables: {
			shouldLogout: true
		}
	});
	const data = result.data?.onUsersDisable;

	useEffect(() => {
		if (
			authStore.currentUser &&
			data?.peakV3Ids.includes(authStore.currentUser.peakV3Id)
		) {
			window.unblock?.();
			history.push(getAppRoutes().STATUS_LOGOUT, {
				isMFA: Boolean(data?.mfa)
			});
		}
	}, [history, authStore, data]);

	return null;
}

export default observer(StatusLogoutTracker);
