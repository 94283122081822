import * as yup from 'yup';
import { FIELD_TYPE_VALIDATION, WHITESPACE_PATTERN } from 'Helpers/validations';
import { EditGroupValues } from './Group.types';
import {
	maxDigits,
	minDigits,
	nospace,
	tenantIdFieldValidationRules
} from 'Helpers/validations/validations.helpers';

const TENANT_ID_VALIDATION_RULES = FIELD_TYPE_VALIDATION.text
	.label('group.tenantID')
	.test(
		'is-no-space',
		nospace,
		(value = '') => !value.match(WHITESPACE_PATTERN)
	)
	.matches(/^[a-z\-_\d]+$/, tenantIdFieldValidationRules)
	.min(2)
	.max(50)
	.required();

const SYNC_CODE_VALIDATION = FIELD_TYPE_VALIDATION.text
	.label('group.syncCode')
	.nullable()
	.min(1, minDigits)
	.max(60, maxDigits);

export const tenantSchema = (maxGroupNameAmountCharacters: number) =>
	yup.object().shape<Partial<Record<keyof EditGroupValues, yup.AnySchema>>>({
		name: FIELD_TYPE_VALIDATION.text
			.min(2)
			.max(maxGroupNameAmountCharacters)
			.label('group.tenantName')
			.required(),
		active: yup.string().label('group.status').required(),
		syncCode: SYNC_CODE_VALIDATION,
		tenantId: TENANT_ID_VALIDATION_RULES,
		systemId: FIELD_TYPE_VALIDATION.text
			.label('group.systemName')
			.test(
				'is-no-space',
				nospace,
				(value = '') => !value.match(WHITESPACE_PATTERN)
			)
			.min(2)
			.max(50)
			.required()
	});

export const editTenantSchema = (maxGroupNameAmountCharacters: number) =>
	yup.object().shape<Partial<Record<keyof EditGroupValues, yup.AnySchema>>>({
		name: FIELD_TYPE_VALIDATION.text
			.min(2)
			.max(maxGroupNameAmountCharacters)
			.label('group.tenantName')
			.required(),
		active: yup.string().label('group.status').required(),
		syncCode: SYNC_CODE_VALIDATION,
		tenantId: TENANT_ID_VALIDATION_RULES
	});

export const groupSchema = (maxGroupNameAmountCharacters: number) =>
	yup.object().shape({
		name: FIELD_TYPE_VALIDATION.text
			.min(2)
			.max(maxGroupNameAmountCharacters)
			.label('group.groupName')
			.required(),
		active: yup.string().label('group.status').required(),
		syncCode: FIELD_TYPE_VALIDATION.text
			.label('group.syncCode')
			.nullable()
			.min(1, minDigits)
			.max(60, maxDigits)
	});
