import { Button, Icon } from 'Components';
import ModalContextProvider, { ModalContext } from 'Hooks/useModal';
import GroupsColumnFilterModal, {
	GroupsColumnFilterModalProps
} from './GroupsColumnFilterModal';

function GroupsColumnFilter(props: GroupsColumnFilterModalProps) {
	return (
		<ModalContextProvider>
			<ModalContext.Consumer>
				{({ modal, showModal }) => (
					<>
						<Button
							type="text"
							thin
							onClick={() => showModal({ value: null })}
						>
							<Icon type="filter" />
						</Button>
						{modal && <GroupsColumnFilterModal {...props} />}
					</>
				)}
			</ModalContext.Consumer>
		</ModalContextProvider>
	);
}

export default GroupsColumnFilter;
