import { MenuItem } from 'app-types';

export enum GroupMoreButtonMenuItemKey {
	Edit = 'Edit',
	Move = 'Move',
	Users = 'Users',
	ResetPassword = 'ResetPassword',
	Delete = 'Delete'
}

export type GroupMoreButtonMenuItem = MenuItem<
	GroupMoreButtonMenuItemKey,
	{ visible?: false }
>;
