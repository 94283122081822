import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Notification } from 'Components';
import { useStores } from 'Hooks/useStore';
import { getAppRoutes } from 'Pages/App/App.constants';

export function useResetExpiredPassword() {
	const { t } = useTranslation();
	const { authStore } = useStores();
	const history = useHistory();
	const appRoutes = getAppRoutes();
	return async (login: string) => {
		await authStore.resetExpiredUser({
			cognitoLogin: login,
			// nullable chain need for ts only
			userPoolId: authStore.currentUserPoolData?.userPoolId ?? ''
		});
		Notification.success({
			description: t('auth.resetTemporaryPassword.notificationSuccess')
		});
		history.push(appRoutes.FIRST_LOGIN);
	};
}
