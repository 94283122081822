import { useColumnProps as useActiveCampaignsColumnProps } from 'Pages/Dashboard/tabs/Phishing/ActiveCampaigns/columns';
import { useColumnProps as useElearningColumnProps } from 'Pages/Dashboard/tabs/Elearning/GroupSummary/columns';
import { useColumnProps as useOverviewColumnProps } from 'Pages/Dashboard/tabs/Overview/GroupSummary/columns';
import { useColumnProps as useRolesColumnProps } from 'Pages/Roles/columns';
import { useGroupColumns } from './columns/groupColumns';
import { usePhishingColumns } from './columns/phishingColumns';
import { useSpotCheckColumns } from './columns/spotCheckColumns';
import { usePreviewUsersColumns } from './columns/usersColumns';
import {
	PreviewReportResponse,
	ReportDataByReportType,
	ReportType
} from 'Services/Api/Reports/Types';
import { ColumnProps } from 'antd/lib/table';

type UseColumnsPropsFn<T = undefined> = T extends undefined
	? () => ColumnProps<any>[] // eslint-disable-line
	: (data: T) => ColumnProps<any>[]; // eslint-disable-line

export const COLUMNS_WITH_DATA: Partial<
	Record<
		keyof ReportDataByReportType,
		UseColumnsPropsFn<PreviewReportResponse>
	>
> = {
	[ReportType.PhishingGroupSummary]: usePhishingColumns,
	[ReportType.SpotcheckGroupSummary]: useSpotCheckColumns
};

export const COLUMNS: Partial<
	Record<keyof ReportDataByReportType, UseColumnsPropsFn>
> = {
	[ReportType.ActiveCampaigns]: useActiveCampaignsColumnProps,
	[ReportType.ElearningGroupSummary]: useElearningColumnProps,
	[ReportType.Groups]: useGroupColumns,
	[ReportType.OverviewGroupSummary]: useOverviewColumnProps,
	[ReportType.PhishingGroupSummary]: undefined,
	[ReportType.Roles]: useRolesColumnProps,
	[ReportType.SpotcheckGroupSummary]: undefined,
	[ReportType.UserActivity]: usePreviewUsersColumns,
	[ReportType.Users]: usePreviewUsersColumns
};
