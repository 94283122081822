import { getAppRoutes } from 'Pages/App/App.constants';

export const getLeadPath = (
	pathname: string | undefined
): string | undefined => {
	if (typeof pathname === 'undefined') return;
	const appRoutes = getAppRoutes();
	if (pathname === appRoutes.HOME) {
		return pathname;
	}

	const [firstPathChunk, secondPathChunk] = pathname
		.replace(/^\//, '')
		.split('/');

	return `/${firstPathChunk}` === appRoutes.HOME
		? secondPathChunk
		: firstPathChunk;
};
