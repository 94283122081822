import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'antd';
import { useCreatePeakV2URL } from 'Hooks';
import { useStores } from 'Hooks/useStore';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import { Button, Icon, Tooltip } from 'Components';
import { Widgets } from '../../Dashboard.types';
import styles from './OpenInNewTab.module.scss';
import {
	adaptWidgetsParams,
	AdaptWidgetsParamsOutput
} from './OpenInNewTab.helpers';
import { PagesWithDynamicUrl } from 'Hooks/useCreatePeakV2URL';
import { DashboardFiltersValues } from 'Pages/Dashboard/Dashboard.types';
import { axiosClient } from 'Services/Api/client';
import SYSTEM_API from 'Services/Api/System/Api';
import { System } from 'Services/Api/System/Types';

interface OpenInNewTabProps<WP> {
	tab: DashboardTabKey;
	widget: Widgets;
	widgetParams?: WP;
	tooltipPlacement?: TooltipProps['placement'];
	button?: (handleButtonClick: () => void) => React.ReactNode;
}

type LinkUserInputTypes = {
	isProperty: DashboardFiltersValues['isProperty'];
} & AdaptWidgetsParamsOutput & {
		tab: DashboardTabKey;
		widget: Widgets;
		groupId: DashboardFiltersValues['id'];
	};

function OpenInNewTab<WP>({
	widget,
	widgetParams,
	tab,
	button,
	tooltipPlacement
}: OpenInNewTabProps<WP>) {
	const { t } = useTranslation();
	const { authStore, systemsStore } = useStores();
	const { filters } = useDashboardContext();
	const { isLMSModulesHidden, linkUserToPeakV2URL } = useCreatePeakV2URL({
		page: PagesWithDynamicUrl.Dashboard,
		groupId: filters?.id
	});

	if (isLMSModulesHidden) return null;

	const isDisabled = authStore.isCurrentUserSuperAdmin && !filters?.id;

	const handleButtonClick = async () => {
		if (isDisabled) return;

		const adaptedWidgetParams = adaptWidgetsParams<WP>(
			tab,
			widget,
			widgetParams
		);

		let system;
		if (authStore.isCurrentUserSuperAdmin) {
			const { data } = await axiosClient.get<System>(
				SYSTEM_API.GET_SYSTEM_BY_GROUP_ID,
				{
					params: {
						groupId: filters?.id
					}
				}
			);

			system = data;
		} else {
			system = systemsStore.systems[0];
		}

		await linkUserToPeakV2URL<LinkUserInputTypes>(system, {
			groupId: filters?.id,
			isProperty: filters?.isProperty,
			tab,
			widget,
			...adaptedWidgetParams
		} as LinkUserInputTypes);
	};
	const placement = tooltipPlacement ?? 'topLeft';
	return (
		<Tooltip
			enabled={isDisabled}
			title={t('dashboard.tabs.showMoreDetailsTooltipForSuperAdmin')}
			trigger="hover"
			placement={placement}
		>
			<div className={styles.root}>
				{button?.(handleButtonClick) ?? (
					<Button link thin type="text" onClick={handleButtonClick}>
						{t('dashboard.showMoreDetails')}
						<Icon className={styles.icon} type="newWindow" />
					</Button>
				)}
			</div>
		</Tooltip>
	);
}

export default OpenInNewTab;
