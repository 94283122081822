import { useEffect, useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';

import { createSortDescription } from 'Components/Table/createColumns';
import { GET_OWNERSHIP_GROUP_PROPERTIES } from 'Services/Api/Groups/Queries';
import {
	GetOwnershipGroupPropertiesRequest,
	GetOwnershipGroupPropertiesResponse,
	GroupActiveStatus,
	OwnershipGroup
} from 'Services/Api/Groups/Types';
import { getIsRequestParamsFiltered } from 'Helpers/table';
import useColumns, {
	OwnershipGroupTableFilters,
	OwnershipGroupTableRecordType
} from './columns';

export interface OwnershipGroupProps {
	group: OwnershipGroup | undefined;
	resultRefetch: () => void;
}
const useOwnershipGroup = (props: OwnershipGroupProps) => {
	const [getOwnershipGroupProperties, result] = useLazyQuery<
		GetOwnershipGroupPropertiesResponse,
		GetOwnershipGroupPropertiesRequest
	>(GET_OWNERSHIP_GROUP_PROPERTIES, {
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true
	});

	useEffect(() => {
		if (!props.group) {
			return;
		}
		void getOwnershipGroupProperties({
			variables: {
				sort: 'name:asc',
				id: props.group.id,
				filters: {
					status: [GroupActiveStatus.Active]
				}
			}
		});
	}, [props.group, getOwnershipGroupProperties]);

	return {
		result
	};
};

export const useOwnershipGroupTable = (props: OwnershipGroupProps) => {
	const { result } = useOwnershipGroup(props);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const { variables, loading } = result;
	const { columns, onChange } = useColumns({
		filterValues: variables?.filters
	});
	const dataSource = result.data?.listOwnershipGroupProperties;

	const namesBySelectedRowKeys = useMemo(() => {
		return !dataSource
			? []
			: dataSource.reduce<string[]>((acc, value) => {
					if (selectedRowKeys.includes(value.id)) {
						acc.push(value.property);
					}
					return acc;
			  }, []);
	}, [dataSource, selectedRowKeys]);

	const onTableChange: TableProps<OwnershipGroupTableRecordType>['onChange'] = (
		...args
	) => {
		onChange?.(...args);
		const { 1: filters, 2: sorter } = args;
		if (!result.refetch || !props.group) {
			return;
		}
		const sort = createSortDescription<OwnershipGroupTableRecordType>(
			sorter as SorterResult<OwnershipGroupTableRecordType>
		);
		const values = filters as OwnershipGroupTableFilters;
		const variables: GetOwnershipGroupPropertiesRequest = {
			id: props.group.id,
			sort,
			filters: {
				property: values.property?.[0] || undefined,
				tenantName: values.tenantName?.[0] || undefined,
				status: values.status,
				isDeleted: values.isDeleted,
				isArchived: values.isArchived
			}
		};

		result.refetch(variables);
	};

	const onControlSubmit = () => {
		setSelectedRowKeys([]);
		result.refetch?.();
	};

	const selected = Boolean(props.group);
	const filtered = getIsRequestParamsFiltered(result.variables?.filters);

	return {
		result,
		selected,
		filtered,
		namesBySelectedRowKeys,
		columns,
		loading,
		dataSource,
		selectedRowKeys: selectedRowKeys as number[],
		setSelectedRowKeys,
		onTableChange,
		onControlSubmit
	};
};
