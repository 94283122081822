import { ColumnProps } from 'antd/lib/table';
import {
	ARCHIVED_LABEL,
	DELETED_LABEL,
	GROUP_ACTIVE_STATUS_LABEL_MAP,
	GROUP_ACTIVE_STATUS_TAG_MAP,
	GROUP_ACTIVE_STATUS_LIST
} from 'app-config';
import { Tag } from 'Components';
import {
	useCreateColumns,
	ColumnFilters
} from 'Components/Table/createColumns';
import {
	GetOwnershipGroupPropertiesRequest,
	OwnershipGroupProperty
} from 'Services/Api/Groups/Types';
import i18n from 'i18n';

type Filters = GetOwnershipGroupPropertiesRequest['filters'];
export type OwnershipGroupTableRecordType = OwnershipGroupProperty;
export type OwnershipGroupTableFilters = ColumnFilters<Filters>;

const useColumns = <T extends OwnershipGroupTableRecordType>({
	filterValues
}: {
	filterValues: Filters | undefined;
}) => {
	const { search, filter, filters, sort, onChange } = useCreateColumns<
		T,
		Filters
	>({
		filterValues
	});

	const columns: Array<ColumnProps<T>> = [
		{
			...search,
			...sort,
			title: i18n.t('ownershipGroups.ownershipGroup.columns.property'),
			key: 'property',
			dataIndex: 'property',
			defaultSortOrder: 'ascend',
			filteredValue: filters.property
		},
		{
			...search,
			...sort,
			title: i18n.t('ownershipGroups.ownershipGroup.columns.tenant'),
			key: 'tenantName',
			dataIndex: 'tenantName',
			filteredValue: filters.tenantName
		},
		{
			...filter,
			...sort,
			title: i18n.t('ownershipGroups.ownershipGroup.columns.status'),
			key: 'status',
			dataIndex: 'status',
			filters: GROUP_ACTIVE_STATUS_LIST.map((value) => ({
				text: GROUP_ACTIVE_STATUS_LABEL_MAP[value](),
				value
			})),
			filteredValue: filters.status,
			render: (_, record) => (
				<Tag type={GROUP_ACTIVE_STATUS_TAG_MAP[record.status]}>
					{GROUP_ACTIVE_STATUS_LABEL_MAP[record.status]()}
				</Tag>
			)
		},
		{
			...filter,
			...sort,
			title: i18n.t('ownershipGroups.ownershipGroup.columns.deleted'),
			key: 'isDeleted',
			dataIndex: 'isDeleted',
			filters: [
				{
					text: DELETED_LABEL.YES(),
					value: true
				},
				{
					text: DELETED_LABEL.NO(),
					value: false
				}
			],
			render: (value) => (
				<Tag type="error">
					{value ? DELETED_LABEL.YES() : DELETED_LABEL.NO()}
				</Tag>
			),
			filteredValue: filters.isDeleted
		},
		{
			...filter,
			...sort,
			title: i18n.t('ownershipGroups.ownershipGroup.columns.archived'),
			key: 'isArchived',
			dataIndex: 'isArchived',
			filters: [
				{
					text: ARCHIVED_LABEL.YES(),
					value: true
				},
				{
					text: ARCHIVED_LABEL.NO(),
					value: false
				}
			],
			render: (value) => (
				<Tag type="error" ghost>
					{value ? ARCHIVED_LABEL.YES() : ARCHIVED_LABEL.NO()}
				</Tag>
			),
			filteredValue: filters.isArchived
		}
	];

	return {
		columns,
		onChange
	};
};

export default useColumns;
