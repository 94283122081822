import { useErrorBoundary } from 'Hooks';
import { useStores } from 'Hooks/useStore';
import { getAppRoutes } from 'Pages/App/App.constants';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ReportType, AddReportRequest } from 'Services/Api/Reports/Types';
import EditReport from './EditReport/EditReport';
import { useEditReport } from './EditReport/EditReport.hooks';

const REPORT_TYPES = Object.values(ReportType);

function AddReport() {
	const errorBoundary = useErrorBoundary();
	const params = useParams<{ type: ReportType }>();
	const { reportStore } = useStores();

	const reportType = REPORT_TYPES.includes(params.type)
		? params.type
		: undefined;
	const { initialValues } = useEditReport(
		reportType ? { reportType } : undefined
	);

	useEffect(() => {
		if (!reportType) {
			errorBoundary.setStatus('notFound');
		}
		// eslint-disable-next-line
	}, []);

	const submit = async (params: AddReportRequest) => {
		await reportStore.addReport(params);
	};

	if (!reportType || !initialValues) return null;

	return (
		<EditReport
			initialValues={initialValues}
			submit={submit}
			returnUrl={getAppRoutes().REPORTS}
		/>
	);
}

export default AddReport;
