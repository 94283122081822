import { MenuItem } from 'app-types';

export enum NavMenuKey {
	Dashboard = 'Dashboard',
	Users = 'Users',
	Groups = 'Groups',
	OwnershipGroups = 'OwnershipGroups',
	Roles = 'Roles',
	Reports = 'Reports',
	Modules = 'Modules',
	AuditLogReport = 'AuditLogReport',
	PeakPortal = 'PeakPortal'
}

export enum UserProfileMenuKey {
	Profile = 'Profile',
	Logout = 'Logout'
}

export type AppHeaderMenuItem = MenuItem<NavMenuKey, { suffix?: string }>;

export interface BlockNavigation {
	blockNavigation?: boolean;
}
