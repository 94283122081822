import { useFormContext } from 'react-hook-form';
import { memo } from 'react';
import { Table, Row, Fx, Pagination } from 'Components';
import useColumns from './columns';
import { AddUsersFormValues, PaginationParams } from './AddUsers.types';
import styles from './AddUsers.module.scss';
import usersStyles from '../Users.module.scss';
import { PAGE_SIZE_OPTIONS } from './AddUsers.constants';

interface AddUsersFormFieldsProps extends PaginationParams {
	validateCognitoLogin: () => void;
	onRowSelectionChange: (indexes: React.Key[]) => void;
	onPaginationChange: (page: number, pageSize?: number) => void;
	resetPhoneNumberField: (key: string) => void;
	selectedRowKeys: React.Key[];
	loading: boolean;
}

const AddUsersFormFields = ({
	validateCognitoLogin,
	onRowSelectionChange,
	onPaginationChange,
	resetPhoneNumberField,
	currentPage,
	pageSize,
	selectedRowKeys,
	loading
}: AddUsersFormFieldsProps) => {
	const { getValues } = useFormContext<AddUsersFormValues>();
	const { data, users } = getValues();

	const columns = useColumns({
		...data,
		validateCognitoLogin,
		resetPhoneNumberField
	});

	const dataSource = users.slice(
		(currentPage - 1) * pageSize,
		pageSize * currentPage
	);

	return (
		<>
			<form className={styles.form}>
				<Table
					className={styles.addMultipleUsersTable}
					columns={columns}
					pagination={false}
					dataSource={dataSource}
					loading={loading}
					rowKey={(user) => `${user.key}_${user.fieldId}`}
					rowSelection={{
						onChange: onRowSelectionChange,
						selectedRowKeys
					}}
				/>
			</form>
			<Row className={styles.paginationRow}>
				<Fx fullWidth>
					<Pagination
						total={users.length}
						pageSize={pageSize}
						current={currentPage}
						pageSizeOptions={PAGE_SIZE_OPTIONS}
						onChange={onPaginationChange}
						className={usersStyles.pagination}
					/>
				</Fx>
			</Row>
		</>
	);
};

export default memo(AddUsersFormFields);
