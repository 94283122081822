import { Layout } from 'Components';
import { Form } from 'formik';
import Filters from 'Pages/Report/EditReport/Filters/Filters';
import General from 'Pages/Report/EditReport/General';
import TimePeriod from 'Pages/Report/EditReport/TimePeriod/TimePeriod';

function DownloadReportForm() {
	return (
		<Form>
			<Layout.List>
				<General />
				<TimePeriod />
				<Filters />
			</Layout.List>
		</Form>
	);
}

export default DownloadReportForm;
