import { Checkbox, DatePicker, DatePickerSelect, Layout } from 'Components';
import { useFormikContext } from 'formik';
import { EditUserValues } from 'Pages/User/User.types';
import { useTranslation } from 'react-i18next';
import styles from '../Profile.module.scss';
import NotificationsDisabledError from './NotificationsDisabledError';

function Notifications() {
	const { t } = useTranslation();
	const { values } = useFormikContext<EditUserValues>();
	const disabled = !values.timezoneId;

	const content = (
		<Layout.List.Section title={t('profile.notifications.title')}>
			{disabled && <NotificationsDisabledError />}
			<div className={styles.notifications}>
				<DatePickerSelect.FormikField
					label={t('profile.notifications.fields.weekday')}
					name="notifications.day"
					picker="weekday"
					disabled={disabled}
					required
				/>
				<DatePicker.FormikField
					label={t('profile.notifications.fields.time')}
					name="notifications.time"
					picker="time"
					fullWidth
					required
					minuteStep={15}
					disabled={disabled}
					allowClear={false}
				/>

				<h3>{t('profile.notifications.generalMails')}</h3>
				<Checkbox.FormikField
					name="notifications.getWeeklyReport"
					disabled={disabled}
				>
					{t('profile.notifications.fields.getWeeklyReport')}
				</Checkbox.FormikField>
				<hr />

				<h3>{t('profile.notifications.manageUsersAndGroups')}</h3>
				<Checkbox.FormikField
					name="notifications.getDeletionReport"
					disabled={disabled}
				>
					{t('profile.notifications.fields.getDeletionReport')}
				</Checkbox.FormikField>
			</div>
		</Layout.List.Section>
	);
	return (
		<Layout.List fullHeight>
			<Layout.List.ListItem>{content}</Layout.List.ListItem>
		</Layout.List>
	);
}

export default Notifications;
