import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import { getIsFailure } from 'Stores/util';
import { getAppRoutes } from './App.constants';
import { useStores } from 'Hooks/useStore';
import { ErrorScreen, Spin } from 'Components';
import Styleguide from 'Pages/Styleguide/Styleguide';
import Provider from './Provider';
import IdleLogout from 'Pages/Auth/IdleLogout/IdleLogout';
import StatusLogout from 'Pages/Auth/StatusLogout/StatusLogout';
import PrivateRoute from './PrivateRoute';
import Dashboard from 'Pages/Dashboard/Dashboard';
import Login from 'Pages/Auth/Login/Login';
import ResetPassword from 'Pages/Auth/ResetPassword/ResetPassword';
import Logout from 'Pages/Auth/Logout/Logout';
import Users from 'Pages/Users/Users';
import CreateUser from 'Pages/User/CreateUser/CreateUser';
import EditUser from 'Pages/User/EditUser/EditUser';
import Profile from 'Pages/User/Profile/Profile';
import ValidateEmail from 'Pages/ValidateEmail/ValidateEmail';
import {
	TwoStepAuthentication,
	TwoStepRegistration
} from 'Pages/Auth/TwoStepAuthentication';
import {
	PermissionCode,
	DynamicPermissionCode
} from 'Services/Api/Users/Types';
import Groups from 'Pages/Groups/Groups';
import { observer } from 'mobx-react-lite';
import AddGroup from 'Pages/Group/AddGroup/AddGroup';
import EditGroup from 'Pages/Group/EditGroup/EditGroup';
import DeletedGroups from 'Pages/DeletedGroups/DeletedGroups';
import Roles from 'Pages/Roles/Roles';
import AddRole from 'Pages/Role/AddRole/AddRole';
import EditRole from 'Pages/Role/EditRole/EditRole';
import AddUsersRoute from 'Pages/Users/AddUsers/AddUsersRoute';
import OwnershipGroups from 'Pages/OwnershipGroups/OwnershipGroups';
import AddOwnershipGroup from 'Pages/OwnershipGroup/AddOwnershipGroup/AddOwnershipGroup';
import EditOwnershipGroup from 'Pages/OwnershipGroup/EditOwnershipGroup/EditOwnershipGroup';
import Reports from 'Pages/Reports/Reports';
import AuditLog from 'Pages/AuditLog/AuditLog';
import GroupsHierarchy from 'Pages/GroupsHierarchy/GroupsHierarchy';
import DownloadReport from 'Pages/DownloadReport/DownloadReport';
import ResetTemporaryPassword from 'Pages/Auth/Login/ResetTemporaryPassword';
import AddReport from 'Pages/Report/AddReport';
import EditReport from 'Pages/Report/EditReport';

const App = () => {
	const { authStore } = useStores();

	// allows to identify user pool before app will start
	useEffect(() => {
		authStore.getUsersPools(window.location.href);
		// eslint-disable-next-line
	}, []);
	if (getIsFailure(authStore.userPoolsStatus))
		return <ErrorScreen.InternalError />;
	if (!authStore.isUsersPoolDefined) return <Spin />;

	const appRoutes = getAppRoutes();

	return (
		<Suspense fallback={<Spin />}>
			<Provider>
				<BrowserRouter>
					<Switch>
						<Route
							exact
							path={'/styleguide'}
							component={Styleguide}
						/>
						<Route
							exact
							path={appRoutes.LOGOUT}
							component={Logout}
						/>
						<Route exact path={appRoutes.LOGIN} component={Login} />
						<Route
							exact
							path={appRoutes.FIRST_LOGIN}
							component={() => <Login firstTimeSignIn />}
						/>
						<Route
							exact
							path={appRoutes.RESET_PASSWORD}
							component={ResetPassword}
						/>
						<Route
							exact
							path={appRoutes.RESET_TEMPORARY_PASSWORD}
							component={ResetTemporaryPassword}
						/>

						<Route
							exact
							path={appRoutes.IDLE_LOGOUT}
							component={IdleLogout}
						/>

						<Route
							exact
							path={appRoutes.STATUS_LOGOUT}
							component={StatusLogout}
						/>
						<Route
							exact
							path={appRoutes.DOWNLOAD_REPORT}
							component={DownloadReport}
						/>
						<PrivateRoute
							exact
							path={appRoutes.PROFILE}
							component={Profile}
						/>

						<PrivateRoute
							exact
							path={appRoutes.HOME}
							component={Dashboard}
						/>
						<PrivateRoute
							exact
							path={appRoutes.USERS}
							component={Users}
							permission={{
								dynamicCode: DynamicPermissionCode.ViewUsers
							}}
						/>
						<PrivateRoute
							exact
							path={appRoutes.ADD_USER}
							component={CreateUser}
							permission={{
								dynamicCode: DynamicPermissionCode.AddUser
							}}
						/>
						<PrivateRoute
							exact
							path={appRoutes.ADD_USERS}
							component={AddUsersRoute}
							permission={{
								dynamicCode: DynamicPermissionCode.AddUser
							}}
						/>
						<PrivateRoute
							path={appRoutes.USER}
							component={EditUser}
							permission={{
								dynamicCode: DynamicPermissionCode.EditUser
							}}
						/>

						<PrivateRoute
							exact
							path={appRoutes.GROUPS}
							component={Groups}
							permission={{
								code: PermissionCode.ViewGroups
							}}
						/>

						<PrivateRoute
							exact
							path={appRoutes.GROUP_HIERARCHY}
							component={GroupsHierarchy}
							permission={{
								code: PermissionCode.ViewGroups
							}}
						/>

						<PrivateRoute
							exact
							path={appRoutes.GROUPS_DELETED}
							component={DeletedGroups}
							permission={{
								code: PermissionCode.ViewGroups
							}}
						/>

						<PrivateRoute
							exact
							path={appRoutes.OWNERSHIP_GROUPS}
							component={OwnershipGroups}
							permission={{
								code: PermissionCode.ViewOwnershipGroups
							}}
						/>

						<PrivateRoute
							exact
							path={appRoutes.ADD_GROUP}
							component={AddGroup}
							permission={{
								code: [
									PermissionCode.AddGroup,
									PermissionCode.AddTenant
								]
							}}
						/>

						<PrivateRoute
							exact
							path={appRoutes.EDIT_GROUP}
							component={EditGroup}
							permission={{
								code: PermissionCode.EditGroup
							}}
						/>

						<PrivateRoute
							exact
							path={appRoutes.EDIT_TENANT}
							component={EditGroup}
							permission={{
								code: PermissionCode.EditTenant
							}}
						/>

						<PrivateRoute
							exact
							path={appRoutes.ADD_OWNERSHIP_GROUP}
							component={AddOwnershipGroup}
							permission={{
								code: PermissionCode.AddOwnershipGroup
							}}
						/>

						<PrivateRoute
							exact
							path={appRoutes.EDIT_OWNERSHIP_GROUP}
							component={EditOwnershipGroup}
							permission={{
								code: PermissionCode.EditOwnershipGroup
							}}
						/>

						<PrivateRoute
							exact
							path={appRoutes.ROLES}
							component={Roles}
							permission={{
								code: PermissionCode.ViewRoles
							}}
						/>

						<PrivateRoute
							exact
							path={appRoutes.ADD_ROLE}
							component={AddRole}
							permission={{
								code: PermissionCode.AddSystemRole
							}}
						/>

						<PrivateRoute
							exact
							path={appRoutes.EDIT_ROLE}
							component={EditRole}
							permission={{
								code: PermissionCode.EditSystemRole
							}}
						/>

						<PrivateRoute
							exact
							path={[appRoutes.REPORTS, appRoutes.SAVED_REPORTS]}
							component={Reports}
						/>

						<PrivateRoute
							exact
							path={appRoutes.ADD_REPORT}
							component={AddReport}
							permission={{
								code: PermissionCode.AddReport
							}}
						/>

						<PrivateRoute
							exact
							path={appRoutes.EDIT_REPORT}
							component={EditReport}
							permission={{
								code: PermissionCode.AddReport
							}}
						/>

						<PrivateRoute
							exact
							path={appRoutes.AUDIT_LOG}
							component={AuditLog}
							permission={{
								code: PermissionCode.ViewAuditLog
							}}
						/>
						<Route
							exact
							path={appRoutes.VALIDATE_EMAIL}
							component={ValidateEmail}
						/>
						<Route
							exact
							path={appRoutes.ERROR_PERMISSION}
							component={ErrorScreen.ErrorPermission}
						/>
						<Route
							exact
							path={appRoutes.TOKEN_EXPIRED}
							component={ErrorScreen.ErrorTokenExpired}
						/>
						<Route
							exact
							path={appRoutes.TWO_STEP_REGISTRATION}
							component={TwoStepRegistration}
						/>
						<Route
							exact
							path={appRoutes.TWO_STEP_AUTHENTICATION}
							component={TwoStepAuthentication}
						/>
						<Route component={ErrorScreen.NotFound} />
					</Switch>
				</BrowserRouter>
			</Provider>
		</Suspense>
	);
};

export default observer(App);
