import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FieldArray } from 'formik';
import { UploadProps } from 'antd';
import { Button, UploadFile, Tooltip } from 'Components';
import { useGroupsHierarchyContext } from '../GroupsHierarchyContext';
import { Level } from '../components';
import { LevelItem, Select } from './components';
import { ValueXLSX, ValuesXLSX } from '../GroupsHierarchy.types';
import styles from './GroupsHierarchyXLSX.module.scss';

interface GroupsHierarchyXLSXProps {
	values: ValuesXLSX;
	isValid: boolean;
	setLevels: React.Dispatch<React.SetStateAction<ValueXLSX[]>>;
	handleFileUpload: UploadProps['onChange'];
	fileName: string;
}
function calcCurrentLevel(parentLevel: number, index: number): number {
	return parentLevel + index + 1;
}
const GroupsHierarchyXLSX = ({
	values,
	isValid,
	setLevels,
	handleFileUpload,
	fileName
}: GroupsHierarchyXLSXProps) => {
	const [levelWithProperty, setLevelWithProperty] = useState<string | null>(
		null
	);
	const {
		parentLevel,
		parent,
		levelNumberXLSX
	} = useGroupsHierarchyContext();
	const { t } = useTranslation();
	// useEffect needs for reset level with the property if a user changed levels amount
	// and the previous checked level don't include in current levels list
	useEffect(() => {
		if (levelWithProperty && levelNumberXLSX) {
			const [level] = levelWithProperty.slice(-1);

			if (
				calcCurrentLevel(parentLevel, Number(level)) > levelNumberXLSX
			) {
				setLevelWithProperty(null);
			}
		}
	}, [levelNumberXLSX, levelWithProperty, parentLevel]);

	const handleLevelWithProperty = useCallback(
		(value: boolean, levelName: string) => {
			setLevelWithProperty(value ? levelName : null);
		},
		[]
	);

	const isDisabled = !isValid || !values.levels.length;
	return (
		<>
			<Form>
				<Select setLevels={setLevels} values={values} />
				<Level levelNumber={parentLevel} parentName={parent?.name} />
				<FieldArray name={'levels'}>
					{() => {
						return values?.levels && values.levels.length
							? values.levels.map((_, i) => {
									const levelNumber = calcCurrentLevel(
										parentLevel,
										i
									);
									const name = `levels.${i}`;
									const isPropertyLevelDisabled = Boolean(
										levelWithProperty &&
											levelWithProperty !== name
									);

									return (
										<LevelItem
											key={levelNumber}
											name={name}
											levelNumber={levelNumber}
											setLevelWithProperty={
												handleLevelWithProperty
											}
											isPropertyLevelDisabled={
												isPropertyLevelDisabled
											}
										/>
									);
							  })
							: null;
					}}
				</FieldArray>

				<Tooltip
					enabled={isDisabled}
					trigger="hover"
					title={t(
						`groupsHierarchy.formXLSX.tooltip.${
							!isValid ? 'invalid' : 'selectLevel'
						}`
					)}
				>
					<Button
						type="text"
						link
						className={styles.downloadButton}
						htmlType="submit"
						disabled={isDisabled}
					>
						{t(`downloadXLSXTemplate`)}
					</Button>
				</Tooltip>
			</Form>

			<div className={styles.uploadContainer}>
				<h3 className={styles.title}>{t('uploadFile')}</h3>
				<div className={styles.uploadFileWrap}>
					<UploadFile
						disabled={false}
						value={fileName}
						onChange={handleFileUpload}
						accept=".xlsx"
					/>
				</div>
			</div>
		</>
	);
};

export default GroupsHierarchyXLSX;
