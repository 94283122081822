import { Formik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';

import { useModal } from 'Hooks/useModal';
import { useEditReport } from 'Pages/Report/EditReport/EditReport.hooks';
import { ReportFormValues, schema } from 'Pages/Report/EditReport/schema';
import { ReportType } from 'Services/Api/Reports/Types';

import { Modal } from 'Components';
import DownloadReportForm from './DownloadReportForm';
import DownloadReportButton from 'Pages/Report/EditReport/EditReportDownloadButton';

export interface DownloadReportModalProps {
	reportType: ReportType;
}
function DownloadReportModal(props: DownloadReportModalProps) {
	const { t } = useTranslation();
	const { modal, hideModal } = useModal();
	const { initialValues } = useEditReport({
		reportType: props.reportType
	});

	if (!modal || !initialValues) return null;

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={noop}
			validationSchema={schema}
			validateOnMount
		>
			<Modal
				visible
				title={t('dashboard.downloadReport')}
				onCancel={hideModal}
				maskClosable={false}
				okText={t('download')}
				okButton={<DownloadReportSubmit />}
				size="sm"
			>
				<DownloadReportForm />
			</Modal>
		</Formik>
	);
}

const DownloadReportSubmit = () => {
	const { hideModal } = useModal();
	const {
		values,
		submitCount,
		dirty,
		isValid
	} = useFormikContext<ReportFormValues>();

	return (
		<DownloadReportButton
			params={values}
			disabled={!((submitCount === 0 || dirty) && isValid)}
			onClick={hideModal}
		/>
	);
};

export default DownloadReportModal;
