import MockAdapter from 'axios-mock-adapter';
import { axiosClient } from './client';
import mockCommon from './Common/Mock';
import mockReports from './Reports/Mock';

const mockAxios = () => {
	const mock = new MockAdapter(axiosClient, { delayResponse: 1000 });

	mockReports(mock);
	mockCommon(mock);

	mock.onAny().passThrough();
};

export default mockAxios;
