export const assistanceComponents = {
	email: (
		<a
			target="_blank"
			rel="noreferrer"
			href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS}`}
		>
			&nbsp;
		</a>
	),
	emailAddress: process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS
};
