import { ColumnProps } from 'antd/lib/table';
import { GROUP_TABLE_COLUMN_PROPS } from 'Pages/Groups/Group/columns';
import { Group } from 'Services/Api/Groups/Types';

export const useGroupColumns = (): Array<ColumnProps<Group>> => {
	const colProps = GROUP_TABLE_COLUMN_PROPS();
	return [
		colProps.name,
		colProps.isProperty,
		colProps.syncCode,
		colProps.active
	];
};
