import { Col, Fx, Row, Stack } from 'Components';
import ActiveCampaigns from './ActiveCampaigns/ActiveCampaigns';
import CampaignsSummary from './CampaignsSummary/CampaignsSummary';
import GroupSummary from './GroupSummary/GroupSummary';
import TotalCampaignOverview from './TotalCampaignOverview';
import TotalCampaignScore from './TotalCampaignScore';

const Phishing = () => {
	return (
		<Stack>
			<Row gutter={24}>
				<Col span={12}>
					<Fx fullHeight>
						<TotalCampaignOverview />
					</Fx>
				</Col>
				<Col span={12}>
					<Fx fullHeight>
						<TotalCampaignScore />
					</Fx>
				</Col>
			</Row>
			<ActiveCampaigns />
			<CampaignsSummary />
			<GroupSummary />
		</Stack>
	);
};

export default Phishing;
