import { Spin } from 'Components';
import UserContextProvider from 'Pages/User/UserContext';
import { DynamicPermissionCode } from 'Services/Api/Users/Types';
import AddUsers from './AddUsers';

function AddUsersRoute() {
	return (
		<UserContextProvider permissionCode={DynamicPermissionCode.AddUser}>
			{({ loading }) => (loading ? <Spin /> : <AddUsers />)}
		</UserContextProvider>
	);
}

export default AddUsersRoute;
