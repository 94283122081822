import { v4 as uuid } from 'uuid';

export const MANAGE_ROW_KEY = uuid();

export const DEFAULT_GROUP_ABBREVIATION = 'Group';

export const FIELD_ARRAY_KEY_NAME = 'fieldId';

export const DELAY_TIME = 2200;

export const WS_NAME = 'groups hierarchy';

export const LEVEL = 'Level';

export const LEVEL_COLUMN_NAME = LEVEL.toUpperCase();

export const IS_PROPERTY_COLUMN_NAME = 'IS_PROPERTY';

export const XLSX_TEMPLATE_NAME = 'XLSX_groups_hierarchy_template';

export const COLUMNS_TITLES_CELLS = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1'];

export const SHEETS_NAMES = ['A', 'B', 'C', 'D', 'E', 'F'];

export const [COLUMN_A] = SHEETS_NAMES;

export const COLUMNS_NAMES = [
	IS_PROPERTY_COLUMN_NAME,
	'LEVEL2',
	'LEVEL3',
	'LEVEL4',
	'LEVEL5',
	'LEVEL6'
];

export const IS_PROPERTY_SIGN = 'X';
