import { Translation } from 'react-i18next';
import moment from 'moment';
import {
	PreviewReportTypeListItemBase,
	ReportDisplayResultsBy
} from 'Services/Api/Reports/Types';
import { PreviewReportProps } from '../PreviewReport.types';
import { REPORT_DISPLAY_RESULTS_BY_MAP } from 'Pages/Report/EditReport/EditReport.constants';
import { APP_CONFIG } from 'app-config';
import { COLUMNS, COLUMNS_WITH_DATA } from './PreviewReportTable.constants';

export const usePreviewReportTable = (props: PreviewReportProps) => {
	const data = props.data;
	const reportType = data.reportType;

	const colProps =
		COLUMNS[reportType]?.() ?? COLUMNS_WITH_DATA[reportType]?.(data as any); // eslint-disable-line

	if (!colProps) {
		return {
			columns: null
		};
	}

	const { displayResultsBy } = data;

	const displayResultByConfig = displayResultsBy
		? REPORT_DISPLAY_RESULTS_BY_MAP[displayResultsBy]
		: null;

	const columnsAddons = displayResultsBy
		? [
				{
					title: displayResultByConfig ? (
						<Translation>
							{(t) => t(displayResultByConfig.label)}
						</Translation>
					) : null,
					key: 'groupperiod',
					dataIndex: 'groupperiod',
					width: 120,
					render: (
						_: PreviewReportTypeListItemBase,
						record: PreviewReportTypeListItemBase
					) => {
						const { groupperiod } = record;
						const d = moment.utc(groupperiod);
						const year = d.year();
						switch (displayResultsBy) {
							case ReportDisplayResultsBy.Month:
								return `${year} ${d.format(
									APP_CONFIG.MONTH_FORMAT
								)}`;
							case ReportDisplayResultsBy.Quarters:
								return `${year} ${d.format(
									APP_CONFIG.QUARTER_FORMAT
								)}`;
							case ReportDisplayResultsBy.Year:
								return year;
							default:
								return '';
						}
					}
				}
		  ]
		: [];

	const columns = [
		...columnsAddons,
		...colProps.map((col) => ({
			...col,
			filters: undefined,
			sorter: false
		}))
	];

	return {
		columns
	};
};
