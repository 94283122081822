import { Input, Modal, Notification } from 'Components';
import TInfoText from 'Contexts/TInfoText/TInfoText';
import { Form, Formik, FormikHelpers } from 'formik';
import { useModal } from 'Hooks/useModal';
import { useStores } from 'Hooks/useStore';
import { useTranslation } from 'react-i18next';
import {
	ReportModalAction,
	ReportModalsPropsBase,
	ReportModalValue
} from './ReportModals.types';
import { schema } from 'Pages/Report/EditReport/schema';
import { AxiosErrorResponse } from 'app-types';
import { getFormErrors } from 'Helpers/api';
import { CloneReportRequest } from 'Services/Api/Reports/Types';

const cloneSchema = schema.pick(['name']);

const PATH = 'reports.tabs.saved.cloneReportModal';

function CloneReportModal(props: ReportModalsPropsBase) {
	const { t } = useTranslation();
	const { modal, hideModal } = useModal();
	const { reportStore } = useStores();

	const modalValue = modal as ReportModalValue;

	if (!modalValue || modalValue.name !== ReportModalAction.Clone) {
		return null;
	}

	const onSubmit = async (
		values: CloneReportRequest,
		helpers: FormikHelpers<CloneReportRequest>
	) => {
		try {
			await reportStore.cloneReport(values);
			hideModal();
			props.refresh();
			Notification.success({
				description: t(`${PATH}.successNotification`)
			});
		} catch (e) {
			const error = e as AxiosErrorResponse;
			const errors = getFormErrors(
				error.response?.data.error,
				t,
				cloneSchema.describe().fields
			);
			helpers.setErrors(errors);
		}
	};

	const initialValues = {
		id: modalValue.value.id,
		name: ''
	};

	return (
		<Formik
			onSubmit={onSubmit}
			initialValues={initialValues}
			validationSchema={cloneSchema}
		>
			{({ submitForm, isSubmitting, isValid, dirty }) => (
				<Modal
					visible
					title={
						<TInfoText
							path={`${PATH}.title`}
							params={{ name: modalValue.value.name }}
						/>
					}
					okText={t('clone')}
					okButtonProps={{
						disabled: !(isValid && dirty),
						loading: isSubmitting
					}}
					onOk={submitForm}
					onCancel={hideModal}
				>
					<Form>
						<Input.FormikField
							name="name"
							label={t(`report.fields.name`)}
							required
							marginBottom={false}
							autoFocus
						/>
					</Form>
				</Modal>
			)}
		</Formik>
	);
}

export default CloneReportModal;
