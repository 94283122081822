import { isNumber } from 'lodash';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './Table.module.scss';

interface TotalCountProps {
	count: number | undefined;
	className?: string;
}

const TotalCount = ({ className, count }: TotalCountProps) => {
	const { t } = useTranslation();
	return isNumber(count) ? (
		<div className={cn('ant-table-footer', styles.totalItems, className)}>
			{t(
				`Components.table.totalItems.${
					count === 1 ? 'single' : 'multiple'
				}`,
				{
					amount: count
				}
			)}
		</div>
	) : null;
};

export default TotalCount;
