import GroupsField, {
	GroupsFieldValue
} from 'Pages/User/components/GroupsField/GroupsField';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import styles from './Copier.module.scss';
import AllGroupsField from 'Pages/User/components/AllGroupsField';
import { AddUsersCopyValues } from '../../AddUsers.types';
import { SystemRoleType } from 'Services/Api/Roles/Types';

const GroupField = () => {
	const { t } = useTranslation();
	const { setFieldValue, values } = useFormikContext<AddUsersCopyValues>();
	const { primarySystemRoleId } = values;

	if (primarySystemRoleId?.value === SystemRoleType.SuperAdmin) {
		return <AllGroupsField className={styles.selectLong} />;
	}

	const onSubmit = (value: GroupsFieldValue) => {
		setFieldValue('groups', value);
	};

	return (
		<GroupsField
			onSubmit={onSubmit}
			label={t('user.group')}
			value={values?.groups}
			error={undefined}
			required={false}
			className={styles.selectLong}
		/>
	);
};

export default GroupField;
