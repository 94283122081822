import { Card, Fx, Icon } from 'Components';
import { CardProps } from 'Components/Card/Card';
import { useTranslation } from 'react-i18next';
import { ReportType } from 'Services/Api/Reports/Types';
import { STANDARD_REPORT_TYPE_MAP } from './Standard.constants';
import styles from './Standard.module.scss';

type StandardReportProps = Omit<CardProps, 'onClick'> & {
	reportType: ReportType;
	onClick?: (reportType: ReportType) => void;
};
function StandardReport({
	reportType,
	onClick,
	...props
}: StandardReportProps) {
	const { t } = useTranslation();
	const handleClick = onClick ? () => onClick(reportType) : undefined;

	const info = STANDARD_REPORT_TYPE_MAP[reportType];

	return (
		<Card
			{...props}
			onClick={handleClick}
			className={styles.card}
			contentClassName={styles.cardContent}
			hoverable={Boolean(handleClick)}
		>
			<Fx align="middle" fullHeight>
				<Fx
					shrink={0}
					align="middle"
					justify="center"
					className={styles.icon}
				>
					<Icon type={info.icon} />
				</Fx>
				<div>
					<h3>{info.label(t)}</h3>
					{info.desc(t)}
				</div>
			</Fx>
		</Card>
	);
}

export default StandardReport;
