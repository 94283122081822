import { Fx, Icon, Card, Divider, Row, Col } from 'Components';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import {
	InfoTooltip,
	OpenInNewTab,
	Statistic
} from 'Pages/Dashboard/components';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import PieChart from 'Pages/Dashboard/visualizations/PieChart/PieChart';
import VisualizationLegend from 'Pages/Dashboard/visualizations/VisualizationLegend/VisualizationLegend';
import { VISUALIZATION_COLOR } from 'Pages/Dashboard/visualizations/Visualizations.constants';
import { VisualizationTimePeriod } from 'Pages/Dashboard/visualizations/Visualizations.types';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getIsPending } from 'Stores/util';
import styles from './Elearning.module.scss';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';
import { ElearningWidgets } from './Elearning.types';

const PATH = 'dashboard.tabs.elearning.courseActivity';

const CourseActivity = () => {
	const { t } = useTranslation();
	const { filters } = useDashboardContext();
	const { dashboardStore } = useStores();
	const {
		elearning: { courseActivity },
		status
	} = dashboardStore;

	const chartData = useMemo(() => {
		if (!courseActivity) {
			return [];
		}
		return [
			{
				timePeriod: VisualizationTimePeriod.Current,
				x: t(`${PATH}.legend.certified`),
				y: courseActivity.lastYearResults.certified
			},
			{
				timePeriod: VisualizationTimePeriod.Previous,
				x: t(`${PATH}.legend.selfTraining`),
				y: courseActivity.lastYearResults.selfTraining
			}
		];
		// eslint-disable-next-line
	}, [courseActivity]);

	const chartDataLegend = useMemo(() => {
		if (!courseActivity) {
			return [];
		}
		return [
			{
				label: t(`${PATH}.legend.total`),
				value: courseActivity.lastYearResults.totalCompleted
			},
			...chartData.map((d) => ({
				label: d.x,
				value: d.y,
				color: VISUALIZATION_COLOR[d.timePeriod]
			}))
		];
	}, [t, chartData, courseActivity]);

	useEffect(() => {
		dashboardStore.getElearningCourseActivity(filters);
		// eslint-disable-next-line
	}, [filters]);

	return (
		<Card
			loading={getIsPending(status.getElearningCourseActivity)}
			title={t('dashboard.tabs.elearning.courseActivity.title')}
			extra={
				<Fx align="middle">
					<InfoTooltip
						title={t('dashboard.tabs.overview.eLearning.tooltip')}
					/>
				</Fx>
			}
			footer={
				<>
					<div className={styles.courseActivityStats}>
						<Row gutter={24}>
							<Col span={10}>
								<Statistic
									inheritColor
									icon={<Icon type="reward" />}
									value={
										courseActivity?.last90DaysResults
											.certified
									}
									type="happy"
								>
									{t(
										'dashboard.tabs.elearning.courseActivity.certifiedLast90Days'
									)}
								</Statistic>
							</Col>
							<Col>
								<Statistic
									inheritColor
									icon={<Icon type="eye" />}
									value={
										courseActivity?.last90DaysResults
											.selfTraining
									}
								>
									{t(
										'dashboard.tabs.elearning.courseActivity.takenLast90Days'
									)}
								</Statistic>
							</Col>
						</Row>
					</div>
					<Divider />
					<OpenInNewTab
						tab={DashboardTabKey.Elearning}
						widget={ElearningWidgets.CourseCertificationActivity}
					/>
				</>
			}
		>
			<Row gutter={24}>
				<Col>
					<Fx justify="center">
						<PieChart data={chartData} loading={false} />
					</Fx>
				</Col>
				<Col>
					<Fx align="middle" fullHeight>
						{courseActivity && (
							<VisualizationLegend
								list={chartDataLegend}
								vertical
							/>
						)}
					</Fx>
				</Col>
			</Row>
		</Card>
	);
};

export default observer(CourseActivity);
