import { isArray, zipObject } from 'lodash';
import { PermissionCode } from 'Services/Api/Users/Types';
import { PermissionProps } from './Permission';

export const getDynamicCode = (code: string, role?: string) => {
	const prefix = `${code}__`;
	return role ? `${prefix}${role}` : prefix;
};

export const hasPermission = ({
	permission: { every, code: permissionCode, dynamicCode, dynamicCodeRole },
	currentPermissionCodes
}: {
	permission: Omit<PermissionProps, 'children'>;
	currentPermissionCodes: PermissionCode[];
}) => {
	let codes: string[] = [];
	const dynamicCodes: string[] = [];

	if (permissionCode) {
		codes = isArray(permissionCode) ? permissionCode : [permissionCode];
	}

	if (dynamicCode) {
		const list = isArray(dynamicCode) ? dynamicCode : [dynamicCode];

		if (dynamicCodeRole) {
			codes.push(
				...list.map((code) => getDynamicCode(code, dynamicCodeRole))
			);
		} else {
			dynamicCodes.push(...list);
		}
	}

	let byCode: Record<string, boolean> = zipObject(
		codes,
		codes.map((code) =>
			currentPermissionCodes.includes(code as PermissionCode)
		)
	);

	if (dynamicCodes.length) {
		byCode = {
			...byCode,
			...zipObject(
				dynamicCodes,
				dynamicCodes.map(
					(value) =>
						currentPermissionCodes
							.join(',')
							// match value at the beginning of the string or after comma
							.match(
								new RegExp(
									`^${getDynamicCode(
										value
									)}|,${getDynamicCode(value)}`
								)
							) !== null
				)
			)
		};
	}

	const permittedByCodeValues = Object.values(byCode);
	return {
		permitted: every
			? permittedByCodeValues.every((value) => value)
			: permittedByCodeValues.some((value) => value),
		permittedByCode: byCode
	};
};
