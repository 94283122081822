import { createContext, FC, useContext } from 'react';
import { isFunction } from 'lodash';
import { QueryResult, useQuery } from '@apollo/client';
import {
	DynamicPermissionCode,
	GetLanguagesList,
	GetTimezonesList
} from 'Services/Api/Users/Types';
import { GetSystemRolesList } from 'Services/Api/Roles/Types';
import { GET_LANGUAGES, GET_TIMEZONES } from 'Services/Api/Users/Queries';
import { GET_SYSTEM_ROLES_LIST } from 'Services/Api/Roles/Queries';

interface UserContextValue {
	languages: QueryResult<GetLanguagesList>;
	languagesList: GetLanguagesList['getLanguagesList'] | undefined;
	timezones: QueryResult<GetTimezonesList>;
	timezonesList: GetTimezonesList['getTimezonesList'] | undefined;
	systemRoles: QueryResult<GetSystemRolesList>;
	systemRolesList: GetSystemRolesList['getSystemRolesList'] | undefined;
}

interface UserContextProviderProps {
	permissionCode: DynamicPermissionCode;
	children:
		| React.ReactNode
		| ((helpers: { loading: boolean }) => React.ReactNode);
}

export const UserContext = createContext<UserContextValue>(
	{} as UserContextValue
);

const UserContextProvider: FC<UserContextProviderProps> = ({
	permissionCode,
	children
}) => {
	const languages = useQuery<GetLanguagesList>(GET_LANGUAGES, {
		fetchPolicy: 'cache-first'
	});
	const timezones = useQuery<GetTimezonesList>(GET_TIMEZONES, {
		fetchPolicy: 'cache-first'
	});
	const systemRoles = useQuery<GetSystemRolesList>(GET_SYSTEM_ROLES_LIST, {
		variables: {
			action: permissionCode
		}
	});

	const loading =
		!languages.called ||
		languages.loading ||
		!timezones.called ||
		timezones.loading ||
		!systemRoles.called ||
		systemRoles.loading;

	return (
		<UserContext.Provider
			value={{
				languages,
				languagesList: languages.data?.getLanguagesList,
				timezones,
				timezonesList: timezones.data?.getTimezonesList,
				systemRoles,
				systemRolesList: systemRoles.data?.getSystemRolesList
			}}
		>
			{isFunction(children) ? children({ loading }) : children}
		</UserContext.Provider>
	);
};

export const useUserContext = () => useContext(UserContext);

export default UserContextProvider;
