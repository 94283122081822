import { isFunction } from 'lodash';
import React from 'react';
import { useDataTree } from './DataTree.hooks';
import { DataTreeContextValue, DataTreeContextProps } from './DataTree.types';

const DataTreeContext = React.createContext<DataTreeContextValue>(
	// eslint-disable-next-line
	{} as any
);
export const useDataTreeContext = () => React.useContext(DataTreeContext);

// eslint-disable-next-line
export const DataTreeContextProvider = <T extends Record<any, any>>(
	props: DataTreeContextProps<T>
) => {
	const value = useDataTree<T>(props);
	return (
		<DataTreeContext.Provider value={value}>
			{isFunction(props.children)
				? props.children(value)
				: props.children}
		</DataTreeContext.Provider>
	);
};
