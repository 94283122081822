import { Notification } from 'Components';
import i18n from 'i18n';

export const notifyUsersResetPasswordSuccess = (count: number) => {
	if (count === 0) {
		Notification.error({
			description: i18n.t(
				`users.components.resetUserPasswordsModal.notification.errorEmpty`
			)
		});
		return;
	}

	Notification.success({
		description: i18n.t(
			`users.components.resetUserPasswordsModal.notification.success`,
			{
				totalCount: count
			}
		)
	});
};
