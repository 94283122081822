import { Redirect, Route, RouteProps } from 'react-router-dom';

import { getAppRoutes } from './App.constants';
import PrivateApp, { PrivateAppPropsBase } from './PrivateApp';
import Permission, { PermissionProps } from 'Components/Permission/Permission';

type PrivateRouteProps = RouteProps &
  PrivateAppPropsBase & {
		// eslint-disable-next-line
		component: any;
		permission?: Omit<PermissionProps, 'children'>;
	};

function PrivateRoute({
	component: Component,
	permission,
	includeLayout,
	...routeProps
}: PrivateRouteProps) {
	return (
		<PrivateApp includeLayout={includeLayout}>
			<Route
				{...routeProps}
				render={(props) => {
					const children = <Component {...props} />;
					return permission ? (
						<Permission {...(permission as PermissionProps)}>
							{({ permitted }) => {
								return permitted ? (
									children
								) : (
									<Redirect
										to={getAppRoutes().ERROR_PERMISSION}
									/>
								);
							}}
						</Permission>
					) : (
						children
					);
				}}
			/>
		</PrivateApp>
	);
}

export default PrivateRoute;
