import { Modal } from 'Components';
import DataTree from 'Contexts/DataTree/DataTree';
import { toDataTreeValue } from 'Contexts/DataTree/DataTree.helpers';
import { useModal } from 'Hooks/useModal';
import { isEqual } from 'lodash';
import GroupTree, {
	GroupTreeProps
} from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupTree';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupActiveStatus } from 'Services/Api/Groups/Types';

type Value = GroupTreeProps['initialValue'];

export type AllGroupsModalProps = {
	initialValue: Value;
	submitting?: boolean;
	onSubmit: (value: Value) => void;
};
function AllGroupsModal(props: AllGroupsModalProps) {
	const { t } = useTranslation();
	const { hideModal } = useModal();

	const [value, setValue] = useState<Value>(
		toDataTreeValue(props.initialValue)
	);

	const handleChange: GroupTreeProps['onChange'] = (options) => {
		setValue(toDataTreeValue(options));
	};
	const handleSubmit = () => {
		props.onSubmit(value);
	};

	const isDirty = !isEqual(
		toDataTreeValue(props.initialValue),
		toDataTreeValue(value)
	);

	return (
		<Modal
			visible
			size="sm"
			title={t('dashboard.filters.groupsModal.title')}
			okText={t('apply')}
			onOk={handleSubmit}
			onCancel={hideModal}
			okButtonProps={{
				disabled: !isDirty,
				loading: props.submitting
			}}
		>
			<GroupTree
				initialValue={props.initialValue}
				onChange={handleChange}
				toNode={(item, node) => {
					return {
						...node,
						disabled:
							item.isArchived ||
							item.active === GroupActiveStatus.Inactive
					};
				}}
			>
				<DataTree.List highlightValue />
			</GroupTree>
		</Modal>
	);
}

export default AllGroupsModal;
