import {
	action,
	computed,
	makeObservable,
	observable,
	runInAction
} from 'mobx';
import { keyBy, omit, without } from 'lodash';

import { Status } from 'app-types';
import { createSortDescription } from 'Components/Table/createColumns';
import { axiosClient } from 'Services/Api/client';
import REPORTS_API from 'Services/Api/Reports/Api';
import {
	GetSavedReportsRequest,
	ReportRequestBase,
	SavedReport,
	StandardReportListItem
} from 'Services/Api/Reports/Types';
import { StoreTableDataMap } from './types';

const SAVED_REPORTS_INITIAL = {
	byId: {},
	allIds: [],
	params: {
		sort: createSortDescription({
			columnKey: 'reportName',
			order: 'ascend'
		})
	}
};
class ReportsStore {
	status = {
		getStandardReports: Status.Idle,
		getSavedReports: Status.Idle,
		deleteReport: Status.Idle,
		getReport: Status.Idle
	};
	standardReports: StandardReportListItem[] = [];
	savedReports: StoreTableDataMap<
		SavedReport,
		GetSavedReportsRequest,
		SavedReport['id']
	> = { ...SAVED_REPORTS_INITIAL };

	constructor() {
		makeObservable(this, {
			status: observable,
			standardReports: observable,
			savedReports: observable,
			savedReportsList: computed
		});
	}

	get savedReportsList() {
		return this.savedReports.allIds.map((id) => this.savedReports.byId[id]);
	}

	@action
	async getStandardReports() {
		runInAction(() => {
			this.status.getStandardReports = Status.Pending;
		});
		try {
			const response = await axiosClient.get<StandardReportListItem[]>(
				REPORTS_API.STANDARD_REPORTS
			);

			runInAction(() => {
				this.standardReports = response.data;
				this.status.getStandardReports = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getStandardReports = Status.Failure;
			});
		}
	}

	@action
	async getSavedReports(params = this.savedReports.params) {
		runInAction(() => {
			this.status.getSavedReports = Status.Pending;
			this.savedReports.params = {
				...this.savedReports.params,
				...params
			};
		});
		try {
			const response = await axiosClient.get<SavedReport[]>(
				REPORTS_API.SAVED_REPORTS,
				{
					params: this.savedReports.params
				}
			);

			runInAction(() => {
				const allIds: SavedReport['id'][] = [];
				const byId = keyBy(response.data, (report) => {
					allIds.push(report.id);
					return report.id;
				});
				this.savedReports.byId = byId;
				this.savedReports.allIds = allIds;
				this.status.getSavedReports = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getSavedReports = Status.Failure;
			});
		}
	}

	@action
	clearSavedReports() {
		this.savedReports = { ...SAVED_REPORTS_INITIAL };
	}

	@action
	async deleteReport({ reportId }: ReportRequestBase) {
		runInAction(() => {
			this.status.deleteReport = Status.Pending;
		});
		try {
			await axiosClient.delete(
				`${REPORTS_API.SAVED_REPORTS}/${reportId}`
			);

			runInAction(() => {
				this.savedReports.allIds = without(
					this.savedReports.allIds,
					reportId
				);
				this.savedReports.byId = omit(this.savedReports.byId, reportId);
				this.status.deleteReport = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.deleteReport = Status.Failure;
			});
			throw e;
		}
	}

	getReportByName(name: string) {
		return this.savedReportsList.find((report) => report.name === name);
	}
}

export const reportsStore = new ReportsStore();

export default ReportsStore;
