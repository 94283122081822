import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';
import { DashboardFiltersValues } from './Dashboard.types';

export interface DashboardContextValues {
	filters?: DashboardFiltersValues;
	setActiveTab?: Dispatch<SetStateAction<DashboardTabKey | undefined>>;
	hasProperties?: boolean;
}

export const DashboardContext = createContext<DashboardContextValues>({});

export const useDashboardContext = () => useContext(DashboardContext);
