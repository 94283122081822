import {
	ApolloClient,
	InMemoryCache,
	createHttpLink,
	fromPromise,
	from
} from '@apollo/client';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { AUTH_TYPE } from 'aws-appsync-auth-link';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import rootStore from 'Stores/RootStore';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import mockAxios from './mocks';
import { GraphQLError } from 'app-types';
import { getAxiosErrorType } from 'Helpers/api';

const httpLink = createHttpLink({
	uri: process.env.REACT_APP_API_URL_GRAPHQL
});

// eslint-disable-next-line
const getHeaders = (headers: Record<string, string>) => {
	// eslint-disable-next-line
	const token = rootStore.authStore.authSession?.getIdToken() as any;
	return {
		headers: {
			...headers,
			Authorization: token ? `${token.jwtToken}` : ''
		}
	};
};

const authLink = setContext((_, { headers }) => getHeaders(headers));

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
	if (graphQLErrors) {
		for (const err of graphQLErrors) {
			// `GraphQLError` Interface isn't aligned with what is returned from BE
			switch (((err as unknown) as GraphQLError).errorType) {
				case 'UnauthorizedException':
					const observable = fromPromise(
						rootStore.authStore
							.getAuthSession()
							.catch(() => rootStore.authStore.signOut())
					)
						.filter(Boolean)
						.flatMap(() => {
							const headers = operation.getContext().headers;
							// modify the operation context with a new token
							operation.setContext(getHeaders(headers));
							// retry the request, returning the new observable
							return forward(operation);
						});
					return observable;
			}
		}
	}
});

// @see https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/509
const subscriptionLink = createSubscriptionHandshakeLink(
	{
		url: process.env.REACT_APP_API_URL_GRAPHQL as string,
		region: process.env.REACT_APP_COGNITO_REGION as string,
		auth: {
			type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
			jwtToken: () =>
				rootStore.authStore.authSession?.getIdToken().getJwtToken() ||
				''
		}
	},
	httpLink
);

export const apolloClient = new ApolloClient({
	link: from([errorLink, authLink, subscriptionLink]),
	cache: new InMemoryCache({
		addTypename: false
	}),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'no-cache'
		}
	}
});

// axios
export const axiosClient = axios.create({
	baseURL: process.env.REACT_APP_API_URL_REST as string
});

const addHeaders = (config: AxiosRequestConfig) => ({
	...config,
	...getHeaders({
		...config.headers
	})
});

axiosClient.interceptors.request.use(addHeaders);
axiosClient.interceptors.response.use(
	(res) => res,
	(error: AxiosError) => {
		const { isUnauthorizedError } = getAxiosErrorType(error);
		if (isUnauthorizedError) {
			return rootStore.authStore
				.getAuthSession()
				.then(() => axiosClient(error.config))
				.catch((error) => {
					const { isUnauthorizedError } = getAxiosErrorType(error);
					// signout if user if the exception is not authorized
					if (isUnauthorizedError) {
						rootStore.authStore.signOut();
					}

					return Promise.reject(error);
				});
		}

		return Promise.reject(error);
	}
);

mockAxios();
