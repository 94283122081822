import { useState, useCallback, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useErrorBoundary, useConfigs } from 'Hooks';
import { GroupsHierarchyContext } from './GroupsHierarchyContext';
import GroupsHierarchyLayout from './GroupsHierarchyLayout';
import GroupsHierarchyManageLayout from './GroupsHierarchyManageLayout';
import {
	ScreenType,
	HierarchyGroup,
	RouteParams
} from './GroupsHierarchy.types';
import { GET_GROUP, GET_HIERARCHY_SIZE } from 'Services/Api/Groups/Queries';
import {
	GetGroupResponse,
	GetHierarchySizeResponse,
	GetHierarchySizeVariables,
	GroupDetails
} from 'Services/Api/Groups/Types';
import { getDisabledIds } from './GroupsHierarchy.helpers';

const GroupsHierarchy = () => {
	const errorBoundary = useErrorBoundary();
	const { groupId } = useParams<RouteParams>();
	const [levelNumber, setLevelNumber] = useState<number | undefined>();
	const [levelNumberXLSX, setLevelNumberXLSX] = useState<
		number | undefined
	>();
	const [parent, setParent] = useState<GroupDetails | null>(null);
	const [blockIsUniqError, setIsUniqError] = useState<boolean>(false);
	const [activesIsPropertyLevel, setActiveIsPropertyLevel] = useState<
		Record<string, string[]>
	>({});
	const [disabledIds, setDisabledIds] = useState<Record<string, string[]>>(
		{}
	);
	const {
		groupsHierarchySizeLimit,
		groupMaxHierarchyLevel,
		isConfigsLoading
	} = useConfigs();
	const [getHierarchySize, { data }] = useLazyQuery<
		GetHierarchySizeResponse,
		GetHierarchySizeVariables
	>(GET_HIERARCHY_SIZE, {
		variables: {
			input: {
				groupId
			}
		},
		onError: errorBoundary.onError
	});
	const [getGroup] = useLazyQuery<GetGroupResponse>(GET_GROUP, {
		variables: {
			id: groupId
		},
		onCompleted: (data) => {
			if (data) {
				setParent(data.getGroup);
				setLevelNumber(data.getGroup?.level);
				setLevelNumberXLSX(data.getGroup?.level);
			}
		},
		onError: errorBoundary.onError
	});
	useEffect(() => {
		getHierarchySize();
		getGroup();
		// eslint-disable-next-line
	}, []);

	const [screenType, setScreenType] = useState<ScreenType>('hierarchy');
	const [hierarchyGroups, setHierarchyGroups] = useState<HierarchyGroup[]>(
		[]
	);

	useEffect(() => {
		if (hierarchyGroups.length) {
			setDisabledIds(
				getDisabledIds(hierarchyGroups, activesIsPropertyLevel)
			);
		}
	}, [hierarchyGroups, activesIsPropertyLevel]);

	const handleChangeScreenType = useCallback(
		(screenType: ScreenType, hierarchyGroups: HierarchyGroup[]) => {
			setScreenType(screenType);
			setHierarchyGroups(hierarchyGroups);
		},
		[]
	);
	const parentLevel = parent?.level ?? 1;
	return (
		<GroupsHierarchyContext.Provider
			value={{
				levelNumber,
				hierarchyGroups,
				parent,
				disabledIds,
				activesIsPropertyLevel,
				setHierarchyGroups,
				setLevelNumber,
				changeScreenType: handleChangeScreenType,
				setActiveIsPropertyLevel,
				blockIsUniqError,
				setIsUniqError,
				groupsHierarchySizeLimit,
				amountOfGroupsInTenant: data?.getHierarchySize.totalCount ?? 1,
				isConfigsLoading,
				groupMaxHierarchyLevel,
				parentLevel,
				levelNumberXLSX,
				setLevelNumberXLSX
			}}
		>
			{screenType === 'hierarchy' ? (
				<GroupsHierarchyLayout />
			) : hierarchyGroups.length ? (
				<GroupsHierarchyManageLayout />
			) : null}
		</GroupsHierarchyContext.Provider>
	);
};

export default GroupsHierarchy;
