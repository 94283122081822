import { useMutation, useLazyQuery } from '@apollo/client';
import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useForm, useFieldArray, SubmitErrorHandler } from 'react-hook-form';
import getAddMultipleGroupsSchema from './schema';
import { PromptHelpers } from 'Components/Prompt/Prompt';
import { GET_HIERARCHY_SIZE } from 'Services/Api/Groups/Queries';
import {
	CreateMultipleGroupsRequest,
	GetHierarchySizeResponse,
	GetHierarchySizeVariables
} from 'Services/Api/Groups/Types';
import { CREATE_MULTIPLE_GROUPS } from 'Services/Api/Groups/Mutations';
import { AddGroup, Values, GroupMode, GroupsRouteParams } from './Groups.types';
import { INITIAL_VALUES, GROUP } from './Groups.constants';
import { Notification } from 'Components';
import getIndexes from 'Helpers/getIndexes';
import { getSortedEntities } from 'Helpers/getSortedEntities';
import { checkApolloError } from 'Helpers/graphql';
import { APIErrorCodes } from 'app-types';
import { UNIQUE_ERROR_TYPE, UNIQUE_SYNC_CODE } from 'app-config';
import { useErrorBoundary, useConfigs } from 'Hooks';

interface UseAddMultipleGroupsParams {
	promptHelpers: PromptHelpers;
}

export const useAddMultipleGroups = ({
	promptHelpers
}: UseAddMultipleGroupsParams) => {
	const [mode, setMode] = useState<GroupMode>('manage');
	const [blockIsUniqError, setIsUniqError] = useState<boolean>(false);
	const { groupId } = useParams<GroupsRouteParams>();
	const errorBoundary = useErrorBoundary();
	const { t } = useTranslation();
	const [createMultipleGroups, response] = useMutation<
		unknown,
		CreateMultipleGroupsRequest
	>(CREATE_MULTIPLE_GROUPS);
	const { groupsHierarchySizeLimit, maxGroupNameCharacters } = useConfigs();
	const [getHierarchySize, { data }] = useLazyQuery<
		GetHierarchySizeResponse,
		GetHierarchySizeVariables
	>(GET_HIERARCHY_SIZE, {
		onError: errorBoundary.onError
	});
	useEffect(() => {
		if (groupId) {
			getHierarchySize({
				variables: {
					input: {
						groupId
					}
				}
			});
		}
		// eslint-disable-next-line
	}, [groupId]);

	const form = useForm<Values>({
		defaultValues: INITIAL_VALUES,
		// @ts-ignore
		resolver: yupResolver(
			getAddMultipleGroupsSchema(maxGroupNameCharacters)
		),
		mode: 'onChange'
	});

	const { fields, append } = useFieldArray({
		control: form.control,
		name: 'groups'
	});

	const validateGroupSyncCode = useCallback((clearAll?: boolean) => {
		const groups = form.getValues().groups;
		const indexes = getIndexes(groups, 'syncCode');
		if (indexes.length) {
			indexes.forEach((index: number) => {
				form.setError(`groups.${index}.syncCode`, {
					type: UNIQUE_SYNC_CODE
				});
			});
			Notification.error({
				description: t(
					'group.fieldErrorCodes.GROUP_SYNC_CODE_SHOULD_BE_UNIQUE_PER_TENANT',
					{ path: 'Group sync code' }
				)
			});
			setIsUniqError(true);
			return;
		}
		const errors = form.formState.errors;
		setIsUniqError(false);

		form.clearErrors(
			// @ts-ignore
			clearAll
				? undefined
				: groups.reduce<string[]>((acc, _, index) => {
						if (
							// @ts-ignore
							errors.groups?.[index]?.name?.type ===
							UNIQUE_SYNC_CODE
						) {
							acc.push(`groups.${index}.syncCode`);
						}
						return acc;
				  }, [])
		);

		// eslint-disable-next-line
	}, []);

	const validateGroupUniqName = useCallback((clearAll?: boolean) => {
		const groups = form.getValues().groups;
		const indexes = getIndexes(groups, 'name');
		if (indexes.length) {
			indexes.forEach((index: number) => {
				form.setError(`groups.${index}.name`, {
					type: UNIQUE_ERROR_TYPE
				});
			});
			Notification.error({
				description: t(
					'groups.addMultipleGroups.errorMessageUniqueName'
				)
			});
			setIsUniqError(true);
			return;
		}

		const errors = form.formState.errors;
		setIsUniqError(false);

		form.clearErrors(
			// @ts-ignore
			clearAll
				? undefined
				: groups.reduce<string[]>((acc, _, index) => {
						if (
							// @ts-ignore
							errors.groups?.[index]?.name?.type ===
							UNIQUE_ERROR_TYPE
						) {
							acc.push(`groups.${index}.name`);
						}
						return acc;
				  }, [])
		);

		// eslint-disable-next-line
	}, []);

	const isDirty = fields.length > 1 || form.formState.isDirty;
	const { prompt, setBlockTransition } = promptHelpers;

	useEffect(() => {
		setBlockTransition(isDirty);
	}, [isDirty, setBlockTransition]);

	const onMultipleGroupsClick = useCallback(() => {
		setMode('add-multiple');
	}, [setMode]);

	const onCancel = useCallback(() => {
		if (isDirty) {
			prompt({
				onOk: () => {
					setMode('manage');
					form.reset();
				}
			});

			return;
		}
		setMode('manage');
	}, [setMode, prompt, isDirty, form]);

	function handleGroupNameUniqueness(
		apolloError: ReturnType<typeof checkApolloError>,
		errorCodes: APIErrorCodes,
		formValues: Values
	) {
		const indexes = apolloError.getErrorIndexes(errorCodes);

		const pathToTranslation = indexes.length > 1 ? 'multiple' : 'single';

		Notification.error({
			description: t(
				`groups.errorCodes.${APIErrorCodes.GroupNameShouldBeUniqueAtLevel}.${pathToTranslation}`
			)
		});

		const sortedGroups = getSortedEntities<AddGroup>(
			formValues.groups,
			({ index }) => indexes.includes(index)
		);
		form.setValue('groups', sortedGroups, {
			shouldValidate: true
		});
		// setTimeout allows do not rewrite formState errors object
		setTimeout(() => {
			indexes.forEach((_, index) => {
				form.setError(`groups.${index}.name`, {
					type: UNIQUE_ERROR_TYPE
				});
			});
		}, 50);
	}

	const onMultipleGroupsSubmit = async (formValues: Values) => {
		try {
			const input = formValues.groups.map(
				({ key, fieldId, canBePropertyToAncestors, ...values }) => ({
					...values,
					syncCode: values.syncCode
						? values.syncCode.trim()
						: undefined,
					parentId: Number(groupId)
				})
			);

			await createMultipleGroups({
				variables: {
					input
				}
			});

			setMode('manage');

			form.reset();
			const amountPath =
				formValues.groups.length === 1 ? 'single' : 'multiple';

			Notification.success({
				description: t(
					`groups.addMultipleGroups.successMessage.${amountPath}`
				)
			});
		} catch (error) {
			const apolloError = checkApolloError(error);

			if (apolloError.is(APIErrorCodes.GroupNameShouldBeUniqueAtLevel)) {
				handleGroupNameUniqueness(
					apolloError,
					APIErrorCodes.GroupNameShouldBeUniqueAtLevel,
					formValues
				);

				return;
			}

			if (
				apolloError.is(APIErrorCodes.GroupSyncCodeShouldBeUniqPerTenant)
			) {
				Notification.error({
					description: t(
						'group.fieldErrorCodes.GROUP_SYNC_CODE_SHOULD_BE_UNIQUE_PER_TENANT',
						{ path: 'Group sync code' }
					)
				});
				const indexes = apolloError.getMeta<number[]>();

				const sortedGroups = getSortedEntities<AddGroup>(
					formValues.groups,
					({ index }) => indexes.includes(index)
				);

				form.setValue('groups', sortedGroups, {
					shouldValidate: true
				});
				// setTimeout allows do not rewrite formState errors object
				setTimeout(() => {
					indexes.forEach((_, index) => {
						form.setError(`groups.${index}.syncCode`, {
							type: UNIQUE_SYNC_CODE
						});
					});
				}, 50);
				return;
			}
			if (apolloError.is(APIErrorCodes.Unique)) {
				handleGroupNameUniqueness(
					apolloError,
					APIErrorCodes.Unique,
					formValues
				);
				return;
			}

			Notification.error({
				description: t('errorCodes.genericErrorMessage')
			});
		}
	};
	const onMultipleGroupsError: SubmitErrorHandler<Values> = useCallback(
		(errors) => {
			const values = form.getValues();
			const failedGroups = (values.groups as AddGroup[])
				.reduce<string[]>((acc, { name }, i) => {
					if (name && errors.groups?.[i]) {
						acc.push(name);
					}
					return acc;
				}, [])
				.join(', ');

			Notification.error({
				description: t('groups.addMultipleGroups.errorMessage', {
					groupNames: failedGroups.length ? `( ${failedGroups} )` : ''
				})
			});

			const sortedGroups = getSortedEntities<AddGroup>(
				values.groups,
				({ index }) => Boolean(errors.groups?.[index])
			);

			form.setValue('groups', sortedGroups, {
				shouldValidate: true
			});
			// it needs to create correct errors order and synchronize them with groups fields
			errors.groups?.sort();
		},
		[form, t]
	);

	const handleOnMultipleGroupSubmit = (
		ev: React.MouseEvent<HTMLElement, MouseEvent>
	) => {
		form.handleSubmit(onMultipleGroupsSubmit, onMultipleGroupsError)(ev);
	};

	const handleAddGroupClick = useCallback(() => {
		const values = form.getValues();
		if (
			values.groups.length + (data?.getHierarchySize.totalCount ?? 1) >=
			groupsHierarchySizeLimit
		) {
			Notification.error({
				description: t('groupsHierarchy.groupsLimitReached')
			});
			return;
		}
		append({
			...GROUP,
			key: String(fields.length),
			fieldId: uuid()
		});
		// eslint-disable-next-line
	}, [fields, data, groupsHierarchySizeLimit]);

	const handleDeleteGroupClick = useCallback(
		(fieldId: string) => {
			const { groups } = form.getValues();

			form.setValue(
				'groups',
				groups
					.filter((group) => group.fieldId !== fieldId)
					.map((group, index) => ({
						...group,
						key: String(index)
					}))
			);

			validateGroupUniqName(true);
			validateGroupSyncCode(true);
		},
		[form, validateGroupUniqName, validateGroupSyncCode]
	);

	return {
		mode,
		form,
		fields,
		handleOnMultipleGroupSubmit,
		handleAddGroupClick,
		handleDeleteGroupClick,
		onMultipleGroupsClick,
		onCancel,
		response,
		groupId,
		blockIsUniqError,
		validateGroupUniqName,
		validateGroupSyncCode
	};
};
