import { useTranslation } from 'react-i18next';
import { isI18nPathPresent } from 'i18n/i18n.helpers';
import {
	AxiosErrorResponse,
	APIErrorCodes,
	APIErrorMessage,
} from 'app-types';
import { APP_CONFIG } from 'app-config';
import { useStores } from 'Hooks/useStore';
import { getAxiosErrorType, isAxiosErrorCodeEqual } from 'Helpers/api';
import { Notification } from 'Components';

function useHandleDownloadError() {
	const { authStore } = useStores();
	const { t } = useTranslation();
	return (axiosError: AxiosErrorResponse) => {
		const { isNotFoundError, isUnprocessableEntity } = getAxiosErrorType(
			axiosError
		);
		let description = '';
		const errorType = axiosError.response?.data.error;
		const errorMessage = axiosError.response?.data.message;

		switch (true) {
			case isUnprocessableEntity &&
				isAxiosErrorCodeEqual(
					APIErrorCodes.NoDataForReportGeneration,
					axiosError
				):
				description =
					'dashboard.pdfExport.progress.failure.NO_DATA_FOR_REPORT_GENERATION';
				break;
			case isNotFoundError &&
				authStore.isCurrentUserOwner &&
				errorMessage === APIErrorMessage.NoGroupsAreAssignedToTheUser:
				description = 'errorCodes.NO_AVAILABLE_PROPERTIES';
				break;
			case isNotFoundError:
				description = `downloadReport.${APIErrorCodes.NotFound}`;
				break;
			case Boolean(errorType):
				const path = `${APP_CONFIG.I18N_ERROR_KEY}.${errorType}`;
				description =
					errorType && isI18nPathPresent(path, t)
						? t(path)
						: (errorType as string);
				break;
			default:
				description = 'errorCodes.genericErrorMessage';
		}

		Notification.error({
			description: t(description)
		});
	};
}

export default useHandleDownloadError;
