import { RadioChangeEvent } from 'antd';
import {
	PROPERTY_LABEL,
	GROUP_ACTIVE_STATUS_LABEL_MAP,
	GROUP_ACTIVE_STATUS_LIST
} from 'app-config';
import { Column } from 'Components/VirtualTable/VirtualTable';
import { Input, Radio, Select, Button, Icon } from 'Components';
import styles from './GroupsHierarchy.module.scss';
import { useConfigs } from 'Hooks';
import { validateGroupName } from './GroupsHierarchy.helpers';
import { GroupActiveStatus } from 'Services/Api/Groups/Types';
import { HierarchyGroup } from './GroupsHierarchy.types';
import { useTranslation } from 'react-i18next';

interface GetColumnsParams {
	level: number;
	disabledIds: Record<string, string[]>;
	disableDeleteGroupButton: boolean;
	onAddGroupClick: () => void;
	onGroupNameChange: (value: string, fieldId: string) => void;
	onSyncCodeChange: (value: string, fieldId: string) => void;
	onIsPropertyRadioChange: (
		value: boolean,
		id: string,
		level: string
	) => void;
	onDeleteGroupClick: (id: string) => void;
}

function useColumns({
	level,
	onGroupNameChange,
	onIsPropertyRadioChange,
	disabledIds,
	disableDeleteGroupButton,
	onDeleteGroupClick,
	onAddGroupClick,
	onSyncCodeChange
}: GetColumnsParams) {
	const { t } = useTranslation();
	const { maxGroupNameCharacters } = useConfigs();
	const columns: Array<Column<HierarchyGroup>> = [
		{
			title: (
				<>
					{t('group.groupName')}
					&nbsp;
					{t('groups.columns.level', {
						level
					})}
				</>
			),
			renderComponent: (data, index) => {
				return data.isManageRow ? (
					<Button
						type="primary"
						shape="round"
						icon={<Icon type="plus" />}
						onMouseUp={onAddGroupClick}
					>
						{t('groups.groupDetails.groupDetailsActions.addGroup')}
					</Button>
				) : (
					<Input.HookFormInput
						name={`${data.parentId}.${index}.name`}
						className={styles.marginBottomNull}
						onBlur={(ev) => {
							onGroupNameChange(ev.target.value, data.id);
						}}
						required
						validate={validateGroupName(maxGroupNameCharacters)}
					/>
				);
			}
		},
		{
			title: t('property'),
			className: styles.alignedStartTd,
			renderComponent: (data, index) => {
				return data.isManageRow ? null : (
					<Radio.Group.HookFormField
						name={`${data.parentId}.${index}.isProperty`}
						defaultValue={false}
						className={styles.marginBottomNull}
						onChange={(ev: RadioChangeEvent) =>
							onIsPropertyRadioChange(
								ev.target.value,
								data.id,
								(data.level as unknown) as string
							)
						}
						disabled={disabledIds[data.level]?.includes(data.id)}
					>
						<Radio value={true}>{PROPERTY_LABEL.YES()}</Radio>
						<Radio value={false}>{PROPERTY_LABEL.NO()}</Radio>
					</Radio.Group.HookFormField>
				);
			}
		},
		{
			title: t('group.syncCode'),
			renderComponent: (data, index) => {
				return data.isManageRow ? null : (
					<Input.HookFormInput
						name={`${data.parentId}.${index}.syncCode`}
						className={styles.marginBottomNull}
						onBlur={(ev) => {
							onSyncCodeChange(ev.target.value, data.id);
						}}
						minLength={1}
						maxLength={60}
					/>
				);
			}
		},
		{
			title: t('group.status'),
			renderComponent: (data, index) => {
				return data.isManageRow ? null : (
					<Select.HookFormSelect
						name={`${data.parentId}.${index}.active`}
						required
						defaultValue={GROUP_ACTIVE_STATUS_LABEL_MAP[
							GroupActiveStatus.Active
						]()}
						className={styles.marginBottomNull}
					>
						{GROUP_ACTIVE_STATUS_LIST.map((status) => (
							<Select.Option key={status} value={status}>
								{GROUP_ACTIVE_STATUS_LABEL_MAP[status]()}
							</Select.Option>
						))}
					</Select.HookFormSelect>
				);
			}
		},
		{
			title: t('actions'),
			width: 80,
			className: styles.centeredTd,
			renderComponent: (data) => {
				return data.isManageRow ? null : (
					<Button
						shape="round"
						type="primary"
						size="small"
						ghost
						icon={<Icon type="close" />}
						disabled={disableDeleteGroupButton}
						onMouseDown={() => onDeleteGroupClick(data.id)}
					/>
				);
			}
		}
	];

	return columns;
}

export default useColumns;
