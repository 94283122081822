import { TInfo } from 'app-types';
import { TFunction } from 'i18next';
import { isArray, isString } from 'lodash';

export const isI18nPathPresent = (path: string, t: TFunction) =>
	t(path) !== path;

const getTranslation = (error: TInfo | string, t: TFunction) => {
	if (isString(error)) {
		return t(error);
	}
	const params = {
		...error.params,
		path: error.params.path ? t(error.params.path) : undefined
	};
	return t(error.key, params);
};

export function translateTInfo(
	error: string | undefined,
	t: TFunction
): string | undefined;
export function translateTInfo(error: TInfo[], t: TFunction): string[];
export function translateTInfo(error: TInfo, t: TFunction): string;
export function translateTInfo(
	error: string | TInfo | TInfo[] | string | undefined,
	t: TFunction
) {
	if (!error) return error;
	if (isArray(error)) {
		return error.map((e) => getTranslation(e, t));
	}
	return getTranslation(error, t);
}
