import { ApolloError } from '@apollo/client';
import { APIErrorCodes } from 'app-types';
import { checkApolloError } from 'Helpers/graphql';
import {
	useErrorBoundaryContext,
	ErrorBoundaryStatus
} from 'Contexts/ErrorBoundaryContext/ErrorBoundaryContext';

function useErrorBoundary() {
	const errorHandlers = useErrorBoundaryContext();

	function onError(error: ApolloError) {
		const apolloError = checkApolloError(error);
		let status: ErrorBoundaryStatus | null = null;
		if (apolloError.is(APIErrorCodes.NotFound)) {
			status = 'notFound';
		} else if (apolloError.is(APIErrorCodes.Internal)) {
			status = 'internal';
		} else if (apolloError.is(APIErrorCodes.Forbidden)) {
			status = 'forbidden';
		}

		errorHandlers.setStatus(status);
	}

	function setStatus(status: ErrorBoundaryStatus) {
		errorHandlers.setStatus(status);
	}

	return {
		onError,
		setStatus
	};
}

export default useErrorBoundary;
