import i18n from 'i18n';
import { ValidatedUser } from 'Services/Api/Users/Types';

export const TRANSLATION_PATH = 'users.addUsers.importXLSXModal.';

export const SHEETS_TO_NAMES: Record<string, keyof ValidatedUser> = {
	A: 'firstName',
	B: 'lastName',
	C: 'email',
	D: 'login',
	E: 'phone',
	F: 'systemRole',
	G: 'group',
	H: 'timezone',
	I: 'language',
	J: 'jobClass',
	K: 'userRole'
};

export const SHEETS_NAMES = Object.keys(SHEETS_TO_NAMES);

export const COLUMNS_NAMES = () => [
	`${i18n.t('user.firstName')}*`,
	`${i18n.t('user.lastName')}*`,
	`${i18n.t('user.email')}*`,
	`${i18n.t('user.cognitoLogin')}*`,
	i18n.t('user.phoneNumber'),
	i18n.t('reports.addUsers.systemRole'),
	i18n.t('user.group'),
	i18n.t('user.timezoneId'),
	i18n.t('user.languageId'),
	i18n.t('user.lmsJobcass.label'),
	i18n.t('user.lmsRole.label')
];

export const COLUMNS_TITLES_CELLS = [
	'A1',
	'B1',
	'C1',
	'D1',
	'E1',
	'F1',
	'G1',
	'H1',
	'I1',
	'J1',
	'K1'
];
