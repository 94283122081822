import { clone } from 'lodash';
import { Reducer, useReducer, useRef } from 'react';
import { SearchState, SearchStateAction } from './Search.types';

const DEFAULT_ITEMS_PER_PAGE = 10;
const DEFAULT_PAGE = 1;

const INITIAL_STATE = {
	pagination: {
		itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
		page: DEFAULT_PAGE
	},
	dataSource: undefined,
	hasMore: false,
	loading: false,
	searching: false,
	searched: false,
	searchTerm: ''
};

export const useSearch = <T>() => {
	// Don't change source object
	const initialState = clone(INITIAL_STATE);

	// Create a ref keeping the last state of the reducer and update every time the reducer runs.
	// It helps to refer to the last state synchronously
	const currentLoadedStateRef = useRef<typeof loadedState>(initialState);
	const [loadedState, updateLoadedState] = useReducer<
		Reducer<
			SearchState<T>,
			| SearchStateAction<T>[0]
			| SearchStateAction<T>[1]
			| SearchStateAction<T>[2]
		>
	>((state, action) => {
		let result = state;
		switch (action.type) {
			case 'start':
				result = {
					...state,
					loading: Boolean(action.payload?.isMore),
					searching: !action.payload?.isMore,
					pagination: action.payload?.isMore
						? {
								...state.pagination,
								page: state.pagination.page + 1
						  }
						: {
								itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
								page: DEFAULT_PAGE
						  },
					searchTerm: '',
					dataSource: action.payload?.isMore ? state.dataSource : []
				};
				break;
			case 'success':
				result = {
					...state,
					// used by 'load more' functionality to use last submitted search value when loading more
					searchTerm: action.payload.searchTerm,
					loading: false,
					searching: false,
					searched: true,
					hasMore:
						action.payload.result.length ===
						state.pagination.itemsPerPage,
					dataSource: action.payload.isMore
						? [
								...(state.dataSource || []),
								...action.payload.result
						  ]
						: action.payload.result
				};
				break;
			case 'error':
				result = {
					...state,
					loading: false,
					searching: false,
					searched: false,
					hasMore: false,
					dataSource: undefined,
					searchTerm: ''
				};
				break;
		}
		currentLoadedStateRef.current = result;
		return result;
	}, initialState);

	return {
		currentLoadedStateRef,
		loadedState,
		updateLoadedState
	};
};
