import { gql } from '@apollo/client';

export const GET_USERS = gql`
	query List(
		$itemsPerPage: Int
		$page: Int
		$search: String
		$filters: UserListFilters
		$sort: String
	) {
		list(
			itemsPerPage: $itemsPerPage
			page: $page
			search: $search
			filters: $filters
			sort: $sort
		) {
			data {
				email
				cognitoId
				cognitoLogin
				lastLoginDate
				firstName
				lastName
				groups {
					id
					name
					deletedDate
				}
				primarySystemRole {
					id
					role
				}
				secondarySystemRoles {
					id
					role
				}
				isActive
				isDeleted
				isLockedOut
				peakV3Id
				loginStatus
				daysBeforeRemoval
				ownershipGroup {
					id
					name
					status
				}
				softDeletedDate
			}
			totalCount
		}
	}
`;

export const GET_USER = gql`
	query findUser($peakV3Id: String!) {
		findUser(peakV3Id: $peakV3Id) {
			peakV3Id
			firstName
			lastName
			email
			cognitoLogin
			phoneNumber
			doNotUpdateOnAutoFeeds
			twoFactorAuthorizationStatus
			primarySystemRole {
				id
				role
				isPrimary
				permissions {
					id
					code
				}
			}
			secondarySystemRoles {
				id
				role
				isPrimary
				permissions {
					id
					code
				}
			}
			groups {
				id
				name
				moduleSettings {
					moduleEnabled
				}
			}
			language {
				id
				name
			}
			timezone {
				id
				zone
			}
			permissions {
				id
				code
			}
			ownershipGroup {
				id
				name
				status
			}
			moduleSettings {
				jobClass {
					peakV3Id
					name
				}
				userRole {
					id
					role
				}
			}
		}
	}
`;

export const GET_LANGUAGES = gql`
	query GetLanguagesList {
		getLanguagesList {
			id
			name
		}
	}
`;

export const GET_TIMEZONES = gql`
	query GetTimezonesList {
		getTimezonesList {
			id
			zone
		}
	}
`;

export const EXPORT_USERS = gql`
	query ExportUsers(
		$search: String
		$sort: String
		$filters: UserListFilters
		$downloadId: String
	) {
		exportUsers(
			search: $search
			sort: $sort
			filters: $filters
			downloadId: $downloadId
		) {
			downloadUrl
		}
	}
`;

export const GET_USERS_IMPORT_TEMPLATE = gql`
	query GetUsersImportTemplate($groupId: Int!) {
		getUsersImportTemplate(groupId: $groupId) {
			downloadUrl
		}
	}
`;

export const VALIDATE_USERS = gql`
	query ValidateUsers($groupId: Int!, $users: [UsersForValidation]!) {
		validateUsers(groupId: $groupId, users: $users) {
			firstName
			lastName
			email
			login
			phone
			errors {
				errorCode
				fieldName
				meta
			}
			warnings {
				warningCode
				fieldName
			}
			group {
				id
				name
				moduleSettings {
					moduleEnabled
				}
			}
			jobClass {
				peakV3Id
				id
				name
			}
			language {
				id
				name
			}
			systemRole {
				id
				role
				isPrimary
			}
			timezone {
				id
				zone
			}
			userRole {
				id
				role
			}
		}
	}
`;

export const GET_FAILED_USERS = gql`
	query GetFailedUsers($input: ReqWithJWT!) {
		getFailedUsers(input: $input) {
			status
			requestId
			failedUsers {
				userInput {
					moduleSettings {
						jobClassId
						userRoleId
					}
					cognitoLogin
					email
					firstName
					groups
					languageId
					lastName
					phoneNumber
					primarySystemRoleId
					timezoneId
				}
				errors {
					fieldName
					errorCode
				}
			}
		}
	}
`;
