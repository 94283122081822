import PrivateApp from 'Pages/App/PrivateApp';
import { useStores } from 'Hooks/useStore';
import { AppWithErrorBoundaryAndLogoutTracker } from 'Pages/App/AppWithErrorBoundaryAndLogoutTracker';
import { RedirectToLogin, RedirectToTwoFactorAuthorization } from 'Components';
import ValidateEmailContent from './ValidateEmail.content';

function ValidateEmail() {
	const { authStore } = useStores();

	return (
		<PrivateApp>
			{(isAuthenticated) =>
				isAuthenticated ? (
					<AppWithErrorBoundaryAndLogoutTracker
						blockNavigation
						includeLayout
					>
						<ValidateEmailContent />
					</AppWithErrorBoundaryAndLogoutTracker>
				) : authStore.isTwoFactorAuthorizationRequired ? (
					<RedirectToTwoFactorAuthorization />
				) : (
					<RedirectToLogin />
				)
			}
		</PrivateApp>
	);
}

export default ValidateEmail;
