import cn from 'classnames';
import { Card as AntCard, CardProps as AntCardProps } from 'antd';
import styles from './Card.module.scss';
import { ReactNode } from 'react';
import { Spin } from 'Components';

export type CardProps = AntCardProps & {
	footer?: ReactNode;
	contentClassName?: string;
};

function Card({
	children,
	footer,
	loading,
	contentClassName,
	...props
}: CardProps) {
	return (
		<AntCard
			{...props}
			className={cn(props.className, styles.root, {
				[styles.loading]: loading
			})}
			title={props.title ? <h3>{props.title}</h3> : null}
		>
			{loading && <Spin className={styles.spin} overlay />}
			<div className={cn(styles.content, contentClassName)}>
				{children}
			</div>
			{footer && <div className={styles.footer}>{footer}</div>}
		</AntCard>
	);
}

export default Card;
