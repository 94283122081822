import { flow, curry } from 'lodash';
import {
	prepareBaseParams,
	curryCreateUserActivityRequest,
	preparePreviewRequest
} from 'Pages/Report/PreviewReport/PreviewReport.helpers';
import { PreviewReportRequest } from 'Services/Api/Reports/Types';
import { prepareRequest } from 'Pages/Report/EditReport/EditReport.helpers';
import { IPagination } from './PreviewReportTable.types';

export const getBaseUserActivityRequest = flow([
	prepareRequest,
	prepareBaseParams,
	curryCreateUserActivityRequest
]);

export const createAllUsersRequest = (
	previewReportRequest: PreviewReportRequest,
	paginationParams: IPagination
): PreviewReportRequest => {
	return { ...previewReportRequest, ...paginationParams };
};

export const curryCreateAllUsersRequest = curry(createAllUsersRequest);

export const getBaseAllUsersRequest = flow([
	preparePreviewRequest,
	curryCreateAllUsersRequest
]);
