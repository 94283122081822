import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { FormikHandlers, useFormikContext } from 'formik';
import { Input, Radio, Notification } from 'Components';
import { FIELD_PATTERN } from 'Helpers/validations';
import { Level } from '../../../components';
import { isGroupsHierarchyLimitReachedXLSX } from '../../../GroupsHierarchy.helpers';
import { ValuesXLSX } from '../../../GroupsHierarchy.types';
import { useGroupsHierarchyContext } from '../../../GroupsHierarchyContext';
import styles from './LevelItem.module.scss';

interface LevelItemInputsProps {
	name: string;
	isPropertyLevelDisabled: boolean;
	setLevelWithProperty(value: boolean, levelName: string): void;
}

const LevelItemInputs = ({
	name,
	isPropertyLevelDisabled,
	setLevelWithProperty
}: LevelItemInputsProps) => {
	const { t } = useTranslation();
	const { values, setFieldError } = useFormikContext<ValuesXLSX>();
	const {
		groupsHierarchySizeLimit,
		amountOfGroupsInTenant
	} = useGroupsHierarchyContext();
	const onChange: FormikHandlers['handleChange'] = (
		ev: ChangeEvent<HTMLInputElement>
	) => {
		setLevelWithProperty((ev.target.value as unknown) as boolean, name);
	};

	const handleHierarchy: FormikHandlers['handleBlur'] = (
		ev: ChangeEvent<HTMLInputElement>
	) => {
		const { value } = ev.target;

		if (!FIELD_PATTERN.numeric.test(value)) return;

		if (
			isGroupsHierarchyLimitReachedXLSX(
				values,
				groupsHierarchySizeLimit,
				amountOfGroupsInTenant
			)
		) {
			Notification.error({
				description: t('groupsHierarchy.groupsLimitReached')
			});
			setFieldError(`${name}.numberOfGroups`, 'limit');
			return;
		}
	};
	return (
		<>
			<div className={cn(styles.levelItem, styles.levelItemName)}>
				<Input.FormikField
					name={`${name}.levelName`}
					label={t('groupsHierarchy.formXLSX.levelItem.name')}
				/>
			</div>
			<div className={styles.levelItem}>
				<Input.FormikField
					name={`${name}.numberOfGroups`}
					onBlur={handleHierarchy}
					maxLength={3}
					label={t(
						'groupsHierarchy.formXLSX.levelItem.numberOfGroups'
					)}
				/>
			</div>
			<div className={cn(styles.levelItem, styles.levelRadioButtons)}>
				<Radio.Group.FormikField
					label={t('property') + ' ?'}
					name={`${name}.isProperty`}
					disabled={isPropertyLevelDisabled}
					onChange={onChange}
				>
					<Radio value={true}>{t('yes')}</Radio>
					<Radio value={false}>{t('no')}</Radio>
				</Radio.Group.FormikField>
			</div>
		</>
	);
};

interface LevelItemProps extends LevelItemInputsProps {
	levelNumber: number;
}
const LevelItem = ({ levelNumber, ...levelItemInputProps }: LevelItemProps) => {
	return (
		<Level
			levelNumber={levelNumber}
			Component={<LevelItemInputs {...levelItemInputProps} />}
			withDashedLine={false}
		/>
	);
};

export default LevelItem;
