import { Group, GroupActiveStatus } from 'Services/Api/Groups/Types';
import { getCanBePropertyToAncestors } from '../EditGroup/EditGroup.helpers';
import { AddGroupValues } from '../Group.types';

export const getInitialValues = (group: Group | undefined) => {
	const initialValues = {
		active: GroupActiveStatus.Active,
		isProperty: false,
		moduleEnabled: true,
		canBePropertyToAncestors: true
	} as AddGroupValues;

	if (group) {
		initialValues.canBePropertyToAncestors = getCanBePropertyToAncestors(
			group
		);
		initialValues.parent = {
			id: group.id,
			name: group.name,
			moduleSettings: group.moduleSettings,
			isProperty: group.isProperty
		};
		initialValues.tenant = group.parents.length
			? group.parents[0]
			: initialValues.parent;

		initialValues.moduleEnabled = Boolean(
			initialValues.tenant.moduleSettings?.moduleEnabled
		);
	}

	return initialValues;
};
