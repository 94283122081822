import i18n from 'i18n';
import { GroupActiveStatus, Property } from 'Services/Api/Groups/Types';
import { Text } from 'Components';
import { GROUP_LEGEND_TYPE_MAP } from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupTreeLegend';

export const propertyToTreeNode = (item: Property) => {
	const color = item.isArchived
		? GROUP_LEGEND_TYPE_MAP['archived']
		: item.deletedDate !== null
		? GROUP_LEGEND_TYPE_MAP['deleted']
		: item.status === GroupActiveStatus.Inactive
		? GROUP_LEGEND_TYPE_MAP['inactive']
		: undefined;

	return {
		key: item.id,
		title: item.name,
		label: (
			<Text color={color}>
				{item.name}
				{item.isTenant ? ` (${i18n.t('group.tenant')})` : ''}
			</Text>
		),
		parentKey: item.tenantId,
		checkable: item.isProperty
	};
};
