import { Col, DatePicker, Fx, Row } from 'Components';
import DatePickerSelect from 'Components/Form/DatePickerSelect/DatePickerSelect';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ReportRecurringFrequency } from 'Services/Api/Reports/Types';
import styles from '../EditReport.module.scss';
import { ReportFormValues } from '../schema';

function RecurrenceOn() {
	const { t } = useTranslation();
	const {
		values: { scheduleFrequency }
	} = useFormikContext<ReportFormValues>();

	if (!scheduleFrequency) return null;

	const timePicker = (
		<DatePicker.FormikField
			name="scheduleFrequency.time"
			picker="time"
			fullWidth
			required
			minuteStep={15}
			help={null}
		/>
	);

	return (
		<Fx self="bottom" align="bottom" fullHeight>
			<Fx shrink={0} className={styles.separator}>
				{t('report.separators.on')}
			</Fx>

			{scheduleFrequency.type === ReportRecurringFrequency.Daily &&
				timePicker}
			{scheduleFrequency.type === ReportRecurringFrequency.Annually && (
				<Row gutter={24} fullWidth>
					<Col xs={10}>
						<DatePicker.FormikField
							name="scheduleFrequency.frequency"
							fullWidth
							required
							help={null}
						/>
					</Col>
					<Col xs={14}>{timePicker}</Col>
				</Row>
			)}
			{scheduleFrequency.type === ReportRecurringFrequency.Weekly && (
				<Row gutter={24} fullWidth>
					<Col xs={10}>
						<DatePickerSelect.FormikField
							picker="weekday"
							name="scheduleFrequency.frequency"
							fullWidth
							required
							help={null}
						/>
					</Col>
					<Col xs={14}>{timePicker}</Col>
				</Row>
			)}
			{(scheduleFrequency.type === ReportRecurringFrequency.Monthly ||
				scheduleFrequency.type ===
					ReportRecurringFrequency.Quarterly) && (
				<Row gutter={24} fullWidth>
					<Col xs={10}>
						<DatePickerSelect.FormikField
							picker="day"
							name="scheduleFrequency.frequency"
							placeholder={t('report.placeholder.frequencyDay')}
							fullWidth
							required
							disabled={
								scheduleFrequency.type ===
								ReportRecurringFrequency.Quarterly
							}
							help={null}
						/>
					</Col>
					<Col xs={14}>{timePicker}</Col>
				</Row>
			)}
		</Fx>
	);
}

export default RecurrenceOn;
