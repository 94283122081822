import moment from 'moment';
import { APP_CONFIG } from 'app-config';

interface DateFormattedProps {
	date: string;
}
function DateFormatted({ date }: DateFormattedProps) {
	const formattedDate = moment(date).format(APP_CONFIG.DATE_TIME_FORMAT);
	return <>{formattedDate}</>;
}

export default DateFormatted;
