import { useTranslation } from 'react-i18next';
import { Formik, FormikConfig } from 'formik';
import { EditableLayout, Prompt } from 'Components';
import { schema, schemaProfile } from '../schema';
import EditUserTabs from './EditUserTabs';
import { LayoutProps } from 'Components/EditableLayout/EditableLayout';
import { TwoFactorAuthorizationStatus } from 'Services/Api/Users/Types';

type EditUserFormProps<Values> = FormikConfig<Values> &
	Pick<LayoutProps, 'goBack'> & {
		userName: string;
		userRole: string;
		isProfile: boolean;
		twoFactorAuthenticationStatus: TwoFactorAuthorizationStatus;
		isLmsHasError?: boolean;
	};

function EditUserForm<V>({
	userName,
	userRole,
	isProfile,
	goBack,
	isLmsHasError,
	twoFactorAuthenticationStatus,
	...formProps
}: EditUserFormProps<V>) {
	const { t } = useTranslation();
	return (
		<Formik
			validationSchema={isProfile ? schemaProfile : schema}
			{...formProps}
		>
			<EditableLayout.Tab
				backButtonVisible={!isProfile}
				goBack={goBack}
				heading={
					isProfile ? (
						t('profile.title')
					) : (
						<>
							{userName} ({userRole})
						</>
					)
				}
				prompt={false}
				footer={false}
			>
				<Prompt>
					{(helpers) => (
						<EditUserTabs
							{...helpers}
							isProfile={isProfile}
							isLmsHasError={isLmsHasError}
							twoFactorAuthenticationStatus={
								twoFactorAuthenticationStatus
							}
						/>
					)}
				</Prompt>
			</EditableLayout.Tab>
		</Formik>
	);
}

export default EditUserForm;
