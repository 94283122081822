import { qsParse } from 'Helpers/navigation';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getAppRoutes } from 'Pages/App/App.constants';
import PrivateApp from 'Pages/App/PrivateApp';
import { useState } from 'react';
import { CognitoResponseError } from 'Services/Api/Auth/Types';
import { Redirect } from 'react-router';
import { useStores } from 'Hooks/useStore';
import AuthLayout from '../AuthLayout';
import ResetPasswordForm, {
	ResetPasswordFormValues
} from './ResetPasswordForm';
import ResetPasswordFormSubmit from './ResetPasswordFormSubmit';
import { Notification } from 'Components';
import { useResetExpiredPassword } from '../hooks';
import {
	COGNITO_ERROR_MSG,
	COGNITO_ERROR_MSG_TO_DESCRIPTION_MAP
} from 'app-config';

interface ResetPasswordParams {
	login?: string;
}

function ResetPassword() {
	const history = useHistory();
	const { t } = useTranslation();
	const { authStore } = useStores();
	const queryParams = qsParse(history.location.search) as ResetPasswordParams;
	const initialLogin = queryParams.login ?? '';
	const initialPage = initialLogin ? 2 : 1;
	const resetExpiredPassword = useResetExpiredPassword();
	const appRoutes = getAppRoutes();
	const [page, setPage] = useState({
		page: initialPage,
		login: initialLogin
	});

	const onSubmit = async (values: ResetPasswordFormValues) => {
		try {
			await sendSecurityCode(values.login, () =>
				setPage({
					...page,
					login: values.login,
					page: 2
				})
			);
		} catch (e) {
			const error = e as CognitoResponseError;
			if (error.code === 'LimitExceededException') {
				Notification.error({
					description: t('auth.resetPassword.errors.limitExceeded')
				});
				return;
			}
			Notification.error({
				description: t('errorCodes.genericErrorMessage')
			});
		}
	};

	const sendSecurityCode = async (login: string, cb?: () => void) => {
		try {
			await authStore.resetPassword({ login });
			Notification.success({
				description: t(
					'auth.resetPasswordSubmitForm.notifications.verificationCodeSent'
				)
			});

			cb?.();
		} catch (e) {
			const error = e as CognitoResponseError;

			if (error.code === 'UserNotFoundException') {
				Notification.error({
					description: t(
						'auth.resetPasswordSubmitForm.notifications.error.userNotFondException'
					)
				});
				return;
			}

			if (error.code === 'NotAuthorizedException') {
				if (error.message === COGNITO_ERROR_MSG.DISABLED) {
					Notification.error({
						description: t(
							COGNITO_ERROR_MSG_TO_DESCRIPTION_MAP[
								COGNITO_ERROR_MSG.DISABLED
							]
						)
					});

					return;
				}

				try {
					// @NOTE: functionality handless behavior described in :
					// https://support.dataart.com/browse/VNZ-1756
					await resetExpiredPassword(login);
				} catch (e) {
					// @@!IMPORTANT!@@: block catch is required for invoking environment
					throw e;
				}
				return;
			}
			// @@!IMPORTANT!@@: block catch is required for invoking environment
			throw e;
		}
	};

	return (
		<PrivateApp>
			{(isAuthenticated) => (
				<>
					{isAuthenticated ? (
						<Redirect to={appRoutes.HOME} />
					) : (
						<AuthLayout>
							{page.page === 1 ? (
								<ResetPasswordForm
									initialValues={{ login: page.login }}
									onSubmit={onSubmit}
								/>
							) : (
								<ResetPasswordFormSubmit
									login={page.login}
									sendSecurityCode={sendSecurityCode}
								/>
							)}
						</AuthLayout>
					)}
				</>
			)}
		</PrivateApp>
	);
}

export default ResetPassword;
