import { APP_CONFIG } from 'app-config';
import { PaginatedRequest } from 'app-types';
import { SearchProps } from 'Contexts/DataTree/components/Search/Search.types';
import DataTree from 'Contexts/DataTree/DataTree';
import { apolloClient } from 'Services/Api/client';
import { SEARCH_GROUPS } from 'Services/Api/Groups/Queries';
import {
	SearchGroup,
	SearchGroupsRequest,
	SearchGroupsResponse
} from 'Services/Api/Groups/Types';

type GroupsSearchProps = Partial<SearchProps> & {
	withDeleted?: boolean;
};

export function GroupsSearch({ withDeleted, ...props }: GroupsSearchProps) {
	const onSearch = async (
		searchTerm: string,
		pagination: PaginatedRequest
	) => {
		const response = await apolloClient.query<
			SearchGroupsResponse,
			SearchGroupsRequest
		>({
			query: SEARCH_GROUPS,
			fetchPolicy: 'no-cache',
			variables: {
				filters: {
					name:
						searchTerm.length >= APP_CONFIG.SEARCH_MIN_LENGTH
							? searchTerm
							: '',
					withDeleted: Boolean(withDeleted)
				},
				...pagination
			}
		});
		return response.data.searchGroups.groups;
	};

	return (
		<DataTree.Search<SearchGroup>
			{...props}
			search={onSearch}
			toOption={(value) => ({
				key: String(value.id),
				value: value.id,
				label: value.name,
				description: value.path
			})}
			getPopupContainer={() => document.body}
			defaultPaginationEnabled
		/>
	);
}

export default GroupsSearch;
