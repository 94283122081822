import { keyBy } from 'lodash';
import { ValidationTestName } from 'Helpers/validations';
import { SchemaDescription } from 'yup/lib/schema';
import { NewPasswordFieldsValues, RuleType } from './NewPasswordFields.types';
import { schema } from './schema';

export const INITIAL_TESTS = {
	[ValidationTestName.Min]: {
		rule: RuleType.Length,
		valid: false
	},
	[ValidationTestName.Max]: {
		rule: RuleType.Length,
		valid: false
	},
	[ValidationTestName.IncludesLowerCase]: {
		rule: RuleType.Lower,
		valid: false
	},
	[ValidationTestName.IncludesUpperCase]: {
		rule: RuleType.Upper,
		valid: false
	},
	[ValidationTestName.IncludesSpecialChar]: {
		rule: RuleType.SpecialChar,
		valid: false
	},
	[ValidationTestName.IncludesInterger]: {
		rule: RuleType.Integer,
		valid: false
	},
	[ValidationTestName.OneOf]: {
		rule: RuleType.Same,
		valid: false
	}
};

export const RULE_TYPES = Object.keys(RuleType) as RuleType[];

export const VALIDATION_TESTS_BY_FIELD: Partial<
	Record<keyof NewPasswordFieldsValues, ValidationTestName[]>
> = {
	confirmPassword: [ValidationTestName.OneOf]
};

const SCHEMA_INFO = (keyBy(
	(schema.describe().fields.password as SchemaDescription).tests,
	(test) => test.name
) as unknown) as Record<string, SchemaDescription['tests'][0]>;

export const MESSAGE_BY_TEST_TYPE = {
	[RuleType.Length]: {
		key: 'auth.changePassword.validationQuide.length',
		params: {
			min: SCHEMA_INFO.min.params?.min,
			max: SCHEMA_INFO.max.params?.max
		}
	},
	[RuleType.Upper]: 'auth.changePassword.validationQuide.upper',
	[RuleType.Lower]: 'auth.changePassword.validationQuide.lower',
	[RuleType.Integer]: 'auth.changePassword.validationQuide.integer',
	[RuleType.SpecialChar]: 'auth.changePassword.validationQuide.specialChar',
	[RuleType.Same]: 'auth.changePassword.validationQuide.same'
};

export type TestsType = typeof INITIAL_TESTS;
