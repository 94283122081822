import { FC } from 'react';
import { ButtonProps } from 'antd/lib/button';
import { useTranslation } from 'react-i18next';
import { Button } from 'Components';

const HierarchySubmitButton: FC<ButtonProps> = ({ children, ...props }) => {
	const { t } = useTranslation();

	return (
		<Button shape="round" type="primary" htmlType="submit" {...props}>
			{children ?? t('groupsHierarchy.submitText')}
		</Button>
	);
};

export default HierarchySubmitButton;
