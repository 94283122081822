import { observer } from 'mobx-react-lite';
import { ColumnProps } from 'antd/lib/table';
import { User } from 'Services/Api/Users/Types';
import { Tabs, Table, Fx, Pagination } from 'Components';
import {
	ReportType,
	PreviewReportTypeData,
	ReportDataByReportTypeUserActivity
} from 'Services/Api/Reports/Types';
import { useTabsWithPagination } from './UserActivityReportTable.hooks';
import styles from './UserActivityReportTable.module.scss';

interface UserActivityReportTableProps {
	data: PreviewReportTypeData<ReportType.UserActivity>['data'];
	columns: ColumnProps<User>[];
	reportName: PreviewReportTypeData<ReportType.UserActivity>['name'];
}

function UserActivityReportTable(props: UserActivityReportTableProps) {
	const propsData = (props.data as unknown) as ReportDataByReportTypeUserActivity;

	const {
		onPaginationChange,
		onTabChange,
		tabs,
		dataLoading,
		tabState
	} = useTabsWithPagination(propsData, props.reportName);

	return (
		<Tabs
			activeKey={tabState.activeTab}
			onChange={onTabChange}
			className={styles.tabs}
		>
			{tabs.map((tab) => {
				return (
					<Tabs.TabPane tab={tab()} key={tab.displayName} animated>
						<Table
							dataSource={tabState.dataSource}
							rowKey="uuid"
							columns={props.columns}
							pagination={false}
							className={styles.table}
							loading={dataLoading}
						/>
						<Fx shrink={0} className={styles.paginationWrap}>
							<Pagination
								total={tabState.totalCount}
								pageSize={tabState.pageSize}
								current={tabState.pageNumber}
								onChange={onPaginationChange}
							/>
						</Fx>
					</Tabs.TabPane>
				);
			})}
		</Tabs>
	);
}

export default observer(UserActivityReportTable);
