import { LabelValueItem } from 'app-types';
import { FormItem, Select } from 'Components';
import { SelectProps } from 'Components/Form/Select/Select';
import {
	dataTreeValueToLabelValue,
	labelValueToDataTreeValue
} from 'Contexts/DataTree/DataTree.helpers';
import ModalContextProvider, { useModal } from 'Hooks/useModal';
import { isArray } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardGroupFilterModal, {
	DashboardGroupFilterModalProps
} from './DashboardGroupFilterModal';
import styles from '../DashboardFilters.module.scss';
import { FormItemProps } from 'Components/Form/FormItem/FormItem';

export type Value = LabelValueItem<number>[];
type DashboardGroupFilterFieldProps = FormItemProps & {
	onChange: (value: number[]) => void;
	initialValue?: Value;
};
export type DashboardGroupFilterProps = Pick<
	DashboardGroupFilterModalProps,
	'submitting' | 'propertiesOnly'
> &
	DashboardGroupFilterFieldProps;
function DashboardGroupFilterField({
	onChange,
	submitting,
	propertiesOnly,
	initialValue,
	...props
}: DashboardGroupFilterProps) {
	const { t } = useTranslation();
	const { modal, showModal, hideModal } = useModal();
	const [value, setValue] = useState<Value>(initialValue ?? []);

	const handleClick = () => {
		showModal({ value: null });
	};

	const handleRemove: SelectProps['onChange'] = (_, option) => {
		if (!isArray(option)) return;
		const newValue = option as Value;
		setValue(newValue);
		onChange(newValue.map((d) => d.key));
	};

	const handleSubmit: DashboardGroupFilterModalProps['onSubmit'] = async (
		value = []
	) => {
		const newValue = dataTreeValueToLabelValue<number>(value);
		setValue(newValue);
		await onChange(newValue.map((d) => d.key));
		hideModal();
	};

	return (
		<>
			{modal && (
				<DashboardGroupFilterModal
					initialValue={labelValueToDataTreeValue(value)}
					onSubmit={handleSubmit}
					submitting={submitting}
					propertiesOnly={propertiesOnly}
				/>
			)}
			<FormItem {...props}>
				<Select
					mode="multiple"
					labelInValue
					value={value}
					onClick={handleClick}
					onChange={handleRemove}
					open={false}
					loading={submitting}
					getPopupContainer={() => document.body}
					placeholder={
						propertiesOnly
							? t(
									'dashboard.filters.groups.propertiesPlaceholder'
							  )
							: t('dashboard.filters.groups.groupsPlaceholder')
					}
					className={styles.root}
				>
					{value?.map((option) => (
						<Select.Option {...option} children={null} />
					))}
				</Select>
			</FormItem>
		</>
	);
}

const DashboardGroupFilter = (props: DashboardGroupFilterProps) => {
	return (
		<ModalContextProvider>
			<DashboardGroupFilterField {...props} />
		</ModalContextProvider>
	);
};

export default DashboardGroupFilter;
