import { Modal } from 'Components';
import { useModal } from 'Hooks/useModal';
import PreviewReport from './PreviewReport';
import styles from './PreviewReport.module.scss';
import { PreviewReportModalValue } from './PreviewReport.types';

function PreviewReportModal() {
	const { modal, hideModal } = useModal();

	if (!modal) return null;
	const value = modal.value as PreviewReportModalValue;
	return (
		<Modal
			visible
			title={<PreviewReport.Title data={value.data} />}
			onCancel={hideModal}
			maskClosable={false}
			footer={null}
			className={styles.root}
		>
			<PreviewReport data={value.data} />
		</Modal>
	);
}

export default PreviewReportModal;
