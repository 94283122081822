import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Layout, Modal, Notification } from 'Components';
import { useStores } from 'Hooks/useStore';
import { useHistory } from 'react-router';
import { getAppRoutes } from 'Pages/App/App.constants';
import AppLayout from 'Pages/App/AppLayout/AppLayout';
import { LanguageI18nKey } from 'Services/Api/Common/Types';

function IdleLogout() {
	const { authStore, languageStore, downloadReportsStore } = useStores();

	const history = useHistory();
	const { t } = useTranslation();
	useEffect(() => {
		authStore.setIdleLogout(true);
		authStore.signOut();
		Object.keys(downloadReportsStore.byDocumentId).forEach((id) => {
			Notification.close(id);
			downloadReportsStore.cancelGenerateReport({
				downloadId: id
			});
		});
		return () => {
			authStore.setIdleLogout(false);
		};
		// eslint-disable-next-line
	}, []);

	const onOk = async () => {
		await languageStore.changeLanguage(LanguageI18nKey.English);
		history.push(getAppRoutes().LOGIN);
	};

	return (
		<AppLayout
			header={null}
			headerTheme="light"
			contentAddon={<Layout.MountainView />}
		>
			<Modal
				visible
				cancelButtonVisible={false}
				okText={t('auth.idleLogout.submitText')}
				onOk={onOk}
				onCancel={onOk}
			>
				{t('auth.idleLogout.description')}
			</Modal>
		</AppLayout>
	);
}

export default observer(IdleLogout);
