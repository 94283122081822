import { Layout } from 'antd';
import React from 'react';
import cn from 'classnames';
import AppFooter from './AppFooter/AppFooter';
import AppHeader from './AppHeader/AppHeader';
import { BlockNavigation } from './AppHeader/AppHeader.types';
import styles from './AppLayout.module.scss';

interface AppLayoutProps extends BlockNavigation {
	className?: string;
	header?: React.ReactNode;
	headerTheme?: 'light';
	contentAddon?: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({
	className,
	headerTheme,
	header,
	contentAddon,
	blockNavigation,
	children
}) => {
	return (
		<Layout className={cn(styles.root, className)}>
			{contentAddon}
			<Layout.Header
				className={cn({
					[styles.themeLight]: headerTheme === 'light'
				})}
			>
				{header ||
					(header !== null && (
						<AppHeader blockNavigation={blockNavigation} />
					))}
			</Layout.Header>
			<Layout.Content>{children}</Layout.Content>
			<AppFooter />
		</Layout>
	);
};

export default AppLayout;
