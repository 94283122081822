import { FC, useEffect } from 'react';
import { Stack, Spin } from 'Components';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { getIsPending } from '../../../../Stores/util';
import Widgets from './Widgets/Widgets';
import QuickLinks from './QuickLinks/QuickLinks';

const MyResults: FC = () => {
	const { dashboardStore } = useStores();
	const { status } = dashboardStore;

	useEffect(() => {
		dashboardStore.getMyResults();
		//eslint-disable-next-line
	}, []);

	if (getIsPending(status.getMyResults)) {
		return <Spin />;
	}

	return (
		<Stack>
			<Widgets />
			<QuickLinks />
		</Stack>
	);
};

export default observer(MyResults);
