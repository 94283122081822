import { ComponentType, useCallback } from 'react';
import { omit } from 'lodash';
import { UNIQUE_ERROR_TYPE, RECREATED_ERROR_TYPE, UNIQUE_SYNC_CODE, API_DEFINED_ERROR_TYPE } from 'app-config';
import { useController, UseControllerProps } from 'react-hook-form';
import { FormItemProps } from 'antd/lib/form';
import { FormItem } from 'Components';

type FieldControllerProps = Omit<UseControllerProps, 'rules'> &
	UseControllerProps['rules'];
type HookFormFieldProps<P> = FieldControllerProps & FormItemHookFormProps & P;
export type FormItemHookFormProps = Pick<
	FormItemProps,
	'label' | 'required' | 'help' | 'className'
>;

export function createHookFormField<P>(component: ComponentType<P>) {
	function HookFormField(props: HookFormFieldProps<P>) {
		const {
			field: { ref, ...handledField },
			fieldState: { invalid, isTouched, error },
			formState: { isSubmitted }
		} = useController({
			name: props.name,
			control: props.control,
			rules: {
				required: props.required,
				maxLength: props.maxLength,
				minLength: props.minLength,
				validate: props.validate
			},
			defaultValue: props.defaultValue
		});

		const onChange = useCallback(
			// eslint-disable-next-line
			(...args: any) => {
				// do not limit to a specific number of arguments, still assume 1st arg is event
				handledField.onChange(...args);
				// @ts-ignore
				props.onChange?.(...args);
			},
			[props, handledField]
		);

		const onBlur = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				handledField.onBlur();

				setTimeout(() => {
					props.onBlur?.(e);
				}, 0);
			},
			[props, handledField]
		);
		const isError =
			((isTouched || isSubmitted) &&
				invalid &&
				(Boolean(handledField.value?.length) ||
					(props?.required && !handledField.value?.length))) || [
            API_DEFINED_ERROR_TYPE,
            UNIQUE_SYNC_CODE,
            UNIQUE_ERROR_TYPE,
            RECREATED_ERROR_TYPE
          ].some(errorType => errorType === error?.type)
			// TODO: should be handled in separate task
		const { label, required, help, className, ...restProps } = props;

		const inputProps = omit(restProps, ['name', 'validate']);
		const formItemProps: FormItemProps = {
			label,
			required,
			help: help && isError && error.message,
			validateStatus: isError ? 'error' : undefined,
			className
		};

		const Component = component;

		return (
			<FormItem {...formItemProps} marginBottom={Boolean(props.help)}>
				{/* @ts-ignore */}
				<Component
					{...inputProps}
					{...handledField}
					defaultValue={handledField.value}
					onBlur={onBlur}
					onChange={onChange}
				/>
			</FormItem>
		);
	}

	return HookFormField;
}
