import { Fx, Icon, Input, Space, Text, Tooltip } from 'Components';
import {
	MESSAGE_BY_TEST_TYPE,
	RULE_TYPES
} from './NewPasswordFields.constants';
import { useNewPasswordFields } from './NewPasswordFields.hooks';
import styles from 'Pages/Auth/AuthLayout.module.scss';
import { isString } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getAdditionalInfoByRule } from './NewPasswordFields.helpers';

function NewPasswordFields() {
	const { t } = useTranslation();
	const { validByRule } = useNewPasswordFields();

	return (
		<>
			<Input.PasswordFormikField
				name="password"
				label={t('auth.changePassword.inputs.password')}
				help={null}
			/>
			<Input.PasswordFormikField
				name="confirmPassword"
				label={t('auth.changePassword.inputs.confirmPassword')}
				help={null}
			/>
			<div className={styles.validationQuide}>
				{RULE_TYPES.map((rule, index) => {
					const message = MESSAGE_BY_TEST_TYPE[rule];
					const messageDescription = isString(message)
						? t(message)
						: t(message.key, message.params);
					const valid = validByRule[rule];
					const color = valid ? 'success' : 'default';
					const info = getAdditionalInfoByRule(rule);
					return (
						<div key={index}>
							<Space align="center">
								<Fx inline>
									<Text color={color}>
										<Icon type="check" />
									</Text>
								</Fx>
								<Text color={color}>{messageDescription}</Text>
								{info && (
									<Text color="default">
										<Tooltip
											title={info}
											placement="topRight"
										>
											<Icon
												type="infoOutlined"
												size="md"
											/>
										</Tooltip>
									</Text>
								)}
							</Space>
						</div>
					);
				})}
			</div>
		</>
	);
}

export default NewPasswordFields;
