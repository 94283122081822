import {
	ARCHIVED_LABEL,
	USER_ACTIVE_LABEL,
	USER_DELETED_LABEL
} from 'app-config';
import { Space, Text } from 'Components';
import { TextProps } from 'Components/Text/Text';
import i18n from 'i18n';
import styles from './GroupTreeLegend.module.scss';

type GroupTreeLegendType = 'archived' | 'active' | 'inactive' | 'deleted';

export const GROUP_LEGEND_TYPE_MAP: Record<
	GroupTreeLegendType,
	TextProps['color']
> = {
	active: undefined,
	inactive: 'default',
	archived: 'warning',
	deleted: 'error'
};

interface LegendItem {
	type: GroupTreeLegendType;
	label: () => string;
}

const LEGEND_ITEMS: LegendItem[] = [
	{ label: USER_ACTIVE_LABEL.YES, type: 'active' },
	{ label: ARCHIVED_LABEL.YES, type: 'archived' },
	{ label: USER_DELETED_LABEL.YES, type: 'deleted' },
	{ label: USER_ACTIVE_LABEL.NO, type: 'inactive' }
];

export interface GroupTreeLegendProps {
	exclude?: LegendItem['type'][];
}
function GroupTreeLegend(props: GroupTreeLegendProps) {
	return (
		<div className={styles.root}>
			<span className={styles.title}>
				{i18n.t('Components.dataTree.colorLegend.label')}:
			</span>
			&nbsp;
			<Space>
				{LEGEND_ITEMS.map((item, index) => {
					if (props.exclude?.includes(item.type)) {
						return null;
					}
					return (
						<Text
							key={index}
							color={GROUP_LEGEND_TYPE_MAP[item.type]}
						>
							{item.label()}
						</Text>
					);
				})}
			</Space>
		</div>
	);
}

export default GroupTreeLegend;
