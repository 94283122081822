import { Modal, Notification } from 'Components';
import TInfoText from 'Contexts/TInfoText/TInfoText';
import { useModal } from 'Hooks/useModal';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { getIsPending } from 'Stores/util';
import { ReportModalAction, ReportModalValue } from './ReportModals.types';

const PATH = 'reports.tabs.saved.deleteReportModal';

function DeleteReportModal() {
	const { t } = useTranslation();
	const { modal, hideModal } = useModal();
	const { reportsStore } = useStores();

	const modalValue = modal as ReportModalValue;

	if (!modalValue || modalValue.name !== ReportModalAction.Delete) {
		return null;
	}

	const onSubmit = async () => {
		try {
			await reportsStore.deleteReport({
				reportId: modalValue.value.id
			});
			hideModal();
			Notification.success({
				description: t(`${PATH}.successNotification`)
			});
		} catch (e) {
			Notification.error({
				description: t('errorCodes.genericErrorMessage')
			});
		}
	};

	return (
		<Modal
			visible
			title={
				<TInfoText
					path={`${PATH}.title`}
					params={{ name: modalValue.value.name }}
				/>
			}
			okText={t('delete')}
			okButtonProps={{
				loading: getIsPending(reportsStore.status.deleteReport)
			}}
			onOk={onSubmit}
			onCancel={hideModal}
		>
			{t(`${PATH}.description`)}
		</Modal>
	);
}

export default observer(DeleteReportModal);
