import { useStores } from 'Hooks/useStore';
import PropertiesFieldModal from 'Pages/OwnershipGroup/AddOwnershipGroup/PropertiesField/PropertiesFieldModal';
import { useTranslation } from 'react-i18next';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import AllGroupsModal, { AllGroupsModalProps } from './AllGroupsModal';

export type DashboardGroupFilterModalProps = AllGroupsModalProps & {
	propertiesOnly: boolean;
};
function DashboardGroupFilterModal(props: DashboardGroupFilterModalProps) {
	const { t } = useTranslation();
	const { authStore } = useStores();

	if (
		authStore.currentUser?.primarySystemRole.role === SystemRoleType.Owner
	) {
		return (
			<PropertiesFieldModal
				{...props}
				title={t('dashboard.filters.propertiesModal.title')}
				okText={t('apply')}
				legendProps={{
					exclude: ['inactive', 'deleted', 'archived']
				}}
				listProps={{
					checkable: false
				}}
			/>
		);
	}

	return <AllGroupsModal {...props} />;
}

export default DashboardGroupFilterModal;
