import { gql } from '@apollo/client';

export const UPDATE_CURRENT_USER = gql`
	mutation UpdateCurrentUser($input: updateCurrentUser) {
		updateCurrentUser(input: $input) {
			email
			phoneNumber
		}
	}
`;
