import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { getUserRoleLabel } from 'app-util';
import {
	USER_ACTIVE_LABEL,
	USER_LOGIN_STATUS_LABEL,
	USER_LOGIN_STATUS_TAG_MAP
} from 'app-config';
import {
	DateFormatted,
	OptionalValue,
	Tag,
	Text,
	DaysBeforeRemoval
} from 'Components';
import { PreviewUser } from 'Services/Api/Users/Types';
import { useConfigs } from 'Hooks';

export function usePreviewUsersColumns() {
	const { t } = useTranslation();
	const { groupMaxHierarchyLevel } = useConfigs();

	const levelsColumns = Array(groupMaxHierarchyLevel)
		.fill(null)
		.map((_, index) => {
			const levelNumber = index + 1;
			return {
				title: t('groups.columns.level', { level: levelNumber }),
				key: `level${levelNumber}`,
				dataIndex: `level${levelNumber}`,
				width: 160
			};
		});
	const columns: Array<ColumnProps<PreviewUser>> = [
		{
			title: t('user.cognitoLogin'),
			key: 'cognitoLogin',
			dataIndex: 'cognitoLogin',
			render: (_value, record) => {
				const description = record.isDeleted ? (
					record.cognitoLogin
				) : (
					<>
						{record.cognitoLogin}
						<br />
						<Text color="default">
							{t('users.columns.lastLogin')}:{' '}
							{record.lastLoginDate ? (
								<DateFormatted date={record.lastLoginDate} />
							) : (
								<OptionalValue />
							)}
						</Text>
					</>
				);

				return (
					<>
						{description}
						<DaysBeforeRemoval days={record.daysBeforeRemoval} />
					</>
				);
			},
			width: 180
		},
		{
			title: t('user.firstName'),
			key: 'firstName',
			dataIndex: 'firstName',
			width: 160
		},
		{
			title: t('user.lastName'),
			key: 'lastName',
			dataIndex: 'lastName',
			width: 160
		},
		{
			title: t('user.email'),
			key: 'email',
			dataIndex: 'email',
			width: 160
		},
		{
			title: t('user.primarySystemRoleId'),
			key: 'systemRoles',
			dataIndex: 'systemRoles',
			render: (_value, record) => {
				const roles = [getUserRoleLabel(record.primarySystemRole.role)];
				if (record.secondarySystemRoles) {
					roles.push(
						...record.secondarySystemRoles.map(
							(secondarySystemRole) => secondarySystemRole.role
						)
					);
				}
				return roles.join(', ');
			},
			width: 150
		},
		{
			title: t('user.status'),
			key: 'isActive',
			dataIndex: 'isActive',
			render: (_, record) => (
				<Tag type={record.isActive ? 'success' : 'default'}>
					{record.isActive
						? USER_ACTIVE_LABEL.YES()
						: USER_ACTIVE_LABEL.NO()}
				</Tag>
			),
			width: 130
		},
		{
			title: t('user.loginStatus'),
			key: 'loginStatus',
			dataIndex: 'loginStatus',
			render: (_, record) => {
				if (!record.loginStatus) {
					return null;
				}
				return (
					<Tag type={USER_LOGIN_STATUS_TAG_MAP[record.loginStatus]}>
						{USER_LOGIN_STATUS_LABEL[record.loginStatus]()}
					</Tag>
				);
			},
			width: 160
		},
		...levelsColumns,
		{
			title: t('user.ownershipGroup'),
			key: 'ownershipGroupName',
			dataIndex: 'ownershipGroupName',
			width: 160
		}
	];
	return columns;
}
