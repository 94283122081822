import { Button, Icon, Tooltip } from 'Components';
import { TooltipProps } from 'Components/Tooltip/Tooltip';
import styles from './InfoTooltip.module.scss';

const InfoTooltip = (props: TooltipProps) => (
	<Tooltip placement="topRight" {...props}>
		<Button type="default" text className={styles.button}>
			{props.children || <Icon type="infoOutlined" size="xl" />}
		</Button>
	</Tooltip>
);

export default InfoTooltip;
