import { useLazyQuery } from '@apollo/client';
import { Button, Notification } from 'Components';
import { useTranslation } from 'react-i18next';
import { GET_GROUP_NAMES_TEMPLATE } from 'Services/Api/Groups/Queries';
import { GetGroupNamesTemplateResponse } from 'Services/Api/Groups/Types';
import { downloadLink } from 'Helpers/downloadLink';

const DownloadGroupsTemplateButton: React.FC = (props) => {
	const { t } = useTranslation();
	const [getTemplate, result] = useLazyQuery<GetGroupNamesTemplateResponse>(
		GET_GROUP_NAMES_TEMPLATE,
		{
			onCompleted: (data) => {
				downloadLink(data.getGroupNamesTemplate.downloadUrl);
				Notification.success({
					description: t('notification.downloadFile.success')
				});
			},
			onError: (error) => {
				Notification.error({
					description: error.message
				});
			}
		}
	);

	const onDownload = () => {
		getTemplate();
	};

	return (
		<Button
			{...props}
			thin
			type="text"
			link
			onClick={onDownload}
			loading={result.loading}
		/>
	);
};

export default DownloadGroupsTemplateButton;
