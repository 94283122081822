import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
	query GetCurrentUser {
		getCurrentUser {
			peakV3Id
			firstName
			lastName
			email
			cognitoLogin
			phoneNumber
			active
			deleted
			doNotUpdateOnAutoFeeds
			isEmailValidated
			isConsentGiven
			twoFactorAuthorizationStatus
			userPool {
				userPoolId
				zeeNumber
				systemId
			}
			primarySystemRole {
				id
				role
				isPrimary
				twoFactorAuthorizationEnabled
				permissions {
					id
					code
				}
			}
			secondarySystemRoles {
				id
				role
				isPrimary
				permissions {
					id
					code
				}
			}
			groups {
				id
				name
				moduleSettings {
					moduleEnabled
				}
			}
			language {
				id
				name
			}
			timezone {
				id
				zone
			}
			ownershipGroup {
				id
				name
			}
			moduleSettings {
				jobClass {
					id
					peakV3Id
					name
				}
				userRole {
					id
					role
				}
			}
			notifications {
				day
				getWeeklyReport
				getDeletionReport
				time
			}
		}
	}
`;
