export enum LanguageI18nKey {
	English = 'en',
	French = 'fr',
	Spanish = 'es'
}

export interface GetTranslationRequest {
	code: LanguageI18nKey;
}

export type GetTranslationResponse = Record<
	string,
	string | Record<string, string>
>;

export type TenantUserPoolType = {
	userPoolId: string;
	cognitoClientId: string;
	postfix: string;
};

export type UserPoolsResponse = TenantUserPoolType[];
