import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { getAppRoutes } from 'Pages/App/App.constants';
import PrivateApp from 'Pages/App/PrivateApp';
import { AuthUser } from 'Services/Api/Auth/Types';
import { Redirect } from 'react-router';
import AuthLayout from '../AuthLayout';
import LoginFormWrap, { LoginProps } from './LoginForm';

type CognitoUserState = AuthUser | null;

function Login({ firstTimeSignIn }: LoginProps) {
	const [cognitoUser, setCognitoUser] = useState<CognitoUserState>(null);

	const [
		shouldMoveToTermsAndConditions,
		setShouldMoveToTermsAndConditions
	] = useState(false);

	return (
		<PrivateApp>
			{(isAuthenticated) => (
				<>
					{isAuthenticated ? (
						<Redirect to={getAppRoutes().HOME} />
					) : (
						<AuthLayout>
							<LoginFormWrap
								firstTimeSignIn={firstTimeSignIn}
								cognitoUser={cognitoUser as AuthUser}
								setCognitoUser={setCognitoUser}
								setShouldMoveToTermsAndConditions={
									setShouldMoveToTermsAndConditions
								}
								shouldMoveToTermsAndConditions={
									shouldMoveToTermsAndConditions
								}
							/>
						</AuthLayout>
					)}
				</>
			)}
		</PrivateApp>
	);
}

export default observer(Login);
