import { DownloadUrl, MutationRequest } from 'app-types';
import { PermissionCode } from '../Users/Types';

export enum SystemRoleType {
	User = 'user',
	Admin = 'admin',
	SuperAdmin = 'superadmin',
	Manager = 'manager',
	SecurityManager = 'securitymanager',
	Owner = 'owner'
}

export interface SystemRole {
	id: number;
	role: string;
	usersCount: number;
	permissionsCount: number;
	createdDate: string;
}

export interface GetSystemRolesRequest {
	sort: string;
	filters: Partial<{
		role: string;
		usersCount: number;
		permissionsCount: number;
		createdDate: string;
	}>;
}
export interface GetSystemRolesResponse {
	getSystemRoles: SystemRole[];
}

export type DeleteRolesRequest = MutationRequest<{ ids: string[] }>;

export type CloneRoleRequest = MutationRequest<{
	id: number;
	role: string;
}>;

export interface GetSystemRolesList {
	getSystemRolesList: (SystemRolesListItem & {
		isPrimary: boolean;
	})[];
}

export interface SystemRolesListItem {
	id: number;
	role: SystemRoleType | string;
	isPrimary: boolean;
	permissions: Permission[];
	twoFactorAuthorizationEnabled: boolean;
}

export interface SystemRoleDetails {
	id: number;
	role: SystemRoleType | string;
	createdDate: string;
	isPrimary: boolean;
	twoFactorAuthorizationEnabled: boolean;
	permissions: Permission[];
}

export interface GetPermissionsResponse {
	getPermissions: Permissions;
}

export interface Permissions {
	core: PermissionGroup[];
	lms: PermissionGroup[];
}

export interface PermissionGroup {
	id: number;
	name: PermissionGroupName;
	permissions: Permission[];
}

export enum PermissionGroupName {
	GroupsManagement = 'groups_management',
	OwnershipGroupsManagement = 'ownership_groups_management',
	SystemRolesManagement = 'system_roles_management',
	UsersManagement = 'users_management',
	DashboardManagement = 'dashboard_management',
	ReportsManagement = 'reports_management',
	ForceSyncManagement = 'force_sync_management',
	SystemsManagement = 'system_management'
}

export interface Permission {
	id: number;
	code: PermissionCode;
}

export interface GetSystemRoleDetailsRequest {
	id: number;
}

export interface GetSystemRoleDetailsResponse {
	getSystemRole: SystemRoleDetails;
}

export type AddSystemRoleRequest = MutationRequest<{
	isPrimary: boolean;
	role: string;
	permissionIds: number[];
}>;

export type EditSystemRoleRequest = MutationRequest<{
	id: number;
	role: string;
	permissionIds: number[];
}>;

export type ExportSystemRolesRequest = Pick<
	GetSystemRolesRequest,
	'sort' | 'filters'
>;

export interface ExportSystemRolesResponse {
	exportSystemRoles: DownloadUrl;
}

export interface GetBaseSystemRolesResponse {
	getBaseSystemRoles: SystemRolesListItem[];
}

export type EnableMFAForUserRoleRequest = MutationRequest<{
	roleId: number;
}>;
