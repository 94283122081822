import { reduce } from 'lodash';
import {
	AddSystemRoleRequest,
	EditSystemRoleRequest,
	PermissionGroup,
	Permissions,
	SystemRoleDetails
} from 'Services/Api/Roles/Types';
import { AddRoleFormValues, EditRoleFormValues } from './EditRole.types';

type PermissionCheckedById = Record<number, boolean>;

const getPermissionsFieldValue = (
	permissions: PermissionGroup[],
	checkedById: PermissionCheckedById
) => {
	return permissions.reduce<EditRoleFormValues['permissionIds']>(
		(acc, { permissions }) => {
			permissions.forEach((perm) => {
				acc[perm.id] = Boolean(checkedById[perm.id]);
			});
			return acc;
		},
		{}
	);
};
const getIsRole = (role: SystemRoleDetails | null): role is SystemRoleDetails =>
	role !== null;
export function getInitialValues(
	role: SystemRoleDetails,
	permissions: Permissions | undefined
): EditRoleFormValues;
export function getInitialValues(
	role: null,
	permissions: Permissions | undefined
): AddRoleFormValues;
export function getInitialValues(
	role: SystemRoleDetails | null,
	permissions: Permissions | undefined
) {
	const data = {
		core: permissions?.core || [],
		lms: permissions?.lms || []
	};
	const list = [...data.core, ...data.lms];

	if (getIsRole(role)) {
		const checkedById = role.permissions.reduce<PermissionCheckedById>(
			(acc, value) => {
				acc[value.id] = true;
				return acc;
			},
			{}
		);
		return {
			id: role.id,
			role: role.role,
			isPrimary: role.isPrimary,
			twoFactorAuthorizationEnabled: role.twoFactorAuthorizationEnabled,
			permissionIds: getPermissionsFieldValue(list, checkedById),
			data
		};
	}

	return {
		role: '',
		isPrimary: false,
		twoFactorAuthorizationEnabled: false,
		permissionIds: getPermissionsFieldValue(list, {}),
		data
	};
}

const getIsSubmitValuesExisting = (
	values: AddRoleFormValues | EditRoleFormValues
): values is EditRoleFormValues => Boolean((values as EditRoleFormValues).id);
export function getSubmitValues<T = AddSystemRoleRequest['input']>(
	values: AddRoleFormValues
): T;
export function getSubmitValues<T = EditSystemRoleRequest['input']>(
	values: EditRoleFormValues
): T;
export function getSubmitValues(
	values: AddRoleFormValues | EditRoleFormValues
) {
	const permissionIds = reduce<typeof values.permissionIds, number[]>(
		values.permissionIds,
		(acc, value, key) => {
			if (value) {
				acc.push(Number(key));
			}
			return acc;
		},
		[]
	);
	if (getIsSubmitValuesExisting(values)) {
		return {
			id: values.id,
			role: values.role,
			twoFactorAuthorizationEnabled:
				values.twoFactorAuthorizationEnabled ?? false,
			permissionIds
		};
	}
	return {
		role: values.role,
		isPrimary: values.isPrimary,
		twoFactorAuthorizationEnabled:
			values.twoFactorAuthorizationEnabled ?? false,
		permissionIds
	};
}
