import { gql } from '@apollo/client';

export const TOGGLE_USER_STATUS = gql`
	mutation UsersActivation($input: UsersActivation) {
		usersActivation(input: $input) {
			peakV3Ids
			shouldLogout
		}
	}
`;

export const DELETE_USER = gql`
	mutation UsersSoftDeletion($input: usersSoftDeletion) {
		usersSoftDeletion(input: $input) {
			peakV3Ids
			shouldLogout
		}
	}
`;

export const CREATE_USER = gql`
	mutation CreateUser($input: UserCreate!) {
		createUser(input: $input) {
			email
		}
	}
`;

export const UPDATE_USER = gql`
	mutation UpdateUser($input: UserUpdate!) {
		updateUser(input: $input) {
			email
			phoneNumber
		}
	}
`;

export const VALIDATE_USER = gql`
	mutation ValidateUser($input: UserValidate!) {
		validateUser(input: $input) {
			isConsentGiven
			isEmailValidated
		}
	}
`;

export const VALIDATE_USER_EMAIL = gql`
	mutation ValidateUserEmail($input: UserValidateEmail!) {
		validateUserEmail(input: $input) {
			email
		}
	}
`;

export const VALIDATE_USER_EMAIL_TOKEN = gql`
	mutation ValidateUserEmailToken($input: ReqWithJWT!) {
		validateUserEmailToken(input: $input) {
			isEmailValidated
		}
	}
`;

export const MOVE_USERS = gql`
	mutation MoveUsers($input: MoveUsers!) {
		moveUsers(input: $input)
	}
`;

export const ADD_MULTIPLE_USERS = gql`
	mutation createMultipleUsers($input: MultipleUsersInput) {
		createMultipleUsers(input: $input)
	}
`;

export const RESTORE_USERS = gql`
	mutation RestoreUsers($input: restoreUsers!) {
		restoreUsers(input: $input)
	}
`;

export const ON_USERS_DISABLE = gql`
	subscription onUsersDisable($shouldLogout: Boolean) {
		onUsersDisable(shouldLogout: $shouldLogout) {
			peakV3Ids
			shouldLogout
			mfa
		}
	}
`;

export const RESET_PASSWORD = gql`
	mutation resetPassword($input: ResetPasswordInput!) {
		resetPassword(input: $input) {
			totalCount
		}
	}
`;

export const RESET_MFA = gql`
	mutation resetMFA($input: UserPeakV3Id) {
		resetMFA(input: $input) {
			peakV3Ids
			shouldLogout
			mfa
		}
	}
`;
