import cn from 'classnames';
import AntButton, { ButtonProps as AntButtonProps } from 'antd/lib/button';
import styles from './Button.module.scss';

export type ButtonProps = AntButtonProps & {
	link?: boolean;
	fullWidth?: boolean;
	thin?: boolean;
	thinVertical?: boolean;
	text?: boolean;
};
const Button: React.FC<ButtonProps> = ({
	link,
	fullWidth,
	thin,
	thinVertical,
	text,
	...props
}) => {
	return (
		<AntButton
			{...props}
			className={cn(styles.root, props.className, {
				[styles.link]: link,
				[styles.fullWidth]: fullWidth,
				[styles.thin]: thin,
				[styles.thinVertical]: thinVertical,
				[styles.text]: text
			})}
		/>
	);
};

export default Button;
