import { gql } from '@apollo/client';

export const GET_USER_ACTIVITY = gql`
	query getUsersActivity($id: Int, $isProperty: Boolean) {
		getUsersActivity(id: $id, isProperty: $isProperty) {
			total
			logged
			lockedOut
			created
			deactivated
			deleted
			duration
		}
	}
`;

export const EXPORT_USERS_ACTIVITY = gql`
	query exportUsersActivity(
		$id: Int
		$isProperty: Boolean
		$fileType: String!
		$downloadId: String
	) {
		exportUsersActivity(
			id: $id
			isProperty: $isProperty
			fileType: $fileType
			downloadId: $downloadId
		) {
			downloadUrl
		}
	}
`;
