import { useEffect } from 'react';
import { VisualizationTimePeriod } from '../Visualizations.types';
import Chart, { ChartHelpers } from '../Chart';

export interface PieValue<T = number> {
	x: string;
	timePeriod?: VisualizationTimePeriod;
	y: T;
}

interface PieChartPropsBase {
	data: PieValue<number | null>[];
}
interface PieChartProps {
	loading: boolean;
	formatFn?: (value: PieValue['y']) => string | null;
}

function PieChartContent({
	chart,
	data
}: PieChartPropsBase & ChartHelpers<'pie'>) {
	useEffect(() => {
		chart?.data(data);
		// eslint-disable-next-line
	}, [data]);

	return null;
}

function PieChart(props: PieChartProps & PieChartPropsBase) {
	return (
		<Chart
			type="pie"
			props={{ formatFn: props.formatFn }}
			loading={props.loading}
		>
			{(helpers) => <PieChartContent {...helpers} data={props.data} />}
		</Chart>
	);
}

export default PieChart;
