import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import { Alert, Button, Notification } from 'Components';
import { useStores } from 'Hooks/useStore';
import {
	AuthUser,
	CompleteNewPasswordRequest,
	CognitoResponseError
} from 'Services/Api/Auth/Types';
import NewPasswordFields from './NewPasswordFields/NewPasswordFields';
import { schema } from './NewPasswordFields/schema';
import { COGNITO_ERROR_MSG_TO_DESCRIPTION_MAP } from 'app-config';

type Values = CompleteNewPasswordRequest;

export interface SetShouldMoveToTermsAndConditions {
	// @NOTE: shouldMoveToTC -> shouldMoveToTermsAndConditions
	setShouldMoveToTermsAndConditions(shouldMoveToTC: boolean): void;
}

export interface ChangePasswordFormProps
	extends SetShouldMoveToTermsAndConditions {
	cognitoUser: AuthUser;
}

function ChangePasswordForm({
	cognitoUser,
	setShouldMoveToTermsAndConditions
}: ChangePasswordFormProps) {
	const { authStore } = useStores();

	const { t } = useTranslation();
	const initialValues: Values = {
		cognitoUser,
		password: '',
		confirmPassword: ''
	};

	const onSubmit = async (values: Values, helpers: FormikHelpers<Values>) => {
		helpers.setStatus('');
		try {
			await authStore.completeNewPassword(values);
			setShouldMoveToTermsAndConditions(true);
		} catch (e) {
			const error = e as CognitoResponseError;
			helpers.setSubmitting(false);

			const cognitoError =
				COGNITO_ERROR_MSG_TO_DESCRIPTION_MAP[error.message];

			if (cognitoError) {
				helpers.setStatus(t(cognitoError));
				return;
			}

			Notification.error({
				description: t('errorCodes.genericErrorMessage')
			});
		}
	};

	return (
		<>
			<h1>{t('auth.changePassword.title')}</h1>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validationSchema={schema}
			>
				{({ isValid, dirty, isSubmitting, status }) => (
					<Form>
						{status && (
							<Alert
								showIcon
								message=""
								description={status}
								type="error"
							/>
						)}
						<NewPasswordFields />

						<Button
							htmlType="submit"
							type="primary"
							shape="round"
							disabled={!(isValid && dirty) || isSubmitting}
							loading={isSubmitting}
							fullWidth
						>
							{t('auth.changePassword.submitText')}
						</Button>
					</Form>
				)}
			</Formik>
		</>
	);
}

export default ChangePasswordForm;
