import { useColumnProps as usePhishingColumnProps } from 'Pages/Dashboard/tabs/Phishing/GroupSummary/columns';
import { PreviewReportTypeData, ReportType } from 'Services/Api/Reports/Types';

type Data = PreviewReportTypeData<ReportType.PhishingGroupSummary>;
type DataItem = Data['data'][0];
export const usePhishingColumns = (data: Data) => {
	const colProps = usePhishingColumnProps();
	if (data.period.to) {
		return colProps.filter(
			(col) => (col.key as keyof DataItem) !== 'variance'
		);
	}
	return colProps;
};
