import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Modal, Notification } from 'Components';
import { useModal } from 'Hooks/useModal';
import { REMOVE_OWNERSHIP_GROUP_PROPERTIES } from 'Services/Api/Groups/Mutations';
import { RemoveOwnershipGroupPropertiesRequest } from 'Services/Api/Groups/Types';
import TInfoText from 'Contexts/TInfoText/TInfoText';

export interface RemovePropertiesModalProps {
	id: number | null;
	ids: number[];
	idsNames: string[];
	onSubmit: () => void;
}

function RemovePropertiesModal({
	onSubmit,
	id,
	ids,
	idsNames
}: RemovePropertiesModalProps) {
	const { t } = useTranslation();
	const { modal, hideModal } = useModal();
	const [removeOwnershipGroupProperties, result] = useMutation<
		unknown,
		RemoveOwnershipGroupPropertiesRequest
	>(REMOVE_OWNERSHIP_GROUP_PROPERTIES);

	const isSingleSelected = ids.length === 1;
	const onRemoveSubmit = async () => {
		if (!id) {
			return;
		}
		try {
			await removeOwnershipGroupProperties({
				variables: {
					id,
					groupIds: ids
				}
			});
			onSubmit();
			hideModal();
			const description = t(
				`ownershipGroups.ownershipGroup.ownershipGroupActionsModals.removeModal.successNotification.${
					isSingleSelected ? 'single' : 'multiple'
				}`
			);
			Notification.success({
				description
			});
		} catch (error) {
			hideModal();
		}
	};

	if (!modal?.name) {
		return null;
	}

	return (
		<Modal
			visible
			title={
				<TInfoText
					path={`ownershipGroups.ownershipGroup.ownershipGroupActionsModals.removeModal.title.${
						isSingleSelected ? 'single' : 'multiple'
					}`}
					params={{ name: idsNames[0] }}
				/>
			}
			okText={t('remove')}
			okButtonProps={{
				loading: result.loading
			}}
			onOk={onRemoveSubmit}
			onCancel={hideModal}
			maskClosable={false}
		>
			{t(
				`ownershipGroups.ownershipGroup.ownershipGroupActionsModals.removeModal.description.${
					isSingleSelected ? 'single' : 'multiple'
				}`
			)}
		</Modal>
	);
}

export default RemovePropertiesModal;
