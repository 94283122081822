import { Text } from 'Components';
import { isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';

interface DaysBeforeRemovalProps {
	days: number | null;
}
function DaysBeforeRemoval({ days }: DaysBeforeRemovalProps) {
	const { t } = useTranslation();
	if (!isNumber(days)) {
		return null;
	}
	const isLessThan1Day = days < 1;
	const count = isLessThan1Day ? 1 : days;
	return (
		<>
			<br />
			<Text color="default">
				{t('users.columns.daysBeforeRemoval.title')}:{' '}
				{isLessThan1Day && '< '}
				{count}{' '}
				{t(
					`users.columns.daysBeforeRemoval.${
						count === 1 ? 'single' : 'multiple'
					}`
				)}
			</Text>
		</>
	);
}

export default DaysBeforeRemoval;
