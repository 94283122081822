import { useState, useLayoutEffect, RefObject } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

const useSize = (target: RefObject<HTMLDivElement>) => {
	const [size, setSize] = useState<DOMRectReadOnly>();

	useLayoutEffect(() => {
		setSize(target?.current?.getBoundingClientRect());
	}, [target]);

	useResizeObserver(target, (entry) => setSize(entry.contentRect));
	return size;
};

export default useSize;
