import { Button, Fx, Icon, Layout } from 'Components';
import Icons from 'Components/Icon/Icons';
import styles from './Styleguide.module.scss';

const Example: React.FC<{ heading?: string }> = ({ heading, children }) => {
	return (
		<div style={{ marginBottom: '20px', padding: '10px' }}>
			<h2>{heading}</h2>
			<div style={{ height: '200px', overflowY: 'auto' }}>{children}</div>
		</div>
	);
};

function Styleguide() {
	return (
		<div>
			<hr />
			<h1>Buttons</h1>
			<Example heading="Type">
				<Fx>
					<div>
						<Button type="primary" shape="round">
							Sign In
						</Button>
						<br />
						<br />
						<Button type="primary" shape="round" disabled>
							Sign In
						</Button>
					</div>
					<div>
						<Button type="primary" shape="round" ghost>
							Sign In
						</Button>
						<br />
						<br />
						<Button type="primary" shape="round" ghost disabled>
							Sign In
						</Button>
					</div>
					<div>
						<Button type="default" shape="round" ghost>
							Sign In
						</Button>
						<br />
						<br />
						<Button type="default" shape="round" ghost disabled>
							Sign In
						</Button>
					</div>
				</Fx>
			</Example>
			<Example heading="Size">
				<Fx>
					<div>
						<Fx>
							<Button type="primary" shape="round" size="small">
								Small
							</Button>
							&nbsp;
							<Button type="primary" shape="round" size="small">
								<Icon type="more" />
							</Button>
							&nbsp;
							<Button
								type="primary"
								shape="round"
								size="small"
								ghost
							>
								<Icon type="more" />
							</Button>
						</Fx>
						<br />
						<br />
						<Fx>
							<Button type="primary" shape="round">
								Middle
							</Button>
							&nbsp;
							<Button type="primary" shape="round">
								<Icon type="more" />
							</Button>
							&nbsp;
							<Button type="primary" shape="round" ghost>
								<Icon type="more" />
							</Button>
						</Fx>
					</div>
				</Fx>
			</Example>
			<hr />
			<h1>Layout</h1>
			<Example heading="Example 1">
				<Layout>
					<Layout.Body>
						<Layout.Content>
							<Layout.List>
								<Layout.List.ListItem title="Title 1">
									lorem ipsum
								</Layout.List.ListItem>
								<Layout.List.ListItem title="Title 2">
									lorem ipsum
								</Layout.List.ListItem>
							</Layout.List>
						</Layout.Content>
					</Layout.Body>
				</Layout>
			</Example>
			<Example heading="Example 2">
				<Layout>
					<Layout.Body>
						<Layout.Content>
							<Layout.List>
								<Layout.List.ListItem title="Title 1">
									<Layout.List.Section title="Section 1">
										lorem ipsum
									</Layout.List.Section>
									<Layout.List.Section title="Section 2">
										lorem ipsum
									</Layout.List.Section>
								</Layout.List.ListItem>
								<Layout.List.ListItem title="Title 2" stacked>
									<Layout.List.Section title="Section 1">
										lorem ipsum
									</Layout.List.Section>
									<Layout.List.Section title="Section 2">
										lorem ipsum
									</Layout.List.Section>
								</Layout.List.ListItem>
							</Layout.List>
						</Layout.Content>
					</Layout.Body>
				</Layout>
			</Example>
			<hr />
			<h1>Icons</h1>
			<div className={styles.icons}>
				{Object.keys(Icons).map((icon) => (
					<div className={styles.iconBox}>
						<div key={icon} className={styles.icon}>
							<Icon type={icon as keyof typeof Icons} />
						</div>
						<p>{icon}</p>
					</div>
				))}
			</div>
		</div>
	);
}

export default Styleguide;
