import { ApolloError } from '@apollo/client';
import { AxiosError } from 'axios';
import { PermissionProps } from 'Components/Permission/Permission';
import { TOptions } from 'i18next';
import React from 'react';

export enum Status {
	Idle = 'idle',
	Pending = 'pending',
	Success = 'success',
	Failure = 'failure'
}

// eslint-disable-next-line
export interface MutationRequest<T extends Record<any, any>> {
	input: T;
}

export interface Entity<K = number> {
	id: K;
	name: string;
}

export type LabelValueItem<K = number> = {
	key: K;
	value: string | number;
	label?: string;
};

export type FieldLabeledValueWithData<T, K = number> = LabelValueItem<K> & {
	data: T;
};

export enum APIErrorCodes {
	AccountDeactivated = 'ACCOUNT_DEACTIVATED',
	GroupHierarchyDepthLImitExceeded = 'GROUP_HIERARCHY_DEPTH_LIMIT_EXCEEDED',
	DeletePeakV2SystemRole = 'DELETE_PEAK_V2_SYSTEM_ROLE',
	DeleteSystemRoleWithUsers = 'DELETE_SYSTEM_ROLE_WITH_USERS',
	DeleteGroupWithUsers = 'DELETE_GROUP_WITH_USERS',
	GroupsNotInHierarchy = 'GROUPS_NOT_IN_HIERARCHY',
	MoveGroupToDescendant = 'MOVE_GROUP_TO_DESCENDANT',
	GroupsParentDeleted = 'GROUPS_PARENT_DELETED',
	RoleCannotBeDeleted = 'ROLE_CANNOT_BE_DELETED',
	RoleWithUsersCannotBeDeleted = 'ROLE_WITH_USERS_CANNOT_BE_DELETED',
	RoleWithDeletedUsersCannotBeDeleted = 'ROLE_WITH_DELETED_USERS_CANNOT_BE_DELETED',
	OwnershipGroupWithUsersCannotBeDeleted = 'OWNERSHIP_GROUP_WITH_USERS_CANNOT_BE_DELETED',
	RestoreUsersWithInvalidGroup = 'RESTORE_USERS_WITH_INVALID_GROUP',
	UserGroupInTheSameHierarchy = 'USER_GROUPS_SHOULD_BE_IN_SAME_HIERARCHY',
	GroupCannotBeProperty = 'GROUP_CAN_NOT_BE_PROPERTY',
	GroupNameShouldBeUniqueAtLevel = 'GROUP_NAME_SHOULD_BE_UNIQUE_FOR_LEVEL_IN_TENANT',
	GroupCannotBeRestoredAsProperty = 'GROUP_CAN_NOT_BE_RESTORED_AS_PROPERTY',
	GroupsHierarchySizeLimitExceeded = 'GROUP_HIERARCHY_SIZE_LIMIT_EXCEEDED',
	Internal = 'INTERNAL',
	Forbidden = 'FORBIDDEN',
	NotFound = 'NOT_FOUND',
	UnprocessableEntity = 'UNPROCESSABLE_ENTITY',
	ValidationIsCognitoPhoneNumber = 'VALIDATION_IS_COGNITO_PHONE_NUMBER',
	IsEmail = 'VALIDATION_IS_EMAIL',
	IsAlphanumericWithSpecialChars = 'VALIDATION_IS_ALPHANUMERIC_WITH_SPECIAL_CHARS',
	IsEmailNotContainsSpecialChars = 'VALIDATION_IS_EMAIL_NOT_CONTAINS_SPECIAL_CHARS',
	IsName = 'VALIDATION_IS_NAME',
	NotContainsSpaces = 'VALIDATION_NOT_CONTAINS_SPACES',
	NotContainsTrailingSpaces = 'VALIDATION_NOT_CONTAINS_TRAILING_SPACES',
	MinLength = 'VALIDATION_MIN_LENGTH',
	MaxLength = 'VALIDATION_MAX_LENGTH',
	IsString = 'VALIDATION_IS_STRING',
	IsInt = 'VALIDATION_IS_INT',
	IsE164PhoneNumber = 'VALIDATION_IS_E164_PHONE_NUMBER',
	Unique = 'VALIDATION_UNIQUE',
	ArrayMaxSize = 'VALIDATION_ARRAY_MAX_SIZE',
	IsUUID = 'VALIDATION_IS_UUID',
	IsBool = 'VALIDATION_IS_BOOL',
	IsEnum = 'VALIDATION_IS_ENUM',
	Min = 'VALIDATION_MIN',
	Max = 'VALIDATION_MAX',
	NoAvailableProperties = 'NO_AVAILABLE_PROPERTIES',
	MovingBetweenTenantsForbidden = 'USER_GROUPS_MOVING_BETWEEN_TENANT_IS_FORBIDDEN',
	MovingGroupsBetweenTenantsForbidden = 'GROUP_MOVING_BETWEEN_TENANT_IS_FORBIDDEN',
	UserNameShouldBeUniquePerUserPool = 'USERNAME_ALREADY_EXISTS_IN_USER_POOL',
	ValidationJWT = 'VALIDATION_JWT',
	PeakV3IDMustBeaUUID = 'peakV3Id must be a UUID',
	NoDataForReportGeneration = 'NO_DATA_FOR_REPORT_GENERATION',
	GroupSyncCodeShouldBeUniqPerTenant = 'GROUP_SYNC_CODE_SHOULD_BE_UNIQUE_PER_TENANT',
	TokenIsNotValid = 'token is not valid',
	TokenExpired = 'TOKEN_EXPIRED',
	EmailsIsNotEqualToUserEmail = 'EMAIL_IS_NOT_EQUAL_TO_USER_EMAIL',
	RoleIsNotAvailableForUser = 'ROLE_IS_NOT_AVAILABLE_FOR_USER'
}

export enum APIWarningCodes {
	InactiveGroupOrHierarchy = 'INACTIVE_GROUP_OR_HIERARCHY'
}

//  describe in details errors
export enum APIErrorMessage {
	NoGroupsAreAssignedToTheUser = 'NO_GROUPS_ARE_ASSIGNED_TO_THE_USER'
}

export enum APIAuthenticationError {
	NoCurrentUser = 'No current user'
}

export enum FEErrorCodes {
	UniqError = 'UniqError',
	MultipleUniqError = 'MultipleUniqError'
}

export interface DownloadUrl {
	downloadUrl: string;
}

export type ApolloErrorType<T = GraphQlErrorInfoType> = Omit<
	ApolloError,
	'graphQLErrors'
> & {
	graphQLErrors: [GraphQLError<T>];
};

export interface GraphQLError<T = GraphQlErrorInfoType> {
	errorInfo?: T;
	errorType: string;
	message: string;
}

type GraphQlErrorInfoType =
	| GraphQlErrorInfoTypeMultiple
	| GraphQlErrorInfoTypeByIndex
	| GraphQlErrorInfoTypeSingle;
export type GraphQlErrorInfoTypeByIndex = Record<number, ErrorInfo[]>;
export type GraphQlErrorInfoTypeMultiple = ErrorInfo[];
export type GraphQlErrorInfoTypeSingle = ErrorInfo;

export type ResponseErrorType =
	| APIErrorCodes
	| ErrorInfo
	| ErrorInfo[]
	| Record<number, ErrorInfo[]>;

export interface ErrorInfo {
	errorCode: APIErrorCodes;
	fieldName?: string;
	// eslint-disable-next-line
	meta: null | Record<string, any>;
}
export interface PaginatedRequest {
	page: number;
	itemsPerPage: number;
}

export interface SheetData {
	v: string;
}

export type Replace<T, U> = Omit<T, keyof Required<U>> & U;

export type TabItem<K> = {
	key: K;
	tab: string;
	permission?: Omit<PermissionProps, 'children'>;
};

export type MenuItem<K, T = unknown> = T & {
	key?: K;
	title: string;
	icon?: React.ReactNode;
	permission?: Omit<PermissionProps, 'children'>;
	children?: (MenuItem<K> & T)[];
};

export type AxiosErrorResponse = AxiosError<{
	error: ResponseErrorType;
	message?: APIErrorMessage;
}>;

export enum ErrorStatusCode {
	Unauthorized = 401,
	NotFound = 404,
	UnprocessableEntity = 422
}

export interface TInfo {
	key: string;
	params: TOptions;
}
