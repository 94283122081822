import { Layout, Tabs } from 'Components';
import { usePermission } from 'Components/Permission/Permission.hooks';
import { useErrorBoundary } from 'Hooks';
import { getAppRoutes } from 'Pages/App/App.constants';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PermissionCode } from 'Services/Api/Users/Types';
import styles from './Reports.module.scss';
import Saved from './Saved/Saved';
import Standard from './Standard/Standard';

function getTabs() {
	const appRoutes = getAppRoutes();
	return [
		{
			tab: 'reports.tabs.standard.title',
			component: Standard,
			permission: {
				code: PermissionCode.ViewStandardReports
			},
			pathname: appRoutes.REPORTS
		},
		{
			tab: 'reports.tabs.saved.title',
			component: Saved,
			permission: {
				code: PermissionCode.ViewStandardReports
			},
			pathname: appRoutes.SAVED_REPORTS
		}
	];
}

function Reports() {
	const { t } = useTranslation();
	const history = useHistory();
	const errorBoundary = useErrorBoundary();
	const { getHasPermission } = usePermission();
	const TABS = useMemo(() => getTabs(), []);
	const tabs = TABS.filter(
		(tab) => !tab.permission || getHasPermission(tab.permission).permitted
	);

	const initialActiveTab = tabs.find(
		(tab) => tab.pathname === history.location.pathname
	)?.tab;
	const [activeTab, setActiveTab] = useState<string | undefined>(
		initialActiveTab
	);

	const handleChange = (value: typeof activeTab) => {
		const tab = tabs.find((tab) => tab.tab === value);
		if (tab) {
			setActiveTab(tab.tab);
			history.push(tab.pathname);
		}
	};

	useEffect(() => {
		if (!tabs.length) {
			errorBoundary.setStatus('forbidden');
		}
		// eslint-disable-next-line
	}, []);

	if (!tabs.length) return null;

	return (
		<Layout>
			<Layout.Heading>
				<h1>{t('reports.title')}</h1>
			</Layout.Heading>
			<Layout className={styles.content}>
				<Tabs
					activeKey={activeTab}
					onChange={handleChange}
					destroyInactiveTabPane
				>
					{tabs.map(({ tab, component: Component }) => (
						<Tabs.TabPane tab={t(tab)} key={tab}>
							<Component />
						</Tabs.TabPane>
					))}
				</Tabs>
			</Layout>
		</Layout>
	);
}

export default Reports;
