import { SchemaFieldDescription, SchemaDescription } from 'yup/lib/schema';

function useInjectMeta<InjectMetaParams extends Record<string, unknown>>() {
	function getInjector(params: Record<string, InjectMetaParams>) {
		function injectMeta(fields: Record<string, SchemaFieldDescription>) {
			Object.keys(params).forEach((key) => {
				if (fields[key]) {
					(fields[key] as SchemaDescription).meta = params[key];
				}
			});

			return fields;
		}
		return injectMeta;
	}

	return {
		getInjector
	};
}

export default useInjectMeta;
