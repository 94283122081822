import { useState, useMemo, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { APP_CONFIG } from 'app-config';
import { useStores } from 'Hooks/useStore';
import { ReportFormValues } from 'Pages/Report/EditReport/schema';
import { ReportDataByAllUsersReportType } from 'Services/Api/Reports/Types';
import { IDataSource, IPagination } from '../PreviewReportTable.types';
import { adaptPropsDataToDataSource } from '../../PreviewReport.helpers';
import { PreviewUser } from 'Services/Api/Users/Types';
import { getBaseAllUsersRequest } from '../PreviewReportTable.helpers';
import { getIsPending } from 'Stores/util';

export function useAllUsers(
	propsData: ReportDataByAllUsersReportType,
	// for the saved reports preview case
	reportName: string
) {
	const { reportStore, reportsStore } = useStores();
	const formikContext = useFormikContext<ReportFormValues>();
	const [allUsersState, setAllUsersState] = useState<IDataSource>({
		dataSource: adaptPropsDataToDataSource<PreviewUser>(propsData.data),
		totalCount: propsData.totalCount,
		pageNumber: APP_CONFIG.TABLE_DEFAULT_PAGINATION_PAGE,
		pageSize: APP_CONFIG.TABLE_PAGE_SIZE
	});
	const formikValues = formikContext?.values ?? null;
	const getAllUsersParams = useMemo(
		() => {
			if (formikValues) {
				return getBaseAllUsersRequest(formikValues);
			}

			const report = reportsStore.getReportByName(reportName);

			if (report) {
				return (paginationParams: IPagination) =>
					Object.assign(
						{},
						{ reportId: report.id },
						paginationParams
					);
			}
		},
		// eslint-disable-next-line
		[formikValues]
	);
	const onPaginationChange = useCallback(
		async (pageNumber?: number, pageSize?: number) => {
			const definedPageNumber =
				pageNumber ?? APP_CONFIG.TABLE_DEFAULT_PAGINATION_PAGE;
			const definedPageSize = pageSize ?? APP_CONFIG.TABLE_PAGE_SIZE;
			const params = getAllUsersParams({
				pageSize,
				pageNumber
			});
			const response = await reportStore.previewReport(params);
			const responseData = (response.data as unknown) as ReportDataByAllUsersReportType;
			setAllUsersState(() => ({
				pageSize: definedPageSize,
				pageNumber: definedPageNumber,
				dataSource: adaptPropsDataToDataSource<PreviewUser>(
					responseData.data as PreviewUser[]
				),
				totalCount: responseData.totalCount
			}));
		},
		// eslint-disable-next-line
		[]
	);

	return {
		...allUsersState,
		dataLoading: getIsPending(reportStore.status.previewReport),
		onPaginationChange
	};
}
