import { gql } from '@apollo/client';

export const GET_AUDIT_LOGS = gql`
	query getAuditLogs(
		$page: Int
		$itemsPerPage: Int
		$search: String
		$sort: String
		$filters: AuditLogFilters
	) {
		getAuditLogs(
			page: $page
			itemsPerPage: $itemsPerPage
			search: $search
			sort: $sort
			filters: $filters
		) {
			totalCount
			data {
				id
				tenant
				login
				ip
				date
				page
				action
				browser
				modifyBy
			}
		}
	}
`;

export const EXPORT_AUDIT_LOGS = gql`
	query exportAuditLogs(
		$search: String
		$sort: String
		$filters: AuditLogFilters
		$downloadId: String
	) {
		exportAuditLogs(
			search: $search
			sort: $sort
			filters: $filters
			downloadId: $downloadId
		) {
			downloadUrl
		}
	}
`;
