import {
	action,
	computed,
	makeObservable,
	observable,
	reaction,
	runInAction
} from 'mobx';
import { Auth, Amplify } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Status } from 'app-types';
import {
	AuthUser,
	ResetPasswordRequest,
	ResetPasswordSubmitRequest,
	SignInRequest,
	CompleteNewPasswordRequest,
	ChangePasswordRequest,
	CurrentUser,
	GetCurrentUserResponse,
	ResetExpiredUserRequest
} from 'Services/Api/Auth/Types';
import { apolloClient, axiosClient } from 'Services/Api/client';
import { GET_CURRENT_USER } from 'Services/Api/Auth/Queries';
import { flattenDeep } from 'lodash';
import { BroadcastChannel } from 'broadcast-channel';
import AUTH_API from 'Services/Api/Auth/Api';
import COMMON_API from 'Services/Api/Common/Api';
import {
	UserPoolsResponse,
	TenantUserPoolType
} from 'Services/Api/Common/Types';
import { mutateAppRoutes, APP_ROUTES } from 'Pages/App/App.constants';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import { TwoFactorAuthorizationStatus } from 'Services/Api/Users/Types';

const BROADCAST_CHANNEL_MESSAGE = {
	SIGNOUT: 'signOut'
};

const TWO_FACTOR_PREFIX = '2FA_';

class AuthStore {
	channel: BroadcastChannel | null = null;
	userPools: UserPoolsResponse = [];
	currentUserPoolData: TenantUserPoolType | undefined;
	definedRoutePostfix: null | string = null;
	prevPostfix = '';
	status = {
		checkAuth: Status.Idle,
		getCurrentUser: Status.Idle
	};
	needReloadPostfix = false;
	authSession: CognitoUserSession | null = null;
	currentUser: CurrentUser | null = null;
	idleLogout = false;
	userPoolsStatus = Status.Idle;
	twoFactorAuthorizationPassed = false;

	get isAuthSessionValid() {
		return this.authSession && this.authSession.isValid();
	}

	get isAuthenticated() {
		return Boolean(
			this.isAuthSessionValid &&
				this.currentUser?.isConsentGiven &&
				this.isTwoFactorAuthorizationPassed
		);
	}

	get isTwoFactorAuthorizationEnabled() {
		return Boolean(
			this.currentUser?.primarySystemRole?.twoFactorAuthorizationEnabled
		);
	}

	get isTwoFactorAuthorizationPassed() {
		if (process.env.NODE_ENV === 'development') return true;

		return this.isTwoFactorAuthorizationEnabled
			? this.currentUser?.twoFactorAuthorizationStatus ===
					TwoFactorAuthorizationStatus.Setup &&
					this.twoFactorAuthorizationPassed
			: this.currentUser?.primarySystemRole
					?.twoFactorAuthorizationEnabled === false;
	}

	get isTwoFactorAuthorizationRequired() {
		return (
			this.isAuthSessionValid &&
			this.isTwoFactorAuthorizationEnabled &&
			this.currentUser?.twoFactorAuthorizationStatus !==
				TwoFactorAuthorizationStatus.Blocked
		);
	}

	get isUsersPoolDefined() {
		return this.currentUserPoolData !== undefined;
	}

	get currentUserPermissionCodes() {
		if (!this.currentUser) {
			return [];
		}
		const permissions = flattenDeep([
			this.currentUser.primarySystemRole.permissions,
			this.currentUser.secondarySystemRoles.map(
				(role) => role.permissions
			)
		]);
		return permissions.map((permission) => permission.code);
	}

	get isCurrentUserSuperAdmin() {
		return (
			this.currentUser?.primarySystemRole.role ===
			SystemRoleType.SuperAdmin
		);
	}

	get isCurrentUserOwner() {
		return (
			this.currentUser?.primarySystemRole.role === SystemRoleType.Owner
		);
	}

	constructor() {
		makeObservable(this, {
			status: observable,
			idleLogout: observable,
			currentUser: observable,
			authSession: observable,
			currentUserPoolData: observable,
			definedRoutePostfix: observable,
			needReloadPostfix: observable,
			prevPostfix: observable,
			userPoolsStatus: observable,
			twoFactorAuthorizationPassed: observable,
			isAuthenticated: computed,
			isUsersPoolDefined: computed,
			currentUserPermissionCodes: computed,
			isCurrentUserSuperAdmin: computed,
			isCurrentUserOwner: computed,
			isTwoFactorAuthorizationPassed: computed,
			isTwoFactorAuthorizationEnabled: computed,
			isAuthSessionValid: computed,
			isTwoFactorAuthorizationRequired: computed
		});

		reaction(
			() => this.isAuthenticated,
			(isAuthenticated) => {
				if (isAuthenticated && !this.channel) {
					this.channel = new BroadcastChannel('auth');
					this.channel.onmessage = (ev) => {
						if (ev === BROADCAST_CHANNEL_MESSAGE.SIGNOUT) {
							this.signOut(false);
						}
					};
				}
			}
		);
	}

	@action
	public async getUsersPools(location: string) {
		try {
			const url = new URL(location);
			const [postfix] = url.pathname.split('/').filter(Boolean);
			const { data } = await axiosClient.get<UserPoolsResponse>(
				COMMON_API.USERS_POOLS
			);

			this.userPools = data;

			const currentUserPool = data.find(
				(pools) => pools.postfix === postfix
			);

			this.setupUserPool(currentUserPool);
			runInAction(() => {
				this.userPoolsStatus = Status.Success;
			});
		} catch (error) {
			runInAction(() => {
				this.userPoolsStatus = Status.Failure;
			});
		}
	}

	@action
	public setupUserPool(currentUserPool: TenantUserPoolType | undefined) {
		let currentPoolId = process.env.REACT_APP_COGNITO_POOL_ID as string;
		let cognitoClientId = process.env.REACT_APP_COGNITO_CLIENT_ID as string;
		let locationOrigin = document.location.origin;
		if (currentUserPool) {
			mutateAppRoutes(currentUserPool.postfix);
			currentPoolId = currentUserPool.userPoolId;
			cognitoClientId = currentUserPool.cognitoClientId;
			locationOrigin = `${document.location.origin}/${currentUserPool.postfix}`;
		}

		Amplify.configure({
			Auth: {
				mandatorySignIn: true,
				region: process.env.REACT_APP_COGNITO_REGION,
				userPoolId: currentPoolId,
				userPoolWebClientId: cognitoClientId,
				storage: localStorage,
				oauth: {
					redirectSignIn: locationOrigin,
					redirectSignOut: locationOrigin,
					responseType: 'token',
					urlOpener: (url: string) => {
						if (this.idleLogout) {
							const w = window.open(url, '_blank');
							setTimeout(() => {
								if (w) {
									w.close();
								}
							}, 10);

							return;
						}
						const windowProxy = window.open(url, '_self');
						if (windowProxy) {
							return Promise.resolve(windowProxy);
						} else {
							return Promise.reject();
						}
					}
				}
			}
		});

		this.currentUserPoolData = currentUserPool ?? {
			userPoolId: currentPoolId,
			cognitoClientId: cognitoClientId,
			postfix: APP_ROUTES.HOME
		};
	}
	@action
	setIdleLogout(value: boolean) {
		this.idleLogout = value;
		this.status.getCurrentUser = Status.Idle;
	}

	@action
	async getAuthSession() {
		try {
			// This method will automatically refresh the accessToken and idToken
			// if tokens are expired and a valid refreshToken presented
			const authSession = await Auth.currentSession();
			runInAction(() => {
				this.authSession = authSession;
			});

			return authSession;
		} catch (e) {
			throw e;
		}
	}

	@action
	async checkAuth(skipStatusUpdate = false) {
		if (!skipStatusUpdate) {
			this.status.checkAuth = Status.Pending;
		}
		try {
			// This method will automatically refresh the accessToken and idToken
			// if tokens are expired and a valid refreshToken presented
			await this.getAuthSession();
			await this.getCurrentUser();
			runInAction(() => {
				this.status.checkAuth = Status.Success;
				const peakV3Id = this.currentUser?.peakV3Id;
				this.twoFactorAuthorizationPassed = Boolean(
					localStorage.getItem(TWO_FACTOR_PREFIX + peakV3Id)
				);
			});
		} catch (e) {
			runInAction(() => {
				this.status.checkAuth = Status.Failure;
			});
		}
	}

	@action
	async getCurrentUser() {
		runInAction(() => {
			this.status.getCurrentUser = Status.Pending;
		});
		try {
			const response = await apolloClient.query<GetCurrentUserResponse>({
				query: GET_CURRENT_USER,
				fetchPolicy: 'no-cache'
			});
			runInAction(() => {
				this.currentUser = response.data.getCurrentUser;
				this.status.getCurrentUser = Status.Success;

				if (
					this.currentUser.userPool.userPoolId !==
					this.currentUserPoolData?.userPoolId
				) {
					const currentUserPool = this.userPools.find(
						(pool) =>
							pool.userPoolId ===
							this.currentUser?.userPool.userPoolId
					);
					this.prevPostfix = this.currentUserPoolData?.postfix ?? '';
					this.needReloadPostfix = true;
					this.setupUserPool(currentUserPool);
				}
			});
		} catch (e) {
			runInAction(() => {
				this.status.getCurrentUser = Status.Failure;
			});
		}
	}

	@action
	async resetExpiredUser(params: ResetExpiredUserRequest) {
		await axiosClient.post(AUTH_API.RESET_EXPIRED_USER, params);
	}

	@action
	async signIn(data: SignInRequest): Promise<AuthUser> {
		try {
			const user = await Auth.signIn(data.login, data.password);
			return user;
		} catch (e) {
			throw e;
		}
	}

	@action
	public resetNeedReloadPostfix() {
		this.needReloadPostfix = false;
	}

	@action
	async resetPassword(data: ResetPasswordRequest) {
		await Auth.forgotPassword(data.login);
	}

	@action
	async resetPasswordSubmit(data: ResetPasswordSubmitRequest) {
		try {
			await Auth.forgotPasswordSubmit(
				data.login,
				data.securityCode,
				data.password
			);
		} catch (e) {
			throw e;
		}
	}

	@action
	async completeNewPassword(data: CompleteNewPasswordRequest) {
		try {
			await Auth.completeNewPassword(data.cognitoUser, data.password);
		} catch (e) {
			throw e;
		}
	}

	@action
	async changePassword(data: ChangePasswordRequest) {
		try {
			const cognitoUser = await Auth.currentAuthenticatedUser();
			await Auth.changePassword(
				cognitoUser,
				data.oldPassword,
				data.password
			);
		} catch (e) {
			throw e;
		}
	}

	@action
	async signOut(triggered = true) {
		const peakV3Id = this.currentUser?.peakV3Id;
		await Auth.signOut();
		runInAction(() => {
			this.authSession = null;
			this.currentUser = null;
			this.status.getCurrentUser = Status.Idle;
			this.twoFactorAuthorizationPassed = false;
		});
		localStorage.removeItem(TWO_FACTOR_PREFIX + peakV3Id);

		if (triggered) {
			this.channel?.postMessage(BROADCAST_CHANNEL_MESSAGE.SIGNOUT);
		}
	}

	@action
	setTwoFactorAuthorizationPassed() {
		this.twoFactorAuthorizationPassed = true;
		const peakV3Id = this.currentUser?.peakV3Id;
		localStorage.setItem(
			TWO_FACTOR_PREFIX + peakV3Id,
			new Date().toISOString()
		);
	}
}

export const authStore = new AuthStore();
export default AuthStore;
