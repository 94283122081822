import {
	LazyQueryHookOptions,
	QueryOptions,
	QueryLazyOptions,
	ApolloError
} from '@apollo/client';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { APP_CONFIG } from 'app-config';
import { Status } from 'app-types';
import { apolloClient } from 'Services/Api/client';
import { ReportFileType } from 'Services/Api/Reports/Types';
import { useStores } from './useStore';
import { useDownloadReportProgress } from './useDownloadReportProgress/useDownloadReportProgress';
import { Notification } from 'Components';

export type GetQueryUrl<TData> = (data: TData) => string;

interface FileTypeVariable {
	fileType?: ReportFileType;
}

function getApolloClientCancellableQuery() {
	const controller = new AbortController();
	async function apolloQuery<TData, TVariables>({
		query,
		options
	}: {
		query: string;
		options: Omit<QueryOptions<TVariables, TData>, 'query'>;
	}) {
		return await apolloClient.query<TData, TVariables>({
			query,
			fetchPolicy: 'network-only',
			context: {
				fetchOptions: {
					signal: controller.signal
				}
			},
			...options
		});
	}

	function cancelQuery() {
		controller.abort();
	}
	return {
		apolloQuery,
		cancelQuery
	};
}

export function useDownloadReportQuery<TData, TVariable>(
	query: string,
	options?: LazyQueryHookOptions,
	getQueryUrl?: GetQueryUrl<TData>
): [
	(params: QueryLazyOptions<TVariable>) => void,
	{ loading: boolean; data?: TData }
] {
	const [result, setResult] = useState<{ loading: boolean; data?: TData }>({
		loading: false
	});
	const { downloadReportsStore } = useStores();
	useDownloadReportProgress();
	const { cancelQuery, apolloQuery } = getApolloClientCancellableQuery();

	async function executor(params: QueryLazyOptions<TVariable>) {
		const downloadId = uuid();
		setResult({ loading: true });
		downloadReportsStore.addDocumentById(downloadId, {
			params: {
				downloadId,
				fileType:
					(params?.variables as FileTypeVariable)?.fileType ??
					ReportFileType.Csv
			},
			status: Status.Pending,
			statusEmailMeReport: Status.Idle
		});

		try {
			const timeoutId = setTimeout(() => {
				const info = downloadReportsStore.byDocumentId[downloadId];
				if (!info) return;
				cancelQuery();

				downloadReportsStore.addDocumentById(downloadId, {
					...info,
					status: Status.Failure
				});
				setResult((result) => ({
					...result,
					loading: false
				}));
			}, APP_CONFIG.DOWNLOAD_MAX_MS);

			const response = await apolloQuery<TData, TVariable>({
				query,
				options: {
					...params,
					// @ts-ignore
					variables: {
						...params.variables,
						downloadId
					}
				}
			});
			clearTimeout(timeoutId);
			options?.onCompleted?.(response);
			if (!downloadReportsStore.byDocumentId[downloadId]) return;
			const url = getQueryUrl?.(response.data);

			if (typeof url !== 'string') {
				throw Error('url is not a string');
			}

			downloadReportsStore.byDocumentId[downloadId] = {
				...downloadReportsStore.byDocumentId[downloadId],
				status: Status.Success,
				result: { url }
			};
		} catch (error) {
			options?.onError?.(error as ApolloError);
			Notification.close(downloadId);
			const info = downloadReportsStore.byDocumentId[downloadId];
			downloadReportsStore.addDocumentById(downloadId, {
				...info,
				status: Status.Failure
			});
		} finally {
			setResult((result) => ({
				...result,
				loading: false
			}));
		}
	}

	return [executor, result];
}
