import { useModal } from 'Hooks/useModal';
import {
	DeleteRoleModal,
	RolesActionsModalName,
	DeleteRoleModalProps,
	CloneRoleModal
} from './modals';

export type RolesActionsModalsProps = DeleteRoleModalProps;

function RolesActionsModals({
	ids,
	idsNames,
	onSubmit
}: RolesActionsModalsProps) {
	const { modal } = useModal();

	return (
		<>
			{modal?.name === RolesActionsModalName.Delete && (
				<DeleteRoleModal
					ids={ids}
					idsNames={idsNames}
					onSubmit={onSubmit}
				/>
			)}
			{modal?.name === RolesActionsModalName.Clone && (
				<CloneRoleModal
					roleId={ids[0]}
					roleName={idsNames[0]}
					onSubmit={onSubmit}
				/>
			)}
		</>
	);
}

export default RolesActionsModals;
