import { getUserRoleLabel } from 'app-util';
import i18n from 'i18n';
import { SystemRolesListItem } from 'Services/Api/Roles/Types';
import { GetUsersRequest, UserLoginStatus } from 'Services/Api/Users/Types';
import { checkApolloError } from 'Helpers/graphql';
import { APIErrorCodes } from 'app-types';
import { Notification } from 'Components';
import {
	UsersTableFilters,
	UsersTableFiltersValue,
	UsersTableParams
} from './Users.types';
import { isArray, isNil, omitBy } from 'lodash';

export function getFilters({
	systemRoles
}: {
	systemRoles: SystemRolesListItem[];
}): UsersTableFilters {
	const systemRolesList = systemRoles.map((systemRole) => ({
		text: getUserRoleLabel(systemRole.role),
		key: systemRole.role,
		value: systemRole.role
	}));

	return {
		systemRoles: systemRolesList
	};
}

export const toTableParams = (params: GetUsersRequest): UsersTableParams => {
	const loginStatus: UserLoginStatus[] = [];
	if (params?.filters.isActivationPending?.includes(true)) {
		loginStatus.push(UserLoginStatus.ActivationPending);
	}
	if (params?.filters.isLockedOut?.includes(true)) {
		loginStatus.push(UserLoginStatus.LockedOut);
	}

	return {
		...params,
		filters: {
			...params?.filters,
			loginStatus
		}
	};
};

export const fromFilterValues = (
	values: UsersTableFiltersValue
): GetUsersRequest['filters'] => {
	return {
		cognitoLogin: values.cognitoLogin?.[0] ?? '',
		firstName: values.firstName?.[0] ?? '',
		lastName: values.lastName?.[0] ?? '',
		email: values.email?.[0] ?? '',
		isActive: values.isActive,
		isLockedOut: values.loginStatus?.includes(UserLoginStatus.LockedOut)
			? [true]
			: undefined,
		isActivationPending: values.loginStatus?.includes(
			UserLoginStatus.ActivationPending
		)
			? [true]
			: undefined,
		systemRoles: values.systemRoles || undefined
	};
};

export function handleForbiddenError(
	e: unknown,
	translationsPath: string
): boolean {
	const apolloError = checkApolloError(e);
	if (apolloError.is(APIErrorCodes.Forbidden)) {
		const meta = apolloError.getMeta<string[]>();

		Notification.error({
			description: i18n.t(translationsPath, {
				users: Array.isArray(meta)
					? meta.map((user) => `"${user}"`).join(', ')
					: ''
			})
		});

		return true;
	}

	return false;
}

export const prepareParams = (params: GetUsersRequest) => {
	return {
		...params,
		// dont inlude unset values
		filters: omitBy(
			params.filters,
			(value) =>
				(isArray(value) && !value.length) ||
				value === '' ||
				isNil(value)
		)
	};
};
