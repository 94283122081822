import { ColumnProps } from 'antd/lib/table';
import { OptionalValue } from 'Components';
import { useColumnProps as useSpotCheckColumnProps } from 'Pages/Dashboard/tabs/SpotCheck/GroupSummary/columns';
import { useTranslation } from 'react-i18next';
import { PreviewReportTypeData, ReportType } from 'Services/Api/Reports/Types';

type Data = PreviewReportTypeData<ReportType.SpotcheckGroupSummary>;
type DataItem = Data['data'][0];
type DataItemKey = keyof DataItem;

const ADDITIONAL_PROPS: Partial<
	Record<DataItemKey, Partial<ColumnProps<DataItem>>>
> = {
	groupName: {
		width: undefined
	},
	last: {
		width: 100
	},
	current: {
		width: 100
	},
	variance: {
		width: 100
	}
};

export const useSpotCheckColumns = (data: Data) => {
	const { t } = useTranslation();
	let columns = useSpotCheckColumnProps();
	const isHistorical = Boolean(data.period.to);

	const omitKeys: DataItemKey[] = isHistorical ? ['variance', 'last'] : [];
	columns = columns.reduce<typeof columns[0][]>((acc, value) => {
		const key = value.key as DataItemKey;
		const shouldOmit = omitKeys.includes(key);
		if (!shouldOmit) {
			const additionalProps = ADDITIONAL_PROPS[key] ?? {};
			acc.push({
				...value,
				...additionalProps
			});
		}
		return acc;
	}, []);
	const extraColumns: ColumnProps<DataItem>[] = [
		{
			title: t('report.previewTable.spotCheck.participationScore'),
			key: 'participationScore',
			dataIndex: 'participationScore',
			width: 110,
			render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			title: t('report.previewTable.spotCheck.responseScore'),
			key: 'responseScore',
			dataIndex: 'responseScore',
			width: 110,
			render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			title: t('report.previewTable.spotCheck.overallScore'),
			key: 'overallScore',
			dataIndex: 'overallScore',
			width: 110,
			render: (value) => <OptionalValue>{value}</OptionalValue>
		}
	];
	return [...columns, ...extraColumns];
};
