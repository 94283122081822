import { useEffect } from 'react';
import { BarValue } from './BarChart.types';
import Chart, { ChartHelpers } from '../Chart';

interface BarChartProps {
	data: BarValue[];
	loading: boolean;
}
function BarChartContent({ chart, data }: BarChartProps & ChartHelpers<'bar'>) {
	useEffect(() => {
		chart?.data(data);
	}, [chart, data]);

	return null;
}

function BarChart(props: BarChartProps) {
	return (
		<Chart type="bar" loading={props.loading} width={880} height={305}>
			{(helpers) => <BarChartContent {...props} {...helpers} />}
		</Chart>
	);
}

export default BarChart;
