import { useTranslation } from 'react-i18next';
import { Modal } from 'Components';

import { useModal } from 'Hooks/useModal';
import {
	CONFIRM_MODAL_BY_ACTION,
	UserActionModalName
} from '../../Users.constants';
import { useUsersTableControls } from './UsersTableControls.hooks';
import MoveUsersModal from '../MoveUsersModal/MoveUsersModal';
import RestoreUsersModal from '../RestoreUsersModal/RestoreUsersModal';
import { User } from 'Services/Api/Users/Types';
import { useMemo } from 'react';
import TInfoText from 'Contexts/TInfoText/TInfoText';

export interface UsersTableControlsModalPropsBase {
	selectedRowKeys: string[];
	selectedUsers: Record<string, User>;
	onChange: () => void;
}

type UsersTableControlsModalProps = Pick<
	ReturnType<typeof useUsersTableControls>,
	'currentAction' | 'currentActionLoading' | 'onConfirm' | 'onCancel'
> &
	UsersTableControlsModalPropsBase;

const UsersTableControlsModal = ({
	selectedRowKeys,
	selectedUsers,
	currentActionLoading,
	currentAction,
	onConfirm,
	onCancel,
	onChange
}: UsersTableControlsModalProps) => {
	const { modal } = useModal();

	const { t } = useTranslation();

	const namesBySelectedRowKeys = useMemo(() => {
		return selectedRowKeys.reduce<string[]>((acc, value) => {
			const user = selectedUsers[value];
			acc.push(`${user.firstName} ${user.lastName}`);
			return acc;
		}, []);
	}, [selectedRowKeys, selectedUsers]);

	if (!modal) return null;

	const confirm = currentAction
		? CONFIRM_MODAL_BY_ACTION[currentAction]
		: null;
	const confirmModalTitle = confirm
		? confirm.title(namesBySelectedRowKeys)
		: null;
	return (
		<>
			{modal.name === UserActionModalName.Move && (
				<MoveUsersModal onSubmit={onChange} />
			)}
			{modal.name === UserActionModalName.Restore && (
				<RestoreUsersModal
					isMultiple={selectedRowKeys.length > 1}
					userFullName={namesBySelectedRowKeys[0]}
					onSubmit={onChange}
				/>
			)}
			{modal.name === UserActionModalName.Confirm && confirm && (
				<Modal
					title={
						confirmModalTitle ? (
							<TInfoText
								{...confirmModalTitle}
								path={confirmModalTitle.key}
							/>
						) : null
					}
					visible
					onOk={onConfirm}
					okButtonProps={{
						loading: currentActionLoading
					}}
					okText={t(confirm.okText)}
					cancelText={t('cancel')}
					onCancel={onCancel}
					maskClosable={false}
				>
					{t(confirm.description)}
				</Modal>
			)}
		</>
	);
};

export default UsersTableControlsModal;
