import { Card } from 'Components';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import {
	ControlSpace,
	InfoTooltip,
	OpenInNewTab
} from 'Pages/Dashboard/components';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import LineChart, {
	LineChartProps
} from 'Pages/Dashboard/visualizations/LineChart/LineChart';
import { LineTimeInterval } from 'Pages/Dashboard/visualizations/LineChart/LineChart.types';
import LineChartControls from 'Pages/Dashboard/visualizations/LineChart/LineChartControls';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIsPending } from 'Stores/util';
import { DashboardTabKey } from 'Services/Api/Dashboard/Types';
import {
	ElearningWidgets,
	ControlValues,
	ElearningWidgetParams
} from './Elearning.types';

const HistoricalCompletedCourses = () => {
	const { t } = useTranslation();

	const { filters } = useDashboardContext();
	const { dashboardStore } = useStores();
	const {
		elearning: { completedCourses },
		status
	} = dashboardStore;

	const [controlsValue, setControlsValue] = useState<ControlValues>({
		timeInterval: LineTimeInterval.Month,
		keys: [],
		checkedKeys: undefined
	});

	useEffect(() => {
		dashboardStore.getElearningCompletedCourses(filters);
		// eslint-disable-next-line
	}, [filters]);

	const onInit: LineChartProps['onInit'] = useCallback((value) => {
		const keys = value.rangesYears.map((d) => d.year()).sort();
		setControlsValue((currentValue) => ({
			...currentValue,
			keys,
			checkedKeys: currentValue.checkedKeys ?? keys
		}));
	}, []);

	const chartData = useMemo(
		() =>
			completedCourses
				? completedCourses.map((d) => ({
						x: d.date,
						y: d.value
				  }))
				: [],
		[completedCourses]
	);

	return (
		<Card
			title={t(
				'dashboard.tabs.elearning.historicalCompletedCourses.title'
			)}
			footer={
				<OpenInNewTab<ElearningWidgetParams>
					tab={DashboardTabKey.Elearning}
					widget={ElearningWidgets.HistoricalCompletedCourses}
					widgetParams={{
						checkedKeys: controlsValue.checkedKeys,
						timeInterval: controlsValue.timeInterval
					}}
				/>
			}
			extra={
				<ControlSpace>
					{Boolean(controlsValue.keys.length) && (
						<LineChartControls
							keys={controlsValue.keys}
							checkedKeys={controlsValue.checkedKeys}
							timeInterval={controlsValue.timeInterval}
							onCheckedKeysChange={(value) => {
								setControlsValue((currentValue) => ({
									...currentValue,
									checkedKeys: value
								}));
							}}
							onTimeIntervalChange={(value) => {
								setControlsValue((currentValue) => ({
									...currentValue,
									timeInterval: value
								}));
							}}
						/>
					)}
					<InfoTooltip
						title={t(
							'dashboard.tabs.elearning.historicalCompletedCourses.tooltip'
						)}
					/>
				</ControlSpace>
			}
		>
			<LineChart
				data={chartData}
				rangesYears={controlsValue.checkedKeys}
				loading={getIsPending(status.getElearningCompletedCourses)}
				timeInterval={controlsValue.timeInterval}
				onInit={onInit}
			/>
		</Card>
	);
};

export default observer(HistoricalCompletedCourses);
