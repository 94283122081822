import React from 'react';
import ReactDOM from 'react-dom';
import 'Styles/index';
import 'Services/yup';
import './i18n';
import App from 'Pages/App/App';
import { launchGainSightTag, launchTwak } from 'Services/Analitycs';

import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';

// @NOTE: Gainsight PX Tag -> service for manage user actions
launchGainSightTag();
launchTwak();

localStorage.setItem("greeting","0");
localStorage.setItem("initalpull","0");
localStorage.setItem("propertiescheck","0");

ReactDOM.render(<App />, document.getElementById('root'));

document.title = 'VENZA';
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();