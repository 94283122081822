import { DeleteOwnershipGroupModalProps } from './DeleteOwnershipGroupModal';
import { RemovePropertiesModalProps } from './RemovePropertiesModal';

export type OwnershipGroupActionsModalsProps = DeleteOwnershipGroupModalProps &
	RemovePropertiesModalProps;

export enum OwnershipGroupActionsModalName {
	Remove = 'Remove',
	Delete = 'Delete'
}
