import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { APIErrorCodes } from 'app-types';
import { Checkbox, Input, Button, Notification } from 'Components';
import { useStores } from 'Hooks/useStore';
import { VALIDATE_USER } from 'Services/Api/Users/Mutations';
import { PhoneNumber } from 'Pages/Auth/Auth.types';
import { getAppRoutes } from 'Pages/App/App.constants';
import { checkApolloError } from 'Helpers/graphql';
import { termsAndConditionsSchema } from '../schema';
import styles from '../../AuthLayout.module.scss';
import { components } from './AcceptTermsAndConditions.components';
import { SetShouldMoveToTermsAndConditions } from './ChangePasswordForm';
import { AuthUser } from 'Services/Api/Auth/Types';

type Values = PhoneNumber;

interface AcceptTermsAndConditionsProps
	extends SetShouldMoveToTermsAndConditions {
	setCognitoUser(user: AuthUser | null): void;
}

const AcceptTermsAndConditions = ({
	setShouldMoveToTermsAndConditions,
	setCognitoUser
}: AcceptTermsAndConditionsProps) => {
	const { t } = useTranslation();
	const [validateUser] = useMutation(VALIDATE_USER);
	const history = useHistory();
	const { authStore } = useStores();

	const initialValues = {};

	const onSubmit = async (values: Values, helpers: FormikHelpers<Values>) => {
		helpers.setStatus('');
		const appRoutes = getAppRoutes();
		try {
			await authStore.checkAuth(true);
			const { phoneNumber } = values;
			await validateUser({
				variables: {
					input: {
						phoneNumber: phoneNumber || null
					}
				}
			});
			if (!authStore.isTwoFactorAuthorizationEnabled) {
				Notification.success({
					description: t('auth.changePassword.notificationSuccess')
				});
			}

			history.push(appRoutes.HOME, { firstTimeSignIn: true });
		} catch (error) {
			helpers.setSubmitting(false);
			const apolloError = checkApolloError(error);

			if (apolloError.is(APIErrorCodes.ValidationIsCognitoPhoneNumber)) {
				helpers.setErrors({
					phoneNumber: t(
						`user.errorCodes.${APIErrorCodes.ValidationIsCognitoPhoneNumber}`
					)
				});
				return;
			}
			setShouldMoveToTermsAndConditions(false);
			setCognitoUser(null);
		}
	};

	return (
		<>
			<h1>{t('auth.login.termsAndConditions.title')}</h1>
			<p>{t('auth.login.termsAndConditions.description')}</p>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validationSchema={termsAndConditionsSchema}
			>
				{({ isValid, isSubmitting, dirty }) => (
					<Form>
						<Input.FormikField
							name="phoneNumber"
							label={t('auth.login.inputs.phoneNumber')+` (optional)`}
							className={styles.inputPhoneStyles}
						/>

						<h4 className={styles.firstLoginTitle}>
							{t('auth.login.consent.title')}
						</h4>
						<p>{t('auth.login.consent.text')}</p>

						<Checkbox.FormikField name="termsOfUse" help={null}>
							<Trans
								i18nKey={'auth.login.inputs.termsOfUse'}
								components={components}
							/>
							{`*`}
						</Checkbox.FormikField>
						<Checkbox.FormikField name="privacyPolicy" help={null}>
							<Trans
								i18nKey={'auth.login.inputs.privacyPolicy'}
								components={components}
							/>
							{`*`}
						</Checkbox.FormikField>
						<Button
							htmlType="submit"
							type="primary"
							shape="round"
							disabled={!(isValid && dirty) || isSubmitting}
							loading={isSubmitting}
							fullWidth
						>
							{t('auth.login.termsAndConditions.submitText')}
						</Button>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default AcceptTermsAndConditions;
