import { Notification } from 'Components';
import { toJS } from 'mobx';
import { useEffect } from 'react';
import ProgressInfo from './ProgressInfo';
import { useStores } from '../useStore';

export function useDownloadReportProgress() {
	const { downloadReportsStore } = useStores();
	// TODO useAsObservableSource
	const documents = toJS(downloadReportsStore.byDocumentId);

	useEffect(() => {
		const ids = Object.keys(documents);

		ids.forEach((id) => {
			Notification.open({
				key: id,
				placement: 'bottomRight',
				duration: 0,
				onClose: () => {
					downloadReportsStore.cancelGenerateReport({
						downloadId: id
					});
				},
				description: (
					<ProgressInfo
						downloadId={id}
						fileType={documents[id].params.fileType}
					/>
				)
			});
		});
		// eslint-disable-next-line
	}, [documents]);
}
