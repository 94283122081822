import { Stack } from 'Components';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';

import SpotCheckOverview from './SpotCheckOverview';
import ICI from './JobRoleSummary/JobRoleSummary';
import GroupSummary from './GroupSummary/GroupSummary';

function SpotCheck() {
	const { dashboardStore } = useStores();
	const { filters } = useDashboardContext();

	useEffect(() => {
		dashboardStore.getSpotCheckJobRoleSummary(filters);
		dashboardStore.getSpotCheckOverview(filters);
		// eslint-disable-next-line
	}, [filters]);

	return (
		<Stack>
			<SpotCheckOverview />
			<ICI />
			<GroupSummary />
		</Stack>
	);
}

export default observer(SpotCheck);
