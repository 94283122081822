import { Fx, Icon, Card } from 'Components';
import { InfoTooltip, Statistic } from 'Pages/Dashboard/components';
import { useTranslation } from 'react-i18next';
import styles from './Phishing.module.scss';
import { observer } from 'mobx-react-lite';
import { useStores } from 'Hooks/useStore';
import { useEffect } from 'react';
import { getIsPending } from 'Stores/util';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';

const TotalCampaignOverview = () => {
	const { t } = useTranslation();
	const { dashboardStore } = useStores();
	const { filters } = useDashboardContext();
	const {
		phishing: { overview },
		status
	} = dashboardStore;

	useEffect(() => {
		dashboardStore.getPhishingTotalCampaignOverview(filters);
		// eslint-disable-next-line
	}, [filters]);

	return (
		<Card
			loading={getIsPending(status.getPhishingTotalCampaignOverview)}
			title={t('dashboard.tabs.phishing.totalCampaignOverview.title')}
			extra={
				<Fx align="middle">
					<InfoTooltip
						title={t(
							'dashboard.tabs.phishing.totalCampaignOverview.tooltip'
						)}
					/>
				</Fx>
			}
		>
			<Fx fullHeight align="middle" justify="space-around">
				<Statistic
					vertical
					inheritColor
					icon={<Icon type="bell" />}
					value={overview?.campaigns}
					className={styles.totalCampaignOverviewStatistic}
				>
					{t(
						'dashboard.tabs.phishing.totalCampaignOverview.campaigns'
					)}
				</Statistic>
				<Statistic
					vertical
					inheritColor
					icon={<Icon type="envelope" />}
					value={overview?.sent}
					className={styles.totalCampaignOverviewStatistic}
				>
					{t('dashboard.tabs.phishing.totalCampaignOverview.sent')}
				</Statistic>
				<Statistic
					vertical
					inheritColor
					icon={<Icon type="newWindow" />}
					value={overview?.opened}
					className={styles.totalCampaignOverviewStatistic}
				>
					{t('dashboard.tabs.phishing.totalCampaignOverview.opened')}
				</Statistic>
				<Statistic
					vertical
					inheritColor
					icon={<Icon type="click" />}
					value={overview?.clicked}
					className={styles.totalCampaignOverviewStatistic}
				>
					{t('dashboard.tabs.phishing.totalCampaignOverview.clicked')}
				</Statistic>
				<Statistic
					vertical
					inheritColor
					icon={<Icon type="check" />}
					value={overview?.submitted}
					className={styles.totalCampaignOverviewStatistic}
				>
					{t(
						'dashboard.tabs.phishing.totalCampaignOverview.submitted'
					)}
				</Statistic>
				<Statistic
					vertical
					inheritColor
					icon={<Icon type="reward" />}
					value={overview?.success}
					className={styles.totalCampaignOverviewStatistic}
				>
					{t('dashboard.tabs.phishing.totalCampaignOverview.success')}
				</Statistic>
			</Fx>
		</Card>
	);
};

export default observer(TotalCampaignOverview);
