import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import { Fx } from 'Components';
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from 'app-config';
import styles from './AppFooter.module.scss';

const AppFooter = () => {
	const { t } = useTranslation();

	const today = new Date();

	return (
		<Layout.Footer className={styles.root}>
			<Fx align="middle" justify="space-between" fullHeight wrap>
				<div>
					<ul>
						<li className={styles.copyright}>
							{`©${today.getFullYear()}.`} {t('footer.copyright')}
						</li>
					</ul>
				</div>
				<div>
					<ul>
						<li>
							<a
								href="https://help.venzagroup.com"
								target="_blank"
								rel="noreferrer"
							>
								{t('footer.contactUs')}
							</a>
						</li>
						<li>
							<a
								href={TERMS_OF_USE_LINK}
								target="_blank"
								rel="noreferrer"
							>
								{t('footer.termsOfUse')}
							</a>
						</li>
						<li>
							<a
								href={PRIVACY_POLICY_LINK}
								target="_blank"
								rel="noreferrer"
							>
								{t('footer.privacyPolicy')}
							</a>
						</li>
					</ul>
				</div>
			</Fx>
		</Layout.Footer>
	);
};

export default AppFooter;
