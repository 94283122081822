import { useState, useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { APIErrorCodes } from 'app-types';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useErrorBoundary } from 'Hooks';
import { schema } from './schema';
import { useStores } from 'Hooks/useStore';
import {
	Layout,
	Notification,
	Spin,
	CustomerSupportNotification
} from 'Components';
import ValidateEmailForm from './ValidateEmailForm';
import ResendValidationEmail from './ResendValidationEmail';
import {
	VALIDATE_USER_EMAIL,
	VALIDATE_USER_EMAIL_TOKEN
} from 'Services/Api/Users/Mutations';
import { getAppRoutes } from 'Pages/App/App.constants';
import { checkApolloError } from 'Helpers/graphql';
import { ValidationActionScreen, Values } from './ValidateEmail.types';
import styles from './ValidateEmail.module.scss';
import { getIsPending } from 'Stores/util';
import { getJWT } from 'Helpers/getJWT';

function ValidateEmailContent() {
	const [
		validationActionScreen,
		setValidationActionScreen
	] = useState<ValidationActionScreen>('validateEmail');
	const [isExpiredJWTError, setExpiredJwtError] = useState(false);
	// NOTE: it allows handling cases when email was validated on a previous screen, it means that a user validated email on another browser tab and doesn't need to resend the validation mail letter again.
	const [isEmailWasValidated, setIsEmailWasValidated] = useState(false);
	const { t } = useTranslation();
	const { authStore } = useStores();
	const [validateUserEmail, { loading }] = useMutation(VALIDATE_USER_EMAIL);
	const [
		validateUserEmailToken,
		{ loading: userEmailTokenLoading, data }
	] = useMutation(VALIDATE_USER_EMAIL_TOKEN);
	const history = useHistory();
	const errorBoundary = useErrorBoundary();
	const initialValues = {
		userEmail: authStore.currentUser?.email ?? ''
	};

	useLayoutEffect(() => {
		const jwt = getJWT(history.location.search);
		jwt && validateEmailToken(jwt);
		return () => {
			setIsEmailWasValidated(false);
		};
		// eslint-disable-next-line
	}, []);

	async function validateEmailToken(token: string) {
		try {
			await validateUserEmailToken({
				variables: {
					input: {
						jwt: token
					}
				}
			});
		} catch (error) {
			const apolloError = checkApolloError(error);

			if (apolloError.is(APIErrorCodes.TokenExpired)) {
				setExpiredJwtError(true);
				return;
			}

			if (apolloError.is(APIErrorCodes.Forbidden)) {
				errorBoundary.setStatus('forbidden');
				return;
			}

			let description: string | React.ReactElement = t(
				'errorCodes.genericErrorMessage'
			);

			if (
				apolloError.is(APIErrorCodes.ValidationJWT) ||
				apolloError.is(APIErrorCodes.EmailsIsNotEqualToUserEmail) ||
				apolloError.is(APIErrorCodes.TokenIsNotValid)
			) {
				description = <CustomerSupportNotification hasError />;
			}

			Notification.error({
				description
			});
		}
	}

	const onSubmit = async (values: Values) => {
		try {
			await authStore.getCurrentUser();
			if (authStore.currentUser?.isEmailValidated) {
				setIsEmailWasValidated(true);
			} else {
				await validateUserEmail({
					variables: {
						input: {
							email: values.userEmail
						}
					}
				});
				setValidationActionScreen('resendValidationEmail');
			}
		} catch (error) {
			Notification.error({
				description: t('errorCodes.genericErrorMessage')
			});
		}
	};

	const navigateToValidateEmail = useCallback(async () => {
		try {
			await authStore.getCurrentUser();

			if (authStore.currentUser?.isEmailValidated) {
				setIsEmailWasValidated(true);
			} else {
				setValidationActionScreen('validateEmail');
				setExpiredJwtError(false);
			}
		} catch (error) {
			history.push(getAppRoutes().LOGIN);
		}
	}, [authStore, history]);

	if (userEmailTokenLoading) return <Spin />;

	if (data?.validateUserEmailToken?.isEmailValidated || isEmailWasValidated) {
		if (!isEmailWasValidated) {
			Notification.success({
				description: t('emailValidation.messages.success')
			});
		}
		return <Redirect to={getAppRoutes().HOME} />;
	}

	const isCurrentUserStatusPending = getIsPending(
		authStore.status.getCurrentUser
	);
	const isValidateEmailScreen = validationActionScreen === 'validateEmail';

	return (
		<Layout>
			<Layout.Heading>
				<h3>
					{t(
						`emailValidation.${
							isExpiredJWTError && isValidateEmailScreen
								? 'tokenHadExpired'
								: 'title'
						}`
					)}
				</h3>
			</Layout.Heading>
			{isExpiredJWTError && (
				<p>{t('emailValidation.confirmEmailAddress')}</p>
			)}
			<div className={styles.dynamicFormContent}>
				<Formik
					initialValues={initialValues}
					onSubmit={onSubmit}
					validationSchema={schema}
				>
					{({ isValid }) =>
						isValidateEmailScreen ? (
							<ValidateEmailForm
								isValid={isValid}
								loading={loading || isCurrentUserStatusPending}
								isExpiredJWTError={isExpiredJWTError}
							/>
						) : (
							<ResendValidationEmail
								resendValidationEmail={navigateToValidateEmail}
								loading={isCurrentUserStatusPending}
							/>
						)
					}
				</Formik>
			</div>
			<p className={styles.assistance}>
				<CustomerSupportNotification />
			</p>
		</Layout>
	);
}

export default observer(ValidateEmailContent);
