import * as yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { EditableFields, Input, Layout, Notification } from 'Components';
import { useStores } from 'Hooks/useStore';
import {
	ChangePasswordRequest,
	CognitoResponseError
} from 'Services/Api/Auth/Types';
import { EditableFieldsProps } from 'Components/EditableLayout/EditableFields';
import { useHistory } from 'react-router';
import { navigateBack } from 'Helpers/navigation';
import { getAppRoutes } from 'Pages/App/App.constants';
import NewPasswordFields from 'Pages/Auth/Login/Steps/NewPasswordFields/NewPasswordFields';
import { schema as passwordSchema } from 'Pages/Auth/Login/Steps/NewPasswordFields/schema';
import { VALIDATION } from 'Pages/Auth/AuthValidation';
import { COGNITO_ERROR_MSG_TO_DESCRIPTION_MAP } from 'app-config';

const schema = yup
	.object()
	.shape({
		oldPassword: VALIDATION.password
	})
	.concat(passwordSchema);

type Values = ChangePasswordRequest;

function ChangePassword(props: EditableFieldsProps) {
	const { t } = useTranslation();
	const history = useHistory();
	const { authStore } = useStores();
	const initialValues: Values = {
		oldPassword: '',
		password: '',
		confirmPassword: ''
	};

	const onSubmit = async (values: Values, helpers: FormikHelpers<Values>) => {
		try {
			await authStore.changePassword(values);
			helpers.resetForm({ values });
			navigateBack(history, getAppRoutes().HOME);
			Notification.success({
				description: t('notification.edit.success')
			});
		} catch (e) {
			const error = e as CognitoResponseError;
			// reset to initial for prompt not blocking transition
			helpers.setSubmitting(false);

			if (error.code === 'NotAuthorizedException') {
				const errors: Partial<Record<keyof Values, string>> = {
					oldPassword: t(
						'user.editUser.tabs.changePasswordTab.oldPasswordIncorrect'
					)
				};
				helpers.setErrors(errors);
				return;
			}
			const cognitoError =
				COGNITO_ERROR_MSG_TO_DESCRIPTION_MAP[error.message];

			const description =
				cognitoError ?? 'errorCodes.genericErrorMessage';

			Notification.error({
				description: t(description)
			});
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={schema}
		>
			<EditableFields onChange={props.onChange} footer>
				<Layout.List>
					<Layout.List.ListItem>
						<Layout.List.Section
							title={t('user.editUser.tabs.changePassword')}
						>
							<Input.PasswordFormikField
								name="oldPassword"
								label={t(
									'user.editUser.tabs.changePasswordTab.oldPassword'
								)}
								required
							/>
							<hr />
							<NewPasswordFields />
						</Layout.List.Section>
					</Layout.List.ListItem>
				</Layout.List>
			</EditableFields>
		</Formik>
	);
}

export default ChangePassword;
