import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Notification } from 'Components';
import { useModal } from 'Hooks/useModal';
import { isNumber } from 'lodash';
import { MOVE_USERS } from 'Services/Api/Users/Mutations';
import { MoveUsersRequest } from 'Services/Api/Users/Types';
import { MoveUsersModalProps, MoveUsersModalValues } from './MoveUsersModal';
import { APIErrorCodes } from 'app-types';
import { handleForbiddenError } from '../../Users.helpers';
import { DataTreeNodeItem } from 'Contexts/DataTree/DataTree.types';
import { Group } from 'Services/Api/Groups/Types';
import { checkApolloError } from 'Helpers/graphql';

export const useMoveUsersModal = (props: MoveUsersModalProps) => {
	const { t } = useTranslation();
	const { modal, hideModal } = useModal();

	const modalValue = modal?.value as MoveUsersModalValues;
	const [moveUsers] = useMutation<void, MoveUsersRequest>(MOVE_USERS, {
		ignoreResults: false
	});

	const onSubmit = async (value: DataTreeNodeItem<Group>[] | undefined) => {
		try {
			const selectedItem = value?.[0];
			if (!selectedItem || !isNumber(selectedItem.key)) {
				throw Error('Validation error');
			}
			const variables: MoveUsersRequest = {
				input: {
					userIds: modalValue.userIds,
					groupId: selectedItem.key
				}
			};
			await moveUsers({
				variables
			});

			Notification.success({
				description: t('users.components.moveUserModal.notification')
			});
			props.onSubmit();
			hideModal();
		} catch (error) {
			if (
				checkApolloError(error).is(
					APIErrorCodes.MovingBetweenTenantsForbidden
				)
			) {
				Notification.error({
					description: t(
						`errorCodes.${APIErrorCodes.MovingBetweenTenantsForbidden}`
					)
				});
			} else {
				handleForbiddenError(
					error,
					`users.components.moveUserModal.errorCodes.${APIErrorCodes.Forbidden}`
				);
			}
		}
	};

	return {
		modalValue,
		onSubmit
	};
};
