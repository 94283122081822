import cn from 'classnames';
import { EditableFields, Tabs } from 'Components';
import { Details, Permissions } from './tabs';
import styles from './EditRoleTabs.module.scss';
import { EditRoleBaseProps, EditRoleFormValues } from './EditRole.types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

export enum EditRoleTabKey {
	Details = 'Details',
	Permissions = 'Permissions',
	Lms = 'Lms'
}

const TAB_BY_KEY: Record<EditRoleTabKey, string> = {
	[EditRoleTabKey.Details]: 'role.editRole.tabs.details',
	[EditRoleTabKey.Permissions]: 'role.editRole.tabs.permissions',
	[EditRoleTabKey.Lms]: 'role.editRole.tabs.lms'
};

function EditRoleTabs(props: EditRoleBaseProps) {
	const { t } = useTranslation();
	const {
		values: {
			data: { core, lms }
		}
	} = useFormikContext<EditRoleFormValues>();
	const { isNew } = props;

	return (
		<Tabs
			defaultActiveKey={EditRoleTabKey.Details}
			className={cn({
				[styles.tabsContentInner]: isNew
			})}
		>
			{!isNew && (
				<Tabs.TabPane
					tab={t(TAB_BY_KEY[EditRoleTabKey.Details])}
					key={EditRoleTabKey.Details}
				>
					<EditableFields>
						<Details />
					</EditableFields>
				</Tabs.TabPane>
			)}

			{Boolean(core.length) && (
				<Tabs.TabPane
					tab={t(TAB_BY_KEY[EditRoleTabKey.Permissions])}
					key={EditRoleTabKey.Permissions}
				>
					<EditableFields>
						<Permissions values={core} />
					</EditableFields>
				</Tabs.TabPane>
			)}

			{Boolean(lms.length) && (
				<Tabs.TabPane
					tab={t(TAB_BY_KEY[EditRoleTabKey.Lms])}
					key={EditRoleTabKey.Lms}
				>
					<EditableFields>
						<Permissions values={lms} />
					</EditableFields>
				</Tabs.TabPane>
			)}
		</Tabs>
	);
}

export default EditRoleTabs;
