export const APP_ROUTES: Record<string, string> = {
	HOME: '/',
	LOGOUT: '/logout',
	IDLE_LOGOUT: '/idle-logout',
	STATUS_LOGOUT: '/status-logout',
	LOGIN: '/login',
	FIRST_LOGIN: '/first-login',
	RESET_PASSWORD: '/reset-password',
	RESET_TEMPORARY_PASSWORD: '/reset-temporary-password',
	PROFILE: '/profile',

	USERS: '/users',
	ADD_USER: '/users/new',
	ADD_USERS: '/users/add-multiple',
	USER: '/users/:peakV3Id',

	GROUPS: '/groups-manage/:groupId?',
	GROUPS_DELETED: '/groups-deleted',
	EDIT_TENANT: '/tenants/:groupId',
	EDIT_GROUP: '/groups/:groupId',
	ADD_GROUP: '/groups-new/:groupId?',
	GROUP_HIERARCHY: '/groups-hierarchy/:groupId',

	OWNERSHIP_GROUPS: '/ownership-groups-manage/:groupId?',
	ADD_OWNERSHIP_GROUP: '/ownership-groups-new',
	EDIT_OWNERSHIP_GROUP: '/ownership-groups/:groupId',

	ROLES: '/roles',
	ADD_ROLE: '/roles/new',
	EDIT_ROLE: '/roles/:roleId',

	REPORTS: '/reports',
	SAVED_REPORTS: '/reports/saved',
	ADD_REPORT: '/reports/:type/new',
	EDIT_REPORT: '/reports/:reportId',
	DOWNLOAD_REPORT: '/reports/download/:token',

	ERROR_PERMISSION: '/no-permission',
	AUDIT_LOG: '/audit-log',
	TOKEN_EXPIRED: '/token-expired',
	VALIDATE_EMAIL: '/validate-email/:token?',
	TWO_STEP_REGISTRATION: '/two-step-registration',
	TWO_STEP_AUTHENTICATION: '/two-step-authentication'
};

function createAppRoutesHandler() {
	let appRoutes: Record<string, string> = { ...APP_ROUTES };

	function getAppRoutes(): typeof APP_ROUTES {
		return appRoutes;
	}

	function mutateAppRoutes(postfix: string): typeof APP_ROUTES {
		appRoutes = {};
		const routeKeys = Object.keys(APP_ROUTES);
		return routeKeys.reduce<Record<string, string>>((acm, key) => {
			const base = `${APP_ROUTES.HOME}${postfix}`;
			acm[key] =
				APP_ROUTES[key] === APP_ROUTES.HOME
					? base
					: `${base}${APP_ROUTES[key]}`;
			return acm;
		}, appRoutes);
	}

	return {
		mutateAppRoutes,
		getAppRoutes
	};
}

const routesHandler = createAppRoutesHandler();

export const getAppRoutes = routesHandler.getAppRoutes;
export const mutateAppRoutes = routesHandler.mutateAppRoutes;
