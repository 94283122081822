import { Layout, Modal } from 'Components';
import { useStores } from 'Hooks/useStore';
import { getAppRoutes } from 'Pages/App/App.constants';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import AppLayout from 'Pages/App/AppLayout/AppLayout';

interface StatusLogoutState {
	isMFA?: boolean;
}

function StatusLogout() {
	const { authStore } = useStores();
	const history = useHistory<StatusLogoutState>();
	const { t } = useTranslation();

	useEffect(() => {
		authStore.signOut();
		// eslint-disable-next-line
	}, []);

	const onOk = () => {
		history.push(getAppRoutes().LOGIN);
	};

	const isMFA = Boolean(history.location?.state?.isMFA);

	return (
		<AppLayout
			header={null}
			headerTheme="light"
			contentAddon={<Layout.MountainView />}
		>
			<Modal
				visible
				cancelButtonVisible={false}
				okText={t('auth.statusLogout.submitText')}
				onOk={onOk}
				onCancel={onOk}
			>
				{t(
					isMFA
						? 'auth.statusLogout.mfaStatusChange'
						: 'auth.statusLogout.description'
				)}
			</Modal>
		</AppLayout>
	);
}

export default StatusLogout;
