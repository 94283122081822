import { ColumnProps } from 'antd/lib/table';
import { OptionalValue } from 'Components';
import {
	useCreateColumns,
	ColumnFilters
} from 'Components/Table/createColumns';
import {
	REPORT_FREQUENCIES,
	REPORT_FREQUENCIES_MAP
} from 'Pages/Report/EditReport/EditReport.constants';
import { useTranslation } from 'react-i18next';
import {
	GetSavedReportsRequest,
	SavedReport
} from 'Services/Api/Reports/Types';
import {
	STANDARD_REPORT_TYPES,
	STANDARD_REPORT_TYPE_MAP
} from '../../Standard/Standard.constants';
import ActionsButton from './ActionsButton';

type FilterValues = GetSavedReportsRequest;
export type TableFilterValues = ColumnFilters<FilterValues>;

type UseColumnsProps = {
	params: GetSavedReportsRequest | undefined;
	currentUserEmail: string;
};

const useColumns = <T extends SavedReport>(props: UseColumnsProps) => {
	const { t } = useTranslation();
	const helpers = useCreateColumns<T, FilterValues>({
		filterValues: props.params,
		sortDesc: props.params?.sort
	});
	const columns: Array<ColumnProps<T>> = [
		{
			...helpers.sort,
			...helpers.search,
			sortOrder: helpers?.getSortOrder('reportName'),
			title: t('reports.tabs.saved.columns.name'),
			key: 'reportName',
			dataIndex: 'name',
			filteredValue: helpers?.filters.reportName
		},
		{
			...helpers.sort,
			...helpers.filter,
			sortOrder: helpers?.getSortOrder('reportTypes'),
			title: t('reports.tabs.saved.columns.reportType'),
			key: 'reportTypes',
			dataIndex: 'reportType',
			filteredValue: helpers?.filters.reportTypes,
			filters: STANDARD_REPORT_TYPES.map((opt) => ({
				value: opt.value,
				text: opt.label(t)
			})),
			render: (_, record) =>
				STANDARD_REPORT_TYPE_MAP[record.reportType].label(t)
		},
		{
			...helpers.sort,
			...helpers.filter,
			sortOrder: helpers?.getSortOrder('recurrenceTypes'),
			title: t('reports.tabs.saved.columns.recurrenceType'),
			key: 'recurrenceTypes',
			dataIndex: 'recurrenceType',
			filteredValue: helpers?.filters.recurrenceTypes,
			filters: REPORT_FREQUENCIES.map((opt) => ({
				value: opt.value,
				text: t(opt.label)
			})),
			render: (_, record) => (
				<OptionalValue>
					{record.recurrenceType &&
						t(REPORT_FREQUENCIES_MAP[record.recurrenceType]?.label)}
				</OptionalValue>
			)
		},
		{
			...helpers.search,
			title: t('reports.tabs.saved.columns.emailsList'),
			key: 'emailsList',
			dataIndex: 'emailsList',
			filteredValue: helpers?.filters.emailsList,
			render: (_, record) => {
				const emails = (record.emailsList || [])
					.map((email) =>
						email === props.currentUserEmail
							? `${email} (${t('reports.tabs.saved.me')})`
							: email
					)
					.join(', ');
				return <OptionalValue>{emails}</OptionalValue>;
			}
		},
		{
			title: t('actions'),
			width: 80,
			render: (_, record) => <ActionsButton report={record} />
		}
	];
	return columns;
};

export default useColumns;
