import { APP_CONFIG } from 'app-config';
import moment from 'moment';

export const getRemainingTime = (prefixPath: string, expiresAtDate: string) => {
	const diff = moment
		.utc(expiresAtDate, APP_CONFIG.DATE_TIME_FORMAT)
		.diff(moment());
	const duration = moment.duration(diff);
	const days = duration.days();
	const hours = duration.hours();
	const minutes = duration.minutes();
	const prefix = `${prefixPath}.expiresIn`;
	const suffix = (count: number) => (count > 1 ? 's' : '');
	const result = [
		{
			key: `${prefix}.day${suffix(days)}`,
			params: { count: days }
		},
		{
			key: `${prefix}.hour${suffix(hours)}`,
			params: { count: hours }
		},
		{
			key: `${prefix}.minute${suffix(minutes)}`,
			params: { count: minutes }
		}
	].filter((d) => d.params.count > 0);

	return result.length
		? result
		: [
				{
					key: `${prefix}.seconds`,
					params: { count: 0 }
				}
		  ];
};
