import React from 'react';
import cn from 'classnames';
import {
	Radio as AntRadio,
	RadioGroupProps as AntRadioGroupProps,
	RadioProps
} from 'antd';
import { createFormikField, createHookFormField } from 'Helpers/components';
import styles from './Radio.module.scss';

type RadioGroupProps = AntRadioGroupProps & {
	vertical?: boolean;
};
type RadioInterface = React.FC<RadioProps> & {
	Group: typeof RadioGroup;
};
type RadioGroupInterface = React.FC<RadioGroupProps> & {
	FormikField: typeof RadioGroupFormikField;
	HookFormField: typeof RadioGroupHookFormField;
};

const Radio: RadioInterface = (props) => <AntRadio {...props} />;
const RadioGroup: RadioGroupInterface = ({ vertical, ...props }) => (
	<AntRadio.Group
		{...props}
		className={cn(styles.root, props.className, {
			[styles.vertical]: vertical
		})}
	/>
);

Radio.Group = RadioGroup;

const RadioGroupFormikField = createFormikField<RadioGroupProps>(
	RadioGroup,
	(props) => ({
		onChange: (e) => {
			props.props.onChange?.(e);
			return props.helpers.setValue(e.target.value);
		}
	})
);
const RadioGroupHookFormField = createHookFormField(RadioGroup);

RadioGroup.HookFormField = RadioGroupHookFormField;
RadioGroup.FormikField = RadioGroupFormikField;

export default Radio;
