import { useQuery } from '@apollo/client';
import { ListProps } from 'Contexts/DataTree/components/List/List';
import DataTree from 'Contexts/DataTree/DataTree';
import { DataTreeContextProps } from 'Contexts/DataTree/DataTree.types';
import { DataTreeContextProvider } from 'Contexts/DataTree/DataTreeContext';
import GroupTreeLegend from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupTreeLegend';
import OwnershipGroupsSearch from 'Pages/OwnershipGroups/OwnershipGroupsSearch';
import { ownershipGroupToTreeNode } from 'Pages/User/components/OwnershipGroupField/OwnershipGroupField.helpers';
import { GET_OWNERSHIP_GROUPS } from 'Services/Api/Groups/Queries';
import {
	GetOwnershipGroupsRequest,
	GetOwnershipGroupsResponse,
	OwnershipGroup
} from 'Services/Api/Groups/Types';

type OwnershipGroupsProps = Pick<
	DataTreeContextProps<OwnershipGroup>,
	'initialValue' | 'clearValue' | 'onChange'
> & {
	listProps?: Partial<ListProps>;
};
function OwnershipGroups(props: OwnershipGroupsProps) {
	const result = useQuery<
		GetOwnershipGroupsResponse,
		GetOwnershipGroupsRequest
	>(GET_OWNERSHIP_GROUPS, {
		variables: {
			filters: {}
		},
		notifyOnNetworkStatusChange: true
	});

	const dataSource = result.data?.listOwnershipGroups;

	return (
		<DataTreeContextProvider<OwnershipGroup>
			{...props}
			dataSource={dataSource}
			toNode={ownershipGroupToTreeNode}
		>
			<DataTree
				layout="modal"
				header={OwnershipGroupsSearch}
				footer={<GroupTreeLegend exclude={['deleted', 'archived']} />}
			>
				<DataTree.List checkable {...props.listProps} />
			</DataTree>
		</DataTreeContextProvider>
	);
}

export default OwnershipGroups;
