import { DownloadUrl } from 'app-types';

export interface GetAuditLogsRequest {
	page: number;
	itemsPerPage: number;
	search?: string;
	sort: string;
	filters: Partial<
		Omit<AuditLog, 'id' | 'date' | 'page' | 'action'> & {
			date: {
				from: string;
				to: string;
			};
			page: AuditLogPage[];
			action: AuditLogAction[];
		}
	>;
}

export interface GetAuditLogsResponse {
	getAuditLogs: {
		data: AuditLog[];
		totalCount: number;
	};
}

export interface AuditLog {
	id: string;
	tenant?: string;
	login: string;
	ip?: string;
	date: string;
	page: AuditLogPage;
	action: AuditLogAction;
	browser?: string;
	modifyBy: string;
}

export enum AuditLogAction {
	UpdateUser = 'Update user',
	AddUser = 'Add user',
	ActivateUser = 'Activate user',
	DeactivateUser = 'Deactivate user',
	DeleteUser = 'Delete user',
	MoveUser = 'Move user',
	RestoreUser = 'Restore user',
	ResetUserPassword = 'Reset user password',
	AddTenant = 'Add tenant',
	AddGroup = 'Add group',
	DeleteGroup = 'Delete group',
	DeleteTenant = 'Delete tenant',
	MoveGroup = 'Move group',
	EditGroup = 'Edit group',
	EditTenant = 'Edit tenant',
	RestoreGroup = 'Restore group',
	RestoreTenant = 'Restore tenant',
	AddOwnershipGroup = 'Add ownership group',
	DeleteOwnershipGroup = 'Delete ownership group',
	EditOwnershipGroup = 'Edit ownership group',
	DeleteOwnershipGroupProperties = 'Delete ownership group properties',
	AddSystemRole = 'Add system role',
	DeleteSystemRole = 'Delete system role',
	CloneSystemRole = 'Clone system role',
	EditSystemRole = 'Edit system role',
	AccountActivation = 'Account activation',
	LockedOut = 'Locked out',
	ValidateUserEmail = 'Validate user email',
	ValidateUserEmailToke = 'Validate user email token',
	ResetPassword = 'Reset password',
	MFASetup = '2FA setup',
	MFAReset = '2FA reset'
}

export enum AuditLogPage {
	UpdateUser = 'Update user',
	UpdateCurrentUser = 'Update current user',
	AddSingleUser = 'Add single user',
	AddMultipleUsers = 'Add multiple users',
	UsersList = 'Users list',
	DeletedUsersList = 'Deleted users list',
	AddTenant = 'Add tenant',
	AddSingleGroup = 'Add single group',
	AddMultipleGroups = 'Add multiple groups',
	AddGroupsHierarchy = 'Add groups hierarchy',
	GroupsList = 'Groups list',
	EditGroup = 'Edit group',
	EditTenant = 'Edit tenant',
	DeletedGroupsList = 'Deleted groups list',
	AddOwnershipGroup = 'Add ownership group',
	OwnershipGroupsList = 'Ownership groups list',
	EditOwnershipGroup = 'Edit ownership group',
	AddSystemRole = 'Add system role',
	SystemRolesList = 'System roles list',
	EditSystemRole = 'Edit system role',
	Login = 'Login',
	ValidateUserEmailToken = 'Validate user email token',
	ValidateUserEmail = 'Validate user email',
	ResetPassword = 'Reset password',
	MFARegistration = '2FA registration'
}

// Export

export type ExportAuditLogsRequest = GetAuditLogsRequest;
export interface ExportAuditLogsResponse {
	exportAuditLogs: DownloadUrl;
}
