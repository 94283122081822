import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Notification } from 'Components';
import { useGroupsHierarchyContext } from '../../../GroupsHierarchyContext';
import { ValueXLSX, ValuesXLSX } from '../../../GroupsHierarchy.types';
import { isGroupsHierarchyLimitReachedXLSX } from '../../../GroupsHierarchy.helpers';
import { SelectLevel } from '../../../components';

interface SelectProps {
	setLevels: React.Dispatch<React.SetStateAction<ValueXLSX[]>>;
	values: ValuesXLSX;
}

const Select = ({ setLevels, values }: SelectProps) => {
	const {
		levelNumberXLSX,
		setLevelNumberXLSX,
		parentLevel,
		groupsHierarchySizeLimit,
		amountOfGroupsInTenant
	} = useGroupsHierarchyContext();

	const { t } = useTranslation();
	const handleOnLevelsSelectChange = useCallback(
		(value: number) => {
			if (levelNumberXLSX) {
				if (levelNumberXLSX > value) {
					setLevels(values.levels.slice(0, value - parentLevel));
				} else if (levelNumberXLSX < value) {
					const levelsAmount = value - levelNumberXLSX;
					const newLevelProperties: ValueXLSX[] = Array(
						levelsAmount
					).fill({
						levelName: '',
						numberOfGroups: '',
						isProperty: false
					});
					const levelsValues = values.levels.concat(
						newLevelProperties
					);
					if (
						isGroupsHierarchyLimitReachedXLSX(
							{ levels: levelsValues },
							groupsHierarchySizeLimit,
							amountOfGroupsInTenant
						)
					) {
						const isSingle = value - parentLevel === 1;

						const translationPath = isSingle
							? 'single'
							: 'multiple';
						Notification.error({
							description: t(
								`groupsHierarchy.groupsHierarchyLevelLimit.${translationPath}`,
								{
									groupsHierarchyLimit: groupsHierarchySizeLimit
								}
							)
						});

						return;
					}

					setLevels(levelsValues);
				}
			}

			setLevelNumberXLSX(value);
		},
		// eslint-disable-next-line
		[values, levelNumberXLSX, parentLevel]
	);

	return (
		<SelectLevel
			handleOnLevelsSelectChange={handleOnLevelsSelectChange}
			levelNumber={levelNumberXLSX}
			help={t('groupsHierarchy.formXLSX.numberOFHierarchyLevelHelp')}
		/>
	);
};

export default Select;
