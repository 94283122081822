import { useLazyQuery, useQuery } from '@apollo/client';
import { PaginationProps, TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { APP_CONFIG } from 'app-config';
import { createSortDescription } from 'Components/Table/createColumns';
import { debounce, isEmpty, isEqual, keyBy, omitBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GET_USERS } from 'Services/Api/Users/Queries';
import {
	DynamicPermissionCode,
	GetUsersRequest,
	GetUsersResponse,
	User
} from 'Services/Api/Users/Types';

import {
	GET_USERS_INITIAL_PARAMS,
	UsersTableDeletedStatus
} from './Users.constants';
import { useHistory } from 'react-router-dom';
import { getAppRoutes } from 'Pages/App/App.constants';
import { qsParse, qsStringify, toPath } from 'Helpers/navigation';
import { usePrevious } from 'Hooks/usePrevious';
import { GET_SYSTEM_ROLES_LIST } from 'Services/Api/Roles/Queries';
import { getFilters, fromFilterValues, prepareParams } from './Users.helpers';
import { GetSystemRolesList } from 'Services/Api/Roles/Types';
import { UsersTableFiltersValue } from './Users.types';

export const useUsers = () => {
	const history = useHistory();
	const [getUsers, result] = useLazyQuery<GetUsersResponse, GetUsersRequest>(
		GET_USERS
	);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [filteredInfo, setFilteredInfo] = useState<GetUsersRequest>(
		GET_USERS_INITIAL_PARAMS
	);
	const previousFilteredInfo = usePrevious(filteredInfo);

	const refetch = useCallback(
		(params: typeof filteredInfo) => {
			// reset selected users when filters change
			setSelectedRowKeys([]);
			getUsers({ variables: prepareParams(params) });
		},
		[getUsers]
	);

	const updateFilteredInfo = useCallback(
		(
			info:
				| { info: GetUsersRequest; override: true }
				| { info: Partial<GetUsersRequest>; override?: false }
		) => {
			setFilteredInfo((currentValue) => {
				const newFilteredInfo: GetUsersRequest = info.override
					? prepareParams(info.info)
					: prepareParams({
							...currentValue,
							page: 1,
							...info.info,
							filters: {
								...currentValue.filters,
								...info.info.filters
							}
					  });

				const isDeleted =
					newFilteredInfo.filters.isDeleted ??
					currentValue.filters.isDeleted;

				return {
					...newFilteredInfo,
					filters: {
						...newFilteredInfo.filters,
						isDeleted
					}
				};
			});
		},
		[setFilteredInfo]
	);

	useEffect(() => {
		const qsFilters = qsParse(
			history.location.search
		) as GetUsersRequest['filters'];
		updateFilteredInfo({
			info: {
				...GET_USERS_INITIAL_PARAMS,
				filters: isEmpty(qsFilters)
					? GET_USERS_INITIAL_PARAMS.filters
					: qsFilters
			},
			override: !isEmpty(qsFilters)
		});
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		// re-fetch data when new filters are applied
		if (!isEqual(previousFilteredInfo, filteredInfo)) {
			refetch(filteredInfo);
		}
	}, [filteredInfo, previousFilteredInfo, refetch]);

	useEffect(() => {
		if (!result.variables) {
			return;
		}

		// update url with actual request paramaters
		history.replace(
			toPath({
				path: getAppRoutes().USERS,
				query: qsStringify(result.variables?.filters || {})
			})
		);
	}, [result.variables, history]);

	const usersBySelectedRowKeys = useMemo(() => {
		const byId = keyBy(
			result.data?.list.data || [],
			(user) => user.peakV3Id
		);

		return omitBy(byId, (value) => {
			return !selectedRowKeys.includes(value.peakV3Id);
		});
	}, [result.data?.list, selectedRowKeys]);

	const dataSource = result.data?.list.data || [];

	return {
		dataSource,
		refetch,
		result,
		selectedRowKeys,
		setSelectedRowKeys,
		usersBySelectedRowKeys,
		filteredInfo,
		updateFilteredInfo
	};
};

export const useUsersFilters = ({
	setSelectedRowKeys,
	result,
	refetch,
	filteredInfo,
	updateFilteredInfo
}: ReturnType<typeof useUsers>) => {
	const systemRoles = useQuery<GetSystemRolesList>(GET_SYSTEM_ROLES_LIST, {
		variables: {
			action: DynamicPermissionCode.ViewUsers
		}
	});

	const updateFilteredInfoRef = useRef(updateFilteredInfo);
	updateFilteredInfoRef.current = updateFilteredInfo;
	// eslint-disable-next-line
	const onSearch = useCallback(
		debounce((current: string, previous?: string) => {
			if (current.length < APP_CONFIG.SEARCH_MIN_LENGTH) {
				if (previous?.length) {
					updateFilteredInfo({
						info: { search: '' }
					});
				}
				return;
			}
			updateFilteredInfo({
				info: {
					search: current,
					page: APP_CONFIG.TABLE_DEFAULT_PAGINATION_PAGE
				}
			});
		}, 400),
		[]
	);

	const onSearchChange = (value: string) => {
		onSearch(value, result.variables?.search);
	};

	const onActionSubmit = useCallback(() => {
		setSelectedRowKeys([]);
		refetch(filteredInfo);
	}, [setSelectedRowKeys, refetch, filteredInfo]);

	const onUsersDeletedStatusChange = (value: UsersTableDeletedStatus) => {
		setSelectedRowKeys([]);
		updateFilteredInfo({
			override: true,
			info:
				value === UsersTableDeletedStatus.All
					? GET_USERS_INITIAL_PARAMS
					: {
							...GET_USERS_INITIAL_PARAMS,
							filters: {
								isDeleted: true
							}
					  }
		});
	};

	const filters = getFilters({
		systemRoles: systemRoles.data?.getSystemRolesList || []
	});

	const onTableChange: TableProps<User>['onChange'] = (
		_,
		filters,
		sorter
	) => {
		const values = filters as UsersTableFiltersValue;
		updateFilteredInfo({
			info: {
				filters: fromFilterValues(values),
				sort: createSortDescription<User>(sorter as SorterResult<User>),
				page: APP_CONFIG.TABLE_DEFAULT_PAGINATION_PAGE
			}
		});
	};

	const onPaginationChange: PaginationProps['onChange'] = useCallback(
		(page, pageSize) => {
			updateFilteredInfo({
				info: {
					page: page,
					itemsPerPage: pageSize ?? 0
				}
			});
		},
		[updateFilteredInfo]
	);

	const setFilterValue = (
		value: Partial<
			Pick<UsersTableFiltersValue, 'groupIds' | 'ownershipGroupIds'>
		>
	) => {
		updateFilteredInfo({
			override: false,
			info: {
				filters: value
			}
		});
	};

	return {
		filters,
		filterValues: filteredInfo,
		onTableChange,
		onPaginationChange,
		onSearchChange,
		onActionSubmit,
		onUsersDeletedStatusChange,
		setFilterValue
	};
};
