import { ColumnProps } from 'antd/lib/table';
import { DateFormatted, OptionalValue } from 'Components';
import {
	useCreateColumns,
	UseCreateColumnsInterface
} from 'Components/Table/createColumns';
import { inPercent } from 'Helpers/numeric';
import { useTranslation } from 'react-i18next';
import {
	PhishingActiveCampaign,
	PhishingActiveCampaignsRequest
} from 'Services/Api/Dashboard/Types';

export function useColumnProps<T extends PhishingActiveCampaign>(
	helpers?: ReturnType<UseCreateColumnsInterface<T, T>>
) {
	const { t } = useTranslation();
	const columns: Array<ColumnProps<T>> = [
		{
			...helpers?.sort,
			title: t('dashboard.tabs.phishing.activeCampaigns.columns.name'),
			key: 'name',
			dataIndex: 'name',
			width: '19.44%',
			sortOrder: helpers?.getSortOrder('name')
		},
		{
			...helpers?.sort,
			title: t('dashboard.tabs.phishing.activeCampaigns.columns.date'),
			key: 'date',
			dataIndex: 'date',
			width: '14.46%',
			sortOrder: helpers?.getSortOrder('date'),
			render: (value) => <DateFormatted date={value} />
		},
		{
			...helpers?.sort,
			title: t('dashboard.tabs.phishing.activeCampaigns.columns.email'),
			key: 'email',
			dataIndex: 'email',
			sortOrder: helpers?.getSortOrder('email')
		},
		{
			...helpers?.sort,
			title: t('dashboard.tabs.phishing.activeCampaigns.columns.landing'),
			key: 'landing',
			dataIndex: 'landing',
			sortOrder: helpers?.getSortOrder('landing')
		},
		{
			...helpers?.sort,
			title: t('dashboard.tabs.phishing.activeCampaigns.columns.status'),
			key: 'status',
			dataIndex: 'status',
			width: '10.26%',
			sortOrder: helpers?.getSortOrder('status'),
			render: (value) => <OptionalValue>{value}</OptionalValue>
		},
		{
			...helpers?.sort,
			title: t('dashboard.tabs.phishing.activeCampaigns.columns.score'),
			key: 'score',
			dataIndex: 'score',
			render: (_, record) => inPercent(record.score),
			width: '8.16%',
			sortOrder: helpers?.getSortOrder('score')
		}
	];
	return columns;
}

export const useColumns = ({
	params
}: {
	params: PhishingActiveCampaignsRequest | undefined;
}) => {
	const helpers = useCreateColumns<
		PhishingActiveCampaign,
		PhishingActiveCampaign
	>({
		filterValues: undefined,
		sortDesc: params?.sort
	});

	return useColumnProps(helpers);
};
