import { FormItem, Select } from 'Components';
import { FormItemProps } from 'Components/Form/FormItem/FormItem';
import { SelectProps } from 'Components/Form/Select/Select';
import { useTranslation } from 'react-i18next';
import { JobClass } from 'Services/Api/Masterdata/Types';

type JobClassFieldProps = Pick<FormItemProps, 'className' | 'label'> &
	Pick<SelectProps, 'onChange' | 'disabled' | 'value' | 'loading'> & {
		isProfile?: boolean;
		error?: string;
		blockHelp?: boolean;
		required?: boolean;
		options: JobClass[] | undefined;
	};
function JobClassField({
	isProfile,
	error,
	label,
	className,
	blockHelp,
	required,
	options,
	loading,
	...selectProps
}: JobClassFieldProps) {
	const { t } = useTranslation();
	return (
		<FormItem
			className={className}
			label={label === false ? null : label ?? t('user.lmsJobcass.label')}
			validateStatus={(error && 'error') || undefined}
			help={blockHelp ? undefined : error}
			required={required}
		>
			<Select.ScrollHandled
				{...selectProps}
				loading={loading}
				disabled={isProfile || loading || selectProps.disabled}
				optionFilterProp="children"
				showSearch
			>
				{options?.map((jobClass) => {
					return (
						<Select.Option
							key={jobClass.peakV3Id}
							value={jobClass.peakV3Id}
						>
							{jobClass.name}
						</Select.Option>
					);
				})}
			</Select.ScrollHandled>
		</FormItem>
	);
}

export default JobClassField;
