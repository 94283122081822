import { Checkbox, Fx, Icon, Input, Layout, Tooltip } from 'Components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { EditRoleBaseProps, EditRoleFormValues } from '../../EditRole.types';
import styles from './Details.module.scss';

function Details(props: EditRoleBaseProps) {
	const { t } = useTranslation();
	const { values } = useFormikContext<EditRoleFormValues>();
	return (
		<Layout.List>
			<Layout.List.ListItem
				title={
					props.isNew ? t('role.editRole.tabs.details') : undefined
				}
			>
				<Layout.List.Section>
					<Input.FormikField
						name="role"
						label={t('role.editRole.fields.name')}
						required
					/>
					<Fx justify="space-between" align="middle">
						<Checkbox.FormikField
							name="isPrimary"
							className={styles.checkbox}
							disabled={!props.isNew}
						>
							{t('role.editRole.fields.isPrimary')}
						</Checkbox.FormikField>
						<Tooltip
							title={t('role.editRole.fields.isPrimaryTooltip')}
						>
							<Icon
								type="infoOutlined"
								color="default"
								size="xl"
							/>
						</Tooltip>
					</Fx>
					{values.isPrimary && (
						<Fx>
							<Checkbox.FormikField
								name="twoFactorAuthorizationEnabled"
								className={styles.checkbox}
							>
								{t(
									'role.editRole.fields.twoFactorAuthentication'
								)}
							</Checkbox.FormikField>
						</Fx>
					)}
				</Layout.List.Section>
			</Layout.List.ListItem>
		</Layout.List>
	);
}

export default Details;
