import { ColumnProps } from 'antd/lib/table';
import {
	useCreateColumns,
	UseCreateColumnsInterface
} from 'Components/Table/createColumns';
import {
	SpotCheckGroupSummaryParams,
	SpotCheckGroupsOverviewItem
} from 'Services/Api/Dashboard/Types';
import {
	renderMoodIndicatorColumn,
	renderTrendIndicator
} from '../../columns.helpers';
import { useTranslation } from 'react-i18next';

export function useColumnProps<T extends SpotCheckGroupsOverviewItem>(
	helpers?: ReturnType<UseCreateColumnsInterface<T, Record<string, string>>>
) {
	const { t } = useTranslation();
	const columns: Array<ColumnProps<T>> = [
		{
			...helpers?.sort,
			sortOrder: helpers?.getSortOrder('groupName'),
			title: t('dashboard.groupSummary.name'),
			key: 'groupName',
			dataIndex: 'groupName',
			width: '63%'
		},
		{
			...helpers?.sort,
			sortOrder: helpers?.getSortOrder('last'),
			title: t('dashboard.timePeriod.previous'),
			key: 'last',
			dataIndex: 'last',
			render: renderMoodIndicatorColumn
		},
		{
			...helpers?.sort,
			sortOrder: helpers?.getSortOrder('current'),
			title: t('dashboard.timePeriod.current'),
			key: 'current',
			dataIndex: 'current',
			render: renderMoodIndicatorColumn
		},
		{
			...helpers?.sort,
			sortOrder: helpers?.getSortOrder('variance'),
			title: t('dashboard.groupSummary.variance'),
			key: 'variance',
			dataIndex: 'variance',
			render: (_, { variance }) => renderTrendIndicator(variance)
		}
	];
	return columns;
}

export function useColumns({
	params
}: {
	params: SpotCheckGroupSummaryParams;
}) {
	const helpers = useCreateColumns<
		SpotCheckGroupsOverviewItem,
		Record<string, string>
	>({
		filterValues: undefined,
		sortDesc: params.sort
	});

	return useColumnProps(helpers);
}
