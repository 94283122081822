import { ColumnProps } from 'antd/lib/table';
import { User, PreviewUser } from 'Services/Api/Users/Types';
import {
	ReportType,
	PreviewReportTypeListItem
} from 'Services/Api/Reports/Types';
import { PreviewReportTypeInterface } from '../PreviewReport.types';
import { usePreviewReportTable } from './PreviewReportTable.hooks';
import { UserActivityReportTable } from './UserActivityReportTable';
import PreviewReportTableComponent from './PreviewReportTableComponent';
import { AllUsersReportTable } from './AllUsersReportTable';

type PreviewReportTableComponentColumn<T> = PreviewReportTypeListItem<T> & {
	uuid: string;
};

const PreviewReportTable: PreviewReportTypeInterface = (props) => {
	const { columns } = usePreviewReportTable(props);

	if (props.data.reportType === ReportType.UserActivity) {
		return (
			<UserActivityReportTable
				data={props.data.data}
				columns={columns as ColumnProps<User>[]}
				reportName={props.data.name}
			/>
		);
	}

	if (props.data.reportType === ReportType.Users) {
		return (
			<AllUsersReportTable
				data={props.data.data}
				columns={columns as ColumnProps<PreviewUser>[]}
				reportName={props.data.name}
			/>
		);
	}

	return (
		<PreviewReportTableComponent<
			PreviewReportTableComponentColumn<typeof props.data.reportType>
		>
			data={props.data}
			columns={
				columns as ColumnProps<
					PreviewReportTableComponentColumn<
						typeof props.data.reportType
					>
				>[]
			}
		/>
	);
};

export default PreviewReportTable;
