import { ExportButton } from 'Components';
import { ExportButtonProps } from 'Components/ExportButton/ExportButton';
import { EXPORT_DELETED_GROUPS } from 'Services/Api/Groups/Queries';
import {
	ExportDeletedGroupsRequest,
	ExportDeletedGroupsResponse
} from 'Services/Api/Groups/Types';

export type DeletedGroupsExportButtonProps = Omit<
	ExportButtonProps<ExportDeletedGroupsResponse, ExportDeletedGroupsRequest>,
	'query' | 'onCompleted'
>;
function DeletedGroupsExportButton(props: DeletedGroupsExportButtonProps) {
	const getQueryUrl = (data: ExportDeletedGroupsResponse) => {
		return data.exportDeletedGroups.downloadUrl;
	};
	return (
		<ExportButton<ExportDeletedGroupsResponse, ExportDeletedGroupsRequest>
			query={EXPORT_DELETED_GROUPS}
			getQueryUrl={getQueryUrl}
			{...props}
		/>
	);
}

export default DeletedGroupsExportButton;
