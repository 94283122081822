import { useQuery } from '@apollo/client';
import { TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { createSortDescription } from 'Components/Table/createColumns';
import { useState } from 'react';
import { GET_SYSTEM_ROLES } from 'Services/Api/Roles/Queries';
import {
	GetSystemRolesRequest,
	GetSystemRolesResponse,
	SystemRole
} from 'Services/Api/Roles/Types';
import useColumns, { TableFilterValues } from './columns';

const GET_ROLES_INITIAL_PARAMS: GetSystemRolesRequest = {
	sort: createSortDescription({
		columnKey: 'role',
		order: 'ascend'
	}),
	filters: {}
};
export const useRoles = () => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const result = useQuery<GetSystemRolesResponse, GetSystemRolesRequest>(
		GET_SYSTEM_ROLES,
		{
			variables: GET_ROLES_INITIAL_PARAMS,
			notifyOnNetworkStatusChange: true
		}
	);

	const refetch = (
		values: GetSystemRolesRequest = GET_ROLES_INITIAL_PARAMS
	) => {
		const variables: GetSystemRolesRequest = values;
		result.refetch(variables);
	};

	const onTableChange: TableProps<SystemRole>['onChange'] = (
		_,
		filters,
		sorter
	) => {
		const sort = createSortDescription<SystemRole>(
			sorter as SorterResult<SystemRole>
		);
		const values = filters as TableFilterValues;
		const variables: GetSystemRolesRequest = {
			sort,
			filters: {
				role: values.role?.[0] ?? undefined
			}
		};
		refetch(variables);
	};

	const columns = useColumns({
		params: result.variables
	});
	const dataSource = result.data?.getSystemRoles || [];

	return {
		result,
		dataSource,
		columns,
		onTableChange,
		selectedRowKeys,
		setSelectedRowKeys,
		refetch
	};
};
