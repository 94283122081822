import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Spin, Notification } from 'Components';
import { useErrorBoundary } from 'Hooks';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getIsPending } from 'Stores/util';
import { useEditReport } from './EditReport/EditReport.hooks';
import EditReportFrom from './EditReport/EditReport';
import { AddReportRequest } from 'Services/Api/Reports/Types';
import { getAppRoutes } from 'Pages/App/App.constants';
import { getAxiosErrorType } from 'Helpers/api';

const EditReport = () => {
	const params = useParams<{ reportId: string }>();
	const { t } = useTranslation();
	const errorBoundary = useErrorBoundary();
	const reportId = Number(params.reportId);

	const stores = useStores();
	const { reportStore } = stores;

	const { initialValues } = useEditReport(reportStore.activeReport);

	const submit = async (params: AddReportRequest) => {
		await reportStore.editReport({
			...params,
			id: reportId
		});
	};

	useEffect(() => {
		async function getReport() {
			try {
				await reportStore.getReport({ reportId });
			} catch (error) {
				const axiosError = (error as unknown) as AxiosError;
				const { isNotFoundError } = getAxiosErrorType(axiosError);
				if (isNotFoundError) {
					errorBoundary.setStatus('notFound');
					return;
				}
				Notification.error({
					description: t('errorCodes.genericErrorMessage')
				});
			}
		}
		getReport();
		return () => {
			stores.clearReportStore();
		};
		// eslint-disable-next-line
	}, []);

	if (getIsPending(reportStore.status.getReport)) {
		return <Spin />;
	}

	if (!initialValues) return null;

	return (
		<EditReportFrom
			initialValues={initialValues}
			submit={submit}
			returnUrl={getAppRoutes().SAVED_REPORTS}
		/>
	);
};

export default observer(EditReport);
