import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { unstable_batchedUpdates } from 'react-dom';
import {
	useState,
	useCallback,
	useEffect,
	useRef,
	useMemo,
	Key,
	MouseEventHandler
} from 'react';
import {
	useFormContext,
	SubmitErrorHandler,
	useFieldArray
} from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';
import { APIErrorCodes, APIWarningCodes, FEErrorCodes } from 'app-types';
import { UNIQUE_ERROR_TYPE, API_DEFINED_ERROR_TYPE } from 'app-config';
import { Notification } from 'Components';
import { getSortedEntities } from 'Helpers/getSortedEntities';
import { ADD_MULTIPLE_USERS } from 'Services/Api/Users/Mutations';
import {
	AddUsersRequest,
	ValidatedUser,
	ValidationError,
	GetFailedUsersRequest,
	GetFailedUsersResponse,
	FailedUser,
	FailedUsersStatus,
	AddUsersResponse
} from 'Services/Api/Users/Types';
import { getAppRoutes } from 'Pages/App/App.constants';
import {
	AddUsersFormValues,
	AddUser,
	PaginationParams,
	ExtendedFieldError
} from './AddUsers.types';
import { useModal } from 'Hooks/useModal';
import getIndexes from 'Helpers/getIndexes';
import {
	MAP_ERRORS,
	PAGE_SIZE_OPTIONS,
	FIRST_PAGE,
	TIMEOUT_DELAY
} from './AddUsers.constants';
import {
	mapValidUserToAddUser,
	getUserValues,
	createInput,
	getCreateMultipleUsersErrorDescription
} from './AddUsers.helpers';
import { useIsModuleSettingDisabled } from 'Pages/User/EditUser/tabs/ModuleSettings/ModuleSettingsContainer';
import { GET_FAILED_USERS } from 'Services/Api/Users/Queries';
import { GET_GROUPS } from 'Services/Api/Groups/Queries';
import { SystemRolesListItem } from 'Services/Api/Roles/Types';
import {
	roleToOption,
	groupToOption
} from 'Pages/User/EditUser/EditUser.helpers';
import {
	isModuleSettingsValidationError,
	ModuleSettingsValidationError,
	ModuleSettingsValidationErrorDataItem
} from 'Pages/User/EditUser/tabs/ModuleSettings/ModuleSettings.helpers';
import { GetGroupsRequest, GetGroupsResponse } from 'Services/Api/Groups/Types';
import { checkApolloError } from 'Helpers/graphql';
import { getJWT } from 'Helpers/getJWT';
import { ShowInactiveModal } from 'Components/InactiveHierarchyModalContent/InactiveHierarchyModalContent.types';
import { useConfigs, useTokenErrorValidation } from 'Hooks';

export function useGetUserValues(user: AddUser) {
	return useMemo(
		() =>
			getUserValues(
				user.groups,
				user.primarySystemRoleId,
				user.moduleSettings
			),
		[user.groups, user.primarySystemRoleId, user.moduleSettings]
	);
}

export function useAddUsers(
	showInactiveModal: ShowInactiveModal['showInactiveModal']
) {
	const { t } = useTranslation();
	const configs = useConfigs();
	const {
		isTokenExpired,
		isTokenInvalid,
		determineTokenErrorForApolloProvider
	} = useTokenErrorValidation();
	// @TODO: may be better to use useReducer here
	const [checkedFields, setCheckedFields] = useState<Key[]>([]);

	const [
		failedUsersStatus,
		setFailedUsersStatus
	] = useState<FailedUsersStatus>();
	const [isForbidden, setIsForbidden] = useState(false);
	const [blockImportXLSXButton, setBlockXLSXButton] = useState(false);
	const [addMultipleUsers] = useMutation<AddUsersResponse, AddUsersRequest>(
		ADD_MULTIPLE_USERS
	);
	const onRowSelectionChange = useCallback((indexes: Key[]) => {
		setCheckedFields(indexes);
	}, []);
	const failedUsers = useRef<FailedUser[]>();
	const errorRef = useRef(null);
	const requestIdRef = useRef<string | null>(null);
	const {
		formState: { isDirty, isSubmitting, errors },
		handleSubmit,
		setValue,
		getValues,
		setError,
		control,
		reset,
		clearErrors
	} = useFormContext<AddUsersFormValues>();

	const [getGroups, { loading: groupsLoading }] = useLazyQuery<
		GetGroupsResponse,
		GetGroupsRequest
	>(GET_GROUPS, {
		onCompleted: (data) => {
			const currentValues = getValues();
			const groups = data.getGroups;
			const users: AddUser[] =
				failedUsers.current?.map(({ userInput }, i) => {
					const primarySystemRole = currentValues.data.systemRoles?.find(
						({ id }) => id === userInput.primarySystemRoleId
					) as SystemRolesListItem;
					const primarySystemRoleId = roleToOption(primarySystemRole);
					return {
						...userInput,
						primarySystemRoleId,
						phoneNumber: userInput.phoneNumber ?? undefined,
						groups: groups
							.filter(({ id }) => userInput.groups?.includes(id))
							.map(groupToOption),
						key: i.toString(),
						fieldId: uuid()
					};
				}) ?? [];

			reset({
				...currentValues,
				users
			});

			const {
				combineErrorCode,
				getErrorDescription
			} = getCreateMultipleUsersErrorDescription(t);

			failedUsers.current?.forEach(({ errors }, userIndex) => {
				errors.forEach(({ errorCode, fieldName }) => {
					combineErrorCode(errorCode);
					// @ts-ignore
					setError(`users.${userIndex}.${fieldName}`, {
						type: API_DEFINED_ERROR_TYPE,
						errorCode
					});
				});
			});
			// @TODO: it would be better to avoid setTimeout
			setTimeout(() => {
				Notification.error({
					description: getErrorDescription({
						amount: configs.maxGroupsAmountForUser
					})
				});
			}, 0);
		}
	});
	const [getFailedUsers, { loading: failedUsersLoading }] = useLazyQuery<
		GetFailedUsersResponse,
		GetFailedUsersRequest
	>(GET_FAILED_USERS, {
		onCompleted: async (data) => {
			if (data.getFailedUsers.status !== FailedUsersStatus.Failed) {
				setFailedUsersStatus(data.getFailedUsers.status);
				return;
			}

			failedUsers.current = data.getFailedUsers.failedUsers;
			requestIdRef.current = data.getFailedUsers.requestId;

			const groupIds = data.getFailedUsers.failedUsers
				.map(({ userInput }) => userInput.groups as number[])
				.flat();
			getGroups({
				variables: {
					input: {
						ids: groupIds
					}
				}
			});
		},
		onError(error) {
			const apolloError = checkApolloError(error);

			const isTokenError = determineTokenErrorForApolloProvider(
				apolloError
			);

			if (isTokenError) return;

			if (
				apolloError.is(APIErrorCodes.GroupsNotInHierarchy) ||
				apolloError.is(APIErrorCodes.RoleIsNotAvailableForUser) ||
				apolloError.is(APIErrorCodes.Forbidden)
			) {
				setIsForbidden(true);
			}
		}
	});

	const { getIsLMSModuleSettingsDisabled } = useIsModuleSettingDisabled();

	const [
		{ currentPage, pageSize },
		setPaginationParam
	] = useState<PaginationParams>({
		currentPage: 1,
		pageSize: Number(PAGE_SIZE_OPTIONS[1])
	});

	const onPaginationChange = useCallback(
		(page: number, pageSize?: number) => {
			setPaginationParam((state) => ({
				currentPage: page,
				pageSize: pageSize ?? state.pageSize
			}));
		},
		[]
	);

	const history = useHistory();
	const [isCopierVisible, setIsCopierVisible] = useState<boolean>(false);
	const [sortErrors, setSortErrors] = useState(false);

	const prevIndexes = useRef<number[]>([]);
	const { append } = useFieldArray({
		control,
		name: 'users'
	});

	const { modal, showModal } = useModal();
	useEffect(() => {
		if (sortErrors) {
			const { users } = getValues();

			const sortedUsers = getSortedEntities<AddUser>(users, ({ index }) =>
				Boolean(errors.users?.[index])
			);

			resetFormValues(sortedUsers);
			// it needs to create correct errors order and synchronize them with users fields
			errors.users?.sort();
			setSortErrors(false);
			onPaginationChange(FIRST_PAGE);
		}
		// eslint-disable-next-line
	}, [sortErrors, errors]);

	useEffect(() => {
		const jwt = getJWT(history.location.search);
		if (jwt) {
			getFailedUsers({
				variables: {
					input: {
						jwt
					}
				}
			});
		}
		// eslint-disable-next-line
	}, []);

	const closeCopier = useCallback(() => {
		setIsCopierVisible(false);
	}, [setIsCopierVisible]);

	const handleUsersFromXLSXFile = useCallback(
		async (validatedUsers: ValidatedUser[]) => {
			// @NOTE: we use 0 index because system has only one type of warning
			const hasInactiveOrArchivedGroupWarnings = validatedUsers.some(
				(validatedUser) =>
					validatedUser.warnings[0]?.warningCode ===
					APIWarningCodes.InactiveGroupOrHierarchy
			);
			if (hasInactiveOrArchivedGroupWarnings) {
				await showInactiveModal();
			}
			// @TODO: unstable_batchedUpdates is outdated functionality and has to be deleted after moving to react > v.18
			unstable_batchedUpdates(() =>
				processValidatedUsers(validatedUsers)
			);
		},
		// eslint-disable-next-line
		[errors, getValues]
	);
	// @NOTE: it would be better to move this functionality to Web Workers example: https://bitbucket.org/peak-iii/peakiii/branch/VNZ-worker-test
	const validateCognitoLogin = useCallback(
		(shouldNotRenderNotification = false) => {
			const users = getValues().users;
			if (users.length > 1) {
				const indexes = getIndexes(users, 'cognitoLogin');

				if (indexes.length) {
					if (!isEqual(prevIndexes.current, indexes)) {
						// @TODO: technical debt it would be to refactor code in order to use this check only once per 'validateCognitoLogin' function scope
						// needs for clean errors associated with cognitoLogin (some of fields may not contain error after checks, without check fields may highlighted wrong)
						const prevErrors = prevIndexes.current.reduce<string[]>(
							(acc, index) => {
								const user = errors.users?.[index];
								if (
									user?.cognitoLogin?.type ===
										UNIQUE_ERROR_TYPE &&
									// @ts-ignore
									!user?.cognitoLogin?.errorCode
								) {
									acc.push(`users.${index}.cognitoLogin`);
								}
								return acc;
							},
							[]
						);

						if (prevErrors.length) {
							// @ts-ignore
							clearErrors(prevErrors);
						}

						if (!shouldNotRenderNotification) {
							Notification.error({
								description: t(
									`errorCodes.${APIErrorCodes.Unique}`,
									{
										path: t('user.cognitoLogin')
									}
								)
							});
						}
					}

					prevIndexes.current = indexes;

					indexes.forEach((index: number) => {
						setError(`users.${index}.cognitoLogin`, {
							type: UNIQUE_ERROR_TYPE
						});
					});

					throw Error(FEErrorCodes.UniqError);
				}
				prevIndexes.current = [];

				const userFieldErrorPaths = users.reduce<string[]>(
					(acc, _, index) => {
						const user = errors.users?.[index];
						if (
							user?.cognitoLogin?.type === UNIQUE_ERROR_TYPE &&
							// @ts-ignore
							!user?.cognitoLogin?.errorCode
						) {
							acc.push(`users.${index}.cognitoLogin`);
						}
						return acc;
					},
					[]
				);
				// @ts-ignore
				clearErrors(userFieldErrorPaths);
			}
		},
		// eslint-disable-next-line
		[errors]
	);

	const handleAPIDefinedErrors = useCallback(
		(
			combineErrorCode?: ReturnType<
				typeof getCreateMultipleUsersErrorDescription
			>['combineErrorCode']
		) => {
			const errorsUsers = errors.users;
			if (!Array.isArray(errorsUsers)) return;
			const hasAPIDefinedError = errorsUsers.some(
				(error) =>
					error &&
					Object.values(error)?.some((err) => {
						const error = err as ExtendedFieldError;
						return (
							error?.type === API_DEFINED_ERROR_TYPE &&
							error?.errorCode
						);
					})
			);
			if (hasAPIDefinedError) {
				errorsUsers.forEach((error, index) => {
					if (error) {
						Object.keys(error).forEach((field) => {
							// @ts-ignore
							const extendedError = error[
								field
							] as ExtendedFieldError;
							if (extendedError?.errorCode) {
								combineErrorCode?.(extendedError?.errorCode);
								setError(
									// @ts-ignore
									`users.${index}.${field}`,
									{
										type: API_DEFINED_ERROR_TYPE,
										errorCode: extendedError?.errorCode
									}
								);
							}
						});
					}
				});
				throw Error(FEErrorCodes.MultipleUniqError);
			}
		},
		// eslint-disable-next-line
		[errors.users]
	);

	const validateCognitoLoginOnAction = useCallback(() => {
		// @NOTE: 'setTimeout' needs for smooth transition between cells in the table
		setTimeout(() => {
			try {
				// @TODO: unstable_batchedUpdates is outdated functionality and has to be deleted after moving to react > v.18
				unstable_batchedUpdates(validateCognitoLogin);
			} catch (error) {
				// @NOTE: try catch block needs for handle Uniq Error case
			}
		}, TIMEOUT_DELAY);
	}, [validateCognitoLogin]);

	const resetPhoneNumberField = useCallback(
		(fieldKey: string) => {
			const key = Number(fieldKey);
			if (
				errors.users?.[key]?.phoneNumber?.type ===
				API_DEFINED_ERROR_TYPE
			) {
				clearErrors(`users.${key}.phoneNumber`);
			}
		},
		// eslint-disable-next-line
		[errors]
	);

	const processValidatedUsers = (validatedUsers: ValidatedUser[]) => {
		const values = getValues();
		// divide users
		const validUsers: AddUser[] = [];

		const invalidUsers = values.users.reduce<AddUser[]>(
			(invalidUsers, user, index) => {
				if (errors?.users?.[index]) {
					invalidUsers.push(user);
				} else {
					validUsers.push(user);
				}
				return invalidUsers;
			},
			[]
		);

		const errorsUsers = Array.isArray(errors.users)
			? errors.users.sort()
			: [];
		const errorsXLSX = errorsUsers.reduce<
			Record<string, ValidationError[]>
		>((acc, error, index) => {
			if (error) {
				acc[String(index)] = (error as unknown) as ValidationError[];
			}
			return acc;
		}, {});

		let count = 0;
		const validUsersXLSX: AddUser[] = [];
		const invalidUsersXLSX = validatedUsers.reduce<AddUser[]>(
			(acc, validatedUser) => {
				const { errors } = validatedUser;
				const formattedUser = mapValidUserToAddUser(
					validatedUser,
					values.data.systemRoles
				);
				if (errors.length) {
					acc.push(formattedUser);
					errorsXLSX[count + invalidUsers.length] = errors;
					count += 1;
				} else {
					validUsersXLSX.push(formattedUser);
				}
				return acc;
			},
			[]
		);

		const users = invalidUsers
			.concat(invalidUsersXLSX, validUsersXLSX, validUsers)
			.map((user, index) => ({
				...user,
				key: String(index)
			}));

		setValue('users', users, {
			shouldValidate: true,
			shouldDirty: true
		});

		const errorsIndexes = Object.keys(errorsXLSX);

		errorsIndexes.forEach((index) => {
			const rowErrors = errorsXLSX[index];
			const fieldErrors = Array.isArray(rowErrors)
				? rowErrors
				: Object.keys(rowErrors).map((fieldName) => ({
						fieldName
				  }));
			fieldErrors.forEach(({ fieldName }) => {
				setError(
					// @ts-ignore
					`users.${(index as unknown) as number}.${
						// @ts-ignore
						MAP_ERRORS[fieldName]
					}`,
					{
						type: API_DEFINED_ERROR_TYPE
					}
				);
			});
		});

		setSortErrors(true);
		setBlockXLSXButton(true);

		if (
			invalidUsersXLSX.length &&
			validatedUsers.some((user) =>
				user.errors.some(
					(error) =>
						error.errorCode ===
						APIErrorCodes.UserNameShouldBeUniquePerUserPool
				)
			)
		) {
			Notification.error({
				description: t(
					`user.errorCodes.${APIErrorCodes.UserNameShouldBeUniquePerUserPool}`
				)
			});
		}

		Notification.success({
			description: t('notification.importFile.success')
		});
	};

	const openXLSXModal = () => {
		showModal({ value: null });
	};

	const resetFormValues = (sortedUsers: AddUser[]) => {
		const { users, ...currentValues } = getValues();
		reset(
			{
				...currentValues,
				users: sortedUsers
			},
			{
				keepErrors: true,
				keepDirty: true
			}
		);
	};
	const onError: SubmitErrorHandler<AddUsersFormValues> = (err) => {
		const { users } = getValues();
		const failedUsers = (users as AddUser[])
			.reduce<string[]>((acc, { cognitoLogin }, i) => {
				if (cognitoLogin && err.users?.[i]) {
					acc.push(cognitoLogin);
				}
				return acc;
			}, [])
			.join(', ');

		Notification.error({
			description: t('users.addUsers.notification.userUploadFailed', {
				users: failedUsers.length ? `( ${failedUsers} )` : ''
			})
		});

		// @ts-ignore
		err.users = users.map((_, i) => {
			if (errors?.users?.[i] && err?.users?.[i]) {
				return {
					...err?.users?.[i],
					...errors?.users?.[i]
				};
			}
			if (errors?.users?.[i] && !err?.users?.[i]) {
				return {
					...errors?.users?.[i]
				};
			}
			if (err?.users?.[i] && !errors?.users?.[i]) {
				return {
					...err?.users?.[i]
				};
			}
			return null;
		});

		const sortedUsers = getSortedEntities<AddUser>(users, ({ index }) =>
			Boolean(err?.users?.[index])
		);
		// @ts-ignore
		errorRef.current = err?.users;
		resetFormValues(sortedUsers);
		err?.users?.sort();

		setTimeout(() => {
			// @TODO: unstable_batchedUpdates is outdated functionality and has to be deleted after moving to react > v.18
			unstable_batchedUpdates(updateAPIDefinedErrors);
		}, TIMEOUT_DELAY);
	};

	function updateAPIDefinedErrors() {
		if (Array.isArray(errorRef.current)) {
			// @ts-ignore
			errorRef.current.forEach((error, index) => {
				if (error) {
					Object.keys(error).forEach((fieldName) => {
						if (error[fieldName].type === API_DEFINED_ERROR_TYPE) {
							setError(
								// @ts-ignore
								`users.${index}.${fieldName}`,
								{
									...error[fieldName]
								}
							);
						}
					});
				}
			});
		}
	}

	function setModuleSettingsError(
		data: ModuleSettingsValidationErrorDataItem[]
	) {
		data.forEach(({ itemNumber, lmsFieldName }) => {
			const fieldNames =
				lmsFieldName === 'both'
					? ['userRoleId', 'jobClassId']
					: [lmsFieldName];
			fieldNames.forEach((fieldName) => {
				setError(
					// @ts-ignore
					`users.${itemNumber}.moduleSettings.${fieldName}`,
					{
						type: UNIQUE_ERROR_TYPE
					}
				);
			});
		});
	}

	const onSubmit = async ({ users }: AddUsersFormValues) => {
		const {
			combineErrorCode,
			getErrorDescription
		} = getCreateMultipleUsersErrorDescription(t);
		let input;
		try {
			// @TODO: unstable_batchedUpdates is outdated functionality and has to be deleted after moving to react > v.18
			unstable_batchedUpdates(() =>
				handleAPIDefinedErrors(combineErrorCode)
			);

			validateCognitoLogin(true);

			input = createInput(
				users,
				getIsLMSModuleSettingsDisabled,
				requestIdRef.current
			);
		} catch (e) {
			const error = e as Error;
			if (isModuleSettingsValidationError(error)) {
				const { data } = error as ModuleSettingsValidationError;

				if (Array.isArray(data)) {
					// @TODO: unstable_batchedUpdates is outdated functionality and has to be deleted after moving to react > v.18
					unstable_batchedUpdates(() => setModuleSettingsError(data));

					Notification.error({
						description: t(
							`user.validationError.moduleSettingsEnabledRequired`
						)
					});
				}
			} else if (error?.message === FEErrorCodes.UniqError) {
				Notification.error({
					description: t(`errorCodes.${APIErrorCodes.Unique}`, {
						path: t('user.cognitoLogin')
					})
				});
			} else if (error?.message === FEErrorCodes.MultipleUniqError) {
				Notification.error({
					description: getErrorDescription({
						amount: configs.maxGroupsAmountForUser
					})
				});
			}
			setSortErrors(true);
			// @NOTE: setTimeout uses as a background process in order to synchronize indexes of duplicated 'cognitoLogin's and avoid unnecessary renders of Notification about login uniques
			setTimeout(() => {
				const users = getValues().users;
				const indexes = getIndexes(users, 'cognitoLogin');
				prevIndexes.current = indexes;
			}, TIMEOUT_DELAY);
		}
		if (input) {
			try {
				const statusOrRequestId = await addMultipleUsers({
					variables: {
						input
					}
				});
				if (
					statusOrRequestId.data?.createMultipleUsers ===
						FailedUsersStatus.InProgress ||
					statusOrRequestId.data?.createMultipleUsers ===
						FailedUsersStatus.Complete
				) {
					setFailedUsersStatus(
						statusOrRequestId.data?.createMultipleUsers
					);
					return;
				}

				Notification.success({
					description: t('users.addUsers.notification.processStarted')
				});

				history.push(getAppRoutes().USERS);
			} catch (e) {
				Notification.error({
					description: t('errorCodes.genericErrorMessage')
				});
			}
		}
	};

	const onSubmitClick: MouseEventHandler<HTMLElement> = (ev) => {
		handleSubmit(onSubmit, onError)(ev);
	};

	const toggleCopierVisible = () => {
		setIsCopierVisible((isVisible) => !isVisible);
	};

	const goBack = () => {
		history.push(getAppRoutes().USERS);
	};

	return {
		t,
		goBack,
		toggleCopierVisible,
		onRowSelectionChange,
		onSubmitClick,
		openXLSXModal,
		validateCognitoLoginOnAction,
		handleUsersFromXLSXFile,
		closeCopier,
		onPaginationChange,
		append,
		setCheckedFields,
		clearErrors,
		resetPhoneNumberField,
		modal,
		currentPage,
		pageSize,
		isCopierVisible,
		isDirty,
		isSubmitting,
		checkedFields,
		blockImportXLSXButton,
		isTokenExpired,
		isTokenInvalid,
		isForbidden,
		failedUsersStatus,
		loading: failedUsersLoading || groupsLoading
	};
}
