import { Trans } from 'react-i18next';
import { assistanceComponents } from './constants';

interface CustomerSupportNotificationProps {
	hasError?: boolean;
}

const CustomerSupportNotification = ({
	hasError
}: CustomerSupportNotificationProps) => {
	return (
		<Trans
			i18nKey={`emailValidation.${
				hasError ? 'messages.errors.tokenValidation' : 'assistance'
			}`}
			components={assistanceComponents}
		/>
	);
};

export default CustomerSupportNotification;
