import { gql } from '@apollo/client';

export const GET_CONFIGS = gql`
	query GetConfigs {
		getConfigs {
			name
			value
		}
	}
`;
