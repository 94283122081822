import { memo } from 'react';
import { JobClassField as JobClassFieldComponent } from 'Pages/User/components';
import {
	ModuleSettingsBusiness,
	ModuleSettingsFieldProps,
	areEqual
} from './ModuleSettingsBusiness';
import { AddUsersFormFieldBaseProps } from '../AddUsers.types';
import ModuleSettingsContainer, {
	useJobClass,
	useResetLmsFieldsOnGroupsChange
} from 'Pages/User/EditUser/tabs/ModuleSettings/ModuleSettingsContainer';
import styles from '../AddUsers.module.scss';

const JobField = memo<ModuleSettingsFieldProps>(
	({ userValues, onDisabled, currentError, currentValue, onChange }) => {
		const { loading, data } = useJobClass({ values: userValues });
		useResetLmsFieldsOnGroupsChange({
			values: userValues,
			resetLmsFields: onDisabled
		});
		return (
			<ModuleSettingsContainer
				values={userValues}
				onDisabled={onDisabled}
			>
				{(disabled) => (
					<>
						<JobClassFieldComponent
							label={false}
							value={currentValue}
							onChange={onChange}
							disabled={disabled}
							error={currentError}
							className={styles.addUsersSelect}
							blockHelp
							options={data}
							loading={loading}
						/>
					</>
				)}
			</ModuleSettingsContainer>
		);
	},
	areEqual
);

function JobClassField(props: AddUsersFormFieldBaseProps) {
	return (
		<ModuleSettingsBusiness {...props} fieldRole="jobClassId">
			{(props) => (
				<>
					<JobField {...props} />
				</>
			)}
		</ModuleSettingsBusiness>
	);
}

export default JobClassField;
