import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { isResponseError } from 'app-util';
import { getAppRoutes } from 'Pages/App/App.constants';
import {
	Space,
	Spin,
	RedirectToLogin,
	Notification,
	CustomerSupportNotification,
	RedirectToTwoFactorAuthorization
} from 'Components';
import ScreenLayout from 'Components/ErrorsScreen/ErrorScreenLayout';
import { ReportByTokenType } from 'Services/Api/Reports/Types';
import { useStores } from 'Hooks/useStore';
import { useTokenErrorValidation } from 'Hooks';
import { getIsPending } from 'Stores/util';
import { getRemainingTime } from './DownloadReport.helpers';
import DownloadReportButton from './DownloadReportButton/DownloadReportButton';
import { getAxiosErrorType } from 'Helpers/api';

const PATH = 'downloadReport';

function DownloadReport() {
	const {
		isTokenExpired,
		isTokenInvalid,
		determineTokenErrorForAxiosService
	} = useTokenErrorValidation();
	const { token } = useParams<{ token?: string }>();
	const { t } = useTranslation();
	const stores = useStores();
	const { reportStore, authStore } = stores;
	const { status, reportByToken: report } = reportStore;

	useEffect(() => {
		verifyToken();
		return () => {
			stores.clearReportStore();
		};
		// eslint-disable-next-line
	}, []);

	const verifyToken = async () => {
		await authStore.checkAuth();
		if (token) {
			try {
				await reportStore.getReportByToken({ token });
			} catch (e) {
				if (!isResponseError(e)) return;
				const { isNotFoundError } = getAxiosErrorType(e);

				if (isNotFoundError) {
					determineTokenErrorForAxiosService(e);
				}
			}
		}
	};

	if (getIsPending(status.getReportByToken)) return <Spin />;

	const appRoutes = getAppRoutes();

	if (isTokenInvalid) {
		Notification.error({
			description: <CustomerSupportNotification />
		});

		return <Redirect to={appRoutes.HOME} />;
	}

	if (isTokenExpired) return <Redirect to={appRoutes.TOKEN_EXPIRED} />;

	if (!report) return null;
	// @NOTE: allows to handle reports types for which user authentication is required, despite the fact that Download report link is public
	if (
		report.type !== ReportByTokenType.UserSavedReports &&
		!authStore.isAuthenticated
	) {
		if (authStore.isTwoFactorAuthorizationRequired) {
			return <RedirectToTwoFactorAuthorization />;
		}
		return <RedirectToLogin />;
	}

	const remainingTime = getRemainingTime(PATH, report.expirationDate);

	return (
		<ScreenLayout
			translationPath={PATH}
			goHomeButton={false}
			footer={<DownloadReportButton />}
		>
			{t(`${PATH}.nameDescription`, {
				reportName: report.name
			})}
			<br />
			<br />
			{t(`${PATH}.expiresInDescription`)}
			<br />
			<Space>
				{remainingTime.map((time, index) => (
					<span key={index}>{t(time.key, time.params)}</span>
				))}
			</Space>
		</ScreenLayout>
	);
}

export default observer(DownloadReport);
