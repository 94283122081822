import { Notification } from 'Components';
import { checkApolloError } from 'Helpers/graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportButtonProps } from './ExportButton';
import { AxiosErrorResponse } from 'app-types';
import { useHandleDownloadError, useDownloadReportQuery } from 'Hooks';

export const useExportButton = <TData, TVariables>({
	query,
	onExport,
	onCompleted,
	getQueryUrl,
	params,
	disabled
}: ExportButtonProps<TData, TVariables>) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [exportFile, result] = useDownloadReportQuery<TData, TVariables>(
		query,
		{
			onError: (error) => {
				const apolloError = checkApolloError(error);
				const errors = apolloError.getNonFieldErrors(t);
				if (errors.length) {
					Notification.error({
						description: errors
					});
				}
			}
		},
		getQueryUrl
	);

	const handleDownloadError = useHandleDownloadError();

	const handleDownload = async () => {
		if (params && query) {
			exportFile({
				variables: params
			});
		} else if (onExport) {
			try {
				setLoading(true);
				const result = await onExport();
				onCompleted?.(result);
				Notification.success({
					description: t('notification.downloadFile.success')
				});
			} catch (e) {
				const axiosError = e as AxiosErrorResponse;
				handleDownloadError(axiosError);
			} finally {
				setLoading(false);
			}
		}
	};

	return {
		handleDownload,
		loading: query ? result.loading : loading,
		disabled: query ? !params || disabled : disabled
	};
};
