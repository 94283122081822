import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { AxiosErrorResponse } from 'app-types';
import { Button, Notification } from 'Components';
import { ButtonProps } from 'Components/Button/Button';
import PreviewReportModal from './PreviewReportModal';

import ModalContextProvider, { useModal } from 'Hooks/useModal';
import { useStores } from 'Hooks/useStore';

import { PreviewReportModalValue } from './PreviewReport.types';
import { ReportFormValues } from '../EditReport/schema';

import { getIsPending } from 'Stores/util';
import { preparePreviewRequest } from './PreviewReport.helpers';
import { getAxiosErrorType } from 'Helpers/api';

type PreviewReportButtonProps = ButtonProps;
const PreviewReportContent = observer((props: PreviewReportButtonProps) => {
	const { t } = useTranslation();
	const { reportStore, authStore } = useStores();
	const { modal, showModal } = useModal();
	const { values } = useFormikContext<ReportFormValues>();

	const handlePreview = async () => {
		try {
			const params = preparePreviewRequest(values);
			const data = await reportStore.previewReport(params);

			if (!data) return;

			const modalValue: PreviewReportModalValue = {
				data
			};

			showModal({ value: modalValue });
		} catch (e) {
			let description = t('errorCodes.genericErrorMessage');
			const {
				isUnprocessableEntity,
				isNotFoundError
			} = getAxiosErrorType(e as AxiosErrorResponse);

			if (
				(isUnprocessableEntity || isNotFoundError) &&
				authStore.isCurrentUserOwner
			) {
				description = t('errorCodes.NO_AVAILABLE_PROPERTIES');
			}

			Notification.error({
				description
			});
		}
	};

	return (
		<>
			<Button
				{...props}
				ghost
				shape="round"
				type="primary"
				onClick={handlePreview}
				loading={getIsPending(reportStore.status.previewReport)}
			>
				{t('report.preview')}
			</Button>
			{modal && <PreviewReportModal />}
		</>
	);
});

const PreviewReportButton = (props: PreviewReportButtonProps) => (
	<ModalContextProvider>
		<PreviewReportContent {...props} />
	</ModalContextProvider>
);

export default PreviewReportButton;
