import { gql } from '@apollo/client';

export const GET_SYSTEM_ROLES = gql`
	query getSystemRoles($filters: SystemRoleListFilters, $sort: String) {
		getSystemRoles(filters: $filters, sort: $sort) {
			id
			role
			usersCount
			permissionsCount
			createdDate
		}
	}
`;

export const GET_SYSTEM_ROLES_LIST = gql`
	query GetSystemRolesList($action: String!) {
		getSystemRolesList(action: $action) {
			id
			role
			isPrimary
			permissions {
				id
				code
			}
		}
	}
`;

export const GET_PERMISSIONS = gql`
	query getPermissions($systemRoleId: Int) {
		getPermissions(systemRoleId: $systemRoleId) {
			core {
				id
				name
				permissions {
					id
					code
				}
			}
			lms {
				id
				name
				permissions {
					id
					code
				}
			}
		}
	}
`;

export const GET_SYSTEM_ROLE = gql`
	query getSystemRole($id: Int!) {
		getSystemRole(id: $id) {
			id
			role
			isPrimary
			twoFactorAuthorizationEnabled
			permissions {
				id
				code
			}
		}
	}
`;

export const EXPORT_SYSTEM_ROLES = gql`
	query exportSystemRoles(
		$filters: SystemRoleListFilters
		$sort: String
		$downloadId: String
	) {
		exportSystemRoles(
			filters: $filters
			sort: $sort
			downloadId: $downloadId
		) {
			downloadUrl
		}
	}
`;

export const GET_BASE_SYSTEM_ROLES = gql`
	query GetBaseSystemRoles {
		getBaseSystemRoles {
			id
			role
			isPrimary
			permissions {
				id
				code
			}
		}
	}
`;
