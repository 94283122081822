import { getAppRoutes } from 'Pages/App/App.constants';
import {
	DynamicPermissionCode,
	PermissionCode
} from 'Services/Api/Users/Types';
import { getLeadPath } from './AppHeader.helpers';
import { AppHeaderMenuItem, NavMenuKey } from './AppHeader.types';

export const PATH_BY_MENU_KEY = () => {
	const appRoutes = getAppRoutes();

	return {
		[NavMenuKey.Dashboard]: appRoutes.HOME,
		[NavMenuKey.Users]: appRoutes.USERS,
		[NavMenuKey.Groups]: appRoutes.GROUPS,
		[NavMenuKey.OwnershipGroups]: appRoutes.OWNERSHIP_GROUPS,
		[NavMenuKey.Roles]: appRoutes.ROLES,
		[NavMenuKey.Reports]: appRoutes.REPORTS,
		[NavMenuKey.AuditLogReport]: appRoutes.AUDIT_LOG,
		[NavMenuKey.Modules]: undefined,
		[NavMenuKey.PeakPortal]: undefined
	};
};

export const MENU_ITEMS: AppHeaderMenuItem[] = [
	{
		key: NavMenuKey.Dashboard,
		title: 'appHeader.dashboard'
	},
	{
		title: 'appHeader.manage',
		children: [
			{
				key: NavMenuKey.Users,
				title: 'appHeader.users',
				permission: { dynamicCode: DynamicPermissionCode.ViewUsers }
			},
			{
				key: NavMenuKey.Groups,
				title: 'appHeader.groups',
				permission: { code: PermissionCode.ViewGroups }
			},
			{
				key: NavMenuKey.OwnershipGroups,
				title: 'appHeader.ownershipGroups',
				permission: { code: PermissionCode.ViewOwnershipGroups }
			},
			{
				key: NavMenuKey.Roles,
				title: 'appHeader.roles',
				permission: { code: PermissionCode.ViewRoles }
			}
		]
	},
	{
		title: 'appHeader.reports',
		children: [
			{
				key: NavMenuKey.Reports,
				title: 'appHeader.reports',
				permission: { code: PermissionCode.ViewStandardReports }
			},
			{
				key: NavMenuKey.AuditLogReport,
				title: 'appHeader.auditLog',
				permission: { code: PermissionCode.ViewAuditLog }
			}
		]
	}
];

export const MENU_KEY_BY_LEAD_PATH = () => {
	const pathByMenuKey = PATH_BY_MENU_KEY();

	return Object.keys(pathByMenuKey).reduce<Record<string, NavMenuKey>>(
		(acc, value) => {
			const key = value as NavMenuKey;
			const partial = getLeadPath(pathByMenuKey[key]);
			if (partial) {
				acc[partial] = key;
			}
			return acc;
		},
		{}
	);
};
