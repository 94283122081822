import { useTranslation } from 'react-i18next';
import { Input, Layout, Select } from 'Components';
import {
	GROUP_ACTIVE_STATUS_LABEL_MAP,
	GROUP_ACTIVE_STATUS_LIST
} from 'app-config';
import ModalContextProvider from 'Hooks/useModal';
import PropertiesField from './PropertiesField/PropertiesField';

function AddOwnershipGroupFormFields() {
	const { t } = useTranslation();

	return (
		<Layout.List>
			<Layout.List.ListItem>
				<Layout.List.Section>
					<Input.FormikField
						name="name"
						label={t(
							'ownershipGroup.addOwnershipGroup.fieldLabels.name'
						)}
						required
					/>
					<Select.FormikField
						name="status"
						label={t(
							'ownershipGroup.addOwnershipGroup.fieldLabels.status'
						)}
						required
					>
						{GROUP_ACTIVE_STATUS_LIST.map((status) => (
							<Select.Option key={status} value={status}>
								{GROUP_ACTIVE_STATUS_LABEL_MAP[status]()}
							</Select.Option>
						))}
					</Select.FormikField>

					<ModalContextProvider>
						<PropertiesField />
					</ModalContextProvider>
				</Layout.List.Section>
			</Layout.List.ListItem>
		</Layout.List>
	);
}

export default AddOwnershipGroupFormFields;
