import { ErrorScreen, Notification } from 'Components';
import { observer } from 'mobx-react-lite';
import { getAppRoutes } from 'Pages/App/App.constants';
import PrivateApp from 'Pages/App/PrivateApp';
import ResetPasswordForm, {
	ResetPasswordFormValues
} from 'Pages/Auth/ResetPassword/ResetPasswordForm';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { useResetExpiredPassword } from '../hooks';
import { AuthState } from '../Auth.types';
import AuthLayout from '../AuthLayout';
import {
	COGNITO_ERROR_MSG,
	COGNITO_ERROR_MSG_TO_DESCRIPTION_MAP
} from 'app-config';

function ResetTemporaryPassword() {
	const history = useHistory<AuthState>();
	const { t } = useTranslation();
	const resetTemporaryPassword = useResetExpiredPassword();
	const onSubmit = async (values: ResetPasswordFormValues) => {
		try {
			await resetTemporaryPassword(values.login);
		} catch (e) {
			Notification.error({
				description: t('errorCodes.genericErrorMessage')
			});
		}
	};

	const { cognitoLoginTemporaryPasswordExpired } =
		history.location.state ?? {};

	if (!cognitoLoginTemporaryPasswordExpired) {
		return <ErrorScreen.NotFound />;
	}
	const cognitoError =
		COGNITO_ERROR_MSG_TO_DESCRIPTION_MAP[
			COGNITO_ERROR_MSG.TEMPORARY_PASSWORD_EXPIRED
		];

	return (
		<PrivateApp>
			{(isAuthenticated) => (
				<>
					{isAuthenticated ? (
						<Redirect to={getAppRoutes().HOME} />
					) : (
						<AuthLayout>
							<ResetPasswordForm
								initialValues={{
									login: cognitoLoginTemporaryPasswordExpired
								}}
								errorMessage={t(cognitoError)}
								onSubmit={onSubmit}
							/>
						</AuthLayout>
					)}
				</>
			)}
		</PrivateApp>
	);
}

export default observer(ResetTemporaryPassword);
