import { Divider as AntDivider, DividerProps as AntDividerProps } from 'antd';
import cn from 'classnames';
import styles from './Divider.module.scss';

type DividerProps = AntDividerProps;

const Divider = (props: DividerProps) => {
	return (
		<AntDivider {...props} className={cn(props.className, styles.root)} />
	);
};

export default Divider;
