import DataTreeLayout, {
	DataTreeLayoutProps
} from './components/DataTreeLayout/DataTreeLayout';
import List from './components/List/List';
import Search from './components/Search/Search';

type DataTreeInterface<P> = React.FC<P> & {
	List: typeof List;
	Search: typeof Search;
};
const DataTree: DataTreeInterface<DataTreeLayoutProps> = (props) => {
	return <DataTreeLayout {...props} />;
};

DataTree.List = List;
DataTree.Search = Search;

export default DataTree;
