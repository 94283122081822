import React, { forwardRef } from 'react';
import { Input as AntInput } from 'antd';
import cn from 'classnames';
import { InputProps as AntInputProps } from 'antd/lib/input';
import {
	createFormikField,
	createHookFormField,
	FormikFieldMapProps
} from 'Helpers/components';
import styles from './Input.module.scss';
import { isFunction } from 'lodash';
import { InputPassword } from '../PasswordInput';
import { useInputAllowClearProps } from './Input.hooks';

type FormInterface = React.FC<AntInputProps> & {
	Search: typeof AntInput.Search;
	FormikField: typeof FormikField;
	PasswordFormikField: typeof InputPassword;
	HookFormInput: typeof HookFormInput;
};
const Input: FormInterface = (props) => {
	const allowClearProps = useInputAllowClearProps(props);

	return (
		<AntInput
			{...props}
			{...allowClearProps}
			className={cn(props.className, styles.root)}
		/>
	);
};

export const mapInputFormikFieldProps: FormikFieldMapProps<AntInputProps> = (
	props
) => ({
	onChange: (e) => {
		props.props.onChange?.(e);
		return props.helpers.setValue(e.currentTarget.value);
	},
	onBlur: (e) => {
		props.field.onBlur(e);
		props.props.onBlur?.(e);
	}
});
const FormikField = createFormikField(Input, mapInputFormikFieldProps);

const InputPasswordFormikField = createFormikField(InputPassword, (props) => ({
	onChange: (origin: string) => {
		return props.helpers.setValue(origin.trim());
	},
	onBlur: () => {
		props.helpers.setTouched(true);
	},
	hasError: Boolean(props.meta.touched ? props.meta.error : undefined)
}));

const HookFormInput = createHookFormField(Input);

Input.Search = forwardRef((props, ref) => {
	const allowClearProps = useInputAllowClearProps(props);

	return (
		<AntInput.Search
			{...props}
			{...allowClearProps}
			ref={(instance) => {
				if (ref) {
					if (isFunction(ref)) {
						ref(instance);
					} else {
						ref.current = instance;
					}
				}
				allowClearProps.ref.current = instance;
			}}
			className={cn(props.className, styles.root)}
		/>
	);
});

Input.FormikField = FormikField;
Input.PasswordFormikField = InputPasswordFormikField;
Input.HookFormInput = HookFormInput;

export default Input;
