import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { Checkbox, Col, Fx, Layout, OptionalValue, Row } from 'Components';
import ReportTypeFilters from './ReportTypeFilters';

import { useDashboardFilters } from 'Pages/Dashboard/DashboardFilters/DashboardFilters.hooks';
import { ReportType } from 'Services/Api/Reports/Types';
import { REPORT_FIELD_LABEL } from '../EditReport.constants';
import styles from '../EditReport.module.scss';
import { ReportFormValues } from '../schema';
import GroupsField, { GroupsFieldProps } from './GroupsField/GroupsField';

interface Props {
	reportType?: ReportType;
}

function Filters(props: Props) {
	const { t } = useTranslation();
	const {
		initialValues,
		values,
		setFieldValue
	} = useFormikContext<ReportFormValues>();

	const {
		submitting,
		isPropertyCheckboxVisible,
		onGroupsChange
	} = useDashboardFilters({
		initialValue: initialValues.groupId
			? [initialValues.groupId]
			: undefined,
		onChange: (value) => {
			if (value.hasOwnProperty('isProperty')) {
				setFieldValue('showOnlyProperties', value.isProperty);
			}
		},
		withInactive:
			props.reportType === ReportType.Users ||
			props.reportType === ReportType.Groups
	});

	const setGroupValue = (
		value: Partial<Parameters<GroupsFieldProps['onConfirm']>[0]> = {}
	) => {
		setFieldValue('ownershipGroupId', value.ownershipGroupId);
		setFieldValue('groupId', value.groupId, true);

		onGroupsChange(value.groupId ? [value.groupId.key] : []);
	};

	const handleConfirm: GroupsFieldProps['onConfirm'] = (value) => {
		setGroupValue(value);

		if (value.ownershipGroupId) {
			setFieldValue('showOnlyProperties', false);
		}
	};

	const isAvailable = initialValues.reportType === ReportType.Roles;
	const isShowOnlyPropertiesVisible =
		isPropertyCheckboxVisible && !values.ownershipGroupId;
	return (
		<Layout.List.ListItem
			wrapInBody={false}
			title={t('report.sections.filters')}
		>
			{isAvailable ? (
				<OptionalValue />
			) : (
				<>
					<Row>
						<Col xs={10}>
							<GroupsField
								disabled={submitting}
								onClear={setGroupValue}
								onConfirm={handleConfirm}
							/>
						</Col>
						<Col xs={14}>
							<Fx
								align="bottom"
								fullHeight
								className={styles.propertiesCheckbox}
							>
								{isShowOnlyPropertiesVisible && (
									<Checkbox.FormikField
										name="showOnlyProperties"
										disabled={submitting}
									>
										{t(
											REPORT_FIELD_LABEL.showOnlyProperties
										)}
									</Checkbox.FormikField>
								)}
							</Fx>
						</Col>
					</Row>
					<ReportTypeFilters />
				</>
			)}
		</Layout.List.ListItem>
	);
}

export default Filters;
