import { PROPERTY_LABEL } from 'app-config';
import { Radio } from 'Components';
import { useFormikContext } from 'formik';
import { isBoolean } from 'lodash';
import { AddGroupValues, EditGroupValues } from 'Pages/Group/Group.types';
import { useTranslation } from 'react-i18next';

function PropertyField() {
	const { t } = useTranslation();
	const { initialValues, values } = useFormikContext<
		AddGroupValues & EditGroupValues
	>();

	const disabled = isBoolean(initialValues.canBeProperty)
		? initialValues.parent?.id !== values.parent?.id
			? !values.canBePropertyToAncestors && !values.isProperty
			: !values.canBeProperty
		: !values.canBePropertyToAncestors && !values.isProperty;

	return (
		<Radio.Group.FormikField
			label={t('group.property')}
			name="isProperty"
			disabled={disabled}
		>
			<Radio value={true}>{PROPERTY_LABEL.YES()}</Radio>
			<Radio value={false}>{PROPERTY_LABEL.NO()}</Radio>
		</Radio.Group.FormikField>
	);
}

export default PropertyField;
