import { useTranslation } from 'react-i18next';
import { APIErrorCodes } from 'app-types';
import { Modal, Spin, Notification } from 'Components';
import { useModal } from 'Hooks/useModal';
import { DataTreeContextValue } from 'Contexts/DataTree/DataTree.types';
import { DataTreeContextProvider } from 'Contexts/DataTree/DataTreeContext';
import {
	GetPropertiesRequest,
	GetPropertiesResponse,
	Property
} from 'Services/Api/Groups/Types';
import { useQuery } from '@apollo/client';
import { GET_PROPERTIES } from 'Services/Api/Groups/Queries';
import DataTree from 'Contexts/DataTree/DataTree';
import GroupTreeLegend, {
	GroupTreeLegendProps
} from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupTreeLegend';
import { propertyToTreeNode } from './PropertiesField.helpers';
import PropertiesSearch from './PropertiesSearch';
import { GroupTreeProps } from 'Pages/Groups/Group/modals/MoveGroupModal/GroupModal/GroupTree';
import { ModalProps } from 'Components/Modal/Modal';
import { ListProps } from 'Contexts/DataTree/components/List/List';

type Value = GroupTreeProps['initialValue'];

export type PropertiesFieldModalProps = Pick<ModalProps, 'title' | 'okText'> & {
	initialValue: Value;
	onSubmit: (value: Value) => void;
	legendProps?: GroupTreeLegendProps;
	listProps?: ListProps;
};

function PropertiesFieldModal(props: PropertiesFieldModalProps) {
	const { t } = useTranslation();
	const { hideModal } = useModal();
	const result = useQuery<GetPropertiesResponse, GetPropertiesRequest>(
		GET_PROPERTIES,
		{
			variables: {
				filters: {}
			},
			notifyOnNetworkStatusChange: true
		}
	);

	const dataSource = result.data?.listProperties;
	const loading = !result.called || result.loading;

	if (!loading && !dataSource?.length) {
		Notification.error({
			description: t(`errorCodes.${APIErrorCodes.NoAvailableProperties}`)
		});
		hideModal();
		return null;
	}
	return (
		<DataTreeContextProvider<Property>
			initialValue={props.initialValue}
			dataSource={dataSource}
			toNode={propertyToTreeNode}
		>
			{({ dirty, value }) => (
				<PropertiesFieldModalContent
					{...props}
					loading={loading}
					value={value}
					dirty={dirty}
				/>
			)}
		</DataTreeContextProvider>
	);
}

type PropertiesFieldModalContentProps = PropertiesFieldModalProps &
	Pick<DataTreeContextValue, 'value' | 'dirty'> & {
		loading: boolean;
	};

function PropertiesFieldModalContent(props: PropertiesFieldModalContentProps) {
	const { t } = useTranslation();
	const { hideModal } = useModal();

	const onSubmit = async () => {
		await props.onSubmit(props.value);
		hideModal();
	};

	return (
		<Modal
			visible
			size="sm"
			title={
				props.title ??
				t(
					'ownershipGroup.addOwnershipGroup.propertiesField.propertiesFieldModal.title'
				)
			}
			okText={
				props.okText ??
				t(
					'ownershipGroup.addOwnershipGroup.propertiesField.propertiesFieldModal.okText'
				)
			}
			onOk={onSubmit}
			onCancel={hideModal}
			okButtonProps={{
				disabled: !props.dirty
			}}
		>
			{props.loading ? (
				<Spin />
			) : (
				<DataTree
					layout="modal"
					header={PropertiesSearch}
					footer={<GroupTreeLegend {...props.legendProps} />}
				>
					<DataTree.List
						highlightValue
						checkable
						checkStrictly
						{...props.listProps}
					/>
				</DataTree>
			)}
		</Modal>
	);
}

export default PropertiesFieldModal;
