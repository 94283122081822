import { isArray, mapValues } from 'lodash';
import {
	CustomValidationError,
	SPECIAL_CHAR_PATTERN,
	ValidationErrorByTestName,
	ValidationTestName
} from 'Helpers/validations';
import {
	TestsType,
	VALIDATION_TESTS_BY_FIELD
} from './NewPasswordFields.constants';
import { NewPasswordFieldsValues, RuleType } from './NewPasswordFields.types';

export const getTests = (
	errors: CustomValidationError[],
	currentValue: TestsType
): TestsType => {
	const invalidErrorTypes = errors.reduce<(keyof TestsType)[]>(
		(acc, error) => {
			const errorType = error.type as ValidationTestName;
			const fieldName = error.path as keyof NewPasswordFieldsValues;
			const shouldValidate = VALIDATION_TESTS_BY_FIELD[fieldName];

			if (
				isArray(shouldValidate) &&
				!shouldValidate.includes(errorType)
			) {
				return acc;
			}
			switch (errorType) {
				case ValidationTestName.PasswordIncludesCharacters:
					const params = error.params as ValidationErrorByTestName[typeof errorType]['params'];

					const infoKeys = (Object.keys(
						params.info
					) as unknown) as (keyof typeof params.info)[];
					infoKeys.forEach((infoKey) => {
						const isValid = params.info[infoKey];
						if (!isValid) {
							acc.push(infoKey);
						}
					});
					break;
				default:
					if (currentValue[errorType]) {
						acc.push(errorType);
					}

					break;
			}
			return acc;
		},
		[]
	);

	return mapValues(currentValue, (value, key) => {
		const valid = !invalidErrorTypes.includes(key as keyof TestsType);
		return {
			...value,
			valid
		};
	});
};

export const getIsValidByRuleType = (rules: TestsType) => {
	const rulesKeys = Object.keys(rules) as (keyof TestsType)[];
	const info = rulesKeys.reduce<Record<RuleType, boolean>>((acc, key) => {
		const rule = rules[key];
		acc[rule.rule] = acc[rule.rule] === false ? false : rule.valid;
		return acc;
	}, {} as Record<RuleType, boolean>);
	return info;
};

export const getAdditionalInfoByRule = (rule: RuleType) => {
	let info = '';
	switch (rule) {
		case RuleType.SpecialChar:
			const str = SPECIAL_CHAR_PATTERN.toString().slice(2, -4);
			const backslash = '\\\\';
			const backslashIndex = str.indexOf(backslash);
			const p1 = str.slice(0, backslashIndex).replace(/\\/g, '');
			const p2 = str
				.slice(backslashIndex + backslash.length)
				.replace(/\\/g, '');
			info = `${p1}\\${p2}`;
			break;
	}

	return info;
};
