import * as yup from 'yup';
import { NewPasswordFieldsValues } from './NewPasswordFields.types';
import { VALIDATION } from 'Pages/Auth/AuthValidation';

export const schema = yup
	.object()
	.shape<Record<keyof NewPasswordFieldsValues, yup.AnySchema>>({
		password: VALIDATION.password,
		confirmPassword: VALIDATION.confirmPassword
	});
