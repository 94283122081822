import { ModalType } from 'Hooks/useModal';
import { PreviewReportModalValue } from 'Pages/Report/PreviewReport/PreviewReport.types';
import { SavedReport } from 'Services/Api/Reports/Types';

export interface ReportModalsPropsBase {
	refresh: () => void;
}

export enum ReportModalAction {
	Preview,
	Delete,
	Clone
}

export type ReportModalValue =
	| ModalType<ReportModalAction.Preview, PreviewReportModalValue>
	| ModalType<ReportModalAction.Delete, SavedReport>
	| ModalType<ReportModalAction.Clone, SavedReport>;
