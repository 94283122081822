import { Icon } from 'Components';
import {
	DynamicPermissionCode,
	PermissionCode
} from 'Services/Api/Users/Types';
import {
	GroupMoreButtonMenuItemKey,
	GroupMoreButtonMenuItem
} from './GroupMoreButton.types';

const TRANSLATION_PATH = 'groups.groupDetails.components.moreDropdownButton';
export const MENU_ITEMS: GroupMoreButtonMenuItem[] = [
	{
		key: GroupMoreButtonMenuItemKey.Edit,
		title: `${TRANSLATION_PATH}.edit`,
		icon: <Icon type="edit" />
	},
	{
		key: GroupMoreButtonMenuItemKey.Move,
		title: `${TRANSLATION_PATH}.move`,
		icon: <Icon type="move" />
	},
	{
		key: GroupMoreButtonMenuItemKey.Users,
		title: `${TRANSLATION_PATH}.showUsers`,
		icon: <Icon type="users" />,
		permission: {
			dynamicCode: DynamicPermissionCode.ViewUsers
		}
	},
	{
		key: GroupMoreButtonMenuItemKey.ResetPassword,
		title: `${TRANSLATION_PATH}.changePassword`,
		icon: <Icon type="password" />
	},
	{
		key: GroupMoreButtonMenuItemKey.Delete,
		title: 'delete',
		icon: <Icon type="delete" />
	}
];

export const MENU_ITEMS_TENANT: Partial<
	Record<GroupMoreButtonMenuItemKey, Partial<GroupMoreButtonMenuItem>>
> = {
	[GroupMoreButtonMenuItemKey.Edit]: {
		permission: {
			code: PermissionCode.EditTenant
		}
	},
	[GroupMoreButtonMenuItemKey.Move]: {
		visible: false
	},
	[GroupMoreButtonMenuItemKey.ResetPassword]: {
		permission: {
			code: PermissionCode.ChangeGroupPassword
		}
	},
	[GroupMoreButtonMenuItemKey.Delete]: {
		permission: {
			code: PermissionCode.DeleteTenant
		}
	}
};

export const MENU_ITEMS_GROUP: typeof MENU_ITEMS_TENANT = {
	[GroupMoreButtonMenuItemKey.Edit]: {
		permission: {
			code: PermissionCode.EditGroup
		}
	},
	[GroupMoreButtonMenuItemKey.Move]: {
		permission: {
			code: PermissionCode.MoveGroup
		}
	},
	[GroupMoreButtonMenuItemKey.ResetPassword]: {
		permission: {
			code: PermissionCode.ChangeGroupPassword
		}
	},
	[GroupMoreButtonMenuItemKey.Delete]: {
		permission: {
			code: PermissionCode.DeleteGroup
		}
	}
};
