import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Notification } from 'Components';
import { FormikHelpers } from 'formik';
import { getAppRoutes } from 'Pages/App/App.constants';
import { generatePath, useHistory } from 'react-router-dom';
import {
	CREATE_OWNERSHIP_GROUP,
	EDIT_OWNERSHIP_GROUP
} from 'Services/Api/Groups/Mutations';
import {
	CreateOwnershipGroupRequest,
	CreateOwnershipGroupResponse,
	EditOwnershipGroupRequest,
	GroupActiveStatus
} from 'Services/Api/Groups/Types';
import { Values } from './AddOwnershipGroup.types';
import { checkApolloError } from 'Helpers/graphql';
import { AddOwnershipGroupProps } from './AddOwnershipGroup';
import schema from './schema';

export const useAddOwnershipGroup = ({ group }: AddOwnershipGroupProps) => {
	const { t } = useTranslation();
	const history = useHistory();

	const [createOwnershipGroup] = useMutation<
		CreateOwnershipGroupResponse,
		CreateOwnershipGroupRequest
	>(CREATE_OWNERSHIP_GROUP, {
		ignoreResults: false
	});
	const [editOwnershipGroup] = useMutation<void, EditOwnershipGroupRequest>(
		EDIT_OWNERSHIP_GROUP
	);

	const onSubmit = async (values: Values, helpers: FormikHelpers<Values>) => {
		try {
			const input: CreateOwnershipGroupRequest['input'] = {
				name: values.name,
				status: values.status,
				groups: values.groups.map((property) => Number(property.key))
			};

			let returnUrl: string | undefined;
			let message: string | undefined;
			const appRoutes = getAppRoutes();
			if (group) {
				await editOwnershipGroup({
					variables: {
						input: {
							...input,
							id: group.id
						}
					}
				});

				returnUrl = generatePath(appRoutes.OWNERSHIP_GROUPS, {
					groupId: group.id
				});
				message = t('notification.edit.success');
			} else {
				const response = await createOwnershipGroup({
					variables: {
						input
					}
				});
				returnUrl = generatePath(appRoutes.OWNERSHIP_GROUPS, {
					groupId: response.data?.createOwnershipGroup.id
				});
				message = t(
					'ownershipGroup.addOwnershipGroup.notification.success'
				);
			}

			if (returnUrl) {
				history.push(returnUrl);
			}

			if (message) {
				Notification.success({
					description: message
				});
			}
		} catch (error) {
			helpers.setSubmitting(false);
			helpers.setErrors(
				checkApolloError(error).getFieldErrors(
					t,
					schema.describe().fields
				) ?? {}
			);
		}
	};

	const initialValues: Values = group
		? {
				id: group.id,
				name: group.name,
				status: group.status,
				groups: group.groups.map((group) => ({
					key: group.id,
					value: group.id,
					label: group.name
				}))
		  }
		: {
				name: '',
				status: GroupActiveStatus.Active,
				groups: []
		  };

	return {
		initialValues,
		onSubmit
	};
};
