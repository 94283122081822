import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStores } from 'Hooks/useStore';
import { TokenForm } from './components/TokenForm';
import { getAppRoutes } from 'Pages/App/App.constants';
import AuthLayout from '../AuthLayout';
import styles from './TwoStepAuthentication.module.scss';

const TwoStepAuthentication = () => {
	const { authStore } = useStores();
	const { t } = useTranslation();
	if (authStore.twoFactorAuthorizationPassed) {
		return <Redirect to={getAppRoutes().HOME} />;
	}

	return (
		<AuthLayout wide>
			<h3 className={styles.h3}>
				{t('twoFactorAuthorization.verification.title')}
			</h3>
			<p className={styles.p}>
				{t('twoFactorAuthorization.verification.description.p1')}
			</p>
			<p className={styles.p}>
				{t('twoFactorAuthorization.verification.description.p2')}
			</p>
			<TokenForm />
			<p className={styles.p}>
				{t('twoFactorAuthorization.verification.support')}
			</p>
		</AuthLayout>
	);
};

export default observer(TwoStepAuthentication);
