import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import { Alert, Button, Fx, Input, Notification } from 'Components';
import { useStores } from 'Hooks/useStore';
import {
	CognitoResponseError,
	ResetPasswordSubmitRequest
} from 'Services/Api/Auth/Types';
import { getAppRoutes } from 'Pages/App/App.constants';
import { useHistory } from 'react-router-dom';
import { VALIDATION } from '../AuthValidation';
import NewPasswordFields from '../Login/Steps/NewPasswordFields/NewPasswordFields';
import { schema as newPasswordsSchema } from '../Login/Steps/NewPasswordFields/schema';
import { observer } from 'mobx-react-lite';
import {
	maxDigits,
	minDigits,
	numeric
} from 'Helpers/validations/validations.helpers';

const schema = yup
	.object()
	.shape({
		login: VALIDATION.login,
		securityCode: yup
			.string()
			.matches(/^[0-9]+$/, numeric)
			.label('auth.resetPasswordSubmitForm.inputs.securityCode')
			.min(6, minDigits)
			.max(6, maxDigits)
			.required(numeric)
	})
	.concat(newPasswordsSchema);

type Values = ResetPasswordSubmitRequest;
export interface ResetPasswordFormSubmitProps {
	login: string;
	sendSecurityCode: (login: string) => void;
}

function ResetPasswordFormSubmit(props: ResetPasswordFormSubmitProps) {
	const { authStore } = useStores();
	const history = useHistory();
	const { t } = useTranslation();
	const initialValues: Values = {
		login: props.login,
		securityCode: '',
		password: '',
		confirmPassword: ''
	};

	const onSubmit = async (values: Values, helpers: FormikHelpers<Values>) => {
		helpers.setStatus('');
		try {
			await authStore.resetPasswordSubmit(values);
			history.push(getAppRoutes().LOGIN);
			Notification.success({
				description: t(
					'auth.resetPasswordSubmitForm.notifications.success'
				)
			});
		} catch (e) {
			const error = e as CognitoResponseError;
			helpers.setStatus(error.message);
			helpers.setSubmitting(false);
		}
	};

	const onResendSecurityCodeSubmit = async (
		_: Record<string, unknown>,
		helpers: FormikHelpers<Record<string, unknown>>
	) => {
		try {
			await props.sendSecurityCode(props.login);
		} catch (e) {
			const error = e as CognitoResponseError;
			helpers.setSubmitting(false);
			if (error.code === 'LimitExceededException') {
				helpers.setStatus(t('auth.resetPassword.errors.limitExceeded'));
				return;
			}
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={schema}
		>
			{({ isValid, dirty, isSubmitting, status }) => (
				<Form>
					<h1>{t('auth.resetPasswordSubmitForm.title')}</h1>
					{status && (
						<Alert
							showIcon
							message=""
							description={status}
							type="error"
						/>
					)}
					<p>{t('auth.resetPasswordSubmitForm.description')}</p>
					<div>
						<Input.FormikField
							name="securityCode"
							label={t(
								'auth.resetPasswordSubmitForm.inputs.securityCode'
							)}
						/>
						<Formik
							initialValues={{}}
							onSubmit={onResendSecurityCodeSubmit}
						>
							{({ isSubmitting, status, submitForm }) => (
								<>
									{status && (
										<Alert
											showIcon
											message=""
											description={status}
											type="error"
										/>
									)}
									<Fx justify="end">
										<Button
											type="text"
											link
											thin
											loading={isSubmitting}
											onClick={submitForm}
										>
											{t(
												'auth.resetPasswordSubmitForm.resendSecurityCode'
											)}
										</Button>
									</Fx>
								</>
							)}
						</Formik>
					</div>
					<hr />
					<h1>
						{t('auth.resetPasswordSubmitForm.createNewPassword')}
					</h1>
					<NewPasswordFields />
					<Button
						htmlType="submit"
						type="primary"
						shape="round"
						disabled={!(isValid && dirty)}
						loading={isSubmitting}
						fullWidth
					>
						{t('auth.resetPassword.submitText')}
					</Button>
				</Form>
			)}
		</Formik>
	);
}

export default observer(ResetPasswordFormSubmit);
