import { useQuery } from '@apollo/client';
import { TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { APP_CONFIG } from 'app-config';
import { createSortDescription } from 'Components/Table/createColumns';
import { debounce } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { GET_DELETED_GROUPS } from 'Services/Api/Groups/Queries';
import {
	DeletedGroup,
	GetDeletedGroupsRequest,
	GetDeletedGroupsResponse
} from 'Services/Api/Groups/Types';
import useColumns, { TableFilterValues } from './columns';

const FETCH_DELETED_GROUPS_INITIAL_VALUES: GetDeletedGroupsRequest = {
	sort: createSortDescription({
		columnKey: 'name',
		order: 'ascend'
	}),
	filters: {}
};

export const useDeletedGroups = () => {
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const result = useQuery<GetDeletedGroupsResponse, GetDeletedGroupsRequest>(
		GET_DELETED_GROUPS,
		{
			variables: FETCH_DELETED_GROUPS_INITIAL_VALUES,
			notifyOnNetworkStatusChange: true
		}
	);

	const refetch = (params: Partial<GetDeletedGroupsRequest> = {}) => {
		const previousParams = result.variables as GetDeletedGroupsRequest;
		const variables: GetDeletedGroupsRequest = {
			...previousParams,
			...params,
			filters: {
				...previousParams.filters,
				...params.filters
			}
		};
		result.refetch(variables);
	};

	const onSearchCbRef = useRef(refetch);
	onSearchCbRef.current = refetch;

	const onTableChange: TableProps<DeletedGroup>['onChange'] = (
		_,
		filters,
		sorter
	) => {
		const sort = createSortDescription<DeletedGroup>(
			sorter as SorterResult<DeletedGroup>
		);
		const values = filters as TableFilterValues;
		const variables: GetDeletedGroupsRequest = {
			sort,
			filters: {
				name: values.name?.[0] || undefined,
				isProperty: values.isProperty ?? undefined,
				syncCode: values.syncCode?.[0] || undefined,
				tenantName: values.tenantName?.[0] || undefined,
				hierarchy: values.hierarchy?.[0] || undefined,
				isArchived: values.isArchived,
				active: values.active
			}
		};
		refetch(variables);
	};

	// eslint-disable-next-line
	const onSearch = useCallback(
		debounce((current: string, previous?: string) => {
			if (current.length < APP_CONFIG.SEARCH_MIN_LENGTH) {
				if (previous?.length) {
					onSearchCbRef.current({
						search: ''
					});
				}
				return;
			}
			onSearchCbRef.current({
				search: current
			});
		}, 400),
		[]
	);

	const onSearchChange = (value: string) => {
		onSearch(value, result.variables?.search);
	};

	const columns = useColumns({
		filterValues: result.variables?.filters || {}
	});

	return {
		result,
		dataSource: result.data?.listDeletedGroups,
		columns,
		onTableChange,
		selectedRowKeys,
		setSelectedRowKeys,
		refetch,
		onSearchChange
	};
};
