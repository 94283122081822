import { useStores } from 'Hooks/useStore';
import { useCallback } from 'react';
import { PermissionProps } from './Permission';
import { hasPermission } from './Permission.helpers';

export const usePermission = () => {
	const {
		authStore: { currentUserPermissionCodes }
	} = useStores();

	const getHasPermission = useCallback(
		(props: Omit<PermissionProps, 'children'>) => {
			return hasPermission({
				permission: props,
				currentPermissionCodes: currentUserPermissionCodes
			});
		},
		[currentUserPermissionCodes]
	);

	return {
		getHasPermission
	};
};
