import { LineChartControlsProps } from 'Pages/Dashboard/visualizations/LineChart/LineChartControls';

export enum ElearningWidgets {
	TotalElearningScore = 'total_elearning_score',
	LearningPathSummary = 'learning_path_summary',
	TotalCourseOverview = 'total_course_overview',
	CourseCertificationActivity = 'course_certification_activity',
	HistoricalCompletedCourses = 'historical_completed_courses'
}

export type ControlValues = Pick<
	LineChartControlsProps,
	'keys' | 'checkedKeys' | 'timeInterval'
>;

export type ElearningWidgetParams = Omit<ControlValues, 'keys'>;
