import { useTranslation } from 'react-i18next';
import HierarchySubmitButton from '../HierarchySubmitButton/HierarchySubmitButton';

interface HierarchyXLSXSubmitButtonProps {
	onClick: () => void;
	disabled: boolean;
}
const HierarchyXLSXSubmitButton = ({
	onClick,
	disabled
}: HierarchyXLSXSubmitButtonProps) => {
	const { t } = useTranslation();
	return (
		<HierarchySubmitButton onClick={onClick} disabled={disabled}>
			{t('groupsHierarchy.importHierarchy')}
		</HierarchySubmitButton>
	);
};

export default HierarchyXLSXSubmitButton;
