import { useTranslation } from 'react-i18next';
import { Button, Fx } from 'Components';
import styles from './ValidateEmail.module.scss';

interface ResendValidationEmailProps {
	resendValidationEmail(): void;
	loading: boolean;
}

function ResendValidationEmail({
	resendValidationEmail,
	loading
}: ResendValidationEmailProps) {
	const { t } = useTranslation();

	return (
		<Fx column fullHeight>
			<p>{t('emailValidation.description')}</p>
			<div className={styles.ButtonResendValidationEmail}>
				<Button
					shape="round"
					type="primary"
					onClick={resendValidationEmail}
					loading={loading}
					disabled={loading}
				>
					{t('emailValidation.resendValidationEmail')}
				</Button>
			</div>
		</Fx>
	);
}

export default ResendValidationEmail;
